import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 5 * 60 * 1000;

export default function useUsersInGroupData({ groupId }: { groupId: string }) {
  const { usersApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    [...QUERY_KEYS.USERS_IN_GROUP_LIST(groupId)],
    () => usersApi.getUsersInGroup(groupId),
    { staleTime: STALE_TIME, enabled: !!groupId },
  );

  return {
    users: data && !data.error ? data.data : [],
    isLoading,
    isSuccess,
  };
}
