import { QrCode, QrCode2, Wifi } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

import { XtagType } from '@/common/entities/things/typedefs';

import DustDustedIcon from './DustDustedIcon';

type DustXTagIconProps = SvgIconProps & {
  type: XtagType;
};

function DustXTagIcon({ type, ...props }: DustXTagIconProps) {
  switch (type) {
    case 'DATA_MATRIX':
      return <QrCode {...props} />;
    case 'DUST':
      return <DustDustedIcon {...props} />;
    case 'NFC':
      return (
        <Wifi
          sx={{
            ...props.sx,
            // TODO: When MUI makes an NFC logo, use that.
            transform: 'rotate(90deg)',
          }}
          {...props}
        />
      );
    case 'QR':
    default:
      return <QrCode2 {...props} />;
  }
}

export default DustXTagIcon;
