import { atom } from 'recoil';
import * as yup from 'yup';

import { userLocalStorageEffect } from '../utility';

/** Undefined = unfetched/unread, and null = user never saw a version. */
export const autoNextAtom = atom<boolean>({
  key: 'bindAutoNext',
  default: false,
  effects: [
    userLocalStorageEffect('bindAutoNext', yup.bool().required(), false),
  ],
});
