import { useMemo } from 'react';

import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useAuth } from 'react-oidc-context';

import { editProfileSchema } from '@/common/entities/users/schemas';
import DustModal from '@/components/Library/DustModal';
import DustPasswordField from '@/components/Library/DustPasswordField';
import useRequest from '@/services/requests/useRequest';

type Props = {
  open: boolean;
  setOpen: (e: boolean) => void;
  user: User;
};

export default function EditUserForm({ open, setOpen, user }: Props) {
  const { usersApi } = useRequest();
  const auth = useAuth();

  const initialValues = useMemo(
    () => ({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      password: '',
      verifyPassword: '',
    }),
    [user],
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: editProfileSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const submitValues: Partial<typeof values> & { sub: string } = {
        sub: user?.sub ?? '',
        ...values,
      };

      if (!values.password) {
        delete submitValues.password;
        delete submitValues.verifyPassword;
      }
      const res = await usersApi.updateUser(submitValues);

      if (!res.error) {
        // Required to clear the password values from the form
        formik.resetForm({
          values: {
            ...values,
            password: '',
            verifyPassword: '',
          },
        });

        setOpen(false);
        // Refresh our current user token to update our own information
        void auth.signinSilent();
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <DustModal
      footerProps={{
        onSubmit: formik.handleSubmit,
        loading: formik.isSubmitting,
        onCancel: handleClose,
      }}
      onClose={handleClose}
      open={open}
      title="Edit User Profile"
    >
      <>
        <div className="flex-col gap-1">
          <h2 className="h5">Basic Information</h2>
          <TextField
            error={formik.touched.firstName && !!formik.errors.firstName}
            helperText={formik.touched.firstName ? formik.errors.firstName : ''}
            label="First Name"
            name="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            value={formik.values.firstName}
          />
          <TextField
            error={formik.touched.lastName && !!formik.errors.lastName}
            helperText={formik.touched.lastName ? formik.errors.lastName : ''}
            label="Last Name"
            name="lastName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            value={formik.values.lastName}
          />
        </div>
        <div className="flex-col gap-1 mt-1">
          <h2 className="h5">Change Password (optional)</h2>
          <DustPasswordField
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password ? formik.errors.password : ''}
            label="Password"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            value={formik.values.password}
          />
          <DustPasswordField
            error={
              formik.touched.verifyPassword && !!formik.errors.verifyPassword
            }
            helperText={
              formik.touched.verifyPassword ? formik.errors.verifyPassword : ''
            }
            label="Verify Password"
            name="verifyPassword"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            value={formik.values.verifyPassword}
          />
        </div>
      </>
    </DustModal>
  );
}
