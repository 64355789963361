import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 5 * 60 * 1000;

export default function useUsersData() {
  const { usersApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.USERS,
    () => usersApi.getUsers(),
    { staleTime: STALE_TIME },
  );

  return {
    users: data && data.error === false ? data.data : [],
    isLoading,
    isSuccess,
  };
}
