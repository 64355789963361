import { useMemo } from 'react';

import DustThumbnail, {
  DustThumbnailProps,
} from '@/components/Library/DustThumbnail';

type Props = {
  thing: Thing;
} & DustThumbnailProps;

function DustThingThumbnail({ thing, ...props }: Props) {
  // Untyped primary image
  const primaryFile = useMemo(
    () => thing.primaryImage ?? thing.files?.find((f) => f.isPrimary),
    [thing],
  );

  return (
    <DustThumbnail
      imageUuid={primaryFile?.uuid}
      title={`${thing.title} Primary Image`}
      {...props}
    />
  );
}

export default DustThingThumbnail;
