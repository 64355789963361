import { antiTamperAnalysisScanModel } from '@/common/entities/antiTamper/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import {
  defaultErrorHandler,
  formatAPIError,
  formatAPIReturn,
} from '@/services/utility';

import { MESSAGES } from '../constants';

type StartAntiTamperAnalysisProps = {
  thingUuid: string;
  scan?: string;
};

type ConfirmAntiTamperAnalysisProps = {
  antiTamperAnalysisUuid?: string;
  outcome: string;
  comment: string;
  notations: AntiTamperNotation[];
};

type LoadAntiTamperAnalysisProps = {
  uuid: string;
};

/** Factory function for producing the features API */
export default class AntiTamperApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  async startAntiTamperAnalysis(options: StartAntiTamperAnalysisProps) {
    return this.apiService
      .request({
        url: 'api/dusts/anti-tamper-analysis/start?blurDetection=true',
        method: 'POST',
        data: options,
      })
      .then((res) => formatAPIReturn(res, antiTamperAnalysisScanModel))
      .catch((err) => {
        this.addToast(MESSAGES.ANTI_TAMPER_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  async loadAntiTamperAnalysis({ uuid }: LoadAntiTamperAnalysisProps) {
    return this.apiService
      .request({
        url: `api/dusts/anti-tamper-analysis/${uuid}`,
        method: 'GET',
      })
      .then((res) => formatAPIReturn(res, antiTamperAnalysisScanModel))
      .catch((err) => {
        this.addToast(MESSAGES.ANTI_TAMPER_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  async checkAntiTamperAllowed() {
    return this.apiService
      .request({
        url: 'api/dusts/anti-tamper-analysis/start',
        method: 'GET',
      })
      .then(() => true)
      .catch(() => false);
  }

  async confirmAntiTamperAnalysis({
    antiTamperAnalysisUuid,
    outcome,
    comment,
    notations,
  }: ConfirmAntiTamperAnalysisProps) {
    return this.apiService
      .request({
        url: 'api/dusts/anti-tamper-analysis/confirm',
        method: 'POST',
        data: {
          antiTamperAnalysisUuid,
          outcome,
          comment,
          annotations: notations,
        },
      })
      .then((res) => {
        this.addToast(MESSAGES.ANTI_TAMPER_COMPLETE, 'success');
        return formatAPIReturn(res, antiTamperAnalysisScanModel);
      })
      .catch(() => {
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.ANTI_TAMPER_FAILED, 'error');
        });
      });
  }
}
