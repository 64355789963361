import { useMemo, useState } from 'react';

import { useInfiniteQuery } from 'react-query';
import { atom } from 'recoil';

import {
  pageCountGetNextPageParam,
  useFetchAll,
  useGetNextPage,
} from '@/common/utilities/table';
import useRequest from '@/services/requests/useRequest';
import { SortOptions } from '@/services/useSort';

const STALE_TIME = 24 * 60 * 60 * 1000;

// NOTE: server-side sorting is unused for now
export const USER_GROUPS_SORT_OPTIONS = Object.freeze({
  nameaz: {
    label: 'Name (A-Z)',
    params: { sort: ['name'] },
    column: 'name',
    direction: 'asc',
  },
  nameza: {
    label: 'Name (Z-A)',
    params: { sort: ['-name'] },
    column: 'name',
    direction: 'desc',
  },
  createdAtNewest: {
    label: 'Newest First',
    params: { sort: ['-createdAt'] },
    column: 'created',
    direction: 'desc',
  },
  createdAtOldest: {
    label: 'Oldest First',
    params: { sort: ['createdAt'] },
    column: 'created',
    direction: 'asc',
  },
} as const);

// TODO: use upcoming TypeScript `satisfies` keyword for this type check
((q: SortOptions) => q)(USER_GROUPS_SORT_OPTIONS);

// NOTE: server-side sorting is unused for now
export const userGroupsSortAtom = atom<keyof typeof USER_GROUPS_SORT_OPTIONS>({
  key: 'userGroupsSort',
  default: 'nameaz',
});

export type GroupFilters = {
  perPage: number;
};

const defaultFilters = ({ perPage = 100 }): GroupFilters => ({
  perPage,
});

type HookProps = Partial<{
  perPage: number;
}>;

export default function useGroupsData(options: HookProps = {}) {
  const [filters, setFilters] = useState(defaultFilters(options));

  const { groupsApi, QUERY_KEYS } = useRequest();

  /** Set a single filter  */
  const setFilter = <T extends keyof GroupFilters>(
    key: T,
    val: GroupFilters[T],
  ) => {
    setFilters((prev) => ({ ...prev, [key]: val }));
  };

  /** Reset a single filter */
  const resetFilter = (key: keyof GroupFilters) => {
    setFilter(key, defaultFilters({})[key]);
  };

  // Query differently depending on if a search string is provided
  const queryParams = {
    perPage: filters.perPage,
  };

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    [...QUERY_KEYS.GROUPS_LIST, queryParams],
    ({ pageParam = 1 }) =>
      groupsApi.getUserGroups({ ...queryParams, page: pageParam }),
    {
      getNextPageParam: pageCountGetNextPageParam,
      staleTime: STALE_TIME,
    },
  );

  const groups = useMemo(
    () =>
      data?.pages?.reduce<Group[]>(
        (combined, current) =>
          combined.concat(current?.error ? [] : current?.data),
        [],
      ) ?? [],
    [data],
  );

  const getNextPage = useGetNextPage(
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  );

  useFetchAll(true, getNextPage, isFetchingNextPage, hasNextPage);

  const totalItems = groups.length;

  return {
    groups,
    totalItems,
    isLoading,
    isFetching: isLoading || isFetchingNextPage,
    isSuccess,
    getNextPage,
    setFilter,
    resetFilter,
    filters,
    refetch,
  };
}
