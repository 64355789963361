import { useState } from 'react';

import useToasts from '@/common/hooks/useToasts';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

type Props = {
  onClose: () => void;
  open: boolean;
  groups: Group[];
};

export default function DeleteUserGroup({ onClose, open, groups = [] }: Props) {
  const { groupsApi } = useRequest();

  const [submitting, setSubmitting] = useState(false);
  const { addToast } = useToasts();

  const handleDelete = async () => {
    const queries = groups.map((g) => groupsApi.deleteGroup(g.id, true));

    setSubmitting(true);
    const res = await Promise.all(queries);

    setSubmitting(false);

    if (!res.some((r) => r.error)) {
      addToast('Group(s) successfully deleted', 'success');
      onClose();
    } else {
      addToast('Some group(s) could not be deleted. ', 'error');
    }
  };

  return (
    <DustModal
      footerProps={{
        onCancel: onClose,
        loading: submitting,
        onSubmit: handleDelete,
        submitLabel: 'Delete',
        cancelLabel: 'Cancel',
        submitColor: 'error',
      }}
      onClose={onClose}
      open={open}
      title="Delete User Group(s)"
    >
      <>
        <h2 className="h6" style={{ marginBottom: '1rem' }}>
          The following groups will be deleted:
        </h2>
        {groups.map((g) => (
          <li key={g.id}>{g.name}</li>
        ))}
      </>
    </DustModal>
  );
}
