import { useEffect, useMemo, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import useGroupRoleData from '@/common/entities/groups/useGroupRoleData';
import useOrgRolesData from '@/common/entities/orgRoles/useOrgRolesData';
import { capitalizeFirst } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import useRequest from '@/services/requests/useRequest';

import styles from './Groups.module.css';

type Props = {
  group: Group;
};

export default function GroupEditRoles({ group }: Props) {
  const { roles, isLoading: isLoadingRoles } = useOrgRolesData();
  const { groupRoles, isLoading: isLoadingGroupRoles } = useGroupRoleData({
    groupOidcId: group.id,
  });

  const [selected, setSelected] = useState<string[]>([]);

  const formIsInitial = useMemo(() => {
    const arr1 = selected.slice().sort();
    const arr2 = groupRoles.map((o) => o.id).sort();
    return (
      arr1.length === arr2.length && arr1.every((e, idx) => e === arr2[idx])
    );
  }, [selected, groupRoles]);

  useEffect(() => {
    if (groupRoles) setSelected(groupRoles.map((g) => g.id));
  }, [groupRoles]);

  const { groupsApi } = useRequest();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    await groupsApi.updateGroupRoles(
      group.id,
      roles
        .filter((r) => selected.includes(r.id))
        .map((r) => ({ id: r.id, name: r.name })),
    );
    setSubmitting(false);
  };

  const handleReset = () => {
    if (groupRoles) setSelected(groupRoles.map((g) => g.id));
  };

  const columns: GridColDef<OrgRole>[] = [
    {
      valueGetter: ({ row }) =>
        row.name
          .split('-')
          .map((e) => capitalizeFirst(e))
          .join(' '),
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
  ];

  const isLoading = isLoadingGroupRoles || isLoadingRoles;
  return (
    <div className={styles.tabBox}>
      <div className="flex-1 mt-1">
        <DustDataGrid
          altTheme
          columns={columns}
          getRowId={(row) => row.id}
          loading={isLoading}
          selectionModel={selected}
          onSelectionModelChange={(rowIds) => setSelected(rowIds as string[])}
          rows={roles}
          checkboxSelection
        />
      </div>
      <DustStepperFooter
        onSubmit={handleSubmit}
        cancelLabel="Discard Changes"
        onCancel={handleReset}
        cancelDisabled={formIsInitial}
        loading={submitting}
      />
    </div>
  );
}
