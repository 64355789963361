import { useCallback, useMemo, useState } from 'react';

import Add from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import { Button, ButtonGroup } from '@mui/material';

import useCatalogsData, {
  catalogSelectorSortAtom,
  FILTER_NAMES,
  FILTER_LABELS,
  CATALOG_SORT_OPTIONS,
} from '@/common/entities/catalogs/useCatalogsData';
import CatalogGrid from '@/components/Composed/CatalogSelector/CatalogGrid';
import DustFilterChip from '@/components/Library/DustFilterChip';
import DustLinkButton from '@/components/Library/DustLinkButton';
import DustSearchInput from '@/components/Library/DustSearchInput';
import { toggleButtonStyle } from '@/components/Library/styleHelpers';
import useRequest from '@/services/requests/useRequest';
import useSort from '@/services/useSort';

import styles from './Catalog.module.css';
import CatalogFilters from './CatalogFilters';
import CatalogSettings from './CatalogSettings';
import CatalogsTable from './CatalogsTable';

const VIEWS = {
  GRID: 'grid',
  TABLE: 'table',
};

type Props = {
  onSelect: (e: Catalog) => void;
  selectedUuid?: string;
  showAddButton?: boolean;
};

export default function CatalogSelector({
  onSelect,
  selectedUuid,
  showAddButton = false,
}: Props) {
  const { catalogsApi } = useRequest();

  const { resetSort, sortRequestParams } = useSort(
    catalogSelectorSortAtom,
    CATALOG_SORT_OPTIONS,
  );

  const [view, setView] = useState(VIEWS.GRID);
  const [showAddCatalogModal, setShowAddCatalogModal] = useState(false);

  // ---------------------------------------------------------------------------
  // State Changes for Catalogs

  // Toggle properties server-side
  const handleFavorite = useCallback(
    async (catalog: Catalog) =>
      catalog.favorite
        ? catalogsApi.unfavorite(catalog.uuid)
        : catalogsApi.favorite(catalog.uuid),
    [catalogsApi],
  );

  const handleHide = useCallback(
    async (catalog: Catalog) =>
      catalog.hidden
        ? catalogsApi.unhide(catalog.uuid)
        : catalogsApi.hide(catalog.uuid),
    [catalogsApi],
  );

  // ---------------------------------------------------------------------------
  // Filter Handling

  const [perPage, setPerPage] = useState(5);
  const {
    isLoading,
    isFetching,
    isSuccess,
    catalogs,
    appliedFilters,
    filters,
    setFilter,
    resetFilter,
    resetFilters,
    getNextPage,
    hasNextPage,
    totalItems,
  } = useCatalogsData({
    perPage: view === VIEWS.TABLE ? 50 : perPage,
    useFilters: true,
    ...sortRequestParams,
  });

  const handleSearchChange = useCallback(
    (value: string) => setFilter(FILTER_NAMES.SEARCH, value),
    [setFilter],
  );

  const viewComponents = useMemo(
    () => ({
      [VIEWS.GRID]: (
        <CatalogGrid
          isSuccess={isSuccess}
          isLoading={isFetching}
          catalogs={catalogs}
          onFavorite={handleFavorite}
          onSelect={onSelect}
          selectedUuid={selectedUuid}
          onScrollEnd={getNextPage}
          onHide={handleHide}
          perPage={perPage}
          setPerPage={setPerPage}
          hasNextPage={hasNextPage}
        />
      ),
      [VIEWS.TABLE]: (
        <CatalogsTable
          catalogs={catalogs}
          onFavorite={handleFavorite}
          onRowClick={(catalog: Catalog) => onSelect(catalog)}
          isLoading={isLoading}
          totalItems={totalItems}
          getNextPage={getNextPage}
          onHide={handleHide}
        />
      ),
    }),
    [
      isSuccess,
      isFetching,
      catalogs,
      handleFavorite,
      handleHide,
      perPage,
      hasNextPage,
      getNextPage,
      isLoading,
      onSelect,
      selectedUuid,
      totalItems,
    ],
  );

  const handleResetFilters = useCallback(() => {
    resetFilters();
    resetSort();
  }, [resetFilters, resetSort]);

  return (
    <>
      <div className="flex-row">
        <DustSearchInput
          defaultValue={filters.name}
          setValue={handleSearchChange}
          aria-label="Search for a catalog"
        />
        {showAddButton && (
          <Button
            onClick={() => setShowAddCatalogModal(true)}
            startIcon={<Add />}
            variant="contained"
          >
            Add
          </Button>
        )}
        <div className="flex-1" />
        <CatalogFilters
          resetFilters={handleResetFilters}
          showResetFilter={appliedFilters.length > 0}
          filters={filters}
          setFilter={setFilter}
        />
        <ButtonGroup sx={{ marginLeft: '.5rem' }}>
          <Button
            data-testid="toggleButtonGrid"
            onClick={() => setView(VIEWS.GRID)}
            sx={toggleButtonStyle(view, VIEWS.GRID)}
          >
            <ViewComfyIcon />
          </Button>
          <Button
            data-testid="toggleButtonTable"
            onClick={() => setView(VIEWS.TABLE)}
            sx={toggleButtonStyle(view, VIEWS.TABLE)}
          >
            <ListIcon />
          </Button>
        </ButtonGroup>
      </div>
      <div className={styles.chips}>
        {appliedFilters.map((filter) => (
          <DustFilterChip
            key={filter}
            label={
              filter === FILTER_NAMES.VIEW
                ? filters[FILTER_NAMES.VIEW]
                : FILTER_LABELS[filter]
            }
            onDelete={() => resetFilter(filter)}
            aria-label={`${filter} filter for catalogs on`}
          />
        ))}
        {appliedFilters.length > 0 && (
          <DustLinkButton
            onClick={handleResetFilters}
            sx={{ margin: '0 .5rem' }}
          >
            Clear filters
          </DustLinkButton>
        )}
      </div>
      {viewComponents[view]}
      <CatalogSettings
        onClose={() => setShowAddCatalogModal(false)}
        open={showAddCatalogModal}
      />
    </>
  );
}
