import { useState, useMemo } from 'react';

import { Alert, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';

import useGroupsData from '@/common/entities/groups/useGroupsData';
import useUserGroupsData from '@/common/entities/groups/useUserGroupsData';
import { USER_ACCOUNT_STATUS } from '@/common/entities/users/constants';
import { addUserSchema } from '@/common/entities/users/schemas';
import useUserData from '@/common/entities/users/useUserData';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import DustCheckboxSelect from '@/components/Library/DustCheckboxSelect';
import DustLoader from '@/components/Library/DustLoader';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import useRequest from '@/services/requests/useRequest';

export default function UserForm() {
  const { userId } = useParams();
  const auth = useAuth();
  if (!userId) throw new Error('Missing Path Id');

  const isEdit = userId !== 'add';

  const navigate = useNavigate();
  const { usersApi } = useRequest();

  const [pwResetLoading, setPwResetLoading] = useState(false);
  const [userCreated, setUserCreated] = useState(false);

  const { user: userDetails, isLoading: isLoadingUser } = useUserData(userId, {
    enabled: isEdit,
  });

  const { userGroups, isLoading: isLoadingUserGroups } = useUserGroupsData({
    sub: userDetails?.sub ?? '',
  });

  const { groups, isLoading: isLoadingGroups } = useGroupsData();

  const initialValues = useMemo(
    () => ({
      firstName: userDetails?.firstName || '',
      lastName: userDetails?.lastName || '',
      email: userDetails?.email || '',
      addUserGroups: userGroups?.map((g) => g.id) || [],
    }),
    [userDetails, userGroups],
  );

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addUserSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (isEdit && !userDetails) return;

      let response;
      if (isEdit) {
        const sub = userDetails?.sub;
        if (!sub) throw new Error('Expected a usable sub for a user edit');
        response = await usersApi.updateUser({ sub, ...values });
        if (sub === auth.user?.profile.sub) {
          void auth.signinSilent();
        }
      } else {
        response = await usersApi.createUser(values);
      }

      if (!response.error && !isEdit) {
        setUserCreated(true);
      }
    },
  });

  useNavigationPrompt(formik.dirty);

  const handleFooterSubmit = () => {
    if (userCreated) {
      formik.resetForm();
      setUserCreated(false);
    } else {
      formik.handleSubmit();
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    navigate(-1);
  };

  const handlePwReset = async (user: User) => {
    setPwResetLoading(true);
    await usersApi.resetUserPassword(user.sub);
    setPwResetLoading(false);
  };

  const loading = isLoadingGroups || isLoadingUser || isLoadingUserGroups;

  return (
    <div className="page-container">
      <div className="action-container">
        <div className="action-body">
          {loading && <DustLoader flex size="xlarge" />}
          {!loading && (
            <form
              className="flex-col gap-1"
              id="add-user-form"
              onSubmit={formik.handleSubmit}
              style={{ maxWidth: '20rem' }}
            >
              <h2 className="h4">Basic Information</h2>
              <TextField
                error={formik.touched.firstName && !!formik.errors.firstName}
                helperText={
                  formik.touched.firstName ? formik.errors.firstName : ''
                }
                label="First Name"
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                size="small"
                value={formik.values.firstName}
              />
              <TextField
                error={formik.touched.lastName && !!formik.errors.lastName}
                helperText={
                  formik.touched.lastName ? formik.errors.lastName : ''
                }
                label="Last Name"
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                size="small"
                value={formik.values.lastName}
              />
              <TextField
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email ? formik.errors.email : ''}
                label="Email Address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                size="small"
                value={formik.values.email}
              />
              <h2 className="h4 mt-1">Permissions & Traceability</h2>
              <DustCheckboxSelect
                entries={groups.map((g) => ({
                  name: g.name,
                  value: g.id,
                }))}
                error={
                  formik.touched.addUserGroups && !!formik.errors.addUserGroups
                }
                helperText={
                  Array.isArray(formik.errors.addUserGroups)
                    ? formik.errors.addUserGroups[0]
                    : formik.errors.addUserGroups
                }
                label="User Groups"
                onChange={(selected) =>
                  formik.setFieldValue('addUserGroups', selected)
                }
                onClear={() => formik.setFieldValue('addUserGroups', [])}
                values={formik.values.addUserGroups}
              />
              {userDetails && (
                <>
                  {userDetails.userAccountStatus ===
                    USER_ACCOUNT_STATUS.PASSWORD_RESET && (
                    <Alert severity="info">
                      User will already be prompted to change their password
                      when they next log in. Click the button to reset again.
                    </Alert>
                  )}
                  <DustLoadingButton
                    disabled={pwResetLoading}
                    loading={pwResetLoading}
                    onClick={() => handlePwReset(userDetails)}
                  >
                    Force Password Reset
                  </DustLoadingButton>
                </>
              )}
            </form>
          )}
        </div>
        <DustStepperFooter
          cancelLabel={userCreated ? 'Done' : 'Cancel'}
          loading={formik.isSubmitting}
          onCancel={handleCancel}
          onSubmit={handleFooterSubmit}
          submitLabel={
            (userCreated && 'Invite another User') ||
            (isEdit && 'Save') ||
            'Invite User'
          }
        />
      </div>
    </div>
  );
}
