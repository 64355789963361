import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DustMultiFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="M15.5 1.5H8.5C7.4 1.5 6.51 2.4 6.51 3.5L6.5 17.5C6.5 18.6 7.39 19.5 8.49 19.5H19.5C20.6 19.5 21.5 18.6 21.5 17.5V7.5L15.5 1.5ZM16.5 23.5H4.5C3.4 23.5 2.5 22.6 2.5 21.5V7.5H4.5V21.5H16.5V23.5ZM8.5 3.5V17.5H19.5V8.5H14.5V3.5H8.5Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
