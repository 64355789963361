/** Manage app-instance-wide state for a user and/or org id key,
 * mainly intended for userLocalStorageEffect atoms */
class StorageKeyState {
  private value: string | null = null;

  private callbacks: ((value: string | null) => void)[] = [];

  get() {
    return this.value;
  }

  set(val: string) {
    if (this.value === val) return; // Skip callbacks where not needed
    this.value = val;
    this.callbacks.forEach((callback) => {
      callback(val);
    });
  }

  /** Since these are used by recoil atoms, no need to allow for removing callbacks yet */
  onChange(callback: (value: string | null) => void): void {
    this.callbacks.push(callback);
  }

  reset() {
    this.value = null;
    this.callbacks.forEach((callback) => {
      callback(null);
    });
  }
}

export type { StorageKeyState };

/* For any localStorage keys that depend on only user */
export const activeUserIdState = new StorageKeyState();
/* For any localStorage keys that depend on user and org id (most data-based state) */
export const activeUserAndOrgIdState = new StorageKeyState();
