import { useCallback, useMemo } from 'react';

import {
  GridColDef,
  GridGroupingColDefOverride,
  GridRowParams,
  GRID_TREE_DATA_GROUPING_FIELD,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useNavigate } from 'react-router-dom';

import useThingTypesData from '@/common/entities/thingTypes/useThingTypesData';
import { ROUTES } from '@/common/routes';
import { makeMixedThingTypeLookupColDef } from '@/common/utilities/relationships/constants';
import {
  getRelationNameForLevel,
  makeInitialHierarchyRows,
  ThingChildrenHierarchyRow,
  thingChildrenToHierarchyRows,
} from '@/common/utilities/relationships/helpers';
import { alphaSort } from '@/common/utilities/sort';
import { keyBy } from '@/common/utility';
import DustDataGrid, {
  GRID_ROW_HIGHLIGHT,
} from '@/components/Library/DustDataGrid';
import { dustedThingColDef } from '@/components/Library/sharedThingColDefs';
import useRequest from '@/services/requests/useRequest';

import { RelationshipGridTreeDataGroupingCell } from './RelationshipGridTreeDataGroupingCell';
import useHierarchyLazyLoader from './useThingChildrenLazyLoader';

type Props = {
  thing: Thing;
};

const groupingColDef: GridGroupingColDefOverride<ThingChildrenHierarchyRow> = {
  headerName: 'Title',
  sortable: true,
  minWidth: 100,
  flex: 1.5, // allow col to expand at greater rate
  hideDescendantCount: true,
  /** Value getter for sort */
  valueGetter: (params) => params.row.title,
  // Need to compare titles not the row uuids
  sortComparator: (a, b) => alphaSort(a, b),
  renderCell: (params) => (
    <RelationshipGridTreeDataGroupingCell
      id={params.id}
      field={params.field}
      rowNode={params.rowNode}
      row={params.row}
    />
  ),
};

export default function ThingRelationshipsTable({ thing }: Props) {
  const navigate = useNavigate();
  const { thingsApi } = useRequest();

  const initialThingRows = useMemo(
    () => makeInitialHierarchyRows(thing),
    [thing],
  );

  const { thingTypes = [] } = useThingTypesData({ fetchAll: true });

  const apiRef = useGridApiRef();
  useHierarchyLazyLoader({
    apiRef,
    fetchChildrenRows: async (current: ThingChildrenHierarchyRow, allRows) => {
      const thingRes = await thingsApi.getThing({
        uuid: current.uuid,
        fields: ['children'],
      });
      if (thingRes.error) return [];
      return thingChildrenToHierarchyRows(thingRes.data, allRows);
    },
  });

  const handleRowClick = useCallback(
    ({ row }: GridRowParams) => {
      if (thing.uuid !== row.uuid) {
        navigate(ROUTES.THING(row.uuid));
      }
    },
    [navigate, thing],
  );

  const columns = useMemo((): GridColDef<ThingChildrenHierarchyRow>[] => {
    const thingTypesMap = keyBy(thingTypes, 'uuid');
    return [
      { ...dustedThingColDef } as GridColDef<ThingChildrenHierarchyRow>,
      makeMixedThingTypeLookupColDef(thingTypesMap),
      {
        field: 'relationType',
        headerName: 'Relationship',
        minWidth: 100,
        maxWidth: 150,
        flex: 1,
      },
    ];
  }, [thingTypes]);

  return (
    <DustDataGrid
      apiRef={apiRef}
      treeData
      getTreeDataPath={(row) => row.hierarchy}
      defaultGroupingExpansionDepth={thing.hasParent ? 2 : 1}
      altTheme
      getRowClassName={(params) => {
        // Highlight the current thing row
        if (params.row.relationType === getRelationNameForLevel(0)) {
          return GRID_ROW_HIGHLIGHT;
        }
        return '';
      }}
      initialState={{
        sorting: {
          /** Sort the title/grouping field asc initially */
          sortModel: [{ field: GRID_TREE_DATA_GROUPING_FIELD, sort: 'asc' }],
        },
      }}
      columns={columns}
      onRowClick={handleRowClick}
      rows={initialThingRows}
      groupingColDef={groupingColDef}
    />
  );
}
