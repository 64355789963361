import React from 'react';

import { MenuItem, SelectChangeEvent } from '@mui/material';
import {
  GridColumns,
  GridFeatureMode,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';

import { Mixpanel } from '@/mPanel';

type SortOption = {
  label: string;
  column: string;
  direction: GridSortDirection;
  params: { [x: string]: any };
  default?: boolean;
};

type SortConfig = {
  trackingEvent?: string;
};

export type SortOptions = { [x: string]: SortOption };

function optionToTableModel<T extends SortOptions>(
  key: keyof T,
  sortOptions: T,
) {
  return {
    field: sortOptions[key].column,
    sort: sortOptions[key].direction,
  };
}

/** Datagrid sort hook that links with data fetch hooks */
function useSort<SortT extends SortOptions>(
  sortAtom: RecoilState<keyof SortT & string>,
  sortOptions: SortT,
  config: SortConfig = {},
) {
  const [selectedSort, setSelectedSort] = useRecoilState(sortAtom);
  const resetSort = useResetRecoilState(sortAtom);

  const track = React.useCallback(
    ({ origin, sortBy }: { origin: string; sortBy: keyof SortT }) => {
      if (!config.trackingEvent) return;
      Mixpanel.track(config.trackingEvent, {
        'Sort Using': origin,
        'Sort By': sortOptions[sortBy]?.label ?? 'Unknown Column',
      });
    },
    [config.trackingEvent, sortOptions],
  );

  const tableSortProps: {
    sortingMode: GridFeatureMode;
    onSortModelChange: (e: GridSortModel) => void;
    sortModel: GridSortItem[];
  } = React.useMemo(
    () => ({
      sortingMode: 'server',
      onSortModelChange: (fields) => {
        const key = fields?.[0]
          ? Object.keys(sortOptions).find(
              (k) =>
                sortOptions[k].column === fields[0].field &&
                sortOptions[k].direction === fields[0].sort,
            )
          : selectedSort;
        if (!key) throw new Error('Missing sort model key');
        track({ origin: 'Column Header', sortBy: key });
        setSelectedSort(key);
      },
      sortModel: [optionToTableModel(selectedSort, sortOptions)],
    }),
    [sortOptions, selectedSort, setSelectedSort, track],
  );

  const sortRequestParams = React.useMemo(
    () => sortOptions[selectedSort].params as ValueOf<SortT>['params'],
    [sortOptions, selectedSort],
  );

  const sortMenuItems = React.useMemo(
    () =>
      Object.keys(sortOptions).map((key) => (
        <MenuItem key={key} value={key}>
          {sortOptions[key].label}
        </MenuItem>
      )),
    [sortOptions],
  );

  const sortSelectProps = React.useMemo(
    () => ({
      onChange: (evt: SelectChangeEvent) => {
        setSelectedSort(evt.target.value);
        track({
          origin: 'Filter',
          sortBy: evt.target.value,
        });
      },
      value: selectedSort,
      children: sortMenuItems,
    }),
    [setSelectedSort, selectedSort, sortMenuItems, track],
  );

  const applyColumnSortProps = React.useCallback(
    <T extends GridValidRowModel>(
      columns: GridColumns<T>,
    ): GridColumns<T & { sortable?: boolean }> =>
      columns.map((column) => ({
        sortable: Object.values(sortOptions).some(
          (opt) => opt.column === column.field,
        ),
        ...column,
      })),
    [sortOptions],
  );

  return {
    tableSortProps,
    applyColumnSortProps,
    sortRequestParams,
    sortSelectProps,
    resetSort,
    selectedSort,
  };
}

export default useSort;
