import { MenuItem, SelectChangeEvent } from '@mui/material';

import {
  USER_ACCOUNT_STATUS,
  USER_ACCOUNT_STATUS_LABELS,
} from '@/common/entities/users/constants';
import useActiveUser from '@/common/entities/users/useActiveUser';
import DustSelect from '@/components/Library/DustSelect';
import useRequest from '@/services/requests/useRequest';

type Props = {
  user: User;
};

export default function UserStatusDropdown({ user }: Props) {
  const { activeUser } = useActiveUser({});
  const { usersApi } = useRequest();

  const handleChange = async (
    event: SelectChangeEvent<'ACTIVE' | 'INACTIVE' | 'PASSWORD_RESET'>,
  ) => {
    if (event.target.value === USER_ACCOUNT_STATUS.PASSWORD_RESET) {
      return usersApi.resetUserPassword(user.sub);
    }

    const isActive = event.target.value === USER_ACCOUNT_STATUS.ACTIVE;

    return usersApi.updateUser({
      sub: user.sub,
      enabled: isActive,
    });
  };

  const renderSelect = (value: keyof typeof USER_ACCOUNT_STATUS) =>
    value === USER_ACCOUNT_STATUS.PASSWORD_RESET
      ? 'Pending'
      : USER_ACCOUNT_STATUS_LABELS[value];

  return (
    user && (
      <DustSelect
        label=""
        disabled={user.sub === activeUser?.sub}
        onChange={handleChange}
        renderValue={(value) => renderSelect(value)}
        sx={{
          maxWidth: '10rem',
          padding: '0',
          '& fieldset': { top: '-10px' },
        }}
        value={user.userAccountStatus}
      >
        <MenuItem
          disabled={
            user.userAccountStatus === USER_ACCOUNT_STATUS.PASSWORD_RESET
          }
          value={USER_ACCOUNT_STATUS.ACTIVE}
        >
          {USER_ACCOUNT_STATUS_LABELS.ACTIVE}
        </MenuItem>
        <MenuItem value={USER_ACCOUNT_STATUS.INACTIVE}>
          {USER_ACCOUNT_STATUS_LABELS.INACTIVE}
        </MenuItem>
        <MenuItem
          disabled={user.userAccountStatus === USER_ACCOUNT_STATUS.INACTIVE}
          value={USER_ACCOUNT_STATUS.PASSWORD_RESET}
        >
          {USER_ACCOUNT_STATUS_LABELS.PASSWORD_RESET}
        </MenuItem>
      </DustSelect>
    )
  );
}
