import { useMemo, useRef } from 'react';

import DustModal from '@/components/Library/DustModal';

import styles from './styles.module.css';

type Props = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  thingTitle: string;
  /** Titles Used to maintain a title lookup for any children the user may see that we may need to show confirmation information for. */
  observeChildrenThingTitles: (Thing | ThingRelationEntry)[];
  childUuidsToAdd: Array<string>;
  childUuidsToRemove: Array<string>;
};

export default function ConfirmEditChildrenDialog({
  open,
  onConfirm,
  onCancel,
  thingTitle,
  observeChildrenThingTitles,
  childUuidsToAdd,
  childUuidsToRemove,
}: Props) {
  // For dialog changes table where the complete list of selected items might be filtered out
  const childrenTitlesLookupRef = useRef(new Map());
  const childrenTitlesLookup = useMemo(() => {
    // useMemo - avoid meaningless render and allow titleMapLookup to be used immediately
    observeChildrenThingTitles.forEach((t) => {
      childrenTitlesLookupRef.current.set(t.uuid, t.title);
    });
    return childrenTitlesLookupRef.current;
  }, [observeChildrenThingTitles]);

  const childrenWithParents = useMemo(
    () =>
      observeChildrenThingTitles
        .filter(
          (child) =>
            (child as Partial<Thing>).hasParent &&
            childUuidsToAdd.includes(child.uuid),
        )
        .map((c) => c.uuid),
    [observeChildrenThingTitles, childUuidsToAdd],
  );

  return (
    <DustModal
      open={open}
      onClose={onCancel}
      title="Are you Sure?"
      footerProps={{
        submitLabel: 'Confirm Changes',
        onSubmit: onConfirm,
        cancelLabel: 'Back',
        onCancel,
      }}
    >
      <>
        <p>
          You&apos;re about to make the following changes to the children of{' '}
          <strong>{thingTitle}</strong>:
        </p>
        <div className={styles.confirmChildrenTable}>
          <div>
            <h2>Children Added:</h2>
            {childUuidsToAdd.length > 0 ? (
              <ul>
                {childUuidsToAdd.map((id) => (
                  <li key={id}>
                    {childrenTitlesLookup.get(id)}
                    {childrenWithParents.includes(id) && '*'}
                  </li>
                ))}
              </ul>
            ) : (
              <p>None</p>
            )}
          </div>
          <div>
            <h2>Children Removed:</h2>
            {childUuidsToRemove.length > 0 ? (
              <ul>
                {childUuidsToRemove.map((id) => (
                  <li key={id}>{childrenTitlesLookup.get(id)}</li>
                ))}
              </ul>
            ) : (
              <p>None</p>
            )}
          </div>
        </div>
        {childrenWithParents.length > 0 && (
          <div className={styles.parentWarn}>
            <p>
              * these children already have a parent. The previous parent will
              be removed and the children will be added to{' '}
              <strong>{thingTitle}</strong>
            </p>
          </div>
        )}
      </>
    </DustModal>
  );
}
