/**
 * Highlights a toggle button when the view matches its key
 *
 * @param   {string} view current view to compare agains
 * @param   {key}    key  button key
 * @returns {object}
 */
export const toggleButtonStyle = (view, key) =>
  view !== key
    ? {
        color: 'var(--medium-grey)',
        borderColor: 'var(--medium-grey)',
        padding: '.5rem',
      }
    : {
        padding: '.5rem',
      };
