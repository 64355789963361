import { useLocation, useParams } from 'react-router-dom';

import useCatalogData from '@/common/entities/catalogs/useCatalogData';
import useThingData from '@/common/entities/things/useThingData';
import { routeTitles, UUID_TYPES } from '@/common/routes';

export default function PageTitle() {
  const location = useLocation();

  const { title, schema } = routeTitles(location.pathname);
  const { thingId, catalogId } = useParams();

  const needsCatalogData = !!schema?.includes(UUID_TYPES.CATALOG);
  const needsThingData = !!schema?.includes(UUID_TYPES.THING) && !!thingId;

  const { thing, isLoading: thingLoading } = useThingData({
    thingUuid: thingId ?? '',
    enabled: needsThingData,
  });

  const { catalog, isLoading: catalogLoading } = useCatalogData({
    catalogUuid: catalogId,
    enabled: needsCatalogData,
  });

  const queriedData = {
    catalog,
    thing,
  };

  const titleData = schema?.map((key) => queriedData[key]);
  // Wait until all data fetches are completed so undefined title is not shown
  const isLoading =
    (catalogLoading && needsCatalogData) || (thingLoading && needsThingData);

  if (isLoading) {
    return null;
  }

  const titleText = title && title(titleData);

  return titleText ? <h1 className="mb-1 h1">{titleText}</h1> : null;
}
