import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 5 * 60 * 1000;

type HookProps = {
  catalogUuid: string;
  enabled?: boolean;
};

/**
 * Get one catalog by uuid
 */
export default function useCatalogData({
  catalogUuid,
  enabled = true,
}: HookProps) {
  const { catalogsApi, QUERY_KEYS } = useRequest();

  const { data, isError, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.CATALOG(catalogUuid),
    () => catalogsApi.get({ uuid: catalogUuid }),
    { enabled, staleTime: STALE_TIME },
  );

  return {
    catalog: data && !data.error ? data.data : undefined,
    isError: !!data?.error || isError,
    isLoading,
    isSuccess,
  };
}
