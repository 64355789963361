import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Button, ButtonProps } from '@mui/material';

import styles from './Components.module.css';
import DustLoadingButton from './DustLoadingButton';

type Props = {
  onPrevious?: () => void;
  onCancel?: () => void;
  onSubmit?: () => Promise<void> | void;
  prevLabel?: string;
  cancelLabel?: string;
  submitLabel?: string;
  submitAriaLabel?: string;
  // Accessory props
  submitFormId?: string | null;
  disabled?: boolean;
  cancelDisabled?: boolean;
  loading?: boolean;
  submitColor?: ButtonProps['color'];
  actions?: JSX.Element | null;
};

export default function DustStepperFooter({
  onPrevious,
  onCancel,
  onSubmit,
  prevLabel = 'Previous',
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
  submitFormId = null,
  submitAriaLabel = '',
  disabled = false,
  cancelDisabled = false,
  loading = false,
  submitColor = 'primary',
  actions = null,
}: Props) {
  return (
    <div className={styles.stepperFooter}>
      {onPrevious && (
        <Button
          onClick={onPrevious}
          startIcon={<ArrowBackIosIcon />}
          sx={{ textTransform: 'none' }}
        >
          {prevLabel}
        </Button>
      )}
      <div className="flex-1" />
      {onCancel && (
        <Button
          onClick={onCancel}
          disabled={cancelDisabled}
          sx={{ textTransform: 'none' }}
        >
          {cancelLabel}
        </Button>
      )}
      {actions}
      {(onSubmit || submitFormId) && (
        <DustLoadingButton
          color={submitColor}
          disabled={disabled || loading}
          form={submitFormId || undefined}
          loading={loading}
          onClick={onSubmit}
          sx={{ marginLeft: 4 }}
          type={submitFormId ? 'submit' : 'button'}
          variant="contained"
          aria-label={submitAriaLabel}
        >
          {submitLabel}
        </DustLoadingButton>
      )}
    </div>
  );
}
