import { useNavigate } from 'react-router-dom';

export default function useNavigateWithParams() {
  const navigate = useNavigate();

  // Save the current path with URL params to allow programmatic navigation back
  return (path: string) => {
    navigate(path, {
      state: { previous: window.location.pathname + window.location.search },
    });
  };
}
