import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import CatalogListSelector, {
  Props,
} from '@/components/Composed/CatalogListSelector/CatalogListSelector';
import { selectedCatalogAtom } from '@/state/atoms/catalog';

/**
 * Select the catalog that will be saved to recoil
 * This catalog can then be used across the app as the default
 */
export default function CatalogListRecoilSelector({
  onSelectCatalog,
  ...rest
}: Omit<Props, 'onSelectCatalog' | 'currentCatalog'> & {
  onSelectCatalog?: Props['onSelectCatalog'];
}) {
  const [currentCatalog, setCatalog] = useRecoilState(selectedCatalogAtom);

  const handleSelectCatalog = useCallback(
    (catalog: Catalog | null, prevCatalog: Catalog | null) => {
      setCatalog(catalog);
      onSelectCatalog?.(catalog, prevCatalog);
    },
    [setCatalog, onSelectCatalog],
  );

  return (
    <CatalogListSelector
      currentCatalog={currentCatalog}
      onSelectCatalog={handleSelectCatalog}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}
