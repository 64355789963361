import { useState } from 'react';

import PropTypes from 'prop-types';

import { combineClass } from '@/components/Library/utility';

import styles from './EditTable.module.css';
import DustTooltip from '../DustTooltip';

function EditTableCell({
  row,
  setCell,
  field,
  onFocus,
  hideCell = false,
  error,
  style,
  rowIdField = 'id',
}) {
  const [value, setValue] = useState(String(row[field] ?? ''));

  const classes = combineClass(
    styles.rowCell,
    hideCell && styles.hideCell,
    error.status === 'error' && styles.error,
    error.status === 'warning' && styles.warning,
  );

  return (
    <div className={classes} style={style}>
      {error.message && (
        <DustTooltip arrow status={error.status} title={error.message}>
          <input
            onBlur={() => setCell(field, row[rowIdField], value.trim())}
            onChange={(evt) => setValue(evt.target.value)}
            onFocus={onFocus}
            type="text"
            value={value}
          />
        </DustTooltip>
      )}
      {!error.message && (
        <input
          onBlur={() => setCell(field, row[rowIdField], value.trim())}
          onChange={(evt) => setValue(evt.target.value)}
          onFocus={onFocus}
          type="text"
          value={value}
        />
      )}
    </div>
  );
}

EditTableCell.propTypes = {
  row: PropTypes.object,
  setCell: PropTypes.func,
  field: PropTypes.string,
  onFocus: PropTypes.func,
  hideCell: PropTypes.bool,
  error: PropTypes.object,
  rowIdField: PropTypes.string,
};

export default EditTableCell;
