import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import styles from './AntiTamperSaveModal.module.css';

type AntiTamperSaveModalProps = {
  open: boolean;
  onCancel: () => void;
  onSave: () => void;
};

export default function AntiTamperSaveModal({
  onCancel,
  onSave,
  open,
}: AntiTamperSaveModalProps) {
  return (
    <Dialog open={open} aria-labelledby="anti-tamper-save-analysis-dialog">
      <DialogTitle component="h3" className={styles.modalTitle}>
        Complete Analysis?
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="p" className={styles.modalContentText}>
          Do you want to complete analysis of this tag? Select “Complete
          Analysis” to finish or “Cancel” to go back to analysis.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.modalAction}>
        <Button
          onClick={onCancel}
          variant="contained"
          className={`${styles.returnButton} ${styles.modalButton}`}
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          variant="contained"
          className={styles.modalButton}
        >
          Complete Analysis
        </Button>
      </DialogActions>
    </Dialog>
  );
}
