import { nanoid } from 'nanoid';
import Papa from 'papaparse';

/** Use to help assert exhaustive logic based on type information at runtime and compile time */
export function assertNever(x: never): never {
  throw new Error(
    `Value ${x} was supposed to be "never", this code should be unreachable`,
  );
}

export function assertNonNull<T>(val: T): NonNullable<T> {
  if (val == null) {
    throw new Error('assertNonNull received a null|undefined value');
  }
  return val;
}

/**
 * Remove the file extension from a string
 */
export function removeFileExtension(filename: string): string {
  return filename ? filename.replace(/\.[^/.]+$/, '') : '';
}

/**
 * Format a bytesize to a readable string
 */
export function readableFileSize(size: number): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

/**
 * Convert a file into a url
 *
 */
export function urlFromFile(file: File): Promise<string> {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target) throw new Error('Invalid file load');
      return res(e.target.result as string /* we're using readAsDataURL */);
    };
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });
}

/**
 * Remove null, undefined, empty string and empty array entries, which prevents
 * sending empty values or arrays to the server
 */
export function removeNil<T extends Record<string, T[keyof T]>>(
  payload: T,
): Partial<T> {
  const filtered: Partial<T> = {};
  Object.entries<T[keyof T]>(payload).forEach(([key, field]) => {
    const isNull =
      field === null ||
      field === undefined ||
      (Array.isArray(field) && field.length < 1) ||
      field === '';
    if (!isNull) {
      filtered[key as keyof T] = field;
    }
  });

  return filtered;
}

/** Checks that an object is a valid object (.toString === '[object Object]',
 * filters out arrays and null */
export function isObject(
  maybeObj: unknown,
): maybeObj is Record<string, unknown> {
  return Object.prototype.toString.call(maybeObj) === '[object Object]';
}

/**
 * Return an array of objects with a name and value from a key-value object
 */
export function objToNameValueList(
  obj: object,
): { name: string; value: any }[] {
  return Object.entries(obj).map(([name, value]) => ({ name, value }));
}

type ParseCsvOptions = {
  /** Option to add a UID to each row */
  addId: boolean;
};

/**
 * Parse a CSV file with a header row
 *
 * @param file CSV file to parse
 */
export function parseCSV(
  file: File,
  { addId = false }: ParseCsvOptions,
): Promise<{
  data: string[][];
  header: string[];
}> {
  return new Promise((resolve) => {
    const results: string[][] = [];
    Papa.parse<string[]>(file, {
      delimiter: ',',
      skipEmptyLines: true,
      dynamicTyping: false,
      step: (row) => results.push(addId ? [nanoid(), ...row.data] : row.data),
      complete: () => {
        const header = results[0].map((e, idx) => e || `Field ${idx}`);
        if (addId) {
          header[0] = 'rowUID';
        }

        const data = results.slice(1);
        resolve({
          data,
          header,
        });
      },
    });
  });
}

/**
 * Take a 2D array and an optional header row to create a CSV string
 */
export function createCSV<B extends boolean = false>(
  data: any[][],
  headerRow: string[] | null = null,
  /** create a file, otherwise, creates a csv string */
  createFile: B = false as B,
): B extends false ? string : Blob {
  const fileData = [...data];
  if (headerRow) {
    fileData.unshift(headerRow);
  }
  const csvString = Papa.unparse(fileData);

  return (
    createFile
      ? new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
      : csvString
  ) as B extends false ? string : Blob; // Type cast needed here
}

export function downloadBlob(blob: Blob, name = 'file.csv') {
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob)
    ((window.navigator as any).msSaveOrOpenBlob as (b: Blob) => void)(blob);

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = data;
  link.download = name;

  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}

/** original code from https://dmitripavlutin.com/timeout-fetch-request/  */
export async function downloadWithTimeout(
  resource: string,
  name: string,
  options: RequestInit & { timeout?: number } = {},
): Promise<{ error: boolean }> {
  const timeout = options?.timeout ?? 8000;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  let error = false;
  try {
    const res = await fetch(resource, {
      ...options,
      cache: 'no-store',
      signal: controller.signal,
    });
    downloadBlob(await res.blob(), name);
  } catch {
    error = true;
  }
  clearTimeout(id);
  return { error };
}

/**
 * Download a file from a URL
 */
export async function downloadFileUrl(
  url: string,
  name?: string,
): Promise<{ error: boolean }> {
  try {
    const res = await fetch(url, { cache: 'no-store' });
    downloadBlob(await res.blob(), name);
    return { error: false };
  } catch {
    return { error: true };
  }
}

export async function fileFromUrl(
  url: string,
  type: string,
  name: string,
): Promise<File | null> {
  if (!type)
    console.warn(`Missing MIME type for file creation. Filename: ${name}`);

  try {
    const res = await fetch(url, { cache: 'no-store' });
    const blob = await res.blob();
    return new File([blob], name, { type });
  } catch {
    console.warn(`Could not fetch fileFromUrl - ${url}`);
    return null;
  }
}

/**
 * Check if an index is within a range
 */
export const idxInRange = (idx: number, range: [number, number]): boolean =>
  idx >= range[0] && idx < range[1];

/**
 * Get the number of pixels for a given rem value
 */
export const remToPx = (rem: number): number =>
  rem * parseFloat(getComputedStyle(document.documentElement).fontSize);

export function normalizeString(str: string | null | undefined) {
  return str ? str.trim().toLowerCase() : '';
}

/**
 * Check if two string match ignoring case and extra whitespace
 */
export function isWordMatch(wordOne: string, wordTwo: string): boolean {
  if (typeof wordOne !== 'string' || typeof wordTwo !== 'string') {
    console.error('Entries are not strings');
    return false;
  }
  return normalizeString(wordOne) === normalizeString(wordTwo);
}

/**
 * Check if an array contains a string ignoring case and whitespace
 */
export const includesWordMatch = (
  arr: readonly string[],
  word: string,
): boolean => arr.some((e) => isWordMatch(e, word));

/**
 * Capitalize first letter of string
 */
export function capitalizeFirst(
  [first, ...rest]: string,
  locale = navigator.language,
): string {
  return [first.toLocaleUpperCase(locale), ...rest].join('');
}

/**
 * Turns "someWord" into "Some Word"
 */
export function camelToTitleCase(input: string): string {
  // Stolen from https://stackoverflow.com/a/7225450
  const spaced = input.replace(/([A-Z])/g, ' $1');
  return spaced.charAt(0).toUpperCase() + spaced.slice(1);
}

/**
 * Covers the version comparison that we expect to use in DICE.
 *
 * See tests.
 *
 * Usage: isNumericVersionGreaterThan('0.3.1', '0.3.0') === true
 * Usage: isNumericVersionGreaterThan('0.3.0', '0.3.0') === false
 */
export function isNumericVersionGreaterThan(left: string, right: string) {
  const splitLeft = left.split('.').map((x) => Number(x));
  const splitRight = right.split('.').map((x) => Number(x));
  for (let i = 0; i < Math.max(splitLeft.length, splitRight.length); i += 1) {
    if (splitLeft[i] > splitRight[i]) return true;
    if (splitLeft[i] < splitRight[i]) return false;
  }
  return false;
}

export const TYPES = {
  OBJECT: 'object',
  ARRAY: 'array',
  DATE: 'date',
  NUMBER: 'number',
  STRING: 'string',
  BOOL: 'boolean',
  SET: 'set',
  MAP: 'map',
  FUNCTION: 'function',
  NULL: 'null',
  UNDEFINED: 'undefined',
};

/**
 * Return the type of a variable
 */
export function getType(obj: any): string {
  const jsType = Object.prototype.toString.call(obj);
  return assertNonNull(jsType.match(/\s([a-zA-Z]+)/))[1].toLowerCase();
}

/**
 * Check if the provided key exists in the object
 *
 * @param object - the object to check for membership in
 * @param key    - the key to check the object for
 */
export function has<T extends Record<string, unknown>>(
  object: T,
  key: string | number | symbol,
): key is keyof T {
  return Object.prototype.hasOwnProperty.call(object, key);
}

/**
 * Inspired by Lodash, accepts an array and a field or key
 * extractor function, and returns an object with array
 * members as the values.
 */
export function keyBy<T extends object>(
  array: T[],
  key: keyof T | ((element: T) => string | number),
): { [key: string | number]: T } {
  const result: any = {};
  if (typeof key === `function`) {
    array.forEach((item) => {
      result[key(item)] = item;
    });
  } else {
    array.forEach((item) => {
      result[item[key]] = item;
    });
  }
  return result as { [key: string | number]: T };
}

/**
 * Returns any elements in `a` that are not in 'b'
 */
export function arrayDiff<T>(a: Array<T>, b: Array<T>): Array<T> {
  return a.filter((elementA) => !b.includes(elementA));
}

export function chunkArray<T>(
  array: Array<T>,
  chunkSize: number,
): Array<Array<T>> {
  let index = 0;
  const results = [];
  if (chunkSize < 1) throw new Error('Chunk size must be at least 1');
  while (index < array.length) {
    results.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }
  return results;
}

// ---------------------------------------------------------------------------
// Typesafe helpers

/** Type safe include */
export function includes<T>(arr: readonly T[], x: T): boolean {
  return arr.includes(x);
}

/** Get the keys of an object. NOTE: This assumes no additional properties at runtime */
export function keysOf<T>(obj: T) {
  return obj ? (Object.keys(obj) as (keyof T)[]) : [];
}

/** Check that a value is not null or undefined */
export function isDefined<T>(argument: T | undefined | null): argument is T {
  return argument !== undefined && argument !== null;
}

/**
 * Returns an array with no duplicate values
 */
export function arrayUnique<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}

// -----------------------------------------------------------------------------
// Unsorted helpers

/** Polls a promise function with exponential backoff until a condition returns true */
export function pollUntil<T>(
  fn: () => Promise<T>,
  stopCondition: (val: T) => boolean,
) {
  let retryDelayMs = 1_000;
  const backoffFactor = 1.6;
  let cancelled = false;

  async function startLoop() {
    /* eslint-disable no-await-in-loop */
    while (!cancelled) {
      // eslint-disable no-await-in-loop
      const tryResult = await fn();
      if (cancelled) return null;
      if (stopCondition(tryResult)) {
        return tryResult;
      }
      const delayMs = retryDelayMs;
      await new Promise((resolve) => {
        setTimeout(resolve, delayMs);
      });
      retryDelayMs *= backoffFactor;
    }
    return null;
    /* eslint-enable no-await-in-loop */
  }

  return {
    cancel: () => {
      cancelled = true;
    },
    promise: startLoop(),
  };
}
