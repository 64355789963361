import { Box, Button, Dialog, LinearProgress, Typography } from '@mui/material';

import LoadingGIF from '@/assets/images/Anti-Tamper-loading.gif';
import { AnalysisLoadingState } from '@/common/entities/antiTamper/useAntiTamper';

import styles from './AntiTamperLoadingModal.module.css';

type AntiTamperLoadingModalProps = {
  open: boolean;
  onHandleCancel: () => void;
  antiTamperLoadingProgressState: AnalysisLoadingState;
  progress: number;
};

function AntiTamperLoadingModal({
  onHandleCancel,
  open,
  antiTamperLoadingProgressState,
  progress,
}: AntiTamperLoadingModalProps) {
  return (
    <Dialog
      open={open}
      aria-labelledby="anti-tamper-loading-dialog"
      sx={{
        '& .MuiDialog-paper': {
          overflow: 'hidden',
          width: 400,
          height: 350,
          padding: '1rem 1rem 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundImage: `url(${LoadingGIF})`,
          backgroundPosition: 'center',
          backgroundSize: '75%',
        },
      }}
    >
      <Box className={styles.dialogHeader}>
        <Typography variant="h5">Analyzing Scan</Typography>
      </Box>
      <Box className={styles.bottom}>
        <Box className={styles.loadingProgress}>
          <Typography className={styles.loadingLabel}>
            {antiTamperLoadingProgressState.label}
          </Typography>
          <LinearProgress
            sx={{
              height: 10,
            }}
            variant="determinate"
            value={progress}
          />
        </Box>
        <Box className={styles.action}>
          <Button onClick={onHandleCancel} color="primary" variant="text">
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

export default AntiTamperLoadingModal;
