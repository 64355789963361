import { ComponentProps, Dispatch, SetStateAction } from 'react';

import useToasts from '@/common/hooks/useToasts';
import Scanner, {
  DiceScanMode,
  SCAN_MODES,
} from '@/components/Composed/Scanner/Scanner';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

import classes from './ScanModal.module.css';

export { SCAN_MODES };

const titles: Record<DiceScanMode, string | null> = {
  [SCAN_MODES.BIND]: 'Bind Thing to DUST',
  [SCAN_MODES.VERIFY]: 'Verify DUST Tag',
  [SCAN_MODES.ANTITAMPER]: 'Tamper Analysis',
  [SCAN_MODES.IDENTIFY]: null,
};

const toasts: Record<DiceScanMode, string | null> = {
  [SCAN_MODES.BIND]: 'Thing successfully bound to DUST',
  [SCAN_MODES.VERIFY]: 'DUST tag verified successfully',
  [SCAN_MODES.ANTITAMPER]: 'Beginning tamper analysis...',
  [SCAN_MODES.IDENTIFY]: null,
};

type Props = {
  thing?: Thing;
  mode: DiceScanMode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  /** Indicates that a scan workflow was successful */
  onComplete: () => void;
  /** Mirrors DustModal */
  onClose: () => void;
  verifyMessage?: JSX.Element | string;
  footerProps?: ComponentProps<typeof DustModal>['footerProps'];
};

export default function ScanModal({
  thing,
  mode,
  open,
  setOpen,
  onComplete,
  onClose = () => null,
  footerProps,
  verifyMessage,
}: Props) {
  const { invalidate } = useRequest();
  const { addToast } = useToasts();

  // TODO: Type scanner events
  const handleSuccess = (evt: any) => {
    if (evt.action === mode) {
      invalidate.things();
      onComplete();
      setOpen(false);
      const toast = toasts[mode];
      if (toast) addToast(toast, 'success');
    }
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <DustModal
      footerProps={footerProps}
      onClose={handleClose}
      open={open}
      title={titles[mode] ?? undefined}
      bodyClass={classes.modalBody}
    >
      <div
        style={{
          height: 496,
          paddingTop: '0.5rem',
        }}
      >
        {!verifyMessage && thing && (
          <Scanner
            catalogUuid={thing.catalogUuid}
            mode={mode}
            onSuccess={handleSuccess}
            showPrevious={false}
            thingUuid={thing.uuid}
          />
        )}
        {verifyMessage}
      </div>
    </DustModal>
  );
}
