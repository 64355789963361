// Note on times - This determines how long after the user has been active
// should an access_token about-to-expire automatically self-renew, see
// SessionDialog.
//
// Access tokens expire after 5 minutes, so with:
// - activity threshold of 3.25 minutes
// - access token exiration time of 5 minutes
// - access token "expiring" notification time of 1.5 min
// , a user could see the dialog anywhere between 3.25 minutes and 6.75 minutes (3.25 + 5 - 1.5) since their last activity, average of 5 minutes.
//
// Algebra:
//
// (threshold * 2 + exp - exp_notify) / 2 = average time
//
// Formula for ACTIVITY_THRESHOLD_MS is: desired average time - (exp - exp_notify) / 2
//
// Example: (10 - (5 - 1.5) / 2) = 8.25 minutes
const ACTIVITY_THRESHOLD_MS = 8.25 * 60 * 1000;

class AppActivityTracker {
  lastActivityTimestampMs = Date.now();

  /** Kick the watchdog to indicate the user is still active */
  kick() {
    this.lastActivityTimestampMs = Date.now();
  }

  isActive() {
    return Date.now() - this.lastActivityTimestampMs < ACTIVITY_THRESHOLD_MS;
  }
}

const appActivityTracker = new AppActivityTracker();

// Use app click and keypress to indicate activity
// 'click' handles primary input, which includes mobile finger press.
window.addEventListener('click', () => {
  appActivityTracker.kick();
});
window.addEventListener('keydown', () => {
  appActivityTracker.kick();
});

export default appActivityTracker;
