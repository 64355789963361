import { AtomEffect } from 'recoil';
import { isSchema, AnySchema } from 'yup';
import Lazy from 'yup/lib/Lazy';

import { isObject } from '@/common/utility';

import {
  activeUserIdState,
  activeUserAndOrgIdState,
  StorageKeyState,
} from './storageKeyStates';

/**
 * Map recoil item to local storage, use sync validators only
 */
export function localStorageEffect(
  key: string,
  yupSchema: AnySchema | Lazy<AnySchema>,
): AtomEffect<any> {
  if (!isSchema(yupSchema)) {
    throw new Error(
      `A yup schema is required for ensuring safe usage of localStorage["${key}"].`,
    );
  }

  return ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      // Attempt parsing and delete the value if it fails
      try {
        const parsed = JSON.parse(savedValue);
        try {
          yupSchema.validateSync(parsed);
          setSelf(parsed);
        } catch {
          console.warn(
            `Validation failed for localStorage["${key}"], removing.`,
          );
          localStorage.removeItem(key);
        }
      } catch {
        console.warn(`Failed to parse localStorage["${key}"], removing.`);
        localStorage.removeItem(key);
      }
    }

    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        // ↓ Will throw if invalid, make sure we're only saving valid values
        yupSchema.validateSync(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };
}

const NO_ARG = Symbol('No arg provided');

/** (This curry-ed function creates recoil effects) */
function makeKeyedLocalStorageEffect(storageKeyState: StorageKeyState) {
  /**
   * Map recoil item to a value held in an object in localStorage
   * keyed by user (or, and org) id, use sync YUP validators only (no
   * async).
   *
   * This effect uses an active user key subscription object, so the value could potentially be null, and will need to accept that.
   *
   * @param missingValueDefault - If no value has been saved, apply this value
   *                      over the default when the user id is known.
   */
  return function userLocalStorageEffect(
    atomKey: string,
    yupSchema: AnySchema,
    missingValueDefault: any = NO_ARG,
  ): AtomEffect<any> {
    if (!isSchema(yupSchema)) {
      throw new Error(
        `A yup schema is required for ensuring safe usage of localStorage[userId]["${atomKey}"].`,
      );
    }

    /** Reads object from localStorage, or removes and returns empty object on error */
    const readUserSettingsObject = (
      userId: string,
    ): Record<string, unknown> => {
      const userObj = localStorage.getItem(userId);
      if (userObj == null) return {};
      try {
        const parsed = JSON.parse(userObj); // Assuming this is an object
        if (!isObject(parsed)) throw new Error('Unexpected non-object');
        return parsed;
      } catch {
        console.warn(`Failed to parse localStorage[userId], removing.`);
        localStorage.removeItem(userId);
        return {}; // Default empty object;
      }
    };

    const removeAtomValue = (
      userId: string,
      userSettings: Record<string, unknown>,
    ) => {
      const { [atomKey]: removed, ...newUserObj } = userSettings;
      localStorage.setItem(userId, JSON.stringify(newUserObj));
    };

    return ({ setSelf, onSet }) => {
      // Consistent function to handle atom value read logic
      const readAtomValueFromStorage = (userId: string) => {
        const userSettings = readUserSettingsObject(userId);
        if (userSettings != null) {
          // Attempt parsing and delete the value if it fails
          const savedValue = userSettings[atomKey];
          try {
            if (savedValue === undefined) {
              // No value was ever saved
              if (missingValueDefault !== NO_ARG) {
                setSelf(missingValueDefault);
              }
              return;
            }
            yupSchema.validateSync(savedValue);
            setSelf(savedValue);
          } catch {
            console.warn(
              `Validation failed for localStorage[userId]["${atomKey}"], removing value.`,
            );
            removeAtomValue(userId, userSettings);
          }
        }
      };

      // ---- Lifecycle ----

      // Handle load if able, otherwise subscribe.
      const currentUserIdValue = storageKeyState.get();
      if (currentUserIdValue !== null) {
        readAtomValueFromStorage(currentUserIdValue);
      }
      storageKeyState.onChange((userId) => {
        if (userId !== null) readAtomValueFromStorage(userId);
      });

      // Handle atom value changes from app
      onSet((newValue, _, isReset) => {
        const userId = storageKeyState.get();
        if (userId === null) {
          throw new Error(
            "Can't set a userLocalStorageEffect value before active user-id has been set",
          );
        }
        const userSettings = readUserSettingsObject(userId);
        if (isReset) {
          removeAtomValue(userId, userSettings);
        } else {
          // ↓ Will throw if invalid, makes sure at runtime we're only saving valid values
          yupSchema.validateSync(newValue);
          const newUserSettings = { ...userSettings, [atomKey]: newValue };
          localStorage.setItem(userId, JSON.stringify(newUserSettings));
        }
      });
    };
  };
}

export const userLocalStorageEffect =
  makeKeyedLocalStorageEffect(activeUserIdState);
export const userOrgLocalStorageEffect = makeKeyedLocalStorageEffect(
  activeUserAndOrgIdState,
);
