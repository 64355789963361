import { useCallback } from 'react';

import { useRecoilState } from 'recoil';

import { includesWordMatch } from '@/common/utility';
import { catalogColumnsAtom } from '@/state/atoms/catalog';

import { DEFAULT_COLUMNS } from './constants';

const NON_EXPORTED_COLUMNS = ['dusted', 'id', 'thingType', 'images', 'files'];

/**
 * ---------------------------------------------------------------------------
 * ### Handle column selection
 *
 * Each catalog will have an entry with a list of selected column field names
 * Field names include typedUUIDs for string typed fields, field names for
 * standard metadata, and a minimum set of column info for the table
 * Recoil + LocalStorageEffect will make these persistent over browser refreshes.
 *
 * NOTE: display columns can be used to determine fieldFilters
 */
export default function useThingsDisplayColumns({
  catalogUuid,
}: {
  catalogUuid?: string;
}) {
  const [catalogColumns, setCatalogColumns] =
    useRecoilState(catalogColumnsAtom);

  const setDisplayColumns = useCallback(
    (cols: string[]) => {
      setCatalogColumns((prev) => ({
        ...prev,
        [catalogUuid ?? 'account']: cols,
      }));
    },
    [catalogUuid, setCatalogColumns],
  );

  /** Get the column list specific to this catalog (or default) */
  const displayColumns =
    catalogColumns[catalogUuid ?? 'account'] ?? DEFAULT_COLUMNS;

  /** Each displayed typed or untyped metadata column can be filtered */
  const fieldFilters = displayColumns.filter(
    (f) => !includesWordMatch(DEFAULT_COLUMNS, f),
  );

  const exportColumns = displayColumns.filter(
    (col) => !NON_EXPORTED_COLUMNS.includes(col),
  );

  return {
    displayColumns,
    exportColumns,
    setDisplayColumns,
    fieldFilters,
  };
}
