import { SvgIcon, SvgIconProps } from '@mui/material';

function DustImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 5V19H5V5H19ZM11.14 15.73L14.14 11.86L18 17H6L9 13.14L11.14 15.73Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default DustImageIcon;
