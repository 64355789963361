import { useEffect, useState } from 'react';

import Remove from '@mui/icons-material/Remove';
import {
  TextField,
  IconButton,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ACCEPTED_IMAGE_TYPE_LIST } from '@/common/entities/things/constants';
import {
  THING_TYPE_FIELD_TYPES,
  PLACEHOLDER_SCHEMAS,
  PLACEHOLDER_TYPES,
} from '@/common/entities/thingTypes/constants';
import { provideFallbackImage } from '@/common/utilities/images';
import { readableFileSize, urlFromFile } from '@/common/utility';
import DustFileIcon from '@/components/Library/Icons/DustFileIcon';
import DustImageIcon from '@/components/Library/Icons/DustImageIcon';
import DustMultiFileIcon from '@/components/Library/Icons/DustMultiFileIcon';
import DustMultiImageIcon from '@/components/Library/Icons/DustMultiImageIcon';

import styles from './AddEditThingTypes.module.css';

function ThingTypeFileField({
  formik,
  field,
  fieldKey,
  idx,
  file,
  onRemoveFile,
}) {
  const name = field.name ?? '';
  const uuid = field?.uuid;
  const isStatic = field?.value?.type === 'STATIC_MEDIA';

  // TODO: Since static images have a type of 'file' we must also check
  // the file type not just the schema type
  const isImage =
    field.schema.type === THING_TYPE_FIELD_TYPES.IMAGE ||
    field.schema?.items?.type === THING_TYPE_FIELD_TYPES.IMAGE ||
    ACCEPTED_IMAGE_TYPE_LIST.includes(file?.type);

  // Must handle name and schema level errors
  const isError =
    !!formik.errors[fieldKey]?.[idx]?.name || !!formik.errors[fieldKey]?.[idx];

  // Higher level field message
  const fieldMessage =
    typeof formik.errors[fieldKey]?.[idx] === 'string'
      ? formik.errors[fieldKey]?.[idx]
      : Object.values(formik.errors[fieldKey]?.[idx] ?? [''])[0];

  // Individual field message
  const errorMessage =
    (formik.touched[fieldKey]?.[idx]?.name || isStatic) &&
    (formik.errors[fieldKey]?.[idx]?.name || fieldMessage);

  // Get an image url from the file
  const [imageSrc, setImageSrc] = useState(null);
  useEffect(() => {
    const getSrc = async () =>
      setImageSrc(file && isImage ? await urlFromFile(file) : null);
    getSrc();
  }, [file, isImage]);

  const getIcon = () => {
    if (imageSrc) {
      return (
        <Tooltip title="Image Preview">
          <img
            alt="Preview"
            className={styles.imageThumbnail}
            src={imageSrc}
            onError={provideFallbackImage}
          />
        </Tooltip>
      );
    }
    if (isImage) {
      return (
        <Tooltip title={isStatic ? 'Static Image' : 'Image Placeholder'}>
          <span>
            <DustImageIcon
              color="secondary"
              sx={{ margin: '.5rem', marginRight: '1rem' }}
            />
          </span>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={isStatic ? 'Static File' : 'File Placeholder'}>
        <span>
          <DustFileIcon
            color="secondary"
            sx={{ margin: '.5rem', marginRight: '1rem' }}
          />
        </span>
      </Tooltip>
    );
  };

  // weird pattern to avoid nested ternary...
  const placeholderType =
    field.schema.type === THING_TYPE_FIELD_TYPES.ARRAY
      ? (isImage && PLACEHOLDER_TYPES.MULTI_IMAGE) ||
        PLACEHOLDER_TYPES.MULTI_FILE
      : (isImage && PLACEHOLDER_TYPES.SINGLE_IMAGE) ||
        PLACEHOLDER_TYPES.SINGLE_FILE;

  const handlePlaceholderTypeChange = (type, idx) => {
    if (type) {
      formik.setFieldValue(
        `${fieldKey}.${idx}.schema`,
        PLACEHOLDER_SCHEMAS[type],
      );
    }
  };
  return (
    <div className="flex-row items-start mt-1">
      {isStatic && getIcon()}
      <TextField
        error={isError}
        helperText={isError && errorMessage}
        inputProps={{
          autoComplete: uuid.split('-')[0],
          form: {
            autocomplete: 'off',
          },
        }}
        label="File Name*"
        margin="none"
        name={`${fieldKey}.${idx}.name`}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        size="small"
        value={name}
        variant="outlined"
        fullWidth
      />
      <div className="flex-row">
        {isStatic && (
          <div className={styles.fileInfo}>
            {file?.type?.split?.('/')?.[1]?.toUpperCase() ?? ''}
            <br />
            {readableFileSize(file?.size)}
          </div>
        )}
        {!isStatic && (
          <ToggleButtonGroup
            aria-label="file-field-type"
            color="primary"
            onChange={(evt, val) => handlePlaceholderTypeChange(val, idx)}
            size="small"
            sx={{ marginLeft: '.5rem' }}
            value={placeholderType}
            exclusive
          >
            <ToggleButton
              aria-label="single image"
              value={PLACEHOLDER_TYPES.SINGLE_IMAGE}
            >
              <Tooltip title="Single Image">
                <div>
                  <DustImageIcon />
                </div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              aria-label="multiple images"
              value={PLACEHOLDER_TYPES.MULTI_IMAGE}
            >
              <Tooltip title="Multiple Images">
                <div>
                  <DustMultiImageIcon />
                </div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              aria-label="single file"
              value={PLACEHOLDER_TYPES.SINGLE_FILE}
            >
              <Tooltip title="Single File">
                <div>
                  <DustFileIcon />
                </div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              aria-label="multiple files"
              value={PLACEHOLDER_TYPES.MULTI_FILE}
            >
              <Tooltip title="Multiple Files">
                <div>
                  <DustMultiFileIcon />
                </div>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Tooltip enterDelay={400} title="Delete">
          <IconButton
            aria-label="Delete"
            onClick={() => onRemoveFile(idx)}
            sx={{ color: 'var(--error-red)' }}
          >
            <Remove />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}

ThingTypeFileField.propTypes = {
  formik: PropTypes.object,
  field: PropTypes.object,
  fieldKey: PropTypes.string,
  idx: PropTypes.number,
  file: PropTypes.object,
  onRemoveFile: PropTypes.func,
};

export default ThingTypeFileField;
