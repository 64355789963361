import { Box } from '@mui/material';

import styles from './AntiTamperVerifiedSession.module.css';

type AntiTamperVerifiedSessionProps = {
  isLoading: boolean;
  scannedImageContainer: JSX.Element;
  isScanVisible: boolean;
};

export default function AntiTamperVerifiedSession({
  isLoading,
  scannedImageContainer,
  isScanVisible,
}: AntiTamperVerifiedSessionProps) {
  return (
    <Box component="div" className={styles.root}>
      <Box
        component="div"
        className={`${styles.container} ${isLoading && styles.isLoading} ${
          !isScanVisible && styles.whiteScreen
        }`}
      >
        {scannedImageContainer}
      </Box>
    </Box>
  );
}
