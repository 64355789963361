import {
  ApiUser,
  OidcUser,
  UserCredentials,
} from '@/common/entities/users/typedefs';

import { USER_ACCOUNT_STATUS } from './constants';

const getUserAccountStatus = (user: any) => {
  if (!user.enabled) return USER_ACCOUNT_STATUS.INACTIVE;

  return user.requiresReset
    ? USER_ACCOUNT_STATUS.PASSWORD_RESET
    : USER_ACCOUNT_STATUS.ACTIVE;
};

export function userModel(user: OidcUser & Partial<ApiUser>): User {
  const result = {
    ...user,
    userAccountStatus: getUserAccountStatus(user),
    // Give priority to Identity service properties
    username: user.preferred_username ?? user.username,
    firstName: user.given_name ?? user.firstName,
    lastName: user.family_name ?? user.lastName,
  };
  return result;
}

export function oidcUserModel(user: any): OidcUser {
  return {
    ...user,
  };
}

export function userCredentialModel(data: any): UserCredentials {
  return {
    ...data,
    user: userModel(data.user),
  };
}
