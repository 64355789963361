import { useState } from 'react';

import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

type ExportBatchFilters = {
  search: string;
  sortBy: string;
};

const defaultFilters = (search = ''): ExportBatchFilters => ({
  search,
  sortBy: '-createdAt',
});

/** Data provider hook for Export Batch */
export default function useExportBatchesData(
  { search = '' } = {},
  enabled = true,
) {
  const [filters, setFilters] = useState(defaultFilters(search));

  const { exportBatchesApi, QUERY_KEYS } = useRequest();

  /** Set a single filter */
  const setFilter = <K extends keyof ExportBatchFilters>(
    key: K,
    val: ExportBatchFilters[K],
  ) => {
    setFilters((prev) => ({ ...prev, [key]: val }));
  };

  /** Reset a single filter */
  const resetFilter = (key: keyof ExportBatchFilters) => {
    setFilter(key, defaultFilters()[key]);
  };

  // Query differently depending on if a search string is provided
  const queryParams = {
    search: filters.search ?? '',
    sortBy: filters.sortBy || '',
  };

  const { isLoading, isSuccess, data } = useQuery(
    [...QUERY_KEYS.EXPORT_BATCHES, queryParams],
    () => exportBatchesApi.getExportBatches(queryParams),
    { enabled },
  );

  return {
    exportBatches: data && !data.error ? data.data : [],
    isLoading,
    isSuccess,
    setFilter,
    resetFilter,
    filters,
  };
}
