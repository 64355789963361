import { GridColDef } from '@mui/x-data-grid';

import { thingTypeThingColDef } from '@/components/Library/sharedThingColDefs';

export const relationshipsTableTheme = {
  '.MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
};

/**
 * Specially meant for relationships, accepts a thingTypesMap to turn
 * thingType uuids into thing type titles.
 */
export function makeMixedThingTypeLookupColDef<
  T extends Thing | ThingRelationEntry,
>(thingTypesMap: Record<string, ThingType>): GridColDef<T> {
  return {
    ...thingTypeThingColDef,
    valueGetter: ({ row }: { row: Thing | ThingRelationEntry }) => {
      if ('thingType' in row) {
        return row.thingType?.name;
      }
      if (row.thingTypeUuid /* Check for presence and non-empty string */) {
        return thingTypesMap[row.thingTypeUuid]?.name ?? '...';
      }
      return '';
    },
  };
}
