export const ACTIONS = {
  ADD_FIELD: 'add-field',
  EDIT_FIELD: 'edit-field',
  MULTI_FILE: 'multi-file',
} as const;

export type SidebarActions = ValueOf<typeof ACTIONS>;

export const TITLES = {
  [ACTIONS.EDIT_FIELD]: 'Edit Field',
  [ACTIONS.ADD_FIELD]: 'Add Field',
  [ACTIONS.MULTI_FILE]: 'Selected Files',
} as const;

// Options to mask when sidebar is open or not
export const MASKING = {
  [ACTIONS.EDIT_FIELD]: true,
  [ACTIONS.ADD_FIELD]: true,
  [ACTIONS.MULTI_FILE]: false,
} as const;
