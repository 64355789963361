import {
  CONFLICTS,
  Conflict,
  RESOLUTIONS,
} from '@/common/entities/exportBatches/typedefs';

export const CONFLICT_LABELS = {
  [CONFLICTS.NOT_CHECKED_OUT]: 'Not checked out',
  [CONFLICTS.DUPLICATE_DUST_IN_CATALOG]: 'Duplicate DUST tag',
  [CONFLICTS.RELATIONSHIP_CONFLICT]: 'Changed relationships',
};

export const getCatalogName = (exportBatch: ExportBatch | null, uuid: string) =>
  (exportBatch?.importValidation?.catalogs ?? []).find((c) => c.uuid === uuid)
    ?.name ?? '';

export const getConflictMessage = (conflict: Conflict): string =>
  CONFLICT_LABELS[conflict] ?? '';

export function isConflictRootLevel(conflict: Conflict) {
  return conflict === CONFLICTS.RELATIONSHIP_CONFLICT;
}

export const RESOLUTION_MAP = {
  [CONFLICTS.NOT_CHECKED_OUT]: {
    [RESOLUTIONS.USE_IMPORTED_THING]: {
      label: 'Use Imported Thing',
      value: 'USE_IMPORTED_THING',
    },
    [RESOLUTIONS.USE_SERVER_THING]: {
      label: 'Use Server Thing',
      value: 'USE_SERVER_THING',
    },
    [RESOLUTIONS.NO_RESOLUTION]: {
      label: 'Conflict exists',
      value: 'NO_RESOLUTION',
    },
  },
  [CONFLICTS.DUPLICATE_DUST_IN_CATALOG]: {
    [RESOLUTIONS.USE_IMPORTED_THING]: {
      label: 'Override Tag',
      value: 'DEACTIVATE_SERVER_DUST',
    },
    [RESOLUTIONS.USE_SERVER_THING]: {
      label: 'Preserve Tag',
      value: 'DEACTIVATE_IMPORTED_DUST',
    },
    [RESOLUTIONS.NO_RESOLUTION]: {
      label: 'Conflict exists',
      value: 'NO_RESOLUTION',
    },
  },
  [CONFLICTS.RELATIONSHIP_CONFLICT]: {
    [RESOLUTIONS.USE_IMPORTED_THING]: {
      label: 'Use New Relationship',
      value: 'USE_IMPORTED_RELATIONSHIP_TREE',
    },
    [RESOLUTIONS.USE_SERVER_THING]: {
      label: 'Keep Relationships',
      value: 'USE_SERVER_RELATIONSHIP_TREE',
    },
    [RESOLUTIONS.NO_RESOLUTION]: {
      label: 'Conflict exists',
      value: 'NO_RESOLUTION',
    },
  },
};
