import { SvgIcon, SvgIconProps } from '@mui/material';

function DustModifiedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 17 15">
        <path
          d="m0.91786,1.29353l6.49519,11.03252l6.4952,-11.03252l-12.99039,0z"
          stroke="#FDD504"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}

export default DustModifiedIcon;
