import { useMemo } from 'react';

import useThingData from '@/common/entities/things/useThingData';
import useThingsData from '@/common/entities/things/useThingsData';

/**
 * Combines current children with eligible children into one table
 */
export default function useChildSelectionData(
  thingUuid: string,
  catalogUuid: string,
) {
  const { thing, isLoading: isThingLoading } = useThingData({
    thingUuid,
    additionalFields: ['children'],
  }); // Need info about curr

  const {
    things,
    isLoading: isEligibleThingsLoading,
    getNextPage,
    totalItems: totalEligibleChildren = 0,
    filters,
    setFilter,
  } = useThingsData({
    catalogUuid,
    fixedParams: {
      canBeChildOf: thingUuid,
      fields: ['mediaLinks', 'metadata', 'schema', 'parent'],
      sort: ['-createdAt'],
    },
  });

  const isLoading = isThingLoading || isEligibleThingsLoading;

  const childrenThings = useMemo(() => {
    const currentChildren = thing?.relationships?.children ?? [];
    const currentChildrenUuids = new Set(currentChildren.map((c) => c.uuid));
    const makeIsCurrentChildMapper =
      (isChild: boolean) => (childThing: Thing | ThingRelationEntry) => ({
        ...childThing,
        isCurrentChild: isChild,
      });

    return things /* Keep defined while re-fetching */
      ? [
          ...currentChildren.map(makeIsCurrentChildMapper(true)),
          ...(things
            ?.map(makeIsCurrentChildMapper(false))
            // Avoid `key` React errors in MUI Data Grid during refetches
            .filter((child) => !currentChildrenUuids.has(child.uuid)) ?? []),
        ]
      : undefined;
  }, [things, thing?.relationships?.children]);

  return {
    isLoading,
    thing,
    totalItems:
      totalEligibleChildren + (thing?.relationships?.children ?? []).length,
    /** Guaranteed to be unique per uuid */
    childrenThings,
    filters,
    setFilter,
    getNextPage,
  };
}
