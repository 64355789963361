import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 10 * 60 * 1000;

export default function useFeaturesData({
  enabled,
}: { enabled?: boolean } = {}) {
  const { featuresApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess, isError } = useQuery(
    QUERY_KEYS.FEATURES,
    () => featuresApi.getFeatures(),
    { enabled, staleTime: STALE_TIME },
  );

  return {
    data: !data?.error ? data?.data : undefined,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error || isError,
  };
}
