import { useState, useRef } from 'react';

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Tooltip, Menu } from '@mui/material';

import { combineClass } from '@/components/Library/utility';

import styles from './LeftNav.module.css';

type Props = {
  name: string;
  setOpen?: (e: boolean) => void;
  isFull?: boolean;
  icon?: React.ReactNode;
  hide?: boolean;
  children?: React.ReactNode;
};

export default function NavEntryMenu({
  name,
  setOpen,
  isFull,
  icon,
  hide,
  children,
}: Props) {
  const [menuEl, _setMenuEl] = useState<Element | null>(null);

  const setMenuEl = (val: Element | null) => {
    _setMenuEl(val);
    if (setOpen) setOpen(!!val);
  };

  const buttonRef = useRef(null);
  return (
    <>
      <Tooltip
        arrow
        enterDelay={isFull ? 10000 : 100}
        placement="right"
        title={name}
      >
        <button
          className={combineClass('text-button', styles.navMenuItem)}
          data-testid={`left-nav-${name?.toLowerCase()}`}
          onClick={() => setMenuEl(buttonRef.current)}
          ref={buttonRef}
          type="button"
          aria-controls={menuEl ? 'settings-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuEl ? 'true' : undefined}
          style={{ visibility: hide ? 'hidden' : 'visible' }}
        >
          {icon}
          {isFull && <span>{name}</span>}
          {isFull && <ArrowForwardIosOutlinedIcon />}
        </button>
      </Tooltip>
      <Menu
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'settings-menu',
        }}
        onClick={() => setMenuEl(null)}
        onClose={() => setMenuEl(null)}
        open={!!menuEl}
        sx={{
          marginLeft: '20px',
        }}
      >
        {children}
      </Menu>
    </>
  );
}
