import { shoppingCartModel } from '@/common/entities/shoppingCarts/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import { DustSingleResponse } from '@/services/requests/types';
import {
  formatAPIReturn,
  formatAPIError,
  defaultErrorHandler,
} from '@/services/utility';

import { MESSAGES } from '../constants';

export default class ShoppingCartsApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  async create() {
    return this.apiService
      .request({
        url: '/api/shopping-carts',
        method: 'POST',
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.shoppingCart();
        this.invalidate.users();
        return formatAPIReturn(res, shoppingCartModel);
      })
      .catch((err) => formatAPIError(err));
  }

  /** Get a single ShoppingCart */
  async getShoppingCart({ fields }: { fields?: string[] } = {}) {
    const args = new URLSearchParams();
    if (fields) {
      args.append('fields', fields.toString());
    }

    return this.apiService
      .request({
        url: encodeURI(`/api/shopping-carts/?${args.toString()}`),
        method: 'GET',
      })
      .then((res: DustSingleResponse) =>
        formatAPIReturn(res, shoppingCartModel),
      )
      .catch((err) => formatAPIError(err));
  }

  /** Add Things to a shopping cart */
  async addThings({
    thingUuids,
    catalogUuids,
    suppressToast = false,
  }: {
    thingUuids?: string[];
    catalogUuids?: string[];
    suppressToast?: boolean;
  }) {
    if (!thingUuids && !catalogUuids)
      throw new Error('Must provide thingUuids OR catalogUuids');

    return this.apiService
      .request({
        url: '/api/shopping-carts/',
        method: 'POST',
        data: removeNil({
          thingUuids: thingUuids ? thingUuids.join(',') : undefined,
          catalogUuids: catalogUuids ? catalogUuids.join(',') : undefined,
        }),
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.shoppingCart();
        this.invalidate.things();
        if (!suppressToast) {
          if (thingUuids) {
            this.addToast(MESSAGES.CART_ADD_THING_SUCCESS, 'success');
          } else {
            this.addToast(MESSAGES.CART_ADD_CATALOG_SUCCESS, 'success');
          }
        }
        return formatAPIReturn(res, shoppingCartModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.CART_ADD_FAILED, 'error');
        }),
      );
  }

  /** Remove Things from a shopping cart */
  async removeThings({
    thingUuids,
    catalogUuids,
  }: {
    thingUuids?: string[];
    catalogUuids?: string[];
  }) {
    if (!thingUuids && !catalogUuids)
      throw new Error('Must provide thingUuids OR catalogUuids');

    return this.apiService
      .request({
        url: '/api/shopping-carts/',
        method: 'PUT',
        data: removeNil({
          thingUuids: thingUuids ? thingUuids.join(',') : undefined,
          catalogUuids: catalogUuids ? catalogUuids.join(',') : undefined,
        }),
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.shoppingCart();
        this.invalidate.things();
        return formatAPIReturn(res, shoppingCartModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.CART_REMOVE_FAILED, 'error');
        }),
      );
  }
}
