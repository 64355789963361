import { facilityModel } from '@/common/entities/facilities/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import {
  DustArrayResponse,
  DustSingleResponse,
} from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIReturn,
  formatAPIError,
} from '@/services/utility';

import { MESSAGES } from '../constants';

/** Factory function for producing the Facilities API */
export default class FacilitiesApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Get all facilities */
  async list({ sort }: { sort?: string }) {
    const args = new URLSearchParams();
    if (sort) {
      args.append('sort', sort);
    }

    return this.apiService
      .request({
        url: `api/facilities?${args.toString()}`,
        method: 'GET',
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, facilityModel))
      .catch((err) => formatAPIError(err));
  }

  /** Get a single facility */
  async get({ uuid }: { uuid: string }) {
    return this.apiService
      .request({
        url: encodeURI(`/api/facilities/${uuid}`),
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, facilityModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED);
      });
  }

  /** Update a single facility */
  // TODO: Properly type values
  async update({
    values,
    uuid,
  }: {
    values: object & { name: string };
    uuid: string;
  }) {
    return this.apiService
      .request({
        url: encodeURI(`/api/facilities/${uuid}`),
        method: 'PUT',
        data: values,
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.facilities();
        this.addToast(
          MESSAGES.FACILITIES.FACILITY_UPDATED.SUCCESS(values.name),
          'success',
        );
        return res.data.data;
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (err.response && err.response.status === 409) {
            this.addToast(
              MESSAGES.FACILITIES.FACILITY_UPDATED.API_MESSAGE(
                err.response.data.message,
              ),
              'error',
            );
          } else {
            this.addToast(
              MESSAGES.FACILITIES.FACILITY_UPDATED.FAILURE(values.name),
              'error',
            );
          }
        }),
      );
  }

  /** Create a facility */
  // TODO: Properly type values
  async create({ values }: { values: object & { name: string } }) {
    return this.apiService
      .request({
        url: encodeURI('/api/facilities'),
        method: 'POST',
        data: values,
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.facilities();
        this.addToast(
          MESSAGES.FACILITIES.FACILITY_CREATED.SUCCESS(values.name),
          'success',
        );
        return res.data.data; // TODO: Model return
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (err.response && err.response.status === 409) {
            this.addToast(
              MESSAGES.FACILITIES.FACILITY_UPDATED.API_MESSAGE(
                err.response.data.message,
              ),
              'error',
            );
          } else {
            this.addToast(
              MESSAGES.FACILITIES.FACILITY_CREATED.FAILURE(values.name),
              'error',
            );
          }
        }),
      );
  }
}
