import { KeyboardEventHandler, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Tooltip } from '@mui/material';
import {
  useGridApiContext,
  GridRowTreeNodeConfig,
  GridRowId,
  GridCellParams,
} from '@mui/x-data-grid-premium';

import { ThingChildrenHierarchyRow } from '@/common/utilities/relationships/helpers';

import styles from './RelationshipGridTreeDataGroupingCell.module.css';

type Props = {
  id: GridRowId;
  field: string;
  rowNode: GridRowTreeNodeConfig;
  row: ThingChildrenHierarchyRow;
};

export function RelationshipGridTreeDataGroupingCell({
  id,
  field,
  rowNode,
  row,
}: Props) {
  const [isClicked, setIsClicked] = useState(false);
  const apiRef = useGridApiContext();

  const isNavigationKey = (key: string) =>
    key === 'Home' ||
    key === 'End' ||
    key.indexOf('Arrow') === 0 ||
    key.indexOf('Page') === 0 ||
    key === ' ';

  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    setIsClicked(!isClicked);
    if (event.key === ' ') {
      event.stopPropagation();
    }
    if (isNavigationKey(event.key) && !event.shiftKey) {
      apiRef.current.publishEvent(
        'cellNavigationKeyDown',
        {
          id,
          field,
          rowNode,
          row,
        } as GridCellParams /* Force conversion based on functionality */,
        event,
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsClicked(!isClicked);
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  const showExpander = row.tableChildrenCount > 0;
  const ExpanderIcon = rowNode.childrenExpanded
    ? KeyboardArrowDownIcon
    : KeyboardArrowRightIcon;

  return (
    <div className={styles.titleGroupingCellContainer}>
      <button
        type="button"
        style={{
          visibility: showExpander ? 'visible' : 'hidden',
        }}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
        className={styles.gridTreeArrowButton}
      >
        <ExpanderIcon />
      </button>
      {/* Use a div to indent the title */}
      <div
        style={{
          width: `${rowNode.depth * 1.5}rem`,
        }}
      />
      <Tooltip enterDelay={900} title={row.title}>
        <span className={styles.gridTreeTitleSpan}>{row.title}</span>
      </Tooltip>
    </div>
  );
}
