import { ROUTES } from '@/common/routes';

import styles from './ErrorPages.module.css';

export default function ErrorPageLinks() {
  return (
    <ul className={styles.list}>
      <li>
        <button
          type="button"
          className={styles.link}
          onClick={() => window.history.back()}
        >
          Previous
        </button>
      </li>
      <li>
        {/* NOTE - because this is an error page, use a raw href to reduce bug and stale state potential */}
        <a className={styles.link} href={ROUTES.CATALOGS}>
          Catalogs
        </a>
      </li>
      <li>
        <a className={styles.link} href={ROUTES.SCAN}>
          Scanner
        </a>
      </li>
      <li>
        <a className={styles.link} href={ROUTES.THINGS_ADD}>
          Create Thing
        </a>
      </li>
    </ul>
  );
}
