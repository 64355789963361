import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 10 * 60 * 1000;

type HookProps = {
  facilityUuid: string | undefined;
  enabled?: boolean;
};

export default function useFacilityData({
  facilityUuid = '',
  enabled = true,
}: HookProps) {
  const { facilitiesApi, QUERY_KEYS } = useRequest();

  const { data, isError, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.FACILITY(facilityUuid ?? ''),
    () => facilitiesApi.get({ uuid: facilityUuid }),
    { enabled: enabled && !!facilityUuid, staleTime: STALE_TIME },
  );

  return {
    facility: data && !data.error ? data.data : undefined,
    isError: data?.error || isError,
    isLoading,
    isSuccess,
  };
}
