import { Tooltip } from '@mui/material';

import styles from './TopNav.module.css';

type Props = {
  org: Org;
};

function LogoText({ text }: { text: string }) {
  return (
    <Tooltip enterDelay={700} title={text}>
      <span className={styles.orgLogoText}>{text}</span>
    </Tooltip>
  );
}

export function LogoImage({ org }: Props) {
  return org && org.logoImage ? (
    <>
      <LogoText text={org.name} />
      <img
        alt={org.shortName}
        className={styles.clientLogo}
        src={`data:${org.logoImage.contentType};base64,${org.logoImage.content}`}
      />
    </>
  ) : (
    <LogoText text={org.name} />
  );
}
