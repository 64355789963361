import fallbackImage from '@/assets/images/broken-image.png';
import fallbackSvg from '@/assets/svg/broken-image.svg';

/**
 * Sets a fallback image for a failed load
 */
export function provideFallbackImage(
  evt: React.SyntheticEvent<HTMLImageElement, Event>,
) {
  /* eslint-disable no-param-reassign */ // simplest way to manipulate this HTMLImageElement in a drop-in way
  // Try SVG
  if (
    evt.currentTarget.src !== fallbackImage &&
    evt.currentTarget.src !== fallbackSvg
  ) {
    evt.currentTarget.src = fallbackSvg;
  }

  // Use pnp as last resort
  if (evt.currentTarget.src === fallbackSvg) {
    evt.currentTarget.src = fallbackImage;
  }
  /* eslint-enable no-param-reassign */
}
