import { SCAN_WIDTH, SCAN_HEIGHT } from './constants';
import { ApiAntiTamperNotation } from './typedefs';
import baseModel from '../baseModel';

function antiTamperNotationsModel(
  notation: ApiAntiTamperNotation,
): AntiTamperNotation {
  return {
    ...baseModel(notation),
    pctX: (100 * notation.x) / SCAN_WIDTH,
    pctY: (100 * notation.y) / SCAN_HEIGHT,
    saved: true,
    active: false,
  };
}

/**
 * Formats the API return into a shoppingCart object
 */
export function antiTamperAnalysisScanModel(
  antiTamperAnalysisScan: ApiAntiTamperAnalysisScan,
): AntiTamperAnalysisScan {
  // TODO: Audit these and see if they're all still used.
  return {
    ...baseModel(antiTamperAnalysisScan),
    matchingMarkersPerc: `${
      antiTamperAnalysisScan.comparisonScan.analysisResults.matchingMarkers *
      100.0
    }`,
    missingMarkersPerc: `${
      antiTamperAnalysisScan.comparisonScan.analysisResults.missingMarkers *
      100.0
    }`,
    modifiedMarkersPerc: `${
      antiTamperAnalysisScan.comparisonScan.analysisResults.modifiedMarkers *
      100.0
    }`,
    tamperScore:
      antiTamperAnalysisScan.comparisonScan.analysisResults.tamperScore,
    alignmentOk:
      antiTamperAnalysisScan.comparisonScan.analysisResults.alignmentOk,
    userDeclaration: antiTamperAnalysisScan.outcome,
    notations: antiTamperAnalysisScan.comparisonScan.notations.map(
      antiTamperNotationsModel,
    ),
    compositeHeatmap: [
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .queryScanTransformedAlphaMaskedUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia.fgHeatmapCombinedUri,
    ],
    compositeMarkers: [
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .queryScanTransformedAlphaMaskedUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia.matchingMarkersUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .missingReferenceMarkersUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .missingQueryMarkersUri,
    ],
    compositeAll: [
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .queryScanTransformedAlphaMaskedUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia.bgHeatmapFullUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia.fgHeatmapCombinedUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia.matchingMarkersUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .missingReferenceMarkersUri,
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .missingQueryMarkersUri,
    ],
    bgNotated: [
      antiTamperAnalysisScan.comparisonScan.generatedMedia
        .queryScanTransformedAlphaMaskedUri,
    ],
  };
}
