import { useMemo } from 'react';

import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { GridColumns, GridRowParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import { formatDateForDisplay } from '@/common/dates';
import {
  thingTypesSortAtom,
  THING_TYPE_SORT_OPTIONS,
} from '@/common/entities/thingTypes/constants';
import useThingTypesData from '@/common/entities/thingTypes/useThingTypesData';
import { ROUTES } from '@/common/routes';
import { useFetchMoreToFillHeightRef } from '@/common/utilities/table';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustEventBlocker from '@/components/Library/DustEventBlocker';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustThumbnail from '@/components/Library/DustThumbnail';
import useSort from '@/services/useSort';

import ThingTypeActions from './ThingTypeActions';
import styles from './ThingTypes.module.css';

export default function ThingTypes() {
  const navigate = useNavigate();

  const { sortRequestParams, applyColumnSortProps, tableSortProps } = useSort(
    thingTypesSortAtom,
    THING_TYPE_SORT_OPTIONS,
  );

  const { thingTypes, search, setSearch, isFetching, getNextPage, totalItems } =
    useThingTypesData({ ...sortRequestParams });

  const tableContainerRef = useFetchMoreToFillHeightRef(
    getNextPage,
    thingTypes.length,
  );

  const handleRowClick = ({ row }: GridRowParams<ThingType>) => {
    navigate(ROUTES.ADMIN_EDIT_THING_TYPE(row.uuid));
  };

  const columns = useMemo(() => {
    const cols: GridColumns<ThingType> = [
      {
        valueGetter: ({ row: thingType }) => thingType.uuid,
        field: 'id',
        headerName: 'id',
        hide: true,
        flex: 1,
      },
      {
        field: 'thumbnail',
        minWidth: 64,
        maxWidth: 64,
        headerName: '',
        renderCell: ({ row: thingType }) => (
          <DustThumbnail
            imageUuid={
              thingType.fieldTypes.find(
                (field) => field.uuid === thingType.primaryImageFieldTypeUuid,
              )?.value?.value
            }
            styleForTable
          />
        ),
      },
      {
        valueGetter: ({ row: thingType }) => thingType.name,
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        valueGetter: ({ row: thingType }) => thingType,
        field: 'createdBy',
        headerName: 'Created By',
        renderCell: ({ formattedValue: thingType }) => (
          <div>
            <div>{thingType.createdBy?.name}</div>
            <div>@{thingType.createdBy?.secondaryName}</div>
          </div>
        ),
        flex: 1,
      },
      {
        valueGetter: ({ row: thingType }) => thingType.createdAt,
        field: 'createdAt',
        headerName: 'Created at',
        valueFormatter: ({ value }) => formatDateForDisplay(value),
        flex: 1,
        sortingOrder: ['desc', 'asc'],
      },
      {
        valueGetter: ({ row: thingType }) => String(thingType.thingsCount),
        field: 'thingsCount',
        headerName: 'Things Using',
        flex: 1,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        valueGetter: ({ row: thingType }) => thingType,
        renderCell: ({ formattedValue: thingType }) => (
          <DustEventBlocker>
            <ThingTypeActions thingType={thingType} />
          </DustEventBlocker>
        ),
        flex: 1,
      },
    ];

    return applyColumnSortProps(cols);
  }, [applyColumnSortProps]);

  return (
    <>
      <div className={styles.controlsRow}>
        <DustSearchInput
          defaultValue={search}
          isLoading={isFetching}
          setValue={setSearch}
          aria-label="Search for a Thing type"
        />
        <Button
          onClick={() => navigate(ROUTES.ADMIN_CREATE_THING_TYPE)}
          startIcon={<Add />}
          variant="contained"
        >
          Create
        </Button>
      </div>
      <p className="mb-1" style={{ maxWidth: '50rem' }}>
        Use Thing Types to create templates for similar types of Things. Thing
        Types predefine the included data fields, images, and files for a
        Thing—streamlining management of the data they contain and enabling fast
        creation.
      </p>
      <div className="flex-1" ref={tableContainerRef}>
        <DustDataGrid
          altTheme
          disableSelectionOnClick
          columns={columns}
          loading={isFetching}
          onRowsScrollEnd={getNextPage}
          rows={thingTypes}
          getRowId={(r) => r.uuid}
          rowCount={totalItems}
          onRowClick={handleRowClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableSortProps}
        />
      </div>
    </>
  );
}
