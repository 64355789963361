import ErrorBase from '@/components/Pages/ErrorPages/ErrorBase';

export default function Page404() {
  return (
    <div className="app-container">
      <ErrorBase
        title="Uh-oh."
        message="We cannot seem to find the page you are looking for. Here are some helpful links:"
      />
    </div>
  );
}
