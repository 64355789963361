import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

export default function useUserOrgsData() {
  const auth = useAuth();
  const { usersApi, QUERY_KEYS } = useRequest();
  const { data, isLoading } = useQuery(
    QUERY_KEYS.USER_ORGS_LIST('activeUser'),
    () => usersApi.getUserOrgs(auth.user?.profile.sub ?? ''),
    { enabled: Boolean(auth.user) },
  );

  return {
    data: data && !data.error && data.data ? data.data : null,
    isLoading,
    error: data && data.error ? data.data : null,
  };
}
