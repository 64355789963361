/**
 * Returns a stable unique name for each level in the hierarchy
 */
export function getRelationNameForLevel(
  /** level -1 = Parent, 0 = "Current Thing", 1 = "Child", and so on */
  level: number,
) {
  if (level < -1) throw new Error('No available level higher than Parent (1)');
  if (level === -1) return 'Parent';
  if (level === 0) return 'This thing';
  if (level === 1) return 'Child'; /* Child level 1, is 2 less than `level` */
  return `Level ${level} Child`;
}

export type HierarchyInfo = {
  /** Sticking to grid conventions for similicity and reusability */
  id: string;
  /** val 0 is current thing, increases with child depth */
  level: number;
  relationType: string;
  hierarchy: string[];
  tableChildrenCount: number;
  childrenFetched: boolean;
};

export type ThingChildrenHierarchyRow = (Thing | ThingRelationEntry) &
  HierarchyInfo;

const makeHierarchyStructure = (
  thing: Thing | ThingRelationEntry,
  level: number,
  hierarchy: string[],
  childrenAlreadyFetched = false,
): ThingChildrenHierarchyRow => ({
  ...thing,
  id: thing.uuid,
  level,
  relationType: getRelationNameForLevel(level),
  hierarchy,
  tableChildrenCount:
    (level === -1 && 1) || // Show expander for root
    ('relationships' in thing && thing.relationships.children?.length) ||
    ('children' in thing && thing.children.length) ||
    0,
  childrenFetched: childrenAlreadyFetched,
});

export function thingChildrenToHierarchyRows(
  thingWithChildren: Thing,
  existingThingRowsSet: Array<ThingChildrenHierarchyRow>,
): Array<ThingChildrenHierarchyRow> {
  const existingThingTarget = existingThingRowsSet.find(
    (t) => t.uuid === thingWithChildren.uuid,
  );
  if (!existingThingTarget) {
    throw new Error(
      'Expected to find parent in existing children hierarchy set',
    );
  }

  if (thingWithChildren.relationships?.children == null) {
    return []; // No valid children to convert
  }

  return thingWithChildren.relationships.children.map((newChild) =>
    makeHierarchyStructure(newChild, existingThingTarget.level + 1, [
      ...existingThingTarget.hierarchy,
      newChild.uuid,
    ]),
  );
}

/**
 * Adds the relationType and the heirarchy data to Parent, Thing, and Thing
 * Children Objects and pushes them to a rows Array to be used in a
 * DustDataGrid
 */
export function makeInitialHierarchyRows(
  thing: Thing,
): Array<ThingChildrenHierarchyRow> {
  const rows = [];

  let parentArrayValue: string[] = [];
  if (thing.relationships.parent) {
    parentArrayValue = [thing.relationships.parent.uuid];
    rows.push(
      makeHierarchyStructure(
        thing.relationships.parent,
        -1,
        parentArrayValue,
        true,
      ),
    );
  }
  rows.push(
    makeHierarchyStructure(thing, 0, [...parentArrayValue, thing.title], true),
  );

  return rows.concat(thingChildrenToHierarchyRows(thing, rows));
}
