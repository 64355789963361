import { useCallback } from 'react';

import { MenuItem, SelectChangeEvent } from '@mui/material';

import {
  catalogSelectorSortAtom,
  CATALOG_SORT_OPTIONS,
  FILTER_VIEW_OPTIONS,
  FILTER_NAMES,
  CatalogFilters as CatalogFiltersType,
} from '@/common/entities/catalogs/useCatalogsData';
import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import TableFilter from '@/components/Composed/TableFilter/TableFilter';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustSelect from '@/components/Library/DustSelect';
import useSort from '@/services/useSort';

type Props = {
  showResetFilter: boolean;
  resetFilters: () => void;
  setFilter: (
    e: (typeof FILTER_NAMES)[keyof typeof FILTER_NAMES],
    v: any,
  ) => void;
  filters: CatalogFiltersType;
};

export default function CatalogFilters({
  showResetFilter,
  resetFilters,
  setFilter,
  filters,
}: Props) {
  const { org } = useOrgData();
  const { sortSelectProps } = useSort(
    catalogSelectorSortAtom,
    CATALOG_SORT_OPTIONS,
  );

  const handleChange = useCallback(
    (evt: SelectChangeEvent<ValueOf<typeof FILTER_VIEW_OPTIONS>>) => {
      setFilter(FILTER_NAMES.VIEW, evt.target.value);
    },
    [setFilter],
  );

  const viewFilterFeatureGate = (filter: ValueOf<typeof FILTER_VIEW_OPTIONS>) =>
    filter !== FILTER_VIEW_OPTIONS.MOBILE ||
    org?.isFeatureEnabled(FEATURE_FLAGS.MOBILE);

  return (
    <TableFilter
      aria-label="Filter the catalogs shown"
      onReset={resetFilters}
      showReset={showResetFilter}
    >
      <div className="flex-col gap-1 mb-05 mt-1">
        <DustSelect
          label="Sort By"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...sortSelectProps}
        />
        <DustSelect
          label="View"
          onChange={handleChange}
          value={filters[FILTER_NAMES.VIEW]}
          aria-label="Filters for the catalogs table"
        >
          {Object.values(FILTER_VIEW_OPTIONS)
            .filter(viewFilterFeatureGate)
            .map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </DustSelect>
      </div>
      {filters[FILTER_NAMES.VIEW] !== FILTER_VIEW_OPTIONS.HIDDEN && (
        <DustCheckbox
          checked={!!filters.displayHidden}
          label="Display Hidden Catalogs"
          onChange={(val) => setFilter(FILTER_NAMES.DISPLAY_HIDDEN, val)}
        />
      )}
    </TableFilter>
  );
}
