import { useMemo } from 'react';

import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import { objToNameValueList } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';

function StepScanSummaryTable({ thing }) {
  const thingMeta = useMemo(
    () => [
      ...objToNameValueList(thing?.metadata),
      ...objToNameValueList(thing?.typedMetadata)
        .filter((entry) => entry?.value?.type === 'string')
        .map((entry) =>
          // Need to replace the metadata value of title with the computed version
          entry.value.name === RESTRICTED_THING_FIELDS.DICE_TITLE
            ? {
                ...entry,
                value: {
                  ...entry.value,
                  value: thing.title,
                },
              }
            : entry,
        ),
    ],
    [thing],
  );

  // TODO: wrap in useMemo
  const columns = [
    {
      objGetter: (entry) => entry?.value?.name ?? entry.name,
      field: 'id',
      headerName: 'Field',
      flex: 1,
    },
    {
      objGetter: (entry) => entry.value?.value ?? entry.value,
      field: 'value',
      headerName: 'Value',
      flex: 1,
    },
  ];

  // TODO: remove in favor of col-def field + valueGetter
  const rows = thingMeta.map((metaEntry) => {
    const row = {};
    columns.forEach((col) => {
      row[col.field] = col.objGetter(metaEntry);
    });
    return row;
  });

  return (
    <div
      className="flex flex-1"
      style={{
        minWidth: '300px',
        minHeight: '150px',
      }}
    >
      <DustDataGrid columns={columns} rows={rows} />
    </div>
  );
}

export default StepScanSummaryTable;
