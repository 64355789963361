import React from 'react';

import { Box, Typography, MenuItem, TextField } from '@mui/material';

import styles from './AntiTamperUserConfirmForm.module.css';

const options = {
  PASS: 'Pass - No Tampering Evident',
  WEAR_AND_TEAR: 'Pass - No Tampering Evident',
  INCONCLUSIVE: 'Inconclusive',
  SUSPECTED_TAMPER: 'Suspect Tamper',
  FLAG_TAMPERED: 'Tampered',
};

type AntiTamperUserConfirmFormProps = {
  observedResult: string;
  setObservedResult: (result: string) => void;
  comment: string;
  setComment: (comment: string) => void;
  isScanLoaded: boolean;
  isReadOnly: boolean;
};

export default function AntiTamperUserConfirmForm({
  observedResult,
  setObservedResult,
  comment,
  setComment,
  isScanLoaded,
  isReadOnly,
}: AntiTamperUserConfirmFormProps) {
  React.useEffect(() => {
    if (!isScanLoaded && !isReadOnly) {
      setComment('');
      setObservedResult('');
    }
  }, [isScanLoaded, setComment, setObservedResult, isReadOnly]);

  return (
    <Box component="div" className={styles.root}>
      <Typography
        className={`${styles.formLabel} ${
          !isScanLoaded && styles.formDisabledLabel
        }`}
      >
        User Confirmation
      </Typography>
      {!isReadOnly && (
        <>
          <TextField
            label="Select Observed Result"
            placeholder="Select Observed Result"
            value={observedResult}
            onChange={(e) => setObservedResult(e.target.value)}
            variant="outlined"
            sx={{
              height: 40,
              marginBottom: '1rem',
              '& svg': {
                color: 'var(--white) !important',
                '&.Mui-disabled': {
                  color: 'var(--deep-grey) !important',
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: 'var(--white) !important',
                marginTop: '-3px',
                '&.Mui-disabled': {
                  color: 'var(--medium-grey) !important',
                },
                fontSize: '0.9rem',
              },
            }}
            InputProps={{
              sx: {
                color: 'var(--white)',
                height: 40,
                border: '1px solid var(--white)',
                '&.Mui-disabled': {
                  border: '1px solid var(--medium-grey)',
                  color: 'var(--white)',
                },
              },
            }}
            select
            disabled={!isScanLoaded || isReadOnly}
          >
            {Object.entries(options).map(([key, label]) => (
              <MenuItem value={key} key={key}>
                {label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            placeholder="Comments"
            sx={{
              color: 'var(--white) !important',
              '& textarea': {
                '-webkit-text-fill-color': 'var(--light-grey) !important',
                '&.Mui-disabled': {
                  '-webkit-text-fill-color': 'var(--medium-grey) !important',
                  color: 'var(--medium-grey) !imoprtant',
                },
              },
            }}
            InputProps={{
              sx: {
                marginTop: '-6px',
                fontSize: '0.9rem',
                border: '1px solid var(--white)',
                '&.Mui-disabled': {
                  border: '1px solid var(--medium-grey)',
                },
              },
            }}
            InputLabelProps={{
              sx: {
                color: 'var(--white) !important',
                marginTop: '-6px',
                fontSize: '0.9rem',
                border: '1px solid var(--white)',
                '&.Mui-disabled': {
                  border: '1px solid var(--medium-grey)',
                  color: 'var(--deep-grey) !important',
                },
                '& ::placeholder': {
                  color: 'red !important',
                },
                '&.Mui-disabled textarea::placeholder': {
                  color: 'red !important',
                },
              },
            }}
            variant="outlined"
            value={comment}
            multiline
            rows={5}
            onChange={(e) => setComment(e.target.value)}
            disabled={!isScanLoaded}
          />
        </>
      )}
      {isReadOnly && (
        <dl className={styles.readOnlyForm}>
          <dt>Observed Result</dt>
          <dd>
            {options[observedResult as keyof typeof options] ?? 'Not set'}
          </dd>
          <dt>Comments</dt>
          <dd>{comment}</dd>
        </dl>
      )}
    </Box>
  );
}
