import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import useExportBatchData from '@/common/entities/exportBatches/useExportBatchData';
import useCheckout, { EXPORT_STEPS } from '@/common/hooks/useCheckout';
import useToasts from '@/common/hooks/useToasts';
import { downloadWithTimeout } from '@/common/utility';
import DustModal from '@/components/Library/DustModal';
import DustProgressLoader from '@/components/Library/DustProgressLoader';
import { MESSAGES } from '@/services/requests/constants';

import styles from './ShoppingCart.module.css';

const CLOSE_MODAL_TIME = 5 * 1000;
type StatusType = 'COMPLETE' | 'PROCESSING' | 'ERROR';

export default function DownloadProgressModal() {
  const closeRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [downloadPrompted, setDownloadPrompted] = useState(false);
  const { exportModal, isCommitting } = useCheckout();
  const { addToast } = useToasts();

  const { exportBatch: polledExportBatch, isError } = useExportBatchData({
    /* NOTE: default '' not used if 'enabled' is false below */
    exportBatchUuid: exportModal.exportBatch?.uuid ?? '',
    refetchInterval: 5000,
    enabled:
      !!exportModal.exportBatch?.uuid &&
      exportModal.step === EXPORT_STEPS.DOWNLOAD_PROGRESS,
  });

  const handleConfirmCancel = exportModal.closeExportModal;

  const delayedCloseModal = useCallback(() => {
    closeRef.current = setTimeout(() => {
      exportModal.closeExportModal();
    }, CLOSE_MODAL_TIME);
  }, [exportModal]);

  const closeWithFailure = useCallback(() => {
    addToast(MESSAGES.EXPORT_BATCH_FAILED, 'error');
    delayedCloseModal();
  }, [addToast, delayedCloseModal]);

  const handleConfirmSubmit = async () => {
    await exportModal.initializeExportModal({
      newExportBatchPlaceholder: exportModal.exportBatchPlaceholder,
      step: EXPORT_STEPS.DOWNLOAD_PROGRESS,
    });
  };

  useEffect(() => {
    async function updateProgress() {
      if (!polledExportBatch) {
        return;
      }

      if (exportModal.step === EXPORT_STEPS.DOWNLOAD_PROGRESS) {
        if (!downloadPrompted && polledExportBatch.status === 'COMPLETE') {
          setDownloadPrompted(true);
          const { error } = await downloadWithTimeout(
            polledExportBatch.downloadUrl,
            polledExportBatch.downloadFileName,
            { timeout: 10000 },
          );
          if (error) {
            addToast(MESSAGES.CHECKOUT_DOWNLOAD_FAILED, 'error');
          }
          delayedCloseModal();
        } else if (polledExportBatch.status === 'ERROR') {
          closeWithFailure();
        }
      }
    }
    void updateProgress();
  }, [
    polledExportBatch,
    delayedCloseModal,
    exportModal.step,
    downloadPrompted,
    closeWithFailure,
    addToast,
  ]);

  const icons = {
    COMPLETE: (
      <CheckCircleOutlineIcon
        color="success"
        sx={{ width: 50, height: 50, margin: '1rem 0 2rem' }}
      />
    ),
    PROCESSING: (
      <DustProgressLoader className="mt-1 mb-2" height={50} width={50} />
    ),
    ERROR: (
      <ErrorOutlineIcon
        color="error"
        sx={{ width: 50, height: 50, margin: '1rem 0 2rem' }}
      />
    ),
  };

  const exportStatus: StatusType = useMemo(() => {
    if (exportModal.exportBatchError || isError) {
      return 'ERROR';
    }
    return (polledExportBatch?.status as StatusType) || 'PROCESSING';
  }, [exportModal.exportBatchError, polledExportBatch, isError]);

  const errorMessage = useMemo(() => {
    if (exportModal.exportBatchError) {
      return exportModal.exportBatchError;
    }
    return exportStatus === 'ERROR'
      ? 'An error occured with the download. Please try again.'
      : null;
  }, [exportStatus, exportModal.exportBatchError]);

  if (!exportModal.isExportModalOpen) {
    return null;
  }

  const modalProps = {
    [EXPORT_STEPS.CONFIRM]: {
      title: 'Are you Sure?',
      children: (
        <div className={styles.exportConfirm}>
          If you download this export, a copy of everything associated with
          Things in this export will be written to the destination file. The
          checked in or out status of Things in this export will not change.
        </div>
      ),
      footerProps: {
        onSubmit: handleConfirmSubmit,
        cancelLabel: 'Cancel',
        submitLabel: isCommitting ? '' : 'Yes, Continue',
        onCancel: handleConfirmCancel,
        showCancel: true,
        loading: isCommitting,
      },
    },
    [EXPORT_STEPS.DOWNLOAD_PROGRESS]: {
      title: 'Download Progress',
      children: (
        <div className="flex-col items-center w-full">
          <h3 className="h3 mb-1 mt-2">{errorMessage || 'Export Summary'}</h3>
          <h4 className="h4 text-center" style={{ margin: '0 3rem 1rem' }}>
            {exportModal.exportBatchPlaceholder?.name}
          </h4>
          {exportModal.exportBatchPlaceholder?.thingCount && (
            <div className="mb-1">
              Qty:{' '}
              <strong>{exportModal.exportBatchPlaceholder.thingCount}</strong>
            </div>
          )}
          {icons[exportStatus]}
          {exportModal.exportBatchPlaceholder?.description && (
            <span className={styles.downloadBatchDescription}>
              {exportModal.exportBatchPlaceholder?.description}
            </span>
          )}
        </div>
      ),
      onClose:
        exportStatus !== 'PROCESSING'
          ? exportModal.closeExportModal
          : undefined,
    },
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <DustModal open {...modalProps[exportModal.step]} />;
}
