import { camelToTitleCase } from '@/common/utility';
import DustModal from '@/components/Library/DustModal';

export enum ChangeFeatureActions {
  Add = 'add',
  Remove = 'remove',
}

export type ConfirmChangeFeatureDialogState = {
  action: ChangeFeatureActions.Add | ChangeFeatureActions.Remove;
  org: Org;
  feature: Feature;
};

export default function ConfirmChangeFeatureFlagDialog({
  state,
  open = true,
  onConfirm,
  onCancel,
}: {
  state: ConfirmChangeFeatureDialogState;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) {
  const message = `Are you sure you want to ${
    state.action === ChangeFeatureActions.Add ? 'enable' : 'disable'
  } feature "${camelToTitleCase(state.feature.name)}" for account "${
    state.org.name
  }"`;

  return (
    <DustModal
      title="Confirm Feature Flag Change"
      open={open}
      onClose={onCancel}
      footerProps={{
        onCancel,
        onSubmit: onConfirm,
        submitLabel: 'Confirm',
      }}
    >
      <div>{message}</div>
    </DustModal>
  );
}
