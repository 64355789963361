// import { ExpandLess as ExpandLessIcon } from '@mui/icons-material';
// import { IconButton } from '@mui/material';
import { useLocation, useParams /* useNavigate */ } from 'react-router-dom';

import useCatalogData from '@/common/entities/catalogs/useCatalogData';
import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import { routeTitles, UUID_TYPES } from '@/common/routes';
import CheckOutButton from '@/components/Composed/ShoppingCart/CheckOutButton';

import { LogoImage } from './LogoImage';
import ProfileMenu from './ProfileMenu';
import styles from './TopNav.module.css';

export default function TopNav() {
  const location = useLocation();
  // const navigate = useNavigate();
  const { catalogId } = useParams();

  const routeInfo = routeTitles(location.pathname);

  // const parentInfo = parent ? routeTitles(parent) : null;
  const needsCatalogData =
    routeInfo?.schema?.includes(UUID_TYPES.CATALOG) ?? false;

  const { catalog, isSuccess: catalogSuccess } = useCatalogData({
    catalogUuid: catalogId ?? '',
    enabled: needsCatalogData && !!catalogId,
  });

  const queriedData = {
    catalog,
    thing:
      null /* Not querying for thing right now, though it's a type-allowed schema option for `title` */,
  };

  const titleData = routeInfo.schema?.map((key) => queriedData[key]) ?? [];

  const { org, isLoading: isAccountLoading } = useOrgData();

  const isLoading = isAccountLoading || (!catalogSuccess && needsCatalogData);

  // TMP - take out until we hear we need it back
  // /**
  //  * We check if there is a previous path (with parameters) that matches the parent
  //  * If so we navigate using that path to preserve the params
  //  */
  // const handleBackNavigate = () => {
  //   const historyPrevState = window.history.state.usr?.previous;
  //   const path =
  //     backButtonRoute ||
  //     // Previous URL coming from useNavigateWithParams
  //     (typeof historyPrevState === 'string' &&
  //     parent &&
  //     historyPrevState.includes(parent)
  //       ? historyPrevState
  //       : parent);
  //
  //   if (path && path !== location.pathname) navigate(path);
  //   else navigate(-1);
  // };

  return (
    <nav className={styles.nav}>
      {!isLoading && org && (
        <>
          {/* TMP - take out until we hear we want it back for some reason *}
          {/* <IconButton */}
          {/*   onClick={handleBackNavigate} */}
          {/*   sx={{ color: 'var(--contrast-text-color)' }} */}
          {/* > */}
          {/*   <ExpandLessIcon */}
          {/*     fontSize="large" */}
          {/*     sx={{ transform: 'rotate(-90deg)' }} */}
          {/*   /> */}
          {/* </IconButton> */}
          {routeInfo.topNavigationTitle && (
            <h1 className="h1">{routeInfo.topNavigationTitle(titleData)}</h1>
          )}
          <span className="flex-1" />
          {org?.isFeatureEnabled(FEATURE_FLAGS.MESH) && <CheckOutButton />}
          <div className={styles.clientLogoBox}>
            <LogoImage org={org} />
            <ProfileMenu />
          </div>
        </>
      )}
    </nav>
  );
}
