import { useRef } from 'react';

import { TextField, Button } from '@mui/material';
import { useFormik } from 'formik';

import { addAnnotationSchema } from '@/common/entities/transactions/schemas';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import { Mixpanel } from '@/mPanel';
import useRequest from '@/services/requests/useRequest';

type Props = {
  transaction: Transaction;
  onClose: () => void;
};

export default function AddAnnotation({ transaction, onClose }: Props) {
  const { transactionsApi } = useRequest();
  const startTime = useRef(Date.now());

  const formik = useFormik({
    initialValues: {
      annotation: '',
    },
    validationSchema: addAnnotationSchema,
    onSubmit: async (values) => {
      const result = await transactionsApi.addAnnotation({
        transactionUuid: transaction.uuid,
        annotation: values.annotation,
      });

      const trackingData = {
        'Transaction Id': transaction.uuid,
        'Transaction Type': transaction.transactionType,
        Verified: transaction.isVerified,
        Outcome: transaction.outcomeLabel,
        'Date Created': transaction.createdAt,
        'Time Taken (s)': Math.floor((Date.now() - startTime.current) / 1000),
      };

      if (!result?.error) {
        Mixpanel.track(
          'Added Annotation to Transaction',
          /* guard against mutation and future refactors */ { ...trackingData },
        );
        onClose();
      } else {
        Mixpanel.track('Added Annotation to Transaction Failed', {
          ...trackingData,
          'Reason for Failure':
            result.data?.message ?? result.message ?? 'unknown',
        });
      }
    },
  });
  return (
    <div style={{ padding: '0 1rem 1rem 1rem' }}>
      <TextField
        error={!!formik.errors.annotation}
        fullWidth
        helperText={formik.errors.annotation}
        margin="normal"
        minRows={2}
        multiline
        name="annotation"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        placeholder="Comment..."
        size="small"
        value={formik.values.annotation}
        autoFocus
      />
      <div className="flex-row gap-1 justify-end">
        <Button onClick={onClose} size="small">
          Cancel
        </Button>
        <DustLoadingButton
          disabled={!formik.values.annotation || !formik.isValid}
          loading={formik.isSubmitting}
          onClick={formik.submitForm}
          size="small"
        >
          Add
        </DustLoadingButton>
      </div>
    </div>
  );
}
