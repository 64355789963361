import {
  ComponentType,
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
} from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { IconButton } from '@mui/material';
import { nanoid } from 'nanoid';

import styles from './Components.module.css';
import { combineClass } from './utility';

function SortableItem({
  id,
  child,
  rowClass,
}: {
  id: string;
  child: ReactNode;
  rowClass: string;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      className={combineClass(styles.sortableRowCont, rowClass)}
      ref={setNodeRef}
      style={style}
    >
      <IconButton {...attributes} {...listeners}>
        <DragIndicatorIcon />
      </IconButton>
      {child}
    </div>
  );
}

type DragListItem<T> = {
  id?: string;
  uuid?: string;
  child?: ComponentType<any>;
} & T;

type Props<CompProps> = {
  items: DragListItem<CompProps>[];
  ListItemComponent?: ComponentType<CompProps>;
  onDragEnd?: (evt: DragEndEvent) => void;
  setItems?: Dispatch<SetStateAction<DragListItem<CompProps>[]>>;
  rowClass?: string;
};

export default function DustDraggableList<T>({
  items,
  onDragEnd,
  setItems,
  rowClass = '',
  ListItemComponent,
}: Props<T>) {
  // Draggable requires an unique id. Either use a pre-specific id/uuid OR generate as needed
  const itemKeys = useMemo(
    () => items.map((i) => i.id ?? i.uuid ?? nanoid()),
    [items],
  );

  const handleDragEnd =
    onDragEnd ??
    ((evt: DragEndEvent) => {
      const { active, over } = evt;
      if (active.id !== over?.id && setItems) {
        setItems((itemList: typeof items) => {
          const oldIndex = itemKeys.indexOf(active.id as string);
          const newIndex = itemKeys.indexOf(over?.id as string);
          return arrayMove(itemList, oldIndex, newIndex);
        });
      }
    });

  const determineChild = (key: string, idx: number) => {
    // Either use the specific child component provided; a top level specified component; or a generic
    const ListComp = items[idx].child ?? ListItemComponent;
    return (
      <SortableItem
        child={ListComp ? <ListComp {...items[idx]} /> : null}
        id={key}
        key={key}
        rowClass={rowClass}
      />
    );
  };
  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext items={itemKeys}>
        {itemKeys.map(determineChild)}
      </SortableContext>
    </DndContext>
  );
}
