import { useState } from 'react';

import { useFormik } from 'formik';

import { getThingFileFieldsFromType } from '@/common/entities/things/models';
import { addThingFileSchema } from '@/common/entities/things/schemas';
import { TypedFileEntry } from '@/common/entities/things/typedefs';
import { THING_TYPE_FIELD_TYPES } from '@/common/entities/thingTypes/constants';
import { ThingType } from '@/common/entities/thingTypes/typedefs';
import useToasts from '@/common/hooks/useToasts';
import {
  FILE_TYPE_OPTIONS,
  FILE_EXTENSIONS_FROM_MIME,
} from '@/components/Library/constants';
import DustFieldLabel from '@/components/Library/DustFieldLabel';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustFileUploadMulti from '@/components/Library/DustFileUploadMulti';
import DustModal from '@/components/Library/DustModal';
import { combineClass } from '@/components/Library/utility';
import useRequest from '@/services/requests/useRequest';

import ThingPrimaryImage from './ThingPrimaryImage';

const typesToShow = {
  [FILE_TYPE_OPTIONS.ALL]: [
    THING_TYPE_FIELD_TYPES.IMAGE,
    THING_TYPE_FIELD_TYPES.FILE,
  ],
  [FILE_TYPE_OPTIONS.FILE]: [THING_TYPE_FIELD_TYPES.FILE],
  [FILE_TYPE_OPTIONS.CSV]: [THING_TYPE_FIELD_TYPES.FILE],
  [FILE_TYPE_OPTIONS.IMAGE]: [THING_TYPE_FIELD_TYPES.IMAGE],
};

const getFormInitialValues = (
  thingType: ThingType | null,
  thing: Thing,
  fileType: keyof typeof typesToShow,
) => {
  const uuids = thing?.files.map((f) => f.thingMetadataTypeUuid);
  const filenames = thing?.files.reduce<Record<string, string>>(
    (files, current) => ({
      ...files,
      [current.thingMetadataTypeUuid]: `${current.filename}.${
        FILE_EXTENSIONS_FROM_MIME[current.fileType]
      }`,
    }),
    {},
  );
  return {
    primaryImage: null,
    files: {
      required: false,
      value: {} as Record<string, File>,
      name: 'Files and Images',
      type: 'all',
      uuid: '',
    },
    typedFiles: getThingFileFieldsFromType(thingType, false)
      .filter((f) => typesToShow[fileType].includes(f.type))
      .map<TypedFileEntry & { disabled: boolean }>((field) => ({
        ...field,
        type: field.type,
        disabled: uuids.includes(field.uuid),
        name:
          uuids.includes(field.uuid) && !field.multiple
            ? filenames[field.uuid]
            : field.name,
      })),
  };
};

type Props = {
  things: Thing[];
  thingType?: ThingType | null;
  open: boolean;
  setOpen: (e: boolean) => void;
  showAddFiles?: boolean;
  showPrimary?: boolean;
  fileType?: keyof typeof typesToShow;
  onFileUploadSuccess?: () => void;
};

export default function ThingFileModal({
  things,
  thingType = null,
  open,
  setOpen,
  showAddFiles = true,
  showPrimary = true,
  fileType = FILE_TYPE_OPTIONS.ALL,
  onFileUploadSuccess = () => {},
}: Props) {
  const primaryDisabled = !things.every((thing) => thing.canChangePrimaryImage);

  const { thingsApi } = useRequest();
  const { addToast } = useToasts();
  const [submitting, setSubmitting] = useState(false);

  const closeModal = () => {
    // Reset values before close
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    formik.resetForm({
      values: getFormInitialValues(thingType, things?.[0], fileType),
    });
    setSubmitting(false);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: getFormInitialValues(thingType, things?.[0], fileType),
    validationSchema: addThingFileSchema,
    validateOnChange: true,
    onSubmit: async (formData) => {
      const files = Object.values(formData.files.value);
      const { typedFiles, primaryImage } = formData;

      // Typed files must have values (either directly or as part of their array)
      const changedTypedFiles = typedFiles.filter((f) =>
        Array.isArray(f.value) ? f.value.length > 0 : !!f.value,
      );

      if (
        !primaryImage &&
        files.length === 0 &&
        changedTypedFiles.length === 0
      ) {
        return closeModal();
      }

      setSubmitting(true);

      // Upload typed Files
      const typedFileUploadQueries = changedTypedFiles.map((fileField) =>
        thingsApi.attachTypedFiles({
          thingUuids: things.map((t) => t.uuid),
          files: fileField.multiple
            ? Object.values(fileField.value)
            : [fileField.value],
          fieldTypeUuid: fileField.uuid,
        }),
      );
      await Promise.all(typedFileUploadQueries);

      if (files.length > 0) {
        await thingsApi.attachTypedFiles({
          thingUuids: things.map((t) => t.uuid),
          files,
        });
      }

      // Upload and set primary image
      if (primaryImage) {
        const untypedThingUuids = things
          .filter((t) => !t.thingType)
          .map((t) => t.uuid);

        // Upload the image to each untyped thing
        if (untypedThingUuids.length > 0) {
          const primaryUntypedRes = await thingsApi.attachTypedFiles({
            thingUuids: untypedThingUuids,
            files: [primaryImage],
          });

          // Set the image as primary for each untyped thing
          if (!primaryUntypedRes.error) {
            const fileUuid = primaryUntypedRes.data?.[0]?.uuid;

            if (!fileUuid) {
              throw new Error('Invalid file uuid provided');
            }
            const setPrimaryQueries = untypedThingUuids.map((uuid) =>
              thingsApi.updateThingFile({
                thingUuid: uuid,
                fileUuid,
                isPrimary: true,
              }),
            );
            await Promise.all(setPrimaryQueries);
          }
        }

        const typedThings = things.filter((t) => t.thingType?.uuid);

        // Remove any existing primary images
        const removePrimaryQueries = typedThings
          .filter((t) => !!t.primaryImage)
          .map((t) =>
            thingsApi.removeThingFile({
              thingUuid: t.uuid,
              fileUuid: (t.primaryImage as ThingFile).uuid,
              suppressToast: true,
            }),
          );
        await Promise.all(removePrimaryQueries);

        // Add the images to the DUST Primary Image field
        const primaryTypedQueries = typedThings.map((t) =>
          thingsApi.attachTypedFiles({
            thingUuids: [t.uuid],
            files: [primaryImage],
            fieldTypeUuid: t.primaryImageFieldTypeUuid,
          }),
        );

        await Promise.all(primaryTypedQueries);
      }
      onFileUploadSuccess();
      setSubmitting(false);
      closeModal();
    },
  });

  const handleReject = (errors: string[]) => {
    errors.forEach((error) => addToast(error, 'error'));
  };

  const fieldNames = {
    [FILE_TYPE_OPTIONS.CSV]: 'CSV Files',
    [FILE_TYPE_OPTIONS.ALL]: 'Files and Images',
    [FILE_TYPE_OPTIONS.FILE]: 'Files',
    [FILE_TYPE_OPTIONS.IMAGE]: 'Images',
  };

  const placeholderNames = {
    [FILE_TYPE_OPTIONS.CSV]: 'File Placeholders',
    [FILE_TYPE_OPTIONS.ALL]: 'Files and Image Placeholders',
    [FILE_TYPE_OPTIONS.FILE]: 'File Placeholders',
    [FILE_TYPE_OPTIONS.IMAGE]: 'Image Placeholders',
  };

  return (
    <DustModal
      footerProps={{
        submitLabel: 'Save',
        onCancel: closeModal,
        loading: submitting,
        submitFormId: 'attach-thing-files',
      }}
      onClose={closeModal}
      open={open}
      title={`Attach ${fieldNames[fileType]}`}
    >
      <form onSubmit={formik.handleSubmit} id="attach-thing-files">
        {showPrimary && (
          <ThingPrimaryImage
            formik={formik}
            disabled={primaryDisabled}
            disabledMessage="Primary image on Thing can't be changed"
          />
        )}
        {showAddFiles && (
          <>
            {!!thingType && (
              <>
                <DustFieldLabel
                  tag="h3"
                  className={combineClass(showPrimary && 'mt-2')}
                  label={`${thingType.name} ${placeholderNames[fileType]}`}
                />
                {formik.values.typedFiles.map((file, idx) =>
                  file.multiple ? (
                    <DustFileUploadMulti
                      fileType={file.type}
                      formik={formik}
                      key={file.name}
                      onReject={(res: string[]) => handleReject(res)}
                      label={`${file.name}${file.required ? '*' : ''}`}
                      name={`typedFiles.${idx}.value`}
                    />
                  ) : (
                    <DustFileUploadField
                      disabled={file.disabled}
                      fileType={file.type}
                      formik={formik}
                      key={file.name}
                      onReject={(res) => handleReject(res)}
                      label={`${file.name}${file.required ? '*' : ''}`}
                      name={`typedFiles.${idx}.value`}
                    />
                  ),
                )}
              </>
            )}
            <DustFieldLabel
              tag="h3"
              className={combineClass((showPrimary || !!thingType) && 'mt-2')}
              label={
                thingType
                  ? `Additional ${fieldNames[fileType]}`
                  : fieldNames[fileType]
              }
            />
            <DustFileUploadMulti
              fileType={fileType}
              formik={formik}
              onReject={(res) => handleReject(res)}
              label={
                thingType
                  ? `Other ${fieldNames[fileType]}`
                  : fieldNames[fileType]
              }
              name="files.value"
            />
          </>
        )}
      </form>
    </DustModal>
  );
}
