import { FormEvent, useCallback } from 'react';

import { Button } from '@mui/material';
import { useAuth } from 'react-oidc-context';

import { ROUTES } from '@/common/routes';

export type Props = { returnPath?: string; onAuthError: () => void };

export default function PreLoginRedirectForm({
  returnPath,
  onAuthError,
}: Props) {
  const auth = useAuth();

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      auth
        .signinRedirect({
          redirect_uri: window.location.origin + (returnPath ?? ROUTES.ROOT),
        })
        .catch((err) => {
          // Log out error for developer
          console.error('Error redirecting to login - ', err);
          onAuthError();
        });
    },
    [auth, returnPath, onAuthError],
  );

  return (
    <form onSubmit={handleSubmit}>
      {/* TODO: follow-up ticket, unlocated yet...
      <TextField
        label="Organization Name"
        margin="normal"
        name="org-name"
        size="small"
        variant="outlined"
        fullWidth
      />
      */}
      <Button type="submit" variant="contained" fullWidth>
        Proceed to Login
      </Button>
    </form>
  );
}
