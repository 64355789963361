import {
  ChangeEventHandler,
  ComponentProps,
  KeyboardEvent,
  useRef,
  useState,
} from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TextField, TextFieldProps, Tooltip } from '@mui/material';

import styles from './Components.module.css';
import DustLoadingButton from './DustLoadingButton';
import { combineClass } from './utility';

type Props = {
  value?: string;
  placeholder?: string;
  isInvalid?: (e?: string) => string | null;
  onSave: (val: string, onSuccess: () => void) => void;
  tag?: keyof JSX.IntrinsicElements;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
  editButtonProps?: Partial<ComponentProps<typeof DustLoadingButton>>;
  saveButtonProps?: Partial<ComponentProps<typeof DustLoadingButton>>;
  cancelButtonProps?: Partial<ComponentProps<typeof IconButton>>;
};
export default function DustEditableText({
  value = '',
  placeholder = '',
  onSave,
  isInvalid = (e?: string) => (!e ? 'Value is required' : null),
  // TODO: use... onDelete,
  tag: Tag = 'div',
  className = '',
  loading = false,
  disabled = false,
  //
  textFieldProps = {},
  editButtonProps = {},
  saveButtonProps = {},
  cancelButtonProps = {},
}: Props) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const fieldRef = useRef<HTMLInputElement | null>(null);

  const reset = () => {
    setErrorMessage(null);
    setIsEditing(false);
  };

  const handleChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (evt) => {
    setErrorMessage(isInvalid(evt.target.value));
  };

  const saveChanges = () => {
    if (
      !isInvalid(fieldRef.current?.value) &&
      typeof fieldRef.current?.value === 'string'
    ) {
      onSave(fieldRef.current.value, () => reset());
    }
  };

  const handleKeyDown = (evt: KeyboardEvent) =>
    evt.key === 'Enter' && saveChanges();
  const handleOnMouseEnter = () => setIsHovering(true);
  const handleOnMouseLeave = () => setIsHovering(false);

  return (
    <div
      className={styles.editTextRow}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {isEditing && (
        <>
          <Tooltip title={errorMessage ?? ''}>
            <TextField
              onChange={handleChange}
              error={!!errorMessage}
              defaultValue={value}
              variant="standard"
              onKeyDown={handleKeyDown}
              disabled={disabled}
              {...textFieldProps}
              inputRef={fieldRef}
            />
          </Tooltip>
          <DustLoadingButton
            disabled={!!errorMessage}
            color="primary"
            isIcon
            loading={loading}
            onClick={saveChanges}
            tooltipProps={{ title: 'Save field' }}
            {...saveButtonProps}
          >
            <CheckIcon />
          </DustLoadingButton>
          <IconButton onClick={reset} {...cancelButtonProps}>
            <CloseIcon />
          </IconButton>
        </>
      )}
      {!isEditing && (
        <div className={styles.editTextStatic}>
          <Tooltip enterDelay={700} title={value || placeholder}>
            <Tag
              className={combineClass(
                className,
                !value && styles.editTextPlaceholder,
              )}
            >
              {value || placeholder}
            </Tag>
          </Tooltip>
          {!disabled && isHovering && (
            <DustLoadingButton
              color="primary"
              isIcon
              loading={loading}
              onClick={() => setIsEditing(true)}
              tooltipProps={{ title: 'Edit field' }}
              className={styles.showOnHover}
              {...editButtonProps}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <EditIcon sx={{ fontSize: '16px' }} />
            </DustLoadingButton>
          )}
        </div>
      )}
    </div>
  );
}
