/**
 * Wraps child component in an element to block the propagation of clicks and keypresses
 * Useful when you have an interactive component in another interactive component and you don't
 * want to worry about events from the child finding their way up to the parent
 */
function DustEventBlocker(props: { [x: string]: any }) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={(evt) => evt.stopPropagation()}
      onKeyPress={(evt) => evt.stopPropagation()}
      {...props}
    />
  );
}

export default DustEventBlocker;
