import { useState, useEffect, useMemo } from 'react';

import { useInfiniteQuery } from 'react-query';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { atom } from 'recoil';

import {
  isFilterDefaulted,
  getChangedFilters,
} from '@/common/utilities/filters';
import {
  pageCountGetNextPageParam,
  useGetNextPage,
} from '@/common/utilities/table';
import useRequest from '@/services/requests/useRequest';
import { SortOptions } from '@/services/useSort';

import { TRANSACTION_TYPES as TX_TYPE_LOOKUP } from './constants';

const STALE_TIME = 5 * 60 * 1000;
export const TRANSACTIONS_PARAM_KEY = 'transaction-filters';

export enum FILTER_NAMES {
  CATALOG = 'catalogUuids',
  FACILITY = 'facilityUuids',
  TRANSACTION_TYPES = 'transactionTypes',
  USERNAME = 'userUuids',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
}

export const FILTER_LABELS = {
  [FILTER_NAMES.CATALOG]: 'Catalogs',
  [FILTER_NAMES.FACILITY]: 'Facilities',
  [FILTER_NAMES.TRANSACTION_TYPES]: 'Transaction Types',
  [FILTER_NAMES.USERNAME]: 'Usernames',
  [FILTER_NAMES.START_DATE]: 'Start Date',
  [FILTER_NAMES.END_DATE]: 'End Date',
};

export type TransactionFilters = {
  catalogUuids: string[];
  facilityUuids: string[];
  transactionTypes: string[];
  userUuids: string[];
  startDate: Date | null;
  endDate: Date | null;
};

export const FILTER_DEFAULTS: TransactionFilters = {
  [FILTER_NAMES.CATALOG]: [],
  [FILTER_NAMES.FACILITY]: [],
  [FILTER_NAMES.TRANSACTION_TYPES]: [],
  [FILTER_NAMES.USERNAME]: [],
  [FILTER_NAMES.START_DATE]: null,
  [FILTER_NAMES.END_DATE]: null,
};

export const TRANSACTION_SORT_OPTIONS = Object.freeze({
  createdAtNewest: {
    label: 'Newest Transaction First',
    params: { sort: '-createdAt' },
    column: 'date',
    direction: 'desc',
  },

  createdAtOldest: {
    label: 'Oldest Transaction First',
    params: { sort: 'createdAt' },
    column: 'date',
    direction: 'asc',
  },
  createdByaz: {
    label: 'Created by (A-Z)',
    params: { sort: 'createdBy' },
    column: 'createdBy',
    direction: 'asc',
  },
  createdByza: {
    label: 'Created by (Z-A)',
    params: { sort: '-createdBy' },
    column: 'createdBy',
    direction: 'desc',
  },
  thingaz: {
    label: 'Thing Title (A-Z)',
    params: { sort: 'thingTitle' },
    column: 'thingTitle',
    direction: 'asc',
  },
  thingza: {
    label: 'Thing Title (Z-A)',
    params: { sort: '-thingTitle' },
    column: 'thingTitle',
    direction: 'desc',
  },
  facilityaz: {
    label: 'Facility Name (A-Z)',
    params: { sort: 'facility' },
    column: 'facilityUuid',
    direction: 'asc',
  },
  facilityza: {
    label: 'Facility Name (Z-A)',
    params: { sort: '-facility' },
    column: 'facilityUuid',
    direction: 'desc',
  },
  catalogaz: {
    label: 'Catalog Name (A-Z)',
    params: { sort: 'catalog' },
    column: 'catalogUuid',
    direction: 'asc',
  },
  catalogza: {
    label: 'Catalog Name (Z-A)',
    params: { sort: '-catalog' },
    column: 'catalogUuid',
    direction: 'desc',
  },
} as const);

export const TRANSACTION_SORT_TRACKING_EVENT = 'Sort Transactions';

// TODO: use upcoming TypeScript `satisfies` keyword for this type check
((q: SortOptions) => q)(TRANSACTION_SORT_OPTIONS);

export const transactionsSortAtom = atom<keyof typeof TRANSACTION_SORT_OPTIONS>(
  {
    key: 'transactionsSort',
    default: 'createdAtNewest',
  },
);

// ---------------------------------------------------------------------------
// Utilities

function defaultFilters(): TransactionFilters {
  return {
    ...FILTER_DEFAULTS,
  };
}

/** clears any sort of identifying information from a filter for tracking */
export const filterToTrackingProp = (
  name: keyof TransactionFilters,
  value: Date | string[] | null,
): [string, number | boolean | string] => {
  switch (name) {
    case 'catalogUuids':
      return ['Filter by Catalog', Array.isArray(value) ? value.length : 0];
    case 'facilityUuids':
      return ['Filter by Facility', Array.isArray(value) ? value.length : 0];
    case 'userUuids':
      return ['Filter by Username', Array.isArray(value) ? value.length : 0];
    case 'endDate':
      return ['Filter by End Date', !!value];
    case 'startDate':
      return ['Filter by Start Date', !!value];
    case 'transactionTypes':
      return [
        'Filter by Transaction Type',
        Array.isArray(value)
          ? value
              .map((txType) => TX_TYPE_LOOKUP[txType]?.label ?? 'Unknown')
              .join(',')
          : '',
      ];
    default:
      return ['Unrecognized filter', name];
  }
};

// ---------------------------------------------------------------------------
// Main component

/** Wrapper for transaction data fetching and filter handling */
export default function useTransactionsData({
  thingUuid,
  perPage = 50,
  sort,
}: {
  thingUuid?: string | null;
  perPage?: number;
  sort?: string;
}) {
  const { transactionsApi, QUERY_KEYS } = useRequest();

  // Set initial filters from the URL
  const [searchParams, setSearchParams] = useSearchParams();
  const urlFilters: TransactionFilters = {
    ...defaultFilters(),
    ...JSON.parse(searchParams.get(TRANSACTIONS_PARAM_KEY) ?? '{}'),
  };

  // ---------------------------------------------------------------------------
  // FILTER HANDLING
  const [filters, setFilters] = useState(urlFilters);

  /** Set a single filter */
  const setFilter = <K extends keyof TransactionFilters>(
    key: K,
    val: TransactionFilters[K],
  ) => {
    setFilters((prev) => ({ ...prev, [key]: val }));
  };

  // Map all filters to the URL for persistence
  useEffect(() => {
    const params = createSearchParams(searchParams);
    params.set(TRANSACTIONS_PARAM_KEY, JSON.stringify(filters));
    setSearchParams(params, { replace: true });
  }, [filters, searchParams, setSearchParams]);

  /** Reset a single filter */
  const resetFilter = (key: keyof TransactionFilters) => {
    setFilter(key, defaultFilters()[key]);
  };

  /** Reset all filters */
  const resetFilters = () => {
    setFilters(defaultFilters());
  };

  // ---------------------------------------------------------------------------
  // Execute Query and handle data formatting

  // Query differently depending on if a search string is provided
  const queryParams = {
    catalogUuids: !isFilterDefaulted(
      filters.catalogUuids,
      defaultFilters(),
      FILTER_NAMES.CATALOG,
    )
      ? filters.catalogUuids
      : undefined,
    facilityUuids: !isFilterDefaulted(
      filters.facilityUuids,
      defaultFilters(),
      FILTER_NAMES.FACILITY,
    )
      ? filters.facilityUuids
      : undefined,
    userUuids: !isFilterDefaulted(
      filters.userUuids,
      defaultFilters(),
      FILTER_NAMES.USERNAME,
    )
      ? filters.userUuids
      : undefined,
    transactionTypes: !isFilterDefaulted(
      filters.transactionTypes,
      defaultFilters(),
      FILTER_NAMES.TRANSACTION_TYPES,
    )
      ? filters.transactionTypes
      : undefined,
    startDate: !isFilterDefaulted(
      filters.startDate,
      defaultFilters(),
      FILTER_NAMES.START_DATE,
    )
      ? new Date(filters.startDate ?? new Date()).toISOString()
      : undefined,
    endDate: !isFilterDefaulted(
      filters.endDate,
      defaultFilters(),
      FILTER_NAMES.END_DATE,
    )
      ? new Date(filters.endDate ?? new Date()).toISOString()
      : undefined,
    sort,
    perPage,
    thingUuids: thingUuid ? [thingUuid] : undefined,
  };

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [...QUERY_KEYS.TRANSACTIONS, queryParams],
    ({ pageParam = 1 }) =>
      transactionsApi.search({ ...queryParams, page: pageParam }),
    {
      getNextPageParam: pageCountGetNextPageParam,
      staleTime: STALE_TIME,
    },
  );

  const isError = data?.pages?.some((page) => page.error);

  const transactions = useMemo(
    () =>
      data?.pages.reduce<Transaction[]>(
        (combined, current) =>
          combined.concat(current.error ? [] : current.data),
        [],
      ) ?? [],
    [data],
  );

  const pageZero = data?.pages?.[0];
  const totalItems = pageZero && !pageZero.error ? pageZero.total : undefined;

  const getNextPage = useGetNextPage(
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  );

  return {
    transactions,
    totalItems,
    isError,
    isLoading,
    isFetching: isLoading || isFetchingNextPage,
    isSuccess,
    getNextPage,
    setFilter,
    resetFilter,
    resetFilters,
    filters,
    appliedFilters: getChangedFilters(filters, defaultFilters()),
    queryParams,
  };
}
