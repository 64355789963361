import { orgModel } from '@/common/entities/orgs/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import {
  DustArrayResponse,
  DustSingleResponse,
} from '@/services/requests/types';
import { formatAPIError, formatAPIReturn } from '@/services/utility';

import { MESSAGES } from '../constants';

/** Factory function for producing the accounts API */
export default class OrgApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Fetch a list of organizations */
  async getOrgs() {
    return this.apiService
      .request({
        url: `/api/accounts`,
        method: 'GET',
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, orgModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Retrieve active orgs details */
  async getCurrentOrg() {
    return this.apiService
      .request({
        url: `/api/accounts/${this.apiService.orgId}?idType=oidc`,
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, orgModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Edit an organizations logo image */
  async putOrgLogo(accountUuid: string, file: File) {
    // create form data
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.apiService
      .request({
        url: `api/accounts/${accountUuid}/primaryImage`,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      })
      .finally(() => {
        this.invalidate.org(accountUuid);
      });
  }
}
