import { useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import useThingsData from '@/common/entities/things/useThingsData';
import { ROUTES } from '@/common/routes';
import { includes } from '@/common/utility';
import DustHeaderStepper from '@/components/Library/DustHeaderStepper';

import StepDetails from './StepDetails';
import StepScan from './StepScan';

enum AddSteps {
  Details = 'details',
  Scan = 'scan',
}

export default function ThingAdd() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const step = useMemo(() => {
    const urlStep = searchParams.get('step') ?? '';
    return includes(Object.values(AddSteps), urlStep)
      ? (urlStep as AddSteps)
      : AddSteps.Details;
  }, [searchParams]);

  const urlThingUuids = useMemo(
    () => searchParams.get('thingUuids')?.split(',') ?? [],
    [searchParams],
  );

  const [catalogUuid, setCatalogUuid] = useState('');
  const [things, setThings] = useState([] as Thing[]);

  // Fetch required data
  const { things: apiThings, isLoading: isApiThingsLoading } = useThingsData({
    fixedParams: {
      thingUuids: urlThingUuids,
    },
    fetchAll: true,
    enabled: things.length === 0 && step === 'scan' && urlThingUuids.length > 0,
  });

  // Thing UUIDs fetched from database are in name order. Reorder them by url order
  const refetchedThings = useMemo(() => {
    if (apiThings.length === 0 || urlThingUuids.length === 0) return [];
    return apiThings.sort(
      (a, b) => urlThingUuids.indexOf(a.uuid) - urlThingUuids.indexOf(b.uuid),
    );
  }, [apiThings, urlThingUuids]);

  const headerSteps = {
    details: { label: 'Add Thing Details' },
    scan: { label: 'Bind to DUST (optional)' },
  };

  const setStep = useCallback(
    (newStep: AddSteps, newThings?: Thing[]) => {
      setSearchParams(
        {
          step: newStep,
          ...(newThings &&
            newThings.length > 0 && {
              thingUuids: newThings.map((t) => t.uuid).join(','),
            }),
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const handleFinishThingDetails = (thingsToSubmit: Thing[]) => {
    setCatalogUuid(thingsToSubmit[0].catalogUuid);
    setThings(thingsToSubmit);
    setStep(AddSteps.Scan, thingsToSubmit);
  };

  useEffect(() => {
    if (step === 'scan' && things.length === 0) {
      if (refetchedThings.length > 0) {
        setThings(refetchedThings);
        setCatalogUuid(refetchedThings[0].catalogUuid);
      } else if (!isApiThingsLoading) {
        setStep(AddSteps.Details);
      }
    }
  }, [things, isApiThingsLoading, refetchedThings, step, setStep]);

  const stepComponents: { [x in AddSteps]: React.ReactElement | boolean } = {
    details: <StepDetails onFinish={handleFinishThingDetails} />,
    scan: things.length > 0 && (
      <StepScan
        catalogUuid={catalogUuid}
        onFinish={() =>
          navigate(
            things.length > 1
              ? ROUTES.THINGS(catalogUuid)
              : ROUTES.THING(things[0].uuid),
          )
        }
        things={things}
      />
    ),
  };

  return (
    <>
      <DustHeaderStepper activeStep={step} steps={headerSteps} />
      {stepComponents[step]}
    </>
  );
}
