import { GridColDef } from '@mui/x-data-grid';

import { formatDateForDisplay } from '@/common/dates';
import { filterImages, filterFiles } from '@/common/entities/things/utility';
import { booleanSort } from '@/common/utilities/sort';
import DustDustedIcon from '@/components/Library/Icons/DustDustedIcon';
import DustFileIcon from '@/components/Library/Icons/DustFileIcon';
import DustImageIcon from '@/components/Library/Icons/DustImageIcon';

import styles from './sharedThingColDefs.module.css';

// -----------------------------------------------------------------------------
// This file shares col defs for the Thing row type between a few different
// tables that use them to avoid duplication and improve visual consistency.
// -----------------------------------------------------------------------------

export const idThingColDef: GridColDef<Thing> & GridColDef<Thing> = {
  field: 'id',
  valueGetter: ({ row }) => row.uuid,
  headerName: 'UUID',
  hide: true,
};

export const titleThingColDef: GridColDef<Thing> & GridColDef<Thing> = {
  field: 'title',
  headerName: 'Title',
  minWidth: 100,
  flex: 1,
};

export const dustedThingColDef: GridColDef<Thing> = {
  field: 'dusted',
  valueGetter: ({ row }) => row.isDusted,
  renderCell: ({ value }) =>
    value ? (
      <DustDustedIcon color="primary" sx={{ marginLeft: '.5rem' }} />
    ) : (
      ''
    ),
  headerName: 'DUSTed',
  sortComparator: (a, b) => booleanSort(a, b),
  width: 80,
};

export const thingTypeThingColDef = {
  field: 'thingType',
  valueGetter: ({ row }: { row: Thing | ThingRelationEntry }) =>
    'thingType' in row ? row.thingType.name : null,
  headerName: 'Thing Type',
  flex: 1,
  minWidth: 100,
};

export const createdAtThingColDef: GridColDef<Thing> = {
  field: 'createdAt',
  valueFormatter: ({ value }) => formatDateForDisplay(value),
  headerName: 'Created at',
  flex: 1,
  minWidth: 70,
  maxWidth: 170,
  sortingOrder: ['desc', 'asc'],
};

export const imagesThingColDef: GridColDef<Thing> = {
  field: 'images',
  valueGetter: ({ row }) => row,
  renderCell: ({ formattedValue: thing }) => {
    const files = filterImages(thing?.files);
    return (
      <div className={styles.fileCell}>
        <DustImageIcon color="secondary" /> {files.length}
      </div>
    );
  },
  headerName: 'Images',
  sortComparator: (a, b) =>
    filterImages(a?.files).length - filterImages(b?.files).length,
  flex: 1,
  minWidth: 60,
  maxWidth: 90,
};

export const filesThingColDef: GridColDef<Thing> = {
  field: 'files',
  valueGetter: ({ row }) => row,
  renderCell: ({ row: thing }) => {
    const files = filterFiles(thing?.files);
    return (
      <div className={styles.fileCell}>
        <DustFileIcon color="secondary" /> {files.length}
      </div>
    );
  },
  headerName: 'Files',
  sortComparator: (a, b) =>
    filterFiles(a?.files).length - filterFiles(b?.files).length,
  flex: 1,
  minWidth: 60,
  maxWidth: 90,
};
