import { FEATURE_FLAGS } from '../features/constants';

export type TransactionType = {
  value: string;
  label: string;
  isScan: boolean;
  userDeclarations?: {
    PASS: string;
    WEAR_AND_TEAR: string;
    INCONCLUSIVE: string;
    SUSPECTED_TAMPER: string;
    FLAG_TAMPERED: string;
  };
  dustAnalysis?: {
    MISMATCH: string;
    SUSPECT_TAMPER: string;
    INCONCLUSIVE: string;
    PASS: string;
    UNKNOWN: string;
  };
};

export const TRANSACTION_TYPES: { [x: string]: TransactionType } = {
  ANTI_TAMPER_ANALYSIS: {
    value: 'ANTI_TAMPER_ANALYSIS',
    label: 'Tamper Analysis',
    isScan: false,
    userDeclarations: {
      PASS: 'Pass - No Tampering Evident',
      WEAR_AND_TEAR: 'Normal Wear and Tear',
      INCONCLUSIVE: 'Inconclusive',
      SUSPECTED_TAMPER: 'Suspect Tamper',
      FLAG_TAMPERED: 'Tampered',
    },
    dustAnalysis: {
      MISMATCH: 'Mismatch',
      SUSPECT_TAMPER: 'Suspect Tamper',
      INCONCLUSIVE: 'Inconclusive',
      PASS: 'Pass',
      UNKNOWN: 'Unknown',
    },
  },
  THING_CUSTODIAN_UPDATE: {
    value: 'THING_CUSTODIAN_UPDATE',
    label: 'Reassign',
    isScan: false,
  },
  THING_METADATA_ADD: {
    value: 'THING_METADATA_ADD',
    label: 'Add Thing data field',
    isScan: false,
  },
  THING_METADATA_UPDATE: {
    value: 'THING_METADATA_UPDATE',
    label: 'Update Thing data field',
    isScan: false,
  },
  THING_CREATE: {
    value: 'THING_CREATE',
    label: 'Create Thing',
    isScan: false,
  },
  THING_FILE_ADD: {
    value: 'THING_FILE_ADD',
    label: 'Add Thing file',
    isScan: false,
  },
  THING_FILE_REMOVE: {
    value: 'THING_FILE_REMOVE',
    label: 'Remove Thing file',
    isScan: false,
  },
  BIND: {
    value: 'BIND',
    label: 'Bind',
    isScan: false,
  },
  UNBIND: {
    value: 'UNBIND',
    label: 'Unbind',
    isScan: false,
  },
  DEACTIVATE_DUST: {
    value: 'DEACTIVATE_DUST',
    label: 'Deactivate DUST',
    isScan: false,
  },
  ENROLL: {
    value: 'ENROLL',
    label: 'Enroll',
    isScan: false,
  },
  IDENTIFY: {
    value: 'IDENTIFY',
    label: 'Identify',
    isScan: true,
  },
  VALIDATE: {
    value: 'VALIDATE',
    label: 'Validate',
    isScan: true,
  },
  VERIFY: {
    value: 'VERIFY',
    label: 'Verify',
    isScan: true,
  },
  EXTRACT: {
    value: 'EXTRACT',
    label: 'Extract',
    isScan: true,
  },
  MIGRATE: {
    value: 'MIGRATE',
    label: 'Migrate',
    isScan: false,
  },
  UPDATE_RELATIONSHIP: {
    value: 'UPDATE_RELATIONSHIP',
    label: 'Update Relationship',
    isScan: false,
  },
  SHARE_DIGITAL_ACCESS: {
    value: 'SHARE_DIGITAL_ACCESS',
    label: 'Share Digital Access',
    isScan: false,
  },
  TRANSFER_PHYSICAL_ACCESS: {
    value: 'TRANSFER_PHYSICAL_ACCESS',
    label: 'Transfer Physical Access',
    isScan: false,
  },
  XTAG_LOOKUP: {
    value: 'XTAG_LOOKUP',
    label: 'Lookup by X-tag',
    isScan: false,
  },
} as const;

export const EXTRACT_OUTCOMES = {
  UNKNOWN_ERROR: {
    value: 'UNKNOWN_ERROR',
    label: 'Unknown error',
  },
  SUCCESS: {
    value: 'SUCCESS',
    label: 'Success',
  },
  LOW_KEYPOINTS: {
    value: 'LOW_KEYPOINTS',
    label: 'Low markers',
  },
  BLUR_REJECT: {
    value: 'BLUR_REJECT',
    label: 'Blur reject',
  },
  ERROR: {
    value: 'ERROR',
    label: 'Error',
  },
} as const;

export const MATCH_OUTCOMES = {
  MATCH_SUCCESS: {
    value: 'MATCH_SUCCESS',
    label: 'Match',
  },
  MATCH_NOT_FOUND: {
    value: 'MATCH_NOT_FOUND',
    label: 'No match',
  },
} as const;

export const TRANSACTION_OUTCOMES = {
  ...EXTRACT_OUTCOMES,
  ...MATCH_OUTCOMES,
  DUPLICATE: {
    value: 'DUPLICATE',
    label: 'Duplicate',
  },
  UNKNOWN: {
    value: 'UNKNOWN',
    label: 'Unknown',
  },
} as const;

export const getVisibleTransactionTypes = (org?: Org | null) => {
  const visibleTransactions = [
    TRANSACTION_TYPES.THING_METADATA_UPDATE,
    TRANSACTION_TYPES.THING_CREATE,
    TRANSACTION_TYPES.THING_FILE_ADD,
    TRANSACTION_TYPES.THING_FILE_REMOVE,
    TRANSACTION_TYPES.BIND,
    TRANSACTION_TYPES.DEACTIVATE_DUST,
    TRANSACTION_TYPES.ENROLL,
    TRANSACTION_TYPES.IDENTIFY,
    TRANSACTION_TYPES.VALIDATE,
    TRANSACTION_TYPES.VERIFY,
    TRANSACTION_TYPES.UPDATE_RELATIONSHIP,
  ];

  if (org && org?.isFeatureEnabled?.(FEATURE_FLAGS.ANTITAMPER)) {
    visibleTransactions.push(TRANSACTION_TYPES.ANTI_TAMPER_ANALYSIS);
  }
  if (org && org.sharingEnabled) {
    visibleTransactions.push(TRANSACTION_TYPES.SHARE_DIGITAL_ACCESS);
    visibleTransactions.push(TRANSACTION_TYPES.TRANSFER_PHYSICAL_ACCESS);
  }

  return visibleTransactions;
};

export const VALIDATE_INTENT = {
  ENROLL: {
    value: 'ENROLL',
    label: 'Enroll',
  },
  MOVE: {
    value: 'MOVE',
    label: 'Move',
  },
};
