import { assertNever } from '@/common/utility';
import AppSidebar from '@/components/AppSidebar';

import { ACTIONS, TITLES, MASKING } from './constants';
import EditDataFields from './EditDataFields';
import MultiFileSelect from './MultiFileSelect';

export type SidebarContent = {
  onClose: () => void;
} & (
  | {
      action: typeof ACTIONS.ADD_FIELD | typeof ACTIONS.EDIT_FIELD;
      data: React.ComponentProps<typeof EditDataFields>['data'];
    }
  | {
      action: typeof ACTIONS.MULTI_FILE;
      data: React.ComponentProps<typeof MultiFileSelect>['data'];
    }
);

type Props = {
  thing: Thing;
  thingType?: ThingType | null;
  content?: SidebarContent | null;
  setContent?: (e: SidebarContent | null) => void;
  open: boolean;
  setOpen: (e: boolean) => void;
  promptForVerify: (e: any) => void;
};

export default function ThingSidebar({
  thing,
  thingType,
  content,
  setContent,
  open,
  setOpen,
  promptForVerify,
}: Props) {
  const handleClose = () => {
    setContent?.(null);
    content?.onClose();
  };

  if (content == null) {
    return null;
  }

  let actionContent;

  if (
    content.action === ACTIONS.ADD_FIELD ||
    content.action === ACTIONS.EDIT_FIELD
  ) {
    actionContent = (
      <EditDataFields
        thing={thing}
        thingType={thingType}
        data={content.data}
        onClose={handleClose}
      />
    );
  } else if (content.action === ACTIONS.MULTI_FILE) {
    actionContent = (
      <MultiFileSelect
        thing={thing}
        data={content.data}
        promptForVerify={promptForVerify}
        onClose={handleClose}
      />
    );
  } else {
    assertNever(content.action);
  }

  return (
    <AppSidebar
      forceUnique={false}
      mask={MASKING[content.action]}
      onClose={handleClose}
      open={open}
      setOpen={setOpen}
      title={TITLES[content.action]}
    >
      {actionContent}
    </AppSidebar>
  );
}
