import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';

import styles from './Components.module.css';
import { combineClass } from './utility';

export type DustPageSidebarProps = {
  children: React.ReactNode;
  title: React.ReactNode | string;
  width?: string;
  overlay?: boolean;
  open: boolean;
  setOpen: (e: boolean) => void;
  mask?: boolean;
  onClose?: () => void;
  contentClassName?: string;
  showCloseButton?: boolean;
};

export default function DustPageSidebar({
  children,
  title,
  width = '22rem',
  overlay,
  open,
  setOpen,
  mask = false,
  onClose = () => null,
  contentClassName = '',
  showCloseButton = true,
}: DustPageSidebarProps) {
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const openStyle = {
    '--sidebar-width': width,
    borderLeft: 'var(--border)',
    overflow: 'visible',
    display: 'flex',
  };

  const maskStyle: CSSProperties = {
    '--sidebar-width': width,
  };

  return (
    <>
      {mask && open && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className={styles.sidebarMask}
          onClick={handleClose}
          style={maskStyle}
        />
      )}
      <aside
        className={combineClass(
          styles.sidebar,
          overlay && styles.sidebar__overlay,
        )}
        style={open ? openStyle : undefined}
      >
        {/* HEADER ROW */}
        <div className={styles.sidebarTitleRow}>
          {typeof title === 'string' ? <h2 className="h4">{title}</h2> : title}
          <div className={styles.flex1} />
          {showCloseButton && (
            <IconButton
              onClick={handleClose}
              title="Close Sidebar"
              role="button"
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        {/* CONTENT */}
        <div
          className={combineClass(styles.sidebarContent, contentClassName)}
          style={{ visibility: open ? 'visible' : 'hidden' }}
        >
          {children}
        </div>
      </aside>
    </>
  );
}
