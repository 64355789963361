import { useEffect, useRef } from 'react';

import { GridEventListener } from '@mui/x-data-grid';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

/** NOTE: This file is only slightly altered from a ref implementation living on the MUI docs:
 *
 * https://v5.mui.com/x/react-data-grid/tree-data/#children-lazy-loading
 */

export default function useHierarchyLazyLoader<
  RowT extends { childrenFetched?: boolean; hierarchy: string[] },
>({
  apiRef,
  fetchChildrenRows,
}: {
  apiRef: React.MutableRefObject<GridApiPremium>;
  fetchChildrenRows: (currentRow: RowT, allRows: RowT[]) => Promise<RowT[]>;
}) {
  const fetchChildrenRef = useRef(fetchChildrenRows);
  fetchChildrenRef.current = fetchChildrenRows;

  useEffect(() => {
    const handleRowExpansionChange: GridEventListener<'rowExpansionChange'> = (
      node,
    ) => {
      void (async () => {
        const row: RowT | null = apiRef.current.getRow(node.id);

        if (!node.childrenExpanded || !row || row.childrenFetched) {
          return;
        }

        apiRef.current.updateRows([
          {
            id: `placeholder-children-${node.id}`,
            hierarchy: [...row.hierarchy, ''],
          },
        ]);

        const childrenRows = await fetchChildrenRef.current(
          row,
          apiRef.current.getSortedRows() as RowT[],
        );
        apiRef.current.updateRows([
          ...childrenRows,
          { id: node.id, childrenFetched: true },
          { id: `placeholder-children-${node.id}`, _action: 'delete' },
        ]);

        if (childrenRows.length) {
          apiRef.current.setRowChildrenExpansion(node.id, true);
        }
      })();
    };

    /**
     * By default, the grid does not toggle the expansion of rows with 0 children
     * We need to override the `cellKeyDown` event listener to force the expansion if there are children on the server
     */
    const handleCellKeyDown: GridEventListener<'cellKeyDown'> = (
      params,
      event,
    ) => {
      const cellParams = apiRef.current.getCellParams(params.id, params.field);
      if (cellParams.colDef.type === 'treeDataGroup' && event.key === ' ') {
        event.stopPropagation();
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.defaultMuiPrevented = true;

        apiRef.current.setRowChildrenExpansion(
          params.id,
          !params.rowNode.childrenExpanded,
        );
      }
    };

    apiRef.current.subscribeEvent(
      'rowExpansionChange',
      handleRowExpansionChange,
    );
    apiRef.current.subscribeEvent('cellKeyDown', handleCellKeyDown, {
      isFirst: true,
    });
  }, [apiRef]);
}
