import { useEffect, useState } from 'react';

import { provideFallbackImage } from '@/common/utilities/images';
import { urlFromFile } from '@/common/utility';
import useRequest from '@/services/requests/useRequest';

import styles from './Catalog.module.css';

type Props = {
  catalog: Catalog;
};

export default function CatalogThumbnail({ catalog }: Props) {
  const { mediaApi } = useRequest();
  const [src, setSrc] = useState('');

  useEffect(() => {
    const get = async () => {
      const res = await mediaApi.getMedia({
        uuid: catalog.imageMediaUuid,
        width: Math.floor(window.devicePixelRatio * 40),
        height: Math.floor(window.devicePixelRatio * 40),
        suppressToast: true,
      });

      if (res.error || !res.data) {
        setSrc('');
        return;
      }

      const url = await urlFromFile(res.data.file);
      setSrc(url);
    };
    if (catalog.imageMediaUuid) void get();
  }, [mediaApi, catalog.imageMediaUuid]);

  return src ? (
    <img
      className={styles.thumbnail}
      src={src}
      alt={`${catalog.name} thumbnail`}
      onError={provideFallbackImage}
    />
  ) : null;
}
