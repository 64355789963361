import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import DustStepperFooter from '@/components/Library/DustStepperFooter';
import useRequest from '@/services/requests/useRequest';

import ModeSelector from './ModeSelector';
import ThingDetailsMulti from './ThingDetailsMulti/ThingDetailsMulti';
import ThingDetailsSingle from './ThingDetailsSingle/ThingDetailsSingle';

const FORM_ID = 'details-form';

type Props = {
  onFinish: (e: Thing[]) => void;
};

export default function StepDetails({ onFinish }: Props) {
  const { thingsApi } = useRequest();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Create');

  // Cache created thing to allow file resubmission if it fails after thing creation
  const [createdThing, setCreatedThing] = useState<Thing | null>(null);

  /** Create a single thing with file attachments */
  const handleSubmitSingle = async (formData: AddThingSubmission) => {
    const { thing, typedFiles, files, primaryImage, catalogUuid } = formData;

    if (!thing) throw new Error('No thing provided');
    setLoading(true);

    // Used cached created thing if available (prevents duplicate creation when resubmitting files)
    const createResult = createdThing
      ? { error: false, data: [createdThing] }
      : await thingsApi.create({
          catalogUuid,
          things: [thing],
        });

    if (createResult.error || !createResult.data) {
      setLoading(false);
      return;
    }

    const newThing = createResult.data[0];

    if (!createdThing) setCreatedThing(newThing);

    // Upload typed Files
    if (typedFiles.length > 0) {
      const fileUploadQueries = typedFiles.map((fileField) =>
        thingsApi.attachTypedFiles({
          thingUuids: [newThing?.uuid],
          files:
            fileField.multiple && typeof fileField.value === 'object'
              ? Object.values(fileField.value)
              : [fileField.value as File],
          fieldTypeUuid: fileField.uuid,
        }),
      );
      // For file failure we won't handle retry on upload failure. Will get very messy
      // to handle partial retry with only selected fields. Redirect to thing page following
      // creation will allow additional file uploading as needed
      await Promise.allSettled(fileUploadQueries);
    }

    // Check if there is a primary image that will not be assigned to the type field
    const isUntypedPrimaryImage = primaryImage && !thing.thingTypeUuid;

    // Upload generic files
    if (files.length > 0 || isUntypedPrimaryImage) {
      const fileRes = await thingsApi.attachTypedFiles({
        thingUuids: [newThing?.uuid],
        files: primaryImage ? [...files, primaryImage] : files,
      });

      // Break early for file upload failure
      if (fileRes.error || !fileRes.data) {
        setLoading(false);
        return;
      }

      // Allow failure for primary attach. Not worth retrying when you can set inside the thing page
      if (isUntypedPrimaryImage) {
        await thingsApi.updateThingFile({
          fileUuid: fileRes.data[fileRes.data.length - 1].uuid,
          thingUuid: newThing?.uuid,
          isPrimary: true,
        });
      }
    }

    setLoading(false);
    onFinish([newThing]);
  };

  const handleSubmitMulti = async (csv: Blob, catalogUuid: string) => {
    setLoading(true);
    const things = await thingsApi.bulkCreate({
      file: csv,
      catalogUuid,
    });
    setLoading(false);

    if (!things.error && things.data) {
      onFinish(things.data);
    }
  };

  return (
    <div
      className="action-container overflow-hidden"
      style={{ '--input-max-width': '16rem' } as React.CSSProperties}
    >
      <div
        className="action-body scroll-y"
        style={{ scrollbarGutter: 'stable' }}
      >
        <ModeSelector
          multi={
            <ThingDetailsMulti
              formId={FORM_ID}
              onDepsSubmitSuccess={handleSubmitMulti}
              setSubmitLabel={setSubmitLabel}
            />
          }
          single={
            <ThingDetailsSingle
              disableAll={!!createdThing}
              formId={FORM_ID}
              onSubmit={handleSubmitSingle}
              setSubmitLabel={setSubmitLabel}
            />
          }
        />
      </div>
      <DustStepperFooter
        loading={loading}
        onCancel={() => navigate(-1)}
        submitFormId={FORM_ID}
        submitLabel={submitLabel}
        submitAriaLabel="Continue to scan your Thing"
      />
    </div>
  );
}
