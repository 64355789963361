import WarningAmber from '@mui/icons-material/WarningAmber';

import useCheckout from '@/common/hooks/useCheckout';
import DustModal from '@/components/Library/DustModal';

export default function RelationshipsCheckoutWarning() {
  const {
    relationshipWarningOptions,
    checkout: { addThings, cancelAddThings },
  } = useCheckout();
  const handleContinue = async () => {
    await addThings({
      ...relationshipWarningOptions,
      bypassRelationshipCheck: true,
    });
  };

  const handleCancel = () => cancelAddThings();

  const thingsWithRelationships = (
    relationshipWarningOptions?.things || []
  ).filter((t) => t.hasRelationships);

  const multiple = thingsWithRelationships.length > 1;

  const thingNames = thingsWithRelationships.map((t) => t.title).join(', ');

  return (
    <DustModal
      open
      title={
        <div className="flex-row gap-05 items-center">
          <WarningAmber color="warning" />
          Warning
        </div>
      }
      footerProps={{
        cancelLabel: 'Cancel',
        submitLabel: 'Continue',
        onCancel: handleCancel,
        onSubmit: handleContinue,
      }}
    >
      {(multiple && (
        <>
          <p className="pb-1">
            Some of the Things in your Checkout Cart have Relationships with
            other Things. By continuing, each Thing’s Relationship Tree will be
            checked-out as well.
          </p>
          <p className="pb-1">
            If you want to make any changes to Relationships before
            checking-out, select ‘Cancel’ and go to the Relationships tab for
            the Things you’d like to edit.
          </p>
          <p className="pb-1">
            Selecting ‘Continue’ will add all Things to the Checkout Cart, as
            well as their Relationship Trees.
          </p>
        </>
      )) || (
        <>
          <p className="pb-1">
            <strong>{thingNames}</strong> has existing Relationships with other
            Things. By continuing, all Things in the Relationship Tree of{' '}
            <strong>{thingNames}</strong> will be checked-out as well.
          </p>
          <p className="pb-1">
            If you want to make any changes to the Relationships of{' '}
            <strong>{thingNames}</strong> before checking out, select ‘Cancel’
            and navigate to the Relationships tab.
          </p>
          <p className="pb-1">
            Selecting ‘Continue’ will add <strong>{thingNames}</strong> to the
            Checkout Cart as well as all of its Related Things.
          </p>
        </>
      )}
    </DustModal>
  );
}
