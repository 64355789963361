import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

type Props = {
  uuids: string[];
  enabled?: boolean;
};

export default function useThingMediaData({ uuids, enabled = true }: Props) {
  const { thingMediaApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.THING_MEDIA(uuids ?? []),
    () => thingMediaApi.search({ uuids }),
    { enabled },
  );

  return {
    thingMedia: data && !data.error ? data.data : [],
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
