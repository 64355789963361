import mixpanelBrowser, { Dict, RequestOptions } from 'mixpanel-browser';
import { UserProfile } from 'oidc-client-ts';

type TrackLogoutParameters = {
  logoutMethod:
    | 'Log out button'
    | 'System Crash'
    | 'Session Timeout'
    | 'Session Dialog';
  facilityUuid: string | null;
};

const enabled = Boolean(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN);
if (enabled) {
  mixpanelBrowser.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {
    // NOTE - not using api_host to proxy for now, setup was complicated, deferring until we think we need it.
    // api_host: `${window.location.origin}/mixpanel`,
    debug: !!window.location.origin.match(/localhost|dev/),
    ip: false,
  });
}

const mixpanel = enabled ? mixpanelBrowser : undefined;

/** Properties that are shared across multiple Mixpanel events */
export const commonProps = Object.freeze({
  pageName: 'Page Name',
  numberOfDataFieldsAtLoad: 'Number of Data Fields at Load',
});

export const Mixpanel = {
  /** Call once per login / app load, identifies and sets user profile properties per user in Mixpanel */
  identifyUser: (
    activeUser: User,
    userProfile: UserProfile,
    orgId: string | null | undefined,
  ) => {
    if (!mixpanel) return;
    mixpanel.identify(activeUser.uuid);
    mixpanel.people.set_once('User Created date', activeUser.createdAt);
    // mixpanel.people.set('User Type', activeUser.role
    // User Type - Operator, Admin/Manager, or Root
    //

    mixpanel.people.set({
      // NOTE: Status meant to be Active, Inactive, or Pending (frontend can only supply active)
      Status: activeUser.userAccountStatus,
      ...(orgId
        ? { 'User Roles': userProfile.organizations[orgId].roles }
        : {}),
      'Org IDs': Object.keys(userProfile.organizations).sort(),
      // NOTE: Current User.lastLoginAt stale, in the future, should come
      // from the Identity API
      'Last Logged In': new Date(),
      'User Id': activeUser.uuid,
    });
  },
  /** Unassociate the user from the mixpanel session, see [Identity Management Best Practices](https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices) */
  logout: ({ logoutMethod, facilityUuid }: TrackLogoutParameters) => {
    Mixpanel.track(
      'Log out',
      {
        'Log Out method': logoutMethod,
        'Facility ID': facilityUuid,
      },
      { send_immediately: true },
    );
    mixpanel?.reset();
  },
  /** Event Props can be of any serializable type, including bools, objects, and arrays.
   * NOTE: mixpanel.track may mutate props and add a 'token' field, so always pass in a safe literal that can tolerate mutation */
  track: (
    eventName: string,
    props?: Dict | undefined,
    options?: RequestOptions | undefined,
  ) => {
    mixpanel?.track(eventName, props, options);
  },
  /** set every-time values for tracking */
  register: (values: Dict) => {
    mixpanel?.register(values);
  },
};
