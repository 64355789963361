import { useState } from 'react';

import { LinearProgress } from '@mui/material';

import { useResize } from '@/common/hooks';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import DustDustedIcon from '@/components/Library/Icons/DustDustedIcon';

import AutoNextSwitch from './AutoNextSwitch';
import styles from './ThingAdd.module.css';

type BoundPaceholderType = {
  onNext: () => void;
  disabled: boolean;
  nextLabel: string;
  autoNextEnabled: boolean;
  onToggleAutoNext?: () => void;
};

const CAMERA_RATIO = 4 / 3;
const FOOTER_SIZE_PX = 68;

export default function BoundPlaceholder({
  onNext,
  disabled,
  nextLabel,
  autoNextEnabled,
  onToggleAutoNext,
}: BoundPaceholderType) {
  const { width, height, resizeRef } = useResize();
  const [isProgressShown] = useState(autoNextEnabled && !disabled);
  const constrainedWidth = Math.max(
    200,
    Math.min(width || 0, ((height || 0) - FOOTER_SIZE_PX) * CAMERA_RATIO),
  );
  const constrainedHeight = constrainedWidth / CAMERA_RATIO + FOOTER_SIZE_PX;
  return (
    <div className={`w-full h-full ${styles.placeholder}`} ref={resizeRef}>
      <div
        className="flex-col"
        style={{ height: constrainedHeight, width: constrainedWidth }}
      >
        <div className={styles.scanPlaceholder}>
          <DustDustedIcon />
          <span>Bound</span>
          {isProgressShown && (
            <LinearProgress
              sx={{ position: 'absolute', bottom: 0, width: '100%' }}
            />
          )}
        </div>
        <DustStepperFooter
          disabled={disabled}
          onSubmit={onNext}
          submitLabel={nextLabel}
          submitAriaLabel="Finish adding your Thing"
          actions={
            onToggleAutoNext && (
              <AutoNextSwitch
                className={styles.placeholderAutoNext}
                checked={autoNextEnabled}
                onClick={onToggleAutoNext}
                disableRipple
              />
            )
          }
        />
      </div>
    </div>
  );
}
