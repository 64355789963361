import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useFacilitiesData from '@/common/entities/facilities/useFacilitiesData';
import { ROUTES } from '@/common/routes';
import FacilitiesTable, {
  facilitiesSortAtom,
  SORT_OPTIONS,
} from '@/components/Composed/Facilities/FacilitiesTable';
import useSort from '@/services/useSort';

function Facilities() {
  const navigate = useNavigate();
  const { sortRequestParams } = useSort(facilitiesSortAtom, SORT_OPTIONS);

  const { isLoading, facilities } = useFacilitiesData({
    ...sortRequestParams,
  });

  const onRowClick = (facility) => {
    navigate(ROUTES.ADMIN_FACILITIES_EDIT(facility.id));
  };

  return (
    <>
      <div className="flex-row mb-05">
        <Button
          onClick={() => navigate(ROUTES.ADMIN_FACILITIES_ADD)}
          startIcon={<Add />}
          sx={{ margin: '0' }}
          variant="contained"
        >
          Add
        </Button>
      </div>
      <div className="flex-1 border">
        <FacilitiesTable
          facilities={facilities}
          onRowClick={onRowClick}
          loading={isLoading}
        />
      </div>
    </>
  );
}

export default Facilities;
