import axios, { AxiosError, AxiosResponse } from 'axios';
import { AuthState } from 'react-oidc-context';

import { AddToastCallback } from '@/common/hooks/useToasts';
import { DustEnvelope, DustSingleResponse } from '@/services/requests/types';
import { AccessTokenRealmAccessPayload, ProfileOrgs } from 'typings/auth-user';

import {
  HTTP_REQUEST_METHODS,
  HTTP_RESPONSE_CODES,
  MESSAGES,
} from './requests/constants';

/**
 * Format a complete ISO 8601 string
 *
 * @param date as a string, date, or unix timestamp(ms)
 * @returns      ISO 8601 long form string (GMT)
 */
export function formatISODate(date: Date | number | string): string {
  return `${new Date(date ?? Date.now()).toISOString().split('Z')[0]}+00:00`;
}

/**
 * Decode a JWT token
 */
export function decodeJWT(token: string): unknown {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

const nextLinkRegexp = /(?<=<)([\S]*)(?=>; rel="Next")/i;

/** Pass in Link header string value, e.g. Axios: response.headers.link */
export function extractNextLinkRel(linkHeader: string | null | undefined) {
  if (linkHeader == null) return null;
  // return null for empty strings too, if they exist
  return linkHeader.match(nextLinkRegexp)?.[0] || null;
}

// ---------------------------------------------------------------------------
// Extended type so that the data type is determined based on the error state
type ApiReturnBase<T> = {
  data: T;
  status: number;
};

type ApiPagedInfo = {
  page: number;
  pages: number;
  perPage: number;
  total: number;
};

export type ApiReturn<T> = ApiReturnBase<T> & { error: false };

export type ApiPagedReturn<T> = ApiReturn<T[]> & ApiPagedInfo;

export type ApiReturnError<T = any> = ApiReturnBase<T> & {
  error: true;
  status: number;
  message: string;
  originalError?: AxiosError;
};

// Gets pagination-specific fields, replaced with Link header in OIDC
function getEnvelopePagedInfo(envelope: DustEnvelope) {
  if (!envelope?.meta) throw new Error('Expected a "meta" response object');
  return {
    page: envelope.meta.page as number,
    pages: envelope.meta.pages as number,
    perPage: envelope.meta.perPage as number,
    total: envelope.meta.total as number,
  };
}

/**
 * Take a DICE axios envelope return and format into standard return object
 */
export function formatAPIReturn<T, K = unknown>(
  res: AxiosResponse<{ data: K; meta: any }>,
  model: (input: any) => T = (x) => x,
): ApiReturn<K extends Array<unknown> ? T[] : T> {
  const baseData = res.data.data;

  return {
    data: (Array.isArray(baseData)
      ? baseData.map((x) => model(x))
      : model(baseData)) as K extends Array<unknown> ? T[] : T,
    error: false,
    status: res.status,
  };
}

export function formatPagedApiReturn<T, K = unknown>(
  res: AxiosResponse<{ data: K; meta: any }>,
  /** Used to guard against mismatching response page (in which case an empty data set is returned) */
  pageRequestCheck: number,
  model: (input: any) => T = (x) => x,
): ApiPagedReturn<T> {
  if (!Array.isArray(res.data.data)) {
    throw new Error('Paginated responses must be arrays');
  }
  const pageInfo = getEnvelopePagedInfo(res.data);
  if (pageInfo.page > pageRequestCheck) {
    throw new Error(
      `Got a greater response page "${pageInfo.page}" when we requested page "${pageRequestCheck}", no safe way to handle this case`,
    );
  }
  const pageRequestOutOfBounds = false && pageInfo.page < pageRequestCheck;
  return {
    data: pageRequestOutOfBounds ? [] : res.data.data.map((x) => model(x)),
    error: false,
    status: res.status,
    ...pageInfo,
    // Fake expected page for react-query infinite-scroll
    page: pageRequestCheck,
  };
}

export const formatIdentityAPIReturn = <T, K = unknown>(
  res: AxiosResponse<K>,
  model: (input: any) => T = (x) => x,
): ApiReturn<K extends Array<unknown> ? T[] : T> => {
  const baseData = res.data;
  return {
    data: (Array.isArray(baseData)
      ? baseData.map((x) => model(x))
      : model(baseData)) as K extends Array<unknown> ? T[] : T,
    error: false,
    status: res.status,
  };
};

export type DefaultErrorResponseObject = {
  message: string;
  errorMessageCode: string | null;
  meta: Record<string, unknown>;
};

export const formatAPIError = <
  T extends Record<string, unknown> = DefaultErrorResponseObject,
>(
  /** axios error object */
  err: AxiosError,
  /** optional message to return */
  message = '',
): ApiReturnError<T> => ({
  error: true,
  status: err?.response?.status || HTTP_RESPONSE_CODES.INTERNAL_SERVER_ERROR,
  data: err.response?.data,
  message: message || 'Issues contacting the server. Please try again',
  originalError: err,
});

/** Use in request service catch blocks to re-throw aborted responses.
 *
 * Handles DOM fetch aborts and axios aborts */
export function isAbortError(error: unknown): boolean {
  return (
    axios.isCancel(error) ||
    (error instanceof DOMException && error.name === 'AbortError')
  );
}

export const defaultErrorHandler =
  (
    addToast: AddToastCallback,
    /** optional function to call if default conditions are not met */
    fallbackHandler?: (e: AxiosError) => void,
  ) =>
  (err: AxiosError) => {
    if (
      err.response?.status === HTTP_RESPONSE_CODES.FORBIDDEN &&
      Object.values(HTTP_REQUEST_METHODS)
        .filter((method) => method !== HTTP_REQUEST_METHODS.GET) // Don't display "permission denied" for GET requests
        .includes(err.config.method ?? '')
    ) {
      addToast(MESSAGES.COMMON.PERMISSION_DENIED, 'error');
    } else if (fallbackHandler) {
      fallbackHandler(err);
    }
    return formatAPIError(err);
  };

/**
 * Merge generic data into the DUST data property
 * to allow proper api response formatting
 */
export const mergeWithDustResponse = (
  response: DustSingleResponse,
  oidcResponse: AxiosResponse<any>,
): DustSingleResponse => ({
  ...response,
  data: {
    meta: response.data.meta,
    data: {
      ...(response.data as any).data,
      ...oidcResponse.data,
    },
  },
});

/**
 * Merge an OIDC response array into the original response using key association between the two lists, even if DICE entries are missing
 * ex. keynameDice = 'oidc_id', keynameOidc = 'id'
 */
export function mergeOidcArrayByKey<T extends object, U extends object>(
  oidcData: T[],
  diceData: U[],
  keynameOidc: keyof T,
  keynameDice: keyof U,
): (T & Partial<U>)[] {
  // Create hash map of oidc response objects keyed by a specified field values
  const keyMap: Record<string, U> = {};
  diceData.forEach((curr) => {
    const keyValue = curr[keynameDice];
    if (typeof keyValue !== 'string')
      throw new Error('Unexpected non-string value');
    keyMap[keyValue] = curr;
  });

  // Merge the data by getting the associated oidc response by the key value
  const mergedData = oidcData.map((u) => {
    const key = u[keynameOidc];
    if (typeof key !== 'string') throw new Error('Unexpected non-string value');
    return {
      ...u,
      ...keyMap[key],
    };
  });

  return mergedData;
}

export function mapResponseEnvelopeData<T, U>(
  response: AxiosResponse<DustEnvelope<T>>,
  mapFn: (data: T) => U,
): AxiosResponse<DustEnvelope<U>> {
  return {
    ...response,
    data: {
      ...response.data,
      data: mapFn(response.data.data),
    },
  };
}

/** Reads out orgs from the auth state, available from `useAuth()`.
 * null indicates unknown, Empty object indicates no user or no orgs listed on user.  */
export function extractUserOrgs(authState: AuthState): ProfileOrgs | null {
  if (!authState.user) {
    // NOTE - while loading or refreshing, we want to use the previous user's data if possible.
    return authState.isLoading ? null : {};
  }
  const userOrgs = authState.user.profile.organizations;

  if (!userOrgs) {
    throw new Error(
      'Parsed an oidc user token without a profile that contains "organizations"',
    );
  }
  return userOrgs;
}

export function getIsRootFromUser(authUser: AuthState['user']) {
  const accessTokenPayload = authUser
    ? (decodeJWT(authUser.access_token) as AccessTokenRealmAccessPayload)
    : null;
  const realmRoles = accessTokenPayload?.realm_access?.roles;
  const isRoot = realmRoles?.includes('root') ?? false;

  // // FOR DEBUGGING - uncomment to see token data
  // console.log({
  //   accessToken: decodeJWT(authUser.access_token),
  //   idToken: decodeJWT(authUser.id_token),
  //   refreshToken: decodeJWT(authUser?.refresh_token),
  // });

  return isRoot;
}
