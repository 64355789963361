export const USER_STATUS = {
  NORMAL: 'NORMAL',
  RESET: 'RESET',
} as const;

export const USER_ACCOUNT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PASSWORD_RESET: 'PASSWORD_RESET',
} as const;

export type UserAccountStatus =
  (typeof USER_ACCOUNT_STATUS)[keyof typeof USER_ACCOUNT_STATUS];

export const USER_ACCOUNT_STATUS_LABELS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  PASSWORD_RESET: 'Force Password Reset',
} as const;
