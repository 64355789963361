import React from 'react';

import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import DustLoader from './DustLoader';

/**
 * @typedef {object} Props
 * @property {boolean}         [loading]
 * @property {React.ReactNode} children
 */

/**
 * Be advised - this link button has underlined and other specific styling.
 *
 * For default use, MUI's Button is preferred elsewhere in the app.
 */
const DustLinkButton = React.forwardRef(
  (
    /** @type {Props & import('@mui/material').ButtonProps} */ {
      loading = false,
      children = null,
      ...props
    },
    ref,
  ) => (
    <Button
      variant="text"
      {...props}
      sx={{
        display: 'block',
        width: 'auto',
        minWidth: 'auto',
        textTransform: 'none',
        fontSize: '0.8rem',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
        },
        ...props.sx,
      }}
      ref={ref}
    >
      {loading ? <DustLoader size="small" /> : children}
    </Button>
  ),
);

DustLinkButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  sx: PropTypes.object,
};

export default DustLinkButton;
