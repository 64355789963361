import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { remToPx } from '@/common/utility';
import CatalogItem from '@/components/Composed/CatalogSelector/CatalogItem';
import DustLoader from '@/components/Library/DustLoader';

import styles from './Catalog.module.css';

type Props = {
  catalogs: Catalog[];
  isSuccess: boolean;
  isLoading: boolean;
  selectedUuid?: string;
  onSelect: (e: Catalog) => void;
  onFavorite: (e: Catalog) => void;
  onScrollEnd?: () => void;
  onHide: (e: Catalog) => void;
  hasNextPage?: boolean;
  perPage: number;
  setPerPage: (e: number) => void;
};

export default function CatalogGrid({
  catalogs,
  isSuccess,
  isLoading,
  onSelect,
  selectedUuid,
  onFavorite,
  onScrollEnd,
  onHide,
  perPage,
  setPerPage,
  hasNextPage,
}: Props) {
  const [showRange, setShowRange] = useState({
    start: 0,
    end: 15,
    numCols: 3,
    numRows: 5,
    beforeHeight: 0,
    afterHeight: 0,
  });

  const scrollCont = useRef<HTMLDivElement | null>(null);

  const setRenderRange = useCallback(() => {
    if (!scrollCont.current) return;

    const CONT_PADDING = 1;
    const CARD_WIDTH = 14.5 + 2; // Width + gap
    const CARD_HEIGHT = 14 + 2; // Height + margin

    // Correction factor for pixel rounding. Should be tested on different screens
    const PIXEL_ROUND = 1;

    // Math.max prevents numCols=0, which prevents divide by 0
    // Handle minmax() logic
    const numCols = Math.max(
      Math.floor(
        (scrollCont.current.scrollWidth - PIXEL_ROUND + remToPx(CONT_PADDING)) /
          remToPx(CARD_WIDTH),
      ),
      1,
    );

    const rowHeight = remToPx(CARD_HEIGHT); // Must match CSS height in Catalog.module.css

    const start =
      Math.floor(scrollCont.current.scrollTop / rowHeight) * numCols;

    const numRows = Math.ceil(scrollCont.current.offsetHeight / rowHeight) + 1;
    const end = start + numRows * numCols;

    // Find the number of rows before and after the render region
    const rowsBefore = start / numCols;
    const rowsAfter = Math.max(Math.ceil((catalogs.length - end) / numCols), 0);

    setShowRange({
      start,
      end,
      numCols,
      numRows,
      beforeHeight: rowsBefore * rowHeight,
      afterHeight: rowsAfter * rowHeight,
    });

    // Change the page size to be a multiple of the column number
    if (
      catalogs.length > numCols &&
      perPage !== numCols * Math.max(numRows, 3)
    ) {
      setPerPage(numCols * Math.max(numRows, 3));
    }

    // Trigger scroll-loading if within range
    const bottomOffset = 100;
    const isScrollEnd =
      scrollCont.current.scrollHeight -
        scrollCont.current.scrollTop -
        scrollCont.current.offsetHeight <=
      bottomOffset;

    if (isScrollEnd && !isLoading) {
      onScrollEnd?.();
    }
  }, [catalogs, isLoading, perPage, setPerPage, onScrollEnd]);

  const setRenderRangeRef = useRef(setRenderRange);
  setRenderRangeRef.current = setRenderRange;
  useEffect(() => {
    setRenderRangeRef.current();
    // Intentionally Want to trigger a re-check anytime catalogs change
  }, [catalogs]);

  // Trigger the fit check when the window is resized
  useEffect(() => {
    window.addEventListener('resize', setRenderRange);

    return () => window.removeEventListener('resize', setRenderRange);
  }, [setRenderRange]);

  const needsPageResize = useMemo(
    () =>
      catalogs.length > showRange.numCols &&
      perPage !== showRange.numCols * Math.max(showRange.numRows, 3),
    [perPage, showRange, catalogs],
  );

  // Display a use message when needed
  const messageText = useMemo(() => {
    if (isSuccess && catalogs.length < 1)
      return 'Sorry, we did not find any results';

    return hasNextPage ||
      catalogs.length < showRange.numCols * showRange.numRows
      ? ''
      : 'All Catalogs Loaded';
  }, [catalogs, isSuccess, hasNextPage, showRange]);

  return (
    <div
      className={styles.layoutGrid}
      ref={scrollCont}
      onScroll={setRenderRange}
    >
      {/* Hide pre-content spacer when not in use to align small numbers of items properly */}
      {showRange.beforeHeight > 0 && (
        <div
          className={styles.catalogPlaceholder}
          style={{ height: showRange.beforeHeight }}
        />
      )}
      {!needsPageResize &&
        catalogs
          .filter((_, idx) => idx >= showRange.start && idx < showRange.end)
          .map((c) => (
            <CatalogItem
              catalog={c}
              isSelected={c.uuid === selectedUuid}
              key={c.uuid}
              onClick={() => onSelect(c)}
              onFavorite={() => onFavorite(c)}
              onHide={() => onHide(c)}
            />
          ))}
      <div
        className={styles.catalogPlaceholder}
        style={{ height: showRange.afterHeight }}
      />
      <div className={styles.fetchingLoader}>
        {isLoading || needsPageResize ? (
          <DustLoader size="xlarge" className={styles.fetchingLoader} />
        ) : (
          <span>{messageText}</span>
        )}
      </div>
    </div>
  );
}
