import React from 'react';

import { AuthProvider } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import DustTheme from './components/Library/DustTheme';
import PageviewTracker from './PageviewTracker';
import RequestContextProvider from './services/requests/RequestContextProvider';
import { userManager } from './services/user-oidc';
import SigninNavReplace, { SigninNavReplaceRef } from './SigninNavReplace';
import { clearVerifiedThings } from './state/atoms/verifiedSession';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !import.meta.env.DEV, // Disable focus fetch during dev
    },
  },
});

// import RecoilDebugObserver from './RecoilDebugObserver' // Enable for Recoil Observing All State Changes https://recoiljs.org/docs/guides/dev-tools/

type Props = {
  children: React.ReactNode;
};

function App({ children }: Props) {
  const signinHistoryReplaceRef = React.useRef<SigninNavReplaceRef>(null);

  const onSigninCallback = React.useCallback(() => {
    if (!signinHistoryReplaceRef.current)
      throw new Error('expected valid signinHistoryReplaceRef');
    signinHistoryReplaceRef.current.removeSigninParams();
    clearVerifiedThings();
  }, []);

  return (
    <AuthProvider userManager={userManager} onSigninCallback={onSigninCallback}>
      {/* For a valid ref while RequestContextProvider is fetching org */}
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          {/* For DEBUG, NODE_ENV controls inclusion internally */}
          <ReactQueryDevtools />
          <RequestContextProvider>
            <DustTheme>
              <BrowserRouter>
                <PageviewTracker />
                <SigninNavReplace ref={signinHistoryReplaceRef} />
                {children}
              </BrowserRouter>
            </DustTheme>
          </RequestContextProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </AuthProvider>
  );
}

export default App;
