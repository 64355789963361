import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

/** Get one transaction by uuid */
export default function useTransactionData({
  transactionUuid,
  enabled = true,
}: {
  transactionUuid: string;
  enabled?: boolean;
}) {
  const { transactionsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.TRANSACTION(transactionUuid),
    () => transactionsApi.getTransaction({ transactionUuid }),
    { enabled },
  );

  return {
    transaction: data && !data?.error ? data.data : undefined,
    isLoading,
    isSuccess,
  };
}
