import { useMemo, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { OidcUser } from '@/common/entities/users/typedefs';
import useUsersData from '@/common/entities/users/useUsersData';
import { alphaSort } from '@/common/utilities/sort';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustLoader from '@/components/Library/DustLoader';
import DustModal from '@/components/Library/DustModal';
import DustSearchInput from '@/components/Library/DustSearchInput';
import useRequest from '@/services/requests/useRequest';

type Props = {
  group: Group;
  onClose: () => void;
  open: boolean;
  existingUsers: OidcUser[];
};

export default function GroupAddUserModal({
  group,
  onClose,
  open,
  existingUsers = [],
}: Props) {
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const { groupsApi } = useRequest();

  const {
    isSuccess: isSuccessUserList,
    isLoading: isLoadingUserList,
    users,
  } = useUsersData();

  const closeModal = () => {
    setSearchValue('');
    setSelected([]);
    onClose();
  };

  // Don't display any existing users in the list of options
  const subExists = useMemo(
    () =>
      existingUsers.reduce<Record<string, boolean>>(
        (map, user) => ({ ...map, [user.sub]: true }),
        {},
      ),
    [existingUsers],
  );

  const searchRegex = new RegExp(searchValue, 'i');
  const filteredUsers = users
    .filter((user) =>
      searchValue
        ? !subExists[user.sub] &&
          (searchRegex.test(user.firstName.toString()) ||
            searchRegex.test(user.lastName.toString()) ||
            searchRegex.test(user.email.toString()))
        : !subExists[user.sub],
    )
    .sort((a, b) => alphaSort(a, b, 'firstName'));

  const submitForm = async () => {
    setSubmitting(true);
    const res = await groupsApi.addUsersToGroup(
      group.id,
      users.filter((u) => selected.includes(u.sub)),
    );
    setSubmitting(false);

    if (!res.error) closeModal();
  };

  const columns: GridColDef<User>[] = [
    {
      valueGetter: ({ row: user }) => `${user.firstName} ${user.lastName}`,
      field: 'user',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
    },
  ];

  return (
    filteredUsers && (
      <DustModal
        footerProps={{
          submitLabel: selected.length > 1 ? 'Add Users' : 'Add User',
          onCancel: closeModal,
          loading: isLoadingUserList || submitting,
          onSubmit: submitForm,
          disabled: selected.length < 1,
        }}
        onClose={closeModal}
        open={open}
        title={`Add Users To Group: ${group.name}`}
      >
        <div className="flex-col gap-1">
          <DustSearchInput
            sx={{ marginTop: '.5rem' }}
            autoComplete="groupUserList"
            setValue={setSearchValue}
            aria-label="Search for a user to add to this group"
          />
          <div className="flex-col h-full" style={{ minHeight: '300px' }}>
            {isLoadingUserList && <DustLoader size="medium" />}
            {isSuccessUserList && (
              <DustDataGrid
                altTheme
                columns={columns}
                selectionModel={selected}
                onSelectionModelChange={(selection) =>
                  setSelected(selection as string[])
                }
                rows={filteredUsers}
                getRowId={(u) => u.sub}
                checkboxSelection
              />
            )}
          </div>
        </div>
      </DustModal>
    )
  );
}
