import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

function DustTooltip({ title, status, children, ...props }) {
  const tooltipStatus = {
    error: {
      backgroundColor: 'var(--error-red)',
    },
    warning: {
      backgroundColor: 'var(--warning-yellow)',
    },
  };

  const arrowStatus = {
    error: {
      color: 'var(--error-red)',
    },
    warning: {
      color: 'var(--warning-yellow)',
    },
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: tooltipStatus[status],
        },
        arrow: {
          sx: arrowStatus[status],
        },
      }}
      title={title}
      {...props}
    >
      {children}
    </Tooltip>
  );
}

DustTooltip.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.node,
};

export default DustTooltip;
