import PropTypes from 'prop-types';

import styles from './Components.module.css';
import { combineClass } from './utility';

/**
 * Needed because MUI Data Grid is very picky about how text is treated, and
 * this seems to be the most straightforward way to wrap text within a cell
 *
 * @param   {React.ComponentProps<"span">} props
 * @returns {JSX.Element}
 */
export default function WrapTextSpan({
  className = undefined,
  children = '',
  ...props
}) {
  return (
    <span className={combineClass(styles.textAllowWrap, className)} {...props}>
      {children}
    </span>
  );
}

WrapTextSpan.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
