import { combineClass } from '@/components/Library/utility';

import styles from './Components.module.css';

export default function DustProgressLoader({
  alt,
  className,
  ...props
}: React.SVGProps<SVGSVGElement> & { alt?: string }) {
  return (
    <svg
      {...props}
      className={combineClass(styles.progLoader, className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <title>{alt}</title>
      <path
        id="Path_368"
        data-name="Path 368"
        d="M 43.8 25 L 43.8 53.653 L 35.779 45.573 L 27.001 54.416 L 45.611 73.16 L 45.617 73.16 C 48.028 75.609 51.978 75.609 54.389 73.16 L 73 54.416 L 64.222 45.573 L 56.207 53.653 L 56.207 25 L 43.8 25 Z"
        clipPath="url(#clip-_0-0)"
      />
      <ellipse cx="50" cy="93" rx="6.476" ry="6.476" />
      <ellipse
        cx="11.831"
        cy="105.03"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 81.169167, -55.029969)"
      />
      <ellipse
        cx="-27.064"
        cy="65.256"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 77.06379, -58.255627)"
      />
      <ellipse
        cx="-73.876"
        cy="108.78"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 80.876061, -58.780106)"
      />
      <ellipse cx="87.2" cy="71.5" rx="6.476" ry="6.476" />
      <ellipse
        cx="-44.565"
        cy="57.93"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 116.065041, 29.309645)"
      />
      <ellipse
        cx="-115.775"
        cy="93.764"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 144.274888, -6.524474)"
      />
      <ellipse
        cx="-128.249"
        cy="70.869"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 141.049226, 0.63062)"
      />
      <ellipse
        cx="-71.947"
        cy="83.585"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 84.74685, -55.085263)"
      />
      <ellipse
        cx="-91.67"
        cy="71.697"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 120.170426, -58.897408)"
      />
      <ellipse
        cx="-22.513"
        cy="45.676"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 94.013283, -32.916207)"
      />
      <ellipse
        cx="-19.569"
        cy="44.32"
        rx="6.476"
        ry="6.476"
        transform="matrix(1, 0, 0, 1, 106.769288, -15.820228)"
      />
      <g className={styles.circularLoader}>
        <circle
          className={styles.loaderPath}
          cx="50"
          cy="50"
          fill="none"
          r="43"
        />
      </g>
    </svg>
  );
}
