import { Tooltip, SvgIcon, SvgIconProps } from '@mui/material';

import { TRANSACTION_TYPES } from '@/common/entities/transactions/constants';
import { combineClass } from '@/components/Library/utility';

import styles from './Icons.module.css';
import TransactionIconTypes from './TransactionIconTypes';
import VerifiedShield from '../Svg/VerifiedShield';

type Props = {
  label?: React.ReactNode;
  // TODO: verify action type "intent" to fully understand what keys should show up here
  type: keyof typeof TRANSACTION_TYPES | string;
  verified: boolean;
  verifySuccess: boolean;
} & SvgIconProps;

export default function DustTransactionIcon({
  type,
  verified,
  verifySuccess,
  label = null,
  ...props
}: Props) {
  // TODO: verify action type "intent" to fully understand what shows up for "type"
  const IconMapping: Record<
    keyof typeof TRANSACTION_TYPES | string,
    keyof typeof TransactionIconTypes
  > = {
    ANTI_TAMPER_ANALYSIS: 'inspect',
    THING_CUSTODIAN_UPDATE: 'updateCustodian',
    THING_METADATA_ADD: 'updateData',
    THING_METADATA_UPDATE: 'updateData',
    THING_CREATE: 'createThing',
    THING_FILE_ADD: 'addAttachment',
    THING_FILE_REMOVE: 'removeAttachment',
    BIND: 'bind',
    UNBIND: 'unbind',
    DEACTIVATE_DUST: 'unbind',
    ENROLL: 'createThing',
    IDENTIFY: 'verifyIdentity',
    VALIDATE: 'verifyIdentity',
    VERIFY: verifySuccess ? 'verifyIdentity' : 'verifyFail',
    EXTRACT: 'extract',
    MIGRATE: 'template',
    XTAG_LOOKUP: 'verifyIdentity',
    /** Note: not a transaction type */
    CREATE_RELATIONSHIP: 'updateRelationship',
    UPDATE_RELATIONSHIP: 'updateRelationship',
    /** Note: not a transaction type */
    EXPIRE_RELATIONSHIP: 'removeRelationship',
    SHARE_DIGITAL_ACCESS: 'shareThing',
    TRANSFER_PHYSICAL_ACCESS: 'transferOwnership',
    /** Note: not a transaction type */
    ACTION_ADD: 'addAttachment',
    /** Note: not a transaction type */
    ACTION_MODIFY: 'updateData',
    /** Note: not a transaction type */
    ACTION_DELETE: 'removeAttachment',
  };

  const wrappedJsx = (
    <div style={{ position: 'relative' }}>
      <SvgIcon {...props}>
        <svg
          fill="none"
          viewBox="0 0 32.29 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          {TransactionIconTypes[IconMapping[type]]}
        </svg>
      </SvgIcon>
      {verified && (
        <VerifiedShield
          className={combineClass(styles.icon__decorator, 'dust-success')}
        />
      )}
    </div>
  );

  return label ? (
    <Tooltip enterDelay={700} title={label}>
      {wrappedJsx}
    </Tooltip>
  ) : (
    wrappedJsx
  );
}
