import { ApiGroup, OidcGroup } from './typedefs';
import baseModel from '../baseModel';

export function groupModel(group: OidcGroup & Partial<ApiGroup>): Group {
  return {
    ...baseModel(group),
  };
}

export function oidcGroupModel(group: OidcGroup): OidcGroup {
  return {
    ...baseModel(group),
  };
}
