import { useEffect, useMemo } from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import useActiveUser from '@/common/entities/users/useActiveUser';
import useRedirectToLoginPage from '@/common/hooks/useRedirectToLoginPage';
import { ROUTES } from '@/common/routes';
import AppLoadingSpinner from '@/components/Library/AppLoadingSpinner';
import { Mixpanel } from '@/mPanel';
import useRequest from '@/services/requests/useRequest';
import { extractUserOrgs, getIsRootFromUser } from '@/services/utility';
import {
  activeUserIdState,
  activeUserAndOrgIdState,
} from '@/state/storageKeyStates';

import AuthErrorPage from './ErrorPages/AuthErrorPage';
import ErrorBase from './ErrorPages/ErrorBase';
import LoginErrorButtons from './ErrorPages/LoginErrorButtons';
import UserNotInOrgsPage from './Login/UserNotInOrgsPage';

const SERVER_USER_NOT_IN_ORG_MESSAGE = 'User not in an org';

type Props = { children: React.ReactNode };

/** This route guard ensures that before interacting with any page in our app, we're:
 * 1) logged in,
 * 2) have a valid organization selected, and
 */
export default function ProtectedRoute({ children }: Props) {
  const auth = useAuth();
  const navigate = useNavigate();
  const willRedirectToLogin = useRedirectToLoginPage();

  const request = useRequest();
  const orgId = request?.orgId;
  const haveAuthAndOrg = auth.isAuthenticated && request.orgId != null;
  // redirect only if orgId is exactly null, follow willRedirectToLogin logic
  const willRedirectToSelectOrg =
    !auth.isLoading && !auth.error && auth.isAuthenticated && orgId === null;

  const userOrgs = extractUserOrgs(auth);

  const anyUsableRolesExist = useMemo(() => {
    const isRoot = getIsRootFromUser(auth.user);
    const userOrgRoles = (orgId && userOrgs?.[orgId].roles) ?? [];
    return userOrgRoles.length > 0 || isRoot;
  }, [auth, userOrgs, orgId]);

  useEffect(() => {
    if (willRedirectToSelectOrg) {
      // TODO: add a return url param
      navigate(`${ROUTES.SELECT_ORG}`);
    }
  }, [willRedirectToSelectOrg, navigate]);

  const {
    activeUser,
    isLoading: isUserLoading,
    errorData: userErrorData,
  } = useActiveUser({
    enabled: haveAuthAndOrg && anyUsableRolesExist,
  });

  // NOTE: `!auth.isAuthenticated` is needed to prevent SessionDialog's
  // signinSilent() call from showing a loading spinner here.
  const isLoading =
    (!auth.isAuthenticated && auth.isLoading) ||
    orgId === undefined ||
    isUserLoading;

  useEffect(() => {
    if (activeUser && auth.user) {
      Mixpanel.identifyUser(activeUser, auth.user.profile, orgId);
    }
  }, [activeUser, auth, orgId]);

  useEffect(() => {
    if (auth.user?.profile.sub && orgId) {
      // Allow Recoil backed by local storage to work
      activeUserIdState.set(auth.user.profile.sub);
      activeUserAndOrgIdState.set(`${auth.user.profile.sub}/${orgId}`);
    }
  }, [auth.user?.profile.sub, orgId]);

  // ---- Return section -------------------------------------------------------

  if (isLoading) {
    return <AppLoadingSpinner />;
  }

  if (auth.error) {
    console.error('Auth error:', auth.error);
    return <AuthErrorPage />;
  }

  if (willRedirectToLogin || willRedirectToSelectOrg || orgId === null) {
    return null;
  }

  if (userOrgs != null && Object.keys(userOrgs).length === 0) {
    return <UserNotInOrgsPage authProps={auth} />;
  }

  if (!anyUsableRolesExist) {
    return (
      <div className="app-container">
        <ErrorBase
          title="No permissions"
          hideLinks
          message="You currently don’t have access to DICE based on your current permissions, please contact your organization's administrator to have the appropriate permissions added"
        >
          <LoginErrorButtons authProps={auth} />
        </ErrorBase>
      </div>
    );
  }

  if (!auth.user) {
    console.error(
      'This should not be possible, auth.user should always be populated if we are authenticated and no auth error occurred.',
    );
    return <AuthErrorPage />;
  }

  // Type-assert isLoading is false
  ((a: false) => a)(isLoading);

  if (!activeUser || !activeUser.uuid) {
    const userNotInOrg =
      userErrorData && userErrorData.message === SERVER_USER_NOT_IN_ORG_MESSAGE;
    if (userNotInOrg) {
      return <UserNotInOrgsPage authProps={auth} />;
    }
    return (
      <div className="app-container">
        <ErrorBase
          title="Uh-oh."
          hideLinks
          message={
            'An error occurred loading user information. Please try again. If the issue persists, please contact the DUST customer success team using the button\xA0below.'
          }
        >
          <LoginErrorButtons authProps={auth} />
        </ErrorBase>
      </div>
    );
  }

  // TODO: cast needed until we upgrade to React 18
  return children as JSX.Element;
}
