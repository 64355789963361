import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FieldArray, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { THING_TYPE_FIELD_TYPES } from '@/common/entities/thingTypes/constants';

import ThingTypeFileField from './ThingTypeFileField';

function ThingTypeFilePlaceholder({ formik }) {
  // Template for new static field field
  const newFilePlaceholder = () => ({
    name: '',
    uuid: uuidv4(),
    schema: { type: THING_TYPE_FIELD_TYPES.IMAGE },
  });

  // Must save the original index for assigning to formik
  const placeholderFiles = formik.values.fieldTypes
    .map((field, idx) => [field, idx])
    .filter(
      ([field]) =>
        field.schema.type !== THING_TYPE_FIELD_TYPES.STRING &&
        field.value?.type !== 'STATIC_MEDIA',
    );

  return (
    <div className="flex-col gap-1">
      <FormikProvider value={formik}>
        <FieldArray
          name="fieldTypes"
          render={(arrayHelpers) => (
            <>
              <div className="flex-col">
                {placeholderFiles.map(([field, idx]) => (
                  <ThingTypeFileField
                    field={field}
                    fieldKey="fieldTypes"
                    formik={formik}
                    idx={idx}
                    key={field.uuid}
                    onRemoveFile={(idx) => arrayHelpers.remove(idx)}
                  />
                ))}
              </div>
              <Button
                fullWidth={false}
                onClick={() => arrayHelpers.push(newFilePlaceholder())}
                startIcon={<Add />}
                sx={{ marginTop: '.5rem', maxWidth: '5rem' }}
              >
                Add
              </Button>
            </>
          )}
          validateOnChange={false}
        />
      </FormikProvider>
    </div>
  );
}

ThingTypeFilePlaceholder.propTypes = {
  formik: PropTypes.object,
};

export default ThingTypeFilePlaceholder;
