import { useState, useEffect, useMemo } from 'react';

import { Button } from '@mui/material';
import { nanoid } from 'nanoid';
import { useRecoilValue } from 'recoil';

import samplePDF from '@/assets/dice-csv-import-instructions.pdf';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import useToasts from '@/common/hooks/useToasts';
import {
  renameMoveColumn,
  addColumn,
  removeColumns,
  suffixDuplicateFields,
  quantityToRows,
} from '@/common/utilities/table';
import {
  parseCSV,
  createCSV,
  isWordMatch,
  includesWordMatch,
} from '@/common/utility';
import CatalogListRecoilSelector from '@/components/Composed/CatalogListSelector/CatalogListRecoilSelector';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustEditTable from '@/components/Library/DustEditTable/DustEditTable';
import DustFieldLabel from '@/components/Library/DustFieldLabel';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustModal from '@/components/Library/DustModal';
import { clone } from '@/components/Library/utility';
import { selectedCatalogAtom } from '@/state/atoms/catalog';

import { DEFAULT_CSV_DATA, FIELD_NAMES, ERRORS } from './constants';
import EditTableButton from './EditTableButton';
import styles from './ThingDetailsMulti.module.css';
import TitleColumnSelect from './TitleColumnSelect';
import {
  validateFieldName,
  validateCells,
  checkTotalQuantity,
} from './validators';

// Column indices for specific fields
const FIELD_LOCATIONS = {
  QUANTITY: 1,
  TITLE: 2,
};

const initialValues = DEFAULT_CSV_DATA.DATA();

type Props = {
  onDepsSubmitSuccess: (csv: Blob, catalogUuid: string) => void;
  formId?: string;
  setSubmitLabel: (label: string) => void;
};

export default function ThingDetailsMulti({
  formId,
  onDepsSubmitSuccess,
  setSubmitLabel,
}: Props) {
  const { addToast } = useToasts();

  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState(DEFAULT_CSV_DATA.DATA());
  const [headerRow, setHeaderRow] = useState(DEFAULT_CSV_DATA.HEADER);
  /** Contains the first row value */
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  // Only block navigation if the table data has changed.
  // Do not check id column when comparing
  const blockNavigation =
    !isSubmittingForm &&
    (data.length !== initialValues.length ||
      data[0].length !== initialValues[0].length ||
      initialValues[0].some((v, idx) => idx > 0 && v !== data[0][idx]));

  // Note - navigation happens in the parant component
  useNavigationPrompt(blockNavigation);

  const [needsTitle, setNeedsTitle] = useState(false);
  const [isQtyError, setIsQtyError] = useState(false);

  const catalog = useRecoilValue(selectedCatalogAtom);
  const [catalogError, setCatalogError] = useState('');
  useEffect(() => {
    setCatalogError('');
  }, [catalog, setCatalogError]);

  const totalQuantity = useMemo(
    () => checkTotalQuantity(data, headerRow),
    [data, headerRow],
  );

  // Get validation errors
  const cellErrors = validateCells(data, headerRow);

  // On file load populate the table
  const handleFile = async (newFile: File | null) => {
    if (newFile) {
      const parseResult = await parseCSV(newFile, { addId: true });

      if (!includesWordMatch(parseResult.header, FIELD_NAMES.DICE_QTY)) {
        parseResult.header = [FIELD_NAMES.DICE_QTY, ...parseResult.header];
        parseResult.data = parseResult.data.map((row) => ['1', ...row]);
      }

      // Move the DICE qty to column 1 if it exists
      const qtyMoved = renameMoveColumn(
        FIELD_NAMES.DICE_QTY,
        FIELD_NAMES.DICE_QTY,
        FIELD_LOCATIONS.QUANTITY,
        parseResult.header,
        parseResult.data,
      );

      // Move the DICE title to column 2 if it exists. Change text to 'title' so that other titles will be suffixed
      const titleMoved = renameMoveColumn(
        FIELD_NAMES.TITLE,
        'title',
        FIELD_LOCATIONS.TITLE,
        qtyMoved.headers,
        qtyMoved.data,
      );

      // Suffix header fields if there is duplicates
      const uniqueHeaders = suffixDuplicateFields(titleMoved.headers);

      // Clean up and format text
      const finalHeaders = uniqueHeaders.map((f) => {
        // Return exact case for special fields
        if (isWordMatch(f, FIELD_NAMES.TITLE)) return FIELD_NAMES.TITLE;
        if (isWordMatch(f, FIELD_NAMES.DICE_QTY)) return FIELD_NAMES.DICE_QTY;

        // Otherwise just trim whitespace
        return f.trim();
      });

      if (
        uniqueHeaders.some(
          (h, idx) =>
            h !== FIELD_NAMES.TITLE && !isWordMatch(h, titleMoved.headers[idx]),
        )
      ) {
        addToast(ERRORS.DUPLICATE_HEADER_IMPORT, 'warning');
      }
      setNeedsTitle(!finalHeaders.includes(FIELD_NAMES.TITLE));

      // Set data and populate table
      setData(titleMoved.data);
      setHeaderRow(finalHeaders);
    } else {
      // Default data if no file is available
      setData(DEFAULT_CSV_DATA.DATA());
      setHeaderRow(DEFAULT_CSV_DATA.HEADER);
      setNeedsTitle(false);
    }

    // Save the file to state for the form field
    setFile(newFile);
    setSelectedRows([]);
  };

  // Sort the data from a specific column
  const handleSort = (field: string, isDirAsc: boolean) => {
    const sortIdx = headerRow.findIndex((e) => e === field);
    const newData = clone(data);
    // Check for any numeric values in a subsection of the data to be sorted
    if (newData.slice(0, 10).some((x) => typeof x[sortIdx] === 'number')) {
      // Sort numerically
      newData.sort(
        isDirAsc
          ? (a, b) => Number(a[sortIdx]) - Number(b[sortIdx])
          : (a, b) => Number(b[sortIdx]) - Number(a[sortIdx]),
      );
    } else {
      newData.sort(
        isDirAsc
          ? (a, b) => String(a[sortIdx]).localeCompare(String(b[sortIdx]))
          : (a, b) => String(b[sortIdx]).localeCompare(String(a[sortIdx])),
      );
    }
    setData(newData);
  };

  const handleReject = (errors: string[]) => {
    errors.forEach((error) => addToast(error, 'error'));
  };

  // ---------------------------------------------------------------------------
  // HANDLE DATA CHANGE ACTIONS

  // Change a pre-existing column into the title column
  const setTitleColumn = (colName: string) => {
    const newTable = renameMoveColumn(
      colName,
      FIELD_NAMES.TITLE,
      FIELD_LOCATIONS.TITLE,
      headerRow,
      data,
    );
    setNeedsTitle(false);
    setData(newTable.data);
    setHeaderRow(newTable.headers);
  };

  const addTableColumn = () => {
    const result = addColumn(data, headerRow, '', 'Field Name', 3);
    setHeaderRow(result.headers);
    setData(result.data);
  };

  const removeTableColumns = (removeFields: string[]) => {
    const result = removeColumns(data, headerRow, removeFields);
    setHeaderRow(result.headers);
    setData(result.data);
  };

  // Edit data values
  const editCell = (field: string, rowUID: string, value: string | number) => {
    const colIdx = headerRow.findIndex((i) => i === field);
    const rowIdx = data.findIndex((r) => r[0] === rowUID);
    const newData = clone(data);
    newData[rowIdx][colIdx] = value;
    setData(newData);
  };

  // Edit the field title
  const editHeaderCell = (field: string, value: string | number) => {
    const colIdx = headerRow.findIndex((i) => i === field);
    const verifiedFieldName = validateFieldName(value, headerRow, colIdx);

    // Reset value on errors
    if (verifiedFieldName.error) {
      addToast(verifiedFieldName.message, 'error');
      return false;
    }

    const newHeaders = clone(headerRow);
    newHeaders[colIdx] = verifiedFieldName.value;

    // Check if columns need to be moved
    const titleIdx = headerRow.findIndex((v) =>
      isWordMatch(v, FIELD_NAMES.TITLE),
    );
    const qtyIdx = headerRow.findIndex((v) =>
      isWordMatch(v, FIELD_NAMES.DICE_QTY),
    );

    if (titleIdx && titleIdx !== FIELD_LOCATIONS.TITLE) {
      const newTable = renameMoveColumn(
        FIELD_NAMES.TITLE,
        FIELD_NAMES.TITLE,
        FIELD_LOCATIONS.TITLE,
        newHeaders,
        data,
      );
      setData(newTable.data);
      setHeaderRow(newTable.headers);
    } else if (qtyIdx && qtyIdx !== FIELD_LOCATIONS.QUANTITY) {
      const newTable = renameMoveColumn(
        FIELD_NAMES.DICE_QTY,
        FIELD_NAMES.DICE_QTY,
        FIELD_LOCATIONS.QUANTITY,
        newHeaders,
        data,
      );
      setData(newTable.data);
      setHeaderRow(newTable.headers);
    } else {
      setHeaderRow(newHeaders);
    }

    return true;
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteRows = () => {
    setShowDeleteModal(false);

    const newData = data.filter((row) => !selectedRows.includes(row[0]));
    if (newData.length > 0) {
      setData(newData);
    } else {
      void handleFile(null); // Default to empty table
    }
  };

  const addRow = () => {
    const newRow = data[0].map(() => '');
    newRow[0] = nanoid(); // Set ID
    newRow[1] = ''; // Set Title
    setData([newRow, ...data]);
  };

  // ---------------------------------------------------------------------------
  // DATA CHANGE SIDE EFFECTS

  // Require title column to be set
  useEffect(() => {
    setNeedsTitle(!includesWordMatch(headerRow, FIELD_NAMES.TITLE));
  }, [headerRow]);

  // Update the create button text based on the number of things
  useEffect(() => {
    setIsQtyError(totalQuantity > 10000);
    setSubmitLabel(`Create ${totalQuantity} Things`);
  }, [data, headerRow, setSubmitLabel, totalQuantity]);

  // Keep track of the total rows
  useEffect(() => {
    if (isQtyError) {
      addToast(ERRORS.TO_MANY_THINGS, 'error');
    }
  }, [isQtyError, addToast]);

  // ---------------------------------------------------------------------------
  // SUBMIT DATA TO API

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!catalog?.uuid) {
      setCatalogError('Catalog is required');
      return;
    }

    if (headerRow.some((h) => h.trim() === '')) {
      addToast(ERRORS.HEADER_EMPTY, 'error');
      return;
    }

    // Format CSV to handle quantity
    const qtyIdx = headerRow.findIndex((f) =>
      isWordMatch(f, FIELD_NAMES.DICE_QTY),
    );
    const rowIdIdx = headerRow.findIndex((f) =>
      isWordMatch(f, FIELD_NAMES.ROW_ID),
    );
    const columnsToRemove = [rowIdIdx]; // Always remove rowUID

    const finalData = quantityToRows(data, qtyIdx, columnsToRemove);
    if (finalData.length > 10000) {
      addToast(ERRORS.TO_MANY_THINGS, 'error');
      return;
    }

    // Filter removed columns from header row
    const finalHeaders = headerRow.filter(
      (_, idx) => ![qtyIdx, rowIdIdx].includes(idx),
    );

    const csv = createCSV(finalData, finalHeaders, true);

    setIsSubmittingForm(true);
    onDepsSubmitSuccess(csv, catalog.uuid);
  };

  // ---------------------------------------------------------------------------
  // TABLE DATA STRUCTURES
  const cols = headerRow.map((col) => ({
    style:
      col === FIELD_NAMES.DICE_QTY
        ? { maxWidth: '8.5rem', minWidth: '8.5rem' }
        : null,
    field: col,
    headerName: col,
    hide: col === FIELD_NAMES.ROW_ID, // Only hid the UID role
  }));

  const rows = data.map((r) => {
    const row: { [key: string]: string | number } = {};
    cols.forEach((col, idx) => {
      row[col.field] = r[idx];
    });
    return row;
  });

  return (
    <div className="flex-1 flex-col min-h-0">
      <form id={formId} onSubmit={onSubmit} />
      <div className={styles.multiFormLayout}>
        <div>
          <DustFieldLabel tag="h2" className="h4 mb-05" label="CSV Import" />
          <p>
            Add Thing details by uploading a pre-populated CSV file (
            <a
              className="text-link"
              href={samplePDF}
              style={{ fontSize: 'inherit' }}
              download="dice-csv-import-instructions.pdf"
            >
              sample
            </a>
            ).{' '}
          </p>
          <DustFileUploadField
            file={file}
            fileType={FILE_TYPE_OPTIONS.CSV}
            label="Thing CSV"
            onDelete={() => handleFile(null)}
            onReject={handleReject}
            setFile={handleFile}
          />
          {needsTitle && (
            <TitleColumnSelect headers={headerRow} onSelect={setTitleColumn} />
          )}
        </div>
        <div className="mb-1">
          <DustFieldLabel tag="h2" className="h4 mb-05" label="Catalog" />
          <p className="mb-1">Select the catalog to upload the things to.</p>
          <CatalogListRecoilSelector error={catalogError} />
        </div>
      </div>
      <div className="flex-row gap-1 mb-05">
        <h3 className="h6" style={{ marginTop: '1rem' }}>
          Or enter/edit manually.
        </h3>
        <div className="flex-1" />
        <EditTableButton
          headers={headerRow}
          onAddHeader={addTableColumn}
          onRemoveHeader={removeTableColumns}
          restrictedFields={[FIELD_NAMES.ROW_ID, FIELD_NAMES.TITLE]}
        />
        <Button
          onClick={addRow}
          variant="contained"
          sx={{ whiteSpace: 'nowrap', height: '2.5rem' }}
        >
          Add Thing
        </Button>
        <Button
          disabled={selectedRows?.length < 1}
          onClick={() => setShowDeleteModal(true)}
          variant="contained"
          sx={{ whiteSpace: 'nowrap', height: '2.5rem' }}
        >
          Delete Rows
        </Button>
        <DustModal
          footerProps={{
            submitLabel: 'Delete',
            onCancel: () => setShowDeleteModal(false),
            onSubmit: deleteRows,
          }}
          onClose={() => setShowDeleteModal(false)}
          open={showDeleteModal}
          title="Delete Things"
        >
          <p>
            Are you sure you want to delete the selected things? You can&apos;t
            undo this action.
          </p>
        </DustModal>
      </div>
      <DustEditTable
        style={{ minHeight: '20rem' }}
        cols={cols}
        errors={cellErrors}
        onSort={handleSort}
        rowIdField={FIELD_NAMES.ROW_ID}
        rows={rows}
        selected={selectedRows}
        setCell={editCell}
        setHeaderCell={editHeaderCell}
        setSelected={setSelectedRows}
      />
    </div>
  );
}
