import { SvgIcon, SvgIconProps } from '@mui/material';

function DustMultiImageIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M21 1H7C5.9 1 5 1.9 5 3V17C5 18.1 5.9 19 7 19H21C22.1 19 23 18.1 23 17V3C23 1.9 22.1 1 21 1ZM1 5H3V21H19V23H3C1.9 23 1 22.1 1 21V5ZM13.21 13.83L15.96 10.29L19.5 15H8.5L11.25 11.47L13.21 13.83ZM7 17H21V3H7V17Z"
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
}

export default DustMultiImageIcon;
