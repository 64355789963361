import { useRecoilState } from 'recoil';

import {
  navPromptOpenAtom,
  navPromptTxAtom,
  navPromptData,
} from '@/common/hooks/useNavigationPrompt';
import DustModal from '@/components/Library/DustModal';

export default function NavigatePrompt() {
  const [open, setOpen] = useRecoilState(navPromptOpenAtom);
  const [tx, setTx] = useRecoilState(navPromptTxAtom);
  const [data, setData] = useRecoilState(navPromptData);

  // Close with no action
  const handleClose = () => {
    setOpen(false);
    setTx(null);
    setData(null);
  };

  // Close and retry the navigation
  const handleContinue = () => {
    handleClose();
    tx.retry();
  };

  return (
    <DustModal
      footerProps={{
        cancelLabel: 'Cancel',
        onCancel: handleClose,
        showCancel: true,
        submitLabel: 'Continue',
        onSubmit: handleContinue,
      }}
      maxWidth="30rem"
      onClose={() => setOpen(false)}
      open={open}
      title={data?.title ?? 'Are you sure?'}
    >
      {data?.message ??
        'You have unsaved changes that will be discarded when you leave this page. Are you sure you would like to continue?'}
    </DustModal>
  );
}
