import { Fragment, useState } from 'react';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Button } from '@mui/material';

import DustThingThumbnail from '@/components/Library/DustThingThumbnail';
import { combineClass } from '@/components/Library/utility';

import styles from './CheckIn.module.css';
import DepthIndicator from './DepthIndicator';

type Props = {
  existingCatalogNameLookup: { [k: string]: string };
  existingThingLookup: { [k: string]: Thing };
  importThing: Thing;
  importDepth: number;
  existingDepth: number;
  exportBatch: ExportBatch;
  isImportedThingSelected: boolean;
  isServerThingSelected: boolean;
  nothingSelected: boolean;
};

const pluralChildren = (num: number) => {
  switch (num) {
    case 0:
      return 'No Children';
    case 1:
      return '1 Child';
    default:
      return `${num} Children`;
  }
};

const getStartIcon = (
  expandable: boolean,
  expanded: boolean,
  children: number,
) => {
  if (!expandable || children === 0) {
    return null;
  }
  return expanded ? <ExpandLess /> : <ExpandMore />;
};

export default function ResolutionChildren({
  exportBatch,
  existingThingLookup,
  existingCatalogNameLookup,
  importThing,
  importDepth,
  existingDepth,
  isImportedThingSelected,
  isServerThingSelected,
  nothingSelected,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const existingSelectedClasses = [
    isServerThingSelected && styles.selectedCell,
    isImportedThingSelected && styles.unselectedCell,
    nothingSelected && styles.noSelectionCell,
  ];

  const importSelectedClasses = [
    isImportedThingSelected && styles.selectedCell,
    isServerThingSelected && styles.unselectedCell,
    nothingSelected && styles.noSelectionCell,
  ];

  const importChildren = importThing.relationships.children ?? [];
  const existingThing = existingThingLookup[importThing.uuid];
  const existingChildren = existingThing?.relationships?.children ?? [];
  const existingChildrenInImport = existingChildren.filter(
    (c) => existingThingLookup[c.uuid],
  );

  const expandable = !!importChildren.length;

  if (!importChildren.length && !existingChildrenInImport.length) return null;
  return (
    <>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'nowrap',
          marginBottom: expanded ? 0 : '1rem',
        }}
      >
        <Button
          onClick={() => setExpanded(!expanded)}
          sx={{
            justifyContent: 'flex-start',
            borderTop: '1px solid var(--light-grey)',
            borderBottom: '1px solid var(--light-grey)',
            padding: '3px 2rem',
            textTransform: 'none',
            width: '100%',
            bbrderRadius: 0,
          }}
          className={combineClass(...existingSelectedClasses)}
          startIcon={getStartIcon(
            expandable,
            expanded,
            existingChildrenInImport.length,
          )}
          disabled={!expandable || existingChildrenInImport.length === 0}
        >
          {pluralChildren(existingChildren.length)}
          {existingChildren.length === existingChildrenInImport.length
            ? ''
            : `, ${pluralChildren(existingChildrenInImport.length)} in import`}
        </Button>
        <DepthIndicator
          depth={existingChildrenInImport.length ? existingDepth : 0}
        />
      </div>
      <span /* These are for grid alignment */ />
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexWrap: 'nowrap',
          marginBottom: expanded ? 0 : '1rem',
        }}
      >
        <Button
          onClick={() => setExpanded(!expanded)}
          sx={{
            justifyContent: 'flex-start',
            borderTop: '1px solid var(--light-grey)',
            borderBottom: '1px solid var(--light-grey)',
            padding: '3px 2rem',
            textTransform: 'none',
            width: '100%',
            borderRadius: 0,
          }}
          className={combineClass(...importSelectedClasses)}
          startIcon={getStartIcon(expandable, expanded, importChildren.length)}
          disabled={!expandable || importChildren.length === 0}
        >
          {pluralChildren(importChildren.length)}
        </Button>
        <DepthIndicator depth={importChildren.length ? importDepth : 0} />
      </div>
      {expanded &&
        importChildren.map((child) => {
          const existingChild = existingThingLookup[child.uuid];
          const importChild =
            exportBatch.importValidation.thingsByUuid[child.uuid];
          return (
            <Fragment key={child.uuid}>
              <div
                className={combineClass(
                  styles.thingCell,
                  ...existingSelectedClasses,
                )}
                key={`resolution_existing_${child.uuid}`}
              >
                {existingChild && (
                  <>
                    <DepthIndicator
                      depth={
                        existingChild.relationships.parent?.uuid ===
                        importChild.relationships.parent?.uuid
                          ? existingDepth
                          : 0
                      }
                    />
                    <DustThingThumbnail
                      thing={existingChild}
                      className={styles.thingImage}
                    />
                    <div className="flex-col flex-1">
                      <h4 className={styles.thingTitle}>
                        {existingChild.title}
                      </h4>
                      <div className={styles.childOf}>
                        {existingChild.relationships?.parent
                          ? `Child of ${existingChild.relationships.parent.title}`
                          : 'No parent Thing'}
                      </div>
                    </div>
                    {/* spacer for button */}
                    <div className={styles.button} />
                  </>
                )}
              </div>
              <div
                className={styles.middleSwitch}
                key={`resolution_switch_${child.uuid}`}
              />
              <div
                className={combineClass(
                  styles.thingCell,
                  styles.importCell,
                  ...importSelectedClasses,
                )}
                key={`resolution_import_${child.uuid}`}
              >
                <div className="flex-col flex-1 pl-1">
                  <DepthIndicator depth={importDepth} />
                  <h4 className={styles.thingTitle}>{importChild.title}</h4>
                  <div className={styles.childOf}>
                    Child of {importThing.title}
                  </div>
                </div>
              </div>
              <ResolutionChildren
                exportBatch={exportBatch}
                importThing={importChild}
                existingThingLookup={existingThingLookup}
                existingCatalogNameLookup={existingCatalogNameLookup}
                importDepth={importDepth + 1}
                existingDepth={
                  existingChildrenInImport.length ? existingDepth + 1 : 0
                }
                isImportedThingSelected={isImportedThingSelected}
                isServerThingSelected={isServerThingSelected}
                nothingSelected={nothingSelected}
                key={`resolution_children_${child.uuid}`}
              />
            </Fragment>
          );
        })}
    </>
  );
}
