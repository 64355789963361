import { useAuth } from 'react-oidc-context';
import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 10 * 60 * 1000;

export default function useOrgData() {
  const { orgApi, orgId, QUERY_KEYS } = useRequest();

  const auth = useAuth();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.ORG(orgId ?? 'no-org'),
    () => orgApi.getCurrentOrg(),
    { staleTime: STALE_TIME, enabled: !!auth.user?.access_token },
  );

  return {
    org: data?.error ? undefined : data?.data,
    isSuccess,
    isLoading,
  };
}
