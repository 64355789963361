import SummaryXTag from './SummaryXTag';
import styles from './XTagSummary.module.css';

type XTagSummaryProps = {
  thing: Thing;
};

export default function XTagSummary({ thing }: XTagSummaryProps) {
  const { xtags } = thing;
  if (!xtags || !xtags.length) {
    return <div className={styles.summary}>No tags exist for this thing.</div>;
  }
  return (
    <div className={styles.summary}>
      {xtags.map((tag) => (
        <SummaryXTag tag={tag} thing={thing} key={tag.uuid} />
      ))}
    </div>
  );
}
