import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip, IconButton, TooltipProps, SvgIconProps } from '@mui/material';

export type Props = {
  title: React.ReactNode;
  tooltipProps?: TooltipProps;
} & SvgIconProps;

export default function DustInfoIconTooltip({
  title,
  tooltipProps,
  ...props
}: Props) {
  return (
    <Tooltip title={title} {...tooltipProps}>
      <IconButton>
        <InfoOutlinedIcon color="secondary" {...props} />
      </IconButton>
    </Tooltip>
  );
}
