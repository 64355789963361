import { useEffect, useRef } from 'react';

/** Fire an action one when the trigger is set to true */
export default function useEffectOnceWhen(
  action: () => void,
  trigger: boolean,
) {
  const hasTriggered = useRef(false);

  useEffect(() => {
    if (!hasTriggered.current && trigger) {
      hasTriggered.current = true;
      action();
    }
  }, [action, trigger]);
}
