import { TextField, Tooltip } from '@mui/material';
import { FormikProps } from 'formik';

import { THING_TYPE_FIELD_TYPES } from '@/common/entities/thingTypes/constants';
import {
  titleFieldToString,
  titleDisplayStringFromFieldValues,
} from '@/common/entities/thingTypes/utility';
import DustSchemaTextField from '@/components/Library/DustSchemaTextField';

type Props = {
  formik: FormikProps<AddThingForm>;
  thingType: ThingType | null;
  disableAll: boolean;
};

export default function ThingDetailTitle({
  formik,
  thingType = null,
  disableAll = false,
}: Props) {
  const titleDisplayValue = titleDisplayStringFromFieldValues(
    thingType,
    formik.values.typedMetadata,
  );

  return formik.values.isComputedTitle && thingType?.fieldTypes ? (
    <>
      <Tooltip
        enterDelay={500}
        title="This title is set by the Thing Type and cannot be edited."
        placement="top"
      >
        <div>
          <DustSchemaTextField
            label="Title"
            disabled
            className="mt-1"
            setValue={() => null}
            value={titleFieldToString(thingType)}
            options={thingType.fieldTypes
              .filter(
                (field) => field.schema.type === THING_TYPE_FIELD_TYPES.STRING,
              )
              .map((field) => field.name)}
          />
        </div>
      </Tooltip>
      {titleDisplayValue && (
        <p className="text-sm mt-05 mb-1">Title preview: {titleDisplayValue}</p>
      )}
    </>
  ) : (
    <TextField
      disabled={disableAll}
      error={formik.touched.title && !!formik.errors.title}
      helperText={formik.touched.title && formik.errors.title}
      label="Title*"
      margin="normal"
      name="title"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      size="small"
      sx={{
        marginBottom: '2rem',
        display: 'flex',
      }}
      value={formik.values.title}
      variant="outlined"
      fullWidth
      aria-label="Enter a title for your Thing"
    />
  );
}
