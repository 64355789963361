import { useState, useRef, useCallback } from 'react';

import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import { useResize } from '@/common/hooks';
import { idxInRange } from '@/common/utility';

import styles from './EditTable.module.css';
import EditTableHeaderCell from './EditTableHeaderCell';
import EditTableRow from './EditTableRow';

function DustEditTable({
  rows,
  cols,
  setCell,
  setHeaderCell,
  onSort,
  errors,
  selected,
  setSelected,
  style = {},
  rowIdField = 'id',
}) {
  // ---------------------------------------------------------------------------
  // HANDLE RENDERING ONLY THE VISIBLE RANGE

  const table = useRef(null);
  const [showRange, setShowRange] = useState(
    /** @type {[number, number]} */ ([0, 2]),
  );

  const setRenderRange = () => {
    const start = Math.floor(table.current.scrollTop / 48);
    const end = Math.floor(table.current.offsetHeight / 48) + 1;
    setShowRange([start, start + end]);
  };

  // Only render a range that fits. Must be updated on first render to check height
  const onResize = useCallback(setRenderRange, []);
  const { resizeRef } = useResize({ callback: onResize });

  // ---------------------------------------------------------------------------
  // HANDlE KEYBOARD NAVIGATION

  // Save reference to which cell is currently focuses to allow programmatic moving
  const [focusedCell, setFocusedCell] = useState([0, 1]);
  const focusCell = (colIdx, rowIdx) => {
    setFocusedCell([rowIdx, colIdx + 1]);
  };

  // Use arrow keys to navigate table
  const handleKeyPress = (evt) => {
    if (evt.code === 'ArrowUp' && focusedCell[0] > 0) {
      const newIdx = focusedCell[0];
      table.current.children[newIdx].children[
        focusedCell[1]
      ].children[0].focus();
    }
    if (evt.code === 'ArrowDown' && focusedCell[0] < rows.length - 1) {
      const newIdx = focusedCell[0] + 2;
      table.current.children[newIdx].children[
        focusedCell[1]
      ].children[0].focus();
    }
  };

  // ---------------------------------------------------------------------------
  // HANDLE SELECTION
  const handleRowCheck = (checked, rowUID) => {
    if (checked) {
      setSelected([...selected, rowUID]);
    } else {
      setSelected(selected.filter((uid) => rowUID !== uid));
    }
  };
  const handleHeaderCheck = (evt) => {
    if (evt.target.checked) {
      setSelected(rows.map((r) => r[rowIdField]));
    } else {
      setSelected([]);
    }
  };

  // ---------------------------------------------------------------------------
  // HANDLE SORTING
  const [sortedField, setSortedField] = useState(null);
  const handleSort = (field, direction) => {
    setSortedField(field);
    onSort(field, direction);
  };

  return (
    <div
      className="flex-1 relative"
      ref={resizeRef}
      style={{ height: '100%', position: 'relative', ...style }}
    >
      {/* TODO: deal with accessibility lint */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.container}
        onKeyDown={handleKeyPress}
        onScroll={setRenderRange}
        ref={table}
      >
        <div className={styles.rowHeader}>
          <div className={styles.headerCheckbox}>
            <Checkbox
              checked={selected.length === rows.length}
              indeterminate={
                selected.length > 0 && selected.length !== rows.length
              }
              onChange={handleHeaderCheck}
              sx={{
                color: 'var(--text-contrast)',
                '&.Mui-checked': { color: 'var(--text-contrast)' },
                '&.MuiCheckbox-indeterminate': {
                  color: 'var(--text-contrast)',
                },
              }}
            />
          </div>
          {cols.map((col, idx) => (
            <EditTableHeaderCell
              field={col.field}
              style={col.style}
              hideCell={col.hide}
              // eslint-disable-next-line react/no-array-index-key
              key={`${col.field}-${idx}`}
              onFocusCell={focusCell}
              onSort={onSort ? handleSort : null}
              setHeaderCell={setHeaderCell}
              sortedField={sortedField}
            />
          ))}
        </div>
        {rows.map((row, rowIdx) =>
          idxInRange(rowIdx, showRange) ? (
            <EditTableRow
              checked={selected.includes(row[rowIdField])}
              cols={cols}
              errors={errors}
              key={row[rowIdField]}
              onChecked={handleRowCheck}
              onFocusCell={focusCell}
              row={row}
              rowIdField={rowIdField}
              rowIdx={rowIdx}
              setCell={setCell}
            />
          ) : (
            <div className={styles.rowBlank} key={row[rowIdField]} />
          ),
        )}
      </div>
    </div>
  );
}

DustEditTable.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
  setCell: PropTypes.func,
  setHeaderCell: PropTypes.func,
  onSort: PropTypes.func,
  errors: PropTypes.object,
  style: PropTypes.object,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  rowIdField: PropTypes.string,
};

export default DustEditTable;
