import { useState, useRef } from 'react';

import FilterIcon from '@mui/icons-material/FilterList';
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { IconButton } from '@mui/material';

import AppSidebar from '@/components/AppSidebar';
import DustLinkButton from '@/components/Library/DustLinkButton';

import styles from './TableFilter.module.css';

type Props = {
  children: React.ReactNode;
  showReset?: boolean;
  onReset?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  label?: string;
  iconType?: string;
};

const icons = {
  filter: {
    title: 'Filter Items',
    icon: <FilterIcon />,
  },
  column: {
    title: 'Column Selection',
    icon: <ViewColumnOutlinedIcon />,
  },
};

export default function TableFilter({
  children,
  showReset,
  onReset,
  onOpen,
  onClose,
  label = 'Filters',
  iconType = 'filter',
}: Props) {
  const anchor = useRef(null);
  const [menuEl, setMenuEl] = useState(false);
  const handleSetMenu = (el: boolean) => {
    if (el && onOpen) {
      onOpen();
    }
    setMenuEl(el);
  };

  type IconType = keyof typeof icons;
  const iconKey = iconType as IconType;

  return (
    <>
      <IconButton
        onClick={() => handleSetMenu(!menuEl)}
        ref={anchor}
        title={icons[iconKey].title}
      >
        {icons[iconKey].icon}
      </IconButton>
      <AppSidebar
        contentClassName={styles.contentPadding}
        onClose={onClose}
        open={!!menuEl}
        setOpen={handleSetMenu}
        title={
          <>
            <h2 className="h4">{label}</h2>
            {showReset && (
              <DustLinkButton
                onClick={onReset ?? undefined}
                sx={{ margin: '0 1rem', paddingBottom: '.2rem' }}
              >
                Clear filters
              </DustLinkButton>
            )}
          </>
        }
      >
        {children}
      </AppSidebar>
    </>
  );
}
