import { useState, useMemo } from 'react';

import RemoveIcon from '@mui/icons-material/Remove';
import { Tooltip } from '@mui/material';

import { provideFallbackImage } from '@/common/utilities/images';
import ThingFileModal from '@/components/Composed/ThingFileModal/ThingFileModal';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import ThingPlaceholderImage from '@/components/Pages/Things/Thing/ThingPlaceholderImage';
import useRequest from '@/services/requests/useRequest';

import styles from './Thing.module.css';

type Props = {
  thing: Thing;
  thingType?: ThingType | null;
  disableInteraction?: boolean;
};

export default function ThingSummaryImage({
  thing,
  thingType = undefined,
  disableInteraction = false,
}: Props) {
  const { thingsApi } = useRequest();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showFileAdd, setShowFileAdd] = useState(false);

  const primaryImage = useMemo(() => thing.primaryImage, [thing]);
  const isStatic = !thing.canChangePrimaryImage;

  const removePrimary = async () => {
    if (!primaryImage) return;

    setIsSubmitting(true);
    if (thingType) {
      await thingsApi.removeThingFile({
        thingUuid: thing.uuid,
        fileUuid: primaryImage.uuid,
      });
    } else {
      await thingsApi.updateThingFile({
        thingUuid: thing.uuid,
        fileUuid: primaryImage.uuid,
        isPrimary: false,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <div className={styles.primaryImageCont}>
      {!primaryImage?.publicUri && (
        <ThingPlaceholderImage
          disabled={disableInteraction}
          onClick={() => setShowFileAdd(true)}
        />
      )}
      {primaryImage?.publicUri && (
        <Tooltip
          enterDelay={1000}
          title={
            isStatic
              ? 'The Primary Image is set by the Thing Type and cannot be edited.'
              : `${thing.title} primary image.`
          }
        >
          <img
            alt={`Reference for ${thing.title}`}
            className={styles.primaryImage}
            onError={provideFallbackImage}
            src={primaryImage?.publicUri}
          />
        </Tooltip>
      )}
      {primaryImage?.publicUri && !isStatic && !disableInteraction && (
        <DustLoadingButton
          color="error"
          isIcon
          loading={isSubmitting}
          onClick={removePrimary}
          title="Remove image as primary"
          sx={{
            position: 'absolute',
            right: '-.5rem',
            bottom: '-.5rem',
            background: 'var(--error-red)',
            color: 'var(--white)',
            padding: 0,
            '&:hover': {
              background: 'var(--error-red)',
              boxShadow: 'var(--elevation-7)',
            },
            '&:focus': {
              background: 'var(--error-red)',
              boxShadow: 'var(--elevation-7)',
            },
          }}
        >
          <RemoveIcon />
        </DustLoadingButton>
      )}
      <ThingFileModal
        open={showFileAdd}
        setOpen={setShowFileAdd}
        showAddFiles={false}
        things={[thing]}
      />
    </div>
  );
}
