import { useState } from 'react';

import useCheckout, { CHECKOUT_STEPS } from '@/common/hooks/useCheckout';
import DustModal from '@/components/Library/DustModal';
import DustStepperFooter from '@/components/Library/DustStepperFooter';

type ShoppingCartActionsProps = {
  things: ShoppingCartThings;
};

export default function ShoppingCartActions({
  things,
}: ShoppingCartActionsProps) {
  const { isCommitting, checkout, cartPanel, shoppingCart } = useCheckout();

  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const handleRemoveAll = async () => {
    await checkout.removeAll();
    setShowRemoveConfirm(false);
  };

  return shoppingCart.numberOfRootThings > 0 ? (
    <>
      <DustStepperFooter
        cancelLabel="Remove All"
        disabled={isCommitting || !!things.errorThingsCount}
        onCancel={() => setShowRemoveConfirm(true)}
        onSubmit={() => cartPanel.setStep(CHECKOUT_STEPS.CONFIRM)}
        submitLabel="Checkout"
      />

      <DustModal
        footerProps={{
          onSubmit: handleRemoveAll,
          disabled: isCommitting,
          submitLabel: 'Remove All',
          loading: cartPanel.isRemovingAll,
          onCancel: () => setShowRemoveConfirm(false),
        }}
        onClose={() => setShowRemoveConfirm(false)}
        open={showRemoveConfirm}
        title="Remove Catalogs"
      >
        <p>Are you sure you want to remove ALL catalogs from the export?</p>
      </DustModal>
    </>
  ) : null;
}
