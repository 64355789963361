import { Tooltip } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import { formatDateForDisplay } from '@/common/dates';
import { ExportBatch } from '@/common/entities/exportBatches/typedefs';
import useCheckout, { EXPORT_STEPS } from '@/common/hooks/useCheckout';
import { ROUTES } from '@/common/routes';
import { timeSort } from '@/common/utilities/sort';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustLinkButton from '@/components/Library/DustLinkButton';

type ExportBatchesTableProps = {
  exportBatches: ExportBatch[];
  isLoading: boolean;
};

function ExportBatchesTable({
  exportBatches = [],
  isLoading,
}: ExportBatchesTableProps) {
  const isRowClickable = (exportBatch: ExportBatch) =>
    exportBatch.status === 'COMPLETE' && exportBatch.thingCount > 0;

  const { exportModal } = useCheckout();

  const navigate = useNavigate();

  const handleDownload = async (exportBatch: ExportBatch) => {
    await exportModal.initializeExportModal({
      newExportBatchPlaceholder: exportBatch.placeholder,
      step: EXPORT_STEPS.CONFIRM,
    });
  };

  // TODO: wrap in useMemo
  const columns = [
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (exportBatch) => exportBatch.uuid,
      field: 'id',
      headerName: 'id',
      hide: true,
      flex: 1,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (exportBatch) => exportBatch,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderCell: ({ formattedValue: batch }) => (
        <Tooltip title={batch.name ?? ''}>
          <DustLinkButton
            onClick={() => navigate(ROUTES.ADMIN_CHECKOUT_BATCH(batch.uuid))}
          >
            {batch.name}
          </DustLinkButton>
        </Tooltip>
      ),
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (exportBatch) => exportBatch.description,
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: false,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (thing) => thing.createdAt,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      valueFormatter: ({ value }) => formatDateForDisplay(value),
      sortComparator: timeSort,
      field: 'createdAt',
      headerName: 'Created at',
      flex: 1,
      sortable: false,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (exportBatch) => exportBatch.thingCount,
      field: 'thingsCount',
      headerName: 'Thing Count',
      flex: 1,
      sortable: false,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (exportBatch) => exportBatch.createdBy.name,
      field: 'user',
      headerName: 'User',
      flex: 1,
      sortable: false,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      objGetter: (exportBatch) => exportBatch,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderCell: ({ value: exportBatch }) =>
        isRowClickable(exportBatch) ? (
          <DustLinkButton
            sx={{ textDecoration: 'none' }}
            onClick={() => handleDownload(exportBatch)}
          >
            Download
          </DustLinkButton>
        ) : (
          ''
        ),
      field: 'downloadLink',
      headerName: 'Download',
      flex: 1,
      sortable: false,
    },
  ];

  // TODO: remove in favor of col-def field + valueGetter
  const rows = exportBatches.map((exportBatch) => {
    const row = {};
    columns.forEach((col) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      row[col.field] = col.objGetter(exportBatch);
    });
    return row;
  });

  return (
    <DustDataGrid
      altTheme
      columns={columns as GridColumns}
      initialPageSize={5}
      loading={isLoading}
      rows={rows}
      rowCount={rows.length}
    />
  );
}

export default ExportBatchesTable;
