import { useMemo, useCallback, useState } from 'react';

import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { formatDateForDisplay } from '@/common/dates';
import useFeaturesData from '@/common/entities/features/useFeaturesData';
import useOrgsData from '@/common/entities/orgs/useOrgsData';
import { camelToTitleCase } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import WrapTextSpan from '@/components/Library/WrapTextSpan';
import useRequest from '@/services/requests/useRequest';

import ConfirmChangeFeatureFlagDialog, {
  ChangeFeatureActions,
  ConfirmChangeFeatureDialogState,
} from './ConfirmChangeFeatureFlagDialog';
import OrgDetailCell from './OrgDetailCell';
import styles from './OrgsTable.module.css';

type OrgGridColDef = GridColDef<Org>;

const getOrgRowId = (a: Org) => a.uuid;

const orgStatusMap = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

const PINNED_COLUMNS = {
  left: ['name', 'status'],
};

const renderWrappedHeader: OrgGridColDef['renderHeader'] = ({
  colDef: { headerName },
}) => <WrapTextSpan className={styles.centerText}>{headerName}</WrapTextSpan>;

const displayDateValueFormatter = ({ value }: { value: string | Date }) =>
  formatDateForDisplay(value, { distanceFromNow: false });

function getFeatureFlagOnOrg(org: Org, feature: Feature) {
  return org.isFeatureEnabled(feature.name);
}

export default function OrgsTable() {
  const {
    data: orgs,
    isLoading: isLoadingOrgs,
    isError: isErrorOrgs,
  } = useOrgsData();
  const { data: features, isLoading: isLoadingFeatures } = useFeaturesData();
  const { featuresApi } = useRequest();

  const [confirmChangeFeatureFlagState, setConfirmChangeFeatureFlagState] =
    useState<ConfirmChangeFeatureDialogState | null>(null);

  const [isUpdating, setIsUpdating] = useState(false);

  const startChangeOrgFeatureFlag = useCallback(
    (org: Org, feature: Feature) =>
      setConfirmChangeFeatureFlagState({
        action: getFeatureFlagOnOrg(org, feature)
          ? ChangeFeatureActions.Remove
          : ChangeFeatureActions.Add,
        feature,
        org,
      }),
    [],
  );

  const handleCancelChangeFeatureFlag = useCallback(() => {
    setConfirmChangeFeatureFlagState(null);
  }, []);
  const handleConfirmChangeFeatureFlag = useCallback(async () => {
    setIsUpdating(true);
    if (confirmChangeFeatureFlagState == null) {
      console.error('confirmChangeFeatureFlagState was unexpectedly null');
      return;
    }
    await featuresApi.toggleFeatureForAccount({
      featureUuid: confirmChangeFeatureFlagState.feature.uuid,
      accountUuid: confirmChangeFeatureFlagState.org.uuid,
      newIsActive:
        confirmChangeFeatureFlagState.action === ChangeFeatureActions.Add,
    });
    // Cleanup
    setIsUpdating(false);
    setConfirmChangeFeatureFlagState(null);
  }, [featuresApi, confirmChangeFeatureFlagState]);

  const columns = useMemo(
    (): OrgGridColDef[] => [
      {
        headerName: 'Details',
        field: 'name', // for sorting
        width: 270, // About right to hold uuid value
        renderCell: ({ value, row: { uuid } }) => (
          <OrgDetailCell name={value} uuid={uuid} />
        ),
      },
      {
        headerName: 'Status',
        field: 'status',
        valueFormatter: ({ value }) =>
          orgStatusMap[value as keyof typeof orgStatusMap] ?? value,
      },
      {
        headerName: 'Created at',
        field: 'createdAt',
        valueFormatter: displayDateValueFormatter,
        renderCell: ({ formattedValue }) => (
          <WrapTextSpan>{formattedValue}</WrapTextSpan>
        ),
        flex: 1,
        sortingOrder: ['desc', 'asc'],
      },
      {
        headerName: 'Last Updated',
        renderHeader: renderWrappedHeader,
        field: 'updatedAt',
        valueFormatter: displayDateValueFormatter,
        renderCell: ({ formattedValue }) => (
          <WrapTextSpan>{formattedValue}</WrapTextSpan>
        ),
        flex: 1,
      },
      {
        // &nbsp; to limit wrapping for 3-worded-header
        headerName: 'Cache Cold\xA0Starts',
        renderHeader: renderWrappedHeader,
        field: 'allowCacheColdStarts',
        type: 'boolean', // this also aligns the header and the cell
        flex: 1,
      },
      {
        headerName: 'Internal Org',
        renderHeader: renderWrappedHeader,
        field: 'isInternal',
        type: 'boolean',
        flex: 1,
      },
      // Dynamic feature columns
      ...(features ?? []).map(
        (feature): OrgGridColDef => ({
          headerName: camelToTitleCase(feature.name),
          renderHeader: renderWrappedHeader,
          field: `feature-${feature.name}`,
          type: 'boolean',
          renderCell: ({ row }) => (
            // NOTE: Technically, this click could probably be handled
            // better using the data grid's editing feature, but this table
            // has enough effort put into it already, so janky keyboard
            // navigation is acceptable because this is a root-user-only
            // visible table
            <Checkbox
              checked={!!getFeatureFlagOnOrg(row, feature)}
              onClick={() => {
                startChangeOrgFeatureFlag(row, feature);
              }}
            />
          ),
          flex: 1,
        }),
      ),
    ],
    [features, startChangeOrgFeatureFlag],
  );

  return (
    <>
      <DustDataGrid
        altTheme
        loading={isLoadingOrgs || isLoadingFeatures || isUpdating}
        error={isErrorOrgs ? true : null}
        columns={columns}
        pinnedColumns={PINNED_COLUMNS}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'asc' }],
          },
        }}
        rows={orgs ?? []}
        getRowId={getOrgRowId}
      />
      {confirmChangeFeatureFlagState && (
        <ConfirmChangeFeatureFlagDialog
          open={!isUpdating /* TODO: does a cleaner pattern exist? */}
          state={confirmChangeFeatureFlagState}
          onCancel={handleCancelChangeFeatureFlag}
          onConfirm={handleConfirmChangeFeatureFlag}
        />
      )}
    </>
  );
}
