import { useCallback, useMemo } from 'react';

import useCatalogsData from '@/common/entities/catalogs/useCatalogsData';
import {
  ExportBatch,
  RESOLUTIONS,
  Resolution,
  ResolutionObject,
} from '@/common/entities/exportBatches/typedefs';
import useThingsData from '@/common/entities/things/useThingsData';
import DustStepperFooter from '@/components/Library/DustStepperFooter';

import styles from './CheckIn.module.css';
import Loading from './Loading';
import ResolutionBookend from './ResolutionBookend';
import ResolutionRow from './ResolutionRow';
import RowSpacer from './RowSpacer';

type Props = {
  exportBatch: ExportBatch;
  onSuccess: () => void;
  onResolutionsUpdate: (resolutions: ResolutionObject[]) => void;
  resolutions: ResolutionObject[];
  onPrevious: () => void;
};

export default function ConfictsStep({
  exportBatch,
  resolutions,
  onSuccess,
  onResolutionsUpdate,
  onPrevious,
}: Props) {
  const { things: existingThings, isLoading } = useThingsData({
    fixedParams: {
      thingUuids: resolutions.map((r) => r.thingUuid),
      fields: ['children', 'parent', 'root'],
    },
    fetchAll: true,
  });

  const { catalogs } = useCatalogsData({ fetchAll: true });

  const unresolvedConflicts = resolutions.some(
    (r) => r.resolution === RESOLUTIONS.NO_RESOLUTION,
  );

  const handleResolution = useCallback(
    (oldResolution: ResolutionObject, newResolution: Resolution) => {
      onResolutionsUpdate(
        resolutions.map((res) =>
          res.thingUuid === oldResolution.thingUuid &&
          res.conflict === oldResolution.conflict
            ? { ...res, resolution: newResolution }
            : res,
        ),
      );
    },
    [onResolutionsUpdate, resolutions],
  );

  const handleSelectAll = useCallback(
    (resolution: Resolution) => {
      onResolutionsUpdate(resolutions.map((res) => ({ ...res, resolution })));
    },
    [onResolutionsUpdate, resolutions],
  );

  const catalogNameLookup = useMemo(
    () =>
      Object.fromEntries(
        catalogs.map((catalog) => [catalog.uuid, catalog.name]),
      ),
    [catalogs],
  );

  const existingThingsByUuid = useMemo(
    () =>
      Object.fromEntries(existingThings.map((thing) => [thing.uuid, thing])),
    [existingThings],
  );

  const resolutionRows = useMemo(() => {
    if (isLoading) {
      return null;
    }

    return resolutions.map((resolution, index) => {
      const { thingUuid, conflict } = resolution;
      const existingThing = existingThingsByUuid[thingUuid];
      const importThing = exportBatch.importValidation.thingsByUuid[thingUuid];
      return (
        <>
          <ResolutionRow
            exportBatch={exportBatch}
            importThing={importThing}
            existingThing={existingThing}
            existingCatalogNameLookup={catalogNameLookup}
            onResolution={(newResolution: Resolution) =>
              handleResolution(resolution, newResolution)
            }
            existingThingLookup={existingThingsByUuid}
            key={`resolution_${thingUuid}_${conflict}`}
            resolution={resolution}
          />
          {index !== resolutions.length - 1 && <RowSpacer />}
        </>
      );
    });
  }, [
    isLoading,
    existingThingsByUuid,
    exportBatch,
    catalogNameLookup,
    resolutions,
    handleResolution,
  ]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.conflictsStep}>
        <h2 className="h2">{exportBatch.name}</h2>
        <p className={styles.subheader}>Manage Conflicts</p>
        <p className="text-sm">
          There are existing files on the device with the same DUST tag. Select
          the below things that you wish to keep.
        </p>
        <div className={styles.conflictsTable}>
          <ResolutionBookend onSelectAll={handleSelectAll} />
          {resolutionRows}
          <ResolutionBookend onSelectAll={handleSelectAll} showOr={false} />
        </div>
      </div>
      <DustStepperFooter
        onCancel={onPrevious}
        onSubmit={onSuccess}
        cancelLabel="Previous"
        submitLabel="Continue"
        disabled={unresolvedConflicts}
      />
    </>
  );
}
