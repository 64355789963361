import { TextField } from '@mui/material';
import { useFormik } from 'formik';

import { addGroupSchema } from '@/common/entities/groups/schemas';
import useOrgRolesData from '@/common/entities/orgRoles/useOrgRolesData';
import { capitalizeFirst, pollUntil } from '@/common/utility';
import DustCheckboxSelect from '@/components/Library/DustCheckboxSelect';
import DustLoader from '@/components/Library/DustLoader';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

type Props = {
  onClose: () => void;
  /** Supply a function to be notified when a new group in DICE is made available */
  onStartNewGroupPoll?: (promise: Promise<boolean>, cancel: () => void) => void;
  open: boolean;
};

export default function AddUserGroup({
  onClose,
  onStartNewGroupPoll,
  open,
}: Props) {
  const { roles, isLoading } = useOrgRolesData();
  const { groupsApi } = useRequest();

  const initialValues = { name: '', description: '', roles: [] as string[] };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: addGroupSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const res = await groupsApi.createGroup(values);
      if (!res.error) {
        await groupsApi.updateGroupRoles(
          res.data.id,
          roles.filter((r) => values.roles.includes(r.id)),
          true,
        );

        if (onStartNewGroupPoll) {
          const poll = pollUntil(
            () =>
              // Workaround is needed here because we have no way
              // to get a specific group from DICE by OIDC-ID, so
              // we have to fetch a search result where it may
              // appear (slightly better than re-fetching all
              // groups)
              groupsApi
                .getDiceUserGroupsRaw({
                  search: values.name,
                  perPage: 100,
                })
                .catch(() => null),
            (v) =>
              v !== null && v.data.data.some((g) => g.oidcId === res.data.id),
          );
          onStartNewGroupPoll(
            poll.promise.then(
              (diceGroupRes) => diceGroupRes != null,
              () => false,
            ),
            poll.cancel,
          );
        }

        onClose();
      }
    },
  });

  return (
    <DustModal
      footerProps={{
        submitLabel: 'Save',
        onCancel: onClose,
        loading: isLoading || formik.isSubmitting,
        onSubmit: formik.handleSubmit,
      }}
      onClose={onClose}
      open={open}
      title="Create New User Group"
    >
      <>
        {isLoading && <DustLoader size="large" />}
        {!isLoading && (
          <form
            className="flex-col gap-1"
            style={{ maxWidth: '20rem' }}
            onSubmit={formik.handleSubmit}
          >
            <h2 className="h6">Enter details for new user group.</h2>
            <TextField
              error={!!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              label="Name"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              value={formik.values.name}
            />
            <TextField
              error={!!formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
              }
              label="Description"
              name="description"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
              multiline
              minRows={3}
              value={formik.values.description}
            />
            <DustCheckboxSelect
              entries={roles.map((role) => ({
                name: role.name
                  .split('-')
                  .map((e) => capitalizeFirst(e))
                  .join(' '),
                value: role.id,
              }))}
              error={formik.touched.roles && !!formik.errors.roles}
              helperText={
                Array.isArray(formik.errors.roles)
                  ? formik.errors.roles[0]
                  : formik.errors.roles
              }
              label="Organization Roles"
              onChange={(selected) => formik.setFieldValue('roles', selected)}
              onClear={() => formik.setFieldValue('roles', [])}
              values={formik.values.roles}
            />
          </form>
        )}
      </>
    </DustModal>
  );
}
