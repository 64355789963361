/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 */
import { useContext, useEffect, useCallback } from 'react';

import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { atom, useSetRecoilState, useRecoilValue } from 'recoil';

import { allowNavBlockAtom } from '@/components/Composed/SessionDialog/SessionDialog';

// Recoil is used to provide a global prompt component. The alternative is local state
// and returning a modal component for each page that must be inserted
export const navPromptOpenAtom = atom({
  key: 'navPromptOpen',
  default: false,
});
export const navPromptTxAtom = atom({
  key: 'navPromptTx',
  default: null,
});
export const navPromptData = atom({
  key: 'navPromptData',
  default: null,
});

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param {Function} blocker
 * @param {boolean}  [when]
 */
export function useBlocker(blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const allowNavBlock = useRecoilValue(allowNavBlockAtom);

  useEffect(() => {
    if (!(when && allowNavBlock)) return undefined;

    // navigator.block is broken in later versions of react-router-dom, with no replacement
    const unblock = navigator.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when, allowNavBlock]);
}

/**
 *
 * @param {boolean} enabled           If false the prompt is not thrown
 * @param {object}  [options]
 * @param {string}  [options.message]
 * @param {string}  [options.title]
 */
export default function useNavigationPrompt(enabled, options = {}) {
  const setOpen = useSetRecoilState(navPromptOpenAtom);
  const setTx = useSetRecoilState(navPromptTxAtom);
  const setData = useSetRecoilState(navPromptData);

  // Optionally override default navigation text
  const { message, title } = options;
  useEffect(() => {
    if (message || title) {
      setData({
        message,
        title,
      });
    }
  }, [message, title, setData]);

  const blocker = useCallback(
    (tx) => {
      setOpen(true);
      setTx(tx);
    },
    [setOpen, setTx],
  );

  useBlocker(blocker, enabled);
}
