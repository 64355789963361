import { Step, StepLabel, Stepper, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './Components.module.css';

/**
 * @typedef {object} StepperStep
 * @property {string} label
 * @property {string} fullLabel
 */

/**
 *
 * @param   {object}                      props
 * @param   {Object<string, StepperStep>} props.steps         Ordered object with steps
 * @param   {'horizontal'|'vertical'}     [props.orientation]
 * @param   {string}                      props.activeStep
 * @param   {boolean}                     [props.allActive]
 * @param   {string}                      [props.maxWidth]
 * @returns
 */
function DustStepper({
  steps,
  orientation,
  activeStep,
  allActive,
  maxWidth = '100%',
}) {
  // Select different labels depending on orientation
  const label = (step) =>
    orientation === 'vertical' ? step.fullLabel : step.label;

  const arraySteps = Object.values(steps);
  const activeIdx = Object.keys(steps).findIndex((k) => k === activeStep);

  // Handle format changes
  const format =
    orientation === 'vertical'
      ? {
          '& .MuiStepLabel-label': {
            fontSize: '14px',
          },
        }
      : {
          width: '100%',
          '& .MuiStepLabel-labelContainer': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          '& .MuiStepLabel-label': {
            fontSize: '12px',
          },
        };

  return (
    <Stepper
      activeStep={activeIdx}
      orientation={orientation}
      sx={{ maxWidth, ...format }}
    >
      {arraySteps.map((step) => (
        <Step active={allActive} key={step.label}>
          <StepLabel
            optional={step.comment ? subtext(step.comment) : undefined}
          >
            {label(step)}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

DustStepper.propTypes = {
  steps: PropTypes.object,
  orientation: PropTypes.string,
  activeStep: PropTypes.string,
  allActive: PropTypes.bool,
  maxWidth: PropTypes.string,
};

const subtext = (comment) => (
  <Tooltip title={comment}>
    <span className={styles.stepperComment}>{comment}</span>
  </Tooltip>
);

export default DustStepper;
