import { useMemo } from 'react';

import { useQuery } from 'react-query';

import { alphaSort } from '@/common/utilities/sort';
import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 24 * 60 * 60 * 1000;

export default function useOrgRolesData() {
  const { orgRolesApi, QUERY_KEYS, orgId } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    [...QUERY_KEYS.ORG_ROLES],
    () => orgRolesApi.getRoles(orgId ?? 'NO-ORG'),
    { staleTime: STALE_TIME, enabled: orgId != null },
  );

  const roles = useMemo(
    () =>
      data && data.error === false
        ? [...data.data].sort((a, b) => alphaSort(a, b, 'name'))
        : [],
    [data],
  );
  return {
    roles,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
