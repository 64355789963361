import { AxiosResponse } from 'axios';

import { oidcGroupModel } from '@/common/entities/groups/models';
import { orgIdentityModel } from '@/common/entities/orgs/models';
import { oidcUserModel, userModel } from '@/common/entities/users/models';
import { OidcUser } from '@/common/entities/users/typedefs';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import { MESSAGES } from '@/services/requests/constants';
import { DustArrayResponse } from '@/services/requests/types';
import {
  ApiReturnError,
  defaultErrorHandler,
  extractNextLinkRel,
  formatAPIError,
  formatAPIReturn,
  formatIdentityAPIReturn,
  mapResponseEnvelopeData,
  mergeOidcArrayByKey,
  mergeWithDustResponse,
} from '@/services/utility';

const IDENTITY_URL = import.meta.env.VITE_USER_AUTHORITY;

export type UserDetails = {
  sub?: string;
  email: string;
  firstName: string;
  lastName: string;
  addUserGroups: string[];
  /** Formerly user status, defaults to true */
  enabled?: boolean;
  password?: string;
};

/** Map fields for submission to our Identity API and remove undefined */
const mapUserDetailNames = (details: Partial<UserDetails>) => ({
  enabled: details?.enabled,
  email: details?.email,
  given_name: details?.firstName,
  family_name: details?.lastName,
  password: details?.password,
});

/** Factory function for producing the users API */
export default class UsersApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
    private currentUserId: string | null,
  ) {
    this.orgId = this.apiService.orgId;
  }

  private orgId: string | null | undefined;

  /** Get the current active user */
  async getCurrent() {
    if (!this.currentUserId) {
      throw new Error(
        'Current user id is unknown, only available after authentication has finished.',
      );
    }
    const identityPromise = this.apiService.request({
      url: `${IDENTITY_URL}/api/users/${this.currentUserId}`,
      method: 'GET',
    });

    const dustPromise = this.apiService.request({
      url: `/api/users/${this.currentUserId}?idType=oidc`,
      method: 'GET',
    });

    const results = await Promise.all([identityPromise, dustPromise]).catch(
      (err) => formatAPIError(err, MESSAGES.COMMON.GENERIC),
    );

    if ('error' in results) return results;

    const [identityResponse, dustResponse] = results;

    return formatAPIReturn(
      mergeWithDustResponse(dustResponse, identityResponse),
      userModel,
    );
  }

  /** Get user by UUID */
  async getUser(userId: string) {
    return this.apiService
      .request({
        url: `/api/users/${userId}?idType=oidc`,
        method: 'GET',
      })
      .then(async (res) => {
        const identityRes = await this.apiService.request({
          url: `${IDENTITY_URL}/api/users/${res.data.data.sub}`,
        });

        return formatAPIReturn(
          mergeWithDustResponse(res, identityRes),
          userModel,
        );
      })
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.GENERIC));
  }

  async getUserOrgs(sub: string) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/users/${sub}/organizations`,
        method: 'GET',
      })
      .then((res: AxiosResponse<unknown[]>) =>
        formatIdentityAPIReturn(res, orgIdentityModel),
      )
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.GENERIC));
  }

  /** Get a list of all groups a user is in */
  async getUserGroups(sub: string) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/users/${sub}/organizations/${this.orgId}/groups`,
        method: 'GET',
      })
      .then((res: AxiosResponse<unknown[]>) =>
        formatIdentityAPIReturn(res, oidcGroupModel),
      )
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.GENERIC));
  }

  async getUsersInGroup(groupOidcId: string) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.orgId}/groups/${groupOidcId}/users`,
        method: 'GET',
      })
      .then((res: AxiosResponse<unknown[]>) =>
        formatIdentityAPIReturn(res, oidcUserModel),
      )
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.GENERIC));
  }

  /** Get all users from the identity api recursively */
  private async getOidcUsers(
    url = `${IDENTITY_URL}/api/organizations/${this.orgId}/users`,
    dataArray: OidcUser[] = [],
  ): Promise<OidcUser[] | ApiReturnError<any>> {
    return this.apiService
      .request({
        url,
        method: 'GET',
      })
      .then(async (res) => {
        const fetchedResults = [...dataArray, ...res.data];
        const nextUrl = extractNextLinkRel(res.headers.link);

        if (nextUrl) {
          return this.getOidcUsers(nextUrl, fetchedResults as OidcUser[]);
        }

        return fetchedResults;
      })
      .catch((err) => formatAPIError(err));
  }

  /** Get users list for admin users screen */
  async getUsers() {
    const identityResult = await this.getOidcUsers();

    if (!Array.isArray(identityResult)) return identityResult;
    /** NOTE: /api/users returns all data regardless as pagination */
    return this.apiService
      .request({
        url: '/api/users',
        method: 'GET',
      })
      .then((res: DustArrayResponse<any>) =>
        formatAPIReturn(
          mapResponseEnvelopeData(res, (resData) =>
            mergeOidcArrayByKey(identityResult, resData, 'sub', 'sub').filter(
              (v) => !v.email.includes('@noreply'), // Filter out special admin user
            ),
          ),
          userModel,
        ),
      )
      .catch(() => ({ error: MESSAGES.COMMON.GENERIC }));
  }

  /** Invite a user */
  async createUser(userDetails: UserDetails) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/users`,
        method: 'POST',
        data: removeNil({
          ...mapUserDetailNames(userDetails),
          requiresReset: true,
          preferred_username: userDetails.email,
        }),
      })
      .then(async (res) => {
        await this.apiService.request({
          url: `${IDENTITY_URL}/api/organizations/${this.orgId}/users`,
          method: 'POST',
          data: [{ sub: res.data.sub }],
        });
        return res;
      })
      .then(async (res) => {
        await this.apiService
          .request({
            url: `${IDENTITY_URL}/api/users/${res.data.sub}/organizations/${this.orgId}/groups`,
            method: 'POST',
            data: userDetails.addUserGroups.map((id) => ({ id })),
          })
          .catch(() => {
            this.addToast(MESSAGES.USER.GROUPS_FAILED, 'error');
          });

        return res;
      })
      .then((res) => {
        this.addToast(MESSAGES.USER.INVITED, 'success');
        return formatAPIReturn(res, oidcUserModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          this.addToast(
            MESSAGES.USER.INVITE_ERROR(err.response?.data?.detail),
            'error',
          );
        }),
      )
      .finally(() => {
        this.invalidate.users();
      });
  }

  /** Update a user.
   *
   * NOTE: If you update yourself as the user logged in, it may be a
   * good idea to perform a silent auth signin to fetch your new token
   * information.
   */
  async updateUser(userDetails: Partial<UserDetails> & { sub: string }) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/users/${userDetails.sub}`,
        method: 'PATCH',
        data: removeNil(mapUserDetailNames(userDetails)),
      })
      .then(async (res) => {
        this.addToast(MESSAGES.USER.UPDATED, 'success');

        // If groups are provided try edit user groups and throw separate error on failure
        return userDetails.addUserGroups
          ? this.apiService
              .request({
                url: `${IDENTITY_URL}/api/users/${res.data.sub}/organizations/${this.orgId}/groups`,
                method: 'PUT',
                data: userDetails.addUserGroups.map((id) => ({ id })),
              })
              .then(() =>
                // Don't show 2nd toast on user groups success
                formatIdentityAPIReturn(res, oidcUserModel),
              )
              .catch((err) => {
                this.addToast(MESSAGES.USER.GROUPS_FAILED, 'error');
                return formatAPIError(err, MESSAGES.USER.GROUPS_FAILED);
              })
          : formatIdentityAPIReturn(res, oidcUserModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          this.addToast(
            MESSAGES.USER.UPDATE_ERROR(err.response?.data?.detail),
            'error',
          );
        }),
      )
      .finally(() => {
        this.invalidate.users();
        this.invalidate.userGroupList(userDetails.sub);
      });
  }

  async resetUserPassword(userSubId: string) {
    await this.apiService
      .request({
        url: `${IDENTITY_URL}/api/users/${userSubId}`,
        method: 'PATCH',
        data: { requiresReset: true },
      })
      .then((res) => {
        this.addToast(MESSAGES.USER.PASSWORD_RESET_SUCCESS, 'success');

        return formatIdentityAPIReturn(res, oidcUserModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          this.addToast(
            MESSAGES.USER.PASSWORD_RESET_ERROR(err.response?.data?.detail),
            'error',
          );
        }),
      )
      .finally(() => {
        this.invalidate.users();
        this.invalidate.userGroupList(userSubId);
      });
  }

  /** Updates arbitrary settings into the user, retrievable from the User object
   * from other routes */
  async updateWebSettings(
    userUuid: string,
    settings: { [key: string]: unknown },
  ) {
    return this.apiService
      .request({
        url: `/api/users/${userUuid}/web-settings`,
        method: 'PUT',
        data: settings,
      })
      .then((res) => formatAPIReturn(res, userModel))
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          this.addToast(
            MESSAGES.USER.UPDATE_ERROR(err.response?.data?.message),
            'error',
          );
        }),
      )
      .finally(() => this.invalidate.user(userUuid));
  }
}
