import { BaseModelOf } from '../baseModel';
import { AuditField } from '../core-types';

export const CONFLICTS = {
  NOT_CHECKED_OUT: 'NOT_CHECKED_OUT',
  DUPLICATE_DUST_IN_CATALOG: 'DUPLICATE_DUST_IN_CATALOG',
  RELATIONSHIP_CONFLICT: 'RELATIONSHIP_CONFLICT',
};

export type Conflict = keyof typeof CONFLICTS;

export type ExportBatchPlaceholder = {
  name: string;
  description: string;
  thingCount: number;
  uuid?: string;
};

export type ApiCheckInConflict = {
  thingUuid: string;
  importProblems: Conflict[];
};

export type ApiImportValidation = {
  catalogs: Catalog[];
  things: ApiThing[];
  validationStatuses: ApiCheckInConflict[];
};

export type ImportValidation = {
  catalogs: Catalog[];
  things: Thing[];
  validationStatuses: ApiCheckInConflict[];
  thingsByUuid: { [k: string]: Thing };
  catalogsByUuid: { [k: string]: Catalog };
  validationStatusesByUuid: { [k: string]: Conflict[] };
};

export type ApiExportBatch = {
  uuid: string;
  createdAt: string;
  createdBy: AuditField;
  name: string;
  description: string;
  status: string;
  thingCount: number;
  downloadUrl: string;
  importValidation: ApiImportValidation;
};

export type ExportBatch = Omit<
  BaseModelOf<ApiExportBatch>,
  'importValidation'
> & {
  downloadFileName: string;
  placeholder: ExportBatchPlaceholder;
  schema_errors?: any;
  hasConflicts: boolean;
  importValidation: ImportValidation;
};

export type CheckInConflict = BaseModelOf<ApiCheckInConflict>;
export type CheckInResult = CheckInConflict[];

export const RESOLUTIONS = {
  USE_IMPORTED_THING: 'USE_IMPORTED_THING',
  USE_SERVER_THING: 'USE_SERVER_THING',
  NO_RESOLUTION: 'NO_RESOLUTION',
} as const;

export type Resolution = (typeof RESOLUTIONS)[keyof typeof RESOLUTIONS];

export type ResolutionObject = {
  thingUuid: string;
  conflict: Conflict;
  resolution: Resolution;
};
