import {
  ImageOutlined as BackgroundIcon,
  ArrowUpwardOutlined as EnhanceHeatMapIcon,
  BlurCircular as HeatMapIcon,
  DiamondOutlined as DiamondIcon,
} from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';

import DustLoader from '@/components/Library/DustLoader';

import styles from './AntiTamperSidebarAction.module.css';

export const SIDEBAR_ACTIONS = {
  MISSING_MARKER: 'missing_marker',
  MATCHING_MARKER: 'matching_marker',
  MODIFIED_MARKER: 'modified_marker',
  BACKGROUND: 'background',
};

type AntiTamperSideBarActionProps = {
  actionType: ValueOf<typeof SIDEBAR_ACTIONS>;
  percentage?: string;
  label: string;
  startIcon?: React.ReactNode;
  isVerifiedSession: boolean;
  isLoading?: boolean;
  layerAction: () => void;
  isLayerVisible: boolean;
  isHeatMapActive?: boolean;
  heatMapAction?: () => void;
  isLoaded: boolean;
  enhanceHeatMapState?: boolean;
  handleEnhanceHeatMapState?: () => void;
};

export default function AntiTamperSideBarAction({
  actionType,
  percentage,
  label,
  startIcon,
  isVerifiedSession,
  isLoading,
  layerAction,
  isLayerVisible,
  isHeatMapActive,
  heatMapAction,
  isLoaded,
  enhanceHeatMapState,
  handleEnhanceHeatMapState,
}: AntiTamperSideBarActionProps) {
  return (
    <Box component="div" className={styles.root}>
      <Box component="div" className={styles.actionIcon}>
        {startIcon}
      </Box>
      <Typography
        className={`${styles.actionLabel} ${
          isVerifiedSession && styles.active
        }`}
        sx={{
          fontSize: '0.7rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {label}
        {actionType !== SIDEBAR_ACTIONS.MODIFIED_MARKER && (
          <span>(unmatched)</span>
        )}
      </Typography>
      {isLoading && <DustLoader size="icon" />}
      {isVerifiedSession && isLoaded && (
        <>
          <Typography
            className={`${styles.actionPercentage} ${
              isVerifiedSession && styles.active
            }`}
            sx={{
              fontSize: '0.7rem',
            }}
          >
            {percentage}
          </Typography>
          {actionType === SIDEBAR_ACTIONS.BACKGROUND && (
            <Box className={styles.action}>
              <Tooltip
                title={
                  isLayerVisible ? 'Turn off background' : 'Turn on background'
                }
              >
                <BackgroundIcon
                  sx={{
                    color: isLayerVisible ? 'inherit' : 'var(--medium-grey)',
                  }}
                  onClick={layerAction}
                  fontSize="small"
                />
              </Tooltip>
            </Box>
          )}
          {actionType !== SIDEBAR_ACTIONS.BACKGROUND && (
            <Box className={styles.action}>
              <Tooltip
                title={isLayerVisible ? 'Turn off markers' : 'Turn on markers'}
              >
                <DiamondIcon
                  sx={{
                    color: isLayerVisible ? 'inherit' : 'var(--medium-grey)',
                  }}
                  onClick={layerAction}
                  fontSize="small"
                />
              </Tooltip>
            </Box>
          )}
          <Box className={styles.action}>
            {actionType !== SIDEBAR_ACTIONS.MODIFIED_MARKER && (
              <Tooltip
                title={isHeatMapActive ? 'Turn off heatmap' : 'Turn on heatmap'}
              >
                <HeatMapIcon
                  sx={{
                    color: isHeatMapActive ? 'inherit' : 'var(--medium-grey)',
                  }}
                  onClick={heatMapAction}
                  fontSize="small"
                />
              </Tooltip>
            )}
          </Box>
          {actionType === SIDEBAR_ACTIONS.BACKGROUND && (
            <Box className={styles.enhanced}>
              {isHeatMapActive && (
                <Tooltip
                  title={
                    enhanceHeatMapState
                      ? 'Turn off heatmap enhancement'
                      : 'Enhance heatmap for greater visibility'
                  }
                >
                  <EnhanceHeatMapIcon
                    sx={{
                      color: enhanceHeatMapState
                        ? 'var(--white)'
                        : 'var(--medium-grey)',
                    }}
                    className={`${styles.enhancedAction} ${
                      enhanceHeatMapState && styles.enhancedActionActive
                    } ${enhanceHeatMapState && styles.enhancedActionDisabled}`}
                    aria-label="Tamper Analysis"
                    onClick={handleEnhanceHeatMapState}
                  />
                </Tooltip>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
