import {
  useState,
  useEffect,
  useRef,
  useMemo,
  FocusEventHandler,
  KeyboardEvent,
  CSSProperties,
  MutableRefObject,
} from 'react';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import SearchIcon from '@mui/icons-material/Search';
import { OutlinedInput } from '@mui/material';
import { nanoid } from 'nanoid';

import {
  getFieldQuery,
  getRenderList,
  executeFieldReplace,
} from '@/common/entities/thingTypes/utility';

import styles from './Components.module.css';
import useOnClickOutside from './Hooks/useOnClickOutside';
import { combineClass } from './utility';

/** Boolean to filter only search matches */
const isSearchMatch = (word: string, partial: string) =>
  word.toLowerCase().startsWith(partial.toLowerCase());

/** Prevent default browser events for certain keyboard actions */
const stopKeyEvents = (evt: KeyboardEvent) => {
  if (['ArrowDown', 'ArrowUp'].includes(evt.code)) evt.preventDefault();
};

/** Conditionally focus a child input */
const focusChildInput = (
  container: MutableRefObject<HTMLDivElement | null>,
  idx: number,
) => {
  const input = container.current?.children?.[idx] as
    | HTMLInputElement
    | undefined;
  input?.focus?.();
};

type InputItemProps = {
  role: string;
  text: string;
  idx: number;
  onInput: (value: string, idx: number, start: number | null) => void;
  onKeydown: (evt: KeyboardEvent<HTMLInputElement>, idx: number) => void;
  onBlur: FocusEventHandler<HTMLElement>;
  disabled: boolean;
} & { [x: string]: any };

// ---------------------------------------------------------------------------
// PRIVATE ELEMENTS

// Input field Element
function InputItem({
  text,
  idx,
  onInput,
  onKeydown,
  onBlur,
  disabled,
  ...rest
}: InputItemProps) {
  return (
    <div className={styles.sizer}>
      <span className={styles.sizerMetric}>{text}</span>
      <input
        disabled={disabled}
        className={styles.sizerInput}
        onBlur={onBlur}
        onChange={(evt) =>
          onInput(evt.target.value, idx, evt.target.selectionStart)
        }
        onKeyDown={(evt) => onKeydown(evt, idx)}
        type="text"
        value={text}
        {...rest}
      />
    </div>
  );
}

type FieldItemProps = {
  text: string;
  idx: number;
  onKeydown: (evt: KeyboardEvent<HTMLElement>, idx: number) => void;
  onBlur: FocusEventHandler<HTMLElement>;
  disabled: boolean;
};

// Chip Element
function FieldItem({ text, idx, onKeydown, onBlur, disabled }: FieldItemProps) {
  return (
    <button
      disabled={disabled}
      className={styles.schemaChip}
      onBlur={onBlur}
      onKeyDown={(evt) => onKeydown(evt, idx)}
      type="button"
    >
      {text}
    </button>
  );
}

// ---------------------------------------------------------------------------
// MAIN ELEMENT

export type DustSchemaTextFieldProps = {
  options: string[];
  value: string;
  setValue: (e: string) => void;
  label: string;
  menuId?: string;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
};

export default function DustSchemaTextField({
  options = [],
  value,
  setValue,
  label,
  style,
  menuId = 'autocomplete-menu',
  className = '',
  disabled = false,
  ...otherProps
}: DustSchemaTextFieldProps) {
  const inputCont = useRef<HTMLDivElement | null>(null);
  const wrapper = useRef<HTMLDivElement | null>(null);
  const optionListCont = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const closeMenu = () => {
    setSearch('');
    setOpen(false);
  };

  const openMenu = () => {
    setSearch('');
    setOpen(true);
  };

  useOnClickOutside(wrapper, () => closeMenu());
  // ---------------------------------------------------------------------------
  // PARSE AND SPLIT VALUE INTO FIELDS AND TEXT
  // __NULL__ is provide to prevent replacement of empty tags when list is empty
  const FIELD_QUERY = useMemo(() => getFieldQuery(options), [options]);
  const renderStringList = getRenderList(value, FIELD_QUERY);

  // Key each entry by uid
  const renderListIds = useMemo(
    () => renderStringList.map(() => nanoid()),
    [renderStringList.length], // eslint-disable-line
  );

  // ---------------------------------------------------------------------------
  // RENDER COMPONENTS FROM PARSED AND SPLIT VALUE
  const [focusLocation, setFocusLocation] = useState<number[] | null>(null);
  const [prevFocused, setPrevFocused] = useState<number[] | null>(null);

  // Track the blur location. If we select from the option list the new value
  // is inserted at the previous blur location
  const handleBlur = (idx: number, selectionStart: number) => {
    setPrevFocused([idx, selectionStart]);
  };

  // If the whole input is blurred we default to inserting at the end of the value
  const handleBlurAll = () => {
    if (wrapper.current && !wrapper.current.matches(':focus-within')) {
      setPrevFocused([
        renderStringList.length - 1,
        renderStringList[renderStringList.length - 1].length,
      ]);
    }
  };

  // Process an input value (including checking for template string starts)
  const handleInput = (val: string, idx: number, pos: number | null) => {
    if (typeof pos !== 'number') return;

    // Move focus after a field has been completed
    if (FIELD_QUERY.test(val)) setFocusLocation([idx + 2, 0]);

    // Open and close menu on brackets
    if (val[pos - 1] === '<') openMenu();
    if (val[pos - 1] === '>') closeMenu();

    // If typing in an open bracket update search
    const searchPartials = val.slice(0, pos).split('<');
    if (searchPartials.length > 1) {
      const searchString = searchPartials[searchPartials.length - 1];
      setSearch(searchString.includes('>') ? '' : searchString);
    }

    const temp = [...renderStringList];
    temp[idx] = val;
    setValue(temp.join(''));
  };

  // Handle when an autocomplete option is selected
  // Requirements:
  // Handle insert of same field when end of title is field
  // Handle insert with multiple template partials
  const handleSelectOption = (option: string) => {
    const list = [...renderStringList];

    const [idx, pos] = prevFocused ?? [list.length - 1, 0];

    const newString = executeFieldReplace(option, list, idx, pos);

    setValue(newString);
    setFocusLocation([idx + 2, 0]);
    closeMenu();
  };

  // Manage keyboard navigation inside inputs
  const handleKeyTraverse = (
    evt: KeyboardEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const list = [...renderStringList];

    const pos: number | null = (evt.target as HTMLInputElement).selectionStart;
    if (typeof pos !== 'number') return;

    stopKeyEvents(evt);

    // CLose menu when removing an opening template bracket
    if (evt.code === 'Backspace' && list[idx][pos - 1] === '<') {
      closeMenu();
    }

    // Allow opening of menu from keyboard events
    if (['ArrowDown', 'Tab'].includes(evt.code) && open) {
      focusChildInput(optionListCont, 0);
    }
    if (evt.code === 'ArrowDown' && !open) {
      setOpen(true);
    }

    // Option to delete chip when backspacing or delete is hit at start/end
    if (
      evt.code === 'Delete' &&
      idx < list.length - 2 &&
      pos === list[idx].length
    ) {
      list[idx + 1] = '';
      setValue(list.join(''));

      setFocusLocation([idx, pos]);
    }

    if (evt.code === 'Backspace' && idx > 0 && list.length > 1 && pos === 0) {
      list[idx - 1] = '';
      setValue(list.join(''));

      const elToFocus = Math.max(0, idx - 2);
      setFocusLocation([elToFocus, list[elToFocus].length]);
    }
    if (evt.code === 'ArrowLeft' && idx > 0 && pos === 0) {
      setFocusLocation([idx - 1, 0]);
    }
    if (
      evt.code === 'ArrowRight' &&
      idx < list.length - 2 &&
      pos === list[idx].length
    ) {
      setFocusLocation([idx + 1, 0]);
    }
  };

  // Manage keyboard navigation when a chip is focuses
  const handleChipTraverse = (evt: KeyboardEvent, idx: number) => {
    const list = [...renderStringList];

    stopKeyEvents(evt);

    // Delete chip on backspace/delete
    if (evt.code === 'Backspace' || evt.code === 'Delete') {
      list[idx] = '';
      setValue(list.join(''));

      const elToFocus = Math.max(0, idx - 1);
      setFocusLocation([elToFocus, list[elToFocus].length]);
    }
    // Traverse with arrows
    if (evt.code === 'ArrowLeft') {
      setFocusLocation([idx - 1, list[idx - 1]?.length ?? 0]);
    }
    if (evt.code === 'ArrowRight') {
      setFocusLocation([idx + 1, 0]);
    }
  };

  const handleListTraverse = (evt: KeyboardEvent, idx: number) => {
    stopKeyEvents(evt);

    if (evt.code === 'ArrowDown' && idx < options.length - 1) {
      focusChildInput(optionListCont, idx + 1);
    }
    if (evt.code === 'ArrowUp' && idx > 0) {
      focusChildInput(optionListCont, idx - 1);
    }
  };

  const handleEscMenu = (evt: KeyboardEvent) => {
    if (evt.code === 'Escape') closeMenu();
  };

  // When a focus request is submitted we must first render the new combination of
  // input and chips. Then we focus in the newly rendered combination
  useEffect(() => {
    if (!focusLocation) return;

    const [idx, pos] = focusLocation;
    // Get the input OR the chip to move focus to
    const input =
      inputCont.current?.children[idx].children[1] ??
      inputCont.current?.children[idx];
    if (input && input instanceof HTMLElement) {
      input.focus();
      if (input instanceof HTMLInputElement) {
        input.setSelectionRange(pos, pos);
      }
    }

    setFocusLocation(null);
  }, [focusLocation]);

  // ---------------------------------------------------------------------------
  // Render a combination of inputs and field chips
  const ARIA_MENU_ID = `${menuId}-menu`;

  const renderList = renderStringList.map((entry, idx) =>
    idx % 2 === 0 ? (
      <InputItem
        disabled={disabled}
        aria-autocomplete="list"
        aria-controls={ARIA_MENU_ID}
        aria-expanded={open}
        idx={idx}
        key={renderListIds[idx]}
        onBlur={(evt) => handleBlur(idx, (evt.target as any).selectionStart)}
        onInput={handleInput}
        onKeydown={handleKeyTraverse}
        role="combobox"
        text={entry}
      />
    ) : (
      <FieldItem
        idx={idx}
        disabled={disabled}
        key={renderListIds[idx]}
        onBlur={() => handleBlur(idx + 1, 0)}
        onKeydown={handleChipTraverse}
        text={entry.slice(1, -1)}
      />
    ),
  );

  return (
    // This is a complex interaction element that handles formatting and UI that responds to keyboard events, uncertain how to best handle aria attributes for this rule.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={combineClass(styles.schemaCont, className)}
      style={style}
      onBlur={handleBlurAll}
      onKeyDown={handleEscMenu}
      ref={wrapper}
    >
      <label className={styles.schemaInputLabel} htmlFor={menuId}>
        {label}
      </label>
      <div className={styles.schemaInput} id={menuId} ref={inputCont}>
        {renderList}
      </div>
      {!disabled && (
        <button
          aria-controls={ARIA_MENU_ID}
          aria-expanded={open}
          aria-label="Field Options"
          className={styles.schemaMenuButton}
          onClick={() => setOpen(!open)}
          type="button"
        >
          <PlaylistAddIcon />
        </button>
      )}
      <div
        className={combineClass(
          styles.schemaMenu,
          !open && styles.schemaMenuClosed,
        )}
      >
        <OutlinedInput
          disabled={options.length === 0}
          onChange={(evt) => setSearch(evt.target.value)}
          size="small"
          startAdornment={<SearchIcon style={{ marginRight: '5px' }} />}
          value={search}
          fullWidth
          {...otherProps}
        />
        <div
          aria-label="Field options"
          id={ARIA_MENU_ID}
          ref={optionListCont}
          role="listbox"
        >
          {options
            .filter((option) => isSearchMatch(option, search))
            .map((option, idx) => (
              <button
                className={styles.schemaMenuOption}
                key={option}
                onClick={() => handleSelectOption(option)}
                onKeyDown={(evt) => handleListTraverse(evt, idx)}
                tabIndex={0}
                type="button"
              >
                {option}
              </button>
            ))}
          {options.length === 0 && (
            <span className={styles.schemaNoOptions}>
              <em>No data fields available for reference</em>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
