import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { ROUTES } from '@/common/routes';
import CatalogSelector from '@/components/Composed/CatalogSelector/CatalogSelector';
import { selectedCatalogAtom } from '@/state/atoms/catalog';

export default function Catalogs() {
  const setCatalog = useSetRecoilState(selectedCatalogAtom);
  const navigate = useNavigate();

  const onSelect = (catalog: Catalog) => {
    setCatalog(catalog);
    navigate(ROUTES.THINGS(catalog.uuid));
  };

  return <CatalogSelector onSelect={onSelect} showAddButton />;
}
