import { Navigate, useParams } from 'react-router-dom';

import { ROUTES } from '@/common/routes';

function ThingRedirect() {
  const { thingId = '' } = useParams();
  return <Navigate to={ROUTES.THING(thingId)} replace />;
}

export default ThingRedirect;
