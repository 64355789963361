import { useState, useCallback, useRef } from 'react';

import { Link as MuiLink } from '@mui/material';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { object, string } from 'yup';

import useToasts from '@/common/hooks/useToasts';
import { ROUTES } from '@/common/routes';
// import DustLoadingButton from '@/components/Library/DustLoadingButton';
import CatalogListSelector from '@/components/Composed/CatalogListSelector/CatalogListSelector';
import DustModal from '@/components/Library/DustModal';
import { MoveConflictError } from '@/services/requests/apis/ThingsApi';
import { MESSAGES } from '@/services/requests/constants';
import useRequest from '@/services/requests/useRequest';
import { ApiReturnError } from '@/services/utility';
import { ToastMessage } from '@/state/atoms/toasts';

type Props = {
  open: boolean;
  onClose: () => void;
  thing: Thing;
  catalog: Catalog;
};

type Values = { name: string };

const formSchema = object({
  name: string().required('Catalog name is a required field.'),
});

export default function ThingChangeCatalog({
  open,
  onClose,
  thing,
  catalog,
}: Props) {
  const { addToast } = useToasts();
  const { catalogsApi, thingsApi } = useRequest();
  // NOTE: As of DICE3-1207, relationship moving is temporarily disabled -
  // leaving logic in here for when/if we turn it back on.
  const [acceptRelationshipWarning, setAcceptRelationshipWarning] = useState(
    !thing.hasRelationships,
  );

  // Warn differently depending on if we are moving a root thing or a child thing
  const { root } = thing.relationships;
  const relationshipWarningJsx =
    thing.hasParent && root ? (
      <p>
        To move <b>{thing.title}</b> and all of its children to a different
        catalog, we need to move the entire relationship tree under{' '}
        <MuiLink component={Link} to={ROUTES.THING(root.uuid)}>
          <b>{root.title}</b>
        </MuiLink>{' '}
        to the new catalog.
      </p>
    ) : (
      <p>
        <b>{thing.title}</b> and all of its children will be moved to the new
        catalog.{' '}
      </p>
    );

  // --------------------------------------------------------------------------
  // Handle catalog creation and selection

  const [selectedCatalog, setSelectedCatalog] = useState<Catalog | null>(null);

  const formikRef = useRef<ReturnType<typeof useFormik<Values>>>();

  const handleCreateCatalog = useCallback(
    async ({ name }: Values) => {
      if (!formikRef.current) throw new Error('Formik ref was never set');
      const res = await catalogsApi.create({ name });

      if (!res.error) {
        formikRef.current.resetForm();
        setSelectedCatalog(res.data);
      } else {
        formikRef.current.setFieldError('name', res.data?.message);
      }
    },
    [catalogsApi],
  );

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: formSchema,
    validateOnChange: true,
    onSubmit: handleCreateCatalog,
  });
  // For submit callback
  formikRef.current = formik;

  // --------------------------------------------------------------------------
  // Handle thing move
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [catalogError, setCatalogError] = useState('');

  const handleMoveError = (
    error: ApiReturnError<{ message: string; conflict?: MoveConflictError }>,
  ) => {
    let message: ToastMessage = '';
    if (error.data?.conflict) {
      try {
        const [originUuid, originName] = Object.entries(
          error.data.conflict.originSearch,
        )[0];
        const [conflictUuid, conflictName] = Object.entries(
          error.data.conflict.destinationConflicts,
        )[0];
        message = (
          <>
            {thing.title} cannot be moved.{' '}
            <MuiLink
              component={Link}
              to={ROUTES.THING(originUuid)}
              sx={{
                color: 'white',
                textDecoration: 'underline',
              }}
            >
              {originName}
            </MuiLink>{' '}
            from <b>{catalog.name}</b> and{' '}
            <MuiLink
              component={Link}
              to={ROUTES.THING(conflictUuid)}
              sx={{
                color: 'white',
                textDecoration: 'underline',
              }}
            >
              {conflictName}
            </MuiLink>{' '}
            from <b>{selectedCatalog?.name}</b> use the same Dust tag. Please{' '}
            resolve the conflict and try again.
          </>
        );
      } catch (e) {
        // Couldn't parse JSON in the error or necessary fields weren't there.
      } finally {
        message ||= MESSAGES.THING_MOVE.DUST_CONFLICT;
      }
    }
    message ||= error?.data?.message || MESSAGES.THING_MOVE.FAILURE;
    addToast(message, 'error');
  };

  async function handleSubmit() {
    // Set accept and move to the next step
    if (!acceptRelationshipWarning) {
      setAcceptRelationshipWarning(true);
      return;
    }

    const catalogUuid = selectedCatalog?.uuid;

    if (!catalogUuid) {
      setCatalogError(
        'Please select a catalog to move the thing and its children.',
      );
      return;
    }
    setCatalogError('');

    setIsSubmitting(true);
    const res = await thingsApi.moveThing({
      thingUuid:
        thing.hasRoot && thing.relationships.root?.uuid
          ? thing.relationships.root.uuid
          : thing.uuid,
      catalogUuid: selectedCatalog?.uuid,
      oldCatalogUuid: thing.catalogUuid,
      onError: handleMoveError,
    });
    setIsSubmitting(false);

    if (!res.error) onClose();
  }

  const catalogListFilter = useCallback(
    (c: Catalog) =>
      c.currentExtractionConfig?.uuid === catalog.currentExtractionConfig?.uuid,
    [catalog],
  );
  return (
    <DustModal
      title="Change Catalog"
      open={open}
      onClose={onClose}
      footerProps={{
        onCancel: onClose,
        onSubmit: handleSubmit,
        loading: isSubmitting,
        submitLabel: !acceptRelationshipWarning ? 'Accept' : 'Change Catalog',
      }}
    >
      {!acceptRelationshipWarning ? (
        relationshipWarningJsx
      ) : (
        <div className="flex-col gap-1">
          <div>Select a catalog to move the thing to:</div>
          <CatalogListSelector
            filterIds={[thing.catalogUuid]}
            error={catalogError}
            currentCatalog={selectedCatalog}
            onSelectCatalog={setSelectedCatalog}
            filter={catalogListFilter}
          />
          {/* <div>Or create a new catalog:</div>
          <form className="flex-row gap-1">
            <TextField
              error={!!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              label="Catalog Name"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              size="small"
              sx={{ flex: 1 }}
            />
            <DustLoadingButton
              onClick={() => formik.handleSubmit()}
              loading={formik.isSubmitting}
            >
              Create
            </DustLoadingButton>
          </form> */}
        </div>
      )}
    </DustModal>
  );
}
