import { Tooltip } from '@mui/material';

import styles from './OrgsTable.module.css';

type Props = {
  name: string;
  uuid: string;
};

export default function OrgDetailCell({ name, uuid }: Props) {
  return (
    <div className={styles.accountDetailCell}>
      <Tooltip title={name}>
        {/* We use an inline style declaration here because there are 
        styles from the table component that will override a class 
        https://github.com/mui/mui-x/issues/3587 */}
        <span className={styles.nameText}> {name} </span>
      </Tooltip>
      <span className={styles.uuidText}>{uuid}</span>
    </div>
  );
}
