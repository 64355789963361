import { useCallback, useMemo, useState } from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';

import { catalogSchema } from '@/common/entities/catalogs/schemas';
import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import useToasts from '@/common/hooks/useToasts';
import { provideFallbackImage } from '@/common/utilities/images';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

import styles from './Catalog.module.css';

type Props = {
  catalog?: Catalog;
  onClose: () => void;
  open: boolean;
};

type FormValues = {
  name: string;
  file: File | null;
  isMobile?: boolean;
};

const sxs = {
  remove: {
    color: 'var(--bg-light)',
    backgroundColor: 'var(--negative-lighten-20)',
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    width: '1.5rem',
    height: '1.5rem',
    '& svg': {
      width: '1.25rem',
      height: '1.25rem',
    },
    '&:hover': {
      backgroundColor: 'var(--light-grey)',
    },
  },
};

/**
 * Add or Update Catalog modal
 */
export default function CatalogSettings({
  catalog,
  onClose: hideModal,
  open,
}: Props) {
  const { addToast } = useToasts();
  const { org } = useOrgData();

  const isAddMode = useMemo(() => !catalog, [catalog]);

  const { catalogsApi, mediaApi } = useRequest();

  const [hasImage, setHasImage] = useState(!!catalog?.imageUri);
  const [existingImageUuid, setExistingImageUuid] = useState<string | null>(
    catalog?.imageMediaUuid ?? null,
  );

  const initialValues: FormValues = useMemo(
    () => ({
      name: catalog?.name ?? '',
      file: null,
      isMobile: catalog?.isMobile,
    }),
    [catalog],
  );

  async function onSubmit(
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) {
    let imageMediaUuid = isAddMode ? null : existingImageUuid;
    if (values.file) {
      const mediaResult = await mediaApi.create(values.file);
      if (mediaResult.error || !mediaResult.data?.uuid) {
        return; // Early abort if failed to save image, defer to toast
      }

      imageMediaUuid = mediaResult.data.uuid;
    }

    const submitData = {
      name: values.name,
      // imageMediaUuid can't be null in create, and can't be undefined
      // when editing and removing an image
      imageMediaUuid: imageMediaUuid ?? (isAddMode ? undefined : null),
      isMobile: values.isMobile,
    };
    const catalogResult = !catalog
      ? await catalogsApi.create(submitData)
      : await catalogsApi.update({ uuid: catalog.uuid, ...submitData });

    if (!catalogResult.error) {
      hideModal();
      helpers.resetForm({ values: initialValues });
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: catalogSchema,
    validateOnChange: true,
    onSubmit,
  });

  const closeModal = useCallback(() => {
    hideModal();
    // Guard against use-before-define
    if (formik) formik.resetForm({ values: initialValues });
  }, [formik, hideModal, initialValues]);

  const removeImage = useCallback(() => {
    setHasImage(false);
    setExistingImageUuid(null);
  }, []);

  const handleReject = useCallback(
    (errors: string[]) => {
      errors.forEach((error) => addToast(error, 'error'));
    },
    [addToast],
  );

  return (
    <DustModal
      footerProps={{
        onCancel: isAddMode ? hideModal : closeModal,
        onSubmit: formik.handleSubmit,
        submitLabel: isAddMode ? 'Create' : 'Save',
        loading: formik.isSubmitting,
      }}
      onClose={isAddMode ? hideModal : closeModal}
      open={open}
      title={isAddMode ? 'Create a new Catalog' : 'Catalog Settings'}
    >
      <form onSubmit={formik.handleSubmit}>
        <h2 className="h6" style={{ marginBottom: '1rem' }}>
          Enter catalog details.
        </h2>
        <TextField
          autoFocus
          error={!!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          label="Name"
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          sx={{ width: '16rem' }}
          value={formik.values.name}
        />

        {catalog && hasImage && (
          <div className={styles.catalogSettingsCoverBox}>
            <IconButton
              aria-label="Remove"
              onClick={removeImage}
              sx={sxs.remove}
            >
              <CloseIcon />
            </IconButton>
            <img
              alt={catalog.name}
              className={styles.catalogSettingsCoverImage}
              src={catalog.imageUri}
              onError={provideFallbackImage}
            />
          </div>
        )}

        {(isAddMode || !hasImage) && (
          <DustFileUploadField
            fileType={FILE_TYPE_OPTIONS.IMAGE}
            formik={formik}
            label="Cover Image"
            name="file"
            onReject={handleReject}
            dataTestId="newCatalogImageUpload"
          />
        )}
        {org?.isFeatureEnabled(FEATURE_FLAGS.MOBILE) && (
          <DustCheckbox
            label="Mobile Catalog"
            checked={formik.values.isMobile}
            style={{
              fontSize: 'var(--font-size-text-sm)',
            }}
            /*
          error={
            formik.touched.noSensitiveInfo ? formik.errors.noSensitiveInfo : ''
          }
          */
            onChange={(checked) => formik.setFieldValue('isMobile', checked)}
            name="isMobile"
          />
        )}
      </form>
    </DustModal>
  );
}
