import { useCallback, useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import FiberSmartRecordOutlinedIcon from '@mui/icons-material/FiberSmartRecordOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';

import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
// import useActiveUser from '@/common/entities/users/useActiveUser';
import useAdminPrivileges from '@/common/entities/users/useAdminPrivileges';
import { ROUTES } from '@/common/routes';
import FeedbackModal from '@/components/Composed/LeftNav/FeedbackModal';
import NavLink from '@/components/Composed/LeftNav/NavLink';
import DustTargetIcon from '@/components/Library/Icons/DustTargetIcon';
import { combineClass } from '@/components/Library/utility';
import { Mixpanel } from '@/mPanel';

import styles from './LeftNav.module.css';
import NavEntryMenu from './NavEntryMenu';
import NavEntryMenuItem from './NavEntryMenuItem';
import NavItemList from './NavItemList';
import NavLogo from './NavLogo';

const feedbackEnabled = Boolean(import.meta.env.VITE_ALLOW_FEEDBACK);

type Props = {
  onNavBlur?: (e: boolean) => void;
};

const leftNavOpenAtom = atom({
  key: 'left-nav-open',
  default: true,
});

export default function LeftNav({ onNavBlur }: Props) {
  const { canAccessAdminPanel, isRoot } = useAdminPrivileges();

  const { org } = useOrgData();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useRecoilState(leftNavOpenAtom);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  // Force nav small at 850px
  useEffect(() => {
    const onResize = () =>
      window.innerWidth < 850 ? setIsOpen(false) : setIsOpen(true);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [setIsOpen]);

  const handleLogoClick = useCallback(() => {
    navigate(ROUTES.CATALOGS);
  }, [navigate]);

  const handleTrackScanClick = useCallback(
    () => Mixpanel.track('Left Nav Scan'),
    [],
  );

  const handleTrackTransactionsClick = useCallback(() => {
    Mixpanel.track('View Transactions', {
      origin: 'Left Navigation',
    });
  }, []);

  const handleExpand = useCallback(
    () => setIsOpen((open) => !open),
    [setIsOpen],
  );

  // NAV Menu Line items
  const entries = useMemo(() => {
    const navItems = [
      <NavLink
        icon={<FolderOutlinedIcon />}
        isFull={isOpen}
        key="catalogs"
        name="Catalogs"
        href={ROUTES.CATALOGS}
      />,
      <NavLink
        icon={<FiberSmartRecordOutlinedIcon />}
        isFull={isOpen}
        key="things"
        name="Things"
        href={ROUTES.THINGS_ACCOUNT}
      />,
      <NavLink
        icon={<CompareArrowsOutlinedIcon />}
        isFull={isOpen}
        key="transactions"
        name="Transactions"
        href={ROUTES.TRANSACTIONS}
        onClick={handleTrackTransactionsClick}
      />,
    ];

    if (canAccessAdminPanel) {
      navItems.unshift(
        <NavEntryMenu
          icon={<SettingsOutlinedIcon />}
          isFull={isOpen}
          key="settings"
          name="Settings"
          setOpen={onNavBlur}
        >
          {isRoot && (
            <NavEntryMenuItem label="Accounts" href={ROUTES.ADMIN_ACCOUNTS} />
          )}
          {org && org.isFeatureEnabled(FEATURE_FLAGS.MESH) && (
            <NavEntryMenuItem
              label="Checkout History"
              href={ROUTES.ADMIN_CHECKOUT_HISTORY}
            />
          )}
          {org && org.isFeatureEnabled(FEATURE_FLAGS.MESH) && (
            <NavEntryMenuItem label="Check In" href={ROUTES.ADMIN_CHECKIN} />
          )}
          <NavEntryMenuItem label="Facilities" href={ROUTES.ADMIN_FACILITIES} />
          <NavEntryMenuItem
            label="Thing Types"
            href={ROUTES.ADMIN_THING_TYPES}
          />
          <NavEntryMenuItem label="Users" href={ROUTES.ADMIN_USERS} />
          <NavEntryMenuItem label="User Groups" href={ROUTES.ADMIN_GROUPS} />
        </NavEntryMenu>,
      );
    }

    if (feedbackEnabled) {
      navItems.push(
        <NavLink
          icon={<ModeCommentOutlinedIcon />}
          isFull={isOpen}
          key="feedback"
          name="Feedback"
          onClick={() => setShowFeedbackModal(true)}
        />,
      );
    }
    return navItems;
  }, [
    org,
    canAccessAdminPanel,
    isOpen,
    isRoot,
    onNavBlur,
    handleTrackTransactionsClick,
  ]);

  return (
    <>
      <div
        className={combineClass(styles.leftNav, isOpen && styles.leftNavOpen)}
      >
        {/* Top Logo */}
        <div
          className={combineClass(
            styles.logoContainer,
            isOpen && styles.logoContainerOpen,
          )}
        >
          <NavLogo isFull={isOpen} onClick={handleLogoClick} />
        </div>
        {/* Main Button List */}
        <div className={styles.navCont}>
          <NavLink
            buttonStyle
            icon={<DustTargetIcon />}
            isFull={isOpen}
            name="Scan"
            href={ROUTES.SCAN}
            onClick={handleTrackScanClick}
          />
          <NavLink
            buttonStyle
            icon={<AddIcon />}
            isFull={isOpen}
            name="Add Thing"
            href={ROUTES.THINGS_ADD}
          />
          <NavItemList isFull={isOpen} onNavBlur={onNavBlur}>
            {entries}
          </NavItemList>
        </div>
        {/* Footer  */}
        {isOpen && (
          <div
            className={combineClass('text-2xs text-contrast', styles.footer)}
          >
            &copy; {new Date().getFullYear()} DUST Identity, Inc. All Rights
            Reserved.
            <br />v{__APP_VERSION__}
          </div>
        )}
      </div>
      <FeedbackModal open={showFeedbackModal} setOpen={setShowFeedbackModal} />
      <Button
        classes={{ root: styles.collapseExpandButton }}
        onClick={handleExpand}
      >
        <div
          className={combineClass(
            styles.expandButtonIcon,
            isOpen && styles.expandedExpandButtonIcon,
          )}
        />
      </Button>
    </>
  );
}
