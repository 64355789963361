import { useMemo } from 'react';

import Remove from '@mui/icons-material/Remove';
import { IconButton, TextField } from '@mui/material';
import { FormikProps } from 'formik';

import styles from './ThingDetailsSingle.module.css';

type ThingDataFieldProps = {
  idx: number;
  onRemove: () => void;
  formik: FormikProps<AddThingForm>;
  isTyped: boolean;
  disabled: boolean;
};

export default function ThingDataField({
  idx,
  onRemove,
  formik,
  isTyped,
  disabled,
}: ThingDataFieldProps) {
  const fieldKey = isTyped ? 'typedMetadata' : 'metadata';

  const name = formik.values[fieldKey][idx]?.name;

  const value = formik.values[fieldKey][idx]?.value;

  // Order of checks matter. We must check for nested error before checking at the field level
  const nameError = useMemo(() => {
    const fieldError = formik.errors[fieldKey]?.[idx];
    const fieldTouched = formik.touched[fieldKey]?.[idx];

    if (fieldError && fieldTouched) {
      return typeof fieldError === 'string'
        ? fieldError
        : fieldError?.name ?? '';
    }

    return '';
  }, [formik, fieldKey, idx]);

  const valueError = useMemo(() => {
    const fieldError = formik.errors[fieldKey]?.[idx];
    const fieldTouched = formik.touched[fieldKey]?.[idx];

    return fieldError &&
      fieldTouched &&
      typeof fieldError === 'object' &&
      typeof fieldError.value === 'string'
      ? fieldError.value
      : '';
  }, [formik, fieldKey, idx]);

  return (
    <div className={styles.metaFieldRow}>
      {!isTyped && (
        <TextField
          disabled={isTyped || disabled}
          error={!!nameError}
          helperText={nameError}
          label="Field Name*"
          margin="normal"
          name={`${fieldKey}.${idx}.name`}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={name}
          variant="outlined"
          fullWidth
          aria-label={`${idx} Field Name`}
        />
      )}
      <TextField
        disabled={disabled}
        error={!!valueError}
        helperText={valueError}
        label={`${
          fieldKey === 'typedMetadata'
            ? formik.values[fieldKey]?.[idx]?.name
            : 'Field Value'
        }${
          fieldKey === 'typedMetadata' &&
          formik.values[fieldKey]?.[idx].required
            ? '*'
            : ''
        }`}
        margin="normal"
        name={`${fieldKey}.${idx}.value`}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        size="small"
        value={value}
        variant="outlined"
        fullWidth
        aria-label={`${idx} Field Value`}
      />
      {!isTyped && (
        <div style={{ flexGrow: 0, marginTop: '1rem' }}>
          <IconButton
            aria-label="Delete"
            disabled={disabled}
            onClick={onRemove}
            sx={{ color: 'var(--error-red)' }}
          >
            <Remove />
          </IconButton>
        </div>
      )}
    </div>
  );
}
