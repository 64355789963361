import { useMemo, useCallback, useState } from 'react';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Output from '@mui/icons-material/Output';
import { IconButton, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import useCatalogData from '@/common/entities/catalogs/useCatalogData';
import useCatalogsData from '@/common/entities/catalogs/useCatalogsData';
import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import { RESTRICTED_COLUMNS } from '@/common/entities/things/constants';
import useThingMetaFilterData from '@/common/entities/things/useThingMetaFilterData';
import useThingsData, {
  BOUND_STATE,
  CHECKED_OUT_STATE,
  SORT_OPTIONS,
  ThingFilters,
  thingSelectorSortAtom,
} from '@/common/entities/things/useThingsData';
import useThingsDisplayColumns from '@/common/entities/things/useThingsDisplayColumns';
import { TRANSACTIONS_PARAM_KEY } from '@/common/entities/transactions/useTransactionsData';
import useCheckout from '@/common/hooks/useCheckout';
import { ROUTES } from '@/common/routes';
import { useFetchMoreToFillHeightRef } from '@/common/utilities/table';
import { downloadBlob, includes } from '@/common/utility';
import ProgressModal from '@/components/Composed/ProgressModal/ProgressModal';
import DustActionButton from '@/components/Library/DustActionButton';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustThingThumbnail from '@/components/Library/DustThingThumbnail';
import {
  dustedThingColDef,
  thingTypeThingColDef,
  createdAtThingColDef,
  idThingColDef,
  titleThingColDef,
  imagesThingColDef,
  filesThingColDef,
} from '@/components/Library/sharedThingColDefs';
import { Mixpanel } from '@/mPanel';
import useRequest from '@/services/requests/useRequest';
import useSort from '@/services/useSort';

import styles from './ThingSelector.module.css';
import ThingSelectorColumnFilter from './ThingSelectorColumnFilter';
import ThingSelectorFilterChips from './ThingSelectorFilterChips';
import ThingSelectorFilters from './ThingSelectorFilters';

type Props = {
  catalogUuid?: string;
  onSelect: (e: Thing[]) => void;
  onRowClick: (e: Thing) => void;
  additionalButtonActions?: any[];
  showActionButton?: boolean;
  selected?: Thing[];
};
export default function ThingSelector({
  catalogUuid,
  onSelect,
  onRowClick,
  additionalButtonActions = [],
  showActionButton = false,
  selected = undefined,
}: Props) {
  const navigate = useNavigate();

  const { catalogs } = useCatalogsData({
    fetchAll: true,
  });

  const { tableSortProps, applyColumnSortProps, sortRequestParams } = useSort(
    thingSelectorSortAtom,
    SORT_OPTIONS,
  );

  // TODO: There seems to be an issue where the first page is fetched
  // twice (getNextPage dep doesn't affect this)
  const {
    things,
    totalItems,
    getNextPage,
    setFilter,
    resetFilter,
    resetFilters,
    filters,
    showResetFilters,
    defaultedFilters,
    isFetching,
    isSuccess,
  } = useThingsData({
    catalogUuid,
    ...sortRequestParams,
    fixedParams: {
      fields: ['children', 'parent', 'root'],
      ...sortRequestParams?.fixedParams,
    },
  });

  const { isError: catalogIsError } = useCatalogData({
    catalogUuid: catalogUuid ?? '',
    enabled: !!catalogUuid,
  });

  if (catalogIsError) throw new Error('Invalid Catalog UUID');

  const { org } = useOrgData();

  const { displayColumns, setDisplayColumns } = useThingsDisplayColumns({
    catalogUuid,
  });

  const { typedFilters } = useThingMetaFilterData({
    catalogUuid,
    enabled: isSuccess,
  });

  const handleRowClick = useCallback(
    ({ row }: { row: Thing }) => onRowClick(row),
    [onRowClick],
  );

  const tableContainerRef = useFetchMoreToFillHeightRef(
    getNextPage,
    things.length,
  );

  // Get list of all possible typed fields for column entries
  const typedFields = useMemo(
    () =>
      Object.entries(typedFilters as Record<string, { name: string }>).map(
        ([field, { name }]): [string, string] => [field, name],
      ),
    [typedFilters],
  );

  const typedFieldUuids = useMemo(
    () => Object.keys(typedFilters),
    [typedFilters],
  );

  // ---------------------------------------------------------------------------
  // TABLE SETUP

  // NOTE: Column spacing reviewed by Jordan 2022-09-13
  const columns = useMemo(() => {
    const catalogsMap = Object.fromEntries(catalogs.map((c) => [c.uuid, c]));

    const colFromTyped = ([field, name]: [string, string]) => ({
      valueGetter: ({ row }: { row: Thing }) => row.typedMetadata[field]?.value,
      field,
      headerName: name,
      minWidth: 80,
      flex: 1,
    });

    const baseColumns: GridColDef<Thing>[] = [
      idThingColDef,
      {
        field: 'thumbnail',
        minWidth: 64,
        maxWidth: 64,
        headerName: '',
        renderCell: ({ row: thing }) =>
          thing.isCheckedOut ? (
            <Output color="secondary" sx={{ margin: 'auto' }} />
          ) : (
            <DustThingThumbnail thing={thing} styleForTable />
          ),
      },
      titleThingColDef,
      dustedThingColDef,
      thingTypeThingColDef,
      {
        field: 'catalog',
        valueGetter: ({ row }) => catalogsMap[row.catalogUuid]?.name,
        headerName: 'Catalog',
        flex: 1,
        hide: !!catalogUuid,
        minWidth: 80,
      },
      createdAtThingColDef,
      // ...metadataFields.map(field => colFromMeta(field)),
      ...typedFields
        .sort((a, b) => {
          const aIndex = displayColumns.indexOf(a[0]);
          const bIndex = displayColumns.indexOf(b[0]);
          return aIndex > bIndex ? 1 : -1;
        })
        .map((entry) => colFromTyped(entry)),
      imagesThingColDef,
      filesThingColDef,
    ];
    return applyColumnSortProps(
      baseColumns.map((item) =>
        // Apply props to all columns here
        ({
          hide: !displayColumns.includes(item.field),
          ...item,
        }),
      ),
    );
  }, [
    catalogUuid,
    displayColumns,
    typedFields,
    catalogs,
    applyColumnSortProps,
  ]);

  // ---------------------------------------------------------------------------
  // Button Actions

  const { thingsApi } = useRequest();

  const { exportColumns } = useThingsDisplayColumns({ catalogUuid });
  const [showExportBlock, setShowExportBlock] = useState(false);

  const exportCSV = useCallback(
    async (exportThings: Thing[], fieldsToExport?: string[]) => {
      setShowExportBlock(true);

      const res = await thingsApi.exportThings({
        thingUuids: exportThings.map((t) => t.uuid),
        exportAllMetadata: !fieldsToExport,
        exportFields: fieldsToExport?.filter(
          (f) => !RESTRICTED_COLUMNS.includes(f),
        ),
      });

      setShowExportBlock(false);

      if (!res.error && res.data.file) {
        downloadBlob(
          res.data.file,
          fieldsToExport
            ? 'things_table_fields_export.csv'
            : 'things_all_fields_export.csv',
        );
      }
    },
    [thingsApi],
  );

  const exportFilteredCSV = useCallback(
    async (exportFilters: ThingFilters, fieldsToExport?: string[]) => {
      setShowExportBlock(true);

      const res = await thingsApi.exportThings({
        catalogUuids: exportFilters.catalogUuids,
        typedMetadataFilters: exportFilters.typedMetadataFilters,
        isBound: BOUND_STATE[exportFilters.status],
        showCheckedOut: CHECKED_OUT_STATE[exportFilters.showCheckedOut],
        metadataText: exportFilters.search,
        exportAllMetadata: !fieldsToExport,
        exportFields: fieldsToExport?.filter(
          (f) => !includes(RESTRICTED_COLUMNS, f),
        ),
      });

      setShowExportBlock(false);

      if (!res.error && res.data.file) {
        downloadBlob(
          res.data.file,
          fieldsToExport
            ? 'things_table_fields_export.csv'
            : 'things_all_fields_export.csv',
        );
      }
    },
    [thingsApi],
  );

  const { checkout } = useCheckout();

  const handleCheckOut = useCallback(() => {
    if (selected) {
      void checkout.addThings({
        things: selected,
      });
    }
  }, [checkout, selected]);

  const handleTransactionNavigation = () => {
    Mixpanel.track('View Transactions', {
      origin: catalogUuid ? 'From Catalog' : 'All Things Page',
    });
    // Path to the transactions page with ONLY the current catalogUuid as a filter
    const transactionsPath = catalogUuid
      ? `${ROUTES.TRANSACTIONS}?${TRANSACTIONS_PARAM_KEY}=${encodeURI(
          JSON.stringify({ catalogUuids: [catalogUuid] }),
        )}`
      : ROUTES.TRANSACTIONS;
    navigate(transactionsPath);
  };

  const buttonActions = useMemo(() => {
    const list: React.ComponentProps<typeof DustActionButton>['actionList'] = [
      { name: 'Selected Things', isDivider: true },
      ...additionalButtonActions,
      {
        name: 'Export All Fields',
        key: 'single-export',
        action: () => {
          if (selected) {
            void exportCSV(selected);
          }
        },
        disabled: !selected || selected.length < 1,
      },
      {
        name: 'Export Table Fields',
        key: 'single-export-table',
        action: () => {
          if (selected) {
            void exportCSV(selected, exportColumns);
          }
        },
        disabled: !selected || selected.length < 1,
      },

      { name: 'All Things', isDivider: true },
      {
        name: 'Export All Fields',
        action: () => {
          void exportFilteredCSV(filters);
        },
      },
      {
        name: 'Export Table Fields',
        action: () => {
          void exportFilteredCSV(filters, exportColumns);
        },
      },
    ];

    if (org && org.isFeatureEnabled(FEATURE_FLAGS.MESH)) {
      list.splice(3, 0, {
        name: 'Check Out Things',
        action: handleCheckOut,
        disabled: !selected || selected.length < 1,
      });
    }

    return list;
  }, [
    org,
    handleCheckOut,
    additionalButtonActions,
    exportCSV,
    exportFilteredCSV,
    exportColumns,
    filters,
    selected,
  ]);

  return (
    <>
      {/* HEADER */}
      <div className="flex-row">
        <DustSearchInput
          defaultValue={filters.search}
          setValue={(value) => setFilter('search', value)}
          aria-label="Search for a Thing"
        />
        {showActionButton && <DustActionButton actionList={buttonActions} />}
        <div className="flex-1" />
        <IconButton onClick={handleTransactionNavigation}>
          <Tooltip
            enterDelay={500}
            title={
              catalogUuid
                ? 'Show Catalog Transactions'
                : 'Show All Transactions'
            }
          >
            <CompareArrowsIcon />
          </Tooltip>
        </IconButton>
        <ThingSelectorFilters
          catalogList={catalogs}
          catalogUuid={catalogUuid}
          filterFields={displayColumns}
          filters={filters}
          resetFilters={resetFilters}
          setFilter={setFilter}
          typedFilters={typedFilters}
          showResetFilters={showResetFilters}
        />
        <ThingSelectorColumnFilter
          columns={columns}
          selected={displayColumns}
          setSelected={setDisplayColumns}
          typedFieldUuids={typedFieldUuids}
        />
      </div>
      <ThingSelectorFilterChips
        defaultedFilters={defaultedFilters}
        filters={filters}
        resetFilter={resetFilter}
        resetFilters={resetFilters}
        showResetFilters={showResetFilters}
      />
      <ProgressModal open={showExportBlock} />
      {/* CONTENT */}
      <div className="flex-1 border" ref={tableContainerRef}>
        <DustDataGrid
          altTheme
          columns={columns}
          columnVisibilityModel={Object.fromEntries(
            columns.map((col) => [col.field, !col.hide]),
          )}
          loading={isFetching}
          onRowClick={handleRowClick}
          onRowsScrollEnd={getNextPage}
          onSelectionModelChange={(items) =>
            onSelect(things.filter((thing) => items.includes(thing.uuid)))
          }
          pinnedColumns={{
            left: [
              GRID_CHECKBOX_SELECTION_COL_DEF.field,
              'thumbnail',
              'title',
              'dusted',
              'thingType',
              'catalog',
            ],
          }}
          rows={things ?? []}
          getRowId={(r) => r.uuid}
          selectionModel={selected?.map((t) => t.uuid)}
          rowCount={totalItems}
          checkboxSelection
          isRowSelectable={(params: GridRowParams) => !params.row.isCheckedOut}
          getRowClassName={(params: GridRowParams<Thing>) =>
            params.row.isCheckedOut ? styles.disabledRow : ''
          }
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableSortProps}
        />
      </div>
    </>
  );
}
