import { useState } from 'react';

import { Collapse } from '@mui/material';
import PropTypes from 'prop-types';

import DustLinkButton from '@/components/Library/DustLinkButton';

/**
 * Almost generic, but has styles specific to the transactions table
 *
 * @param {object} props
 * @param {string} props.value
 */
export function UserAgentCollapsableCell({ value }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCollapseToggle = (e) => {
    e.stopPropagation();
    setIsExpanded((v) => !v);
  };

  return (
    <>
      <Collapse
        collapsedSize="1rem"
        in={isExpanded}
        onClick={handleCollapseToggle}
        sx={{
          cursor: 'pointer',
        }}
      >
        {value}
      </Collapse>
      <DustLinkButton
        sx={{
          padding: 0,
          height: '1rem',
          fontWeight: 'normal',
          fontSize: 'var(--font-size-text-2xs)',
        }}
        onClick={handleCollapseToggle}
      >
        {isExpanded ? '(See Less)' : '(See More)'}
      </DustLinkButton>
    </>
  );
}

UserAgentCollapsableCell.propTypes = {
  value: PropTypes.string.isRequired,
};
