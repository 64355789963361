import { mediaFileModel } from '@/common/entities/media/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import { MESSAGES } from '@/services/requests/constants';
import { DustSingleResponse } from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIError,
  formatAPIReturn,
} from '@/services/utility';

/** Factory function for producing the media API */
export default class MediaApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
  ) {}

  /** Create by Upload file */
  async create(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.apiService
      .request({
        url: '/api/media',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      .then((res: DustSingleResponse) =>
        formatAPIReturn<{ uuid: string }, unknown>(res),
      )
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (err.response?.data?.message) {
            this.addToast(err.response.data.message, 'error');
          } else {
            this.addToast(MESSAGES.COMMON.GENERIC, 'error');
          }
        }),
      );
  }

  /** Media query */
  async getMedia({
    uuid,
    width,
    height,
    suppressToast = false,
  }: {
    uuid: string;
    width?: number;
    height?: number;
    suppressToast?: boolean;
  }) {
    const args = new URLSearchParams();
    if (width && height) {
      args.append('width', String(width));
      args.append('height', String(height));
    }

    return this.apiService
      .request({
        url: `/api/media/${uuid}?${args.toString()}`,
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, mediaFileModel))
      .catch((err) => {
        if (!suppressToast) this.addToast('Could not query media.', 'error');
        return formatAPIError(err);
      });
  }
}
