import { useEffect } from 'react';

import { FieldArray, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { THING_TYPE_FIELD_TYPES } from '@/common/entities/thingTypes/constants';
import useToasts from '@/common/hooks/useToasts';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustFileUploadMulti from '@/components/Library/DustFileUploadMulti';

import ThingTypeFileField from './ThingTypeFileField';

function ThingTypeStaticFiles({ formik, files, removeFile, initialFiles }) {
  const { addToast } = useToasts();

  const staticFields = formik.values?.fieldTypes
    .map((field, idx) => [field, idx])
    .filter(([field]) => field.value?.type === 'STATIC_MEDIA');

  // Template for new static field field
  // TODO: use type to fix lint rule
  // eslint-disable-next-line no-unused-vars
  const newStaticFile = (name, type) => ({
    name,
    uuid: uuidv4(),
    schema: {
      type: THING_TYPE_FIELD_TYPES.FILE, // TODO: For some reason both images and files are type 'File' per BE
    },
    value: {
      type: 'STATIC_MEDIA',
      value: '',
    },
  });

  const handleSelectFiles = async (files, arrayHelpers) => {
    const filesToSet = {};
    Object.keys(files).forEach(async (filename) => {
      const name = filename.split('.').slice(0, -1).join('');
      const fieldEntry = newStaticFile(name, files[filename].type);
      arrayHelpers.push(fieldEntry);
      filesToSet[fieldEntry.uuid] = files[filename];
    });

    formik.setFieldValue('files', {
      ...formik.values.files,
      ...filesToSet,
    });
  };

  const { validateForm } = formik;
  useEffect(() => {
    validateForm();
  }, [files, validateForm]);

  const handleRemoveFile = (idx, uuid, arrayHelpers) => {
    arrayHelpers.remove(idx);
    removeFile(uuid);
  };

  const handleReject = (errors) => {
    errors.forEach((error) => addToast(error, 'error'));
  };

  return (
    <div className="flex-col">
      <FormikProvider value={formik}>
        <FieldArray
          name="fieldTypes"
          render={(arrayHelpers) => (
            <div className="flex-col">
              <DustFileUploadMulti
                files={{}}
                fileType={FILE_TYPE_OPTIONS.ALL}
                label="Add Static Files"
                setFiles={(f) => handleSelectFiles(f, arrayHelpers)}
                style={{
                  minHeight: '2rem',
                  marginTop: 0,
                }}
                onReject={handleReject}
              />
              {staticFields.map(([field, idx]) => (
                <ThingTypeFileField
                  field={field}
                  fieldKey="fieldTypes"
                  file={files[field.uuid] ?? initialFiles[field.uuid]}
                  formik={formik}
                  idx={idx}
                  key={field.uuid}
                  onRemoveFile={(idx) =>
                    handleRemoveFile(idx, field.uuid, arrayHelpers)
                  }
                />
              ))}
            </div>
          )}
          validateOnChange={false}
        />
      </FormikProvider>
    </div>
  );
}

ThingTypeStaticFiles.propTypes = {
  formik: PropTypes.object,
  initialFiles: PropTypes.object,
  files: PropTypes.object,
  removeFile: PropTypes.func,
};

export default ThingTypeStaticFiles;
