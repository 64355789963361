import { atom } from 'recoil';

import { SortOptions } from '@/services/useSort';

export const THING_TYPE_FIELD_TYPES = {
  STRING: 'string',
  FILE: 'file',
  IMAGE: 'image',
  ARRAY: 'array',
};

export const PLACEHOLDER_TYPES = {
  SINGLE_IMAGE: 'single-image',
  MULTI_IMAGE: 'multi-image',
  SINGLE_FILE: 'single-file',
  MULTI_FILE: 'multi-file',
};

export const PLACEHOLDER_SCHEMAS = {
  [PLACEHOLDER_TYPES.SINGLE_IMAGE]: { type: THING_TYPE_FIELD_TYPES.IMAGE },
  [PLACEHOLDER_TYPES.SINGLE_FILE]: { type: THING_TYPE_FIELD_TYPES.FILE },
  [PLACEHOLDER_TYPES.MULTI_IMAGE]: {
    type: THING_TYPE_FIELD_TYPES.ARRAY,
    items: { type: THING_TYPE_FIELD_TYPES.IMAGE },
  },
  [PLACEHOLDER_TYPES.MULTI_FILE]: {
    type: THING_TYPE_FIELD_TYPES.ARRAY,
    items: { type: THING_TYPE_FIELD_TYPES.FILE },
  },
};

export const THING_TYPE_SORT_OPTIONS = Object.freeze({
  nameaz: {
    label: 'Name (A-Z)',
    params: { sort: [{ field: 'name', desc: false }] },
    column: 'name',
    direction: 'asc',
  },
  nameza: {
    label: 'Name (Z-A)',
    params: { sort: [{ field: 'name', desc: true }] },
    column: 'name',
    direction: 'desc',
  },
  createdByAZ: {
    label: 'Created By (A-Z)',
    params: { sort: [{ field: 'createdBy', desc: false }] },
    column: 'createdBy',
    direction: 'asc',
  },
  createdByZA: {
    label: 'Created By (Z-A)',
    params: { sort: [{ field: 'createdBy', desc: true }] },
    column: 'createdBy',
    direction: 'desc',
  },
  createdAtNewest: {
    label: 'Creation Date (Newest First)',
    params: { sort: [{ field: 'createdAt', desc: true }] },
    column: 'createdAt',
    direction: 'desc',
  },
  createdAtOldest: {
    label: 'Creation Date (Oldest First)',
    params: { sort: [{ field: 'createdAt', desc: false }] },
    column: 'createdAt',
    direction: 'asc',
  },
  thingsCountAsc: {
    label: 'Things Count (ascending)',
    params: { sort: [{ field: 'thingsCount', desc: false }] },
    column: 'thingsCount',
    direction: 'asc',
  },
  thingsCountDesc: {
    label: 'Things Count (descending)',
    params: { sort: [{ field: 'thingsCount', desc: true }] },
    column: 'thingsCount',
    direction: 'desc',
  },
} as const);

// TODO: use upcoming TypeScript `satisfies` keyword for this type check
((q: SortOptions) => q)(THING_TYPE_SORT_OPTIONS);

export const thingTypesSortAtom = atom<keyof typeof THING_TYPE_SORT_OPTIONS>({
  key: 'thingTypesSort',
  default: 'nameaz',
});
