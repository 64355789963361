import { useCallback, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import { useRecoilState } from 'recoil';

import { isNumericVersionGreaterThan } from '@/common/utility';
import DustAlert from '@/components/Library/DustAlert';
import { prevReleaseVersionSeenAtom } from '@/state/atoms/releaseInfo';

import styles from './ReleaseBanner.module.css';

export type Props = { appContentRef: React.RefObject<HTMLDivElement> };

/** Example: "3.2" */
const appVersion = __APP_VERSION__;

const seeChangesUrl = `${import.meta.env.VITE_HELP_URL}/newforthisrelease`;

export default function ReleaseBanner({ appContentRef }: Props) {
  const [show, setShow] = useState(false);

  const [prevReleaseVersionSeen, setPrevReleaseVersionSeen] = useRecoilState(
    prevReleaseVersionSeenAtom,
  );

  // Control showing the banner visibility
  useEffect(() => {
    if (prevReleaseVersionSeen === undefined) return; // Noop while unfetched
    const shouldShowReleaseBanner =
      prevReleaseVersionSeen === null ||
      isNumericVersionGreaterThan(appVersion, prevReleaseVersionSeen);
    setShow(shouldShowReleaseBanner);
  }, [prevReleaseVersionSeen]);

  const handleDismiss = useCallback(() => {
    // Intent is to hide the banner, depends on the banner visibility effect above
    setPrevReleaseVersionSeen(appVersion);
  }, [setPrevReleaseVersionSeen]);

  return (
    <DustAlert
      show={show}
      containerRef={appContentRef}
      color="info"
      flexGrowMessage
      icon={<InfoOutlinedIcon />}
      action={
        <IconButton
          color="inherit"
          title="Dismiss"
          size="small"
          onClick={handleDismiss}
        >
          <CloseIcon className={styles.alertIcon} />
        </IconButton>
      }
    >
      <div className={styles.alertMessageContent}>
        New Release — DICE v{appVersion}
        <a
          className={styles.alertLink}
          href={seeChangesUrl}
          target="_blank"
          rel="noreferrer"
        >
          See Changes
        </a>
      </div>
    </DustAlert>
  );
}
