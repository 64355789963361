import { AxiosResponse } from 'axios';

import baseModel from '@/common/entities/baseModel';
import { groupModel, oidcGroupModel } from '@/common/entities/groups/models';
import { ApiGroup } from '@/common/entities/groups/typedefs';
import { orgRoleModel } from '@/common/entities/orgRoles/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import {
  DustArrayResponse,
  DustSingleResponse,
} from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIError,
  formatAPIReturn,
  formatIdentityAPIReturn,
  formatPagedApiReturn,
  mapResponseEnvelopeData,
  mergeOidcArrayByKey,
  mergeWithDustResponse,
} from '@/services/utility';

import { MESSAGES } from '../constants';

const IDENTITY_URL = import.meta.env.VITE_USER_AUTHORITY;

type GetUserGroupsParams = {
  metadataText?: string;
  search?: string;
  page?: number;
  perPage?: number;
  groupUuids?: string[];
  sort?: readonly string[];
};

/** Factory function for producing the groups API */
export default class GroupsApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  async getDiceUserGroupsRaw(
    params: GetUserGroupsParams,
  ): Promise<DustArrayResponse<ApiGroup>> {
    return this.apiService.request({
      url: '/api/groups/search',
      method: 'POST',
      data: removeNil({
        search: '',
        perPage: 10,
        page: 1,
        ...params,
      }),
    });
  }

  /** Get user groups */
  async getUserGroups(params: GetUserGroupsParams) {
    return this.getDiceUserGroupsRaw(params)
      .then(async (res) => {
        const identityRes = await this.apiService.request({
          url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups`,
        });

        return formatPagedApiReturn(
          mapResponseEnvelopeData(res, (resData) =>
            mergeOidcArrayByKey(identityRes.data, resData, 'id', 'oidcId'),
          ),
          params.page ?? 1,
          groupModel,
        );
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        }),
      );
  }

  /** Get user group by UUID */
  async getGroup(uuid: string) {
    return this.apiService
      .request({
        url: `/api/groups/${uuid}`,
        method: 'GET',
      })
      .then(async (res: DustSingleResponse) => {
        const identityRes = await this.apiService.request({
          url: `${IDENTITY_URL}/api/organizations/${
            this.apiService.orgId
          }/groups/${(res.data.data as any).oidcId}`,
        });

        return formatAPIReturn(
          mergeWithDustResponse(res, identityRes),
          groupModel,
        );
      })
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED));
  }

  /** Create a user group */
  async createGroup({
    name,
    description,
  }: {
    name: string;
    description: string;
  }) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups`,
        method: 'POST',
        data: { name, description },
      })
      .then((res: AxiosResponse<object>) => {
        this.addToast(MESSAGES.GROUPS.CREATED(name), 'success');
        return formatIdentityAPIReturn(res, oidcGroupModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (err.response?.status === 409) {
            this.addToast(
              MESSAGES.GROUPS.CREATE_FAILED(
                name,
                MESSAGES.GROUPS.ALREADY_EXISTS,
              ),
              'error',
            );
          } else {
            this.addToast(
              MESSAGES.GROUPS.CREATE_FAILED(name, err.response?.data?.detail),
              'error',
            );
          }
        }),
      )
      .finally(() => this.invalidate.groups());
  }

  /** Create a user group */
  async updateGroup(
    groupOidcId: string,
    { name, description }: { name: string; description: string },
  ) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups/${groupOidcId}`,
        method: 'PATCH',
        data: { name, description },
      })
      .then((res: AxiosResponse<object>) => {
        this.addToast(MESSAGES.GROUPS.UPDATE(name), 'success');
        return formatIdentityAPIReturn(res, oidcGroupModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          this.addToast(
            MESSAGES.GROUPS.UPDATE_FAILED(err.response?.data?.detail),
            'error',
          );
        }),
      )
      .finally(() => this.invalidate.groups());
  }

  /** Add users to a group */
  async addUsersToGroup(groupOidcId: string, users: { sub: string }[]) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups/${groupOidcId}/users`,
        method: 'POST',
        data: users.map((s) => ({ sub: s.sub })),
      })
      .then((res: AxiosResponse<object>) => {
        this.addToast(MESSAGES.GROUPS.ADD_USER.SUCCESS, 'success');
        return formatIdentityAPIReturn(res, orgRoleModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.GROUPS.ADD_USER.FAIL, 'error');
        }),
      )
      .finally(() => {
        this.invalidate.users();
        this.invalidate.groups();
      });
  }

  /** Remove users from a group */
  async removeUsersFromGroup(groupOidcId: string, users: { sub: string }[]) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups/${groupOidcId}/users`,
        method: 'DELETE',
        data: users.map((s) => ({ sub: s.sub })),
      })
      .then((res: AxiosResponse<object>) => {
        this.addToast(MESSAGES.GROUPS.REMOVE_USER.SUCCESS, 'success');
        return formatIdentityAPIReturn(res, orgRoleModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.GROUPS.REMOVE_USER.FAIL, 'error');
        }),
      )
      .finally(() => {
        this.invalidate.users();
      });
  }

  /** Get all the roles attached to a group */
  async getGroupRoles(groupOidcId: string) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups/${groupOidcId}/roles`,
        method: 'GET',
      })
      .then((res: AxiosResponse<unknown[]>) =>
        formatIdentityAPIReturn(res, orgRoleModel),
      )
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        }),
      );
  }

  /** Update a user groups roles */
  async updateGroupRoles(
    groupOidcId: string,
    roles: { id: string; name: string }[],
    suppressToast = false,
  ) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups/${groupOidcId}/roles`,
        method: 'PUT',
        data: roles,
      })
      .then((res: AxiosResponse<object>) => {
        if (!suppressToast)
          this.addToast(MESSAGES.GROUPS.UPDATE_ROLES.SUCCESS, 'success');
        return formatIdentityAPIReturn(res, orgRoleModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.GROUPS.UPDATE_ROLES.FAIL, 'error');
        }),
      )
      .finally(() => {
        // TODO: invalidate.group(groupOidcId) (uuid <-> id)
        this.invalidate.groups();
        this.invalidate.groupRoles(groupOidcId);
      });
  }

  /** Delete a user group */
  async deleteGroup(groupOidcId: string, suppressToast = false) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${this.apiService.orgId}/groups/${groupOidcId}`,
        method: 'DELETE',
      })
      .then((res: AxiosResponse<object>) => {
        if (!suppressToast) this.addToast(MESSAGES.GROUPS.DELETED(), 'success');

        return formatIdentityAPIReturn(res);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (!suppressToast) {
            this.addToast(
              MESSAGES.GROUPS.DELETE_FAILED(
                err.response?.data?.detail ?? 'Server Error',
              ),
              'error',
            );
          }
        }),
      )
      .finally(() => {
        this.invalidate.groups();
      });
  }

  /** Get filter bounds for user group */
  async getFilterBounds({ uuid }: { uuid: string }) {
    return (
      this.apiService
        .request({
          url: `/api/groups/${uuid}/bounds`,
          method: 'GET',
        })
        // Uses baseModel since we don't have/need an object model for this
        .then((res) => formatAPIReturn(res, baseModel<GroupFilter>))
        .catch((err) => formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED))
    );
  }

  /** Update a user groups filters */
  // TODO: Confirm typing is correct for this return. Why are we using baseModel?
  async updateGroupFilters(uuid: string, values: object) {
    return this.apiService
      .request({
        url: encodeURI(`/api/groups/${uuid}/bounds`),
        method: 'PUT',
        data: { ...values },
      })
      .then((res) => {
        this.addToast(MESSAGES.GROUPS.UPDATED_FILTERS, 'success');
        this.invalidate.filters(uuid);
        // Uses baseModel since we don't have/need an object model for this
        return formatAPIReturn(res, baseModel<Group>);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          this.addToast(
            MESSAGES.GROUPS.UPDATE_FAILED(err.response?.data?.message),
            'error',
          );
        }),
      );
  }
}
