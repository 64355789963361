import { ComponentProps, ReactNode } from 'react';

import Close from '@mui/icons-material/Close';
import { Modal, IconButton, ModalProps } from '@mui/material';

import styles from './Components.module.css';
import DustStepperFooter from './DustStepperFooter';
import { combineClass } from './utility';

type Props = {
  open: boolean;
  children: ReactNode;
  onClose?: () => void;
  title?: ReactNode;
  header?: ReactNode;
  maxWidth?: string | number;
  footerProps?: ComponentProps<typeof DustStepperFooter>;
  closeButtonTitle?: string;
  bodyClass?: string;
} & Omit<ModalProps, 'title'>;

export default function DustModal({
  open,
  children,
  onClose,
  title,
  header,
  maxWidth,
  footerProps,
  closeButtonTitle,
  bodyClass,
  ...otherProps
}: Props) {
  const defaultHeader = (
    <div className={styles.modalHeader}>
      <h3 className="h3">{title}</h3>
      {onClose && (
        <IconButton
          title={closeButtonTitle ?? 'Close Dialog'}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      )}
    </div>
  );

  return (
    <Modal onClose={onClose} open={open} {...otherProps}>
      <div
        className={styles.modal}
        style={{ maxWidth, width: `min(${maxWidth}, calc(100% - 2rem))` }}
      >
        {header ?? defaultHeader}
        <div className={combineClass(styles.modalBody, bodyClass)}>
          {children}
        </div>
        {footerProps && <DustStepperFooter {...footerProps} />}
      </div>
    </Modal>
  );
}
