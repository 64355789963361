import { useState } from 'react';

import { Remove } from '@mui/icons-material';
import { TextField, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import DustLoadingButton from '@/components/Library/DustLoadingButton';
import DustAsteriskIcon from '@/components/Library/Icons/DustAsteriskIcon';

function ThingTypeField({
  formik,
  fieldKey,
  name,
  idx,
  checkRequiredIsValid,
  onRemove,
  onChange,
  onEnter,
  onBlur,
}) {
  // Must handle name and schema level errors
  const isError =
    formik.touched[fieldKey]?.[idx]?.name &&
    (!!formik.errors[fieldKey]?.[idx]?.name ||
      !!formik.errors[fieldKey]?.[idx]);

  const fieldMessage =
    typeof formik.errors[fieldKey]?.[idx] === 'string'
      ? formik.errors[fieldKey]?.[idx]
      : Object.values(formik.errors[fieldKey]?.[idx] ?? [''])[0];

  const errorMessage =
    isError && (formik.errors[fieldKey]?.[idx]?.name || fieldMessage);

  const isRequired = formik.values[fieldKey]?.[idx]?.required;
  const uuid = formik.values[fieldKey]?.[idx]?.uuid;

  // When editing we check if making a field required is possible
  const [isCheckingRequired, setIsCheckingRequired] = useState(false);
  const handleRequiredChange = async () => {
    setIsCheckingRequired(true);
    const isValid = !isRequired ? await checkRequiredIsValid(uuid) : false;
    setIsCheckingRequired(false);
    formik.setFieldValue(`${fieldKey}.${idx}.required`, isValid);
  };

  const handleEnter = (evt) => {
    if (evt.code === 'Enter') onEnter();
  };

  return (
    <>
      <TextField
        error={isError}
        helperText={errorMessage}
        inputProps={{
          autoComplete: uuid.split('-')[0],
          form: {
            autocomplete: 'off',
          },
        }}
        onKeyDown={handleEnter}
        label="Field Name*"
        margin="none"
        name={`${fieldKey}.${idx}.name`}
        onBlur={(e) => onBlur(e, { prevValue: name })}
        onChange={(e) => onChange(e, { prevValue: name })}
        size="small"
        value={name}
        variant="outlined"
        fullWidth
      />
      <div className="flex-row">
        <DustLoadingButton
          color="primary"
          isIcon
          loading={isCheckingRequired}
          name={`${fieldKey}.${idx}.required`}
          onClick={handleRequiredChange}
          tooltipProps={{
            enterDelay: 400,
            title: isRequired ? 'Make field optional' : 'Make field required',
          }}
        >
          <DustAsteriskIcon color={isRequired ? 'primary' : 'disabled'} />
        </DustLoadingButton>
        <Tooltip enterDelay={400} title="Delete field">
          <IconButton
            aria-label="Delete"
            onClick={() => onRemove(idx)}
            sx={{ color: 'var(--error-red)' }}
          >
            <Remove />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}

ThingTypeField.propTypes = {
  formik: PropTypes.object,
  fieldKey: PropTypes.string,
  name: PropTypes.string,
  uuid: PropTypes.string,
  idx: PropTypes.number,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
export default ThingTypeField;
