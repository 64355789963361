import { useMemo } from 'react';

import { useQuery } from 'react-query';

import { alphaSort } from '@/common/utilities/sort';
import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 5 * 60 * 1000;

export default function useGroupRoleData({
  groupOidcId,
}: {
  groupOidcId: string;
}) {
  const { groupsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    [...QUERY_KEYS.GROUP_ROLES(groupOidcId)],
    () => groupsApi.getGroupRoles(groupOidcId),
    { staleTime: STALE_TIME, enabled: !!groupOidcId },
  );

  const groupRoles = useMemo(
    () =>
      data && data.error === false
        ? [...data.data].sort((a, b) => alphaSort(a, b, 'name'))
        : [],
    [data],
  );

  return {
    groupRoles,
    isLoading,
    isSuccess,
  };
}
