import React, { MouseEventHandler } from 'react';

import { Button, Tooltip } from '@mui/material';

import {
  SCAN_WIDTH,
  SCAN_HEIGHT,
} from '@/common/entities/antiTamper/constants';
import { useResize } from '@/common/hooks';

import styles from './AntiTamperNotation.module.css';
import AntiTamperNotationPointer from './AntiTamperNotationPointer';

type AntiTamperNotationProps = {
  isActive: boolean;
  notations: AntiTamperNotation[];
  setNotations: React.Dispatch<React.SetStateAction<AntiTamperNotation[]>>;
  isReadOnly: boolean;
};

// TODO: Repalce these with constants located elsewhere?

const RATIO = SCAN_WIDTH / SCAN_HEIGHT;

const sanitizeNumber = (number: number) => (Number.isNaN(number) ? 0 : number);

export default function AntiTamperNotation({
  isActive,
  notations,
  setNotations,
  isReadOnly,
}: AntiTamperNotationProps) {
  const {
    width: unsanitizedWidth,
    height: unsanitizedHeight,
    resizeRef,
  } = useResize();
  const [width, height] = [unsanitizedWidth, unsanitizedHeight].map(
    sanitizeNumber,
  );
  const imageW = Number.isNaN(width) ? 0 : Math.min(width, height * RATIO);
  const imageH = imageW / RATIO;
  const marginY = (height - imageH) / 2;
  const marginX = (width - imageW) / 2;
  const scale = imageW / SCAN_WIDTH;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (isActive && !isReadOnly) {
      const [x, y] = [
        Math.floor(e.nativeEvent.offsetX / scale),
        Math.floor(e.nativeEvent.offsetY / scale),
      ];
      setNotations((oldNotations) => [
        ...oldNotations.filter((n) => !n.active),
        {
          x,
          y,
          pctX: (x / SCAN_WIDTH) * 100,
          pctY: (y / SCAN_HEIGHT) * 100,
          active: true,
          saved: false,
        } as AntiTamperNotation,
      ]);
    }
  };

  const handleNotationCancel = (index: number) => {
    setNotations((oldNotations) =>
      oldNotations.filter((notation, idx) => idx !== index),
    );
  };

  const handleNotationSave = (index: number, comment: string) => {
    setNotations((oldNotations) =>
      oldNotations.map((notation, idx) =>
        idx === index
          ? { ...notation, comment, active: false, saved: true }
          : notation,
      ),
    );
  };

  const handleClose = () => {
    setNotations((oldNotations) =>
      oldNotations.map((notation) => ({ ...notation, active: false })),
    );
  };

  const handleOpen = (index: number) => {
    setNotations((oldNotations) =>
      oldNotations.map((notation, idx) => ({
        ...notation,
        active: idx === index,
      })),
    );
  };

  return (
    <div className={styles.notationWrapper} ref={resizeRef}>
      <Tooltip title="Click to add a notation" followCursor enterDelay={2000}>
        <Button
          sx={{
            width: imageW,
            height: imageH,
            zIndex: 9,
            display: isActive ? 'block' : 'none',
            cursor: isReadOnly ? 'default' : 'pointer',
            position: 'relative',
            top: marginY,
            bottom: marginY,
            left: marginX,
            right: marginX,
            padding: 0,
          }}
          onClick={handleClick}
          disableRipple
        />
      </Tooltip>
      <div
        style={{
          width: imageW,
          height: imageH,
          display: isActive ? 'block' : 'none',
          cursor: 'pointer',
          position: 'absolute',
          top: marginY,
          bottom: marginY,
          left: marginX,
          right: marginX,
          padding: 0,
        }}
      >
        {isActive &&
          notations.map((item, index) => (
            <AntiTamperNotationPointer
              item={item}
              key={`${item.x}_${item.y}`}
              handleCancel={() => handleNotationCancel(index)}
              handleSave={(comment: string) =>
                handleNotationSave(index, comment)
              }
              handleClose={handleClose}
              handleOpen={() => handleOpen(index)}
              index={index}
              isReadOnly={isReadOnly}
            />
          ))}
      </div>
    </div>
  );
}
