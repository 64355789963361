import { ChatBubble as CommentIcon } from '@mui/icons-material';

import { formatDateForDisplay } from '@/common/dates';
// TODO use import { TRANSACTION_TYPES } from '@/common/entities/transactions/constants';
import DustTransactionIcon from '@/components/Library/Icons/DustTransactionIcon';

import styles from './TransactionHeading.module.css';

type Props = {
  transaction: Transaction;
};

export default function TransactionHeading({ transaction }: Props) {
  return transaction ? (
    <div className="flex-row items-center" key={transaction.uuid}>
      <DustTransactionIcon
        className="mr-2"
        sx={{ height: '2rem', width: '2rem', marginRight: '.5rem' }}
        type={transaction.transactionType}
        verified={!!transaction.verifiedDustUuid}
        verifySuccess={transaction.matchOutcome === 'MATCH_SUCCESS'}
      />
      <div className="min-w-0">
        <div className="flex-row">
          <div className="font-medium text-sm text-overflow">
            {transaction?.label}
          </div>
          {transaction.annotations?.length > 0 && (
            <div className={styles.annotationCountContainer}>
              <CommentIcon
                className={styles.annotationIcon}
                fontSize="medium"
              />
              <span className={styles.annotationCount}>
                {transaction.annotations.length}
              </span>
            </div>
          )}
        </div>
        <div className="text-overflow" style={{ fontSize: '10px' }}>
          {`${formatDateForDisplay(transaction.createdAt, {
            showTime: false,
          })} @${
            transaction.user?.username ?? `${transaction.user?.name} (Token)`
          }`}
        </div>
      </div>
    </div>
  ) : (
    (null as any) /* TODO: React 18 */
  );
}
