import React from 'react';

import { Box, Grid } from '@mui/material';

// import { NavigationCheck } from 'components/molecules';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';

import styles from './AntiTamperLayout.module.css';

type AntiTamperLayoutProps = {
  header: React.ReactNode;
  sideBar: React.ReactNode;
  scanModal: React.ReactNode;
  isVerifiedSession: boolean;
  newSession: React.ReactNode;
  handleClickOutSideAntiTamper: () => void;
  warningModal: React.ReactNode;
  verifiedSession: React.ReactNode;
  rescanWarningModal: React.ReactNode;
  antiTamperSaveModal: React.ReactNode;
  antiTamperLoadingModal: React.ReactNode;
};

export default function AntiTamperLayout({
  header,
  sideBar,
  scanModal,
  isVerifiedSession,
  newSession,
  handleClickOutSideAntiTamper,
  warningModal,
  verifiedSession,
  rescanWarningModal,
  antiTamperSaveModal,
  antiTamperLoadingModal,
}: AntiTamperLayoutProps) {
  useNavigationPrompt(true, {
    message:
      'Do you want to discard analysis of this tag? Select "Continue" to exit or "Cancel" to go back to analysis.',
    title: 'Leave without completing analysis?',
  });

  return (
    <>
      <Box
        className={styles.overlay}
        component="div"
        onClick={handleClickOutSideAntiTamper}
      />
      <Grid container direction="row" className={styles.root}>
        {sideBar}
        <Grid item component="div" className={styles.rightSide} dir="column">
          {header}
          {isVerifiedSession && verifiedSession}
          {!isVerifiedSession && newSession}
        </Grid>
        {scanModal}
        {warningModal}
        {rescanWarningModal}
        {antiTamperSaveModal}
        {antiTamperLoadingModal}
      </Grid>
    </>
  );
}
