import { SvgIcon, SvgIconProps } from '@mui/material';

function DustFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="M6 2.5H14L20 8.5V20.5C20 21.6 19.1 22.5 18 22.5H5.99C4.89 22.5 4 21.6 4 20.5L4.01 4.5C4.01 3.4 4.9 2.5 6 2.5ZM6 4.5V20.5H18V9.5H13V4.5H6Z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default DustFileIcon;
