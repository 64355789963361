import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './Toast.module.css';

const TOAST_COLOR_STYLES = {
  error: {
    '--toast-text': 'var(--color-text-error)',
    '--toast-bg': 'var(--color-error)',
  },
  warning: {
    '--toast-text': 'var(--color-text-warning)',
    '--toast-bg': 'var(--color-warning)',
  },
  info: {
    '--toast-text': 'var(--color-text-info)',
    '--toast-bg': 'var(--color-info)',
  },
  success: {
    '--toast-text': 'var(--color-text-success)',
    '--toast-bg': 'var(--color-success)',
  },
};

function Toast({ toast, onDelete }) {
  const Icon = {
    info: <InfoOutlinedIcon />,
    success: <CheckCircleOutlinedIcon />,
    warning: <WarningAmberOutlinedIcon />,
    error: <ErrorOutlineOutlinedIcon />,
  };
  return (
    <div className={styles.toast} style={TOAST_COLOR_STYLES[toast.severity]}>
      {Icon[toast.severity]}
      <span>{toast.message}</span>
      <IconButton onClick={onDelete} sx={{ padding: '0 8px' }}>
        <CloseOutlinedIcon sx={{ color: 'var(--toast-text)' }} />
      </IconButton>
    </div>
  );
}

Toast.propTypes = {
  toast: PropTypes.object,
  onDelete: PropTypes.func,
};

export default Toast;
