import baseModel from '../baseModel';

const catalogFilter = (catalogUuid: string) => (t: Thing) =>
  t.catalogUuid === catalogUuid;
const checkedOutReducer = (sum: number, thing: Thing) =>
  sum + (thing.isCheckedOut ? 1 : 0);
const childrenFilter = (thingUuid: string) => (t: Thing) =>
  t.relationships?.parent?.uuid === thingUuid;

/**
 * Formats the API return into a shoppingCart object
 */
export function shoppingCartModel(shoppingCart: ShoppingCart): ShoppingCart {
  return {
    ...baseModel(shoppingCart),
    numberOfRootThings: shoppingCart.thingUuids.length,
    isThingAdded: (thing: Thing) =>
      shoppingCart.thingUuids.includes(thing.uuid) ||
      shoppingCart.thingUuids.includes(thing.relationships?.root?.uuid ?? ''),
  };
}

/**
 * Formats an array of Things into a shoppingCartThings object
 */
export function shoppingCartThingsModel(things: Thing[]): ShoppingCartThings {
  const catalogUuids = [...new Set(things.map((t) => t.catalogUuid))];
  return {
    things,
    catalogUuids,
    numberOfCatalogs: catalogUuids.length,
    childrenOfThing: (thing: Thing) =>
      things.filter(childrenFilter(thing.uuid)),
    thingsInCatalog: (catalogUuid: string) =>
      things.filter(catalogFilter(catalogUuid)),
    errorThingsCount: things.reduce(checkedOutReducer, 0),
    errorThingsInCatalogCount: (catalogUuid: string) =>
      things.filter(catalogFilter(catalogUuid)).reduce(checkedOutReducer, 0),
  };
}
