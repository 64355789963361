import { Children, useState, useEffect, useRef } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import styles from './LeftNav.module.css';
import NavEntryMenu from './NavEntryMenu';
import NavEntryMenuItem from './NavEntryMenuItem';

type Props = {
  isFull: boolean;
  onNavBlur?: (e: boolean) => void;
  children?: JSX.Element[];
};

export default function NavItemList({ isFull, onNavBlur, children }: Props) {
  const container = useRef<HTMLDivElement | null>(null);

  const [numElements, setNumElements] = useState(children?.length ?? 0);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!container.current) return;

      const contBounds = container.current.getBoundingClientRect();
      const el = container.current?.children?.[0];
      if (el) {
        const style = getComputedStyle(el, null);

        const itemSize =
          (el?.getBoundingClientRect().height ?? 0) +
          parseInt(style?.marginTop, 10) +
          parseInt(style?.marginBottom, 10);

        const num = Math.floor(contBounds.height / itemSize);

        if (num > 1) {
          setNumElements(num);
        } // Always show 1 element
      }
    });

    if (container.current) {
      observer.observe(container.current);
    }
    return () => observer.disconnect();
  }, [children]);

  const linkChildren = (Children.toArray(children) as JSX.Element[]).filter(
    (item, idx) => idx < numElements - 1,
  );
  const menuChildren = (Children.toArray(children) as JSX.Element[]).filter(
    (item, idx) => idx >= numElements - 1,
  );

  return (
    <div className={styles.navList} ref={container}>
      {linkChildren}
      {Children.count(children) >= numElements && (
        <NavEntryMenu
          icon={<MoreHorizIcon />}
          isFull={isFull}
          key="more"
          name="More"
          setOpen={onNavBlur}
        >
          {menuChildren.map((child, idx) => (
            <NavEntryMenuItem
              key={child?.props?.name ?? idx}
              label={child?.props?.name}
              href={child?.props?.href}
            />
          ))}
        </NavEntryMenu>
      )}
    </div>
  );
}
