import { Alert } from '@mui/material';

import styles from './ShoppingCart.module.css';

type ShoppingCartHeaderProps = {
  shoppingCart: ShoppingCart;
  things: ShoppingCartThings;
};

export default function ShoppingCartHeader({
  shoppingCart,
  things,
}: ShoppingCartHeaderProps) {
  return (
    <div className={styles.header}>
      <div className="text-sm mb-1">
        Root Things:{' '}
        <span className={styles.summaryValue}>
          {shoppingCart.numberOfRootThings}
        </span>
        Catalogs:{' '}
        <span className={styles.summaryValue}>{things.numberOfCatalogs}</span>
      </div>
      {things.errorThingsCount > 0 && (
        <Alert className="mb-1" severity="error">
          {things.errorThingsCount} Things unavailable for checkout
        </Alert>
      )}
    </div>
  );
}
