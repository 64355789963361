import { useMemo } from 'react';

import { MenuItem } from '@mui/material';

import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import useThingsData, {
  CHECKOUT_OPTIONS,
  DUST_OPTIONS,
} from '@/common/entities/things/useThingsData';
import { includesWordMatch } from '@/common/utility';
import DustCheckboxSelect from '@/components/Library/DustCheckboxSelect';
import DustSelect from '@/components/Library/DustSelect';

import TableFilter from '../TableFilter/TableFilter';

type UseThingData = ReturnType<typeof useThingsData>;

type Props = {
  filters: UseThingData['filters'];
  setFilter: UseThingData['setFilter'];
  filterFields: string[];
  resetFilters: UseThingData['resetFilters'];
  showResetFilters: boolean;
  catalogUuid?: string;
  catalogList: Catalog[];
  typedFilters: ThingTypedMetaFilters;
};

export default function ThingSelectorFilters({
  setFilter,
  filters,
  filterFields,
  resetFilters,
  showResetFilters,
  catalogUuid,
  catalogList,
  typedFilters,
}: Props) {
  const typeFilterList = useMemo(
    () =>
      Object.entries(typedFilters).filter(([field]) =>
        includesWordMatch(filterFields, field),
      ),
    [typedFilters, filterFields],
  );

  const { org } = useOrgData();

  /** Set typed metadata filters in the request */
  const handleTypedChange = (values: string[], key: string) => {
    if (values.length > 0) {
      setFilter('typedMetadataFilters', {
        ...filters.typedMetadataFilters,
        [key]: values,
      });
    } else {
      const { [key]: remove, ...rest } = filters.typedMetadataFilters;
      setFilter('typedMetadataFilters', rest);
    }
  };

  /** Clear all of the catalog filters */
  const handleClearCatalogFilter = () => {
    setFilter('catalogUuids', []);
  };

  /** Clear all filter values for the specified field */
  const clearFieldSelections = (key: string) => {
    const { [key]: remove, ...rest } = filters.typedMetadataFilters;
    setFilter('typedMetadataFilters', rest);
  };

  return (
    <TableFilter onReset={resetFilters} showReset={showResetFilters}>
      <div className="flex-col mb-05 mt-1 gap-1">
        <DustSelect
          label="DUST Status"
          onChange={(evt) => setFilter('status', evt.target.value)}
          value={filters.status}
        >
          {DUST_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </DustSelect>
        {org && org.isFeatureEnabled(FEATURE_FLAGS.MESH) && (
          <DustSelect
            label="Checkout Status"
            onChange={(evt) => setFilter('showCheckedOut', evt.target.value)}
            value={filters.showCheckedOut}
          >
            {CHECKOUT_OPTIONS.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </DustSelect>
        )}
        {!catalogUuid && (
          <DustCheckboxSelect
            entries={catalogList.map((catalog) => ({
              name: catalog.name,
              value: catalog.uuid,
            }))}
            label="Catalog"
            onChange={(newValues) => setFilter('catalogUuids', newValues)}
            onClear={handleClearCatalogFilter}
            values={filters.catalogUuids}
          />
        )}
      </div>
      <div className="divider-1 h6">Fields</div>
      {typeFilterList.length === 0 && (
        <p>
          <em>No fields selected for column display</em>
        </p>
      )}
      <div className="flex-col gap-1 mb-1">
        {typeFilterList.map(([field, { values, name }]) => (
          <DustCheckboxSelect
            entries={values.map((e) => ({ name: e, value: e }))}
            key={field}
            label={name}
            onChange={(newValues) => handleTypedChange(newValues, field)}
            onClear={() => clearFieldSelections(field)}
            values={filters?.typedMetadataFilters?.[field] ?? []}
          />
        ))}
      </div>
    </TableFilter>
  );
}
