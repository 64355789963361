import { useState, useRef, SyntheticEvent } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  ButtonGroup,
  Tooltip,
  Menu,
  MenuItem,
  ButtonProps,
} from '@mui/material';

type Props = {
  label: string;
  options: {
    label: JSX.Element;
    tooltip?: string;
    disabled?: boolean;
    onClick?: () => void;
  }[];
  onClick?: () => void;
  onMenuClick?: (buttonIdx: number) => void;
  color?: string;
} & ButtonProps;

export default function DustMenuButton({
  options,
  label,
  onClick,
  onMenuClick,
  color = 'primary',
  ...props
}: Props) {
  const button = useRef<HTMLButtonElement | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const open = !!menuAnchor;

  // Prevent event bubbling
  const handleMenu = (evt: SyntheticEvent) => {
    evt.stopPropagation();
    setMenuAnchor(button.current);
  };

  const handleMenuClick = (action: () => void) => {
    action?.();
    setMenuAnchor(null);
  };
  return (
    <>
      <ButtonGroup color={color} disableElevation>
        <Button
          size="small"
          variant="contained"
          {...props}
          onClick={onClick ?? handleMenu}
        >
          {label}
        </Button>
        <Button
          id={label}
          onClick={handleMenu}
          ref={button}
          size="small"
          disabled={props.disabled}
          sx={{
            borderLeft: '1px solid var(--contrast-text-color)',
            padding: '.25rem',
          }}
          variant="contained"
        >
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={label}
        onClose={() => setMenuAnchor(null)}
        open={open}
        sx={{ fontSize: '.875rem' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tooltip key={idx} title={item.tooltip || ''}>
            <MenuItem
              onClick={() => {
                if (!item.disabled) {
                  handleMenuClick(item.onClick ?? (() => onMenuClick?.(idx)));
                }
              }}
              sx={
                item.disabled
                  ? {
                      color: 'var(--color-text-disabled)',
                      '&:hover,&:active,&:focus': {
                        color: 'var(--color-text-disabled)',
                        backgroundColor: 'var(--white)',
                      },
                      cursor: 'default',
                    }
                  : undefined
              }
            >
              {item.label}
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  );
}
