import baseModel from '@/common/entities/baseModel';
import { thingMediaFileModel } from '@/common/entities/thingMedia/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import { MESSAGES } from '@/services/requests/constants';
import { DustArrayResponse } from '@/services/requests/types';
import {
  formatAPIReturn,
  formatAPIError,
  isAbortError,
} from '@/services/utility';

/** Factory function for thing media submissions */
export default class ThingMediaApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
  ) {}

  /** Upload thing media files */
  async createMedia({
    files,
    filenames = [],
  }: {
    files: File[];
    filenames: string[];
  }) {
    const formData = new FormData();
    Object.values(files).forEach((file, idx) => {
      formData.append('files', file, filenames[idx] ?? file.name);
    });

    return this.apiService
      .request({
        method: 'POST',
        url: '/api/thing-media',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      .then((res: DustArrayResponse) =>
        formatAPIReturn(res, baseModel<ThingFile>),
      )
      .catch((err) => formatAPIError(err));
  }

  /** Search thing media */
  async search({ uuids }: { uuids: string[] }) {
    return this.apiService
      .request({
        method: 'POST',
        url: 'api/thing-media/search',
        data: {
          uuids: uuids.toString(),
        },
      })
      .then((res: DustArrayResponse) =>
        formatAPIReturn(res, thingMediaFileModel),
      )
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  // TODO: Add correct return type and fix users of getMedia
  /** Get Thing Media */
  async getMedia({
    uuid,
    width,
    height,
    suppressToast = false,
    signal,
  }: {
    uuid: string;
    width?: number;
    height?: number;
    suppressToast?: boolean;
    signal?: AbortSignal;
  }) {
    const args = new URLSearchParams();
    if (width && height) {
      args.append('width', String(width));
      args.append('height', String(height));
    }

    return this.apiService
      .request({
        url: `/api/thing-media/${uuid}?${args.toString()}`,
        method: 'GET',
        signal,
      })
      .then((res) => formatAPIReturn(res))
      .catch((err) => {
        if (isAbortError(err)) throw err;
        if (!suppressToast) this.addToast('Could not query media.', 'error');

        return formatAPIError(err);
      });
  }
}
