import { useCallback, useMemo } from 'react';

import { Button } from '@mui/material';
import { FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';

import useToasts from '@/common/hooks/useToasts';
import { readableFileSize } from '@/common/utility';
import {
  ACCEPTED_MIME_TYPES,
  FILE_TYPE_ICONS,
  FILE_TYPE_OPTIONS,
} from '@/components/Library/constants';

import styles from './Components.module.css';
import DustFileChip from './DustFileChip';
import DustMultiFileIcon from './Icons/DustMultiFileIcon';
import DustMultiImageIcon from './Icons/DustMultiImageIcon';
import {
  combineClass,
  extensionsFromFileType,
  mimeTypesFromFileType,
  getNested,
  setNested,
} from './utility';

type Props<T extends object> = {
  fileType: keyof typeof ACCEPTED_MIME_TYPES;
  className?: string;
  style?: object;
  label?: string;
  maxSize?: number;
  maxFiles?: number;

  files?: Record<string, File>;
  setFiles?: (e: Record<string, File>) => void;
  onReject: (e: string[]) => void;

  formik?: FormikProps<T>;
  name?: string;
} & (PropsWithoutFormik | PropsWithFormik<T>);

type PropsWithFormik<T extends object> = {
  name: string;
  formik: FormikProps<T>;
};

type PropsWithoutFormik = {
  name?: undefined;
  // // Marker needed to allow type refinement
  formik?: undefined;

  files: Record<string, File>;
  setFiles: (e: Record<string, File>) => void;
};

function FileIcon({
  fileType,
}: {
  fileType: keyof typeof ACCEPTED_MIME_TYPES;
}) {
  return FILE_TYPE_ICONS[fileType] === FILE_TYPE_OPTIONS.IMAGE ? (
    <DustMultiImageIcon />
  ) : (
    <DustMultiFileIcon />
  );
}

export default function DustFileUploadMulti<T extends object>({
  className,
  style,
  maxFiles = 5,
  maxSize = 20971520, // 20MB
  label,
  files,
  setFiles,
  fileType,
  onReject,
  name,
  formik,
}: Props<T>) {
  const { addToast } = useToasts();

  const fileList: Record<string, File> = useMemo(
    () => (formik ? getNested(formik.values, name) : files),
    [formik, name, files],
  );

  const error = useMemo(
    () =>
      formik ? getNested(formik?.errors, name?.split('.value')?.[0]) : null,
    [formik, name],
  );

  // ---------------------------------------------------------------------------
  // If Formik is detected handle file state through the Formik interface
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const droppedFiles = acceptedFiles.reduce<Record<string, File>>(
        (all, current) => ({ ...all, [current.name]: current }),
        {},
      );

      const combinedFileList = { ...droppedFiles, ...fileList };

      if (Object.keys(combinedFileList).length > maxFiles) {
        addToast(`Cannot upload more than ${maxFiles} files.`, 'error');
        return;
      }

      if (formik) {
        const values = setNested(combinedFileList, formik.values, name, true);
        formik.setValues(values, true);
      } else {
        setFiles?.(combinedFileList);
      }
    },
    [addToast, formik, fileList, maxFiles, name, setFiles],
  );

  const handleDelete = useCallback(
    (filename: string) => {
      const reducedList = { ...fileList };
      delete reducedList[filename];

      if (formik) {
        const values = setNested(reducedList, formik.values, name, true);
        formik.setValues(values, false);
      } else {
        setFiles?.(reducedList);
      }
    },
    [fileList, formik, name, setFiles],
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      maxFiles,
      maxSize,
      accept: mimeTypesFromFileType(fileType),
      // Disable FSAccessAPI
      // Bug on Chromium (especially chromium linux) where picker won't open with mimeTypes specified
      // https://github.com/react-dropzone/react-dropzone/issues/1190
      useFsAccessApi: false,
      onDropRejected: (items) => {
        // Create a unique set of errors (don't display too many files for each files)
        // Format error messages as needed
        const errors: string[] = [];
        items.forEach((item) => {
          item?.errors?.forEach((err) => {
            if (err.code === 'file-too-large') {
              const size = readableFileSize(item.file.size);
              const max = readableFileSize(maxSize);
              errors.push(
                `${item.file.name}: Max file size is ${max}. File size: ${size}`,
              );
            } else if (err.code === 'file-invalid-type') {
              errors.push(
                `${
                  item.file.name
                }: Unsupported file type. Supported file types: ${extensionsFromFileType(
                  fileType,
                ).join(', ')}`,
              );
            } else if (err.code === 'too-many-files') {
              errors.push(`Cannot upload more than ${maxFiles} files.`);
            } else if (err.message) {
              const msg = err.message.replace(/,(?=[^\s])/g, ', '); // Add trailing space to commas
              errors.push(`${item.file.name}: ${msg}`);
            }
          });
        });

        const uniqueErrors = [...new Set(errors)];
        onReject(uniqueErrors);
      },
    });

  const isError = useMemo(() => error || isDragReject, [error, isDragReject]);

  return (
    // No need for keyboard listener due to button availability
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div
      className={combineClass(
        styles.dropzone,
        className,
        styles.dropzone__multi,
        isError && styles.dropzone__reject,
        isDragAccept && styles.dropzone__accept,
      )}
      style={style}
      {...getRootProps()}
      onClick={open}
    >
      <input {...getInputProps()} />
      <div className={styles.dropzone_controls}>
        <div className={styles.dropzone_label}>
          <FileIcon fileType={fileType} />
          <p>{`${label} (Max: ${maxFiles})`}</p>
        </div>
        <Button
          sx={
            isError
              ? {
                  textTransform: 'none',
                  color: 'var(--error-red)',
                  transition: '0s',
                }
              : { textTransform: 'none' }
          }
          onClick={(evt) => {
            evt.stopPropagation();
            open();
          }}
        >
          Browse
        </Button>
      </div>
      {/* Display File Chips for Multi-File */}
      <div className={styles.dropzone__multi_chip_cont}>
        {Object.values(fileList).map((file) => (
          <DustFileChip
            filename={file.name}
            key={file.name}
            onDelete={handleDelete}
            sx={{ marginRight: '.5rem', marginBottom: '.5rem' }}
          />
        ))}
      </div>
    </div>
  );
}
