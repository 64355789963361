import { forwardRef } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

function DustAsteriskIcon(props: SvgIconProps, ref: React.Ref<SVGSVGElement>) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props} ref={ref}>
      <path
        d="M4.43848 6.78711L0.452148 5.65723L1.20117 3.35938L5.14941 4.94629L4.89551 0.515625H7.38379L7.12988 5.03516L10.9766 3.47363L11.7256 5.79688L7.66309 6.92676L10.3291 10.3037L8.31055 11.7383L6 8.03125L3.71484 11.6113L1.69629 10.2402L4.43848 6.78711Z"
        transform="translate(6, 6)"
      />
    </SvgIcon>
  );
}

export default forwardRef(DustAsteriskIcon);
