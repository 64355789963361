import { atom } from 'recoil';
import * as yup from 'yup';

import { userLocalStorageEffect } from '../utility';

/** Undefined = unfetched/unread, and null = user never saw a version. */
export const prevReleaseVersionSeenAtom = atom<string | null | undefined>({
  key: 'prevReleaseVersionSeen',
  default: undefined,
  effects: [
    userLocalStorageEffect(
      'prevReleaseVersionSeen',
      yup.string().required(),
      null,
    ),
  ],
});
