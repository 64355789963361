import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { DustGeolocation } from '@/common/entities/core-types';

export type DustHeaders = {
  Authorization?: string;
  'X-Scope-OrgID'?: string;
  'X-Facility'?: string;
  'X-Geolocation-Latitude'?: string;
  'X-Geolocation-Longitude'?: string;
};

/**
 * Axios wrapper, make sure to keep access token updated
 */
export default class ApiService {
  constructor(public readonly baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    // REQUEST INTERCEPTORS
    this.instance.interceptors.request.use(
      (config) => config,
      (error) => Promise.reject(error),
    );

    // RESPONSE INTERCEPTORS
    this.instance.interceptors.response.use(
      (config) => config,
      (error) => {
        if (
          error.response?.data?.message === 'Invalid facility' &&
          this.clearFacility
        ) {
          this.clearFacility();
        }
        if (import.meta.env.DEV) {
          console.error(error, {
            status: error.response?.status,
            requestUuid: error.response?.data?.meta?.requestUuid,
          });
        }
        return Promise.reject(error);
      },
    );
  }

  /** facilityUuid is included in every request header */
  private facilityUuid: string | null = null;

  private clearFacility: (() => void) | null = null;

  private geolocation: null | DustGeolocation = null;

  /** Readable Org ID for interfacing with Identity api.
   *
   * undefined = unknown, null = unselected, string = valid & usable
   *
   * If orgId is undefined, the app will show a loading screen (via ProtectedRoute)
   * If orgId is null, the user is required to make a selection, but we can request non-org-requiring API data
   */
  orgId: string | null | undefined = undefined;

  /** NOTE: frontend shouldn't use accessToken for non-permission data, use id_token data (available on `useAuth().user.profile`) for most UX concerns */
  private accessToken: null | string = null;

  private instance: AxiosInstance;

  request(options: AxiosRequestConfig): Promise<AxiosResponse> {
    if (this.instance == null) {
      throw new Error('Axios instance has not been initialized');
    }
    // Initial headers
    const headers: DustHeaders = {};

    // Remove undefined props
    if (options.data) {
      Object.keys(options.data).forEach((key) => {
        if (options.data[key] === undefined) {
          // Bit risky, but better for performance compared to the
          // immutable approach, since this is used frequently
          // eslint-disable-next-line no-param-reassign
          delete options.data[key];
        }
      });
    }

    if (this.accessToken) {
      headers.Authorization = `Bearer ${this.accessToken}`;
    }
    if (this.orgId != null) {
      headers['X-Scope-OrgID'] = this.orgId;
    }
    if (this.facilityUuid) {
      headers['X-Facility'] = this.facilityUuid;
    }
    if (this.geolocation) {
      headers['X-Geolocation-Latitude'] = this.geolocation.lat.toString();
      headers['X-Geolocation-Longitude'] = this.geolocation.lon.toString();
    }

    return this.instance({
      ...options,
      // withCredentials: true,
      headers: {
        ...headers,
        ...options?.headers,
      },
    });
  }

  /** Accept a changing access token given the current session, setup
   * in RequestContextProvider */
  setAccessToken(accessToken: string | null) {
    this.accessToken = accessToken;
  }

  setOrgId(orgId: string | null) {
    // Beware changing orgs to keep react-query cache (or other caches) isolated by org
    if (this.orgId != null && orgId !== this.orgId)
      throw new Error('Cannot switch org id without an app refresh');
    this.orgId = orgId;
  }

  setFacilityUuid(facilityUuid: string | null) {
    this.facilityUuid = facilityUuid ?? '';
  }

  setClearFacility(callback: () => void | null) {
    this.clearFacility = callback;
  }

  setGeolocation(geo: null | DustGeolocation) {
    this.geolocation = geo;
  }
}
