import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

type Params = { groupUuid: string };

export default function useFiltersData({ groupUuid }: Params) {
  const { groupsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.FILTERS(groupUuid),
    () => groupsApi.getFilterBounds({ uuid: groupUuid }),
  );

  return {
    filters: data?.data,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
