import { useState } from 'react';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import useToasts from '@/common/hooks/useToasts';
import { provideFallbackImage } from '@/common/utilities/images';
import { downloadFileUrl, fileFromUrl } from '@/common/utility';
import DustFieldLabel from '@/components/Library/DustFieldLabel';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

import styles from '../ThingTabs.module.css';

/**
 *
 * @param   {object}      props
 * @param   {ThingFile}   props.selectedImage
 * @param   {Function}    props.onTraverse
 * @param   {Thing}       props.thing
 * @param   {Function}    props.promptForVerify
 * @param   {ThingFile[]} props.images
 * @param   {boolean}     [props.isStaticPrimary]
 * @param   {boolean}     [props.disableInteraction]
 * @returns {JSX.Element}
 */
function ThingImageCarousel({
  selectedImage,
  onTraverse,
  thing,
  promptForVerify,
  images,
  isStaticPrimary = false,
  disableInteraction = false,
}) {
  const { addToast } = useToasts();
  const { thingsApi } = useRequest();

  const [removeUuid, setRemoveUuid] = useState(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(null);
  const [isSettingPrimary, setIsSettingPrimary] = useState(false);

  const isCalling = isRemoving || isDownloading || isSettingPrimary;
  const onDownloadFile = async (image) => {
    if (isCalling) return;

    setIsDownloading(image.uuid);
    const res = await downloadFileUrl(image.publicUri, image.filename);
    setIsDownloading(null);

    if (res.error) {
      addToast(
        'Could not download the requested file. Please try again or contact support. ',
        'error',
      );
    }
  };

  const handleConfirmRemoveFile = () => {
    if (promptForVerify(() => setRemoveUuid(selectedImage.uuid))) return;
    setRemoveUuid(selectedImage.uuid);
  };

  const onRemoveFile = async (imageUuid = removeUuid) => {
    if (isCalling) return;

    setIsRemoving(true);
    await thingsApi.removeThingFile({
      thingUuid: thing.uuid,
      fileUuid: imageUuid,
    });
    setIsRemoving(false);
    setRemoveUuid(null);
  };

  const onSetPrimary = async (image, isAddMode) => {
    if (isCalling) return;
    setIsSettingPrimary(true);

    if (thing.primaryImageFieldTypeUuid) {
      // Typed mode where field image must be replaced
      if (isAddMode) {
        if (thing.primaryImage) {
          const deleteRes = await thingsApi.removeThingFile({
            thingUuid: thing.uuid,
            fileUuid: thing.primaryImage.uuid,
            suppressToast: true,
          });

          if (deleteRes.error) {
            addToast(
              'Could not remove the existing primary image file. Please try again or contact support.',
              'error',
            );
          }
        }

        const uploadRes = await thingsApi.attachTypedFiles({
          catalogUuid: thing.catalogUuid,
          thingUuids: [thing.uuid],
          fieldTypeUuid: thing.primaryImageFieldTypeUuid,
          files: [
            await fileFromUrl(image.publicUri, image.fileType, image.filename),
          ],
          suppressToast: true,
        });

        if (uploadRes.error) {
          addToast(
            'Could not attach the new primary image file. Please try again or contact support.',
            'error',
          );
        }
      } else {
        await thingsApi.removeThingFile({
          thingUuid: thing.uuid,
          fileUuid: image.uuid,
        });
      }
    } else {
      // Untyped image set
      await thingsApi.updateThingFile({
        thingUuid: thing.uuid,
        fileUuid: image.uuid,
        isPrimary: isAddMode,
      });
    }

    setIsSettingPrimary(false);
  };

  const imageIconStyle = (backgroundColor, color = 'var(--white)') => ({
    color,
    backgroundColor,
    '&:hover': {
      backgroundColor,
      boxShadow: 'var(--elevation-7)',
    },
    '&:focus': {
      backgroundColor,
      boxShadow: 'var(--elevation-7)',
    },
  });

  return (
    <div className="flex-col flex-1 p-1">
      <DustFieldLabel tag="h3" className="h4" label={selectedImage.filename} />
      <div className={styles.mainImageCont}>
        <img
          alt="Selected Thing"
          className={styles.mainImage}
          src={selectedImage.publicUri}
          onError={provideFallbackImage}
        />
        {!disableInteraction && (
          <div className={styles.imageButtons}>
            {!isStaticPrimary &&
              (selectedImage.isPrimary ? (
                <DustLoadingButton
                  color="primary"
                  isIcon
                  loading={isSettingPrimary}
                  onClick={() => onSetPrimary(selectedImage, false)}
                  sx={imageIconStyle('var(--error-red)')}
                  tooltipProps={{ title: 'Remove image as primary' }}
                >
                  <RemoveIcon />
                </DustLoadingButton>
              ) : (
                <DustLoadingButton
                  color="primary"
                  isIcon
                  loading={isSettingPrimary}
                  onClick={() => onSetPrimary(selectedImage, true)}
                  sx={imageIconStyle('var(--primary-blue)')}
                  tooltipProps={{ title: 'Set image as primary' }}
                >
                  <StarOutlineOutlinedIcon />
                </DustLoadingButton>
              ))}
            <DustLoadingButton
              color="primary"
              isIcon
              loading={!!isDownloading}
              onClick={() => onDownloadFile(selectedImage)}
              sx={imageIconStyle('var(--primary-blue)')}
              tooltipProps={{ title: 'Download Image' }}
            >
              <DownloadOutlinedIcon />
            </DustLoadingButton>
            {!selectedImage.isStatic && (
              <DustLoadingButton
                color="error"
                isIcon
                loading={isRemoving}
                onClick={handleConfirmRemoveFile}
                sx={imageIconStyle('var(--error-red)')}
                tooltipProps={{ title: 'Delete Image' }}
              >
                <DeleteOutlinedIcon />
              </DustLoadingButton>
            )}
          </div>
        )}
        <SwitchImageButton dir="left" onClick={() => onTraverse('left')} />
        <SwitchImageButton dir="right" onClick={() => onTraverse('right')} />
        <DustModal
          footerProps={{
            onCancel: () => setRemoveUuid(null),
            onSubmit: () => onRemoveFile(),
            submitLabel: 'Remove',
            submitColor: 'error',
            loading: isRemoving,
          }}
          onClose={() => setRemoveUuid(null)}
          open={!!removeUuid}
          title="Remove Image"
        >
          <p>
            Are you sure you want to remove image:{' '}
            <b>{images.find((f) => f.uuid === removeUuid)?.filename}</b>
          </p>
        </DustModal>
      </div>
    </div>
  );
}

ThingImageCarousel.propTypes = {
  selectedImage: PropTypes.object,
  onTraverse: PropTypes.func,
  thing: PropTypes.object,
  promptForVerify: PropTypes.func,
  images: PropTypes.array,
  isStaticPrimary: PropTypes.bool,
};

function SwitchImageButton({ dir, onClick }) {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: 'white',
        border: 'var(--border)',
        // opacity: .8,
        height: '2rem',
        width: '2rem',
        position: 'absolute',
        top: 'calc(50% - 15px)',
        [dir]: '-1rem',
        transition: 'box-shadow .2s ease',
        '&:hover': {
          boxShadow: 'var(--elevation-4)',
          opacity: 1,
          backgroundColor: 'var(--white)',
        },
      }}
    >
      {dir === 'left' ? (
        <ArrowBackIosNewIcon
          sx={{
            width: '1.25rem',
            height: '1.25rem',
            transform: 'translate(-1px)',
          }}
        />
      ) : (
        <ArrowForwardIosIcon
          sx={{
            width: '1.25rem',
            height: '1.25rem',
            transform: 'translate(1px)',
          }}
        />
      )}
    </IconButton>
  );
}

SwitchImageButton.propTypes = {
  dir: PropTypes.string,
  onClick: PropTypes.func,
};

export default ThingImageCarousel;
