import { SvgIcon, SvgIconProps } from '@mui/material';

function DustMissingMarkersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 15 15">
        <rect
          x="1"
          y="1"
          width="13"
          height="13"
          stroke="#FF0000"
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
}

export default DustMissingMarkersIcon;
