import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { Button } from '@mui/material';
import { FieldArray, FormikProps, FormikProvider } from 'formik';
import { nanoid } from 'nanoid';

import ThingDataField from './ThingDataField';
import styles from './ThingDetailsSingle.module.css';

const makeDefaultField = () => ({ name: '', value: '', uuid: nanoid() });

type Props = {
  formik: FormikProps<AddThingForm>;
  disableAll: boolean;
  isTyped: boolean;
};

/**
 * Dynamic Formik for metadata fields
 */
export default function ThingDataFields({
  formik,
  isTyped,
  disableAll = false,
}: Props) {
  const fieldKey = isTyped ? 'typedMetadata' : 'metadata';
  const fieldValues = formik.values[fieldKey] ?? [];

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={fieldKey}
        render={(arrayHelpers) => (
          <div className={isTyped ? styles.typedFields : styles.metaFields}>
            {fieldValues.map((field, idx) => (
              <ThingDataField
                key={field.uuid}
                isTyped={isTyped}
                disabled={disableAll}
                formik={formik}
                idx={idx}
                onRemove={() => arrayHelpers.remove(idx)}
              />
            ))}
            {!isTyped && (
              <>
                <Button
                  disabled={disableAll}
                  onClick={() => arrayHelpers.push(makeDefaultField())}
                  size="small"
                  startIcon={<Add />}
                  sx={{ marginTop: '.5rem' }}
                >
                  Add Field
                </Button>
                {fieldValues.length > 0 && (
                  <Button
                    color="error"
                    disabled={disableAll}
                    endIcon={<Remove />}
                    onClick={() => {
                      formik.setFieldValue(fieldKey, []);
                    }}
                    size="small"
                    sx={{ marginTop: '.5rem', float: 'right' }}
                  >
                    Remove All Fields
                  </Button>
                )}
              </>
            )}
          </div>
        )}
        validateOnChange={false}
      />
    </FormikProvider>
  );
}
