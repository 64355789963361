import { MouseEventHandler } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

import {
  filterRestrictedFields,
  getTypedDataFields,
} from '@/common/entities/things/utility';
import ThingFieldEntry from '@/components/Pages/Things/Thing/Tabs/ThingFieldEntry';

import styles from './ThingTabs.module.css';

type Props = {
  thing: Thing;
  onSelectField: (fieldKey: string, isTyped: boolean) => void;
  onAddField: MouseEventHandler<HTMLButtonElement>;
  disableInteraction?: boolean;
};

export default function ThingDataFields({
  thing,
  onSelectField,
  onAddField,
  disableInteraction = false,
}: Props) {
  const meta = Object.entries(filterRestrictedFields(thing.metadata));
  const typedMeta = getTypedDataFields(thing);

  const addIconStyle = {
    border: 'var(--border-w) solid',
    borderRadius: '50%',
  };

  return (
    <>
      <Button
        disabled={disableInteraction}
        onClick={onAddField}
        startIcon={<AddIcon style={addIconStyle} />}
      >
        Add Field
      </Button>
      {typedMeta.length > 0 && (
        <h2 className="mt-1 h6 border-bottom">Typed Data Fields</h2>
      )}
      <div className={styles.fieldContainer}>
        {typedMeta.map((t) => (
          <ThingFieldEntry
            disabled={disableInteraction}
            key={t[0]}
            fieldKey={t[0]}
            value={t[1]}
            onSelect={onSelectField}
          />
        ))}
      </div>
      {meta.length > 0 && <h2 className="h6 border-bottom">Data Fields</h2>}
      <div className={styles.fieldContainer}>
        {meta.map((t) => (
          <ThingFieldEntry
            disabled={disableInteraction}
            fieldKey={t[0]}
            key={t[0]}
            value={t[1]}
            onSelect={onSelectField}
          />
        ))}
      </div>
    </>
  );
}
