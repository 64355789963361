import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { useResize } from '@/common/hooks';
import useToasts from '@/common/hooks/useToasts';
import { ROUTES } from '@/common/routes';
import CatalogListRecoilSelector from '@/components/Composed/CatalogListSelector/CatalogListRecoilSelector';
import Scanner, { SCAN_MODES } from '@/components/Composed/Scanner/Scanner';
import { combineClass } from '@/components/Library/utility';
import { Mixpanel } from '@/mPanel';
import { MESSAGES } from '@/services/requests/constants';
import { selectedCatalogAtom } from '@/state/atoms/catalog';

const HORIZONTAL_LAYOUT_MIN_WIDTH = 720;

export default function Scan() {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  // Used for calculating size of the page for column or row layout
  const { resizeRef: contentRef, width: contentWidth } = useResize();
  const direction =
    HORIZONTAL_LAYOUT_MIN_WIDTH > contentWidth ? 'column' : 'row';

  const catalogValue = useRecoilValue(selectedCatalogAtom);

  const onSuccess = (result: any) => {
    if (result.thingUuid) {
      addToast(MESSAGES.SCAN.SUCCESS, 'success');
      navigate(ROUTES.THING(result.thingUuid as string));
    } else {
      addToast(MESSAGES.SCAN.ORPHANED_DUST_TAG, 'error');
    }
  };

  const handleTrackSelectCatalog = useCallback(
    (catalog: null | Catalog, prevCatalog: null | Catalog) => {
      Mixpanel.track('Scan Select Catalog', {
        'Catalog ID': catalog?.uuid ?? null,
        'Previous Catalog ID': prevCatalog?.uuid ?? null,
      });
    },
    [],
  );

  return (
    <div
      className={combineClass(
        'page-container gap-1 h-full',
        direction === 'column' ? 'flex-col' : 'flex-row',
      )}
      ref={contentRef}
    >
      <div style={{ maxWidth: 400, minWidth: 300 }}>
        <h3 className="h3 mb-1">1. Select a Catalog</h3>
        <CatalogListRecoilSelector
          onSelectCatalog={handleTrackSelectCatalog}
          sx={{ maxWidth: '30rem' }}
        />
      </div>
      <div className="flex-1 flex-col overflow-hidden gap-1">
        <h3 className="h3">2. Scan DUST Tag</h3>
        <div
          className="overflow-hidden"
          style={{
            maxWidth: '2000px',
            flexBasis: '100%',
          }}
        >
          <Scanner
            catalogUuid={catalogValue?.uuid ?? null /* null disables scanner */}
            mode={SCAN_MODES.IDENTIFY}
            onSuccess={onSuccess}
          />
        </div>
      </div>
    </div>
  );
}
