import {
  ThingMetadataObject,
  ThingTypedMetadataObject,
  TypedFileEntry,
} from '@/common/entities/things/typedefs';
import { isWordMatch } from '@/common/utility';

import {
  RESTRICTED_THING_FIELDS,
  ACCEPTED_IMAGE_TYPE_LIST,
  ACCEPTED_FILE_TYPE_LIST,
} from './constants';

/** Filter out fields with a restricted name. Will check for typed fields */
export function filterRestrictedFields<
  T extends ThingMetadataObject | ThingTypedMetadataObject,
>(metadata: T) {
  const filtered: Record<string, T[keyof T]> = {};

  Object.keys(metadata).forEach((field: keyof typeof metadata & string) => {
    // Check if the field key or name matches a restricted field (case insensitive)
    const isRestricted = Object.values(RESTRICTED_THING_FIELDS).some(
      (restrictedField) => {
        const entry = metadata[field];
        return isWordMatch(
          restrictedField,
          typeof entry === 'string' ? field : entry.name,
        );
      },
    );

    if (!isRestricted) filtered[field] = metadata[field];
  });

  return filtered;
}

export function getTypedDataFields(thing: Thing) {
  return Object.entries(filterRestrictedFields(thing.typedMetadata)).filter(
    (e) => e[1].type === 'string',
  );
}

/** Filter a list of thing files by type */
export function getFilesWithType(
  mimeList: readonly string[],
  files: ThingFile[] = [],
) {
  const fileTypes = mimeList.map((mime) => mime.split('/')[1]);
  return files.filter(
    (file) =>
      mimeList.includes(file.fileType) || fileTypes.includes(file.fileType),
  );
}

/** Filter only images from the thing file list */
export function filterImages(files: ThingFile[]) {
  return getFilesWithType(ACCEPTED_IMAGE_TYPE_LIST, files);
}

/** Filter only non-images from the thing file list */
export function filterFiles(files: ThingFile[]) {
  return getFilesWithType(ACCEPTED_FILE_TYPE_LIST, files);
}

/** Map form data to API requirements for thing creation */
export function thingFromForm(formData: AddThingForm): AddThingSubmission {
  const metadata = Object.fromEntries(
    formData.metadata.map((meta) => [meta.name, meta.value]),
  );
  const typedMetadata = Object.fromEntries(
    formData.typedMetadata.map((meta) => [meta.uuid, meta.value]),
  );

  if (formData.thingTypeUuid) {
    if (!formData?.titleUuid) {
      throw new Error('Missing title ID in form');
    }
    // Assign the title value if provided
    typedMetadata[formData.titleUuid] =
      formData.computedTitle ?? formData.title;
  }

  // Modify format for types things
  const thing = formData.thingTypeUuid
    ? {
        thingTypeUuid: formData.thingTypeUuid,
        metadata,
        typedMetadata,
      }
    : {
        title: formData.title,
        metadata,
        typedMetadata,
      };

  return {
    thing,
    catalogUuid: formData.catalogUuid,
    typedFiles:
      formData.primaryImage && formData.thingTypeUuid
        ? [
            ...formData.typedFiles,
            {
              ...formData.primaryImageField,
              value: formData.primaryImage,
            } as TypedFileEntry,
          ]
        : formData.typedFiles,
    files: Object.values(formData.files.value),
    primaryImage: formData.primaryImage,
  };
}

export const getTreeDataPath = (thing: Thing, things: Thing[]): string[] => {
  if (!thing.hasParent) {
    return [thing.uuid];
  }
  const parent = things.find(
    (t) => t.uuid === thing.relationships?.parent?.uuid,
  );
  if (!parent) {
    return [thing.uuid];
  }
  return [...getTreeDataPath(parent, things), thing.uuid];
};
