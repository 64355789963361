import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

// Type + config safety check
if (!import.meta.env.VITE_USER_AUTHORITY)
  throw new Error('No VITE_USER_AUTHORITY was provided - cannot login');

const authority = import.meta.env.VITE_USER_AUTHORITY;

// Note on times: access_token expires after 5 minutes (300 seconds)
export const SESSION_TIMEOUT_WARN = 90;

// NOTE: docs for UserManager are here:
// https://authts.github.io/oidc-client-ts/classes/UserManager.html
//
// *** Docs also work for most methods on `useAuth()` throughout the app.

export const userManager = new UserManager({
  authority,
  client_id: 'dice-web',
  automaticSilentRenew: false,
  accessTokenExpiringNotificationTimeInSeconds: SESSION_TIMEOUT_WARN,
  // In practice, ProtectedRoute's auto-signin can override this
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  // Default is sessionStorage, using localStorage for tests and immediate app renavigations
  userStore: new WebStorageStateStore({ store: window.localStorage }),
});

export const knownAuthSearchParamKeys = ['state', 'session_state', 'code'];

export const userProfileHref = `${authority}/profile`;
