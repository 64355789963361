import { useMemo, useState, useCallback } from 'react';

import { atom, useRecoilState } from 'recoil';

import { ExportBatchPlaceholder } from '@/common/entities/exportBatches/typedefs';
import useShoppingCartData from '@/common/entities/shoppingCarts/useShoppingCartData';
import useRequest from '@/services/requests/useRequest';

import { chunkArray } from '../utility';

export const CHECKOUT_STEPS = {
  CART: 'cart',
  CONFIRM: 'confirm',
} as const;
type CheckoutStep = (typeof CHECKOUT_STEPS)[keyof typeof CHECKOUT_STEPS];

export const EXPORT_STEPS = {
  CONFIRM: 'confirm',
  DOWNLOAD_PROGRESS: 'download-progress',
} as const;
type ExportStep = (typeof EXPORT_STEPS)[keyof typeof EXPORT_STEPS];

type AddThingsProps = {
  things?: Thing[];
  catalogUuids?: string[];
  suppressToast?: boolean;
  onSuccess?: () => void;
  onError?: () => void;
  bypassRelationshipCheck?: boolean;
};

type RemoveThingsProps = {
  things?: Thing[];
  thingUuids?: string[];
  catalogUuids?: string[];
  onSuccess?: () => void;
  onError?: () => void;
  bypassRelationshipCheck?: boolean;
};

// ---------------------------------------------------------------------------
// Recoil is used so that all components using the hook can access the same state
// State is defined here as it is scoped to this hook only (atoms should not be used elsewhere)
const exportModalStepAtom = atom<ExportStep>({
  key: 'export-modal-step',
  default: EXPORT_STEPS.DOWNLOAD_PROGRESS,
});

const exportModalBatchAtom = atom<ExportBatch | null>({
  key: 'export-modal-batch',
  default: null,
});

const exportBatchErrorAtom = atom<string | null>({
  key: 'export-modal-batch-error',
  default: null,
});

const exportModalBatchPlaceholderAtom = atom<ExportBatchPlaceholder | null>({
  key: 'export-modal-batch-placeholder',
  default: null,
});

const cartOpenAtom = atom({
  key: 'checkout-cart-open',
  default: false,
});

const cartRemovingAllAtom = atom({
  key: 'checkout-cart-removing',
  default: false,
});

const checkoutStepAtom = atom<CheckoutStep>({
  key: 'checkout-step',
  default: CHECKOUT_STEPS.CART,
});

const relationshipWarningOptionsAtom = atom<AddThingsProps | null>({
  key: 'relationship-warning-options',
  default: null,
});

const relationshipRemoveWarningOptionsAtom = atom<AddThingsProps | null>({
  key: 'relationship-warning-remove-options',
  default: null,
});

/** Hook for access to all checkout functionality and state */
export default function useCheckout() {
  const [isCartOpen, setIsCartOpen] = useRecoilState(cartOpenAtom);
  const [isRemovingAll, setIsRemovingAll] = useRecoilState(cartRemovingAllAtom);

  // Property to watch data mutations like adding/removing
  const [isCommitting, setIsCommitting] = useState(false);

  const [checkoutStep, setCheckoutStep] = useRecoilState(checkoutStepAtom);
  const [exportModalStep, setExportModalStep] =
    useRecoilState(exportModalStepAtom);
  const [exportBatch, setExportBatch] = useRecoilState(exportModalBatchAtom);
  const [exportBatchPlaceholder, setExportBatchPlaceholder] = useRecoilState(
    exportModalBatchPlaceholderAtom,
  );
  const [exportBatchError, setExportBatchError] =
    useRecoilState(exportBatchErrorAtom);
  const [relationshipWarningOptions, setRelationshipWarningOptions] =
    useRecoilState(relationshipWarningOptionsAtom);
  const [
    relationshipRemoveWarningOptions,
    setRelationshipRemoveWarningOptions,
  ] = useRecoilState(relationshipRemoveWarningOptionsAtom);

  const { shoppingCartsApi, exportBatchesApi } = useRequest();
  const { shoppingCart, isLoading, isError, isSuccess } = useShoppingCartData(
    {},
  );

  /** Add things to the shopping cart
   * NOTE: When adding Things (as opposed to catalogs) you must have requested the things 'relationships' fields
   * Otherwise, we can't be sure whether or not the thing has any relationships and warn the user about it.
   */
  const addThings = useCallback(
    async (options: AddThingsProps) => {
      if (isLoading || isCommitting) {
        return null;
      }
      if (
        options.things &&
        options.things.some((t) => t.hasRelationships) &&
        !options.bypassRelationshipCheck
      ) {
        setRelationshipWarningOptions(options);
        return null;
      }

      setIsCommitting(true);
      const res = await shoppingCartsApi.addThings({
        ...options,
        ...(options.things && {
          thingUuids: options.things.map((t) => t.uuid),
        }),
      });
      setIsCommitting(false);
      setRelationshipWarningOptions(null);
      if (res.error) {
        options?.onError?.();
      } else {
        options?.onSuccess?.();
      }
      return null;
    },
    [isLoading, isCommitting, shoppingCartsApi, setRelationshipWarningOptions],
  );

  const cancelAddThings = useCallback(() => {
    setRelationshipWarningOptions(null);
  }, [setRelationshipWarningOptions]);

  /** Remove things from the shopping cart */
  const removeThings = useCallback(
    async (options: RemoveThingsProps) => {
      if (isLoading || isCommitting) {
        return null;
      }
      if (
        options.things &&
        options.things.some((t) => t.hasRelationships) &&
        !options.bypassRelationshipCheck
      ) {
        setRelationshipRemoveWarningOptions(options);
        return null;
      }
      setIsCommitting(true);
      const res = await shoppingCartsApi.removeThings({
        ...options,
        ...(options.things && {
          thingUuids: options.things.map((t) => t.uuid),
        }),
      });
      setRelationshipRemoveWarningOptions(null);
      setIsCommitting(false);

      return res.error ? options?.onError?.() : options?.onSuccess?.();
    },
    [
      isLoading,
      isCommitting,
      shoppingCartsApi,
      setRelationshipRemoveWarningOptions,
    ],
  );

  const cancelRemoveRelationship = useCallback(() => {
    setRelationshipRemoveWarningOptions(null);
  }, [setRelationshipRemoveWarningOptions]);

  const apiRemoveAll = useCallback(
    async () =>
      Promise.all(
        chunkArray(shoppingCart.thingUuids, 100).map((thingUuids) =>
          removeThings({ thingUuids }),
        ),
      ),
    [shoppingCart.thingUuids, removeThings],
  );

  /** Complete checkout process */
  const completeCheckout = useCallback(
    async (options: { uuid?: string; name: string; description: string }) => {
      const { data, error: createError } = await exportBatchesApi.create(
        options,
      );
      if (!createError) {
        await apiRemoveAll();
      }
      setCheckoutStep(CHECKOUT_STEPS.CART);

      if (!data || createError || data.schema_errors) {
        setExportBatchError('Error creating checkout. Please try again.');
        setExportBatchPlaceholder(null);
        return false;
      }
      const { data: checkoutResult, error: checkoutError } =
        await exportBatchesApi.checkOut({
          uuid: data.uuid,
        });
      if (!checkoutResult || checkoutError || checkoutResult.schema_errors) {
        setExportBatchError('Error completing checkout. Please try again.');
        setExportBatchPlaceholder(null);
        return false;
      }
      setExportBatch(checkoutResult);
      return checkoutResult;
    },
    [
      apiRemoveAll,
      exportBatchesApi,
      setExportBatch,
      setExportBatchError,
      setExportBatchPlaceholder,
      setCheckoutStep,
    ],
  );

  /** Regenerate export */
  const regenerate = useCallback(
    async (uuid: string) => {
      if (isLoading || isCommitting) {
        return null;
      }
      setIsCommitting(true);
      const result = await exportBatchesApi.regenerate(uuid);
      setIsCommitting(false);
      return result.error ? null : result.data;
    },
    [isLoading, isCommitting, exportBatchesApi],
  );

  /** Remove all things from the cart */
  const removeAll = async () => {
    setIsRemovingAll(true);
    // TODO: DICE3-954 - Removing things using list of catalog UUIDs only removes the first catalog in the list
    // Replace the below Promise.all with this next line
    // await checkout.removeThings({ catalogUuids: shoppingCart.catalogUuids });
    await apiRemoveAll();
    setIsRemovingAll(false);
  };

  /** Wrapper for all cart panel state */
  const cartPanel = useMemo(
    () => ({
      open: isCartOpen,
      setOpen: setIsCartOpen,
      toggleOpen: () => setIsCartOpen(!isCartOpen),
      isRemovingAll,
      step: checkoutStep,
      setStep: setCheckoutStep,
    }),
    [isCartOpen, isRemovingAll, checkoutStep, setCheckoutStep, setIsCartOpen],
  );

  /** Wrapper for export modal state */
  const exportModal = useMemo(
    () => ({
      initializeExportModal: async ({
        newExportBatchPlaceholder,
        step,
      }: {
        newExportBatchPlaceholder: ExportBatchPlaceholder | null;
        step: ExportStep;
      }) => {
        setExportBatch(null);
        setExportBatchPlaceholder(newExportBatchPlaceholder);
        setExportModalStep(step);
        setExportBatchError(null);
        if (
          newExportBatchPlaceholder?.uuid &&
          step === EXPORT_STEPS.DOWNLOAD_PROGRESS
        ) {
          const newExportBatch = await regenerate(
            newExportBatchPlaceholder.uuid,
          );
          if (!newExportBatch) {
            setExportBatchError('Error regenerating export. Please try again.');
          } else {
            setExportBatch(newExportBatch);
          }
        }
      },
      isExportModalOpen: !!exportBatchPlaceholder,
      closeExportModal: () => {
        setExportBatchPlaceholder(null);
        setExportBatch(null);
      },
      setExportBatch,
      step: exportModalStep,
      setStep: setExportModalStep,
      exportBatch,
      exportBatchPlaceholder,
      exportBatchError,
    }),
    [
      regenerate,
      exportModalStep,
      exportBatch,
      setExportBatch,
      exportBatchError,
      setExportBatchError,
      exportBatchPlaceholder,
      setExportBatchPlaceholder,
      setExportModalStep,
    ],
  );

  return {
    checkout: {
      addThings,
      cancelAddThings,
      removeThings,
      cancelRemoveRelationship,
      removeAll,
      completeCheckout,
      regenerate,
    },
    exportBatch,
    shoppingCart,
    isLoading,
    isError,
    isSuccess,
    isCommitting,
    cartPanel,
    exportModal,
    relationshipWarningOptions,
    relationshipRemoveWarningOptions,
  };
}
