import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ExportBatch } from '@/common/entities/exportBatches/typedefs';
import DustModal from '@/components/Library/DustModal';
import DustProgressLoader from '@/components/Library/DustProgressLoader';

import styles from './CheckIn.module.css';

type CheckInProgressModalProps = {
  exportBatch: ExportBatch;
  checkInSuccess: boolean;
  isError: boolean;
  onComplete: () => void;
};

const STATUS = {
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS',
};

type Status = (typeof STATUS)[keyof typeof STATUS];

type IconsObject = {
  [key: Status]: JSX.Element;
};

const icons: IconsObject = {
  [STATUS.SUCCESS]: (
    <CheckCircleOutlineIcon
      color="success"
      sx={{ width: 50, height: 50, margin: '1rem 0 2rem' }}
    />
  ),
  [STATUS.PROCESSING]: (
    <DustProgressLoader className={styles.checkInIcon} height={50} width={50} />
  ),
  [STATUS.ERROR]: (
    <ErrorOutlineIcon
      color="error"
      sx={{ width: 50, height: 50, margin: '1rem 0 2rem' }}
    />
  ),
};

const titles = {
  [STATUS.PROCESSING]: 'Check In Progress',
  [STATUS.SUCCESS]: 'Check In Complete',
  [STATUS.ERROR]: 'Check In Error',
};

function CheckInProgressModal({
  exportBatch,
  checkInSuccess,
  isError,
  onComplete,
}: CheckInProgressModalProps) {
  const status: Status =
    (isError && STATUS.ERROR) ||
    (checkInSuccess && STATUS.SUCCESS) ||
    STATUS.PROCESSING;

  const thingsCount = exportBatch.importValidation.things.length;
  const catalogsCount = exportBatch.importValidation.catalogs.length;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <DustModal
      open
      title={titles[status]}
      footerProps={{
        disabled: status === STATUS.PROCESSING,
        onSubmit: onComplete,
        submitLabel: 'Back to Catalogs',
      }}
    >
      <div className="flex-col items-center w-full">
        <h4 className="h4 text-center" style={{ margin: '2rem 3rem 0' }}>
          {exportBatch.name}
        </h4>
        <div className="text-sm p-1 flex-row gap-1">
          <span>
            Qty: <strong>{thingsCount}</strong>
          </span>
          <span>
            Catalogs: <strong>{catalogsCount}</strong>
          </span>
        </div>
        {icons[status]}
        <p className="p-2">Description: {exportBatch.description}</p>
      </div>
    </DustModal>
  );
}

export default CheckInProgressModal;
