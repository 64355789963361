import PropTypes from 'prop-types';

import styles from './Components.module.css';
import { combineClass } from './utility';

function DustTableSimple({ rows, className = '' }) {
  return (
    <table className={combineClass(styles.tableSimple, className)}>
      <tbody>
        {rows.map(([head, ...data], trIndex) => (
          <tr
            className={trIndex % 2 === 1 ? styles.tableAltRow : styles.tableRow}
            // eslint-disable-next-line react/no-array-index-key
            key={`${head}${trIndex}`}
          >
            <th className={styles.tableCell}>{head}</th>
            {data.map((datum, tdIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <td className={styles.tableCell} key={`${datum}${tdIndex}`}>
                {datum}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default DustTableSimple;

DustTableSimple.propTypes = {
  rows: PropTypes.array,
  className: PropTypes.string,
};
