import { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export default function useOnClickOutside(
  ref: React.RefObject<HTMLElement>,
  onClickOutside: (...args: any[]) => void,
) {
  // SEE: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
  const onClickOutsideRef = useRef(onClickOutside);
  onClickOutsideRef.current = onClickOutside;

  useEffect(() => {
    // Handler
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        !ref.current.contains(
          event.target as Node /* Just checking equality, and EventTarget can be a Node */,
        )
      ) {
        onClickOutsideRef.current?.();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
