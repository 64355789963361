import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 5 * 60 * 1000;

export default function useGroupData({ groupUuid }: { groupUuid: string }) {
  const { groupsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.GROUP(groupUuid),
    () => groupsApi.getGroup(groupUuid),
    { staleTime: STALE_TIME, enabled: !!groupUuid },
  );

  return {
    group: data?.data && !data.error ? data.data : undefined,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
