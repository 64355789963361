import NoRelationsActions from './NoRelationsActions';
import ThingRelationshipsTable from './ThingRelationshipsTable';

type Props = {
  thing: Thing;
};

export default function ThingRelationships({ thing }: Props) {
  if (!thing.hasRelationships) {
    return (
      <div className="flex-col items-center h-full">
        <h4 className="h4 mb-1 mt-2">
          &ldquo;{thing.title}&rdquo; has no relationships.
        </h4>
        <NoRelationsActions thing={thing} gapClassName="gap-2" />
      </div>
    );
  }

  /** Key: we want to be sure that if we navigate to a different
   * thing from this sub-tab, all state specific to this tab is
   * forgotten (including data grid state), and recursive events are
   * re-subscribed */
  return <ThingRelationshipsTable key={thing.uuid} thing={thing} />;
}
