import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 5 * 60 * 60;
export const DEFAULT_FIELDS = ['mediaLinks', 'metadata', 'schema'];
export const combineFields = (additional: string[]) => [
  ...DEFAULT_FIELDS,
  ...additional,
];

type Params = {
  thingUuid: string;
  additionalFields?: string[];
  enabled?: boolean;
};

export default function useThingData({
  thingUuid,
  additionalFields = [],
  enabled = true,
}: Params) {
  const { thingsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    [...QUERY_KEYS.THING(thingUuid), combineFields(additionalFields)],
    () =>
      thingsApi.getThing({
        uuid: thingUuid,
        fields: combineFields(additionalFields),
      }),
    { enabled, staleTime: STALE_TIME },
  );

  return {
    thing: data && !data.error ? data.data : undefined,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
