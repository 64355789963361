import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';

import useToasts from '@/common/hooks/useToasts';
import { extractUserOrgs } from '@/services/utility';
import { selectedFacilityAtom } from '@/state/atoms/facility';
import { geolocationAtom } from '@/state/atoms/geolocation';
import { ProfileOrgs } from 'typings/auth-user';

import ApiService from './ApiService';
import { makeRequestContext, RequestContext } from './context';

type Props = { children: React.ReactNode };

const ORG_ID_KEY = 'orgIdPreference';

/** Choose a usable org based on selection and available orgs, `null` indicates
 * a selection is required. */
function decideApiOrgId(_selectedOrgId: string | null, tokenOrgs: ProfileOrgs) {
  const availableOrgIds = Object.keys(tokenOrgs);
  // Disable org selection for multi-org users
  // TMP disable - if (selectedOrgId && availableOrgIds.includes(selectedOrgId)) {
  // TMP disable -   // Guard against invalid org selections
  // TMP disable -   return selectedOrgId;
  // TMP disable - }
  if (availableOrgIds.length === 1) {
    // If no org selected, but only one org available, auto-default to it.
    return availableOrgIds[0];
  }

  return null;
}

const apiService = new ApiService(import.meta.env.VITE_API_URL);

export default function RequestContextProvider({ children }: Props) {
  const auth = useAuth();
  const queryClient = useQueryClient();

  const [knownSelectedOrgId, setKnownSelectedOrgId] = useState<
    undefined | null | string
  >();
  const [currentOrgId, setCurrentOrgId] = useState<undefined | null | string>();

  useEffect(() => {
    // TODO: request/read knownSelectedOrgId using request api
    // null indicates no selection
    setKnownSelectedOrgId(localStorage.getItem(ORG_ID_KEY) ?? null);
  }, [auth]);

  useEffect(() => {
    if (auth.isLoading || knownSelectedOrgId === undefined) return;
    const userOrgs = extractUserOrgs(auth);
    if (userOrgs) {
      setCurrentOrgId(decideApiOrgId(knownSelectedOrgId, userOrgs));
    }
  }, [auth, knownSelectedOrgId]);

  // Always call to ensure this gets set before any query state changes
  apiService.setAccessToken(
    (auth.isAuthenticated && auth.user?.access_token) || null,
  );

  const [facilityUuid, setFacilityUuid] = useRecoilState(selectedFacilityAtom);
  useEffect(() => {
    apiService.setFacilityUuid(facilityUuid);
  }, [facilityUuid]);

  const clearFacility = useCallback(() => {
    setFacilityUuid(null);
  }, [setFacilityUuid]);

  useEffect(() => {
    apiService.setClearFacility(clearFacility);
  }, [clearFacility]);

  const location = useRecoilValue(geolocationAtom);
  useEffect(() => {
    apiService.setGeolocation(location);
  }, [location]);

  const { addToast } = useToasts();

  const contextValue = useMemo(
    () => {
      function setOrgId(orgId: string) {
        localStorage.setItem(ORG_ID_KEY, orgId);
        // When changing org id, simply reload to avoid worrying about stale
        // state or cached recoil data.
        window.location.assign('/');
      }
      // Similarly to access token, ensure org id is current in the api.
      if (currentOrgId !== undefined) {
        apiService.setOrgId(currentOrgId);
      }
      return makeRequestContext(
        apiService,
        queryClient,
        addToast,
        auth.user?.profile.sub ?? null,
        setOrgId,
      );
    },
    // [orgId] signals that orgId should re-build from apiService
    [currentOrgId, addToast, queryClient, auth.user],
  );

  return (
    <RequestContext.Provider value={contextValue}>
      {children}
    </RequestContext.Provider>
  );
}
