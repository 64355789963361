import React from 'react';

import { VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridEventListener, GridColDef } from '@mui/x-data-grid';

import { formatDateForDisplay } from '@/common/dates';
import {
  catalogSelectorSortAtom,
  CATALOG_SORT_OPTIONS,
} from '@/common/entities/catalogs/useCatalogsData';
import CatalogThumbnail from '@/components/Composed/CatalogSelector/CatalogThumbnail';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustFavoriteIcon from '@/components/Library/Icons/DustFavoriteIcon';
import useSort from '@/services/useSort';

import styles from './Catalog.module.css';
import CatalogMenu from './CatalogMenu';

type Props = {
  catalogs: Catalog[];
  onRowClick: (e: Catalog) => void;
  onFavorite: (e: Catalog) => void;
  isLoading?: boolean;
  totalItems?: number;
  getNextPage?: () => any;
  onHide?: (e: Catalog) => void;
};

export default function CatalogsTable({
  catalogs,
  onRowClick,
  onFavorite,
  isLoading,
  totalItems,
  getNextPage,
  onHide,
}: Props) {
  const { applyColumnSortProps, tableSortProps } = useSort(
    catalogSelectorSortAtom,
    CATALOG_SORT_OPTIONS,
  );
  const handleFavorite = React.useCallback(
    (catalog: Catalog): React.MouseEventHandler<HTMLButtonElement> =>
      (evt) => {
        evt.stopPropagation();
        onFavorite(catalog);
      },
    [onFavorite],
  );

  const handleHide = React.useCallback(
    (catalog: Catalog): React.MouseEventHandler<HTMLButtonElement> =>
      (evt) => {
        evt.stopPropagation();
        if (onHide) {
          onHide(catalog);
        }
      },
    [onHide],
  );

  const hiddenCatalogsExist = React.useMemo(
    () => catalogs.some((catalog) => catalog.hidden),
    [catalogs],
  );

  const columns: GridColDef<Catalog>[] = React.useMemo(
    () =>
      applyColumnSortProps([
        {
          valueGetter: ({ row }) => row.uuid,
          field: 'id',
          headerName: 'UUID',
          hide: true,
        },
        {
          valueGetter: ({ row }) => row,
          field: 'thumbnail',
          minWidth: 64,
          maxWidth: 64,
          headerName: '',
          renderCell: ({ formattedValue: catalog }) => (
            <CatalogThumbnail catalog={catalog as Catalog} />
          ),
          flex: 1,
        },
        {
          field: 'name',
          headerName: 'Name',
          flex: 1,
        },
        {
          field: 'size',
          headerName: 'Things',
          flex: 1,
        },
        {
          valueFormatter: ({ value }) => formatDateForDisplay(value),
          field: 'createdAt',
          headerName: 'Created at',
          flex: 1,
          sortingOrder: ['desc', 'asc'],
        },
        {
          valueFormatter: ({ value }) => formatDateForDisplay(value),
          field: 'updatedAt',
          headerName: 'Modified',
          flex: 1,
          sortingOrder: ['desc', 'asc'],
        },
        {
          valueGetter: ({ row }) => row,
          renderCell: ({ formattedValue: catalog }) => (
            <>
              <IconButton onClick={handleFavorite(catalog)}>
                <DustFavoriteIcon favorite={!!catalog.favorite} />
              </IconButton>
              {catalog.hidden ? (
                <IconButton onClick={handleHide(catalog)}>
                  <VisibilityOff fontSize="small" />
                </IconButton>
              ) : (
                hiddenCatalogsExist && <div className={styles.hiddenSpacer} />
              )}
              <CatalogMenu catalog={catalog} />
            </>
          ),
          field: 'actions',
          headerName: '',
          flex: 1,
        },
      ]),
    [applyColumnSortProps, handleFavorite, handleHide, hiddenCatalogsExist],
  );

  const handleSelect: GridEventListener<'rowClick'> = (row) => {
    const catalog = catalogs?.find((c) => c?.uuid === row.id);

    if (catalog) onRowClick(catalog);
  };

  return (
    <DustDataGrid
      altTheme
      columns={columns}
      initialPageSize={5}
      onRowClick={handleSelect}
      rows={catalogs}
      getRowId={(row) => row.uuid}
      hideFooterPagination
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tableSortProps}
      loading={isLoading}
      rowCount={totalItems}
      onRowsScrollEnd={getNextPage}
      getRowClassName={({ row }) => (row.hidden ? styles.hiddenCatalogRow : '')}
    />
  );
}
