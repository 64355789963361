import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    success: {
      main: '#1EB871',
      dark: '#1ba967',
      contrastText: '#fff',
    },
    secondary: {
      light: '#b1b1b1',
      main: '#828282',
      dark: '#565656',
      contrastText: '#fff',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // backgroundColor: 'var(--primary-blue)',
        },
        arrow: {
          // color: 'var(--primary-blue)',
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButton: {
      // The props to change the default for on the application.
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {},
        sizeMedium: {
          height: '2.5rem',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& fieldset>legend': {
            // minHeight: '11px',
            height: '11px',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {},
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
        },
        label: {
          fontSize: 'inherit',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
          color: 'var(--color-text-primary)',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          fontSize: '.875rem',
          color: 'var(--color-text-secondary)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 'inherit',
          color: 'var(--color-text-secondary)',
          '&:hover': {
            color: 'var(--color-text-primary)',
            backgroundColor: 'var(--light-blue)',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 10, // Need to override so that scanner menu shows overtop
        },
      },
    },
  },
});

function DustTheme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default DustTheme;
