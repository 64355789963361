/**
 * Check all filters match default values or just check one filter property
 */
export function isFilterDefaulted<T extends Record<string, unknown>>(
  /** if checking all filter values use object; otherwise one filter property is string */
  currentValue: unknown,
  defaultFilters: T,
  /** value of one filter */
  filterKey: keyof T,
): boolean {
  const defaultValue = defaultFilters[filterKey];

  // If array values are provided check deep equality
  if (Array.isArray(currentValue) && Array.isArray(defaultValue)) {
    return currentValue.every((value, idx) => value === defaultValue[idx]);
  }

  // Check for standard equality
  if (['boolean', 'number'].includes(typeof currentValue)) {
    return currentValue === defaultValue;
  }

  const tryDate = new Date(currentValue as string | number | Date).getTime();
  // Try to parse a date and compare. WARNING: If there are single string filters added this could parse a date
  if (!Number.isNaN(tryDate)) {
    return (
      tryDate === new Date(defaultValue as string | number | Date).getTime()
    );
  }

  // Otherwise just check equality (strings that cannot be parsed as a date)
  return currentValue === defaultValue;
}

/**
 * Return a list of filter keys that have been changed from the default
 */
export function getChangedFilters<T extends Record<string, unknown>>(
  filters: T,
  defaultFilters: T,
): (keyof T)[] {
  const changedItems: Array<keyof T> = [];
  Object.keys(filters).forEach((key) => {
    if (!isFilterDefaulted(filters[key], defaultFilters, key)) {
      changedItems.push(key);
    }
  });
  return changedItems;
}
