import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

function DustTableCellTooltip({ title = null }) {
  if (title) {
    return (
      <Tooltip title={title}>
        {/* We use an inline style declaration here because there are styles from the table component that will override a class https://github.com/mui/mui-x/issues/3587 */}
        <span
          className="table-cell-trucate"
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {title}
        </span>
      </Tooltip>
    );
  }
  return null;
}

export function renderTooltipCell({ formattedValue: value = null }) {
  return <DustTableCellTooltip title={value} />;
}

DustTableCellTooltip.propTypes = {
  title: PropTypes.node,
};

export default DustTableCellTooltip;
