import { useEffect, useState } from 'react';

import { MenuItem } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';

import useFacilitiesData from '@/common/entities/facilities/useFacilitiesData';
import DustLoader from '@/components/Library/DustLoader';
import DustModal from '@/components/Library/DustModal';
import DustSelect from '@/components/Library/DustSelect';
import {
  ignoreFacilityAtom,
  selectedFacilityAtom,
} from '@/state/atoms/facility';

type Props = {
  open: boolean;
  onSuccess: () => void;
  onDismiss: () => void;
};

export default function FacilityModal({ open, onSuccess, onDismiss }: Props) {
  const { isLoading, facilities } = useFacilitiesData({});

  const [facilityUuid, setFacility] = useRecoilState(selectedFacilityAtom);
  const setIgnoreFacility = useSetRecoilState(ignoreFacilityAtom);
  const [selectedValue, setSelectedValue] = useState(facilityUuid ?? '');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (selectedValue) setErrorMessage('');
  }, [selectedValue]);

  function handleSave() {
    if (selectedValue) {
      setErrorMessage('');
      setFacility(selectedValue);
      setIgnoreFacility(false);
      onSuccess();
    } else {
      setErrorMessage('Facility is required');
    }
  }

  return (
    <DustModal
      footerProps={{
        onCancel: onDismiss,
        onSubmit: handleSave,
        submitLabel: 'Set Facility',
      }}
      onClose={onDismiss}
      open={open}
      title="Facility"
    >
      <>
        {isLoading && <DustLoader size="medium" />}
        {!isLoading && (
          <>
            <h2 className="h6" style={{ marginBottom: '1rem' }}>
              Select your current facility
            </h2>
            <DustSelect
              error={!!errorMessage}
              helperText={errorMessage}
              label="Facility"
              onChange={(event) => setSelectedValue(event.target.value)}
              value={selectedValue}
            >
              {facilities.map((facility) => (
                <MenuItem key={facility.uuid} value={facility.uuid}>
                  {facility.name}
                </MenuItem>
              ))}
            </DustSelect>
          </>
        )}
      </>
    </DustModal>
  );
}
