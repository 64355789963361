import { catalogModel } from '@/common/entities/catalogs/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import { HTTP_RESPONSE_CODES, MESSAGES } from '@/services/requests/constants';
import { DustArrayResponse } from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIReturn,
  formatAPIError,
  formatPagedApiReturn,
} from '@/services/utility';

/** Factory function for producing the catalogs API */
export default class CatalogsApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Create a new catalog */
  async create({
    name,
    imageMediaUuid,
    isMobile,
  }: {
    name: string;
    imageMediaUuid?: string | null;
    isMobile?: boolean;
  }) {
    return this.apiService
      .request({
        url: 'api/catalogs',
        method: 'POST',
        data: {
          name,
          imageMediaUuid,
          ...(isMobile !== undefined && { isMobile }),
          testbogus: undefined,
        },
      })
      .then((res) => {
        this.invalidate.catalogs();
        this.addToast(MESSAGES.CATALOG_CREATE_SUCCESS(name), 'success');
        return formatAPIReturn(res, catalogModel);
      })
      .catch((err) => {
        defaultErrorHandler(this.addToast, (error) => {
          if (
            error.response?.status === HTTP_RESPONSE_CODES.CONFLICT &&
            error.response?.data?.message
          ) {
            this.addToast(error.response.data.message, 'error');
          } else {
            this.addToast(MESSAGES.COMMON.GENERIC, 'error');
          }
        })(err);

        return formatAPIError<{ message: string }>(err);
      });
  }

  /** Favorite a catalog */
  async favorite(uuid: string) {
    return this.apiService
      .request({
        url: `api/catalogs/${uuid}/favorite`,
        method: 'PUT',
      })
      .then((res) => {
        this.invalidate.catalogs();
        return formatAPIReturn(res, catalogModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.CATALOG_UPDATE_FAILED, 'error');
        }),
      );
  }

  /** Get all catalogs */
  async getAll({
    fields,
    name,
    sort,
  }: { fields?: string[]; name?: string; sort?: string } = {}) {
    const args = new URLSearchParams();
    if (fields) {
      args.append('fields', fields.toString());
    }
    if (name) {
      args.append('name', name);
    }
    if (sort) {
      args.append('sort', sort);
    }

    return this.apiService
      .request({
        url: `api/catalogs?${args.toString()}`,
        method: 'GET',
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, catalogModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED);
      });
  }

  /** Search for catalogs */
  async search(params: {
    fields: string[];
    name?: string;
    isFavorite?: boolean;
    isHidden?: boolean;
    page?: number;
    perPage?: number;
    sort?: string;
    isMobile?: boolean;
  }) {
    const { sort = 'createdAt', page = 1, ...otherDataParams } = params;
    return this.apiService
      .request({
        url: 'api/catalogs/search',
        method: 'POST',
        data: removeNil({
          page,
          perPage: 50,
          ...otherDataParams,
          sort: sort ? [sort] : null,
        }),
      })
      .then((res) => formatPagedApiReturn(res, page, catalogModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED);
      });
  }

  /** Get a catalog by uuid */
  async get({ uuid }: { uuid: string }) {
    return this.apiService
      .request({
        url: encodeURI(`/api/catalogs/${uuid}`),
        method: 'GET',
      })
      .then((res) => formatAPIReturn(res, catalogModel))
      .catch((err) => formatAPIError(err));
  }

  /** Hide a catalog */
  async hide(uuid: string) {
    return this.apiService
      .request({
        url: `api/catalogs/${uuid}/hide`,
        method: 'PUT',
      })
      .then((res) => formatAPIReturn(res, catalogModel))
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.CATALOG_UPDATE_FAILED, 'error');
        }),
      )
      .finally(() => this.invalidate.catalogs());
  }

  /** Unfavorite a catalog */
  async unfavorite(uuid: string) {
    return this.apiService
      .request({
        url: `api/catalogs/${uuid}/unfavorite`,
        method: 'PUT',
      })
      .then((res) => formatAPIReturn(res, catalogModel))
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.CATALOG_UPDATE_FAILED, 'error');
        }),
      )
      .finally(() => this.invalidate.catalogs());
  }

  /** Unhide a catalog */
  async unhide(uuid: string) {
    return this.apiService
      .request({
        url: `api/catalogs/${uuid}/unhide`,
        method: 'PUT',
      })
      .then((res) => {
        this.invalidate.catalogs();
        return formatAPIReturn(res, catalogModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.CATALOG_UPDATE_FAILED, 'error');
        }),
      );
  }

  /** Update an existing catalog. Only changed fields need to be in request body */
  async update({
    name,
    imageMediaUuid,
    uuid,
    isMobile,
  }: {
    name: string;
    // posting `null` deletes image association
    imageMediaUuid?: string | null;
    uuid: string;
    isMobile?: boolean;
  }) {
    return this.apiService
      .request({
        url: `api/catalogs/${uuid}`,
        method: 'PUT',
        data: {
          name,
          imageMediaUuid,
          ...(isMobile !== undefined && { isMobile }),
        },
      })
      .then((res) => {
        this.invalidate.catalogs();
        this.addToast(MESSAGES.CATALOG_UPDATE_SUCCESS, 'success');
        return formatAPIReturn(res, catalogModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (
            [
              HTTP_RESPONSE_CODES.BAD_REQUEST,
              HTTP_RESPONSE_CODES.CONFLICT,
            ].includes(err.response?.status as number) &&
            err.response?.data?.message
          ) {
            this.addToast(err.response.data.message, 'error');
          } else {
            this.addToast(MESSAGES.COMMON.GENERIC, 'error');
          }
        }),
      );
  }
}
