import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import PropTypes from 'prop-types';

import styles from './Components.module.css';
import { combineClass } from './utility';

export const SNACKBAR_STATUS = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

const LABEL = {
  [SNACKBAR_STATUS.INFO]: 'Info: ',
  [SNACKBAR_STATUS.SUCCESS]: 'Success: ',
  [SNACKBAR_STATUS.WARNING]: 'Warning: ',
  [SNACKBAR_STATUS.ERROR]: 'Error: ',
};

const COLOR_STYLES = {
  [SNACKBAR_STATUS.ERROR]: {
    '--snackbar-text': 'var(--color-text-error)',
    '--snackbar-bg': 'var(--color-error)',
  },
  [SNACKBAR_STATUS.WARNING]: {
    '--snackbar-text': 'var(--color-text-warning)',
    '--snackbar-bg': 'var(--color-warning)',
  },
  [SNACKBAR_STATUS.INFO]: {
    '--snackbar-text': 'var(--color-text-info)',
    '--snackbar-bg': 'var(--color-info)',
  },
  [SNACKBAR_STATUS.SUCCESS]: {
    '--snackbar-text': 'var(--color-text-success)',
    '--snackbar-bg': 'var(--color-success)',
  },
};

const ICONS = {
  [SNACKBAR_STATUS.INFO]: <InfoOutlinedIcon />,
  [SNACKBAR_STATUS.SUCCESS]: <CheckCircleOutlinedIcon />,
  [SNACKBAR_STATUS.WARNING]: <WarningAmberOutlinedIcon />,
  [SNACKBAR_STATUS.ERROR]: <ErrorOutlineOutlinedIcon />,
};

function DustPageSnackbar({
  label,
  status,
  className = '',
  hideStatus = false,
  children,
}) {
  return (
    <div
      className={combineClass(styles.snackbar, className)}
      style={COLOR_STYLES[status]}
    >
      <div className={styles.snackbarText}>
        {ICONS[status]}
        <span>
          {!hideStatus && <b>{LABEL[status]}</b>}
          {label}
        </span>
      </div>
      {children}
    </div>
  );
}

DustPageSnackbar.propTypes = {
  label: PropTypes.element,
  status: PropTypes.string,
  hideStatus: PropTypes.bool,
  className: PropTypes.string,
};

export default DustPageSnackbar;
