import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import useThingMediaData from '@/common/entities/thingMedia/useThingMediaData';
import useThingTypeData from '@/common/entities/thingTypes/useThingTypeData';
import {
  copyThingType,
  mapFieldFiles,
} from '@/common/entities/thingTypes/utility';
import DustLoader from '@/components/Library/DustLoader';

import AddEditThingType from './AddEditThingType';

function AddThingType() {
  const [searchParams] = useSearchParams();
  const typeIdToCopy = searchParams.get('copy');

  const {
    // TODO: use or remove isLoading: isTypeLoading,
    isSuccess: isTypeSuccess,
    // TODO: use or remove isError: isTypeError,
    thingType,
  } = useThingTypeData({
    thingTypeUuid: typeIdToCopy ?? '',
    enabled: !!typeIdToCopy,
  });

  const uuids =
    thingType?.fieldTypes
      ?.filter((field) => field.value?.type === 'STATIC_MEDIA')
      .map((field) => field.value.value)
      .filter((val) => !!val) ?? [];

  const {
    // TODO: use or remove isLoading: isMediaLoading,
    isSuccess: isMediaSuccess,
    // TODO: use or remove isError: isMediaError,
    thingMedia,
  } = useThingMediaData({ uuids, enabled: isTypeSuccess && uuids.length > 0 });

  const loadingComplete =
    !typeIdToCopy || (isTypeSuccess && (uuids.length === 0 || isMediaSuccess));

  const clonedData = useMemo(() => {
    if (!loadingComplete) return null;
    return copyThingType(thingType, mapFieldFiles(thingType, thingMedia));
  }, [loadingComplete, thingType, thingMedia]);

  return (
    clonedData && (
      <div className="action-container">
        {loadingComplete ? (
          <AddEditThingType
            mediaFiles={clonedData.clonedMedia}
            thingType={clonedData.clonedType}
          />
        ) : (
          <DustLoader size="xlarge" sx={{ marginTop: '5rem' }} />
        )}
      </div>
    )
  );
}

export default AddThingType;
