import { Navigate } from 'react-router-dom';

import useAdminPrivileges from '@/common/entities/users/useAdminPrivileges';
import AppLoadingSpinner from '@/components/Library/AppLoadingSpinner';

import ProtectedRoute from '../ProtectedRoute';

type Props = { children: React.ReactNode };

/* Rendered inside ProtectedRoute, checks admin-specific user
 * information, meant to avoid re-checking basic login, which should be
 * covered by ProtectedRoute already */
function AdminCheckGuard({ children }: Props) {
  const { canAccessAdminPanel, isLoadingAdminPrivileges } =
    useAdminPrivileges();

  // Wait until all required data is loaded
  if (isLoadingAdminPrivileges) {
    return <AppLoadingSpinner />;
  }

  // If there is a user check permissions and redirect if unauthorized
  return canAccessAdminPanel ? (
    // TODO: cast needed until we upgrade to React 18
    (children as JSX.Element)
  ) : (
    <Navigate to="/unauthorized" replace />
  );
}

export default function ProtectedAdminRoute({ children }: Props) {
  return (
    <ProtectedRoute>
      <AdminCheckGuard>{children}</AdminCheckGuard>
    </ProtectedRoute>
  );
}
