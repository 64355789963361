import {
  renameMoveColumn,
  suffixDuplicateFields,
} from '@/common/utilities/table';
import { isWordMatch } from '@/common/utility';

import { ERRORS, FIELD_NAMES } from './constants';

/** @param {string} message */
function errorReturn(message) {
  return {
    error: /** @type {const} */ (true),
    message,
  };
}

function successReturn(value) {
  return {
    error: /** @type {const} */ (false),
    value,
  };
}

export function validateFieldName(fieldName, fields, colIdx) {
  if (
    fields.some((val, idx) => isWordMatch(fieldName, val) && idx !== colIdx)
  ) {
    return errorReturn(ERRORS.DUPLICATE_HEADER);
  }
  if (fieldName.length < 2) {
    return errorReturn(ERRORS.SHORT_HEADER);
  }
  if (fieldName.length > 256) {
    return errorReturn(ERRORS.LONG_HEADER);
  }
  const specialField = Object.values(FIELD_NAMES).find((name) =>
    isWordMatch(name, fieldName),
  );

  return successReturn(specialField ?? fieldName);
}

export function validateFieldValue(value) {
  if (value.length < 2) {
    return errorReturn(ERRORS.SHORT_VALUE);
  }
  if (value.length > 500) {
    return errorReturn(ERRORS.LONG_VALUE);
  }
  return successReturn(value);
}

/**
 * @typedef ValidateResult
 * @property {string} status
 * @property {string} message
 */

/**
 * Check all cells for errors
 *
 * @param   {(string | number)[][]}                       data
 * @param   {string[]}                         headers
 * @returns {Object<string, ValidateResult[]>}
 */
export const validateCells = (data, headers) => {
  /**
   * @type {Object<string, ValidateResult[]>}
   */
  const errors = {};

  // Initialize error object
  headers.forEach((field) => {
    errors[field] = [];
  });

  // Search for errors
  data.forEach((row, rowIdx) => {
    headers.forEach((field, colIdx) => {
      const value = data?.[rowIdx]?.[colIdx];
      errors[field][rowIdx] = { status: null, message: '' };
      // Check title field exists
      if (isWordMatch(field, FIELD_NAMES.TITLE)) {
        if (!value) {
          errors[field][rowIdx] = {
            status: 'error',
            message: 'Title is required.',
          };
        }
      }

      // Check for valid DICE quantity
      if (isWordMatch(field, FIELD_NAMES.DICE_QTY)) {
        const number = Number(value);
        const integer = parseInt(value.toString(), 10);

        if (Number.isNaN(number) || number <= 0 || number !== integer) {
          errors[field][rowIdx] = {
            status: 'error',
            message: 'DICE Quantity must be a positive integer.',
          };
        }
      }
    });
  });

  return errors;
};

/**
 * Get the total count including quantity if available
 *
 * @param   {(string | number)[][]} data
 * @param   {string[]}   headers
 * @returns {number}
 */
export const checkTotalQuantity = (data, headers) => {
  const qtyIdx = headers.findIndex((h) => isWordMatch(h, FIELD_NAMES.DICE_QTY));
  const titleIdx = headers.findIndex((h) => isWordMatch(h, FIELD_NAMES.TITLE));
  return qtyIdx < 0
    ? data.length
    : data.reduce((total, row) => {
        // If no title, return current count.
        if (row[titleIdx] === '') {
          return total;
        }
        const quantity = parseInt(row[qtyIdx].toString(), 10);
        return Number.isNaN(quantity) ? total + 1 : total + quantity;
      }, 0);
};

export const checkImportHeaders = (headers, data) => {
  // Move the DICE title to column 1 if it exists
  const titleMoved = renameMoveColumn(
    FIELD_NAMES.DICE_TITLE,
    'title',
    1,
    headers,
    data,
  );

  // Move the DICE qty to column 2 if it exists
  const qtyMoved = renameMoveColumn(
    FIELD_NAMES.DICE_QTY,
    FIELD_NAMES.DICE_QTY,
    2,
    titleMoved.headers,
    titleMoved.data,
  );

  const uniqueHeaders = suffixDuplicateFields(qtyMoved.headers);

  // Clean up and format text
  const finalHeaders = uniqueHeaders.map((f) => {
    if (isWordMatch(f, FIELD_NAMES.DICE_TITLE)) {
      return FIELD_NAMES.DICE_TITLE;
    }
    if (isWordMatch(f, FIELD_NAMES.DICE_QTY)) {
      return FIELD_NAMES.DICE_QTY;
    }
    return f.trim();
  });

  return {
    data: qtyMoved.data,
    headers: finalHeaders,
    headersChanged: uniqueHeaders.some((h, idx) => h !== qtyMoved.headers[idx]),
  };
};
