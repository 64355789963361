type BaseModelType = Record<string, unknown> & {
  createdAt?: unknown;
  updatedAt?: unknown;
};

export type BaseModelOf<ApiType> = {
  [Prop in keyof ApiType]: Prop extends 'createdAt' | 'updatedAt'
    ? Date
    : ApiType[Prop];
};

// Basic formatting or server side data objects
export default function baseModel<T extends BaseModelType>(
  data: T,
): BaseModelOf<T> {
  const formatted = { ...data };
  // Format dates to ISO including T separator for Safari support
  if (typeof formatted.createdAt === 'string') {
    formatted.createdAt = new Date(formatted.createdAt.replace(' ', 'T'));
  }
  if (typeof formatted.updatedAt === 'string') {
    formatted.updatedAt = new Date(formatted.updatedAt.replace(' ', 'T'));
  }

  return formatted as BaseModelOf<T>;
}
