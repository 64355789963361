import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, Tooltip } from '@mui/material';

import { formatDateForDisplay } from '@/common/dates';
import { provideFallbackImage } from '@/common/utilities/images';
import DustFavoriteIcon from '@/components/Library/Icons/DustFavoriteIcon';
import { combineClass } from '@/components/Library/utility';

import styles from './Catalog.module.css';
import CatalogMenu from './CatalogMenu';

const buttonStyle = {
  backgroundColor: 'var(--bg-light)',
  position: 'absolute',
  right: '1.5rem',
  width: '1.5rem',
  height: '1.5rem',
  '& svg': {
    width: '1.25rem',
    height: '1.25rem',
  },
  '&:hover': {
    backgroundColor: 'var(--light-grey)',
  },
};

const favoriteButtonStyle = {
  ...buttonStyle,
  top: '1.5rem',
};

const hiddenButtonStyle = {
  ...buttonStyle,
  bottom: '4.4rem',
};

type Props = {
  catalog: Catalog;
  onClick: () => void;
  onFavorite: () => void;
  onHide: () => void;
  isSelected?: boolean;
};

export default function CatalogItem({
  catalog,
  onClick,
  isSelected = false,
  onFavorite,
  onHide,
}: Props) {
  return (
    <div
      className={`${styles.itemContainer} ${
        catalog.hidden ? styles.hidden : ''
      }`}
    >
      <button
        className={combineClass(
          styles.catalogItem,
          isSelected && styles.catalogItemSelected,
        )}
        onClick={onClick}
        type="button"
      >
        <div className={styles.catalogCoverImage}>
          {catalog.imageUri && (
            <img
              alt={`${catalog.name} catalog cover`}
              src={catalog.imageUri}
              onError={provideFallbackImage}
            />
          )}
          {catalog.size > 0 && (
            <div className={styles.catalogSize}>
              {Intl.NumberFormat().format(catalog.size)}
            </div>
          )}
        </div>
        <h5 className={`h5 text-overflow ${styles.cardText}`}>
          {catalog.name}
        </h5>
        <div className={`text-sm ${styles.cardText}`}>
          Created {formatDateForDisplay(catalog.createdAt, { showTime: false })}
        </div>
      </button>
      <Tooltip
        // TODO: decide between title and aria-label long-term
        title={catalog.favorite ? 'Remove from Favorites' : 'Add to favorites'}
        // TODO: Find better way to structure CatalogItem to allow Playwright
        // to target this item better, we should not need to hack an
        // overly-specific aria-label for this button.
        aria-label={
          catalog.favorite
            ? `Remove ${catalog.name} from Favorites`
            : `Add ${catalog.name} to favorites`
        }
      >
        <IconButton onClick={onFavorite} size="small" sx={favoriteButtonStyle}>
          <DustFavoriteIcon favorite={!!catalog.favorite} />
        </IconButton>
      </Tooltip>
      <div className={styles.catalogButtonRow}>
        <CatalogMenu catalog={catalog} />
      </div>
      {catalog.hidden && (
        <Tooltip title="Hidden" aria-label="Hidden">
          <IconButton onClick={onHide} size="small" sx={hiddenButtonStyle}>
            <VisibilityOff />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
