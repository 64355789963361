import { useMemo } from 'react';

import { useQueries } from 'react-query';

import { chunkArray, keyBy } from '@/common/utility';
import { GetThings } from '@/services/requests/apis/ThingsApi';
import useRequest from '@/services/requests/useRequest';

type Params = {
  catalogUuid: string;
  thingUuids: Array<string>;
  /** Added to query parameters, not included in filters */
  queryParams?: GetThings;
};

const PER_PAGE_CHUNK = 50;

export default function useThingsByUuids({
  catalogUuid,
  thingUuids,
  queryParams = undefined,
}: Params) {
  const { thingsApi, QUERY_KEYS } = useRequest();

  const queryOptions = useMemo(
    () =>
      chunkArray(thingUuids, PER_PAGE_CHUNK).map((uuidChunk) => ({
        queryKey: [...QUERY_KEYS.THINGS_BY_UUID(uuidChunk), queryParams],
        queryFn: () =>
          thingsApi.search({
            fields: [],
            ...queryParams,
            perPage: uuidChunk.length,
            thingUuids: uuidChunk,
            catalogUuids: [catalogUuid],
          }),
      })),
    [QUERY_KEYS, thingsApi, thingUuids, catalogUuid, queryParams],
  );

  const queryResults = useQueries(queryOptions);

  const thingsMap = useMemo(() => {
    const allResults = queryResults.flatMap((r) =>
      r.data != null && !r.data.error ? r.data.data : [],
    );
    return keyBy(allResults, 'uuid');
  }, [queryResults]);

  return {
    thingsMap,
    isLoading: queryResults.some((r) => r.isLoading),
    isSuccess: queryResults.every((r) => r.isSuccess),
  };
}
