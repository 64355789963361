import { useState } from 'react';

import { Button } from '@mui/material';

import useToasts from '@/common/hooks/useToasts';
import DustModal from '@/components/Library/DustModal';
import DustPageSnackbar, {
  SNACKBAR_STATUS,
} from '@/components/Library/DustPageSnackbar';
import useRequest from '@/services/requests/useRequest';

import styles from './Thing.module.css';

export default function ThingRecovery({ thing }: { thing: Thing }) {
  const { exportBatchesApi } = useRequest();
  const { addToast } = useToasts();

  const [modalOpen, setModalOpen] = useState(false);
  const [isRecovering, setIsRecovering] = useState(false);

  const handleRecover = async () => {
    setIsRecovering(true);

    const res = await exportBatchesApi.recoverThing({
      thingUuid: thing.uuid,
    });

    if (res.error) {
      addToast('Could not recover the Thing. Please try again.', 'error');
    } else {
      addToast(`Successfully recovered ${thing.title}.`, 'success');
      setModalOpen(false);
    }

    setIsRecovering(false);
  };

  return (
    <>
      <DustPageSnackbar
        className={styles.snackbar}
        label={
          <>
            <b>This Thing is currently checked out. </b>
            No changes can be made until it has been checked in or recovered.
          </>
        }
        status={SNACKBAR_STATUS.WARNING}
      >
        <Button
          onClick={() => setModalOpen(true)}
          sx={{ color: 'var(--color-text-primary)' }}
        >
          Recover
        </Button>
      </DustPageSnackbar>
      <DustModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        footerProps={{
          onCancel: () => setModalOpen(false),
          onSubmit: () => {
            void handleRecover();
          },
          loading: isRecovering,
        }}
        title="Recover Thing"
      >
        <p>
          If you perform a recovery the Thing on this machine will revert to
          Checked In status. Data conflicts may occur if you try to check a
          Thing in from another machine later.
        </p>
      </DustModal>
    </>
  );
}
