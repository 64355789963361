import React from 'react';

import useAntiTamper from '@/common/entities/antiTamper/useAntiTamper';
import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import DustCircleMatchingIcon from '@/components/Library/Icons/DustCircleMatchingIcon';
import DustMissingMarkersIcon from '@/components/Library/Icons/DustMissingMarkersIcon';
import DustModifiedIcon from '@/components/Library/Icons/DustModifiedIcon';

import AntiTamperHeader from './AntiTamperHeader';
import AntiTamperLoadingModal from './AntiTamperLoadingModal';
import AntiTamperNewSession from './AntiTamperNewSession';
import AntiTamperNotation from './AntiTamperNotation/AntiTamperNotation';
import AntiTamperRescanWarningModal from './AntiTamperRescanWarningModal';
import AntiTamperSaveModal from './AntiTamperSaveModal';
import AntiTamperScanModal from './AntiTamperScanModal';
import AntiTamperSideBar from './AntiTamperSideBar/AntiTamperSideBar';
import AntiTamperSideBarAction, {
  SIDEBAR_ACTIONS,
} from './AntiTamperSiderBarAction';
import AntiTamperImageLayer from './AntiTamperVerifiedSession/AntiTamperImageLayer';
import AntiTamperImagesContainer from './AntiTamperVerifiedSession/AntiTamperImagesContainer';
import AntiTamperVerifiedSession from './AntiTamperVerifiedSession/AntiTamperVerifiedSession';
import AntiTamperWarningModal from './AntiTamperWarningModal';
import AntiTamperLayout from './Layout/AntiTamperLayout';

type AntiTamperProps = {
  onComplete: (x: boolean) => void;
  thing: Thing;
  transaction?: Transaction;
};

function AntiTamper({ onComplete, thing, transaction }: AntiTamperProps) {
  // TODO: A lot of these 'state' values should be changed to 'open' or 'active'.
  const [isScanLoaded, setIsScanLoaded] = React.useState(false);
  const [isRescanModalOpen, setIsRescanModalOpen] = React.useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = React.useState(false);
  const [isScanModalOpen, setIsScanModalOpen] = React.useState(false);
  const [observedResult, setObservedResult] = React.useState('');
  const [comment, setComment] = React.useState('');
  const [notations, setNotations] = React.useState<AntiTamperNotation[]>([]);
  const [isWarningModalOpen, setIsWarningModalOpen] = React.useState(false);
  const [isBackgroundImageActive, setIsBackgroundImageActive] =
    React.useState(true);
  const [isEnhancedHeatMapActive, setIsEnhancedHeatmapActive] =
    React.useState(false);
  const [scanHeatMapState, setScanHeatMapStateState] = React.useState(false);
  const [isMatchingMarkersHeatmapActive, setIsMatchingMarkersHeatmapActive] =
    React.useState(false);
  const [missingMarkersHeatMapState, setMissingMarkersHeatMapState] =
    React.useState(false);
  const [isMatchingMarkersVisible, setIsMatchingMarkersVisible] =
    React.useState(false);
  const [modifiedMarkers, setModifiedMarkersState] = React.useState(false);
  const [missingMarkers, setMissingMarkersState] = React.useState(false);
  const [isNotationActive, setIsNotationActive] = React.useState(false);
  const [isFetchingModifiedMarkers, setIsFetchingModifiedMarkersState] =
    React.useState(false);
  const [isLoadedModifiedMarkers, setIsLoadedModifiedMarkersState] =
    React.useState(false);
  const [isFetchingMissingMarkers, setIsFetchingMissingMarkersState] =
    React.useState(false);
  const [isLoadedMissingMarkers, setIsLoadedMissingMarkersState] =
    React.useState(false);
  const [isLoadedMatchingMarker, setIsLoadedMatchingMarker] =
    React.useState(false);
  const [isFetchingMatchingMarker, setIsFetchingMatchingMarker] =
    React.useState(false);

  const [previousTamperResult, setPreviousTamperResult] = React.useState<
    Transaction | null | undefined
  >(transaction);

  const isReadOnly = !!previousTamperResult;

  const { isPageVerified, pageVerifiedTime } = useVerifiedSession();

  const {
    analysisLoadingState,
    progress,
    isLoadingModalOpen,
    setIsLoadingModalOpen,
    isVerifiedSession,
    setIsVerifiedSession,
    handleCancel: cancelAntiTamper,
    antiTamperAnalysisScan,
    startAntiTamperAnalysis,
    confirmAntiTamperAnalysis,
    loadAntiTamperAnalysis,
    resetAnalysis,
  } = useAntiTamper();

  const clickAway = () => {
    if (isReadOnly) {
      onComplete(false);
    } else {
      setIsWarningModalOpen(true);
    }
  };

  const handleWarningModalDiscard = () => {
    onComplete(false);
  };

  const handleWarningModalReturn = () => {
    setIsWarningModalOpen(false);
  };

  const handleNotations = () => {
    setIsNotationActive((pre) => !pre);
  };

  const handleRescan = () => {
    setIsRescanModalOpen(false);
    setIsVerifiedSession(false);
    setIsScanModalOpen(true);
    setNotations([]);
  };

  const handleNewScan = () => {
    setPreviousTamperResult(null);
    setNotations([]);
    setComment('');
    setObservedResult('');
    resetAnalysis();
  };

  React.useEffect(() => {
    if (isVerifiedSession) {
      setIsFetchingMatchingMarker(true);

      setTimeout(() => {
        setIsFetchingMatchingMarker(false);
        setIsMatchingMarkersVisible(true);
        setIsLoadedMatchingMarker(true);
        setIsFetchingMissingMarkersState(true);
        setIsMatchingMarkersHeatmapActive(true);
      }, 250);
      setTimeout(() => {
        setIsFetchingMissingMarkersState(false);
        setMissingMarkersState(true);
        setIsLoadedMissingMarkersState(true);
        setIsFetchingModifiedMarkersState(true);
        setMissingMarkersHeatMapState(true);
      }, 500);
      setTimeout(() => {
        setIsFetchingModifiedMarkersState(false);
        setModifiedMarkersState(true);
        setIsLoadedModifiedMarkersState(true);
      }, 750);
      setTimeout(() => {
        setIsLoadingModalOpen(false);
        setIsScanLoaded(true);
      }, 1000);
    } else {
      setIsLoadedMatchingMarker(false);
      setIsLoadedMissingMarkersState(false);
      setIsLoadedModifiedMarkersState(false);
      setModifiedMarkersState(false);
      setMissingMarkersState(false);
      setMissingMarkersState(false);
      setMissingMarkersHeatMapState(false);
      setIsMatchingMarkersHeatmapActive(false);
      setIsScanLoaded(false);
    }
  }, [isVerifiedSession, setIsLoadingModalOpen]);

  React.useEffect(() => {
    const load = async () => {
      if (
        previousTamperResult &&
        !isScanLoaded &&
        analysisLoadingState.key === 'PROCESSING_SCAN'
      ) {
        const antiTamperUuid =
          previousTamperResult.transactionData?.actionData
            ?.anti_tamper_analysis_uuid;
        if (antiTamperUuid) {
          const scan = await loadAntiTamperAnalysis(antiTamperUuid);
          if (scan) {
            setIsScanLoaded(true);
            setNotations(scan.notations);
            setComment(scan.comments);
            setObservedResult(scan.outcome);
          }
        }
      }
    };
    load().catch(() => {});
  }, [
    previousTamperResult,
    isScanLoaded,
    loadAntiTamperAnalysis,
    analysisLoadingState,
  ]);

  const handleLayersHeatMap = (actionType: ValueOf<typeof SIDEBAR_ACTIONS>) => {
    switch (actionType) {
      case SIDEBAR_ACTIONS.BACKGROUND: {
        if (!scanHeatMapState) {
          setIsMatchingMarkersHeatmapActive(false);
          setMissingMarkersHeatMapState(false);
        }
        setScanHeatMapStateState((pre) => !pre);
        return;
      }
      case SIDEBAR_ACTIONS.MATCHING_MARKER: {
        if (scanHeatMapState) {
          setScanHeatMapStateState(false);
        }
        setIsMatchingMarkersHeatmapActive((pre) => !pre);
        return;
      }
      case SIDEBAR_ACTIONS.MISSING_MARKER: {
        if (scanHeatMapState) {
          setScanHeatMapStateState(false);
        }
        setMissingMarkersHeatMapState((pre) => !pre);
        break;
      }
      default:
        break;
    }
  };

  const handleStartAntiTamperAnalysis = async (scan?: string) => {
    setIsScanLoaded(false);
    try {
      await startAntiTamperAnalysis({
        thingUuid: thing?.uuid,
        ...(scan && { scan }),
      });
      setIsScanLoaded(true);
    } catch (e) {
      // Error is handled by API
    }
  };

  const handleConfirmAntiTamperAnalysis = async () => {
    try {
      await confirmAntiTamperAnalysis({
        outcome: observedResult,
        comment,
        notations,
      });
      setIsSaveModalOpen(false);
      onComplete(false);
    } catch (e) {
      // Error is handled by API
    }
  };

  const scannedImage = antiTamperAnalysisScan
    ? antiTamperAnalysisScan?.comparisonScan.generatedMedia
        .queryScanTransformedAlphaMaskedUri ||
      antiTamperAnalysisScan?.comparisonScan.generatedMedia.queryScanUri
    : '';

  const heatMap = isEnhancedHeatMapActive
    ? antiTamperAnalysisScan?.comparisonScan.generatedMedia.bgHeatmapNormUri ??
      ''
    : antiTamperAnalysisScan?.comparisonScan.generatedMedia.bgHeatmapFullUri ??
      '';

  return (
    <AntiTamperLayout
      header={
        <AntiTamperHeader
          isVerifiedSession={
            isVerifiedSession && !!antiTamperAnalysisScan?.alignmentOk
          }
          handleRescan={() => setIsRescanModalOpen(true)}
          handleNotations={handleNotations}
          allowRetake={
            isVerifiedSession || !!antiTamperAnalysisScan?.alignmentOk
          }
          isNotationActive={isNotationActive}
          isReadOnly={isReadOnly}
          handleNewScan={handleNewScan}
        />
      }
      sideBar={
        <AntiTamperSideBar
          isReadOnly={isReadOnly}
          handleCancelButton={clickAway}
          isVerifiedSession={isVerifiedSession}
          isScanLoaded={isScanLoaded}
          handleSaveButton={() => {
            setIsSaveModalOpen(true);
          }}
          riskScore={
            antiTamperAnalysisScan ? antiTamperAnalysisScan.tamperScore : 0.5
          }
          matchingMarkerAction={
            <AntiTamperSideBarAction
              label="Matching Markers"
              isVerifiedSession={isVerifiedSession}
              isLoaded={isLoadedMatchingMarker}
              percentage={
                antiTamperAnalysisScan
                  ? `${parseFloat(
                      antiTamperAnalysisScan.matchingMarkersPerc,
                    ).toFixed(0)}%`
                  : '-'
              }
              actionType={SIDEBAR_ACTIONS.MATCHING_MARKER}
              startIcon={<DustCircleMatchingIcon />}
              isLoading={isFetchingMatchingMarker}
              isLayerVisible={isMatchingMarkersVisible}
              layerAction={() => setIsMatchingMarkersVisible((pre) => !pre)}
              isHeatMapActive={isMatchingMarkersHeatmapActive}
              heatMapAction={() =>
                handleLayersHeatMap(SIDEBAR_ACTIONS.MATCHING_MARKER)
              }
            />
          }
          missingMarkerAction={
            <AntiTamperSideBarAction
              label="Missing Markers"
              isVerifiedSession={isVerifiedSession}
              isLoading={isFetchingMissingMarkers}
              isLoaded={isLoadedMissingMarkers}
              percentage={
                antiTamperAnalysisScan
                  ? `${parseFloat(
                      antiTamperAnalysisScan.missingMarkersPerc,
                    ).toFixed(0)}%`
                  : '-'
              }
              actionType={SIDEBAR_ACTIONS.MISSING_MARKER}
              startIcon={<DustMissingMarkersIcon />}
              isLayerVisible={missingMarkers}
              layerAction={() => setMissingMarkersState((pre) => !pre)}
              isHeatMapActive={missingMarkersHeatMapState}
              heatMapAction={() =>
                handleLayersHeatMap(SIDEBAR_ACTIONS.MISSING_MARKER)
              }
            />
          }
          modifiedMarkerAction={
            <AntiTamperSideBarAction
              label="Modified Markers"
              isVerifiedSession={isVerifiedSession}
              isLoaded={isLoadedModifiedMarkers}
              percentage={
                antiTamperAnalysisScan
                  ? `${parseFloat(
                      antiTamperAnalysisScan.modifiedMarkersPerc,
                    ).toFixed(0)}%`
                  : '-'
              }
              actionType={SIDEBAR_ACTIONS.MODIFIED_MARKER}
              startIcon={<DustModifiedIcon />}
              isLoading={isFetchingModifiedMarkers}
              isLayerVisible={modifiedMarkers}
              layerAction={() => setModifiedMarkersState((pre) => !pre)}
            />
          }
          background={
            <AntiTamperSideBarAction
              label="Background"
              isVerifiedSession={isVerifiedSession}
              isLoaded={isVerifiedSession}
              actionType={SIDEBAR_ACTIONS.BACKGROUND}
              isLayerVisible={isBackgroundImageActive}
              layerAction={() => setIsBackgroundImageActive((pre) => !pre)}
              isHeatMapActive={scanHeatMapState}
              heatMapAction={() => {
                handleLayersHeatMap(SIDEBAR_ACTIONS.BACKGROUND);
                setIsEnhancedHeatmapActive(false);
              }}
              enhanceHeatMapState={isEnhancedHeatMapActive}
              handleEnhanceHeatMapState={() =>
                setIsEnhancedHeatmapActive((pre) => !pre)
              }
            />
          }
          observedResult={observedResult}
          setObservedResult={setObservedResult}
          comment={comment}
          setComment={setComment}
        />
      }
      isVerifiedSession={isVerifiedSession}
      newSession={
        <AntiTamperNewSession
          handleNewScanButton={() => setIsScanModalOpen(true)}
          handleVerifiedScanButton={handleStartAntiTamperAnalysis} // TODO: This is missing the scan.
          isVerifiedSession={isPageVerified()}
          pageVerifiedTime={pageVerifiedTime}
        />
      }
      verifiedSession={
        <AntiTamperVerifiedSession
          isLoading={isLoadingModalOpen}
          isScanVisible={isBackgroundImageActive}
          scannedImageContainer={
            <AntiTamperImagesContainer
              scannedImage={scannedImage}
              scannedImageIsActive={isBackgroundImageActive}
              isLoading={isLoadingModalOpen}
              notationLayer={
                <AntiTamperNotation
                  isActive={isNotationActive}
                  notations={notations}
                  setNotations={setNotations}
                  isReadOnly={isReadOnly}
                />
              }
              combinedHeatMapLayer={
                <AntiTamperImageLayer
                  imageSrc={heatMap}
                  isLayerActive={scanHeatMapState}
                />
              }
              matchingMarkersHeatMapLayer={
                <AntiTamperImageLayer
                  imageSrc={
                    // TODO: Instead of empty string, accept null on the other end.
                    antiTamperAnalysisScan?.comparisonScan.generatedMedia
                      .fgHeatmapMatchingUri ?? ''
                  }
                  isLayerActive={
                    isMatchingMarkersHeatmapActive &&
                    !missingMarkersHeatMapState
                  }
                />
              }
              missingMarkersHeatMapLayer={
                <AntiTamperImageLayer
                  imageSrc={
                    antiTamperAnalysisScan?.comparisonScan.generatedMedia
                      .fgHeatmapMissingUri ?? ''
                  }
                  isLayerActive={
                    missingMarkersHeatMapState &&
                    !isMatchingMarkersHeatmapActive
                  }
                />
              }
              combinedMarkersHeatMapLayer={
                <AntiTamperImageLayer
                  imageSrc={
                    antiTamperAnalysisScan?.comparisonScan.generatedMedia
                      .fgHeatmapCombinedUri ?? ''
                  }
                  isLayerActive={
                    missingMarkersHeatMapState && isMatchingMarkersHeatmapActive
                  }
                />
              }
              matchingMarkers={
                <AntiTamperImageLayer
                  imageSrc={
                    antiTamperAnalysisScan?.comparisonScan.generatedMedia
                      .matchingMarkersUri
                      ? antiTamperAnalysisScan?.comparisonScan.generatedMedia
                          .matchingMarkersUri
                      : ''
                  }
                  isLayerActive={isMatchingMarkersVisible}
                />
              }
              modifiedMarkers={
                <AntiTamperImageLayer
                  imageSrc={
                    antiTamperAnalysisScan?.comparisonScan.generatedMedia
                      .missingQueryMarkersUri
                      ? antiTamperAnalysisScan?.comparisonScan.generatedMedia
                          .missingQueryMarkersUri
                      : ''
                  }
                  isLayerActive={modifiedMarkers}
                />
              }
              missingMarkers={
                <AntiTamperImageLayer
                  imageSrc={
                    antiTamperAnalysisScan?.comparisonScan.generatedMedia
                      .missingReferenceMarkersUri
                      ? antiTamperAnalysisScan?.comparisonScan.generatedMedia
                          .missingReferenceMarkersUri
                      : ''
                  }
                  isLayerActive={missingMarkers}
                />
              }
            />
          }
        />
      }
      handleClickOutSideAntiTamper={clickAway}
      scanModal={
        <AntiTamperScanModal
          open={isScanModalOpen}
          onClose={() => setIsScanModalOpen(false)}
          catalogUuid={thing.uuid}
          onError={() => console.log('error')}
          onSuccess={handleStartAntiTamperAnalysis}
          thingUuid={thing.uuid}
        />
      }
      warningModal={
        <AntiTamperWarningModal
          modalstate={isWarningModalOpen}
          handleModalDiscard={handleWarningModalDiscard}
          handleModalReturn={handleWarningModalReturn}
        />
      }
      rescanWarningModal={
        <AntiTamperRescanWarningModal
          open={isRescanModalOpen}
          onCancel={() => setIsRescanModalOpen(false)}
          onRescan={handleRescan}
        />
      }
      antiTamperSaveModal={
        <AntiTamperSaveModal
          open={isSaveModalOpen}
          onSave={() => handleConfirmAntiTamperAnalysis()}
          onCancel={() => setIsSaveModalOpen(false)}
        />
      }
      antiTamperLoadingModal={
        <AntiTamperLoadingModal
          antiTamperLoadingProgressState={analysisLoadingState}
          open={isLoadingModalOpen}
          progress={progress}
          onHandleCancel={cancelAntiTamper}
        />
      }
    />
  );
}

export default AntiTamper;
