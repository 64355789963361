import { useState, useMemo, Fragment, useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useTimeout } from 'usehooks-ts';

import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import { filterImages } from '@/common/entities/things/utility';
import { removeFileExtension } from '@/common/utility';
import ThingFileModal from '@/components/Composed/ThingFileModal/ThingFileModal';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustFieldLabel from '@/components/Library/DustFieldLabel';

import ThingImage from './ThingImage';
import ThingImageCarousel from './ThingImageCarousel';
import styles from '../ThingTabs.module.css';

/**
 *
 * @param   {object}      props
 * @param   {Thing}       props.thing
 * @param   {ThingType | null}   props.thingType
 * @param   {Function}   props.promptForVerify
 * @param   {boolean}    props.disableInteraction
 * @returns {JSX.Element}
 */
function ThingImages({
  thing,
  thingType = null,
  promptForVerify,
  disableInteraction = false,
}) {
  // Get only images and move primary image to top
  const untypedImages = useMemo(
    () =>
      filterImages(thing.files)
        .filter((img) => !img.thingMetadataTypeUuid)
        .sort((img) => (img.isPrimary ? -1 : 0)) ?? [],
    [thing],
  );

  const typedFieldMap = useMemo(
    () =>
      Object.fromEntries(thingType?.fieldTypes.map((f) => [f.uuid, f]) ?? []),
    [thingType],
  );

  const typedImages = useMemo(
    () =>
      filterImages(thing.files)
        .filter((img) => img.thingMetadataTypeUuid)
        .map((img) => ({
          ...img,
          isStatic: typedFieldMap[img.thingMetadataTypeUuid]?.isStatic,
          fieldName:
            // Sort static type images that are not primary into a dedicated group
            typedFieldMap[img.thingMetadataTypeUuid]?.isStatic &&
            typedFieldMap[img.thingMetadataTypeUuid]?.name !==
              RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE
              ? 'Static Images'
              : removeFileExtension(
                  typedFieldMap[img.thingMetadataTypeUuid]?.name,
                ),
          isPrimary:
            typedFieldMap[img.thingMetadataTypeUuid]?.name ===
            RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE,
        }))
        .sort((img) => (img.isPrimary ? -1 : 0)) ?? [],
    [thing, typedFieldMap],
  );

  const images = useMemo(
    () => [...typedImages, ...untypedImages],
    [typedImages, untypedImages],
  );

  // Sort the images into groups by field name to handle fields with a multi-file option
  const typedImagesByField = useMemo(
    () =>
      // Each fieldName will be unique. Keep all images associated with that fieldName in an array
      Object.entries(
        typedImages.reduce(
          (keyedByField, img) => ({
            ...keyedByField,
            [img.fieldName]: [...(keyedByField[img.fieldName] ?? []), img],
          }),
          {},
        ),
      ).sort(([fieldName]) =>
        fieldName === RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE ? -1 : 0,
      ),
    [typedImages],
  );

  const [showAddFile, setShowAddFile] = useState(false);

  // Handle selection and actions
  const [selected, setSelected] = useState(images[0]?.uuid);

  const selectedImage = useMemo(
    () => images.find((img) => img.uuid === selected),
    [images, selected],
  );

  const onAddFile = () => {
    if (promptForVerify(() => setShowAddFile(true))) return;
    setShowAddFile(true);
  };

  /**
   *
   * @param   {'left'|'right'} dir
   * @returns {void}
   */
  const traverseSelect = (dir) => {
    const currentIdx = images.findIndex((i) => i.uuid === selected);

    if (currentIdx < 0) return;

    const nextIdx = currentIdx + 1 >= images.length ? 0 : currentIdx + 1;
    const prevIdx = currentIdx - 1 < 0 ? images.length - 1 : currentIdx - 1;
    const moveIdx = dir === 'right' ? nextIdx : prevIdx;

    setSelected(images[moveIdx].uuid);
  };

  // ---------------------------------------------------------------------------
  // Render Styles
  const addIconStyle = {
    border: 'var(--border-w) solid',
    borderRadius: '50%',
  };

  const [delay, setDelay] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const resetScroll = useCallback(() => setScrolling(false), []);
  useTimeout(resetScroll, delay);

  // Alternate the delay to reset the timeout on each scroll event
  // Final timeout with set scrolling to fall (slightly debounced)
  const handleScroll = () => {
    if (!scrolling) setScrolling(true);
    setDelay(delay === 300 ? 301 : 300);
  };

  return (
    <div className="flex-row h-full">
      <div className={styles.scrollSidepanel}>
        {/* Extra div provided to normalize button styling to match other tabs */}
        <div className={styles.addButton}>
          <Button
            onClick={onAddFile}
            disabled={disableInteraction}
            startIcon={<AddIcon style={addIconStyle} />}
            sx={{
              maxWidth: '9.5rem',
              marginBottom: '1rem',
              marginLeft: '2rem',
            }}
          >
            Add Images
          </Button>
        </div>
        <div className={styles.imageScroller} onScroll={handleScroll}>
          {typedImagesByField.map(([fieldName, fieldImages]) => (
            <Fragment key={`${fieldName}fragment`}>
              <DustFieldLabel
                label={fieldName}
                tag="h3"
                className={styles.imageLabel}
              />
              {fieldImages.map((image) => (
                <ThingImage
                  scrolling={scrolling}
                  key={image.uuid}
                  image={image}
                  onClick={() => setSelected(image.uuid)}
                />
              ))}
            </Fragment>
          ))}
          {untypedImages.length > 0 && (
            <DustFieldLabel
              label={thingType ? 'Additional Images' : 'Images'}
              tag="h3"
              className={styles.imageLabel}
            />
          )}
          {untypedImages.map((image) => (
            <ThingImage
              scrolling={scrolling}
              key={image.uuid}
              image={image}
              onClick={() => setSelected(image.uuid)}
            />
          ))}
        </div>
      </div>
      {!!selected && (
        <ThingImageCarousel
          disableInteraction={disableInteraction}
          isStaticPrimary={
            thingType?.primaryImageField?.value?.type === 'STATIC_MEDIA'
          }
          thing={thing}
          images={images}
          promptForVerify={promptForVerify}
          selectedImage={selectedImage}
          onTraverse={traverseSelect}
        />
      )}
      {/* Modals */}
      <ThingFileModal
        fileType={FILE_TYPE_OPTIONS.IMAGE}
        open={showAddFile}
        setOpen={setShowAddFile}
        showPrimary={false}
        things={[thing]}
        thingType={thingType}
      />
    </div>
  );
}

ThingImages.propTypes = {
  thing: PropTypes.object.isRequired,
  thingType: PropTypes.object,
  promptForVerify: PropTypes.func.isRequired,
};

export default ThingImages;
