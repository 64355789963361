import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button } from '@mui/material';
import { useCountdown } from 'usehooks-ts';

const CONFIRMATION_TIMEOUT = 5 * 60;
const ONE_MINUTE_WARNING_TIME = 60;

type RedirectWarningProps = {
  onTimeout: () => void;
};

export default function RedirectWarning({ onTimeout }: RedirectWarningProps) {
  const [count, { resetCountdown, startCountdown, stopCountdown }] =
    useCountdown({
      countStart: CONFIRMATION_TIMEOUT,
    });

  const handleReset = () => {
    resetCountdown();
    startCountdown();
  };

  const isLessThanOneMinute = count <= ONE_MINUTE_WARNING_TIME;

  React.useEffect(() => {
    if (count === 0) {
      stopCountdown();
      onTimeout();
    }
  }, [count, onTimeout, stopCountdown]);

  React.useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  if (isLessThanOneMinute) {
    return (
      <div
        className="flex-row items-center text-sm"
        style={{
          padding: '1rem',
          backgroundColor: 'var(--error-red)',
          color: 'var(--white)',
        }}
      >
        <InfoOutlinedIcon />{' '}
        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
          In {count} seconds, you will be timed out of Download
        </div>
        <Button onClick={handleReset} sx={{ color: 'var(--white)' }}>
          Refresh
        </Button>
      </div>
    );
  }

  return (
    <div
      className="flex-row items-center text-sm"
      style={{
        padding: '1rem',
        backgroundColor: 'var(--warning-yellow)',
      }}
    >
      <WarningAmberIcon />{' '}
      <div style={{ marginLeft: '1rem' }}>
        If you do not complete your download in {Math.ceil(count / 60)} minutes,
        you will be redirected to the Summary window.
      </div>
    </div>
  );
}
