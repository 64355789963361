import { RESTRICTED_THING_FIELDS } from './constants';
import {
  ApiThing,
  ThingFormComputedTitle,
  ThingMetadataObject,
  ThingTypedMetadataObject,
  ThingTypedMetaFilters,
  TypedFileEntry,
  Xtag,
} from './typedefs';
import baseModel from '../baseModel';
import { THING_TYPE_FIELD_TYPES } from '../thingTypes/constants';

const mapXtagsToDustUuids = (xtags: Xtag[]) =>
  xtags
    ? xtags.filter((xtag) => xtag.xtagType === 'DUST').map((xtag) => xtag.value)
    : [];

export function thingMinimalModel(
  thingMin: ThingRelationEntry,
): ThingRelationEntry & Pick<Thing, 'isDusted'> {
  const dustUuids = mapXtagsToDustUuids(thingMin.xtags);

  return {
    ...baseModel(thingMin),
    isDusted: dustUuids?.length > 0,
  };
}

function relationshipsModel(
  relationships: ApiThing['relationships'],
): Thing['relationships'] {
  return (
    relationships && {
      children: relationships?.children?.map((c) => thingMinimalModel(c)),
      parent: relationships?.parent && thingMinimalModel(relationships.parent),
      root: relationships?.root && thingMinimalModel(relationships.root),
    }
  );
}

export function thingModel(thing: ApiThing): Thing {
  const metadata =
    typeof thing.metadata === 'string'
      ? (JSON.parse(thing.metadata) as ThingMetadataObject)
      : thing.metadata;

  if (!thing.thingType) {
    metadata.title = metadata?.title ?? thing.title;
  }
  const typedMetadata =
    typeof thing.typedMetadata === 'string'
      ? (JSON.parse(thing.typedMetadata) as ThingTypedMetadataObject)
      : thing.typedMetadata;

  const isCheckedOut = !!thing.checkedOutAt;
  const hasTypedPrimaryImage =
    thing.primaryImageFieldTypeUuid &&
    thing.schema?.thingMetadataTypes &&
    thing.schema.thingMetadataTypes.find(
      (metadataType) =>
        metadataType.uuid === thing.primaryImageFieldTypeUuid &&
        metadataType.value?.type === 'STATIC_MEDIA',
    );

  const dustUuids = mapXtagsToDustUuids(thing.xtags);

  return {
    ...baseModel(thing),
    metadata,
    typedMetadata,
    dustUuids,
    isDusted: dustUuids?.length > 0,
    hasRelationships:
      !!thing.relationships?.parent ||
      (thing.relationships?.children?.length ?? 0) > 0,
    hasParent: !!thing.relationships?.parent,
    hasRoot: !!thing.relationships?.root,
    // This value is either the root thing in a thing's relationship tree, or the thingUuid if no root exists
    rootUuid: thing.relationships?.root?.uuid || thing.uuid,
    relationships: relationshipsModel(thing?.relationships),
    isCheckedOut,
    // We use the existence of this value to determine which flow to use to attach
    // a primary image. Is null from the backend
    primaryImageFieldTypeUuid:
      thing?.primaryImageFieldTypeUuid ??
      Object.entries(thing?.typedMetadata).reduce(
        (primaryImageUuid, [fieldUuid, fieldValue]) =>
          fieldValue.name === RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE
            ? fieldUuid
            : primaryImageUuid,
        '',
      ),
    primaryImage:
      thing?.files?.find((f) =>
        thing?.primaryImageFieldTypeUuid
          ? f.thingMetadataTypeUuid === thing.primaryImageFieldTypeUuid
          : f.isPrimary,
      ) ?? null,
    canChangePrimaryImage: !isCheckedOut && !hasTypedPrimaryImage,
  };
}

export function thingMetadataFilterModel(data: any): ThingMetaFilters {
  return {
    ...baseModel(data),
  };
}

export function thingTypedMetadataFilterModel(
  data: any,
): ThingTypedMetaFilters {
  return {
    ...baseModel(data),
  };
}

export function thingMetaFilterModel(data: any) {
  return {
    filters: thingMetadataFilterModel(data.filters),
    typedFilters: thingTypedMetadataFilterModel(data.typedFilters),
  };
}
// ---------------------------------------------------------------------------

/**
 * Immutably change a metadata value and name
 */
export function changeMetadataField(
  metadata: Record<string, string>,
  /** Pass null if no old value is to be removed */
  oldName: string | null,
  newName: string,
  value: string /* (maybe) */,
): Record<string, string> {
  let prevFields = metadata;
  if (oldName !== null) {
    // Remove [oldName]
    const { [oldName]: old, ...others } = metadata;
    prevFields = others;
  }
  return {
    ...prevFields,
    [newName]: value,
  };
}

/**
 * Immutably change a typed metadata field value (Type preliminary)
 */
export function changeTypedMetadataField<T extends Record<string, object>>(
  typedMeta: T,
  key: string,
  value: string,
) {
  return {
    ...typedMeta,
    [key]: {
      ...typedMeta[key],
      value,
    },
  };
}

export const getThingFileFieldsFromType = (
  thingType: ThingType | null,
  getStaticFiles: boolean,
): TypedFileEntry[] =>
  !thingType
    ? []
    : thingType.fieldTypes
        .filter(
          (f) =>
            (f.schema.type === THING_TYPE_FIELD_TYPES.IMAGE ||
              f.schema.type === THING_TYPE_FIELD_TYPES.FILE ||
              f.schema.type === THING_TYPE_FIELD_TYPES.ARRAY) &&
            f.uuid !== thingType.primaryImageFieldTypeUuid &&
            (getStaticFiles
              ? f.value?.type === 'STATIC_MEDIA'
              : f.value?.type !== 'STATIC_MEDIA'),
        )
        .map((f) => ({
          required: !!f?.required,
          value:
            (getStaticFiles && f.value) || // Save reference to static file
            (f.schema.type === THING_TYPE_FIELD_TYPES.ARRAY && []) ||
            null,
          name: f.name,
          uuid: f.uuid,
          // @ts-expect-error TODO: figure out if schema.items is correct
          type: f.schema?.items?.type || f.schema.type,
          multiple: f.schema.type === THING_TYPE_FIELD_TYPES.ARRAY,
        }));

/**
 * Default the form schema for an add thing form
 * Apply thing type properties when provided
 */
export const getAddThingInitialValues = (
  thingType: ThingType | null,
  catalogUuid = '',
): AddThingForm => ({
  title: '',
  catalogUuid,
  isComputedTitle: thingType?.titleField?.value?.type === 'COMPUTED_VALUE',
  computedTitle:
    (thingType?.titleField?.value?.value as ThingFormComputedTitle) ?? null,
  titleUuid: thingType?.titleFieldTypeUuid ?? null,
  useThingType: !!thingType?.uuid,
  thingTypeUuid: thingType?.uuid ?? null,
  metadata: [],
  files: {
    required: false,
    value: {},
    name: 'Files and Images',
    type: 'all',
    uuid: '',
  },
  primaryImage: null,
  primaryImageField: thingType?.primaryImageField
    ? {
        required: thingType.primaryImageField.required,
        value: thingType.primaryImageField.value?.value,
        name: thingType.primaryImageField.name,
        uuid: thingType.primaryImageField.uuid,
        type: thingType.primaryImageField.schema.type,
        multiple: false,
      }
    : null,
  typedMetadata: !thingType
    ? []
    : thingType.fieldTypes
        .filter(
          (f) =>
            f.schema.type === THING_TYPE_FIELD_TYPES.STRING &&
            f.name !== RESTRICTED_THING_FIELDS.DICE_TITLE,
        )
        .map((f) => ({
          name: f.name,
          uuid: f.uuid,
          value: '',
          required: !!f?.required,
          type: THING_TYPE_FIELD_TYPES.STRING,
        })),
  typedFiles: getThingFileFieldsFromType(thingType, false),
  typedStaticFiles: getThingFileFieldsFromType(thingType, true),
});
