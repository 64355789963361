import { useState } from 'react';

import { useInfiniteQuery } from 'react-query';

import {
  pageCountGetNextPageParam,
  useFetchAll,
  useGetNextPage,
} from '@/common/utilities/table';
import { SearchThingTypeParams } from '@/services/requests/apis/ThingTypeApi';
import useRequest from '@/services/requests/useRequest';

type HookProps = {
  fetchAll?: boolean;
} & Pick<SearchThingTypeParams, 'sort'>;

/** Data fetching for thingType data */
export default function useThingTypesData({
  fetchAll = false,
  sort,
}: HookProps = {}) {
  const { thingTypeApi, QUERY_KEYS } = useRequest();
  const [search, setSearch] = useState('');

  const queryParams = {
    nameLike: search,
    sort,
  };

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [...QUERY_KEYS.THING_TYPES_SEARCH, queryParams],
    ({ pageParam = 1 }) =>
      thingTypeApi.search({ ...queryParams, page: pageParam }),
    {
      getNextPageParam: pageCountGetNextPageParam,
    },
  );

  const thingTypes =
    data?.pages.reduce<ThingType[]>(
      (combined, current) => combined.concat(current.error ? [] : current.data),
      [],
    ) ?? [];

  const pageZero = data?.pages?.[0];
  const totalItems = pageZero && !pageZero.error ? pageZero.total : undefined;

  const getNextPage = useGetNextPage(
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  );

  useFetchAll(fetchAll, getNextPage, isFetchingNextPage, hasNextPage);

  return {
    thingTypes,
    totalItems,
    isLoading,
    isFetching: isLoading || isFetchingNextPage,
    isSuccess,
    hasNextPage,
    getNextPage,
    search,
    setSearch,
  };
}
