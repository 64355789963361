import { SvgIcon, SvgIconProps } from '@mui/material';

function DustTargetIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        clipRule="evenodd"
        d="M12 18.5455C15.615 18.5455 18.5455 15.615 18.5455 12C18.5455 8.38505 15.615 5.45455 12 5.45455C8.38505 5.45455 5.45455 8.38505 5.45455 12C5.45455 15.615 8.38505 18.5455 12 18.5455ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.8327 2V8.66667H11.166V2H12.8327Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12.8327 15.3333V21.9999H11.166V15.3333H12.8327Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M8.66602 12.8334H1.99935V11.1667H8.66602V12.8334Z"
        fill="white"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M22 12.8334H15.3333V11.1667H22V12.8334Z"
        fill="white"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default DustTargetIcon;
