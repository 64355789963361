import { useState } from 'react';

import { Button } from '@mui/material';
import { AuthContextProps } from 'react-oidc-context';
import { useRecoilValue } from 'recoil';

import { CONTACT_SUPPORT_EMAIL } from '@/components/Library/constants';
import { Mixpanel } from '@/mPanel';
import { selectedFacilityAtom } from '@/state/atoms/facility';

import styles from './ErrorPages.module.css';

export default function LoginErrorButtons({
  authProps,
}: {
  authProps: AuthContextProps;
}) {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const facilityUuid = useRecoilValue(selectedFacilityAtom);

  return (
    <div className={styles.loginErrorButtons}>
      <Button
        className={styles.button}
        size="small"
        // use "disabled" for minimal feedback on start, then fail/next status info
        disabled={isRedirecting}
        variant="contained"
        onClick={async () => {
          Mixpanel.logout({ logoutMethod: 'System Crash', facilityUuid });
          setIsRedirecting(true);
          try {
            // Ensure that an attempt to signin will not default to using old tokens
            await authProps.revokeTokens();
            await authProps.signinRedirect();
          } catch (err) {
            // Log out error for developer, fallback to displaying error auth-page again
            console.error('Error redirecting on "Retry Login" - ', err);
            // Add delay to ensure user can see something happened if instantaneous
            await new Promise((r) => {
              setTimeout(r, 1000);
            });
          }
          setIsRedirecting(false);
        }}
      >
        Retry Login
      </Button>
      <Button
        className={styles.button}
        size="small"
        variant="contained"
        href={`mailto:${CONTACT_SUPPORT_EMAIL}`}
      >
        Contact Us
      </Button>
    </div>
  );
}
