import { Box, Button } from '@mui/material';

import styles from './AntiTamperSideBarFooter.module.css';

type AntiTamperSideBarFooterProps = {
  handleCancel: () => void;
  handleSaveButton: () => void;
  observedResult: string;
  isReadOnly: boolean;
};

export default function AntiTamperSideBarFooter({
  handleCancel,
  handleSaveButton,
  observedResult,
  isReadOnly,
}: AntiTamperSideBarFooterProps) {
  return (
    <Box className={styles.root}>
      <Button
        variant="text"
        onClick={handleCancel}
        sx={{
          color: 'var(--white)',
        }}
      >
        {isReadOnly ? 'Close' : 'Cancel'}
      </Button>
      {!isReadOnly && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveButton}
          disabled={observedResult === ''}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: 'var(--dark-blue)',
              color: 'var(--medium-grey)',
            },
          }}
        >
          Save Analysis
        </Button>
      )}
    </Box>
  );
}
