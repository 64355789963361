import { useState } from 'react';

import StarIcon from '@mui/icons-material/Star';
import { Tooltip, Fade } from '@mui/material';
import PropTypes from 'prop-types';

import { provideFallbackImage } from '@/common/utilities/images';
import { FILE_EXTENSIONS_FROM_MIME } from '@/components/Library/constants';

import styles from '../ThingTabs.module.css';

function ThingImage({ onClick, image, scrolling = false }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleOpen = () => setShowTooltip(true);
  const handleClose = () => setShowTooltip(false);

  return (
    <Tooltip
      open={!scrolling && showTooltip}
      onOpen={handleOpen}
      onClose={handleClose}
      onMouseOut={handleClose}
      placement="right"
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
      title={`${image.filename}.${FILE_EXTENSIONS_FROM_MIME[image.fileType]}`}
      enterDelay={700}
    >
      <button type="button" className={styles.imageCard} onClick={onClick}>
        {image.isPrimary && (
          <Tooltip title="Primary Image" enterDelay={700}>
            <StarIcon
              color="primary"
              sx={{
                position: 'absolute',
                right: '-.5rem',
                top: '-.5rem',
                color: 'var(--white)',
                backgroundColor: 'var(--primary-blue)',
                borderRadius: '50%',
                padding: '.2rem',
              }}
            />
          </Tooltip>
        )}
        <img
          alt={`${image.filename} thumbnail`}
          onError={provideFallbackImage}
          className={styles.imageItem}
          src={image.publicUri}
        />
      </button>
    </Tooltip>
  );
}

ThingImage.propTypes = {
  image: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  scrolling: PropTypes.bool,
};

export default ThingImage;
