import { useCallback, useMemo, useState } from 'react';

import { MenuItem, SelectChangeEvent, Tooltip } from '@mui/material';

import useCatalogsData from '@/common/entities/catalogs/useCatalogsData';
import { booleanSort } from '@/common/utilities/sort';
import DustLoader from '@/components/Library/DustLoader';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustSelect, { DustSelectProps } from '@/components/Library/DustSelect';
import DustFavoriteIcon from '@/components/Library/Icons/DustFavoriteIcon';

import styles from './CatalogListSelector.module.css';

export type Props = {
  currentCatalog: Catalog | null;
  onSelectCatalog: (
    catalog: null | Catalog,
    prevCatalog: null | Catalog,
  ) => void;
  error?: string;
  filterIds?: string[];
  filter?: (c: Catalog) => boolean;
} & Omit<DustSelectProps<string>, 'label' | 'error'>;

export default function CatalogListSelector({
  error,
  currentCatalog,
  onSelectCatalog,
  filterIds = [],
  filter = () => true,
  ...props
}: Props) {
  const { catalogs, isLoading } = useCatalogsData({ fetchAll: true });
  const [search, setSearch] = useState('');

  // Always include the current catalog after search filtering
  const sorted = useMemo(
    () =>
      catalogs
        .sort((a, b) => booleanSort(a, b, 'favorite'))
        .filter(
          (c) =>
            c.name.toLowerCase().includes(search.toLowerCase()) &&
            !filterIds.includes(c.uuid) &&
            filter(c),
        ),
    [catalogs, search, filterIds, filter],
  );

  const handleChange = useCallback(
    (evt: SelectChangeEvent<unknown>) => {
      const uuid = evt.target.value;
      const newCatalog = catalogs.find((c) => c.uuid === uuid);
      if (!newCatalog) {
        throw new Error('Mismatch between selected UUID and catalog list');
      }

      onSelectCatalog(newCatalog, currentCatalog);
    },
    [onSelectCatalog, currentCatalog, catalogs],
  );

  const handleClose = useCallback(() => {
    setSearch('');
    // Blurring the select prevents the Space key from re-opening the select in the scanner
    // Blur has to be deferred so that the menu can close first
    setTimeout(() => {
      (window.document.activeElement as HTMLElement).blur?.();
    }, 0);
  }, []);

  return (
    <DustSelect
      label="Catalog*"
      error={!!error}
      fullWidth
      value={currentCatalog?.uuid ?? ''}
      helperText={error}
      onChange={handleChange}
      onClose={handleClose}
      aria-label="Select a catalog for the Thing you are adding"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isLoading && <DustLoader size="medium" />}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        onKeyDown={(evt) => evt.stopPropagation()}
        className={styles.searchSection}
      >
        <DustSearchInput
          autoFocus
          aria-label="Search for a catalog for this thing"
          sx={{ width: '100%' }}
          setValue={setSearch}
        />
      </div>
      {sorted.map((catalog) => (
        <MenuItem
          key={catalog.uuid}
          value={catalog.uuid}
          sx={{
            display: 'flex',
            gap: '.5rem',
            maxWidth: '29rem',
          }}
        >
          {catalog.favorite && (
            <DustFavoriteIcon favorite={!!catalog.favorite} />
          )}
          {/* Handle alignment when icon is missing */}
          <Tooltip enterDelay={1000} title={catalog.name}>
            <span className="text-overflow"> {catalog.name}</span>
          </Tooltip>
        </MenuItem>
      ))}
    </DustSelect>
  );
}
