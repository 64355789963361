import {
  ApiExportBatch,
  ApiCheckInConflict,
  ApiImportValidation,
  CheckInConflict,
  ImportValidation,
} from './typedefs';
import baseModel from '../baseModel';
import { catalogModel } from '../catalogs/models';
import { thingModel } from '../things/models';

/**
 * API return to check in conflict object
 */
export function checkInConflictModel(
  conflict: ApiCheckInConflict,
): CheckInConflict {
  return {
    ...baseModel(conflict),
  };
}

function importValidationModel(
  importValidation: ApiImportValidation,
): ImportValidation {
  const val = {
    things: (importValidation?.things ?? []).map((t) => thingModel(t)),
    catalogs: (importValidation?.catalogs ?? []).map((c) => catalogModel(c)),
    validationStatuses: (importValidation?.validationStatuses ?? []).map((v) =>
      checkInConflictModel(v),
    ),
  };

  return {
    ...val,
    thingsByUuid: Object.fromEntries(
      val.things.map((thing) => [thing.uuid, thing]),
    ),
    catalogsByUuid: Object.fromEntries(
      val.catalogs.map((catalog) => [catalog.uuid, catalog]),
    ),
    validationStatusesByUuid: Object.fromEntries(
      val.validationStatuses.map((v) => [v.thingUuid, v.importProblems]),
    ),
  };
}

/**
 * Formats the API return into a exportBatch object
 */
export function exportBatchModel(exportBatch: ApiExportBatch): ExportBatch {
  return {
    ...baseModel(exportBatch),
    downloadFileName: `${exportBatch.name}.zip`,
    placeholder: {
      name: exportBatch.name,
      description: exportBatch.description,
      thingCount: exportBatch.thingCount,
      uuid: exportBatch.uuid,
    },
    hasConflicts: !!exportBatch.importValidation?.validationStatuses?.some(
      (s) => s.importProblems.length,
    ),
    importValidation: importValidationModel(exportBatch.importValidation),
  };
}
