/**
 * Each route must be declared in the ROUTES export. They can be functional
 * Each route needs a corresponding entry in the routeTitles function to generate a title for the nav
 * Route titles can reference data objects from a UUID using a schema composed of UUID_TYPES
 */

export const ROUTES = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  SELECT_ORG: '/select-org',
  CATALOGS: '/catalogs',
  /** For matching and redirecting only, not a navigable target route */
  CATALOG_ROOT: '/catalogs/:catalogId',
  SCAN: '/catalogs/account/scanner',
  TRANSACTIONS: '/catalogs/account/transactions',
  TRANSACTION: (transactionId: string) =>
    `/catalogs/account/transactions/${transactionId}`,
  THINGS_ACCOUNT: '/catalogs/account/things',
  THINGS_ADD: '/catalogs/account/things/add',
  /** Pseudo-route, only here for tracking */
  THINGS_ADD_STEP_SCAN: '/catalogs/account/things/add?step=scan',
  THINGS: (catalogId: string) => `/catalogs/${catalogId}/things`,
  THING: (thingId: string) => `/things/${thingId}`,
  THING_EDIT_PARENT: (thingId: string) => `/things/${thingId}/parent`,
  THING_EDIT_CHILDREN: (thingId: string) => `/things/${thingId}/children`,
  THING_REDIRECT: (catalogId: string, thingId: string) =>
    `/catalogs/${catalogId}/things/${thingId}`,
  ADMIN_GATE: '/admin',
  ADMIN_ACCOUNTS: '/admin/accounts',
  ADMIN_CHECKIN: '/admin/checkin',
  ADMIN_CHECKOUT_HISTORY: '/admin/checkouts',
  ADMIN_CHECKOUT_BATCH: (batchUuid: string) => `/admin/checkouts/${batchUuid}`,
  ADMIN_GROUPS: '/admin/groups',
  ADMIN_GROUP_DETAILS: (groupId: string) => `/admin/group/${groupId}`,
  ADMIN_USERS: '/admin/users',
  ADMIN_USERS_ADD: '/admin/users/add',
  ADMIN_USERS_EDIT: (userId: string) => `/admin/users/${userId}`,
  ADMIN_FACILITIES: '/admin/facilities',
  ADMIN_FACILITIES_ADD: '/admin/facilities/add',
  ADMIN_FACILITIES_EDIT: (facilityId: string) =>
    `/admin/facilities/${facilityId}`,
  ADMIN_THING_TYPES: '/admin/templates',
  ADMIN_CREATE_THING_TYPE: '/admin/thing-types/add',
  ADMIN_CLONE_THING_TYPE: (thingTypeUuid: string) =>
    `/admin/thing-types/add?copy=${thingTypeUuid}`,
  ADMIN_EDIT_THING_TYPE: (thingTypeUuid: string) =>
    `/admin/thing-types/${thingTypeUuid}`,
  ANTI_TAMPER_REVIEW: (thingUuid: string, transactionUuid: string) =>
    `/things/${thingUuid}?antiTamperTransaction=${transactionUuid}`,
  UNAUTHORIZED: '/unauthorized',
});

/** NOTE: If adding more, see component PageTitle */
export const UUID_TYPES = Object.freeze({
  THING: 'thing',
  CATALOG: 'catalog',
});

type UuidRouteType = (typeof UUID_TYPES)[keyof typeof UUID_TYPES];

/** Route info for analytic events. Ensure no user data (besides ids) is sent to Mixpanel, null means "don't track" */
export type TrackingConfig = {
  /** Used for the 'Page Name' prop value */
  name: string;
  /** Define correspond property names (or null to skip) for any UUIDs that
   * appear in the corresponding route matching the config. */
  uuidPropNames?: Array<string | null>;
};

/* NOTE - use null for routes that should not be considered as page views - they are skipped when considering navigation paths */
const trackingConfigs: {
  [Key in keyof typeof ROUTES]: TrackingConfig | null;
} = {
  ROOT: null,
  LOGIN: { name: 'Login' },
  SELECT_ORG: { name: 'Select Organization' },
  CATALOGS: {
    name: 'Catalogs',
  },
  CATALOG_ROOT: null,
  SCAN: {
    name: 'Scanner Landing',
  },
  TRANSACTIONS: {
    name: 'Transactions',
  },
  TRANSACTION: {
    name: 'Transaction Details',
    uuidPropNames: ['Transaction ID'],
    // TODO (story pending) non-route props:
    //   'Transaction Type': transaction?.transactionType,
    //   'Transaction Outcome': transaction?.outcomeStatus,
    //   'Thing ID': transaction.thingUuid,
  },
  THINGS_ACCOUNT: {
    name: 'Things',
  },
  THINGS_ADD: {
    name: 'Add Thing Details',
  },
  THINGS_ADD_STEP_SCAN: {
    name: 'Add Things - Bind to Dust',
  },
  THINGS: {
    name: 'Thing Listing',
    uuidPropNames: ['Catalog ID'],
  },
  THING: {
    name: 'Thing Detail',
    uuidPropNames: ['Thing ID'],
  },
  THING_REDIRECT: {
    name: 'Thing Detail (old catalog route)',
    uuidPropNames: ['Catalog ID', 'Thing ID'],
  },
  THING_EDIT_PARENT: {
    name: 'Thing Edit Parent',
    uuidPropNames: ['Thing ID'],
  },
  THING_EDIT_CHILDREN: {
    name: 'Thing Edit Children',
    uuidPropNames: ['Thing ID'],
  },
  ADMIN_GATE: null,
  ADMIN_ACCOUNTS: {
    name: 'Accounts',
  },
  ADMIN_CHECKIN: {
    name: 'Checkin',
  },
  ADMIN_CHECKOUT_HISTORY: {
    name: 'Checkout History',
  },
  ADMIN_CHECKOUT_BATCH: {
    name: 'Checkout Batch',
  },
  ADMIN_GROUPS: {
    name: 'User Groups',
  },
  ADMIN_GROUP_DETAILS: {
    name: 'Edit User Groups',
    uuidPropNames: ['User group ID'],
  },
  ADMIN_USERS: {
    name: 'Users',
  },
  ADMIN_USERS_ADD: {
    name: 'Add Users',
  },
  ADMIN_USERS_EDIT: {
    name: 'Edit Users',
    uuidPropNames: ['Editing User ID'], // NOTE: don't use User ID, that's mixpanel
  },
  ADMIN_FACILITIES: {
    name: 'Facilities',
  },
  ADMIN_FACILITIES_ADD: {
    name: 'Add Facility',
  },
  ADMIN_FACILITIES_EDIT: {
    name: 'Edit Facility',
    uuidPropNames: ['Facility ID'],
  },
  ADMIN_THING_TYPES: {
    name: 'Thing Type',
  },
  ADMIN_CREATE_THING_TYPE: {
    name: 'Create Thing Type',
  },
  ADMIN_CLONE_THING_TYPE: {
    name: 'Copy Thing Type',
    uuidPropNames: ['Source Thing Type ID'],
  },
  ADMIN_EDIT_THING_TYPE: {
    name: 'Edit Thing Type',
    uuidPropNames: ['Thing Type ID'],
  },
  ANTI_TAMPER_REVIEW: {
    name: 'Anti Tamper Review',
    uuidPropNames: ['thingUuid', 'transactionUuid'],
  },
  UNAUTHORIZED: {
    name: 'Unauthorized',
  },
};

// TODO: This approach would not be necessary if we used
// React Router's new data routing APIs (even if we didn't
// use data routing itself) - with the new APIs, React
// Router would provide the ability to obtain current
// matches from any component under the router, not just
// route children.
const UUID_PATTERN =
  /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi;
const uuidMarker = '<UUID>';

const routePatternToTrackingConfig: Record<string, TrackingConfig | null> =
  Object.fromEntries(
    Object.entries(ROUTES).map(([key, value]) => {
      // Turn all the route entries (some of which are functions) into a single string -> TrackingConfig lookup.
      const uuidMarkerArgsArray = Array.from(Array(value.length)).map(
        () => uuidMarker,
      );
      return [
        typeof value === 'string'
          ? value
          : // Call functions with uuidMarker's for params
            (value as (...x: any[]) => string)(...uuidMarkerArgsArray),
        trackingConfigs[key as keyof typeof ROUTES],
      ];
    }),
  );

/** Retrieve the tracking config per route - routeSearch is used if exists in config (for cases like `?step=scan`, or `add?copy=${thingTypeUuid}` */
export function getTrackingRouteInfo(
  routePathname: string,
  routeSearch: string,
): { config: TrackingConfig; uuidProps: Record<string, string> } | null {
  const pathPattern = routePathname.replace(UUID_PATTERN, uuidMarker);
  const searchPattern = routeSearch.replace(UUID_PATTERN, uuidMarker);

  const routePattern =
    (pathPattern + searchPattern in routePatternToTrackingConfig &&
      pathPattern + searchPattern) ||
    (pathPattern in routePatternToTrackingConfig && pathPattern) ||
    false;

  if (!routePattern) return null;
  const config = routePatternToTrackingConfig[routePattern];
  if (!config) return null;

  let uuidProps;
  if (config.uuidPropNames != null) {
    const routeWithSearch = routePathname + routeSearch;
    const matches = routeWithSearch.match(UUID_PATTERN);
    if (matches == null || matches.length < config.uuidPropNames.length) {
      throw new Error(
        `Frontend route tracking config error - uuidPropNames has more entries than route uuids matched at runtime. routeWithSearch was "${routeWithSearch}"`,
      );
    }
    uuidProps = Object.fromEntries(
      config.uuidPropNames.map((propName, index) => [propName, matches[index]]),
    );
  } else {
    uuidProps = null;
  }
  return { config, uuidProps };
}

type RouteTitle = {
  /** title generation function that takes the schema data */
  title: false | ((uuidData: any[]) => Exclude<React.ReactNode, ''>);
  topNavigationTitle?: (uuidData: any[]) => React.ReactNode;
  /** list of UUID types to provide data for, should correspond to title */
  schema?: UuidRouteType[];
  /** parent URL to return to */
  // parent?: string;
  /** Previous page to navigate to */
  // backButtonRoute?: string;
};

/**
 * Get the route title information
 */
export function routeTitles(route: string): RouteTitle {
  const baseRoute = route.replace(UUID_PATTERN, uuidMarker);
  // (for parent, if used) const uuids = route.match(UUID_PATTERN);

  /* NOTE - try to keep in order, use only the values from the ROUTES object as
   * the keys for this structure - NO inline strings as possible sources of
   * drift */

  /* Also NOTE - if title or topNavigationTitle accepts any params, they must be included in the `schema` */
  const ROUTE_TITLES: {
    [key: string]: RouteTitle;
  } = {
    [ROUTES.CATALOGS]: {
      title: () => 'Catalogs',
      // (as parent) topNavigationTitle: () => 'Catalogs',
    },
    [ROUTES.SCAN]: {
      title: () => '',
      topNavigationTitle: () => 'Scan Thing',
      // parent: ROUTES.SCAN,
      // backButtonRoute: ROUTES.THINGS_ACCOUNT,
    },
    // TODO: If we want the back button to return to scan then use the below
    // '/catalogs/account/scanner/things/<uuid>': {
    //   title: ([thing]) => `${thing?.title ?? ''}`,
    //   schema: [UUID_TYPES.THING],
    //   parent: ROUTES.SCAN,
    // },
    [ROUTES.TRANSACTIONS]: {
      title: () => 'Transactions',
      // (as parent) topNavigationTitle: () => 'All Transactions',
    },
    [ROUTES.TRANSACTION(uuidMarker)]: {
      title: () => 'Transaction Details',
      // parent: ROUTES.TRANSACTIONS,
    },
    [ROUTES.THINGS_ACCOUNT]: {
      title: () => 'Things',
    },
    [ROUTES.THINGS_ADD]: {
      title: () => '',
      topNavigationTitle: () => 'Add Thing(s)',
    },
    [ROUTES.THINGS(uuidMarker)]: {
      title: ([catalog]) => (catalog as Catalog).name,
      schema: [UUID_TYPES.CATALOG],
      // parent: ROUTES.CATALOGS,
      // (as parent) topNavigationTitle: ([catalog]) => (catalog as Catalog).name,
    },
    [ROUTES.THING(uuidMarker)]: {
      title: false,
    },
    [ROUTES.THING_REDIRECT(uuidMarker, uuidMarker)]: {
      title: false,
    },
    [ROUTES.THING_EDIT_PARENT(uuidMarker)]: {
      title: ([thing]) => `Editing Parent Relationship of ${thing?.title}`,
      schema: [UUID_TYPES.THING],
      // parent: ROUTES.THINGS(uuids?.[0] ?? ''),
    },
    [ROUTES.THING_EDIT_CHILDREN(uuidMarker)]: {
      // NOTE: design has thing title bold, but using h3 for title
      // renders this pointless, leaving in for potential change.
      title: ([thing]) => `Editing Child Relationships of ${thing?.title}`,
      schema: [UUID_TYPES.THING],
      // parent: ROUTES.THINGS(uuids?.[0] ?? ''),
    },
    [ROUTES.ADMIN_ACCOUNTS]: {
      // TODO: this may not be used in the future
      title: () => 'Accounts',
    },
    [ROUTES.ADMIN_CHECKIN]: {
      title: () => '',
      topNavigationTitle: () => 'Check In',
    },
    [ROUTES.ADMIN_CHECKOUT_HISTORY]: {
      title: () => 'Checkout History',
    },
    [ROUTES.ADMIN_CHECKOUT_BATCH(uuidMarker)]: {
      title: () => 'Recover Things',
    },
    [ROUTES.ADMIN_GROUPS]: {
      title: () => 'User Groups',
      // (parent) topNavigationTitle: () => 'All User Groups',
    },
    [ROUTES.ADMIN_GROUP_DETAILS(uuidMarker)]: {
      title: false,
      schema: [],
      // parent: ROUTES.ADMIN_GROUPS,
      topNavigationTitle: () => 'User Groups' /* matches ADMIN_GROUPS title */,
    },
    [ROUTES.ADMIN_USERS]: {
      title: () => 'Users',
      // (as parent) topNavigationTitle: () => 'All Users',
    },
    [ROUTES.ADMIN_USERS_ADD]: {
      title: () => 'Invite New User',
    },
    [ROUTES.ADMIN_USERS_EDIT(uuidMarker)]: {
      title: () => 'Edit User',
      // parent: ROUTES.ADMIN_USERS,
    },
    [ROUTES.ADMIN_FACILITIES]: {
      title: () => 'Facilities',
      // (as parent) topNavigationTitle: () => 'All Facilities',
    },
    [ROUTES.ADMIN_FACILITIES_ADD]: {
      title: () => 'Create New Facility',
    },
    [ROUTES.ADMIN_FACILITIES_EDIT(uuidMarker)]: {
      title: () => 'Edit Facility',
      // parent: ROUTES.ADMIN_FACILITIES,
    },
    [ROUTES.ADMIN_THING_TYPES]: {
      title: () => 'Thing Types',
      // (as parent) topNavigationTitle: () => 'All Thing Types',
    },
    [ROUTES.ADMIN_CREATE_THING_TYPE]: {
      title: () => 'Create Thing Type',
    },
    [ROUTES.ADMIN_CLONE_THING_TYPE(uuidMarker)]: {
      // TODO: this title/route doesn't seem to be working
      title: () => 'Copy Thing Type',
    },
    [ROUTES.ADMIN_EDIT_THING_TYPE(uuidMarker)]: {
      title: () => 'Edit Thing Type',
      // parent: ROUTES.ADMIN_THING_TYPES,
    },
    [ROUTES.UNAUTHORIZED]: {
      title: false,
    },
  };
  const fallback = { title: () => route };

  return ROUTE_TITLES[baseRoute] ?? fallback;
}

type StyleOverride = {
  /** classNames to be added to the page element */
  pageClassOverrides: string;
};

const EMPTY_STYLE_OVERRIDE = {
  pageClassOverrides: '',
};

/**
 * Get the route title information
 */
export function pageStyleOverrides(route: string): StyleOverride {
  const baseRoute = route.replace(UUID_PATTERN, uuidMarker);

  /* NOTE - try to keep in order, use only the values from the ROUTES object as
   * the keys for this structure - NO inline strings as possible sources of
   * drift */
  const ROUTE_STYLE_OVERRIDES: {
    [key: string]: StyleOverride;
  } = {
    [ROUTES.ADMIN_CHECKIN]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter page-no-padding',
    },
    [ROUTES.ADMIN_CHECKOUT_HISTORY]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
    [ROUTES.ADMIN_FACILITIES]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
    [ROUTES.ADMIN_FACILITIES_ADD]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
    [ROUTES.ADMIN_GROUPS]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
    [ROUTES.ADMIN_USERS]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
    [ROUTES.THINGS_ADD]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter page-no-padding',
    },
    [ROUTES.THINGS(uuidMarker)]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
    [ROUTES.TRANSACTIONS]: {
      pageClassOverrides: 'page-auto-scrollbar-gutter',
    },
  };

  return ROUTE_STYLE_OVERRIDES[baseRoute] ?? EMPTY_STYLE_OVERRIDE;
}
