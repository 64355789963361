import { SyntheticEvent } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, TextField, TextFieldProps } from '@mui/material';

import useDebouncedCallback from '@/common/hooks/useDebouncedCallback';

function Adornment({ loading }: { loading?: boolean }) {
  return loading ? (
    <div style={{ display: 'flex', padding: '2px', marginRight: '5px' }}>
      <CircularProgress color="inherit" size="20px" />
    </div>
  ) : (
    <SearchIcon style={{ marginRight: '5px' }} />
  );
}

type Props = TextFieldProps & {
  setValue: (e: string) => void;
  isLoading?: boolean;
  debounceDelay?: number;
};

export default function DustSearchInput({
  setValue,
  isLoading = false,
  debounceDelay = 300,
  ...props
}: Props) {
  const onSearchChange = useDebouncedCallback(
    (evt: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      // evt.target instead of currentTarget due to using MUI
      setValue((evt.target as HTMLInputElement).value.trim()),
    debounceDelay,
  );

  return (
    <TextField
      InputProps={{
        startAdornment: <Adornment loading={isLoading} />,
      }}
      name="search"
      onChange={onSearchChange}
      placeholder="Search..."
      size="small"
      type="search"
      variant="outlined"
      {...props}
      sx={{
        width: '20rem',
        marginRight: '1rem',
        '& .MuiOutlinedInput-notchedOutline': {
          top: '-10px',
        },
        ...props.sx,
      }}
    />
  );
}
