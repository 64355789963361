import { useEffect, useState, useMemo } from 'react';

import { TextField, Button, Tooltip } from '@mui/material';
import { useFormik } from 'formik';

import {
  changeMetadataField,
  changeTypedMetadataField,
} from '@/common/entities/things/models';
import { thingMetaSchema } from '@/common/entities/things/schemas';
import {
  filterRestrictedFields,
  getTypedDataFields,
} from '@/common/entities/things/utility';
import { normalizeString } from '@/common/utility';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import { commonProps, Mixpanel } from '@/mPanel';
import useRequest from '@/services/requests/useRequest';

import styles from './ThingSidebar.module.css';

/** Intent here is to match the same logic we use on the top of ThingDataFields */
function getDataFieldCount(thing: Thing) {
  return (
    Object.keys(filterRestrictedFields(thing.metadata)).length +
    getTypedDataFields(thing).length
  );
}

const createInitialValues = (
  thing: Thing,
  thingType: ThingType | null | undefined,
  isTyped: boolean | undefined,
  key: string | null,
) => ({
  value:
    (key &&
      (isTyped ? thing?.typedMetadata?.[key].value : thing.metadata[key])) ??
    '',
  name: (key && (isTyped ? thing.typedMetadata[key].name : key)) || '',
  required: thingType?.fieldTypes?.find((t) => t.uuid === key)?.required,
});

type Props = {
  thing: Thing;
  thingType?: ThingType | null;
  data: { key: string | null; isTyped?: boolean };
  onClose: () => void;
};

export default function EditDataFields({
  thing,
  thingType,
  data,
  onClose,
}: Props) {
  const { isTyped, key } = data;
  const { thingsApi } = useRequest();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemove = async () => {
    if (key === null) throw new Error('Cannot remove null key');
    const { [key]: removedValue, ...rest } = isTyped
      ? thing.typedMetadata
      : thing.metadata;

    setIsRemoving(true);
    const res = await thingsApi.updateMetadata({
      thingUuid: thing.uuid,
      catalogUuid: thing.catalogUuid,
      metadata: isTyped ? thing.metadata : rest,
      typedMetadata: isTyped ? rest : thing.typedMetadata,
    });
    setIsRemoving(false);

    if (!res.error) {
      Mixpanel.track('Remove Thing Data Field', {
        [commonProps.numberOfDataFieldsAtLoad]: getDataFieldCount(thing),
      });
      onClose();
    } else {
      Mixpanel.track('Remove Thing Data Field Failed', {
        [commonProps.numberOfDataFieldsAtLoad]: getDataFieldCount(thing),
        'Reason of Failure':
          res.message ?? res.originalError?.message ?? 'unknown',
      });
    }
  };

  const handleClose = () => {
    if (!isSubmitting && !isRemoving) {
      onClose();
    }
  };

  // ---------------------------------------------------------------------------
  // FORMIK HANDLING

  const validationSchema = useMemo(() => {
    // We filter to pull out the currently edited field
    const forbiddenFieldNames = Object.keys(thing.metadata)
      .filter((k) => k !== key)
      .map(normalizeString);

    return thingMetaSchema(forbiddenFieldNames);
  }, [key, thing.metadata]);

  const formik = useFormik({
    initialValues: createInitialValues(thing, thingType, isTyped, key),
    validationSchema,
    validateOnChange: true,
    onSubmit: async (formValues) => {
      if (isSubmitting || isRemoving) return;

      const metadata = isTyped
        ? thing.metadata
        : changeMetadataField(
            thing.metadata,
            key,
            formValues.name,
            formValues.value,
          );

      if (isTyped === true && key === null) {
        throw new Error('Cannot change a null key value for typed field');
      }
      const typedMetadata =
        isTyped && key
          ? changeTypedMetadataField(thing.typedMetadata, key, formValues.value)
          : thing.typedMetadata;

      setIsSubmitting(true);
      const res = await thingsApi.updateMetadata({
        thingUuid: thing.uuid,
        catalogUuid: thing.catalogUuid,
        metadata,
        typedMetadata,
      });
      setIsSubmitting(false);

      const originalValues = createInitialValues(
        thing,
        thingType,
        isTyped,
        key,
      );

      const mixpanelEventName =
        key !== null ? 'Edit Thing Data Field' : 'Add Thing Data Field';

      const mixpanelData =
        key !== null
          ? {
              [commonProps.numberOfDataFieldsAtLoad]: getDataFieldCount(thing),
              'Field name changed': originalValues.name !== formValues.name,
              'Field value changed': originalValues.value !== formValues.value,
              'Data Type': isTyped ? 'Typed' : 'Untyped',
            }
          : {
              [commonProps.numberOfDataFieldsAtLoad]: getDataFieldCount(thing),
              'Field Value Added': !!formValues.value,
            };
      if (!res.error) {
        onClose();
        Mixpanel.track(mixpanelEventName, { ...mixpanelData });
      } else {
        Mixpanel.track(`${mixpanelEventName} Failed`, {
          ...mixpanelData,
          'Reason of Failure':
            res.message ?? res.originalError?.message ?? 'unknown',
        });
      }
    },
  });
  const { setValues } = formik;

  useEffect(() => {
    void setValues(createInitialValues(thing, thingType, isTyped, key));
  }, [setValues, thing, thingType, isTyped, key]);

  return (
    <div className={styles.sidebarContent}>
      <form className={styles.sidebarForm} onSubmit={formik.handleSubmit}>
        <Tooltip
          /** If the key exists in metadata we always hide the tooltip */
          open={
            isTyped && key && typeof thing?.metadata?.[key] === 'undefined'
              ? undefined /* show tooltip */
              : false /* disable toolitp */
          }
          enterDelay={500}
          title="The field title is set by the Thing Type"
        >
          <TextField
            disabled={isTyped}
            error={formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            label="Field Name*"
            margin="normal"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            sx={{ maxWidth: 'var(--input-max-width, 16rem)' }}
            value={formik.values.name}
            variant="outlined"
            fullWidth
          />
        </Tooltip>
        <TextField
          error={formik.touched.value && !!formik.errors.value}
          helperText={String(
            (formik.touched.value && formik.errors.value) ?? '',
          )}
          label={
            !isTyped
              ? 'Field Value'
              : `Field Value${formik.values?.required ? '*' : ''}`
          }
          margin="normal"
          name="value"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          sx={{ maxWidth: 'var(--input-max-width, 16rem)' }}
          value={formik.values.value}
          variant="outlined"
          fullWidth
        />
        <div className="flex-row gap-1 mt-1">
          <Button onClick={handleClose}>Cancel</Button>
          <DustLoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
          >
            Apply
          </DustLoadingButton>
        </div>
      </form>
      <div className="divider-2" />
      {!isTyped && key && (
        <>
          <h2 className="h4 error">Remove Field</h2>
          <p className="mb-1 mt-1">
            Clicking the button below will remove this data field.
          </p>
          <DustLoadingButton
            color="error"
            loading={isRemoving}
            onClick={onRemove}
          >
            Remove
          </DustLoadingButton>
        </>
      )}
    </div>
  );
}
