import { arrayUnique } from '@/common/utility';

import { ACCEPTED_MIME_TYPES, FILE_EXTENSIONS_FROM_MIME } from './constants';

/**
 * Combine React classes with conditional checking
 */
export function combineClass(
  ...entries: (string | boolean | null | undefined)[]
): string {
  const className = entries
    .filter((item) => item && typeof item === 'string')
    .join(' ');
  return className;
}

function getRegExpFlags(regExp: RegExp) {
  if (typeof regExp.flags === 'string') {
    return regExp.flags;
  }
  const flags = [];
  if (regExp.global) flags.push('g');
  if (regExp.ignoreCase) flags.push('i');
  if (regExp.multiline) flags.push('m');
  if (regExp.sticky) flags.push('y');
  if (regExp.unicode) flags.push('u');
  return flags.join('');
}

/** Deep copy an object */
export function clone<T>(obj: T): T {
  if (typeof obj === 'function') {
    return obj;
  }
  const result: any = Array.isArray(obj) ? [] : {};

  // eslint-disable-next-line no-restricted-syntax,guard-for-in
  for (const key in obj) {
    // include prototype properties
    const value = obj[key];
    const type = {}.toString.call(value).slice(8, -1);
    if (type === 'Array' || type === 'Object') {
      result[key] = clone(value);
    } else if (type === 'Date') {
      result[key] = new Date((value as Date).getTime());
    } else if (type === 'RegExp') {
      result[key] = RegExp(
        (value as RegExp).source,
        getRegExpFlags(value as RegExp),
      );
    } else {
      result[key] = value;
    }
  }
  return result as T;
}

/**
 * Get a deeply nested value with a dot separated path
 */
export function getNested<T extends object>(obj: T, path: string) {
  return path
    ?.split('.')
    .reduce((acc: any, part: string | number) => acc && acc[part], obj);
}

/**
 * Set a deeply nested value with a dot separated path
 */
export function setNested<T extends object>(
  val: any,
  obj: T,
  path: string,
  immutable = false,
) {
  const objResult = immutable ? clone(obj) : obj;
  let place: { [x: string]: any } = objResult;

  const keys = path.split('.');
  keys.forEach((key: string, idx) => {
    if (idx === keys.length - 1) {
      try {
        place[key] = val;
      } catch {
        console.warn('Invalid set location: ', place, path);
      }
    } else {
      place = place?.[key];
    }
  });

  return objResult;
}

export function mimeTypesFromFileType(
  fileType: keyof typeof ACCEPTED_MIME_TYPES,
) {
  return Object.fromEntries(
    ACCEPTED_MIME_TYPES[fileType]
      .split(',')
      .map((mime) => [
        mime.trim(),
        [`.${FILE_EXTENSIONS_FROM_MIME[mime.trim()]}`],
      ]),
  );
}

export function extensionsFromFileType(
  fileType: keyof typeof ACCEPTED_MIME_TYPES,
) {
  return arrayUnique(
    ACCEPTED_MIME_TYPES[fileType]
      .split(',')
      .map((v) => `.${FILE_EXTENSIONS_FROM_MIME[v.trim()]}`),
  );
}
