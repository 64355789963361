import DustLoader from '@/components/Library/DustLoader';

import styles from './CheckIn.module.css';

export default function Loading() {
  return (
    <div className={styles.centeredContentWrapper}>
      <DustLoader size="large" />
    </div>
  );
}
