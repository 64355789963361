import { nanoid } from 'nanoid';

export const DEFAULT_CSV_DATA = {
  HEADER: ['rowUID', 'DICE Quantity', 'Title'],
  DATA: () => [[nanoid(), 1, '']],
};

export const ERRORS = {
  INVALID_FILE_TYPE: 'Invalid file type. ',
  HEADER_EMPTY:
    'All columns must have a valid header name. Please provide a name for the column. ',
  DUPLICATE_HEADER: 'Header names must be unique. ',
  DUPLICATE_TITLE:
    'Both a DICE title and a title field were provided. The title field is restricted and has been suffixed.',
  DUPLICATE_HEADER_IMPORT:
    'Duplicate header fields detected. A numeric suffix was added to prevent conflicts.',
  DUPLICATE_TITLE_FIELD:
    '"title" is a reserved field name. Please add a suffix/prefix and try again.',
  SHORT_HEADER: 'Field name must be at least 2 characters',
  LONG_HEADER: 'Field name cannot be more than 256 characters',
  SHORT_VALUE: 'Field value must be at least 2 characters',
  LONG_VALUE: 'Field value cannot be more than 500 characters',
  TO_MANY_THINGS:
    'Total Thing count (rows + DICE quantity) must not exceed 10000 Things.',
  TO_MANY_THINGS_CSV:
    'Total Thing count (rows + DICE quantity) must not exceed 10000 Things. Try breaking the CSV file into multiple imports',
};

export const FIELD_NAMES = {
  DICE_TITLE: 'DICE Title',
  ROW_ID: 'rowUID',
  DICE_QTY: 'DICE Quantity',
  TITLE: 'Title',
};
