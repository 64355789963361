import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  BusinessOutlined as BusinessOutlinedIcon,
  LoginOutlined as LoginOutlinedIcon,
  ModeEditOutlined as ModeEditOutlinedIcon,
  MyLocationOutlined as MyLocationOutlinedIcon,
  PermIdentity as PermIdentityIcon,
  PersonOutlined as PersonOutlinedIcon,
  PrecisionManufacturing as PrecisionManufacturingIcon,
  HelpOutlineOutlined as HelpIcon,
  WorkOutline as WorkOutlineIcon,
} from '@mui/icons-material';
import {
  Link as MaterialLink,
  ListItem,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { validate as validateUuid } from 'uuid';

import useFacilitiesData from '@/common/entities/facilities/useFacilitiesData';
import useFacilityData from '@/common/entities/facilities/useFacilityData';
import { ACCOUNT_SETTING_OPTIONS } from '@/common/entities/orgs/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import useActiveUser from '@/common/entities/users/useActiveUser';
import useAdminPrivileges from '@/common/entities/users/useAdminPrivileges';
import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import { ROUTES } from '@/common/routes';
import FacilityModal from '@/components/Composed/FacilitySelector/FacilitySelector';
import EditUserForm from '@/components/Composed/TopNav/EditUserForm';
import DustLoader from '@/components/Library/DustLoader';
import VerifiedShield from '@/components/Library/Svg/VerifiedShield';
import { Mixpanel } from '@/mPanel';
import {
  ignoreFacilityAtom,
  selectedFacilityAtom,
} from '@/state/atoms/facility';
import { geolocationAtom } from '@/state/atoms/geolocation';

import styles from './ProfileMenu.module.css';

const sxs = {
  menuItem: {
    fontSize: 'var(--font-size-h5)',
    width: '14rem',
    whiteSpace: 'normal',
    color: 'var(--dark-grey)',
  },
  menuIcon: {
    width: '0.75rem',
    height: '0.75rem',
    color: 'var(--dark-grey)',
  },
  paper: {
    '& .MuiList-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  menu: { pb: 0, width: '16rem' },
  footerItem: {},
};
sxs.footerItem = { ...sxs.menuItem, backgroundColor: 'var(--bg-footer)' };

export default function ProfileMenu() {
  const { canAccessAdminPanel } = useAdminPrivileges();
  const [menuElement, setMenuElement] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const { activeUser } = useActiveUser();
  const [showEditProfile, setShowEditProfile] = useState(false);

  const auth = useAuth();
  const { org } = useOrgData();
  const allowLocation = useMemo(
    () =>
      org &&
      org.transactionLocationSetting !== ACCOUNT_SETTING_OPTIONS.RESTRICTED,
    [org],
  );

  const allowFacility = useMemo(
    () =>
      org &&
      org.transactionFacilitySetting !== ACCOUNT_SETTING_OPTIONS.RESTRICTED,
    [org],
  );

  // Handle location
  // Save to recoil and recheck whenever the organization changes
  const [location, setLocation] = useRecoilState(geolocationAtom);
  useEffect(() => {
    // Either save the location to recoil state or clear the state
    if (allowLocation) {
      const geoChange: PositionCallback = ({ coords }) =>
        setLocation({
          lat: coords.latitude.toFixed(5),
          lon: coords.longitude.toFixed(5),
        });

      navigator.geolocation.getCurrentPosition(geoChange);
    } else {
      setLocation(null);
    }
  }, [allowLocation, setLocation]);

  const { isPageVerified } = useVerifiedSession();

  const handleClose = () => setMenuElement(null);
  const handleProfileIconClick = (event: React.SyntheticEvent<HTMLElement>) =>
    setMenuElement(event.currentTarget);

  // ------------------------------------------------------------------------------------
  // Handle facility modal
  const [currentFacilityUuid, setCurrentFacilityUuid] =
    useRecoilState(selectedFacilityAtom);

  const { facility, isLoading: isFacilityLoading } = useFacilityData({
    facilityUuid: currentFacilityUuid ?? '',
    enabled: !!currentFacilityUuid && allowFacility === true,
  });

  const { isLoading: facilitiesLoading, facilities } = useFacilitiesData({
    enabled: !currentFacilityUuid && allowFacility === true,
  });

  const [isFacilityOpen, setFacilityOpen] = useState(false);

  // Option to dismiss facility dialog on first login for optional account settings
  const [ignoreFacility, setIgnoreFacility] =
    useRecoilState(ignoreFacilityAtom);

  const handleFacilityClick = () => {
    if (!facilitiesLoading && facilities.length === 0 && canAccessAdminPanel) {
      navigate(ROUTES.ADMIN_FACILITIES_ADD);
    } else {
      setFacilityOpen(true);
    }
  };

  const handleFacilityDismiss = () => {
    // Persist the dismissal if facility is optional
    if (org?.transactionFacilitySetting === ACCOUNT_SETTING_OPTIONS.OPTIONAL) {
      setIgnoreFacility(true);
    }

    setFacilityOpen(false);
  };

  const handleLogout = useCallback(() => {
    Mixpanel.logout({
      logoutMethod: 'Log out button',
      facilityUuid: currentFacilityUuid,
    });
    void auth.signoutRedirect();
  }, [auth, currentFacilityUuid]);

  useEffect(() => {
    // Cannot use allowFacility because we don't want to reset if the account is loading
    if (
      org?.transactionFacilitySetting === ACCOUNT_SETTING_OPTIONS.RESTRICTED
    ) {
      // Clear facility uuid if restricted for a given user
      setCurrentFacilityUuid(null);
      return;
    }

    if (
      (!currentFacilityUuid || !validateUuid(currentFacilityUuid)) &&
      !ignoreFacility &&
      facilities.length > 0
    ) {
      setFacilityOpen(true);
    }
  }, [
    currentFacilityUuid,
    setCurrentFacilityUuid,
    org,
    ignoreFacility,
    facilities,
  ]);

  const facilitySection =
    (isFacilityLoading && <DustLoader size="small" />) ||
    (facility?.name ?? 'No Facility');
  const isMultiOrg =
    Object.keys(auth.user?.profile.organizations || {}).length > 1;

  const orgContentJsx = (
    <>
      <div className={styles.iconCol}>
        <WorkOutlineIcon sx={sxs.menuIcon} />
      </div>
      <div className={styles.menuText}>{org?.shortName}</div>
    </>
  );

  const name = `${activeUser?.firstName} ${activeUser?.lastName}`;
  return (
    <>
      <button
        type="button"
        className={styles.userIcon}
        onClick={handleProfileIconClick}
      >
        <PersonOutlinedIcon />
        {isPageVerified() && (
          <VerifiedShield className={styles.verifiedDecorator} />
        )}
      </button>
      <Menu
        anchorEl={menuElement}
        id="account-menu"
        onClick={handleClose}
        onClose={handleClose}
        open={!!menuElement}
        PaperProps={{ sx: sxs.paper }}
        sx={sxs.menu}
      >
        <div className={styles.footerItem}>
          This organization is managed by {org?.shortName}
        </div>
        <MenuItem sx={sxs.menuItem} onClick={() => setShowEditProfile(true)}>
          <div className={styles.iconCol}>
            <PermIdentityIcon sx={sxs.menuIcon} />
          </div>
          <div className={styles.menuText}>
            <Tooltip enterDelay={700} title={name}>
              <div>{name}</div>
            </Tooltip>
            <Tooltip enterDelay={700} title={activeUser?.username}>
              <div>{activeUser?.username}</div>
            </Tooltip>
          </div>
          <div className={styles.iconRight}>
            <ModeEditOutlinedIcon sx={sxs.menuIcon} />
          </div>
        </MenuItem>
        {isMultiOrg ? (
          <MenuItem sx={sxs.menuItem} component={Link} to={ROUTES.SELECT_ORG}>
            {orgContentJsx}
            <div className={styles.iconRight}>
              <ModeEditOutlinedIcon sx={sxs.menuIcon} />
            </div>
          </MenuItem>
        ) : (
          <ListItem sx={sxs.menuItem}>{orgContentJsx}</ListItem>
        )}
        {allowFacility && (
          <MenuItem onClick={handleFacilityClick} sx={sxs.menuItem}>
            <div className={styles.iconCol}>
              <BusinessOutlinedIcon sx={sxs.menuIcon} />
            </div>
            <div className={styles.menuText}>{facilitySection}</div>
            <div className={styles.iconRight}>
              <ModeEditOutlinedIcon sx={sxs.menuIcon} />
            </div>
          </MenuItem>
        )}
        {import.meta.env.VITE_DEV_PORTAL_URL &&
          (import.meta.env.VITE_DEV_PORTAL_URL as string).match(/\w/) && (
            <MenuItem
              sx={sxs.menuItem}
              // react-router-dom's Link won't work for an external URL
              component={MaterialLink}
              href={import.meta.env.VITE_DEV_PORTAL_URL}
              target="_blank"
            >
              <div className={styles.iconCol}>
                <PrecisionManufacturingIcon sx={sxs.menuIcon} />
              </div>
              <div className={styles.menuText}>Dev Portal</div>
            </MenuItem>
          )}
        {location && (
          <ListItem sx={sxs.menuItem}>
            <div className={styles.iconCol}>
              <MyLocationOutlinedIcon sx={sxs.menuIcon} />
            </div>
            <div className={styles.menuText}>
              Location {location.lat}, {location.lon}
            </div>
          </ListItem>
        )}
        <MenuItem
          component="a"
          href={import.meta.env.VITE_HELP_URL}
          target="_blank"
          sx={sxs.menuItem}
        >
          <div className={styles.iconCol}>
            <HelpIcon sx={sxs.menuIcon} />
          </div>
          <div className={styles.menuText}>Help</div>
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={sxs.menuItem}>
          <div className={styles.iconCol}>
            <LoginOutlinedIcon sx={sxs.menuIcon} />
          </div>
          <div className={styles.menuText}>Logout</div>
        </MenuItem>
        <div className={styles.footerItem}>
          <a
            className="text-link"
            href="https://dustidentity.com/terms-of-service"
            rel="noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
          <span className="text-sm">&nbsp;|&nbsp;</span>
          <a
            className="text-link"
            href="https://dustidentity.com/privacy-policy/"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </Menu>
      <FacilityModal
        open={isFacilityOpen}
        onSuccess={() => setFacilityOpen(false)}
        onDismiss={handleFacilityDismiss}
      />
      {activeUser && (
        <EditUserForm
          user={activeUser}
          open={showEditProfile}
          setOpen={setShowEditProfile}
        />
      )}
    </>
  );
}
