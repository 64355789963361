import { useParams } from 'react-router-dom';

import useThingMediaData from '@/common/entities/thingMedia/useThingMediaData';
import useThingTypeData from '@/common/entities/thingTypes/useThingTypeData';
import { mapFieldFiles } from '@/common/entities/thingTypes/utility';
import DustLoader from '@/components/Library/DustLoader';
import DustPageSnackbar, {
  SNACKBAR_STATUS,
} from '@/components/Library/DustPageSnackbar';

import AddEditThingType from './AddEditThingType';

function EditThingType() {
  const { typeId } = useParams();

  const {
    // TODO: use or remove isLoading: isLoadingType,
    isSuccess: isSuccessType,
    thingType,
  } = useThingTypeData({ thingTypeUuid: typeId });

  const {
    // TODO: use or remove isLoading: isMediaLoading,
    isSuccess: isMediaSuccess,
    thingMedia,
  } = useThingMediaData({
    uuids: thingType?.mediaUuids,
    enabled: isSuccessType && thingType?.mediaUuids.length > 0,
  });

  const loadingComplete =
    isSuccessType && (thingType?.mediaUuids.length === 0 || isMediaSuccess);

  return (
    <div className="page-container">
      {thingType?.thingsCount > 0 && (
        <DustPageSnackbar
          label={`This Thing type is applied to ${thingType?.thingsCount} Things.
        Updates will be reflected on all instances.`}
          status={SNACKBAR_STATUS.WARNING}
        />
      )}
      <div className="action-container">
        {loadingComplete ? (
          <AddEditThingType
            edit
            mediaFiles={mapFieldFiles(thingType, thingMedia)}
            thingType={thingType}
          />
        ) : (
          <DustLoader size="xlarge" sx={{ marginTop: '5rem' }} />
        )}
      </div>
    </div>
  );
}

export default EditThingType;
