import CatalogThing from './CatalogThing';

type Props = {
  catalog: Catalog;
  readOnly: boolean;
  things: ShoppingCartThings;
  shoppingCart: ShoppingCart;
};

export default function CatalogThings({
  catalog,
  readOnly,
  things,
  shoppingCart,
}: Props) {
  return (
    <>
      {things
        .thingsInCatalog(catalog.uuid)
        .sort((a: Thing, b: Thing) => a.title.localeCompare(b.title))
        .map((thing) => (
          <CatalogThing
            key={thing.uuid}
            readOnly={readOnly}
            thing={thing}
            shoppingCart={shoppingCart}
          />
        ))}
    </>
  );
}
