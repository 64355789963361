import { ApiOrg, OrgIdentity } from './typedefs';
import baseModel from '../baseModel';

export function orgModel(data: ApiOrg): Org {
  return {
    ...baseModel(data),
    isFeatureEnabled: (feature) => !!data.features[feature],
  };
}

export function orgIdentityModel(data: OrgIdentity): OrgIdentity {
  return {
    ...baseModel(data),
  };
}
