import React from 'react';

import { Box, Typography } from '@mui/material';

import AntiTamperAnalysisConfidenceLevel from './AntiTamperAnalysisConfidenceLevel';
import styles from './AntiTamperSideBar.module.css';
import AntiTamperSideBarFooter from './AntiTamperSideBarFooter';
import AntiTamperUserConfirmForm from './AntiTamperUserConfirmForm';

type AntiTamperSideBarProps = {
  matchingMarkerAction: React.ReactNode;
  missingMarkerAction: React.ReactNode;
  modifiedMarkerAction: React.ReactNode;
  background: React.ReactNode;
  handleCancelButton: () => void;
  handleSaveButton: () => void;
  isScanLoaded: boolean;
  isVerifiedSession: boolean;
  riskScore: number;
  isReadOnly: boolean;
  observedResult: string;
  comment: string;
  setObservedResult: (r: string) => void;
  setComment: (c: string) => void;
};

export default function AntiTamperSideBar({
  matchingMarkerAction,
  missingMarkerAction,
  modifiedMarkerAction,
  handleCancelButton,
  handleSaveButton,
  isScanLoaded,
  isVerifiedSession,
  background,
  riskScore,
  isReadOnly,
  observedResult,
  setObservedResult,
  comment,
  setComment,
}: AntiTamperSideBarProps) {
  return (
    <Box className={styles.root} component="div">
      <Box className={styles.wrapper}>
        <Box className={styles.overflowContainer}>
          <Box className={styles.sideBarContainer}>
            <Box className={styles.sideBarHeader}>
              <Typography component="h5" className={styles.sideBarTitle}>
                DUST Analysis
              </Typography>
            </Box>
            <Box className={styles.sideBarActionSection}>
              {matchingMarkerAction}
              {missingMarkerAction}
              {modifiedMarkerAction}
              {background}
            </Box>
            {isVerifiedSession && (
              <AntiTamperAnalysisConfidenceLevel riskScore={riskScore} />
            )}
            <AntiTamperUserConfirmForm
              observedResult={observedResult}
              setObservedResult={setObservedResult}
              comment={comment}
              setComment={setComment}
              isScanLoaded={isScanLoaded}
              isReadOnly={isReadOnly}
            />
          </Box>
        </Box>
      </Box>
      <AntiTamperSideBarFooter
        handleCancel={handleCancelButton}
        handleSaveButton={handleSaveButton}
        observedResult={observedResult}
        isReadOnly={isReadOnly}
      />
    </Box>
  );
}
