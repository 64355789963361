import * as yup from 'yup';

import { includesWordMatch, normalizeString } from '@/common/utility';

import { RESTRICTED_THING_FIELDS } from './constants';

export const thingMetaSchema = (normalizedForbiddenFieldNames: string[] = []) =>
  yup.object({
    name: yup
      .string()
      .trim()
      .required('Required')
      .test(
        'Restricted Names',
        'Field name cannot be a restricted value',
        (value) =>
          !includesWordMatch(
            Object.values(RESTRICTED_THING_FIELDS),
            value ?? '',
          ),
      )
      .test(
        'is unique',
        'Field name must be unique',
        (name) =>
          !includesWordMatch(
            normalizedForbiddenFieldNames,
            normalizeString(name),
          ),
      ),
    value: yup
      .string()
      .when('required', {
        is: true,
        then: yup.string().trim().required('Required'),
      })
      .nullable(),
  });

export const thingTypedMetaSchema = yup.object().shape({
  value: yup.string().when('required', {
    is: true,
    then: yup.string().trim().required('Required'),
  }),
  required: yup.boolean(),
  uuid: yup.string(),
  name: yup.string(),
  type: yup.string(),
});

export const thingTypedFile = yup.object().shape({
  value: yup.mixed().when('required', {
    is: true,
    then: yup.string().trim().required('Required'),
  }),
  required: yup.boolean(),
  uuid: yup.string(),
  name: yup.string(),
  type: yup.string(),
  multiple: yup.boolean(),
});

export const addThingSchema = yup.object({
  title: yup.string().when('isComputedTitle', {
    is: (value: any) => !!value,
    then: yup
      .string()
      .test('disallowed', 'Title cannot be set on Typed Thing', (val) => !val),
    otherwise: yup.string().trim().required('Required'),
  }),
  catalogUuid: yup.string().required(),
  computedTitle: yup.array(yup.object()).nullable(),
  useThingType: yup.boolean(),
  metadata: yup.array().of(
    yup
      .object()
      .shape({
        name: yup
          .string()
          .trim()
          .required('Required')
          .test(
            'Restricted Names',
            'Field name cannot be a restricted value',
            (value) =>
              !includesWordMatch(
                Object.values(RESTRICTED_THING_FIELDS),
                value ?? '',
              ),
          ),
        value: yup.string().nullable(),
      })
      .test('is unique', 'Field name must be unique', ({ name }, context) => {
        const normalizedName = normalizeString(name);
        const numInstances = (context.parent as AddThingForm['metadata'])
          .map((f) => normalizeString(f.name))
          .reduce((t, curr) => (curr === normalizedName ? t + 1 : t), 0);
        return !(numInstances > 1 && name);
      }),
  ),
  typedMetadata: yup.array().of(thingTypedMetaSchema),
  files: yup
    .mixed()
    .test('required', 'Files are required', (fileGroup) =>
      fileGroup.required ? Object.values(fileGroup.value).length > 0 : true,
    ),
  typedFiles: yup.array().of(thingTypedFile),
});

export const addThingFileSchema = yup.object().shape({
  files: yup
    .mixed()
    .test('required', 'Files are required', (fileGroup) =>
      fileGroup.required ? Object.values(fileGroup.value).length > 0 : true,
    ),
  typedFiles: yup.array().of(thingTypedFile),
  primaryImage: yup.mixed(),
});

export const deactivateThingSchema = yup.object({
  reason: yup.string().required('Deactivation reason is required'),
});
