import { Chip, ChipProps } from '@mui/material';

type Props = {
  filename: string;
  onDelete: (e: string) => void;
} & ChipProps;
export default function DustFileChip({
  filename,
  onDelete,
  sx,
  ...props
}: Props) {
  return (
    <Chip
      label={filename}
      onDelete={() => onDelete(filename)}
      {...props}
      sx={{ maxWidth: '180px', ...sx }}
    />
  );
}
