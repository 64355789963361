import { atom } from 'recoil';
import * as yup from 'yup';

import { DEFAULT_COLUMNS } from '@/common/entities/things/constants';

import { userOrgLocalStorageEffect } from '../utility';

export const selectedCatalogAtom = atom<Catalog | null>({
  key: 'selectedCatalog',
  default: null,
});

const catalogColumnsSchema = yup.object({
  account: yup.array().of(yup.string().min(1)),
});

/**
 * Track the list of columns to show per catalog
 */
export const catalogColumnsAtom = atom<Record<string, Array<string>>>({
  key: 'catalog-columns',
  default: {
    account: [...DEFAULT_COLUMNS],
  },
  effects: [userOrgLocalStorageEffect('catalogColumns', catalogColumnsSchema)],
});
