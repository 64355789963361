import PropTypes from 'prop-types';
import { atom } from 'recoil';

import DustDataGrid from '@/components/Library/DustDataGrid';
import useSort from '@/services/useSort';

export const facilitiesSortAtom = atom({
  key: 'facilitiesSort',
  default: 'nameaz',
});

export const SORT_OPTIONS = {
  nameaz: {
    label: 'Name (A-Z)',
    params: { sort: 'name' },
    column: 'name',
    direction: 'asc',
  },
  nameza: {
    label: 'Name (Z-A)',
    params: { sort: '-name' },
    column: 'name',
    direction: 'desc',
  },
};

function FacilitiesTable({ loading, facilities, onRowClick }) {
  const { tableSortProps, applyColumnSortProps } = useSort(
    facilitiesSortAtom,
    SORT_OPTIONS,
  );
  // TODO: wrap in useMemo
  const columns = applyColumnSortProps([
    {
      objGetter: (facility) => facility.name,
      field: 'name',
      headerName: 'Facility Name',
      flex: 1,
    },
    {
      objGetter: (facility) => facility.uuid,
      field: 'id',
      headerName: 'UUID',
      flex: 1,
    },
  ]);

  // TODO: remove in favor of col-def field + valueGetter
  const rows = facilities.map((facility) => {
    const row = {};
    columns.forEach((col) => {
      row[col.field] = col.objGetter(facility);
    });
    return row;
  });

  const initialState = {
    sorting: {
      sortModel: [{ field: 'name', sort: /** @type {const} */ ('asc') }],
    },
  };

  return (
    <DustDataGrid
      altTheme
      columns={columns}
      initialPageSize={5}
      initialState={initialState}
      onRowClick={onRowClick}
      rows={rows}
      loading={loading}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...tableSortProps}
    />
  );
}

FacilitiesTable.propTypes = {
  facilities: PropTypes.array,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default FacilitiesTable;
