// TMP - commented imports are for real organization picker UI, uncomment to add back in.
// import {
//   Divider,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemText,
// } from '@mui/material';
import { useAuth } from 'react-oidc-context';

import dustLogoLoginHeaderGray from '@/assets/svg/dust-logo-login-header-gray.svg';
// import useOrgsData from '@/common/entities/orgs/useOrgsData';
import useUserOrgsData from '@/common/entities/users/useUserOrgsData';
import useRedirectToLoginPage from '@/common/hooks/useRedirectToLoginPage';
import AppLoadingSpinner from '@/components/Library/AppLoadingSpinner';
import { combineClass } from '@/components/Library/utility';
import AuthErrorPage from '@/components/Pages/ErrorPages/AuthErrorPage';
import useRequest from '@/services/requests/useRequest';
import { extractUserOrgs } from '@/services/utility';

import styles from './Login.module.css';
import UserNotInOrgsPage from './UserNotInOrgsPage';
import ErrorBase from '../ErrorPages/ErrorBase';

export default function SelectOrg() {
  const auth = useAuth();
  const request = useRequest();
  const willRedirect = useRedirectToLoginPage();
  const authOrgs = extractUserOrgs(auth);

  const {
    data: userOrgs,
    isLoading: userOrgsLoading,
    error: userOrgsError,
  } = useUserOrgsData();

  if (willRedirect) return null;
  if (
    auth.isLoading ||
    request.orgId === undefined ||
    !authOrgs ||
    userOrgsLoading
  )
    return <AppLoadingSpinner />;

  // TODO: We may want to give distinct messages for these errors
  if (userOrgsError || !userOrgs /* ← for TS */) {
    return <AuthErrorPage />;
  }

  if (Object.keys(userOrgs).length === 0) {
    return <UserNotInOrgsPage authProps={auth} />;
  }

  // TMP - comment out unused handler for select-org UI, uncomment to re-enable
  // const handleSelectOrgId: React.MouseEventHandler<HTMLElement> = ({
  //   currentTarget,
  // }) => {
  //   const newOrgId = currentTarget.dataset.orgId;
  //   if (!newOrgId) throw new Error('currentTarget needs a data-org-id');
  //   request.selectOrgId(newOrgId);
  // };

  return (
    <div className={styles.loginPage}>
      <img
        className={styles.logoSvg}
        src={dustLogoLoginHeaderGray}
        alt="logo"
      />

      <div className={styles.loginCardListShrinkStopper}>
        <div className={combineClass(styles.loginCard, styles.noSidePadding)}>
          {/* --------8<---- BEGIN BLOCKER --------------------------------- */}
          {/* Error block while backend can't support multi-org users */}
          <ErrorBase
            title="Multiple Organizations Unsupported"
            hideLinks
            message="Sorry, users that are members of more than one organization cannot use DICE currently"
          />
          <div className="mb-2" />
          {/* --------8<---- END BLOCKER ----------------------------------- */}

          {/* Normal select-an-organization option, uncomment (and re-add imports) to reenable */}
          {/* <h1 className="h1">Select an Organization</h1> */}
          {/* <Divider /> */}
          {/* <List disablePadding className={styles.orgList}> */}
          {/*   {Object.entries(authOrgs).map(([orgId, org]) => ( */}
          {/*     <ListItem key={orgId} divider disablePadding> */}
          {/*       <ListItemButton data-org-id={orgId} onClick={handleSelectOrgId}> */}
          {/*         <ListItemText */}
          {/*           primary={ */}
          {/*             userOrgs.find((o) => o.id === orgId)?.displayName ?? */}
          {/*             org.name */}
          {/*           } */}
          {/*         /> */}
          {/*       </ListItemButton> */}
          {/*     </ListItem> */}
          {/*   ))} */}
          {/* </List> */}
        </div>
      </div>
    </div>
  );
}
