import { useCallback, useMemo } from 'react';

import useCatalogsData from '@/common/entities/catalogs/useCatalogsData';
import useFacilitiesData from '@/common/entities/facilities/useFacilitiesData';
import useOrgData from '@/common/entities/orgs/useOrgData';
import { getVisibleTransactionTypes } from '@/common/entities/transactions/constants';
import {
  FILTER_NAMES,
  FILTER_LABELS,
  TRANSACTION_SORT_OPTIONS,
  transactionsSortAtom,
  TransactionFilters,
  TRANSACTION_SORT_TRACKING_EVENT,
} from '@/common/entities/transactions/useTransactionsData';
import useUsersData from '@/common/entities/users/useUsersData';
import TableFilter from '@/components/Composed/TableFilter/TableFilter';
import DustCheckboxSelect from '@/components/Library/DustCheckboxSelect';
import DustDateTimePicker from '@/components/Library/DustDateTimePicker';
import DustLoader from '@/components/Library/DustLoader';
import DustSelect from '@/components/Library/DustSelect';
import useSort from '@/services/useSort';

type Props = {
  filters: TransactionFilters;
  setFilter: (e: keyof TransactionFilters, val: any) => void;
  resetFilter: (e: keyof TransactionFilters) => void;
  resetFilters: () => void;
  showResetFilters?: boolean;
  showCatalogFilter?: boolean;
  showSortFilter?: boolean;
  onOpen: () => void;
};
export default function TransactionsFilters({
  filters,
  setFilter,
  resetFilter,
  resetFilters,
  showResetFilters,
  showCatalogFilter = true,
  showSortFilter = true,
  onOpen,
}: Props) {
  const { sortSelectProps } = useSort(
    transactionsSortAtom,
    TRANSACTION_SORT_OPTIONS,
    { trackingEvent: TRANSACTION_SORT_TRACKING_EVENT },
  );
  // Fetch all data lists
  const { catalogs, isLoading: catalogsLoading } = useCatalogsData({
    fetchAll: true,
  });
  const { facilities, isLoading: facilitiesLoading } = useFacilitiesData({});

  // Additional data for filters
  const { users, isLoading: usersLoading } = useUsersData();
  const { org, isLoading: accountLoading } = useOrgData();
  const transactionTypes = getVisibleTransactionTypes(org);

  const isLoading =
    catalogsLoading || facilitiesLoading || usersLoading || accountLoading;

  const catalogOptionList = useMemo(
    () =>
      catalogs.map((catalog) => ({
        name: catalog.name,
        value: catalog.uuid,
      })),
    [catalogs],
  );

  const facilityOptionList = useMemo(
    () =>
      facilities.map((facility) => ({
        name: facility.name,
        value: facility.uuid,
      })),
    [facilities],
  );

  const userOptionList = useMemo(
    () =>
      users
        .map((user) => ({
          name: user.username,
          value: user.uuid,
        }))
        // While transaction filters on user uuid values, only allow selection of users that are in DICE
        .filter((a): a is { name: string; value: string } => a.value != null),
    [users],
  );

  const typeOptionList = useMemo(
    () =>
      transactionTypes.map((type) => ({
        name: type.label,
        value: type.value,
      })),
    // Safe to ignore here - special case.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    transactionTypes,
  );

  // ---------------------------------------------------------------------------
  // Handle filter setting
  const setDate = useCallback(
    (date: Date | null, field: keyof TransactionFilters) => {
      setFilter(field, date);
    },
    [setFilter],
  );

  type FilterOption = [
    keyof Omit<TransactionFilters, 'startDate' | 'endDate'>,
    { name: string; value: string }[],
  ];

  const filterOptions = useMemo(() => {
    const options: FilterOption[] = [
      [FILTER_NAMES.FACILITY, facilityOptionList],
      [FILTER_NAMES.USERNAME, userOptionList],
      [FILTER_NAMES.TRANSACTION_TYPES, typeOptionList],
    ];

    if (showCatalogFilter) {
      options.unshift([FILTER_NAMES.CATALOG, catalogOptionList]);
    }

    return options;
  }, [
    facilityOptionList,
    userOptionList,
    typeOptionList,
    catalogOptionList,
    showCatalogFilter,
  ]);

  return (
    <TableFilter
      onReset={resetFilters}
      showReset={showResetFilters}
      onOpen={onOpen}
    >
      {isLoading && <DustLoader className="mt-2 mb-2" size="large" />}
      {!isLoading && (
        <div className="mt-1 flex-col gap-1">
          <DustDateTimePicker
            initialValue={filters[FILTER_NAMES.START_DATE]}
            label="Start Date"
            onAccept={(date) => setDate(date, FILTER_NAMES.START_DATE)}
            onClear={() => setDate(null, FILTER_NAMES.START_DATE)}
          />
          <DustDateTimePicker
            initialValue={filters[FILTER_NAMES.END_DATE]}
            label="End Date"
            onAccept={(date) => setDate(date, FILTER_NAMES.END_DATE)}
            onClear={() => setDate(null, FILTER_NAMES.END_DATE)}
          />
          {showSortFilter && (
            <DustSelect
              label="Sort"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...sortSelectProps}
            />
          )}
          {filterOptions.map(([field, options]) => (
            <DustCheckboxSelect
              entries={options}
              key={field}
              label={FILTER_LABELS[field]}
              onChange={(newValues) => setFilter(field, newValues)}
              onClear={() => resetFilter(field)}
              values={filters[field]}
            />
          ))}
        </div>
      )}
    </TableFilter>
  );
}
