import { getNested } from '@/components/Library/utility';

/**
 * Sort boolean values with an optional dot separated key to get a nested prop
 *
 * Sorts booleans in true-first order ([true, false])
 */
export function booleanSort<T>(a: T, b: T, key?: string & keyof T): number {
  const getBoolVal =
    key != null
      ? (val: T) =>
          typeof val === 'object' && val != null
            ? Boolean(getNested(val, key))
            : Boolean(val)
      : Boolean;

  return Number(getBoolVal(b)) - Number(getBoolVal(a));
}

/**
 * Date sorting for arrays
 */
export function timeSort<T extends object | string | Date>(
  a: T,
  b: T,
  key?: keyof T,
): number {
  const valA = ((key ? a[key] : a) ?? 0) as string | number | Date;
  const valB = ((key ? b[key] : b) ?? 0) as string | number | Date;
  return new Date(valA).getTime() - new Date(valB).getTime();
}

/**
 * Sort alphanumerically
 */
export function alphaSort<T extends object | string>(
  a: T,
  b: T,
  key?: keyof T,
): number {
  const valA = ((key ? a[key] : a) ?? '') as string;
  const valB = ((key ? b[key] : b) ?? '') as string;

  return valA.localeCompare(valB);
}
