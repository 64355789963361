import { useParams } from 'react-router-dom';

import TransactionDetail from '@/components/Composed/TransactionDetail/TransactionDetail';

export default function TransactionDetails() {
  const { transactionId } = useParams();

  if (!transactionId) return null;
  return <TransactionDetail transactionUuid={transactionId} />;
}
