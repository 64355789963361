import React from 'react';

import { FormControlLabel, Switch, SwitchProps } from '@mui/material';

import styles from './ThingAdd.module.css';

type Props = SwitchProps;

export default React.forwardRef(
  (props: Props, ref: React.Ref<HTMLButtonElement>) => (
    <FormControlLabel
      className={styles.autonext}
      control={
        <Switch
          size="small"
          edge="end"
          sx={{
            '&.MuiSwitch-root': {
              minWidth: 40,
            },
          }}
          ref={ref}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      }
      label="Auto-Next"
      title="Automatically select to the next item after binding"
      sx={{
        fontSize: 'var(--font-size-text-xs)',
        paddingTop: '0.25rem',
        marginRight: '-0.25rem',
        overflow: 'hidden',
      }}
    />
  ),
);
