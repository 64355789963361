import { atom } from 'recoil';
import * as yup from 'yup';

import { localStorageEffect } from '../utility';

const VERIFIED_THINGS_KEY = 'verifiedThings';

const schema = yup.lazy((obj) =>
  yup.object(
    Object.fromEntries(
      Object.entries(obj).map(([key, _value]) => [
        key,
        yup.number().integer().positive(),
      ]),
    ),
  ),
);

export const verifiedThingsAtom = atom<Record<string, number>>({
  key: 'verifiedThings',
  default: {},
  effects: [localStorageEffect(VERIFIED_THINGS_KEY, schema)],
});

/**
 * This needs to exist outside of recoil to allow the platform to clear verified things without the recoil context
 */
export const clearVerifiedThings = () =>
  localStorage.removeItem(VERIFIED_THINGS_KEY);
