import WarningAmber from '@mui/icons-material/WarningAmber';

import useCheckout from '@/common/hooks/useCheckout';
import DustModal from '@/components/Library/DustModal';

export default function RelationshipsCheckoutWarning() {
  const {
    relationshipRemoveWarningOptions,
    checkout: { removeThings, cancelRemoveRelationship },
  } = useCheckout();
  const handleContinue = async () => {
    await removeThings({
      ...relationshipRemoveWarningOptions,
      bypassRelationshipCheck: true,
    });
  };
  const handleCancel = () => cancelRemoveRelationship();

  const thingsWithRelationships = (
    relationshipRemoveWarningOptions?.things ?? []
  ).filter((t) => t.hasRelationships);

  const multiple = thingsWithRelationships.length > 1;

  const thingNames = thingsWithRelationships.map((t) => t.title).join(', ');

  return (
    <DustModal
      open
      title={
        <div className="flex-row gap-05 items-center">
          <WarningAmber color="warning" />
          Warning
        </div>
      }
      footerProps={{
        cancelLabel: 'Cancel',
        submitLabel: 'Continue',
        onCancel: handleCancel,
        onSubmit: handleContinue,
      }}
    >
      {(multiple && (
        <>
          <p className="pb-1">
            Some of the Things you are removing from your Checkout Cart have
            Relationships with other Things. By continuing, each Thing’s
            Relationship Tree will be removed as well.
          </p>
          <p className="pb-1">
            Selecting ‘Continue’ will remove all selected Things from the
            Checkout Cart, as well as their Relationship Trees.
          </p>
        </>
      )) || (
        <>
          <p className="pb-1">
            <strong>{thingNames}</strong> has existing Relationships with other
            Things. By continuing, all Things in the Relationship Tree of{' '}
            <strong>{thingNames}</strong> will be removed from the Checkout Cart
            as well.
          </p>
          <p className="pb-1">
            Selecting ‘Continue’ will remove <strong>{thingNames}</strong>, as
            well as all of its Related Things.
          </p>
        </>
      )}
    </DustModal>
  );
}
