import baseModel from '../baseModel';

export function catalogModel(catalog: Catalog): Catalog {
  return {
    ...baseModel({
      ...catalog,
      fingerprintExtractionConfigs: catalog.fingerprintExtractionConfigs.map(
        (c) => baseModel(c),
      ),
    }),
    currentExtractionConfig: catalog.fingerprintExtractionConfigs.find(
      (c) => c.isCurrent,
    ),
  };
}
