import React from 'react';

import {
  AddCommentOutlined as AddNotationIcon,
  LocationSearching as RescanIcon,
  Troubleshoot,
} from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import styles from './AntiTamperHeader.module.css';

type AntiTamperHeaderProps = {
  isVerifiedSession: boolean;
  handleNotations: () => void;
  handleRescan: () => void;
  isNotationActive: boolean;
  allowRetake: boolean;
  isReadOnly: boolean;
  handleNewScan: () => void;
};

export default React.forwardRef(
  (
    {
      isVerifiedSession,
      handleNotations,
      handleRescan,
      isNotationActive,
      allowRetake,
      isReadOnly,
      handleNewScan,
    }: AntiTamperHeaderProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => (
    <Box className={styles.root} component="header" ref={ref}>
      <Button
        variant="text"
        startIcon={<AddNotationIcon />}
        disabled={!isVerifiedSession}
        onClick={handleNotations}
        sx={{
          color: 'var(--white)',
          '&:disabled': {
            color: 'var(--deep-grey)',
          },
        }}
        className={`${styles.buttons} ${
          isNotationActive && styles.notationActive
        }`}
      >
        {isNotationActive ? 'Hide Notations' : 'Show Notations'}
      </Button>
      {isReadOnly ? (
        <Button
          variant="text"
          startIcon={<Troubleshoot />}
          onClick={handleNewScan}
          sx={{
            color: 'var(--white)',
            '&:disabled': {
              color: 'var(--deep-grey)',
            },
          }}
        >
          New Scan
        </Button>
      ) : (
        <Button
          variant="text"
          startIcon={<RescanIcon />}
          disabled={!allowRetake}
          onClick={handleRescan}
          sx={{
            color: 'var(--white)',
            '&:disabled': {
              color: 'var(--deep-grey)',
            },
          }}
        >
          Retake Scan
        </Button>
      )}
    </Box>
  ),
);
