import { useEffect, useState, useRef } from 'react';

import ThumbnailFallbackUrl from '@/assets/images/ThumbnailFallback.jpeg';
import { provideFallbackImage } from '@/common/utilities/images';
import { urlFromFile } from '@/common/utility';
import useRequest from '@/services/requests/useRequest';
import { isAbortError } from '@/services/utility';

import styles from './Components.module.css';
import { combineClass } from './utility';

export type DustThumbnailProps = {
  imageUuid?: string;
  title?: string;
  className?: string;
  styleForTable?: boolean;
};

export default function DustThumbnail({
  imageUuid,
  title,
  className = '',
  styleForTable = false,
}: DustThumbnailProps) {
  const { thingMediaApi } = useRequest();

  const [src, setSrc] = useState<string | null>(null);
  const abortCtrlRef = useRef<AbortController | null>(null);
  useEffect(
    () => () => {
      if (abortCtrlRef.current) abortCtrlRef.current.abort();
    },
    [],
  );

  useEffect(() => {
    if (!imageUuid) {
      setSrc(null);
      return;
    }
    const get = async () => {
      if (!imageUuid) return;
      abortCtrlRef.current = new AbortController();
      let res;
      try {
        res = await thingMediaApi.getMedia({
          uuid: imageUuid,
          width: Math.floor(window.devicePixelRatio * 40),
          height: Math.floor(window.devicePixelRatio * 40),
          suppressToast: true,
          signal: abortCtrlRef.current.signal,
        });
      } catch (err) {
        if (isAbortError(err)) return;
        throw err;
      }

      if (res.error) {
        setSrc(null);
        return;
      }

      // TODO: add type to thingMediaApi.getMedia
      const resultData = res.data as any;

      // Generate a file from the byte string
      const byteString = window.atob(resultData.bytes);
      const byteArray = new Uint8Array(byteString.length).map((_x, i) =>
        byteString.charCodeAt(i),
      );

      const file = new File([byteArray], resultData.filename, {
        type: resultData.fileType,
      });

      const url = await urlFromFile(file);

      // Slightly evil
      if (!abortCtrlRef.current.signal.aborted) {
        setSrc(url);
      }
    };
    void get();
  }, [thingMediaApi, imageUuid]);

  return (
    <img
      className={combineClass(
        className,
        styleForTable && styles.thingTableThumbnail,
      )}
      src={src ?? ThumbnailFallbackUrl}
      alt={`${title} thumbnail`}
      onError={provideFallbackImage}
    />
  );
}
