import { AxiosResponse } from 'axios';

import { orgRoleModel } from '@/common/entities/orgRoles/models';
import ApiService from '@/services/requests/ApiService';
import { formatAPIError, formatIdentityAPIReturn } from '@/services/utility';

import { MESSAGES } from '../constants';

const IDENTITY_URL = import.meta.env.VITE_USER_AUTHORITY;

/** Factory function for producing the roles API */
export default class OrgRolesApi {
  constructor(private apiService: ApiService) {}

  /** Get group roles */
  async getRoles(orgId: string) {
    return this.apiService
      .request({
        url: `${IDENTITY_URL}/api/organizations/${orgId}/roles`,
        method: 'GET',
      })
      .then((res: AxiosResponse<unknown[]>) =>
        formatIdentityAPIReturn(res, orgRoleModel),
      )
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED));
  }
}
