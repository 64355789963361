import { useCallback, useEffect, useRef, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useInterval } from 'usehooks-ts';

import DustModal from '@/components/Library/DustModal';
import { Mixpanel } from '@/mPanel';
import appActivityTracker from '@/state/appActivityTracker';
import { selectedFacilityAtom } from '@/state/atoms/facility';

export const allowNavBlockAtom = atom({ key: 'allowNavBlock', default: true });

export default function SessionDialog() {
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const auth = useAuth();

  const setAllowNavBlock = useSetRecoilState(allowNavBlockAtom);
  const facilityUuid = useRecoilValue(selectedFacilityAtom);
  const facilityRef = useRef(facilityUuid);
  facilityRef.current = facilityUuid;

  const handleLogout = useCallback(() => {
    Mixpanel.logout({ logoutMethod: 'Session Dialog', facilityUuid });
    void auth.signoutRedirect();
  }, [auth, facilityUuid]);

  const showCountdownDialog = useCallback(
    (expiresInSeconds: number) => {
      setAllowNavBlock(false); // Prevent restricting navigation
      setCountdown(expiresInSeconds);
      setOpen(true);
    },
    [setAllowNavBlock],
  );

  useEffect(
    () =>
      auth.events.addAccessTokenExpiring(async () => {
        const expiry = auth.user?.expires_at;
        if (!expiry) {
          throw new Error('Invalid expires_at, check access token');
        }
        if (appActivityTracker.isActive()) {
          const signinResultUser = await auth.signinSilent();
          if (signinResultUser) {
            return;
          }
          console.warn(
            'Automatic signinSilent failed, showing expiration dialog',
          );
        }

        const secondsRemaining = Math.floor(expiry - Date.now() / 1000);
        showCountdownDialog(secondsRemaining);
      }),
    [auth, auth.user?.expires_at, showCountdownDialog],
  );
  useEffect(
    () =>
      auth.events.addAccessTokenExpired(() => {
        // On automatic expiration, auto-logout
        Mixpanel.logout({
          logoutMethod: 'Session Timeout',
          facilityUuid: facilityRef.current,
        });
        void auth.signoutRedirect();
      }),
    [auth],
  );

  // Decrement counter if dialog is open
  useInterval(
    () => {
      setCountdown((value) => value - 1);
    },
    open && countdown > 0 ? 1000 : null,
  );

  const extendSession = useCallback(async () => {
    // ODIC - based on https://www.npmjs.com/package/react-oidc-context#user-content-adding-event-listeners
    let user;

    try {
      user = await auth.signinSilent();
    } catch (e) {
      // Navigate back to root on error, allow login flow to restart.
      window.location.assign('/');
    }
    if (user) {
      setOpen(false);
      setCountdown(0);
      setAllowNavBlock(true);
    } else {
      // Navigate back to root on error, allow login flow to restart.
      window.location.assign('/');
    }
  }, [auth, setAllowNavBlock]);

  return (
    <DustModal
      footerProps={{
        onSubmit: extendSession,
        cancelLabel: 'Logout',
        submitLabel: 'Continue',
        onCancel: handleLogout,
      }}
      disableEscapeKeyDown
      maxWidth="25rem"
      open={open}
      title="Inactivity Warning"
    >
      <>
        Due to inactivity you will be logged out in{' '}
        {countdown < 0 ? 0 : countdown} seconds. Do you wish to extend?
      </>
    </DustModal>
  );
}
