import { Chip, SxProps } from '@mui/material';

export enum CHIP_STATUS {
  NEUTRAL = 'Neutral',
  SUCCESS = 'Success',
  WARNING = 'Warning',
  ERROR = 'Error',
}

const chipStyles = {
  [CHIP_STATUS.NEUTRAL]: {
    // background: 'var(--error-red)',
    color: 'var(--black)',
    border: 'none',
  },
  [CHIP_STATUS.SUCCESS]: {
    background: 'var(--success-green)',
    color: 'var(--color-text-contrast)',
    border: 'none',
  },
  [CHIP_STATUS.WARNING]: {
    background: 'var(--warning-yellow)',
    color: 'var(--color-text-warning)',
    border: 'none',
  },
  [CHIP_STATUS.ERROR]: {
    background: 'var(--error-red)',
    color: 'var(--color-text-contrast)',
    border: 'none',
  },
};

type Props = {
  label: string;
  status: string;
  sx?: SxProps;
};

export default function DustStatusChip({ label, status, sx }: Props) {
  return (
    <Chip
      label={label}
      size="small"
      sx={{
        ...chipStyles[status as CHIP_STATUS],
        ...sx,
      }}
    />
  );
}
