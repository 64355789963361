import { useMemo } from 'react';

import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import { Chip, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

import { formatDateForDisplay } from '@/common/dates';
import useFacilityData from '@/common/entities/facilities/useFacilityData';
import useOrgData from '@/common/entities/orgs/useOrgData';
import useTransactionData from '@/common/entities/transactions/useTransactionData';
import useEffectOnceWhen from '@/common/hooks/useEffectOnceWhen';
import { ROUTES } from '@/common/routes';
import DustCopyableText from '@/components/Library/DustCopyableText';
import DustLoader from '@/components/Library/DustLoader';
import DustMap from '@/components/Library/DustMap';
import DustStatusChip from '@/components/Library/DustStatusChip';
import DustTableSimple from '@/components/Library/DustTableSimple';
import DustDustedIcon from '@/components/Library/Icons/DustDustedIcon';
import DustTransactionIcon from '@/components/Library/Icons/DustTransactionIcon';
import { Mixpanel } from '@/mPanel';

import TransactionSummary from './Summary';
import styles from './TransactionDetail.module.css';
import { UserAgentCollapsableCell } from './UserAgentCollapsableCell';

type Props = {
  transactionUuid: string;
  showTitle?: boolean;
  disableInteraction?: boolean;
};

export default function TransactionDetail({
  transactionUuid,
  showTitle = true,
  disableInteraction = false,
}: Props) {
  const {
    isLoading: isTransactionLoading,
    isSuccess: isTransactionSuccess,
    transaction,
  } = useTransactionData({ transactionUuid });

  useEffectOnceWhen(() => {
    if (!transaction) return; // Will only fire when transactions exists

    Mixpanel.track('View Transaction Details', {
      'Transaction ID': transaction.uuid,
      'Catalog ID': transaction.catalogUuid,
      'Thing ID': transaction.thingUuid,
      'Transaction Type': transaction.transactionType,
      Verified: transaction.isVerified,
      Outcome: transaction.outcomeLabel,
      'View Type': showTitle ? 'Transaction Detail Page' : 'Thing Page',
      'Location Map': !!transaction.transactionData?.geolocation?.lat,
      'Date Created': transaction.createdAt,
    });
  }, !!transaction);

  const { org } = useOrgData();
  const isFacilityEnabled = !!transaction?.facilityUuid;
  const {
    facility,
    isLoading: isFacilityLoading,
    isSuccess: isFacilitySuccess,
  } = useFacilityData({
    facilityUuid: transaction?.facilityUuid,
    enabled: !!transaction?.facilityUuid,
  });

  const isLoading = isTransactionLoading || isFacilityLoading;
  const isSuccess =
    isTransactionSuccess && (!isFacilityEnabled || isFacilitySuccess);

  const txData = transaction?.transactionData;
  const txDust = txData?.dust;

  const rows = useMemo(() => {
    if (transaction == null || txData == null || txDust == null) {
      return []; // loading...
    }

    const thingUrl =
      transaction.catalogUuid && transaction.thingUuid
        ? ROUTES.THING(transaction.thingUuid)
        : '';

    return [
      {
        label: 'Transaction Type',
        value: transaction.label ?? transaction.transactionType,
      },
      {
        label: 'Thing',
        value:
          transaction.thingTitle && thingUrl ? (
            <div className={styles.copyableTextContainer}>
              <MuiLink component={Link} to={thingUrl}>
                {transaction.thingTitle}
              </MuiLink>
              <DustCopyableText
                text={`${window.location.origin}${thingUrl}`}
                placeholder={<CopyIcon color="disabled" />}
              />
            </div>
          ) : null,
      },
      {
        label: 'Transaction ID',
        value: (
          <div className={styles.copyableTextContainer}>
            <MuiLink component={Link} to={ROUTES.TRANSACTION(transaction.uuid)}>
              {transaction.uuid}
            </MuiLink>
            <DustCopyableText
              text={`${window.location.origin}${ROUTES.TRANSACTION(
                transaction.uuid,
              )}`}
              placeholder={<CopyIcon color="disabled" />}
            />
          </div>
        ),
      },
      {
        label: 'Thing Hash',
        value: txData.thing?.write_thing?.hash,
      },
      {
        label: 'Transaction Date',
        value: formatDateForDisplay(transaction.createdAt, {
          distanceFromNow: false,
        }),
      },
      {
        label: 'Username',
        value: transaction.createdBy,
      },
      {
        label: 'Facility',
        value: facility?.name,
      },
      {
        label: 'IP Address',
        value: txData.ipAddress,
      },
      {
        label: 'User Agent',
        value: txData.userAgent && (
          <UserAgentCollapsableCell value={txData.userAgent} />
        ),
      },
      {
        label: 'Query Fingerprint Hash',
        value: txDust.query_fingerprint_hash,
      },
      {
        label: 'Reference Fingerprint Hash',
        value: txDust.reference_fingerprint_hash,
      },
      {
        label: 'Verified Fingerprint Hash',
        value: txData.verifiedFingerprintHash,
      },
      {
        label: 'Blockchain ID',
        value: null, // This will have a value eventually. No task yet
      },
    ]
      .filter(
        (row) => !([null, undefined, false, ''] as any[]).includes(row.value),
      ) // Avoid the filtering of rows with value "0"
      .map((row) => [row.label, row.value]);
  }, [transaction, txData, txDust, facility?.name]);

  if (isLoading) {
    return <DustLoader size="xlarge" sx={{ margin: '3rem 0' }} />;
  }
  if (!isSuccess || !transaction) {
    return <>Can&apos;t display transaction information at this time</>;
  }

  return (
    <>
      <div className="flex flex-row gap-1">
        <DustStatusChip
          label={transaction.outcomeLabel}
          status={transaction.outcomeStatus}
        />
        {transaction.isVerified && (
          <Chip
            className={styles.frameChip}
            color="primary"
            icon={<DustDustedIcon />}
            label="Verified"
            size="small"
            variant="outlined"
          />
        )}
        {facility && (
          <Chip
            className={styles.frameChip}
            icon={<LocationIcon />}
            label={facility.name}
            size="small"
            variant="outlined"
          />
        )}
      </div>
      {showTitle && (
        <div className="flex-row items-end gap-05 mt-1">
          <DustTransactionIcon
            label={transaction.label}
            sx={{ height: '2rem', width: '2rem' }}
            type={transaction.transactionType}
            verified={transaction.isVerified}
            verifySuccess={transaction.matchOutcome === 'MATCH_SUCCESS'}
          />
          <h1 className="h1">{transaction.label ?? ''}</h1>
        </div>
      )}
      <div className={styles.contentCont}>
        {transaction && (
          <TransactionSummary
            transaction={transaction}
            disableInteraction={disableInteraction}
          />
        )}
        <div className={styles.tableCont}>
          <h3 className={styles.header}>Transaction Data</h3>
          <DustTableSimple rows={rows} />
        </div>
      </div>
      {txData?.geolocation != null &&
        org &&
        org.transactionLocationSetting !== 'RESTRICTED' && (
          <>
            <h3 className={styles.header}>Location</h3>
            <DustMap
              latitude={parseFloat(txData.geolocation.lat)}
              longitude={parseFloat(txData.geolocation.lon)}
              zoom={12}
            />
          </>
        )}
    </>
  );
}
