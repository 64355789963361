import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 1 * 60 * 1000;

/** Hook for access to all checkout functionality and state */
export default function useActiveUser({ enabled = true } = {}) {
  const { QUERY_KEYS, usersApi } = useRequest();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(
    QUERY_KEYS.USER('activeUser'),
    usersApi.getCurrent.bind(usersApi),
    {
      enabled,
      staleTime: STALE_TIME,
    },
  );

  return {
    isLoading,
    isSuccess,
    isError,
    activeUser: user && !user.error ? user.data : undefined,
    errorData: user && user.error ? user.data : undefined,
  };
}
