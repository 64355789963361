import { useCallback, useRef } from 'react';

import { useAuth } from 'react-oidc-context';
import { useRecoilState } from 'recoil';

import {
  toastListAtom,
  toastModel,
  ToastMessage,
  ToastSeverity,
} from '@/state/atoms/toasts';

export type AddToastCallback = (
  message: ToastMessage,
  severity: ToastSeverity,
  persist?: boolean,
  timeout?: number,
) => void;

export default function useToasts() {
  const [toasts, setToasts] = useRecoilState(toastListAtom);

  const auth = useAuth();
  // Make a stable ref for callback for the addToast stable callback
  const shouldAddToastRef = useRef(true);
  shouldAddToastRef.current = auth.isAuthenticated;

  const removeToast = useCallback(
    (deleteId: string) => {
      setToasts((prevToasts) => prevToasts.filter((t) => t.id !== deleteId));
    },
    [setToasts],
  );

  /**
   * Add a toast with an optional timeout to clear on.
   *
   * !! IMPORTANT - make sure this function stays stable for all renders, or
   * the api service could reinitialize
   */
  const addToast = useCallback<AddToastCallback>(
    (message, severity, persist = false, timeout = 7000) => {
      if (!shouldAddToastRef.current) {
        return;
      }

      const newToast = toastModel(message, severity, persist, timeout);
      setToasts((prevToasts) => [...prevToasts, newToast]);
      if (timeout) {
        setTimeout(() => removeToast(newToast.id), timeout);
      }
    },
    [setToasts, removeToast],
  );

  return {
    toasts,
    addToast,
    removeToast,
  };
}
