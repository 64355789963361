import { useCallback, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import dustLogoLoginHeaderGray from '@/assets/svg/dust-logo-login-header-gray.svg';
import { combineClass } from '@/components/Library/utility';
import AuthErrorPage from '@/components/Pages/ErrorPages/AuthErrorPage';

import styles from './Login.module.css';
import PreLoginRedirectForm from './PreLoginRedirectForm';

// See Notion for planned capabilities of login flow:
// https://www.notion.so/dustidentity/Login-Flow-92f3ccc282bf401d811310e229ed742b

export default function Login() {
  const [urlSearchParams] = useSearchParams();
  const returnPath = urlSearchParams.get('returnPath');
  const [showAuthError, setShowAuthError] = useState(false);

  const handleAuthError = useCallback(() => {
    setShowAuthError(true);
  }, []);

  return showAuthError ? (
    <AuthErrorPage />
  ) : (
    <div className={styles.loginPage}>
      <div className={styles.logoHeader}>
        <img
          className={styles.logoSvg}
          src={dustLogoLoginHeaderGray}
          alt="logo"
        />
      </div>

      <div className={combineClass(styles.loginCard, styles.loginCardPadding)}>
        <h1 className="h1">Login</h1>
        <PreLoginRedirectForm
          returnPath={returnPath ?? undefined}
          onAuthError={handleAuthError}
        />
      </div>
    </div>
  );
}
