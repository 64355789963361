import { TextField, Box } from '@mui/material';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import DustStepperFooter from '@/components/Library/DustStepperFooter';

import styles from './AntiTamperNotationForm.module.css';

type AntiTamperNotationFormProps = {
  handleCancel: () => void;
  handleSave: (comment: string) => void;
  disabled: boolean;
  item: AntiTamperNotation;
  handleClose: () => void;
  isReadOnly: boolean;
};

type FormValues = {
  comment: string;
};
function AntiTamperNotationForm({
  disabled,
  handleCancel,
  handleSave,
  handleClose,
  item,
  isReadOnly,
}: AntiTamperNotationFormProps) {
  const notationFormInitialValues: FormValues = {
    comment: item.comment,
  };
  const notationFormValidation = yup.object({
    comment: yup.string().trim().required('Required'),
  });

  return (
    <Box className={styles.root}>
      <Formik
        initialValues={notationFormInitialValues}
        validationSchema={notationFormValidation}
        onSubmit={(values: FormValues) => {
          handleSave(values.comment);
        }}
      >
        {(formik: FormikProps<FormValues>) => (
          <>
            <Box className={styles.commentBox}>
              <TextField
                placeholder="Write an annotation"
                value={formik.values.comment}
                name="comment"
                variant="outlined"
                onChange={formik.handleChange}
                multiline
                rows={4}
                fullWidth
                disabled={disabled}
              />
            </Box>
            <Box className={styles.footer}>
              {!disabled && (
                <DustStepperFooter
                  cancelLabel="Cancel"
                  disabled={!(formik.isValid && formik.dirty)}
                  onCancel={handleCancel}
                  onSubmit={() => {
                    formik.handleSubmit();
                  }}
                  submitLabel="Save"
                />
              )}
              {disabled && (
                <DustStepperFooter
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(!isReadOnly && {
                    cancelLabel: 'Delete',
                    onCancel: handleCancel,
                  })}
                  onSubmit={handleClose}
                  submitLabel="Close"
                />
              )}
            </Box>
          </>
        )}
      </Formik>
    </Box>
  );
}

export default AntiTamperNotationForm;
