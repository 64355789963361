import { useEffect, useRef } from 'react';

import fastDeepEqual from 'fast-deep-equal';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { Mixpanel, commonProps } from '@/mPanel';

import { getTrackingRouteInfo, TrackingConfig } from './common/routes';
import { trackingDataAtom } from './state/atoms/mpData';

export default function PageviewTracker() {
  const setTrackingData = useSetRecoilState(trackingDataAtom);
  const location = useLocation();
  const prevEventData = useRef<{
    pageUrl: string;
    pageName: string;
    trackingConfig: TrackingConfig;
    uuidProps: null | Record<string, string>;
    timeEntered: number;
    isInternal: string;
  } | null>(null);

  const auth = useAuth();

  useEffect(() => {
    const trackingInfo = getTrackingRouteInfo(
      location.pathname,
      location.search,
    );
    if (!trackingInfo) return;
    const { config, uuidProps } = trackingInfo;

    const isInternal = (auth.user?.profile.email ?? '').match(
      /dustidentity.com/,
    )
      ? 'Internal'
      : 'External';

    // Track by config object references to allow more control over what
    // similar routes might count as a "Page" for this Mixpanel event, and
    // ensures that arbitrary query params do not affect pageview events
    // (unless they are explicitly listed in the pageview config)
    if (
      config === prevEventData.current?.trackingConfig &&
      fastDeepEqual(uuidProps, prevEventData.current?.uuidProps) &&
      isInternal === prevEventData.current?.isInternal
    ) {
      return;
    }

    // Keep consistent with mixpanel, which includes origin and hash
    const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

    const currentPageName = config.name;

    // When the URL changes we emit an exit event for the previous page
    if (prevEventData.current) {
      // Fire before registering props to capture Current URL from the previous page
      Mixpanel.track('Page Exit', {
        'Next Page Name': currentPageName,
        'Next Page URL': currentUrl,
        'Seconds on Page': Math.floor(
          (Date.now() - prevEventData.current.timeEntered) / 1000,
        ),
      });
    }

    setTrackingData({
      pageName: currentPageName,
    });
    const registeredProps: Record<string, string> = {
      // Mixpanel auto-tracks Current URL
      [commonProps.pageName]: currentPageName,
      'Last Page URL': prevEventData.current?.pageUrl ?? 'N/A',
      'Last Page Name': prevEventData.current?.pageName ?? 'N/A',
      'Is Internal': isInternal,
    };

    // If a sid is available then provide to link session information
    if (auth.user && auth.user.profile.sid)
      registeredProps['Session ID'] = auth.user.profile.sid;

    Mixpanel.register(registeredProps);
    Mixpanel.track('Page View', { ...uuidProps });

    prevEventData.current = {
      pageUrl: currentUrl,
      pageName: currentPageName,
      trackingConfig: config,
      uuidProps,
      timeEntered: Date.now(),
      isInternal,
    };
  }, [location, setTrackingData, auth.user]);

  return null;
}
