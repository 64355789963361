/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Marker } from '@googlemaps/markerclusterer/dist/marker-utils';

import { formatDateForDisplay } from '@/common/dates';
import useTransactionsData, {
  FILTER_LABELS,
  TransactionFilters,
  filterToTrackingProp,
} from '@/common/entities/transactions/useTransactionsData';
import { ROUTES } from '@/common/routes';
import TransactionsFilters from '@/components/Composed/TransactionFilters/TransactionsFilters';
import DustFilterChip from '@/components/Library/DustFilterChip';
import DustLinkButton from '@/components/Library/DustLinkButton';
import DustMapWrapper from '@/components/Library/DustMapWrapper';
import { Mixpanel } from '@/mPanel';

import styles from './ThingMap.module.css';

function MapError({ message }: { message: string }) {
  return <div style={{ fontSize: 'var(--font-size-text-sm)' }}>{message}</div>;
}

const txGeoEquals = (t1: Transaction, t2: Transaction) =>
  t1.transactionData.geolocation.lat === t2.transactionData.geolocation.lat &&
  t1.transactionData.geolocation.lon === t2.transactionData.geolocation.lon;

const filterNonGeolocated = (tx: Transaction) =>
  tx.transactionData.geolocation.lat && tx.transactionData.geolocation.lon;

const generateTransactionInfoWindow = (tx: Transaction, txs: Transaction[]) =>
  txs
    .filter((t) => txGeoEquals(tx, t))
    .map(
      (t) => `<div class="${styles.infoWindowWrapper}">
    <a class="${styles.infoWindowTitle}" href="${ROUTES.TRANSACTION(t.uuid)}">${
        t.label
      }</a>
    <div class="${styles.infoWindowDateTime}">${formatDateForDisplay(
        t.createdAt,
      )}</div>
    <div class="${styles.infoWindowUser}">${t.createdBy}</div>
    <div class="${styles.infdWindowLocation}">${
        t.transactionData.geolocation.lat
      }, ${t.transactionData.geolocation.lon}</div>
  </div>`,
    )
    .join('');

export default function ThingMap({ thing }: { thing: Thing }) {
  const {
    transactions,
    isError,
    filters,
    resetFilter,
    resetFilters,
    setFilter,
    appliedFilters,
  } = useTransactionsData({
    thingUuid: thing.uuid,
    sort: '-createdAt',
    perPage: 100,
  });

  const baseTrackingData = React.useMemo(
    () =>
      Object.fromEntries(
        Object.entries(filters).map(([key, value]) =>
          filterToTrackingProp(
            key as keyof typeof filters,
            value as ValueOf<typeof filters>,
          ),
        ),
      ),
    [filters],
  );

  const handleFiltersOpened = React.useCallback(() => {
    Mixpanel.track('View Map Transaction Filters', baseTrackingData);
  }, [baseTrackingData]);

  // NOTE: Type for reference only, may not be worth the complexity
  const handleSetFilter = React.useCallback(
    (
      filterName: keyof TransactionFilters,
      filterValue: Date | string[] | null,
    ) => {
      const trackValues = {
        ...baseTrackingData,
        ...Object.fromEntries([filterToTrackingProp(filterName, filterValue)]),
      };
      Mixpanel.track('Filter Map Transactions', trackValues);
      setFilter(filterName, filterValue);
    },
    [setFilter, baseTrackingData],
  );

  const filteredTransactions = React.useMemo(
    () => transactions.filter(filterNonGeolocated),
    [transactions],
  );

  // useEffect needs to triggered on change. This callback allows that to happen.
  const setRef = React.useCallback(
    (instance: HTMLDivElement | null) => {
      if (!instance) {
        return;
      }

      const coords: google.maps.LatLngLiteral[] = [];
      const bounds = new google.maps.LatLngBounds();
      const center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
      const mapOptions: google.maps.MapOptions = {
        draggable: true,
        scrollwheel: true,
        panControl: true,
        keyboardShortcuts: true,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        center,
        zoom: 2,
      };

      const infoWindow = new window.google.maps.InfoWindow({
        content: '',
        disableAutoPan: true,
      });

      const map = new window.google.maps.Map(instance, mapOptions);
      let i = 0;
      const markers = filteredTransactions.reduce((prev: Marker[], tx) => {
        const position = {
          lat: parseFloat(tx.transactionData.geolocation.lat),
          lng: parseFloat(tx.transactionData.geolocation.lon),
        };
        coords.push(position);
        if (
          filteredTransactions.some(
            (t) => t.createdAt > tx.createdAt && txGeoEquals(t, tx),
          )
        ) {
          return prev;
        }
        i += 1;
        const marker = new window.google.maps.Marker({
          position,
          draggable: false,
          map,
          label: i.toString(),
        });
        bounds.extend(position);
        const label = generateTransactionInfoWindow(tx, filteredTransactions);
        marker.addListener('click', () => {
          infoWindow.setContent(label);
          infoWindow.open(map, marker);
        });
        map.fitBounds(bounds);
        return [...prev, marker];
      }, []);
      const line = new google.maps.Polyline({
        path: coords,
        geodesic: true,
        strokeColor: '#0066CC',
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });

      line.setMap(map);
      // eslint-disable-next-line no-new
      new MarkerClusterer({ markers, map });
    },
    [filteredTransactions],
  );

  const displayError = filteredTransactions.length === 0 || isError;

  return (
    <section className={styles.section}>
      <div className={styles.controlsRow}>
        {/* CONTENT */}
        <div className={styles.chipRow}>
          {appliedFilters.map((filter) => (
            <DustFilterChip
              key={filter}
              label={FILTER_LABELS[filter]}
              onDelete={() => resetFilter(filter)}
            />
          ))}
        </div>
        {appliedFilters.length > 0 && (
          <DustLinkButton
            onClick={resetFilters}
            sx={{ margin: '0 .5rem', minWidth: 'fit-content' }}
          >
            Clear filters
          </DustLinkButton>
        )}
        <TransactionsFilters
          filters={filters}
          resetFilter={resetFilter}
          resetFilters={resetFilters}
          setFilter={handleSetFilter}
          showCatalogFilter={false}
          showSortFilter={false}
          showResetFilters={appliedFilters.length > 0}
          onOpen={handleFiltersOpened}
        />
      </div>
      {displayError && (
        <MapError message="No geolocated transactions match your search criteria" />
      )}
      {!displayError && (
        <DustMapWrapper>
          <div ref={setRef} className={styles.map} />
        </DustMapWrapper>
      )}
    </section>
  );
}
