import {
  Resolution,
  ResolutionObject,
  RESOLUTIONS,
} from '@/common/entities/exportBatches/typedefs';

import ResolutionChildren from './ResolutionChildren';
import ResolutionRoot from './ResolutionRoot';
import { isConflictRootLevel } from './utility';

type Props = {
  exportBatch: ExportBatch;
  resolution: ResolutionObject;
  existingCatalogNameLookup: { [k: string]: string };
  existingThing?: Thing;
  importThing: Thing;
  onResolution: (resolution: Resolution) => void;
  existingThingLookup: { [k: string]: Thing };
};

export default function ResolutionRow({
  exportBatch,
  existingCatalogNameLookup,
  existingThing,
  importThing,
  onResolution,
  existingThingLookup,
  resolution,
}: Props) {
  // Note: Until 'use import dust' or 'use server dust' enter usage, resolutions are going to be either
  // NO_RESOLUTION, USE_IMPORTED_THING, or USE_SERVER_THING. NO_RESOLUTION behaves the same as USE_SERVER_THING
  // but indicates that the user has not yet chosen a resolution.

  const isImportedThingSelected =
    resolution.resolution === RESOLUTIONS.USE_IMPORTED_THING;
  const isServerThingSelected =
    resolution.resolution === RESOLUTIONS.USE_SERVER_THING;
  const nothingSelected = resolution.resolution === RESOLUTIONS.NO_RESOLUTION;

  return (
    <>
      <ResolutionRoot
        existingCatalogNameLookup={existingCatalogNameLookup}
        existingThing={existingThing}
        importThing={importThing}
        resolution={resolution}
        onResolution={onResolution}
        isImportedThingSelected={isImportedThingSelected}
        isServerThingSelected={isServerThingSelected}
        nothingSelected={nothingSelected}
        importCatalogName={
          exportBatch.importValidation.catalogsByUuid[importThing.catalogUuid]
            .name
        }
      />
      {resolution.conflict && isConflictRootLevel(resolution.conflict) && (
        <ResolutionChildren
          exportBatch={exportBatch}
          importThing={importThing}
          existingThingLookup={existingThingLookup}
          existingCatalogNameLookup={existingCatalogNameLookup}
          importDepth={importThing?.relationships.children?.length ? 1 : 0}
          existingDepth={existingThing?.relationships.children?.length ? 1 : 0}
          isImportedThingSelected={isImportedThingSelected}
          isServerThingSelected={isServerThingSelected}
          nothingSelected={nothingSelected}
        />
      )}
    </>
  );
}
