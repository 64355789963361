import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useInfiniteQuery } from 'react-query';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { atom, SetterOrUpdater, useRecoilState } from 'recoil';

import { getChangedFilters } from '@/common/utilities/filters';
import {
  pageCountGetNextPageParam,
  useFetchAll,
  useGetNextPage,
} from '@/common/utilities/table';
import useRequest from '@/services/requests/useRequest';
import { SortOptions } from '@/services/useSort';

const STALE_TIME = 5 * 60 * 1000;
const PARAM_KEY = 'catalog-filters';

export const FILTER_VIEW_OPTIONS = {
  ALL: 'All',
  FAVORITE: 'Favorites Only',
  HIDDEN: 'Hidden Catalogs Only',
  MOBILE: 'Mobile Catalogs',
} as const;

export const FILTER_NAMES = {
  SEARCH: 'name',
  VIEW: 'view',
  DISPLAY_HIDDEN: 'displayHidden',
} as const;

export const FILTER_LABELS = {
  [FILTER_NAMES.SEARCH]: 'Search',
  [FILTER_NAMES.VIEW]: 'View',
  [FILTER_NAMES.DISPLAY_HIDDEN]: 'Show Hidden',
} as const;

export const SEARCH_PARAM_HIDDEN_VALUES = {
  HIDDEN_ONLY: 'HIDDEN_ONLY',
  VISIBLE_ONLY: 'VISIBLE_ONLY',
  BOTH: 'BOTH',
};

export const SEARCH_PARAM_MOBILE_VALUES = {
  MOBILE: true,
  NONMOBILE: false,
  BOTH: undefined,
};

export const SEARCH_PARAM_FAVORITE_VALUES = {
  FAVORITED_ONLY: 'FAVORITED_ONLY',
  NOT_FAVORITED_ONLY: 'NOT_FAVORITED_ONLY',
  BOTH: 'BOTH',
};

export type CatalogFilters = {
  name: string;
  /** Toggle filters are tri-state using undefined */
  view: ValueOf<typeof FILTER_VIEW_OPTIONS>;
  /** Toggle filters are tri-state using undefined */
  displayHidden: boolean;
};

export const FILTER_DEFAULTS: CatalogFilters = Object.freeze({
  [FILTER_NAMES.SEARCH]: '',
  [FILTER_NAMES.VIEW]: FILTER_VIEW_OPTIONS.ALL,
  [FILTER_NAMES.DISPLAY_HIDDEN]: false,
});

export const CATALOG_SORT_OPTIONS = {
  nameaz: {
    label: 'Catalog Title (A-Z)',
    params: { sort: 'name' },
    column: 'name',
    direction: 'asc',
  },
  nameza: {
    label: 'Catalog Title (Z-A)',
    params: { sort: '-name' },
    column: 'name',
    direction: 'desc',
  },
  createdAtNewest: {
    label: 'Date Created (Newest First)',
    params: { sort: '-createdAt' },
    column: 'createdAt',
    direction: 'desc',
  },
  createdAtOldest: {
    label: 'Date Created (Oldest First)',
    params: { sort: 'createdAt' },
    column: 'createdAt',
    direction: 'asc',
  },
} as const;

// TODO: use upcoming TypeScript `satisfies` keyword for this type check
((q: SortOptions) => q)(CATALOG_SORT_OPTIONS);

export const catalogSelectorSortAtom = atom<keyof typeof CATALOG_SORT_OPTIONS>({
  key: 'catalogSelectorSort',
  default: 'nameaz',
});

const filterAtom = atom({
  key: 'catalog-filters',
  default: FILTER_DEFAULTS,
});

/** Fetch the list of catalogs */
export default function useCatalogsData({
  perPage = 50,
  fetchAll = false,
  useFilters = false,
  sort = 'name',
}: {
  perPage?: number;
  fetchAll?: boolean;
  useFilters?: boolean;
  sort?: ValueOf<typeof CATALOG_SORT_OPTIONS>['params']['sort'];
}) {
  const { catalogsApi, QUERY_KEYS } = useRequest();

  // Set initial filters from the URL
  // Only save filters to the URL when set. Otherwise default filters are used
  // Use case is fetching the entire list of catalogs for a selector input or similar
  const [searchParams, setSearchParams] = useSearchParams();

  // ---------------------------------------------------------------------------
  // FILTER HANDLING
  const [filters, setRecoilFilters] = useRecoilState(filterAtom);

  const setFilters: SetterOrUpdater<CatalogFilters> = useCallback(
    (filterGen) => {
      const newFilters =
        typeof filterGen === 'function' ? filterGen(filters) : filterGen;

      // Synchronize filters with the URL
      if (useFilters) {
        const params = createSearchParams(searchParams);
        params.set(PARAM_KEY, JSON.stringify(newFilters));
        setSearchParams(params, { replace: true });
      }

      // Execute the original setter
      setRecoilFilters(newFilters);
    },
    [setRecoilFilters, searchParams, filters, setSearchParams, useFilters],
  );

  /** Set a single filter */
  const setFilter = useCallback(
    <U extends keyof CatalogFilters>(key: U, val: CatalogFilters[U]) => {
      setFilters((prev: any) => ({ ...prev, [key]: val }));
    },
    [setFilters],
  );

  const initialSearchParamsRef = useRef(searchParams);
  const initialFiltersRef = useRef(filters);
  const initialSetFiltersRef = useRef(setFilters);
  useEffect(() => {
    const urlFilters: CatalogFilters = JSON.parse(
      initialSearchParamsRef.current.get(PARAM_KEY) ?? '{}',
    );
    // On initial render we set the filters to URL overrides if provided
    // Otherwise we reset the URL filters to the ones stored in recoil
    if (Object.keys(urlFilters).length > 0) {
      initialSetFiltersRef.current({
        ...FILTER_DEFAULTS,
        ...urlFilters,
      });
    } else {
      initialSetFiltersRef.current(initialFiltersRef.current);
    }
  }, []);

  /** Reset a single filter */
  const resetFilter = useCallback(
    (key: keyof CatalogFilters) => {
      setFilter(key, FILTER_DEFAULTS[key]);
    },
    [setFilter],
  );

  /** Reset all filters */
  const resetFilters = useCallback(() => {
    setFilters(FILTER_DEFAULTS);
  }, [setFilters]);

  const queryFilters = useFilters ? filters : FILTER_DEFAULTS;
  const hiddenCatalogsFilter = useMemo(() => {
    if (queryFilters[FILTER_NAMES.VIEW] === FILTER_VIEW_OPTIONS.HIDDEN) {
      return SEARCH_PARAM_HIDDEN_VALUES.HIDDEN_ONLY;
    }
    if (queryFilters[FILTER_NAMES.DISPLAY_HIDDEN]) {
      return SEARCH_PARAM_HIDDEN_VALUES.BOTH;
    }
    return SEARCH_PARAM_HIDDEN_VALUES.VISIBLE_ONLY;
  }, [queryFilters]);

  const favoritedCatalogsFilter = useMemo(() => {
    if (queryFilters[FILTER_NAMES.VIEW] === FILTER_VIEW_OPTIONS.FAVORITE) {
      return SEARCH_PARAM_FAVORITE_VALUES.FAVORITED_ONLY;
    }
    return SEARCH_PARAM_FAVORITE_VALUES.BOTH;
  }, [queryFilters]);

  const mobileCatalogsFilter = useMemo(() => {
    if (queryFilters[FILTER_NAMES.VIEW] === FILTER_VIEW_OPTIONS.MOBILE) {
      return SEARCH_PARAM_MOBILE_VALUES.MOBILE;
    }
    return SEARCH_PARAM_MOBILE_VALUES.BOTH;
  }, [queryFilters]);

  const queryParams = useMemo(
    () => ({
      name: queryFilters.name,
      sort,
      hiddenCatalogsFilter,
      favoritedCatalogsFilter,
      isMobile: mobileCatalogsFilter,
      fields: ['mediaLinks'],
      perPage,
    }),
    [
      hiddenCatalogsFilter,
      favoritedCatalogsFilter,
      mobileCatalogsFilter,
      queryFilters.name,
      perPage,
      sort,
    ],
  );

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [...QUERY_KEYS.CATALOGS, queryParams],
    ({ pageParam = 1 }) =>
      catalogsApi.search({
        ...queryParams,
        page: pageParam,
      }),
    {
      getNextPageParam: pageCountGetNextPageParam,
      staleTime: STALE_TIME,
    },
  );

  const isError = data?.pages?.some((page) => page.error);

  const catalogs = useMemo(
    () =>
      data?.pages.reduce<Catalog[]>(
        (combined, current) =>
          combined.concat(current.error ? [] : current.data),
        [],
      ) ?? [],
    [data],
  );

  const pageZero = data?.pages?.[0];
  const totalItems = pageZero && !pageZero.error ? pageZero.total : undefined;

  const appliedFilters = useMemo(
    () =>
      getChangedFilters(filters, FILTER_DEFAULTS).filter((n) => n !== 'name'),
    [filters],
  );

  const getNextPage = useGetNextPage(
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  );
  useFetchAll(fetchAll, getNextPage, isFetchingNextPage, hasNextPage);

  return {
    catalogs,
    totalItems,
    isError,
    isLoading,
    isFetching: isLoading || isFetchingNextPage,
    isSuccess,
    hasNextPage,
    getNextPage,
    setFilter,
    resetFilter,
    resetFilters,
    filters,
    appliedFilters,
  };
}
