import { useEffect } from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/common/routes';

/** Redirects to login page, using the current route as a redirect parameter for when login finishes */
export default function useRedirectToLoginPage() {
  const navigate = useNavigate();

  const auth = useAuth();

  const willRedirect = !auth.isLoading && !auth.error && !auth.isAuthenticated;

  useEffect(() => {
    // Navigate to login page, returning to previous location when finished
    if (willRedirect) {
      // See https://developer.mozilla.org/en-US/docs/Web/API/Location
      const hrefMinusOrigin =
        window.location.pathname +
        window.location.search +
        window.location.hash;

      // Redirect to login, include return path if it's meaningful
      const returnPathSegment =
        hrefMinusOrigin !== ROUTES.ROOT
          ? `?returnPath=${encodeURIComponent(hrefMinusOrigin)}`
          : '';
      navigate(`${ROUTES.LOGIN}${returnPathSegment}`);
    }
  }, [navigate, willRedirect]);

  return willRedirect;
}
