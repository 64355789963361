import { Checkbox } from '@mui/material';
import PropTypes from 'prop-types';

import styles from './EditTable.module.css';
import EditTableCell from './EditTableCell';

function EditTableRow({
  row,
  cols,
  setCell,
  onFocusCell,
  rowIdx,
  onChecked,
  checked,
  errors,
  rowIdField = 'id',
}) {
  const getCellError = (rowIdx, field) => {
    const error = errors[field][rowIdx];
    return error ?? {};
  };
  return (
    <div className={styles.row}>
      <div className={styles.checkBoxCell}>
        <Checkbox
          checked={checked}
          onChange={(evt) => onChecked(evt.target.checked, row[rowIdField])}
        />
      </div>
      {cols.map((col, idx) => (
        <EditTableCell
          error={getCellError(rowIdx, col.field)}
          field={col.field}
          hideCell={col.hide}
          style={col.style}
          // eslint-disable-next-line react/no-array-index-key
          key={`row-${row[rowIdField]}-${col.field}-${idx}`}
          onFocus={() => onFocusCell(idx, rowIdx)}
          row={row}
          rowIdField={rowIdField}
          setCell={setCell}
        />
      ))}
    </div>
  );
}

EditTableRow.propTypes = {
  row: PropTypes.object,
  cols: PropTypes.array,
  setCell: PropTypes.func,
  onFocusCell: PropTypes.func,
  rowIdx: PropTypes.number,
  onChecked: PropTypes.func,
  checked: PropTypes.bool,
  errors: PropTypes.object,
  rowIdField: PropTypes.string,
};

export default EditTableRow;
