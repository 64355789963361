import { useState, useEffect } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, SxProps, Tab } from '@mui/material';

export type DustTabContainerTab = {
  label: string;
  content: JSX.Element;
  /** Styles for specific tab */
  tabStyles?: SxProps;
};

type Props = {
  ariaLabel?: string;
  tabs: DustTabContainerTab[];
  /** Styles for all tabs */
  tabStyles?: SxProps;
  headerStyles?: SxProps;
  onChange?: (e: string) => void;
  selectedTab?: string;
};

export default function DustTabContainer({
  ariaLabel,
  tabs,
  tabStyles,
  headerStyles,
  onChange,
  selectedTab,
}: Props) {
  const [activeTab, setActiveTab] = useState(selectedTab ?? '0');

  const styles = headerStyles || {
    borderBottom: 1,
    borderColor: 'divider',
    '.MuiTabs-root': {
      minHeight: 'var(--tab-list-height)',
    },
    '.MuiButtonBase-root': {
      fontWeight: '500',
      fontSize: '1rem',
      textTransform: 'none',
      minHeight: 'var(--tab-list-height)',
      padding: '1rem 1rem .5rem 1rem',
    },
    '.MuiButtonBase-root.Mui-selected': {
      fontWeight: '500',
    },
  };

  // Allow content to scroll inside a flex-container
  // Can be overridden by tabStyles if needed
  const modTabStyles: SxProps = {
    flex: 1,
    minHeight: 0,
    overflowY: 'auto',
    ...tabStyles,
  };

  useEffect(() => {
    if (selectedTab !== undefined && selectedTab !== activeTab) {
      setActiveTab(selectedTab);
    }
  }, [activeTab, selectedTab]);

  const handleTabChange = (_evt: any, val: string) => {
    setActiveTab(val);
    onChange?.(val);
  };

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 0,
      }}
    >
      <TabContext value={String(activeTab)}>
        <Box sx={styles}>
          <TabList aria-label={ariaLabel} onChange={handleTabChange}>
            {tabs.map((item, idx) => (
              <Tab
                aria-label={`${ariaLabel} ${item.label} tab`}
                key={item.label}
                label={item.label}
                value={String(idx)}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map((item, idx) => (
          <TabPanel
            key={item.label}
            sx={{ ...modTabStyles, ...item.tabStyles } as SxProps}
            value={String(idx)}
          >
            {item.content}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
}
