import { featureModel } from '@/common/entities/features/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import {
  DustArrayResponse,
  DustSingleResponse,
} from '@/services/requests/types';
import { formatAPIError, formatAPIReturn } from '@/services/utility';

import { MESSAGES } from '../constants';

/** Factory function for producing the features API */
export default class FeaturesApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Fetch a list of accounts */
  async getFeatures({ isActive = true } = {}) {
    return this.apiService
      .request({
        url: `/api/features/search`,
        method: 'POST',
        data: { isActive },
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, featureModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Create a feature flag */
  async createFeatureFlag({
    orgUuid,
    featureUuid,
  }: {
    orgUuid: string;
    featureUuid: string;
  }) {
    return this.apiService
      .request({
        url: `/api/features/flags`,
        method: 'POST',
        data: {
          accountUuid: orgUuid,
          featureUuid,
        },
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, featureModel))
      .catch((err) => {
        this.addToast(MESSAGES.FEATURES.ADD_ACCOUNT_FLAG_FAILURE, 'error');
        return formatAPIError(err);
      })
      .finally(() => {
        this.invalidate.orgs();
      });
  }

  /** Delete a feature flag */
  async deleteFeatureFlag(featureFlagUuid: string) {
    return this.apiService
      .request({
        url: `/api/features/flags/${featureFlagUuid}`,
        method: 'DELETE',
      })
      .then((res) => formatAPIReturn(res))
      .catch((err) => {
        this.addToast(MESSAGES.FEATURES.REMOVE_ACCOUNT_FLAG_FAILURE, 'error');
        return formatAPIError(err);
      })
      .finally(() => {
        this.invalidate.orgs();
      });
  }

  /** Delete a feature flag */
  async toggleFeatureForAccount({
    featureUuid,
    accountUuid,
    newIsActive,
  }: {
    featureUuid: string;
    accountUuid: string;
    newIsActive: boolean;
  }) {
    const data = newIsActive
      ? {
          add_account_uuids: [accountUuid],
        }
      : {
          remove_account_uuids: [accountUuid],
        };
    return this.apiService
      .request({
        url: `/api/features/${featureUuid}`,
        method: 'PATCH',
        data,
      })
      .then((res) => formatAPIReturn(res))
      .catch((err) => {
        this.addToast(MESSAGES.FEATURES.REMOVE_ACCOUNT_FLAG_FAILURE, 'error');
        return formatAPIError(err);
      })
      .finally(() => {
        this.invalidate.orgs();
      });
  }
}
