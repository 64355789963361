import { useState, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { formatDateForDisplay } from '@/common/dates';
// UNUSED: import { filterFiles } from '@/common/entities/things/utility';
import useToasts from '@/common/hooks/useToasts';
import { timeSort } from '@/common/utilities/sort';
import { downloadFileUrl } from '@/common/utility';
import ThingFileModal from '@/components/Composed/ThingFileModal/ThingFileModal';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

/**
 *
 * @param   {object}      props
 * @param   {Thing}       props.thing
 * @param   {ThingType|null}   [props.thingType]
 * @param   {Function}    props.onSelectFile
 * @param   {Function}    props.promptForVerify
 * @param   {boolean}     [props.disableInteraction]
 * @returns {JSX.Element}
 */
function ThingFiles({
  thing,
  thingType = undefined,
  onSelectFile,
  promptForVerify,
  disableInteraction = false,
}) {
  const files = thing.files ?? [];
  const { thingsApi } = useRequest();
  const { addToast } = useToasts();
  const [showAddFile, setShowAddFile] = useState(false);

  const typedFieldMap = useMemo(
    () =>
      Object.fromEntries(thingType?.fieldTypes.map((f) => [f.uuid, f]) ?? []),
    [thingType],
  );

  // Handle selection and actions
  const [selected, setSelected] = useState([]);

  const [removeUuid, setRemoveUuid] = useState(null);
  const [isRemoving, setIsRemoving] = useState(null);
  const [isDownloading, setIsDownloading] = useState(null);

  const isCalling = isRemoving || isDownloading;

  const handleSelect = (items) => {
    onSelectFile(items, setSelected); // Save items to sidebar state
    setSelected(items);
  };

  const onAddFile = () => {
    if (promptForVerify(() => setShowAddFile(true))) {
      return;
    }

    setShowAddFile(true);
  };

  const onDownloadFile = async (file) => {
    if (isCalling) {
      return;
    }
    setIsDownloading(file.uuid);
    const res = await downloadFileUrl(file.publicUri, file.filename);
    setIsDownloading(null);
    if (res.error) {
      addToast(
        'Could not download the requested file. Please try again or contact support. ',
        'error',
      );
    }
  };

  const onRemoveFile = async () => {
    setIsRemoving(removeUuid);
    await thingsApi.removeThingFile({
      thingUuid: thing.uuid,
      fileUuid: removeUuid,
    });
    setIsRemoving(null);
    setRemoveUuid(null);
  };

  const handleConfirmRemoveFile = (evt, file) => {
    evt.stopPropagation();
    if (isCalling || promptForVerify(() => setRemoveUuid(file.uuid))) {
      return;
    }
    setRemoveUuid(file.uuid);
  };

  // ---------------------------------------------------------------------------
  // Table Data Formatting

  // TODO: wrap in useMemo
  const columns = [
    {
      objGetter: (file) => file.uuid,
      field: 'id',
      headerName: 'UUID',
      hide: true,
    },
    {
      objGetter: (file) => file.filename,
      field: 'filename',
      headerName: 'Filename',
      flex: 1,
    },
    {
      objGetter: (file) => {
        if (typedFieldMap[file.thingMetadataTypeUuid]) {
          return `${typedFieldMap[file.thingMetadataTypeUuid].name} ${
            typedFieldMap[file.thingMetadataTypeUuid]?.isStatic
              ? '(Static)'
              : ''
          }`;
        }
        return '';
      },
      field: 'setBy',
      headerName: 'Thing Type Field',
      flex: 1,
      minWidth: 200,
    },
    {
      objGetter: (file) => file.fileType,
      field: 'type',
      headerName: 'File Type',
      flex: 1,
    },
    {
      objGetter: (file) => file.createdAt,
      valueFormatter: ({ value }) => formatDateForDisplay(value),
      sortComparator: (a, b) => timeSort(a, b),
      field: 'createdAt',
      headerName: 'Created at',
      flex: 1,
      sortingOrder: ['desc', 'asc'],
    },
    {
      objGetter: (file) => file,
      renderCell: ({ formattedValue: file }) => (
        <div className="flex-row gap-05">
          <DustLoadingButton
            color="primary"
            isIcon
            loading={isDownloading === file.uuid}
            onClick={(evt) => {
              evt.stopPropagation();
              onDownloadFile(file);
            }}
          >
            <DownloadOutlinedIcon />
          </DustLoadingButton>
          {!typedFieldMap[file.thingMetadataTypeUuid]?.isStatic && (
            <DustLoadingButton
              color="error"
              isIcon
              loading={isRemoving === file.uuid}
              onClick={(evt) => handleConfirmRemoveFile(evt, file)}
              aria-label={`Delete the file named ${file.filename}`}
            >
              <DeleteOutlinedIcon />
            </DustLoadingButton>
          )}
        </div>
      ),
      hide: disableInteraction,
      filterable: false,
      field: 'actions',
      editable: false,
      headerName: 'Actions',
      flex: 1,
    },
  ];

  // TODO: remove in favor of col-def field + valueGetter
  const rows = files.map((file) => {
    const row = {};
    columns.forEach((col) => {
      row[col.field] = col.objGetter(file);
    });
    return row;
  });

  // ---------------------------------------------------------------------------
  // Render Styles
  const addIconStyle = {
    border: 'var(--border-w) solid',
    borderRadius: '50%',
  };

  return (
    <div className="flex-col h-full">
      <div className="mb-1">
        <Button
          disabled={disableInteraction}
          onClick={onAddFile}
          startIcon={<AddIcon style={addIconStyle} />}
        >
          Add Files
        </Button>
      </div>
      <div className="flex-1">
        <DustDataGrid
          altTheme
          columns={columns}
          hideFooter
          onSelectionModelChange={handleSelect}
          rows={rows}
          selectionModel={selected}
          checkboxSelection
          isRowSelectable={() => !disableInteraction}
        />
      </div>
      <ThingFileModal
        fileType={FILE_TYPE_OPTIONS.FILE}
        open={showAddFile}
        setOpen={setShowAddFile}
        showPrimary={false}
        things={[thing]}
        thingType={thingType}
      />
      <DustModal
        footerProps={{
          onCancel: () => setRemoveUuid(null),
          onSubmit: () => onRemoveFile(),
          submitLabel: 'Remove',
          submitColor: 'error',
          loading: !!isRemoving,
        }}
        onClose={() => setRemoveUuid(null)}
        open={!!removeUuid}
        title="Remove File"
      >
        <p>
          Are you sure you want to remove file: <br />-{' '}
          {files.find((f) => f.uuid === removeUuid)?.filename}
        </p>
      </DustModal>
    </div>
  );
}

ThingFiles.propTypes = {
  thing: PropTypes.object.isRequired,
  thingType: PropTypes.object,
  onSelectFile: PropTypes.func.isRequired,
  promptForVerify: PropTypes.func.isRequired,
};

export default ThingFiles;
