import { memo, useState } from 'react';

import PropTypes from 'prop-types';

import useThingsByUuids from '@/common/entities/things/useThingsByUuids';
import { timeSort } from '@/common/utilities/sort';
import DustEventBlocker from '@/components/Library/DustEventBlocker';
import DustLinkButton from '@/components/Library/DustLinkButton';
import { Mixpanel } from '@/mPanel';

import AddAnnotation from './AddAnnotation';
import { ACTION_FORMATTER } from './summaryActions';
import styles from './TransactionDetail.module.css';

const isChildrenRelationshipEditData = (actionDataKey) =>
  actionDataKey === 'children_added_uuids' ||
  actionDataKey === 'children_removed_uuids';

/**
 * Exported with memo(): This hook does a fair bit of work, memoize props,
 * transaction should be immutable.
 *
 * @param {object}      props
 * @param {Transaction} props.transaction
 * @param {boolean} [props.disableInteraction]
 */
function TransactionSummary({ transaction, disableInteraction = false }) {
  const annotations = transaction.annotations ?? [];
  const actionData = transaction?.transactionData?.actionData ?? {};
  const actionDataWithAnnotations = {
    annotations: annotations.sort((a, b) => timeSort(b, a, 'createdAt')),
    ...actionData,
    file_data: Object.values(actionData?.file_data ?? {}), // File changed data must be changed to an array
  };

  // Some annoyingly-specific logic to fetch required things to allow showing
  // titles when we only have uuids in the actions.
  const thingUuidsToFetch = new Set();
  Object.entries(actionDataWithAnnotations).forEach(([k, v]) => {
    if ((k === 'prev_parent_uuid' || k === 'next_parent_uuid') && v != null) {
      thingUuidsToFetch.add(v);
    }
    if (isChildrenRelationshipEditData(k)) {
      v.forEach((uuid) => {
        thingUuidsToFetch.add(uuid);
      });
    }
  });

  // Fetch required data
  const { thingsMap = {} } = useThingsByUuids({
    catalogUuid: transaction.catalogUuid,
    thingUuids: [...thingUuidsToFetch],
  });

  const summaryItems = [];
  Object.keys(actionDataWithAnnotations).forEach((key) => {
    const dataValue = actionDataWithAnnotations[key];
    if (ACTION_FORMATTER[key]) {
      const renderFormatter = (d) =>
        ACTION_FORMATTER[key](d, { transaction, thingsMap });
      if (
        Array.isArray(dataValue) &&
        isChildrenRelationshipEditData(key) === false // These keys requires an array for its one summary box
      ) {
        summaryItems.push(...dataValue.map(renderFormatter));
      } else {
        summaryItems.push(renderFormatter(dataValue));
      }
    }
  });

  // Allow actions to conditionally not render by returning null (only null,
  // allow error on undef just like react)
  const renderedSummaryItems = summaryItems.filter((i) => i !== null);

  const [showAdd, setShowAdd] = useState(false);

  const triggerAdd = () => {
    Mixpanel.track('Add Annotation to Transaction', {
      'Transaction Id': transaction.uuid,
      'Transaction Type': transaction.transactionType,
      Verified: transaction.isVerified,
      Outcome: transaction.outcomeLabel,
      'Date Created': transaction.createdAt,
    });

    setShowAdd(true);
  };

  return (
    <DustEventBlocker className="flex-1">
      <div className="flex-row justify-space items-end">
        <h3 className={styles.header}>Summary</h3>
        {!disableInteraction && (
          <DustLinkButton onClick={triggerAdd} sx={{ height: '2rem' }}>
            Add Annotation
          </DustLinkButton>
        )}
      </div>
      <div className={styles.summaryItemsBox}>
        {showAdd && (
          <AddAnnotation
            onClose={() => setShowAdd(false)}
            transaction={transaction}
          />
        )}
        {renderedSummaryItems.map((item) => (
          <div className={styles.summaryItem} key={item.key}>
            <div className={styles.summaryItemLeftCol}>{item.icon}</div>
            <div className={styles.summaryItemRightCol}>
              <div className={styles.summaryItemTitle}>{item.title}</div>
              <div className={styles.summaryItemDate}>{item.date}</div>
              <div className={styles.summaryItemValue}>{item.body}</div>
            </div>
          </div>
        ))}
        {summaryItems.length < 1 && (
          <div className={styles.summaryItem}>No Summary Info Available</div>
        )}
      </div>
    </DustEventBlocker>
  );
}

TransactionSummary.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default memo(TransactionSummary);
