import { useCallback, useMemo } from 'react';

import useTransactionsData, {
  FILTER_LABELS,
  filterToTrackingProp,
  TransactionFilters,
  transactionsSortAtom,
  TRANSACTION_SORT_OPTIONS,
  TRANSACTION_SORT_TRACKING_EVENT,
} from '@/common/entities/transactions/useTransactionsData';
import { useFetchMoreToFillHeightRef } from '@/common/utilities/table';
import TransactionDetail from '@/components/Composed/TransactionDetail/TransactionDetail';
import TransactionHeading from '@/components/Composed/TransactionDetail/TransactionHeading';
import TransactionsFilters from '@/components/Composed/TransactionFilters/TransactionsFilters';
import DustAccordion from '@/components/Library/DustAccordion';
import DustFilterChip from '@/components/Library/DustFilterChip';
import DustLinkButton from '@/components/Library/DustLinkButton';
import DustLoader from '@/components/Library/DustLoader';
import { Mixpanel } from '@/mPanel';
import useSort from '@/services/useSort';

import styles from './ThingTabs.module.css';

type Props = {
  thing: Thing;
  /** Stops UI actions from being available */
  disableInteraction: boolean;
};

export default function ThingTransactions({
  thing,
  disableInteraction = false,
}: Props) {
  const { sortRequestParams } = useSort(
    transactionsSortAtom,
    TRANSACTION_SORT_OPTIONS,
    { trackingEvent: TRANSACTION_SORT_TRACKING_EVENT },
  );
  const {
    transactions,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    totalItems,
    getNextPage,
    filters,
    resetFilter,
    resetFilters,
    setFilter,
    appliedFilters,
  } = useTransactionsData({ thingUuid: thing.uuid, ...sortRequestParams });

  const items = transactions
    ? transactions.map((t) => ({
        name: t.uuid,
        summary: <TransactionHeading transaction={t} />,
        content: (
          <TransactionDetail
            showTitle={false}
            transactionUuid={t.uuid}
            disableInteraction={disableInteraction}
          />
        ),
      }))
    : [];

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const isBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop <
      e.currentTarget.clientHeight + 10;
    if (isBottom) {
      void getNextPage();
    }
  };

  const listContainerRef = useFetchMoreToFillHeightRef(
    getNextPage,
    transactions.length,
    3.5,
  );

  const baseTrackingData = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(filters).map(([key, value]) =>
          filterToTrackingProp(
            key as keyof typeof filters,
            value as ValueOf<typeof filters>,
          ),
        ),
      ),
    [filters],
  );

  const handleFiltersOpened = useCallback(() => {
    Mixpanel.track('View Transaction Filters', baseTrackingData);
  }, [baseTrackingData]);

  // NOTE: Type for reference only, may not be worth the complexity
  const handleSetFilter = useCallback(
    (
      filterName: keyof TransactionFilters,
      filterValue: Date | string[] | null,
    ) => {
      const trackValues = {
        ...baseTrackingData,
        ...Object.fromEntries([filterToTrackingProp(filterName, filterValue)]),
      };
      Mixpanel.track('Filter Transactions', trackValues);
      setFilter(filterName, filterValue);
    },
    [setFilter, baseTrackingData],
  );

  if (isError) {
    return <div className="mt-1">Could not load transactions</div>;
  }

  return (
    <>
      <div className={styles.controlsRow}>
        {/* CONTENT */}
        <div className={styles.chipRow}>
          {appliedFilters.map((filter) => (
            <DustFilterChip
              key={filter}
              label={FILTER_LABELS[filter]}
              onDelete={() => resetFilter(filter)}
            />
          ))}
        </div>
        {appliedFilters.length > 0 && (
          <DustLinkButton
            onClick={resetFilters}
            sx={{ margin: '0 .5rem', minWidth: 'fit-content' }}
          >
            Clear filters
          </DustLinkButton>
        )}
        <TransactionsFilters
          filters={filters}
          resetFilter={resetFilter}
          resetFilters={resetFilters}
          setFilter={handleSetFilter}
          showCatalogFilter={false}
          showResetFilters={appliedFilters.length > 0}
          onOpen={handleFiltersOpened}
        />
      </div>
      <div
        className={styles.transactionCont}
        onScroll={handleScroll}
        ref={listContainerRef}
      >
        {isSuccess && (
          <DustAccordion
            accordionProps={{ TransitionProps: { unmountOnExit: true } }}
            items={items}
            singleExpanded
          />
        )}
        {(isLoading || isFetching) && (
          <DustLoader className="mt-1 mb-1" size="medium" />
        )}
        {transactions.length === totalItems && (
          <p className="text-center mt-1 mb-1">All transactions listed</p>
        )}
      </div>
    </>
  );
}
