export const HTTP_REQUEST_METHODS = {
  DELETE: 'delete',
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
};

export const HTTP_RESPONSE_CODES = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const MESSAGES = {
  COMMON: {
    FETCH_FAILED:
      'Could not retrieve the data. Please reload the page and try again.',
    GENERIC: 'Could not complete the request. Please try again.',
    PERMISSION_DENIED: 'You do not have permission to perform that action',
  },
  ANTI_TAMPER_COMPLETE: 'AntiTamper analysis saved',
  ANTI_TAMPER_FAILED: 'Error with tamper analysis. Please try again',
  BATCH_IMPORT_EMPTY: 'No Things found in your import.',
  BATCH_IMPORT_FAILED: 'There was an error with your upload. Please try again',
  CHECK_IN_FAILED:
    'There was an error checking in your things. Please try again',
  CHECK_IN_RELATIONSHIP_LOOP:
    'Import can not be completed due to relationship loop. Remove relationships of existing things and try again',
  CHECKOUT_DOWNLOAD_FAILED:
    "Failed to download file. Please try again in 'Checkout History'",
  CATALOG_CREATE_SUCCESS: (name: string) =>
    `Catalog ${name} successfully created. `,
  CATALOG_CREATE_FAIL: 'Could not create the catalog. Please try again.',
  CATALOG_UPDATE_SUCCESS: 'Catalog successfully updated.',
  FEEDBACK: {
    SUCCESS: 'Your feedback has been received. Thank you!',
    FAIL: 'Sorry, couldn’t submit your feedback. Please try again.',
  },
  FEATURES: {
    ADD_ACCOUNT_FLAG_FAILURE: 'Failed to add feature flag to account',
    REMOVE_ACCOUNT_FLAG_FAILURE: 'Failed to remove feature flag to account',
  },
  FILE_FAILURE:
    'Unable to process your uploads at this time. Please try again later',
  FILE_UPDATE_FAILURE: 'Could not update the requested file. Please try again.',
  FILE_DELETE_FAILURE: 'Could not delete the requested file. Please try again.',
  FACILITIES: {
    FACILITY_CREATED: {
      SUCCESS: (facilityName: string) =>
        `New facility created: ${facilityName}`,
      FAILURE: (facilityError: string) =>
        `Error creating facility: ${facilityError}`,
      API_MESSAGE: (message: string) => message,
    },
    FACILITY_UPDATED: {
      SUCCESS: (facilityName: string) => `Facility updated: ${facilityName}`,
      FAILURE: (facilityError: string) =>
        `Error updating facility: ${facilityError}`,
      API_MESSAGE: (message: string) => message,
    },
  },
  THING_CREATE: {
    BULK_ERROR:
      'Invalid CSV. Could not create the things. Please check your fields and try again.',
    CREATE_ERROR:
      'Could not create the thing. Please check your fields and try again.',
  },
  THING_MOVE: {
    SUCCESS: 'Thing successfully moved between catalogs.',
    FAILURE: 'Thing could not be moved. Please try again.',
    DUST_CONFLICT:
      'A Thing in the destination catalog has the same DUST Id. Please resolve the conflict by unbinding the tag from either Thing and try again.',
  },
  GROUPS: {
    CREATED: (groupName: string) => `New user group created: ${groupName}`,
    CREATE_FAILED: (groupName: string, message: string) =>
      `Error creating group: ${groupName}. ${message}`,
    ALREADY_EXISTS: 'User group name already exists. Names must be unique.',
    DELETED: () => `User group(s) deleted`,
    DELETE_FAILED: (message: string) => `Error deleting group: ${message}`,
    UPDATE: (groupName: string) => `Group updated successfully: ${groupName}`,
    UPDATE_ROLES: {
      SUCCESS: `Roles updated for group.`,
      FAIL: 'Roles could not be set for the group. ',
    },

    UPDATE_FAILED: (message: string) =>
      `Error updating group. ${message ?? ''}`,
    UPDATED_FILTERS: 'Filters updated.',
    ADD_USER: {
      SUCCESS: 'Users added to group successfully. ',
      FAIL: 'Users could not be added to the group. Please try again or contact support. ',
    },
    REMOVE_USER: {
      SUCCESS: 'Users removed from the group successfully. ',
      FAIL: 'Users could not be removed from the group. Please try again or contact support. ',
    },
  },
  RELATIONSHIPS: {
    UPDATE_FAILED: 'Failed to update thing relationships',
  },
  CATALOG_UPDATE_FAILED: 'Could not update the catalog. Please try again.',
  CART_ADD_FAILED: 'Could not add item to checkout cart. Please try again.',
  CART_ADD_THING_SUCCESS: 'All Things successfully added to checkout cart.',
  CART_ADD_CATALOG_SUCCESS: 'Catalog successfully added to checkout cart.',
  CART_ADD_CATALOG_NAME_SUCCESS: (name: string) =>
    `Catalog ${name} successfully added to checkout cart.`,
  CART_REMOVE_FAILED:
    'Could not remove item from checkout cart. Please try again.',
  USER: {
    INVITED: 'User has been invited successfully.',
    INVITE_ERROR: (message: string) => `Error inviting user: ${message}`,
    UPDATED: 'User updated successfully.',
    UPDATE_ERROR: (message: string) => `Error updating user: ${message}`,
    PASSWORD_RESET_SUCCESS: 'Password successfully reset.',
    PASSWORD_RESET_ERROR: (message: string) =>
      `Failed to reset user password: ${message}`,
    PASSWORD_UPDATED: 'Password updated successfully.',
    GROUPS_FAILED:
      'Could not add the user to the specified groups. Please try editing the user groups. ',
  },
  DUST_DEACTIVATE_FAILED:
    'Could not deactivate the DUST tag. Please try again.',
  DUST_DEACTIVATE_SUCCESS: 'DUST tag successfully deactivated.',
  EXPORT_BATCH_FAILED:
    'Export failed. One or more things may already be checked out.',
  TRANSACTION_ANNOTATE_FAILED:
    'Could not add the annotation. Please try again.',
  EXPIRED_PASSWORD_RESET:
    'Reset password link has expired. Please request a new password reset.',
  THING_TYPE: {
    CREATE: {
      SUCCESS: 'Thing type successfully created.',
      FAIL: 'Could not create the Thing Type. Please try again.',
    },
    EDIT: {
      SUCCESS: 'Thing type successfully modified.',
      FAIL: 'Thing type modification failed. Please try again.',
    },
    DELETE: {
      SUCCESS: 'Thing type successfully deleted.',
      FAIL: 'Could not delete the thing type. Please try again.',
    },
  },
  SCAN: {
    ORPHANED_DUST_TAG:
      'Could not load Thing data. Please contact the DUST customer success team',
    SUCCESS: 'Match successfully found.',
  },
};

// NOTE: About this QUERY_KEYS object...
//
// Order matters within a key. Invalidating ['catalogs'] will also invalidate
// ['catalogs', 'things', 'someUuid'] but NOT ['things', 'catalogs', 'someUuid']
//
// Keep any unreproducable queryParams outside this object, e.g.:
// [...QUERY_KEYS.THING_TYPES_SEARCH, queryParams]
// so that invalidating QUERY_KEYS.THING_TYPES_SEARCH can be done correctly
// (passing queryParams as an argument always adds an extra element to the
// query keys array, and prevents correct invalidation)
//
// For best practices, see React Query's recommended post: https://tkdodo.eu/blog/effective-react-query-keys

export const QUERY_KEYS = Object.freeze({
  ORGS: ['orgs'],
  ORG: (orgUuid: string) => [...QUERY_KEYS.ORGS, `org-${orgUuid}`],
  USERS: ['users'],
  USER: (userId: string) => [...QUERY_KEYS.USERS, `user-${userId}`],
  USER_ORGS_LIST: (userId: string) => [...QUERY_KEYS.USER(userId), 'orgs'],
  USER_GROUP_LIST: (sub: string) => [`user-group-list-${sub}`],
  USERS_IN_GROUP_LIST: (groupId: string) => [...QUERY_KEYS.USERS, groupId],
  EXPORT_BATCHES: ['export-batch'],
  EXPORT_BATCH: (uuid: string) => [
    ...QUERY_KEYS.EXPORT_BATCHES,
    `export-batches-${uuid}`,
  ],
  FACILITIES: ['facilities'],
  FACILITY: (facilityId: string) => [
    ...QUERY_KEYS.FACILITIES,
    `facilities-${facilityId}`,
  ],
  FEATURES: ['features'],
  FILTERS: (groupUuid: string) => [`filters-${groupUuid}`],
  GROUPS: ['groups'],
  GROUPS_LIST: ['groups', 'list'],
  GROUP: (groupUuid: string) => ['groups', groupUuid],
  GROUP_ROLES: (groupOidcId: string) => [`roles-${groupOidcId}`],
  ORG_ROLES: ['orgRoles'],
  // Thing Handling
  CATALOG: (catalogUuid: string) => ['catalogs', catalogUuid],
  CATALOGS: ['catalogs'],
  // Catalog is independent of things inside so no need to invalidate things
  // When invalidating me must make sure to invalidate with and without catalogUuid
  THINGS: ['things'],
  /** For useThingsData infinite query */
  THINGS_LIST: ['things', 'list'],
  THINGS_BY_UUID: (uuids: string[]) => ['things', 'by-uuids', uuids],
  // Multi-things listing needs updating when a thing is changed
  THING: (thingUuid: string) => ['things', 'thing', thingUuid],
  // Multi-things and the thing needs updating when a transactions is edited
  // Thing types should be independent
  THING_TYPES: ['thing-types'],
  THING_TYPES_SEARCH: ['thing-types', 'search'], // "[...THING_TYPES," not used - not fn, breaks QUERY_KEYS's type
  THING_TYPE: (thingTypeUuid: string) => [
    ...QUERY_KEYS.THING_TYPES,
    thingTypeUuid,
  ],
  THING_META_FILTERS_ALL: ['thing-meta-filters'],
  THING_META_FILTERS: (catalogUuid: string) => [
    ...QUERY_KEYS.THING_META_FILTERS_ALL,
    catalogUuid,
  ],
  SHOPPING_CART: ['shopping-cart'],
  TRANSACTIONS: ['transactions'],
  TRANSACTION: (transactionUuid: string) => [
    ...QUERY_KEYS.TRANSACTIONS,
    `transaction-${transactionUuid}`,
  ],
  THING_MEDIA: (uuids: Array<string>) => [uuids],
} as const);

// TODO: use upcoming TypeScript `satisfies` keyword for this type check
((q: Record<string, readonly unknown[] | ((...args: any[]) => unknown[])>) =>
  q)(QUERY_KEYS);
