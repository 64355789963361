import { useState } from 'react';

import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const STEPS = {
  SINGLE: 'single',
  MULTI: 'multi',
};

type Props = { single: React.ReactNode; multi: React.ReactNode };

export default function ModeSelector({ single, multi }: Props) {
  const [step, setStep] = useState(STEPS.SINGLE);

  const buttonInactiveStyle = {
    color: 'var(--medium-grey)',
    borderColor: 'var(--medium-grey)',
  };

  return (
    <>
      <ButtonGroup sx={{ marginBottom: '1rem' }}>
        <Button
          onClick={() => setStep(STEPS.SINGLE)}
          startIcon={<AddBoxOutlinedIcon />}
          sx={step !== STEPS.SINGLE ? buttonInactiveStyle : null}
        >
          Create Single Thing
        </Button>
        <Button
          onClick={() => setStep(STEPS.MULTI)}
          startIcon={<UploadOutlinedIcon />}
          sx={step !== STEPS.MULTI ? buttonInactiveStyle : null}
        >
          Create Multiple Things
        </Button>
      </ButtonGroup>
      {step === STEPS.SINGLE && single}
      {step === STEPS.MULTI && multi}
    </>
  );
}
