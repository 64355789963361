import { MouseEventHandler } from 'react';

import { Tooltip } from '@mui/material';

import useCheckout from '@/common/hooks/useCheckout';
import DustLinkButton from '@/components/Library/DustLinkButton';

import styles from './CatalogItems.module.css';

type CatalogSummaryProps = {
  catalog: Catalog;
  readOnly: boolean;
  things: ShoppingCartThings;
};

function CatalogSummary({
  catalog,
  readOnly = false,
  things,
}: CatalogSummaryProps) {
  const { checkout, isCommitting } = useCheckout();

  const handleRemoveCatalog: MouseEventHandler<Element> = (evt) => {
    evt.stopPropagation(); // Prevent menu from closing
    void checkout.removeThings({
      catalogUuids: [catalog.uuid],
    });
  };

  return (
    <>
      <h3 className={styles.catalogTitle}>
        <Tooltip title={catalog.name}>
          <span>{catalog.name}</span>
        </Tooltip>
        {` (${things.thingsInCatalog(catalog.uuid).length})`}
        {things.errorThingsInCatalogCount(catalog.uuid) > 0 && (
          <div className={styles.catalogUnavailable}>
            Unavailable: {things.errorThingsInCatalogCount(catalog.uuid)}
          </div>
        )}
      </h3>
      {!readOnly && (
        <DustLinkButton
          loading={isCommitting}
          onClick={handleRemoveCatalog}
          sx={{ color: 'var(--color-text-secondary)', minWidth: '115px' }}
        >
          Remove Catalog
        </DustLinkButton>
      )}
    </>
  );
}

export default CatalogSummary;
