import { useState } from 'react';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Edit from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/common/routes';
import DustModal from '@/components/Library/DustModal';
import DustMultiFileIcon from '@/components/Library/Icons/DustMultiFileIcon';
import useRequest from '@/services/requests/useRequest';

/**
 *
 * @param   {object}      props
 * @param   {ThingType}   props.thingType
 * @returns {JSX.Element}
 */
function ThingTypeActions({ thingType }) {
  const navigate = useNavigate();

  const [showRemove, setShowRemove] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { thingTypeApi } = useRequest();

  const handleRemove = async () => {
    setIsRemoving(true);
    const res = await thingTypeApi.remove({ uuid: thingType.uuid });
    setIsRemoving(false);

    if (!res.error) setShowRemove(false);
  };

  return (
    <div className="flex-row items-center">
      <Tooltip enterDelay={700} title="Duplicate Type">
        <IconButton
          onClick={() =>
            navigate(ROUTES.ADMIN_CLONE_THING_TYPE(thingType.uuid))
          }
        >
          <DustMultiFileIcon sx={{ height: 20, width: 20 }} />
        </IconButton>
      </Tooltip>
      <Tooltip enterDelay={700} title="Edit Type">
        <IconButton
          color="primary"
          onClick={() => navigate(ROUTES.ADMIN_EDIT_THING_TYPE(thingType.uuid))}
        >
          <Edit sx={{ height: 20, width: 20 }} />
        </IconButton>
      </Tooltip>
      <Tooltip
        enterDelay={700}
        title={
          thingType.thingsCount > 0
            ? 'In use. Cannot be deleted.'
            : 'Delete Type'
        }
      >
        <span>
          <IconButton
            color="error"
            disabled={thingType.thingsCount > 0}
            onClick={() => setShowRemove(true)}
          >
            <DeleteOutlinedIcon sx={{ height: 20, width: 20 }} />
          </IconButton>
        </span>
      </Tooltip>
      {showRemove && (
        <DustModal
          footerProps={{
            showPrev: false,
            submitColor: 'error',
            submitLabel: 'Delete',
            loading: isRemoving,
            onSubmit: handleRemove,
            onCancel: () => setShowRemove(false),
          }}
          onClose={() => setShowRemove(false)}
          open={showRemove}
          title={`Delete ${thingType.name}`}
        >
          <p>
            {' '}
            You are about to delete a Thing Type, which cannot be undone. Click{' '}
            <strong>Delete</strong> to permanently remove or{' '}
            <strong>Cancel</strong> to retain.
          </p>
        </DustModal>
      )}
    </div>
  );
}

export default ThingTypeActions;
