import { useEffect, useRef, useState } from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import { pageStyleOverrides } from '@/common/routes';
import LeftNav from '@/components/Composed/LeftNav/LeftNav';
import NavigatePrompt from '@/components/Composed/NavigatePrompt/NavigatePrompt';
import PageTitle from '@/components/Composed/PageTitle/PageTitle';
import SessionDialog from '@/components/Composed/SessionDialog/SessionDialog';
import ShoppingCart from '@/components/Composed/ShoppingCart/ShoppingCart';
import ToastBar from '@/components/Composed/Toast/ToastBar';
import TopNav from '@/components/Composed/TopNav/TopNav';
import VerifiedSessionDialog from '@/components/Composed/VerifiedSessionDialog/VerifiedSessionDialog';
import { combineClass } from '@/components/Library/utility';
import ErrorBoundary from '@/components/Pages/ErrorPages/ErrorBoundary';
import ReleaseBanner from '@/components/ReleaseBanner';
import useRequest from '@/services/requests/useRequest';

declare global {
  interface Window {
    services: ReturnType<typeof useRequest>;
  }
}

function AppBody() {
  const [showBlur, setShowBlur] = useState(false);
  const location = useLocation();
  const styleOverrides = pageStyleOverrides(location.pathname);

  // For e2e testing we expose the api service layer for direct queries
  const services = useRequest();
  useEffect(() => {
    if (import.meta.env.DEV) {
      window.services = services;
    }
  }, [services]);

  const appContentRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className="app-container"
      style={
        import.meta.env.VITE_NON_PROD_ENV_NAME
          ? {
              backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='298' height='200' viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3e%3ctext x='0' y='150' transform='rotate%28-45%29' fill='lightgray'%3e${
                import.meta.env.VITE_NON_PROD_ENV_NAME
              }%3c/text%3e%3c/svg%3e ")`,
            }
          : {}
      }
    >
      <LeftNav onNavBlur={(val) => setShowBlur(val)} />
      <div className="app-container__outer_content">
        <TopNav />
        <VerifiedSessionDialog />
        <SessionDialog />
        <NavigatePrompt />
        <ErrorBoundary path={location.pathname}>
          <div
            className="flex-row flex-1 min-h-0 relative"
            id="body-content" /* id for AppSidebar */
            ref={appContentRef}
          >
            <div
              className={combineClass(
                'app-container__content page-container--padded',
                styleOverrides.pageClassOverrides,
              )}
            >
              <PageTitle />
              <ShoppingCart />
              <Outlet />
            </div>
          </div>
          <ReleaseBanner appContentRef={appContentRef} />
        </ErrorBoundary>
        {showBlur && <div className="body-blur" />}
      </div>
      <ToastBar />
    </div>
  );
}

export default AppBody;
