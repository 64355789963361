import { useState } from 'react';

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { IconButton, Tooltip } from '@mui/material';

import { Xtag } from '@/common/entities/things/typedefs';
import DeactivationModal from '@/components/Composed/DeactivationModal/DeactivationModal';
import XTagDeactivationModal from '@/components/Composed/XTagDeactivationModal/XTagDeactivationModal';
import DustXTagIcon from '@/components/Library/Icons/DustXTagIcon';

import styles from './XTagSummary.module.css';

type SummaryXTagProps = {
  tag: Xtag;
  thing: Thing;
};

const TYPE_MAP = {
  DUST: {
    label: 'DUST',
    color: 'var(--primary-blue}',
  },
  NFC: {
    label: 'NFC Tag',
    color: 'var(--orange)',
  },
  QR: {
    label: 'QR Code',
    color: 'var(--success-green)',
  },
  DATA_MATRIX: {
    label: '2D Matrix',
    color: 'var(--dark-blue)',
  },
};

export default function SummaryXTag({ tag, thing }: SummaryXTagProps) {
  const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);
  const [xTagDeactivationUuid, setXTagDeactivationUuid] = useState<string>();
  const handleDeactivate = () => {
    if (tag.xtagType === 'DUST') {
      setDeactivationModalOpen(true);
    } else {
      setXTagDeactivationUuid(tag.uuid);
    }
  };

  return (
    <>
      <div className={styles.row}>
        <div
          className={styles.tagCircle}
          style={{ backgroundColor: TYPE_MAP[tag.xtagType].color }}
        >
          <DustXTagIcon type={tag.xtagType} />
        </div>
        <div className={styles.typeAndValue}>
          <div className={styles.type}>{TYPE_MAP[tag.xtagType].label}</div>
          <div className={styles.value}>{tag.value}</div>
        </div>
        <Tooltip title="Click to Deactivate this Tag">
          <IconButton onClick={handleDeactivate}>
            <RemoveCircleOutlineOutlinedIcon
              sx={{ color: 'var(--error-red)' }}
            />
          </IconButton>
        </Tooltip>
      </div>
      <DeactivationModal
        open={deactivationModalOpen}
        onSuccess={() => setDeactivationModalOpen(false)}
        onCancel={() => setDeactivationModalOpen(false)}
        onError={() => setDeactivationModalOpen(false)}
        thing={thing}
      />
      <XTagDeactivationModal
        open={!!xTagDeactivationUuid}
        onSuccess={() => setXTagDeactivationUuid(undefined)}
        onCancel={() => setXTagDeactivationUuid(undefined)}
        onError={() => setXTagDeactivationUuid(undefined)}
        thing={thing}
        xTagUuid={xTagDeactivationUuid ?? ''}
      />
    </>
  );
}
