import { exportBatchModel } from '@/common/entities/exportBatches/models';
import { CheckInConflict } from '@/common/entities/exportBatches/typedefs';
import { thingModel } from '@/common/entities/things/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import {
  DustArrayResponse,
  DustSingleResponse,
} from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIReturn,
  formatAPIError,
} from '@/services/utility';

import { MESSAGES } from '../constants';

export type CheckInResolutionParam = {
  thingUuid: string;
  resolutions: string[];
};

type CheckInParams = {
  uuid: string;
  dryRun?: boolean;
  thingResolutions?: CheckInResolutionParam[];
};

/** Factory function for producing the things API */
export default class ExportBatchesApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Create an export batch */
  async create({ name, description }: { name: string; description: string }) {
    return this.apiService
      .request({
        url: encodeURI('/api/exports/'),
        method: 'POST',
        data: {
          name,
          description,
        },
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.shoppingCart();
        this.invalidate.things();
        return formatAPIReturn(res, exportBatchModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.EXPORT_BATCH_FAILED, 'error');
        }),
      );
  }

  async checkOut({ uuid }: { uuid: string }) {
    return this.apiService
      .request({
        url: encodeURI(`/api/exports/${uuid}/check-out`),
        method: 'POST',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, exportBatchModel)) // TODO: possibly add invalidators here?
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.EXPORT_BATCH_FAILED, 'error');
        }),
      );
  }

  /** Get export batch */
  async get({ uuid }: { uuid: string }) {
    return this.apiService
      .request({
        url: encodeURI(`/api/exports/${uuid}`),
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, exportBatchModel))
      .catch((err) => formatAPIError(err));
  }

  /** Get export batches */
  async getExportBatches({
    search = '',
  }: // sortBy = '-createdAt',
  {
    search: string;
    // sortBy: string;
  }) {
    return this.apiService
      .request({
        url: '/api/exports/search',
        method: 'POST',
        data: {
          search,
          // sortBy, // TODO: this doesn't work yet
        },
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, exportBatchModel))
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED));
  }

  /** Query things from an export batch */
  async searchThings({
    batchUuid,
    search,
  }: {
    batchUuid: string;
    search: string;
  }) {
    return this.apiService
      .request({
        url: `/api/exports/${batchUuid}`,
        method: 'GET',
        data: {
          search,
        },
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, thingModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  async validateBatch({ uuid }: { uuid: string }) {
    return this.apiService
      .request({
        url: `/api/exports/${uuid}/validate`,
        method: 'POST',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, exportBatchModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Regenerate export batch */
  async regenerate(uuid: string) {
    return this.apiService
      .request({
        url: `/api/exports/${uuid}/regenerate`,
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, exportBatchModel))
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED));
  }

  /** Query things from an export batch */
  async recoverThing({ thingUuid }: { thingUuid: string }) {
    return this.apiService
      .request({
        url: `/api/things/${thingUuid}/recover`,
        method: 'POST',
        data: {},
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      })
      .finally(() => this.invalidate.things());
  }

  /** Upload an export batch for import */
  async upload(file: File) {
    const formData = new FormData();
    formData.append('archive', file, file.name);
    return this.apiService
      .request({
        url: '/api/exports/upload',
        method: 'POST',
        data: formData,
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, exportBatchModel))
      .catch((err) => {
        this.addToast(MESSAGES.BATCH_IMPORT_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Check in an export batch */
  async checkIn({
    uuid,

    thingResolutions = [],
  }: CheckInParams) {
    const data = {
      ...(thingResolutions && { thingResolutions }),
    };
    return this.apiService
      .request({
        url: encodeURI(`/api/exports/${uuid}/check-in`),
        method: 'POST',
        data,
      })
      .then((res: DustArrayResponse) =>
        formatAPIReturn<CheckInConflict, any>(res),
      )
      .catch(
        defaultErrorHandler(this.addToast, (err) => {
          if (err.response?.data?.errorMessageCode === 'RELATIONSHIP_LOOP') {
            this.addToast(MESSAGES.CHECK_IN_RELATIONSHIP_LOOP, 'error');
          } else {
            this.addToast(MESSAGES.CHECK_IN_FAILED, 'error');
          }
        }),
      );
  }
}
