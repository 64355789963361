import { useState } from 'react';

import PropTypes from 'prop-types';

import { remToPx, downloadFileUrl } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

function MultiFileSelect({ thing, data, onClose, promptForVerify }) {
  const { thingsApi } = useRequest();
  const { selected } = data;
  const files = thing.files.filter((f) => selected.includes(f.uuid));

  const [isRemoving, setIsRemoving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPromptedForRemove, setIsPromptedForRemove] = useState(false);
  const isCalling = isRemoving || isDownloading;

  const onRemoveAll = async (skipPrompt = false) => {
    if (
      isCalling ||
      (!skipPrompt && promptForVerify(() => onRemoveAll(true)))
    ) {
      return;
    }

    setIsRemoving(true);
    await Promise.allSettled(
      selected.map((fileUuid) =>
        thingsApi.removeThingFile({
          thingUuid: thing.uuid,
          fileUuid,
        }),
      ),
    );
    setIsRemoving(false);

    setIsPromptedForRemove(false);
    onClose();
  };

  const onPromptForRemove = () => {
    setIsPromptedForRemove(true);
  };

  const onDownloadAll = async () => {
    if (isCalling) {
      return;
    }
    setIsDownloading(true);
    await Promise.allSettled(
      files.map((file) => downloadFileUrl(file.publicUri, file.filename)),
    );
    setIsDownloading(false);

    onClose();
  };
  // ---------------------------------------------------------------------------
  // Table Data

  // TODO: wrap in useMemo
  const columns = [
    {
      objGetter: (file) => file.uuid,
      field: 'id',
      headerName: 'UUID',
      hide: true,
    },
    {
      objGetter: (file) => file.filename,
      field: 'filename',
      headerName: 'Name',
      flex: 1,
    },
  ];

  // TODO: remove in favor of col-def field + valueGetter
  const rows = files.map((file) => {
    const row = {};
    columns.forEach((col) => {
      row[col.field] = col.objGetter(file);
    });
    return row;
  });

  return (
    <div
      className="padding flex-1 flex-col"
      style={{ minHeight: '0', padding: '1rem' }}
    >
      <div
        style={{
          border: 'var(--border)',
          // borderBottom: 'none',
          boxSizing: 'content-box',
          height: files.length * remToPx(3) + remToPx(2.5), // Handle table size limit
        }}
      >
        <DustDataGrid altTheme columns={columns} hideFooter rows={rows} />
      </div>
      <p className="mt-1 text-right">{`${files.length} Selected Files`}</p>
      <div className="flex-row gap-1 mt-1">
        {/* Slightly reduce lateral padding to prevent spinner overflow */}
        <DustLoadingButton
          color="error"
          loading={isRemoving}
          onClick={() => onPromptForRemove()}
          sx={{ padding: '6px 14px' }}
        >
          Remove All
        </DustLoadingButton>
        <DustLoadingButton
          loading={isDownloading}
          onClick={onDownloadAll}
          sx={{ padding: '6px 14px' }}
        >
          Download All
        </DustLoadingButton>
      </div>
      <DustModal
        footerProps={{
          showCancel: true,
          onCancel: () => setIsPromptedForRemove(false),
          onSubmit: () => onRemoveAll(),
          submitLabel: 'Remove',
          submitColor: 'error',
          loading: !!isRemoving,
        }}
        onClose={() => setIsPromptedForRemove(false)}
        open={isPromptedForRemove}
        title="Remove File(s)"
      >
        <p>Are you sure you want to remove these file(s): </p>
        <ul className="mt-2">
          {files.map((file) => (
            <li key={file.uuid}>
              <strong>{file.filename}</strong>
            </li>
          ))}
        </ul>
      </DustModal>
    </div>
  );
}

MultiFileSelect.propTypes = {
  thing: PropTypes.object,
  onClose: PropTypes.func,
  data: PropTypes.object,
};

export default MultiFileSelect;
