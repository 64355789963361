import { transactionModel } from '@/common/entities/transactions/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import { MESSAGES } from '@/services/requests/constants';
import { DustSingleResponse } from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIError,
  formatAPIReturn,
  formatPagedApiReturn,
} from '@/services/utility';

/** Factory function for producing the transactions API */
export default class TransactionsApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Search all transactions  */
  async search(
    params: {
      catalogUuids?: string[];
      facilityUuids?: string[];
      page: number;
      perPage?: number;
      sort?: string;
      startDate?: string;
      endDate?: string;
      transactionTypes?: string[];
      userUuids?: string[];
      thingUuids?: string[];
    } = { page: 1 },
  ) {
    return this.apiService
      .request({
        url: 'api/transactions/search',
        method: 'POST',
        data: removeNil({ sort: '-createdAt', ...params }),
      })
      .then((res) => formatPagedApiReturn(res, params.page, transactionModel))
      .catch((err) => formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED));
  }

  /** Export transactions */
  async export(
    params: {
      catalogUuids?: string[];
      facilityUuids?: string[];
      sort?: string;
      startDate?: string;
      endDate?: string;
      transactionTypes?: string[];
      userUuids?: string[];
      thingUuids?: string[];
      transactionUuids?: string[];
    } = {},
  ) {
    return this.apiService
      .request({
        url: 'api/transactions/export',
        method: 'POST',
        data: removeNil({ sort: '-createdAt', ...params }),
      })
      .then((res: DustSingleResponse) => {
        const file = new Blob([res.data.data as string], {
          type: 'text/csv;charset=utf-8;',
        });

        // Mock standard payload format
        return formatAPIReturn<{ file: File }>({
          ...res,
          data: {
            data: { file },
            meta: null,
          },
        });
      })
      .catch((err) => {
        const errResult = formatAPIError<{ message: string }>(err);
        if (errResult.data?.message) {
          this.addToast(errResult.data.message, 'error');
        }
        return errResult;
      });
  }

  /** Get one transaction detail */
  async getTransaction({ transactionUuid }: { transactionUuid: string }) {
    return this.apiService
      .request({
        url: `api/transactions/${transactionUuid}`,
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, transactionModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err, MESSAGES.COMMON.FETCH_FAILED);
      });
  }

  /** Add an annotation to a transaction entity */
  async addAnnotation({
    transactionUuid,
    annotation,
  }: {
    transactionUuid: string;
    annotation: string;
  }) {
    return this.apiService
      .request({
        url: `api/transactions/${transactionUuid}/annotate`,
        method: 'POST',
        data: {
          annotation,
        },
      })
      .then((res) => {
        this.invalidate.transactions();
        return formatAPIReturn(res);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.TRANSACTION_ANNOTATE_FAILED, 'error');
        }),
      );
  }
}
