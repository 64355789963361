import { useState } from 'react';

import { MenuItem, Button } from '@mui/material';
import PropTypes from 'prop-types';

import DustSelect from '@/components/Library/DustSelect';

function TitleColumnSelect({ headers, onSelect }) {
  const [selected, setSelected] = useState('');
  const [error, setError] = useState(false);

  const handleSelect = (evt) => {
    setError(false);
    setSelected(evt.target.value);
  };

  const submitSelect = () => {
    if (!selected) {
      setError(true);
    } else {
      onSelect(selected);
    }
  };
  return (
    <div style={{ marginBottom: '.5rem' }}>
      <p className="error" style={{ marginBottom: '1.5rem' }}>
        *The upload is missing a &ldquo;Title&rdquo; column. Please select the
        column that best represents the Thing title.
      </p>
      <DustSelect
        error={error}
        helperText="Required"
        label="Select Title Column"
        onChange={handleSelect}
        value={selected}
      >
        {headers
          .filter((h) => h !== 'rowUID')
          .map((h) => (
            <MenuItem key={h} value={h}>
              {h}
            </MenuItem>
          ))}
      </DustSelect>
      <Button
        onClick={submitSelect}
        sx={{ marginTop: '1rem' }}
        variant="contained"
      >
        Set Title Field
      </Button>
    </div>
  );
}

TitleColumnSelect.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
};
export default TitleColumnSelect;
