import { useRecoilState } from 'recoil';

import { verifiedThingsAtom } from '@/state/atoms/verifiedSession';

const EXPIRYTIME = 15 * 60 * 1000; // 15 minutes

type UseVerifiedSession = {
  verifiedThings: Record<string, number>;
  onThingVerify: (thingUuid: string, expiryMs?: number) => void;
  /**
   * Removed expired things from verified list
   */
  expireVerifiedThing: (thingUuid: string) => void;
  /** Check if you are on a thing page */
  isPageVerified: () => boolean;
  pageVerifiedTime: number;
};

/**
 * Track the verified sessions for scanned Things
 */
export default function useVerifiedSession(): UseVerifiedSession {
  const [verifiedThings, setVerifiedThings] =
    useRecoilState(verifiedThingsAtom);

  /**
   * Track the things verification status with a set expiry (in milliseconds)
   */
  const onThingVerify = (
    thingUuid: string,
    expiryMs = Date.now() + EXPIRYTIME,
  ) => {
    setVerifiedThings((oldThings) => ({
      ...oldThings,
      [thingUuid]: expiryMs,
    }));
  };

  const expireVerifiedThing = (thingUuid: string) => {
    setVerifiedThings((prev) => {
      const { [thingUuid]: removedId, ...rest } = prev;
      return rest;
    });
  };

  const isPageForThingUuid = (thingUuid: string) =>
    window.location.pathname.includes(thingUuid);

  const isPageVerified = () =>
    Object.keys(verifiedThings).some(isPageForThingUuid);

  const pageVerifiedTime = isPageVerified()
    ? (Object.entries(verifiedThings).find(([thingUuid]) =>
        isPageForThingUuid(thingUuid),
      )?.[1] ?? 0) - EXPIRYTIME
    : 0;

  return {
    verifiedThings,
    onThingVerify,
    expireVerifiedThing,
    isPageVerified,
    pageVerifiedTime,
  };
}
