import React from 'react';

import { CaptureResponse } from '@dustid/dice-sdk';
import { Dialog, DialogContent } from '@mui/material';

import Scanner from '../Scanner/Scanner';

type AntiTamperScanModalProps = {
  catalogUuid: string;
  onClose: () => void;
  onError: () => void;
  onSuccess: (scan: string) => void;
  open: boolean;
  thingUuid?: string;
};

export default function AntiTamperScanModal({
  catalogUuid,
  onClose,
  onSuccess,
  onError,
  open,
  thingUuid,
}: AntiTamperScanModalProps) {
  const handleClose = () => {
    onClose();
  };

  const handleCaptureResponse = (result: CaptureResponse) => {
    if (result.scan) {
      onSuccess(result.scan);
    } else {
      onError();
    }
    onClose();
  };

  return (
    <Dialog
      aria-labelledby="scan-dialog-title"
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogContent
        sx={{
          padding: 0,
          height: 518,
        }}
      >
        <Scanner
          catalogUuid={catalogUuid}
          mode="ANTITAMPER"
          onScan={handleCaptureResponse}
          thingUuid={thingUuid}
        />
      </DialogContent>
    </Dialog>
  );
}
