import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Chip } from '@mui/material';

type TableStatusProps = {
  conflictsFound: boolean;
  unresolvedConflicts: boolean;
};

const STATUS = {
  NO_CONFLICTS: {
    label: 'No conflicts found',
    color: 'success',
  },
  UNRESOLVED_CONFLICTS: {
    label: 'Unresolved conflicts',
    color: 'error',
  },
  CONFLICTS_RESOLVED: {
    label: 'Conflicts resolved',
    color: 'success',
  },
};

export default function TableStatus({
  conflictsFound,
  unresolvedConflicts,
}: TableStatusProps) {
  const status =
    (unresolvedConflicts && STATUS.UNRESOLVED_CONFLICTS) ||
    (conflictsFound && STATUS.CONFLICTS_RESOLVED) ||
    STATUS.NO_CONFLICTS;
  return (
    <Chip
      variant="outlined"
      color={status.color as 'success' | 'error'}
      icon={<InfoOutlined />}
      label={status.label}
      sx={{ border: 'none' }}
    />
  );
}
