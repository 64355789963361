import { useState, useEffect, useRef } from 'react';

import { nanoid } from 'nanoid';
import { createPortal } from 'react-dom';
import { useRecoilState, atom } from 'recoil';

import DustPageSidebar, {
  DustPageSidebarProps,
} from './Library/DustPageSidebar';

const openIdAtom = atom({
  key: 'sidebar-open-id',
  default: [] as string[],
});

type Props = DustPageSidebarProps & {
  /**
   * Options to close the sidebar whenever another is opened. Alternative is stacking where
   * when the top bar is closed the other sidebars remain
   */
  forceUnique?: boolean;
};

export default function AppSidebar(props: Props) {
  const { open, onClose, setOpen, forceUnique } = props;

  const sidebarId = useRef(nanoid());

  const [openId, setOpenId] = useRecoilState(openIdAtom);

  const [el, setEl] = useState(document.getElementById('body-content'));

  useEffect(() => {
    setEl(document.getElementById('body-content'));
  }, []);

  useEffect(() => {
    if (open) {
      setOpenId((prev) => [sidebarId.current, ...prev]);
    } else {
      setOpenId((prev) => prev.filter((id) => id !== sidebarId.current));
    }
  }, [open, setOpenId]);

  // Close all other sidebars when a new one has been opened if we force no multiples
  // Can be disabled for performance on pages with 1 sidebar
  useEffect(() => {
    if (openId[0] !== sidebarId.current && forceUnique) {
      setOpen(false);
      onClose?.();
    }
  }, [openId, forceUnique, setOpen, onClose]);

  return (
    el &&
    createPortal(
      // eslint-disable-next-line react/jsx-props-no-spreading
      <DustPageSidebar width={props.width ?? '22rem'} {...props} />,
      el,
    )
  );
}
