import { useMemo } from 'react';

import { GridColDef, GridEventListener } from '@mui/x-data-grid';

import { formatDateForDisplay } from '@/common/dates';
import { timeSort, alphaSort } from '@/common/utilities/sort';
import DustDataGrid from '@/components/Library/DustDataGrid';

import UserStatusDropdown from './UserStatusDropdown';

type Props = {
  users: User[];
  onRowClick: GridEventListener<'rowClick'>;
};

const commonLayout = { flex: 1, minWidth: 130 };

export default function UsersTable({ users, onRowClick = () => null }: Props) {
  const columns: GridColDef<User>[] = useMemo(
    () => [
      {
        valueGetter: ({ row }) => row.uuid,
        field: 'id',
        headerName: 'id',
        hide: true,
      },
      {
        valueGetter: ({ row }) => `${row.firstName} ${row.lastName}`,
        field: 'name',
        headerName: 'Name',
        ...commonLayout,
      },
      {
        field: 'email',
        headerName: 'Email',
        ...commonLayout,
      },
      {
        valueFormatter: ({ value }) =>
          value ? formatDateForDisplay(value) : '-',
        sortComparator: (a, b) => timeSort(a, b),
        field: 'createdAt',
        headerName: 'Created at',
        sortingOrder: ['desc', 'asc'],
        ...commonLayout,
      },
      {
        valueFormatter: ({ value }) =>
          value ? formatDateForDisplay(value) : '-',
        sortComparator: (a, b) => timeSort(a, b),
        field: 'updatedAt',
        headerName: 'Modified at',
        sortingOrder: ['desc', 'asc'],
        ...commonLayout,
      },
      {
        valueGetter: ({ row }) => row,
        sortComparator: (a, b) => alphaSort(a, b, 'userAccountStatus'),
        renderCell: ({ value }) => <UserStatusDropdown user={value} />,
        cellClassName: 'no-cell-select',
        field: 'status',
        headerName: 'Set Status',
        ...commonLayout,
      },
    ],
    [],
  );

  return (
    <DustDataGrid
      altTheme
      columns={columns}
      initialPageSize={5}
      getRowId={(u) => u.sub}
      onRowClick={onRowClick}
      rows={users}
    />
  );
}
