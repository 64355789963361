import { ACCEPTED_MIME_TYPES } from '@/components/Library/constants';

export const ACTIONS = Object.freeze({
  SCAN: 'scan',
  SEARCH: 'search',
  WORKFLOW: 'workflow',
  THING_DETAIL: 'thing-detail',
});

export const ACCEPTED_IMAGE_TYPE_LIST = Object.freeze(
  ACCEPTED_MIME_TYPES.image.split(',').map((type) => type.trim()),
);
export const ACCEPTED_FILE_TYPE_LIST = Object.freeze(
  ACCEPTED_MIME_TYPES.file.split(',').map((type) => type.trim()),
);

// Restricted metadata fields that should not be shown in editing
export const RESTRICTED_THING_FIELDS = Object.freeze({
  TITLE: 'title',
  DICE_TITLE: 'DICE Title',
  DICE_QTY: 'DICE Quantity',
  DICE_PRIMARY_IMAGE: 'DICE Primary Image',
});

export const RESTRICTED_COLUMNS = Object.freeze([
  'thumbnail',
  'title',
  'dusted',
  'id',
  'thingType',
  'catalog',
]);

export const BASIC_COLUMNS = Object.freeze(['createdAt', 'images', 'files']);
export const DEFAULT_COLUMNS = Object.freeze([
  ...RESTRICTED_COLUMNS,
  ...BASIC_COLUMNS,
]);
