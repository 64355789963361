import * as yup from 'yup';

import { includesWordMatch, normalizeString } from '@/common/utility';

import { RESTRICTED_THING_FIELDS } from '../things/constants';

const fieldTypeSchema = yup
  .object({
    name: yup
      .string()
      .trim()
      .max(255, 'Must be 255 characters or less.')
      .test(
        'Restricted Names',
        'Field Name cannot be a restricted value',
        (value) =>
          !includesWordMatch(
            Object.values(RESTRICTED_THING_FIELDS),
            value ?? '',
          ),
      )
      .required('Field Name is required'),
    required: yup.boolean(),
    schema: yup.object().required('DEBUG: Schema must be specified'),
    uuid: yup.string().required('DEBUG: Field must have a uuid'),
    value: yup.mixed(),
  })
  .test('is unique', 'Field Name must be unique', (value, context) => {
    const normalizedName = normalizeString(value.name);
    const names = (context.parent as ThingType['fieldTypes']).map((field) =>
      normalizeString(field.name),
    );
    const numInstances = names.reduce(
      (total, current) => (current === normalizedName ? total + 1 : total),
      0,
    );
    return !(numInstances > 1 && value.name);
  });

export const addThingTypeSchema = yup.object({
  name: yup
    .string()
    .trim()
    .max(255, 'Must be 255 characters or less.')
    .required('Required'),
  fieldTypes: yup.array(fieldTypeSchema),
  files: yup.mixed(),
});
