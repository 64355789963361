import * as yup from 'yup';

export const addGroupSchema = yup.object({
  name: yup.string().required('Required'),
  description: yup.string().min(0).default(''),
  roles: yup
    .array(yup.string())
    .min(1, 'At least one role must be selected')
    .required('Required'),
});
