import React from 'react';

import { GetNextPageParamFunction } from 'react-query';

import { clone } from '@/components/Library/utility';
import { ApiPagedReturn, ApiReturnError } from '@/services/utility';

import { isWordMatch, includesWordMatch, remToPx } from '../utility';

/**
 * Change a pre-existing column into the title column
 */
export function renameMoveColumn<T>(
  currentName: string,
  newName: string,
  moveIdx: number,
  headers: string[],
  data: T[][],
): { data: T[][]; headers: string[] } {
  const colIdx = headers.findIndex((h) => isWordMatch(h, currentName));
  if (colIdx < 0) {
    return { data: clone(data), headers: clone(headers) };
  }

  const newHeaders = clone(headers);

  // Remove the data and reinsert just after UID
  newHeaders.splice(colIdx, 1); // Remove the current header name
  newHeaders.splice(moveIdx, 0, newName); // Insert the new header name

  const newData = data.map((row) => {
    const newRow = clone(row);
    const colData = newRow.splice(colIdx, 1); // Remove and save the current column
    newRow.splice(moveIdx, 0, colData[0]); // Re-insert the column

    return newRow;
  });

  return {
    data: newData,
    headers: newHeaders,
  };
}

/**
 * For new fields we must append a numeric suffix to prevent duplicate names
 */
export function getNextFieldName(
  headers: string[],
  fieldName = 'Field Name',
): string {
  const idx = headers.findIndex((f) => isWordMatch(f, fieldName));

  const segments = fieldName.split(' ');
  const endNumeral = parseInt(segments[segments.length - 1], 10);
  const hasNumeral = !Number.isNaN(endNumeral);
  const contentEnd = hasNumeral ? -1 : segments.length;
  const nameContent = segments
    .slice(0, contentEnd)
    .reduce((name, segment) => `${name + segment} `, '')
    .trim();

  const nextName = `${nameContent} ${(endNumeral || 0) + 1}`;
  return idx < 0 ? fieldName : getNextFieldName(headers, nextName);
}

/**
 * For each field in a header row suffix duplicates sequentially
 */
export function suffixDuplicateFields(headers: string[]): string[] {
  return headers.reduce<string[]>((uniqueHeaders, f) => {
    if (includesWordMatch(uniqueHeaders, f)) {
      uniqueHeaders.push(getNextFieldName(uniqueHeaders, f));
    } else {
      uniqueHeaders.push(f);
    }
    return uniqueHeaders;
  }, []);
}

/**
 * Immutably adds a column with provided emtpy value
 */
export function addColumn(
  data: (string | number)[][],
  headers: string[],
  emptyVal: string,
  name: string,
  insertIdx = 0,
): {
  data: (string | number)[][];
  headers: string[];
} {
  const headerName = getNextFieldName(headers, name);

  const newHeaders = [
    ...headers.slice(0, insertIdx),
    headerName,
    ...headers.slice(insertIdx),
  ];
  const newData = clone(data).map((row) => [
    ...row.slice(0, insertIdx),
    emptyVal,
    ...row.slice(insertIdx),
  ]);

  return {
    data: newData,
    headers: newHeaders,
  };
}

/**
 * Remove select columns by field name
 */
export function removeColumns(
  data: (string | number)[][],
  headers: string[],
  removeFields: string[],
): { data: (string | number)[][]; headers: string[] } {
  const removeIdxs = removeFields.reduce<number[]>((idxs, removeField) => {
    idxs.push(headers.findIndex((f) => f === removeField));
    return idxs;
  }, []);

  const newHeaders = headers.filter((_, idx) => !removeIdxs.includes(idx));
  const newData = data.map((row) =>
    row.filter((_, idx) => !removeIdxs.includes(idx)),
  );

  return {
    data: newData,
    headers: newHeaders,
  };
}

export function quantityToRows(
  data: (string | number)[][],
  /** index of row element representing QTY */
  qtyIdx: number,
  /** column indices to remove */
  removeCols: number[],
): (string | number)[][] {
  const tableData: (string | number)[][] = [];
  const removeIdxs = qtyIdx >= 0 ? [...removeCols, qtyIdx] : [...removeCols];
  data.forEach((row) => {
    const parsedNum = parseInt(row[qtyIdx]?.toString(), 10);
    const qty = row[qtyIdx] === '' || Number.isNaN(parsedNum) ? 1 : parsedNum;

    // Exclude any fields in a column flagged for removal
    const newRow = row.filter((_, fieldIdx) => !removeIdxs.includes(fieldIdx));

    for (let i = 0; i < qty; i += 1) {
      tableData.push(newRow);
    }
  });

  return tableData;
}

/**
 * When using a DustDataGrid wrapped with a div, this can be used to check whether to initially fetch more rows based on how many items were fetched and the height of the table container
 */
export function useFetchMoreToFillHeightRef(
  getNextPage: () => void,
  fetchedCount: number,
  /** Default MUI Data Grid table height */
  rowHeightEstimateRem = 3,
) {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const getNextPageRef = React.useRef(getNextPage);
  getNextPageRef.current = getNextPage;

  React.useEffect(() => {
    if (!containerRef.current) return;

    const cellHeight = remToPx(rowHeightEstimateRem);
    const needMoreThings =
      containerRef.current.offsetHeight > fetchedCount * cellHeight;

    if (needMoreThings) getNextPageRef.current();
  }, [fetchedCount, rowHeightEstimateRem]);

  return containerRef;
}

/** A common way of getting the next page in our DICE api responses */
export const pageCountGetNextPageParam: GetNextPageParamFunction<
  ApiReturnError<unknown> | ApiPagedReturn<unknown>
> = (lastPage) =>
  !lastPage.error &&
  typeof lastPage.page === 'number' &&
  typeof lastPage.pages === 'number' &&
  lastPage.page < lastPage.pages
    ? lastPage.page + 1
    : undefined;

/** Reusable convenience for supplying a getNextPage function in infinite paginated hooks */
export function useGetNextPage<T>(
  fetchNextPage: () => Promise<T>,
  isFetchingNextPage: boolean,
  hasNextPage: boolean | undefined,
): () => Promise<T> | null {
  const nextDeps = { isFetchingNextPage, hasNextPage };
  const nextDepsRef = React.useRef(nextDeps);
  nextDepsRef.current = nextDeps;
  return React.useCallback(
    () =>
      nextDepsRef.current.hasNextPage && !nextDepsRef.current.isFetchingNextPage
        ? fetchNextPage()
        : null,
    [fetchNextPage],
  );
}

/** Reusable hook to accept an optional param that allows continuous fetching
 * of all items from an infinite scrolling route */
export function useFetchAll(
  fetchAll: boolean,
  getNextPage: () => Promise<unknown> | null,
  isFetchingNextPage: boolean,
  hasNextPage: boolean | undefined,
) {
  React.useEffect(() => {
    if (fetchAll && hasNextPage && !isFetchingNextPage) void getNextPage();
    // NOTE: `isFetchingNextPage` is needed because we need to recheck
    // hasNextPage only after we finished fetching
  }, [fetchAll, isFetchingNextPage, hasNextPage, getNextPage]);
}
