import { ReactNode, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SxProps,
  AccordionProps,
} from '@mui/material';

import DustLoader from '@/components/Library/DustLoader';

type DustAccordionEntry = {
  name: string;
  summary: ReactNode;
  content: ReactNode;
};

type Props = {
  items: DustAccordionEntry[];
  /** Mui Accordion Summary style */
  summarySx?: SxProps;
  /** Mui Accordion Summary Content style */
  summaryContentSx?: React.CSSProperties;
  /** Mui sx prop for each accordion item */
  itemSx?: SxProps;
  /** Mui Accordion Props */
  accordionProps?: Omit<AccordionProps, 'children'>;
  /** Whether to allow only a single panel open at a time */
  singleExpanded?: boolean;
  loading?: boolean;
};

export default function DustAccordion({
  items,
  summarySx = {},
  summaryContentSx = {},
  itemSx = {},
  accordionProps = {},
  singleExpanded = false,
  loading = false,
}: Props) {
  const [expandedItems, setExpandedItems] = useState<{ [x: string]: boolean }>(
    {},
  );

  const accordionStyle = {
    boxShadow: 'none',
    borderBottom: '1px solid var(--light-grey)',
  };

  const headerStyle: SxProps = {
    ...summarySx,
    '& .MuiAccordionSummary-content': {
      ...summaryContentSx,
      minWidth: 0,
    },
  };

  const contentStyle = {
    ...itemSx,
    minWidth: 0,
  };

  // Option to restrict only 1 item open
  const handleSelect = (item: string) => {
    if (singleExpanded) {
      setExpandedItems((prev) => ({
        [item]: !prev[item],
      }));
    } else {
      setExpandedItems((prev) => ({
        ...prev,
        [item]: !prev[item],
      }));
    }
  };

  return loading ? (
    <DustLoader size="large" />
  ) : (
    <>
      {items.map((item) => (
        <Accordion
          expanded={!!expandedItems[item.name]}
          key={item.name}
          onClick={() => handleSelect(item.name)}
          sx={accordionStyle}
          disableGutters
          square
          {...accordionProps}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={headerStyle}>
            {item.summary}
          </AccordionSummary>
          <AccordionDetails sx={contentStyle}>{item.content}</AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
