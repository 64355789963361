import { useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import {
  ResolutionObject,
  RESOLUTIONS,
} from '@/common/entities/exportBatches/typedefs';
import { getTreeDataPath } from '@/common/entities/things/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import { idThingColDef } from '@/components/Library/sharedThingColDefs';
import { combineClass } from '@/components/Library/utility';

import styles from './CheckIn.module.css';
import Loading from './Loading';
import ResolutionStatus from './ResolutionStatus';
import TableStatus from './TableStatus';
import { RESOLUTION_MAP, getCatalogName } from './utility';

type FinishStepProps = {
  onSuccess: () => void;
  exportBatch: ExportBatch | null;
  resolutions: ResolutionObject[];
  isLoading: boolean;
  onPrevious: () => void;
};

export default function FinishStep({
  onSuccess,
  exportBatch,
  resolutions,
  isLoading,
  onPrevious,
}: FinishStepProps) {
  const things = useMemo(
    () => exportBatch?.importValidation?.things ?? [],
    [exportBatch],
  );

  // -------------------------------------------------------------------------------
  const columns = useMemo<GridColDef<Thing>[]>(
    () => [
      idThingColDef,
      {
        field: 'catalog',
        valueGetter: ({ row }) => getCatalogName(exportBatch, row.catalogUuid),
        headerName: 'Catalog Name',
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'uploadStatus',
        renderCell: ({ row }) => {
          const res = resolutions.filter((r) => r.thingUuid === row.uuid);
          if (res.length === 0) {
            return '';
          }
          if (res.length > 1) {
            const unresolved = res.some(
              (r) => r.resolution === RESOLUTIONS.NO_RESOLUTION,
            );
            // TODO: Some sort of satanic incantation to make this better
            return (
              <ResolutionStatus
                status={unresolved ? 'error' : 'success'}
                label={
                  unresolved ? 'Unresolved conflicts' : 'All conflicts resolved'
                }
              />
            );
          }
          const resolution = res[0];
          return (
            <ResolutionStatus
              status={
                resolution.resolution === RESOLUTIONS.NO_RESOLUTION
                  ? 'error'
                  : 'success'
              }
              label={
                RESOLUTION_MAP[resolution.conflict][resolution.resolution].label
              }
            />
          );
        },
        headerName: 'Upload Status',
        minWidth: 260,
      },
    ],
    [resolutions, exportBatch],
  );

  const conflictsFound = useMemo(
    () => Object.keys(resolutions).length > 0,
    [resolutions],
  );
  const unresolvedConflicts = useMemo(
    () =>
      Object.values(resolutions).some(
        (res) => res.resolution === RESOLUTIONS.NO_RESOLUTION,
      ),
    [resolutions],
  );

  if (!things || !exportBatch) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.finishStep}>
        <h2 className="h2">{exportBatch.name}</h2>
        <div className={styles.infoRow}>
          <h5 className="h5">Import Summary</h5>
          <span
            className={combineClass(
              styles.conflictsStatus,
              conflictsFound ? styles.conflictsFound : styles.noConflicts,
            )}
          >
            <TableStatus
              conflictsFound={conflictsFound}
              unresolvedConflicts={unresolvedConflicts}
            />
          </span>
          <span className="text-sm">
            Catalogs in Import:{' '}
            <strong>{exportBatch.importValidation.catalogs.length}</strong>
          </span>
        </div>
        <DustDataGrid
          altTheme
          columns={columns}
          getRowId={(r) => r.uuid}
          rows={things}
          rowCount={things.length}
          loading={isLoading}
          treeData
          getTreeDataPath={(thing) => getTreeDataPath(thing, things)}
          groupingColDef={{
            headerName: 'Title',
            valueGetter: ({ row }) => row.title,
            hideDescendantCount: true,
          }}
        />
      </div>
      <DustStepperFooter
        onCancel={onPrevious}
        onSubmit={onSuccess}
        cancelLabel="Previous"
        submitLabel="Finish"
        disabled={unresolvedConflicts}
      />
    </>
  );
}
