import { useCallback, useState } from 'react';

import { Collapse, TextField } from '@mui/material';
import { useFormikContext } from 'formik';

import useCheckout, { CHECKOUT_STEPS } from '@/common/hooks/useCheckout';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustStepperFooter from '@/components/Library/DustStepperFooter';

import CheckoutItemList from './CheckoutItemList';
import RedirectWarning from './RedirectWarning';
import styles from './ShoppingCart.module.css';

type FormValues = {
  name: string;
  description: string;
};

type ConfirmBodyProps = {
  things: ShoppingCartThings;
};

export default function ConfirmBody({ things }: ConfirmBodyProps) {
  const { shoppingCart, cartPanel, exportBatch } = useCheckout();
  const [termsChecked, setTermsChecked] = useState(false);

  const formik = useFormikContext<FormValues>();

  const handleRedirectTimeout = useCallback(() => {
    if (!exportBatch && !formik.isSubmitting) {
      formik.setFieldValue('confirmation', false);
      cartPanel.setStep(CHECKOUT_STEPS.CART);
    }
  }, [cartPanel, exportBatch, formik]);

  return (
    <form
      className="min-h-0 flex-1 flex-col"
      id="checkout-items"
      onSubmit={formik.handleSubmit}
    >
      <div style={{ flexGrow: 1, overflowY: 'auto' }}>
        <div style={{ padding: '1rem' }}>
          <p className="text-sm mb-1">
            Please provide a name (and optionally a Description) for this
            download. Be sure to review and confirm the items in the Export
            Summary.
          </p>
          <TextField
            error={!!formik.errors.name}
            helperText={formik.errors.name || ' '}
            inputProps={{ maxLength: 255 }}
            label="Name"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            sx={{ width: '100%', marginBottom: '0.5rem' }}
            value={formik.values.name}
          />
          <TextField
            error={!!formik.errors.description}
            helperText={formik.errors.description}
            inputProps={{ maxLength: 255 }}
            label="Description"
            name="description"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            rows={3}
            size="small"
            sx={{ width: '100%' }}
            value={formik.values.description}
            multiline
          />
        </div>
        <div className="divider-0 mb-1" />
        <div className={styles.exportSummary}>
          <h4 className="h4">Export Summary</h4>
          Root Things:{' '}
          <span className={styles.summaryValue}>
            {shoppingCart.numberOfRootThings}
          </span>
          Catalogs:{' '}
          <span className={styles.summaryValue}>{things.numberOfCatalogs}</span>
        </div>
        <CheckoutItemList
          isReview
          things={things}
          shoppingCart={shoppingCart}
        />
      </div>
      <div>
        <DustCheckbox
          label={
            <div className="text-xs" style={{ marginLeft: '0.5rem' }}>
              I understand that Things will be read-only and checked out in this
              Org once complete
            </div>
          }
          // TODO: checked={termsChecked}
          onChange={setTermsChecked}
          style={{ margin: '1rem 0 1rem 0.25rem' }}
        />
        <Collapse in={termsChecked && !exportBatch} mountOnEnter unmountOnExit>
          <RedirectWarning onTimeout={handleRedirectTimeout} />
        </Collapse>
        <DustStepperFooter
          disabled={!formik.isValid || !termsChecked}
          loading={formik.isSubmitting}
          onCancel={() => cartPanel.setStep(CHECKOUT_STEPS.CART)}
          submitFormId="checkout-items"
          submitLabel={formik.isSubmitting ? '' : 'Download'}
        />
      </div>
    </form>
  );
}
