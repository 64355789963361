import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { addFacilitySchema } from '@/common/entities/facilities/schemas';
import useFacilityData from '@/common/entities/facilities/useFacilityData';
import { ROUTES } from '@/common/routes';
import DustLoader from '@/components/Library/DustLoader';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import useRequest from '@/services/requests/useRequest';

function FacilitiesForm() {
  const { facilitiesApi } = useRequest();
  const navigate = useNavigate();
  const { facilityId } = useParams();
  if (!facilityId) throw new Error('Missing facilityId from route');
  const isUpdate = facilityId !== 'add';
  const { isLoading, isSuccess, facility } = useFacilityData({
    facilityUuid: facilityId,
    enabled: isUpdate,
  });

  const formik = useFormik({
    initialValues: {
      name: facility?.name || '',
    },
    enableReinitialize: true,
    validationSchema: addFacilitySchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (isUpdate) {
        const res = await facilitiesApi.update({
          values: {
            uuid: facilityId,
            name: values.name,
          },
          uuid: facilityId,
        });
        if (!res.error) {
          navigate(ROUTES.ADMIN_FACILITIES);
        }
      } else {
        const res = await facilitiesApi.create({
          values: { name: values.name },
        });
        if (!res.error) {
          navigate(ROUTES.ADMIN_FACILITIES);
        }
      }
    },
  });

  return (
    <div className="page-container">
      <div className="action-container" style={{ maxHeight: '20rem' }}>
        <div className="action-body">
          {isLoading && <DustLoader flex size="xlarge" />}
          {(isSuccess || !isUpdate) && (
            <form
              id="add-facility-form"
              onSubmit={formik.handleSubmit}
              style={{ maxWidth: '36.5rem' }}
            >
              <h2 className="h2" style={{ marginBottom: '2rem' }}>{`${
                isUpdate ? 'Update' : 'New'
              } Facility Information`}</h2>
              <TextField
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                id="name"
                label="Facility Name"
                name="name"
                onChange={formik.handleChange}
                size="small"
                value={formik.values.name}
                fullWidth
              />
            </form>
          )}
        </div>
        <DustStepperFooter
          onCancel={() => navigate(-1)}
          showPrevious={false}
          submitFormId="add-facility-form"
          submitLabel={isUpdate ? 'Update Facility' : 'Add New Facility'}
          type="submit"
        />
      </div>
    </div>
  );
}

export default FacilitiesForm;
