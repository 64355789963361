import { useCallback, useMemo, useState } from 'react';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Button } from '@mui/material';

import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import DeactivationModal from '@/components/Composed/DeactivationModal/DeactivationModal';
import ScanModal, {
  SCAN_MODES,
} from '@/components/Composed/ScanModal/ScanModal';
import { DiceScanMode } from '@/components/Composed/Scanner/Scanner';
import DustMenuButton from '@/components/Library/DustMenuButton';
import DustDustedIcon from '@/components/Library/Icons/DustDustedIcon';
import { Mixpanel } from '@/mPanel';

type Props = {
  thing: Thing;
  disabled?: boolean;
};

export default function ThingDustedButton({ thing, disabled = false }: Props) {
  const [scanMode, setScanMode] = useState<DiceScanMode>(SCAN_MODES.BIND);
  const [showScanModal, setScanModalOpen] = useState(false);

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const { isPageVerified } = useVerifiedSession();

  const thingMenuOptions = useMemo(
    () => [
      {
        label: (
          <div className="flex-row gap-05 items-center dust-success">
            <CheckCircleOutlinedIcon />
            {isPageVerified() ? 'Re-verify' : 'Verify'}
          </div>
        ),
        onClick: () => {
          setScanModalOpen(true);
          setScanMode(SCAN_MODES.VERIFY);
        },
      },
      {
        label: (
          <div className="flex-row gap-05 items-center error">
            <RemoveCircleOutlineOutlinedIcon />
            Deactivate DUST
          </div>
        ),
        onClick: () => setShowDeactivateModal(true),
      },
    ],
    [isPageVerified],
  );

  const handleDeactivateSuccess = () => {
    setShowDeactivateModal(false);
  };

  // Toast is handled by the thingApi
  const handleDeactivateError = () => {
    setShowDeactivateModal(false);
  };

  const handleDeactivateCancel = () => {
    setShowDeactivateModal(false);
  };

  const handleScanClose = useCallback(() => null, []);
  const handleScanComplete = useCallback(() => null, []);

  return (
    <>
      {thing.isDusted ? (
        <DustMenuButton
          color={isPageVerified() ? 'success' : undefined}
          label={isPageVerified() ? 'Verified' : 'DUSTed'}
          options={thingMenuOptions}
          disabled={disabled}
          startIcon={<DustDustedIcon />}
        />
      ) : (
        <Button
          disabled={disabled}
          color="secondary"
          onClick={() => {
            Mixpanel.track('Bind to DUST');
            setScanMode(SCAN_MODES.BIND);
            setScanModalOpen(true);
          }}
          size="small"
          startIcon={<DustDustedIcon />}
          variant="contained"
          className="text-overflow"
        >
          Bind To DUST
        </Button>
      )}
      <ScanModal
        mode={scanMode}
        onComplete={handleScanComplete}
        onClose={handleScanClose}
        open={showScanModal}
        setOpen={setScanModalOpen}
        thing={thing}
      />
      <DeactivationModal
        open={showDeactivateModal}
        onSuccess={handleDeactivateSuccess}
        onError={handleDeactivateError}
        onCancel={handleDeactivateCancel}
        thing={thing}
      />
    </>
  );
}
