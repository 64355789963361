import { useAuth } from 'react-oidc-context';

import ErrorPageLinks from '@/components/Pages/ErrorPages/ErrorPageLinks';

import style from './ErrorPages.module.css';

type Props = {
  title: string;
  errorCode?: string | number;
  message: string;
  hideLinks?: boolean;
  children?: React.ReactNode;
};

export default function ErrorBase({
  title,
  errorCode,
  message,
  hideLinks,
  children,
}: Props) {
  const auth = useAuth();
  return (
    <div className={style.wrapper}>
      {errorCode && (
        <span className={style.errorCode}>Error Code: {errorCode}</span>
      )}
      <h1 className="h1">{title}</h1>
      <span>{message}</span>
      {children}
      {!hideLinks && <ErrorPageLinks />}
      {auth.user?.profile.email && (
        <div className="mt-2 text-sm">
          You are logged in as <strong>{auth.user.profile.email}</strong>
        </div>
      )}
    </div>
  );
}
