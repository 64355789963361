import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EditIcon from '@mui/icons-material/Edit';
import NotesIcon from '@mui/icons-material/Notes';
import RemoveIcon from '@mui/icons-material/Remove';

import { formatDateForDisplay } from '@/common/dates';
import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import { TRANSACTION_TYPES } from '@/common/entities/transactions/constants';
import { ROUTES } from '@/common/routes';
import { capitalizeFirst } from '@/common/utility';
import DustLinkButton from '@/components/Library/DustLinkButton';
import DustTransactionIcon from '@/components/Library/Icons/DustTransactionIcon';

// Formatter functions for different transaction action type
// Functions can be used for multiple action types

const getActionLabel = (action) =>
  action.new_label ?? action.label ?? action.key;

const actionIconStyle = (color) => ({
  borderRadius: '50%',
  padding: '4px',
  border: 'none',
  color: 'white',
  backgroundColor: color,
});

const fieldAdded = (action, { transaction }) => {
  let actionValue = action.value;
  if (
    action.label === RESTRICTED_THING_FIELDS.DICE_TITLE &&
    action.label !== transaction.thingTitle
  ) {
    actionValue = 'a computed value from type';
  }
  return {
    key: `add-${action.key}-${actionValue}`,
    title: `${transaction.createdBy} added a data field`,
    body: actionValue ? (
      <>
        Field <em>{getActionLabel(action)}</em> set to <em>{actionValue}</em>
      </>
    ) : (
      <>
        Field <em>{getActionLabel(action)}</em> initialized with empty value
      </>
    ),
    date: null,
    icon: <AddIcon sx={actionIconStyle('var(--success-green)')} />,
  };
};

const fieldDeleted = (action, { transaction }) => ({
  key: `delete-${action.key}-${action.value}`,
  title: `${transaction.createdBy} deleted a data field`,
  body: (
    <>
      Field <em>{getActionLabel(action)}</em> removed
    </>
  ),
  date: null,
  icon: <RemoveIcon sx={actionIconStyle('var(--error-red)')} />,
});

const fieldModified = (action, { transaction }) => {
  const fieldValueChanged =
    action.new_value !== action.old_value || action.new !== action.previous;
  const fieldNameChanged = action.new_label !== action.old_label;
  return {
    key: `modify-${action.key}-${action.value}`,
    title: `${transaction.createdBy} modified a data field`,
    body: (
      <>
        {fieldValueChanged && (
          <>
            Field <em>{getActionLabel(action)}</em> set to <em>{action.new}</em>{' '}
            from <em>{action.previous}</em>
          </>
        )}
        {fieldValueChanged && fieldNameChanged && <br />}
        {fieldNameChanged && (
          <>
            Field label changed to <em>{action.new_label}</em> from{' '}
            <em>{action.old_label}</em>
          </>
        )}
      </>
    ),
    date: null,
    icon: <EditIcon sx={actionIconStyle('var(--primary-blue)')} />,
  };
};

const fileAdded = (action, { transaction }) => ({
  key: `${action.filename}-${action.hash}`,
  title: `${transaction.createdBy} added a file`,
  body: (
    <>
      File <em>{action.filename}</em> added
    </>
  ),
  date: null,
  icon: <AttachFileIcon sx={actionIconStyle('var(--success-green)')} />,
});

const fileDeleted = (action, { transaction }) => ({
  key: `${action.filename}-${action.hash}`,
  title: `${transaction.createdBy} removed a file`,
  body: (
    <>
      File <em>{action.filename}</em> removed
    </>
  ),
  date: null,
  icon: <AttachFileIcon sx={actionIconStyle('var(--error-red)')} />,
});

const fileChanged = (action, { transaction }) =>
  transaction.transactionType === TRANSACTION_TYPES.THING_FILE_ADD.value
    ? fileAdded(action, { transaction })
    : fileDeleted(action, { transaction });

const filenameOnlyLEGACY = (action, transaction) =>
  fileChanged({ filename: action }, transaction);

const actionIntent = (action /* UNUSED: transaction */) => ({
  key: `intent-action-${action}`,
  title: 'Tag Extraction',
  body: (
    <>
      Intent: <em>{capitalizeFirst(action.toLowerCase())}</em>
    </>
  ),
  date: null,
  icon: (
    <DustTransactionIcon type={action} verified={false} verifySuccess={false} />
  ),
});

const changeCustodian = (action) => ({
  key: `custodian-changed-${action}`,
  title: 'Custodian changed',
  body: `Custodian changed to ${action}`,
  date: null,
  icon: (
    <DustTransactionIcon
      type="TRANSFER_PHYSICAL_ACCESS"
      verified={false}
      verifySuccess={false}
    />
  ),
});

const actionReason = (action) => ({
  key: `reason-action-${action}`,
  title: 'Reason',
  body: action,
  date: null,
  icon: <NotesIcon sx={actionIconStyle('var(--primary-blue)')} />,
});

const userOutcome = (action) => {
  const userDeclarations = {
    PASS: 'Pass - No Tampering Evident',
    WEAR_AND_TEAR: 'Normal Wear and Tear',
    INCONCLUSIVE: 'Inconclusive',
    SUSPECTED_TAMPER: 'Suspect Tamper',
    FLAG_TAMPERED: 'Tampered',
  };
  return {
    key: 'user-outcome',
    title: 'Outcome',
    body: userDeclarations[action] ?? 'Unknown',
    date: null,
    icon: (
      <DustTransactionIcon
        type="THING_CUSTODIAN_UPDATE"
        verified={false}
        verifySuccess={false}
      />
    ),
  };
};

const antiTamperAnalysis = (action, { transaction }) => ({
  key: 'analysis-review',
  title: 'Analysis Review',
  body: (
    <DustLinkButton
      onClick={(evt) => {
        evt.stopPropagation();
        window.location.href = ROUTES.ANTI_TAMPER_REVIEW(
          transaction.thingUuid,
          transaction.uuid,
        );
      }}
    >
      Click to Review Analysis
    </DustLinkButton>
  ),
  date: null,
  icon: (
    <DustTransactionIcon
      type="ANTI_TAMPER_ANALYSIS"
      verified={false}
      verifySuccess={false}
    />
  ),
});

const annotationAdded = (action) => ({
  key: action.uuid,
  title: `${action.createdBy.name} added an annotation`,
  body: action?.annotation,
  date: formatDateForDisplay(action.createdAt, { showTime: false }),
  icon: <NotesIcon sx={actionIconStyle('var(--primary-blue)')} />,
});

const parentModified = (action, { transaction, thingsMap }) => {
  const { prev_parent_uuid: prevParentUuid } =
    transaction.transactionData.actionData;
  const wasAdded = action != null && prevParentUuid == null;
  const wasModified = action != null && action !== prevParentUuid;
  const wasRemoved = action == null && prevParentUuid != null;
  if (!wasAdded && !wasRemoved && !wasModified) {
    return null;
  }
  return {
    key: 'parent-modified',
    title: `${transaction.createdBy} ${
      (wasAdded && 'added parent') ||
      (wasModified && 'changed parent') ||
      'removed parent'
    }`,
    body: (
      <>
        {(wasModified || wasRemoved) && (
          <p style={{ paddingBottom: '1rem' }}>
            Removed from Parent{' '}
            <strong>{thingsMap[prevParentUuid]?.title ?? '...'}</strong>
          </p>
        )}
        {(wasAdded || wasModified) && (
          <p>
            Added <strong>{thingsMap[action]?.title ?? '...'}</strong> as Parent
          </p>
        )}
      </>
    ),
    icon:
      wasAdded || wasModified ? (
        <AddIcon sx={actionIconStyle('var(--success-green)')} />
      ) : (
        <RemoveIcon sx={actionIconStyle('var(--error-red)')} />
      ),
  };
};

/**
 * Renders '...' while title data is unavailable, otherwise shows
 * comma-separated uuid title array
 *
 * @param {string[]} uuids
 * @param {object}   thingsMap
 */
function conditionallyRenderChildrenList(uuids, thingsMap) {
  if (Object.keys(thingsMap).length === 0) {
    return '...';
  }
  const titles = uuids.map((id) => thingsMap[id]?.title ?? '...');
  if (titles.length === 1) return titles[0];
  return `${titles.slice(0, -1).join(', ')}, and ${titles.slice(-1)[0]}.`;
}

const childrenAdded = (action, { transaction, thingsMap }) => {
  if (action.length === 0) return null;
  const childWord = action.length === 1 ? 'child' : 'children';
  return {
    key: 'children-added',
    title: `${transaction.createdBy} added ${childWord}`,
    body: (
      <>
        Added {childWord}{' '}
        <strong>{conditionallyRenderChildrenList(action, thingsMap)}</strong>
      </>
    ),
    icon: <AddIcon sx={actionIconStyle('var(--success-green)')} />,
  };
};

const childrenRemoved = (action, { transaction, thingsMap }) => {
  if (action.length === 0) return null;
  const childWord = action.length === 1 ? 'child' : 'children';
  return {
    key: 'children-removed',
    title: `${transaction.createdBy} removed ${childWord}`,
    body: (
      <>
        Removed {childWord}{' '}
        <strong>{conditionallyRenderChildrenList(action, thingsMap)}</strong>
      </>
    ),
    icon: <RemoveIcon sx={actionIconStyle('var(--error-red)')} />,
  };
};

/**
 * Mapping for synthetic actions object created in TransactionSummary.
 * Some properties in the API return must be altered for clean rendering (such as file_data into an array)
 */
export const ACTION_FORMATTER = {
  additions: fieldAdded,
  deletions: fieldDeleted,
  modifications: fieldModified,
  typed_additions: fieldAdded,
  typed_deletions: fieldDeleted,
  typed_modifications: fieldModified,
  file_data: fileChanged,
  user_outcome: userOutcome,
  intent: actionIntent,
  custodian: changeCustodian,
  reason: actionReason,
  annotations: annotationAdded,
  next_parent_uuid: parentModified,
  children_added_uuids: childrenAdded,
  children_removed_uuids: childrenRemoved,
  anti_tamper_analysis_uuid: antiTamperAnalysis,
  // LEGACY
  filename: filenameOnlyLEGACY,
  filenames: filenameOnlyLEGACY,
};
