import { SvgIcon, SvgIconProps } from '@mui/material';

function DustDustedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        aria-hidden="true"
        className="MuiSvgIcon-root makeStyles-boundIcon-2751 MuiSvgIcon-colorPrimary"
        focusable="false"
        viewBox="0 0 211 245"
      >
        <path d="M0,61.18v8.53A197.73,197.73,0,0,0,98.59,240.47l7.39,4.26,7.38-4.26A197.72,197.72,0,0,0,211.94,69.71V61.18L106,0,0,61.18m165,112H138V144.46l26.38-15.37,13.31,22.58A185.38,185.38,0,0,1,165,173.18Zm-84.28-6.72V144.84l18.55,10.81Zm0-25.9V104.17l22.22-13,31.42,18.3v25.69l-31.42,18.3Zm53.64,6.05v25.2l-21.63-12.6ZM138,111.67l18.36,10.69L138,133.06Zm-3.69-38.75V98.13L112.7,85.53Zm-53.64,27V78.28L99.24,89.09Zm-9.85,34.93L49.45,122.37l21.39-12.46Zm-51-60.2h51v31L46.43,119.85Zm3.48-9.84L97.48,22,72.91,64.79Zm84.45-40.94L133.67,69,109,83.39,82.47,67.92ZM138,71.56h52.36l-26,44.09L138,100.28Zm58.84,8.38a181.86,181.86,0,0,1-14.13,60.87l-10.85-18.44Zm-63.19,95.75-25.92,45.19L82.47,176.81,109,161.35ZM70.84,170.1H44.9a184.4,184.4,0,0,1-12.29-21.74l13.82-23.47L70.84,139.1ZM30.73,144.29A182.17,182.17,0,0,1,14.85,74.63h.68l28.1,47.74Zm162.18-77-.36.62H137.24L109.87,20.15l.36-.64ZM110.2,225.15l-.33-.56,27.37-47.72h25.12A182.51,182.51,0,0,1,110.2,225.15ZM106,227.68,51.86,179.94h21l24.27,42.3A182.1,182.1,0,0,1,51.86,179.94Z" />
      </svg>
    </SvgIcon>
  );
}

export default DustDustedIcon;
