import PropTypes from 'prop-types';

import styles from './Components.module.css';
import DustStepper from './DustStepper';
import { combineClass } from './utility';

function DustHeaderStepper({ maxWidth = '40rem', className = '', ...props }) {
  return (
    <div className={combineClass(styles.stepperHeader, className)}>
      <DustStepper maxWidth={maxWidth} orientation="horizontal" {...props} />
    </div>
  );
}

DustHeaderStepper.propTypes = {
  maxWidth: PropTypes.string,
  className: PropTypes.string,
  props: PropTypes.any,
};
export default DustHeaderStepper;
