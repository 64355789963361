import { MouseEventHandler } from 'react';

import navLogoWhiteCollapsedSvg from '@/assets/logo/nav-logo-white-collapsed.svg';
import navLogoWhiteFullSvg from '@/assets/logo/nav-logo-white-full.svg';

import styles from './LeftNav.module.css';

type Props = {
  isFull?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export default function NavLogo({ isFull = false, onClick }: Props) {
  return (
    <button type="button" className={styles.logoButton} onClick={onClick}>
      {isFull && <img src={navLogoWhiteFullSvg} alt="" />}
      {!isFull && <img src={navLogoWhiteCollapsedSvg} alt="" />}
    </button>
  );
}
