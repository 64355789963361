import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/common/routes';

type Props = {
  thing: Thing;
  gapClassName: string;
  buttonProps?: ButtonProps;
};

export default function NoRelationsActions({
  thing,
  gapClassName = 'gap-1',
  buttonProps = {},
}: Props) {
  const navigate = useNavigate();
  const handleAddParent = () => {
    navigate(ROUTES.THING_EDIT_PARENT(thing.uuid));
  };

  const handleAddChildren = () => {
    navigate(ROUTES.THING_EDIT_CHILDREN(thing.uuid));
  };

  // Handy for override of button props from both places we use this in.
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div className={`flex-row flex-wrap justify-center ${gapClassName}`}>
      <Button variant="contained" onClick={handleAddParent} {...buttonProps}>
        Add A Parent
      </Button>
      <Button variant="contained" onClick={handleAddChildren} {...buttonProps}>
        Add Children
      </Button>
    </div>
  );
}
