import { useEffect, useMemo } from 'react';

import { GridColDef } from '@mui/x-data-grid';

import { getTreeDataPath } from '@/common/entities/things/utility';
import useToasts from '@/common/hooks/useToasts';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import {
  idThingColDef,
  titleThingColDef,
} from '@/components/Library/sharedThingColDefs';
import { MESSAGES } from '@/services/requests/constants';

import styles from './CheckIn.module.css';
import Loading from './Loading';
import ResolutionStatus from './ResolutionStatus';
import TableStatus from './TableStatus';
import { getCatalogName, getConflictMessage } from './utility';

type ReviewStepProps = {
  onSuccess: () => void;
  exportBatch: ExportBatch | null;
  isLoading: boolean;
  onCancel: () => void;
};

export default function ReviewStep({
  onSuccess,
  exportBatch,
  isLoading,
  onCancel,
}: ReviewStepProps) {
  const { addToast } = useToasts();
  const things = useMemo(
    () => exportBatch?.importValidation?.things ?? [],
    [exportBatch],
  );

  const columns = useMemo<GridColDef<Thing>[]>(
    () => [
      idThingColDef,
      titleThingColDef,
      {
        field: 'catalog',
        valueGetter: ({ row }) => getCatalogName(exportBatch, row.catalogUuid),
        headerName: 'Catalog Name',
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'uploadStatus',
        renderCell: ({ row }) => {
          const conflict =
            exportBatch?.importValidation.validationStatusesByUuid[row.uuid][0];
          return (
            conflict && (
              <ResolutionStatus
                status="error"
                label={getConflictMessage(conflict)}
              />
            )
          );
        },
        headerName: 'Upload Status',
        minWidth: 170,
      },
    ],
    [exportBatch],
  );

  const conflictsFound = useMemo(
    () =>
      Object.keys(exportBatch?.importValidation.validationStatuses ?? [])
        .length > 0,
    [exportBatch],
  );
  const catalogsCount = exportBatch?.importValidation?.catalogs.length;

  useEffect(() => {
    if (!isLoading && exportBatch && !things.length) {
      addToast(MESSAGES.BATCH_IMPORT_EMPTY, 'error');
      onCancel();
    }
  }, [addToast, onCancel, isLoading, exportBatch, things]);

  if (isLoading || !exportBatch) {
    return <Loading />;
  }

  return (
    <>
      <div className={styles.reviewStep}>
        <h2 className="h2">{exportBatch.name}</h2>
        <div className={styles.infoRow}>
          <h5 className="h5">Import Summary</h5>

          <span className={styles.conflictsStatus}>
            <TableStatus
              conflictsFound={conflictsFound}
              unresolvedConflicts={conflictsFound}
            />
          </span>
          <span className="text-sm">
            Catalogs in Import: <strong>{catalogsCount}</strong>
          </span>
        </div>
        <DustDataGrid
          altTheme
          columns={columns}
          getRowId={(r) => r.uuid}
          rows={things}
          sx={{
            '& .MuiDataGrid-cell': {
              cursor: 'default',
            },
          }}
          treeData
          getTreeDataPath={(thing) => getTreeDataPath(thing, things)}
          groupingColDef={{
            headerName: 'Title',
            valueGetter: ({ row }) => row.title,
            hideDescendantCount: true,
          }}
        />
      </div>
      <DustStepperFooter
        onCancel={onCancel}
        onSubmit={onSuccess}
        cancelLabel="Cancel"
        submitLabel={conflictsFound ? 'Manage Conflicts' : 'Check In'}
      />
    </>
  );
}
