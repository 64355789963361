import { useState } from 'react';

import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ExportBatch } from '@/common/entities/exportBatches/typedefs';
import useToasts from '@/common/hooks/useToasts';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustProgressLoader from '@/components/Library/DustProgressLoader';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import useRequest from '@/services/requests/useRequest';

import styles from './CheckIn.module.css';

type Props = {
  onSuccess: (exportBatch: ExportBatch) => Promise<void>;
  onError: () => void;
};

export default function ImportStep({ onSuccess, onError }: Props) {
  const navigate = useNavigate();
  const handleDelete = () => {};
  const [isValidating, setIsValidating] = useState(false);
  const { exportBatchesApi } = useRequest();
  const { addToast } = useToasts();

  const handleReject = (errors: string[]) => {
    errors.forEach((error) => addToast(error, 'error'));
  };

  const handleFile = async (file: File) => {
    if (!file) {
      // This condition is when an invalid file is dropped. handleReject() will handle appropriate messaging.
      return;
    }
    setIsValidating(true);
    const exportBatchResult = await exportBatchesApi.upload(file);
    if (exportBatchResult.error) {
      // Toast error should be presented by API
      setIsValidating(false);
      onError();
      return;
    }
    const exportBatch = exportBatchResult.data;
    await onSuccess(exportBatch);
    setIsValidating(false);
  };

  const handleCancel = () => navigate(-1);

  return (
    <div className={styles.importStep}>
      {isValidating ? (
        <>
          <Alert
            severity="info"
            classes={{ root: styles.alert, icon: styles.alertIcon }}
          >
            Validation in progress
          </Alert>
          <div className={styles.centeredContentWrapper}>
            <DustProgressLoader
              className={styles.checkInIcon}
              alt="Validating your import..."
            />
          </div>
        </>
      ) : (
        <div className={styles.centeredContentWrapper}>
          <DustFileUploadField
            formik={undefined}
            fileType={FILE_TYPE_OPTIONS.IMPORT}
            label="Click here to upload a Checked out Thing file"
            onDelete={handleDelete}
            onReject={handleReject}
            setFile={handleFile}
            className={styles.dropzone}
          />
        </div>
      )}
      <DustStepperFooter onCancel={handleCancel} cancelLabel="Cancel" />
    </div>
  );
}
