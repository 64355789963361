export default function () {
  return (
    <>
      <span
        style={{
          height: '1rem',
        }}
      />
      <span />
      <span />
    </>
  );
}
