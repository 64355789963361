import { useEffect, useMemo, useState } from 'react';

import { ViewQuiltOutlined } from '@mui/icons-material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { Tooltip } from '@mui/material';
import {
  createSearchParams,
  Link,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import useCatalogData from '@/common/entities/catalogs/useCatalogData';
import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import { changeTypedMetadataField } from '@/common/entities/things/models';
import useThingData from '@/common/entities/things/useThingData';
import { filterImages } from '@/common/entities/things/utility';
import useThingTypeData from '@/common/entities/thingTypes/useThingTypeData';
import useTransactionData from '@/common/entities/transactions/useTransactionData';
import useTransactionsData from '@/common/entities/transactions/useTransactionsData';
import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import { ROUTES } from '@/common/routes';
import AntiTamper from '@/components/Composed/AntiTamper/AntiTamper';
import ScanModal, {
  SCAN_MODES,
} from '@/components/Composed/ScanModal/ScanModal';
import TransactionHeading from '@/components/Composed/TransactionDetail/TransactionHeading';
import DustEditableText from '@/components/Library/DustEditableText';
import DustLoader from '@/components/Library/DustLoader';
import DustTabContainer, {
  DustTabContainerTab,
} from '@/components/Library/DustTabContainer';
import useRequest from '@/services/requests/useRequest';

import ThingDataFields from './Tabs/ThingDataFields';
import ThingFiles from './Tabs/ThingFiles';
import ThingImages from './Tabs/ThingImages/ThingImages';
import ThingTransactions from './Tabs/ThingTransactions';
import styles from './Thing.module.css';
import ThingActionButton from './ThingActionButton';
import ThingDustedButton from './ThingDustedButton';
import ThingMap from './ThingMap';
import ThingRecovery from './ThingRecovery';
import ThingRelationships from './ThingRelationships';
import { ACTIONS } from './ThingSidebar/constants';
import ThingSidebar, { SidebarContent } from './ThingSidebar/ThingSidebar';
import ThingSummaryCard from './ThingSummaryCard';
import ThingSummaryImage from './ThingSummaryImage';
import XTagSummary from './XTagSummary';

/** Primary page display for Thing */
export default function ThingPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [analysisOpened, setAnalysisOpened] = useState(false);
  const [analysisTransaction, setAnalysisTransaction] = useState<Transaction>();
  const { thingId } = useParams();
  if (!thingId) {
    throw new Error('Missing UUIDs');
  }

  const [showVerifyPrompt, setShowVerifyPrompt] = useState(false);
  const [isAntiTamperOpen, setIsAntiTamperOpen] = useState(false);

  const {
    thing,
    isLoading: isThingLoading,
    isError,
  } = useThingData({
    thingUuid: thingId,
    additionalFields: ['parent', 'children', 'root'],
  });

  const {
    catalog,
    isLoading: isCatalogLoading,
    isError: isCatalogError,
  } = useCatalogData({
    catalogUuid: thing?.catalogUuid ?? '',
    enabled: !isThingLoading && !isError && !!thing?.catalogUuid,
  });

  const thingTypeUuid = thing?.thingType?.uuid;
  const {
    thingType,
    isLoading: isLoadingType,
    isError: isThingTypeError,
  } = useThingTypeData({
    thingTypeUuid: thingTypeUuid ?? '',
    enabled: !!thingTypeUuid,
  });

  if (isError || isThingTypeError || isCatalogError) {
    throw new Error('Could not load Thing, Thing Type, or Catalog');
  }

  const isComputedTitle =
    thingType?.titleField?.value?.type === 'COMPUTED_VALUE';

  const { thingsApi } = useRequest();
  const { transactions, isLoading: isTransactionsLoading } =
    useTransactionsData({ thingUuid: thingId });

  const { transaction: antiTamperTransaction } = useTransactionData({
    transactionUuid: searchParams.get('antiTamperTransaction') ?? '',
    enabled: !!searchParams.get('antiTamperTransaction'),
  });

  const isLoading =
    isThingLoading ||
    isTransactionsLoading ||
    isLoadingType ||
    isCatalogLoading;

  const VERIFY_STEPS = {
    PROMPT: 'prompts',
    SCAN: 'scan',
    USE_UNVERIFIED: 'unverified',
  };
  const { isPageVerified } = useVerifiedSession();

  // Store the onsuccess action function in state. Requires wrapping in an anonymous function
  const [onVerifySuccess, setOnVerifySuccess] = useState<() => void>(
    () => () => null,
  );
  const [verifyStep, setVerifyStep] = useState(VERIFY_STEPS.PROMPT);

  /**  Will prompt for thing verification and either dismiss or start a scan flow */
  const promptForVerify = (onSuccess: () => void) => {
    // Only prompt is the thing is verified AND the use has not requested an unverified session
    if (
      isPageVerified() ||
      verifyStep === VERIFY_STEPS.USE_UNVERIFIED ||
      !thing?.isDusted
    ) {
      return false;
    }
    setShowVerifyPrompt(true);
    setOnVerifySuccess(() => onSuccess);
    return true;
  };

  const handleUseUnverified = () => {
    setShowVerifyPrompt(false);
    setVerifyStep(VERIFY_STEPS.USE_UNVERIFIED);
    onVerifySuccess?.();
  };

  const handleScanComplete = () => onVerifySuccess?.();

  const [isUpdatingTitle, setUpdatingTitle] = useState(false);
  const updateTitle = async (title: string, onSuccess: () => void) => {
    if (!thing) throw new Error('Expected a defined thing');
    setUpdatingTitle(true);
    let data;

    if (thing.thingType) {
      // Get the title field uuid
      const titleKey = Object.entries(thing.typedMetadata).find(
        ([_uuid, field]) => field.name === RESTRICTED_THING_FIELDS.DICE_TITLE,
      )?.[0];

      if (!titleKey) {
        throw new Error('Missing title field uuid. Is this a typed thing?');
      } else {
        data = {
          metadata: thing.metadata,
          typedMetadata: changeTypedMetadataField(
            thing.typedMetadata,
            titleKey,
            title,
          ),
        };
      }
    } else {
      data = {
        metadata: {
          ...thing.metadata,
          title,
        },
        typedMetadata: thing.typedMetadata,
      };
    }

    const res = await thingsApi.updateMetadata({
      thingUuid: thing.uuid,
      catalogUuid: thing.catalogUuid,
      ...data,
    });

    if (!res.error) {
      onSuccess();
    }
    setUpdatingTitle(false);
  };
  // ---------------------------------------------------------------------------
  // MANAGE SIDEBAR FLOWS ------------------------------------------------------

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [contentData, setContentData] = useState<SidebarContent | null>(null);

  /** Provision sidebar action */
  const setSidebarData = (
    { action, data }: DistributivePick<SidebarContent, 'action' | 'data'>,
    openSidebar = true,
    onClose: () => void = () => null,
  ) => {
    setContentData({
      action,
      data,
      onClose,
    });
    if (openSidebar) {
      setSidebarOpen(true);
    }
  };

  const clearContent = () => setContentData(null);

  const [selectedTab, setSelectedTab] = useState(
    searchParams.get('thing-selected-tab') ?? '0',
  );

  useEffect(() => {
    const params = createSearchParams(searchParams);
    params.set('thing-selected-tab', String(selectedTab));
    setSearchParams(params, { replace: true });
  }, [selectedTab, setSearchParams, searchParams]);

  useEffect(() => {
    if (antiTamperTransaction && !analysisOpened) {
      setAnalysisOpened(true);
      setAnalysisTransaction(antiTamperTransaction);
      setIsAntiTamperOpen(true);
    }
  }, [analysisOpened, antiTamperTransaction]);

  const handleTabChange = (val: string) => {
    setSelectedTab(val);
    clearContent();
  };

  const openAntiTamper = () => setIsAntiTamperOpen(true);

  const closeAntiTamper = () => {
    setAnalysisTransaction(undefined);
    setIsAntiTamperOpen(false);
  };

  // ---- Render section ----
  const isCheckedOut = useMemo(() => !!thing?.isCheckedOut, [thing]);

  if (isLoading || thing == null) {
    return (
      <div className="flex-center-fill">
        <DustLoader size="xlarge" />
      </div>
    );
  }

  const tabs: DustTabContainerTab[] = [
    {
      label: 'Summary',
      content: (
        <div className={styles.summary}>
          <div className={styles.summaryContent}>
            <ThingSummaryCard title="Primary Image">
              <ThingSummaryImage
                thing={thing}
                thingType={thingType}
                disableInteraction={isCheckedOut}
              />
            </ThingSummaryCard>
            <ThingSummaryCard title="Tags">
              <XTagSummary thing={thing} />
            </ThingSummaryCard>
            <ThingSummaryCard title="Recent Transactions">
              <div className={styles.transactionsItemContainer}>
                <TransactionHeading transaction={transactions[0]} />
                <TransactionHeading transaction={transactions[1]} />
                <TransactionHeading transaction={transactions[2]} />
              </div>
            </ThingSummaryCard>
          </div>
        </div>
      ),
    },
    {
      label: 'Data Fields',
      content: (
        <ThingDataFields
          disableInteraction={isCheckedOut}
          onAddField={() => {
            if (
              promptForVerify(() =>
                setSidebarData({
                  action: ACTIONS.ADD_FIELD,
                  data: { key: null },
                }),
              )
            ) {
              return;
            }
            setSidebarData({ action: ACTIONS.ADD_FIELD, data: { key: null } });
          }}
          onSelectField={(key: string, isTyped: boolean) => {
            if (
              promptForVerify(() =>
                setSidebarData({
                  action: ACTIONS.EDIT_FIELD,
                  data: { key, isTyped },
                }),
              )
            ) {
              return;
            }
            setSidebarData({
              action: ACTIONS.EDIT_FIELD,
              data: { key, isTyped },
            });
          }}
          thing={thing}
        />
      ),
      tabStyles: { paddingTop: '1rem' },
    },
    {
      label: `Images (${filterImages(thing.files)?.length})`,
      content: (
        <ThingImages
          disableInteraction={isCheckedOut}
          promptForVerify={promptForVerify}
          thing={thing}
          thingType={thingType ?? null}
        />
      ),
      tabStyles: { paddingTop: '0', paddingBottom: '0' },
    },
    {
      label: `Files (${thing.files?.length})`,
      content: (
        <ThingFiles
          disableInteraction={isCheckedOut}
          thing={thing}
          thingType={thingType}
          onSelectFile={(
            selected: string[],
            setSelected: React.Dispatch<React.SetStateAction<string[]>>,
          ) =>
            // Will open the sidebar if it is the first selection in a selection group
            // This allows the use to collapse the sidebar and select more before re-opening
            selected.length > 0
              ? setSidebarData(
                  {
                    action: ACTIONS.MULTI_FILE,
                    data: { selected },
                  },
                  selected.length < 2 || !contentData,
                  () => setSelected([]),
                )
              : clearContent()
          }
          promptForVerify={promptForVerify}
        />
      ),
      tabStyles: { paddingTop: '1rem' },
    },
    {
      label: 'Transactions',
      content: (
        <ThingTransactions thing={thing} disableInteraction={isCheckedOut} />
      ),
      tabStyles: { overflowY: 'hidden', padding: '0rem' },
    },
    {
      label: 'Relationships',
      content: <ThingRelationships thing={thing} />,
    },
    {
      label: 'Map',
      content: <ThingMap thing={thing} />,
      tabStyles: { padding: '0', height: '100%' },
    },
  ];

  return (
    <>
      {isCheckedOut && <ThingRecovery thing={thing} />}
      <div
        className="flex-row items-start justify-space"
        style={{ margin: '0 0 1rem 0', maxWidth: '70rem' }}
      >
        <div className="flex-col min-w-0">
          <DustEditableText
            disabled={isComputedTitle || thing.isCheckedOut}
            className="h1"
            loading={isUpdatingTitle || isThingLoading}
            onSave={(title, onSuccess) =>
              promptForVerify(() => updateTitle(title, onSuccess)) ||
              updateTitle(title, onSuccess)
            }
            tag="h1"
            textFieldProps={{
              variant: 'standard',
              sx: { width: '23rem' },
              inputProps: {
                maxLength: 255,
              },
            }}
            value={thing.title}
          />
          <div className="flex-row items-center min-w-0 gap-1 text-sm">
            {thingType && (
              <div className="flex-row items-center min-w-0 gap-05">
                <ViewQuiltOutlined />
                <Tooltip title={thingType.name}>
                  <span className="flex-1 min-w-0 text-overflow">
                    {thingType.name}
                  </span>
                </Tooltip>
              </div>
            )}
            {catalog && (
              <div className="flex-row items-center min-w-0 gap-05">
                <FolderOutlinedIcon />
                <Tooltip title={catalog.name}>
                  <Link
                    className="flex-1 min-w-0 text-overflow"
                    to={ROUTES.THINGS(catalog.uuid)}
                  >
                    {catalog.name}
                  </Link>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
        <div className="flex-row gap-1">
          <ThingDustedButton thing={thing} disabled={isCheckedOut} />
          {catalog && (
            <ThingActionButton
              thing={thing}
              catalog={catalog}
              disabled={isCheckedOut}
              onAntiTamper={openAntiTamper}
            />
          )}
        </div>
      </div>
      {!isAntiTamperOpen && (
        <>
          <div className={styles.tabSection}>
            <DustTabContainer
              onChange={handleTabChange}
              tabs={tabs}
              selectedTab={selectedTab}
            />
          </div>
          <ThingSidebar
            content={contentData}
            open={sidebarOpen}
            promptForVerify={promptForVerify}
            setContent={setContentData}
            setOpen={setSidebarOpen}
            thing={thing}
            thingType={thingType}
          />
          <ScanModal
            footerProps={
              verifyStep === VERIFY_STEPS.PROMPT
                ? {
                    submitLabel: 'Verify Thing',
                    cancelLabel: 'Use Unverified Session',
                    onSubmit: () => setVerifyStep(VERIFY_STEPS.SCAN),
                    onCancel: handleUseUnverified,
                  }
                : undefined
            }
            mode={SCAN_MODES.VERIFY}
            onClose={() => {
              setVerifyStep(VERIFY_STEPS.PROMPT);
            }}
            onComplete={handleScanComplete}
            open={showVerifyPrompt}
            setOpen={setShowVerifyPrompt}
            thing={thing}
            verifyMessage={
              verifyStep === VERIFY_STEPS.PROMPT ? (
                <div className="p-1">
                  Your session is not verified. Would you like to verify your
                  DUST tag before editing the Thing?
                </div>
              ) : undefined
            }
          />
        </>
      )}
      {isAntiTamperOpen && (
        <AntiTamper
          onComplete={closeAntiTamper}
          thing={thing}
          transaction={analysisTransaction}
        />
      )}
    </>
  );
}
