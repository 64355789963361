import { QueryClient } from 'react-query';

import { QUERY_KEYS } from './constants';

/**
 * Create cache invalidator functions. This provides a common interface to the API for
 * complex invalidation cases where multiple keys may need to be invalidated
 */
export const invalidators = (
  queryClient: Pick<QueryClient, 'invalidateQueries'>,
) => ({
  orgs() {
    void queryClient.invalidateQueries(QUERY_KEYS.ORGS);
  },
  org(orgUuid: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.ORG(orgUuid));
  },
  /**
   * Invalidate the catalog list
   */
  catalogs() {
    void queryClient.invalidateQueries(QUERY_KEYS.CATALOGS);
  },

  /**
   * Invalidate the facilities list
   */
  facilities() {
    void queryClient.invalidateQueries(QUERY_KEYS.FACILITIES);
  },

  /**
   * Invalidate all things listed in a catalog and at the account-level
   * When thing data displayed in the listing changes this should be used
   */
  things() {
    void queryClient.invalidateQueries(QUERY_KEYS.THINGS);
    void queryClient.invalidateQueries(QUERY_KEYS.TRANSACTIONS);
  },

  /**
   * Invalidate a single thing query
   */
  thing(thingUuid: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.THING(thingUuid));
    void queryClient.invalidateQueries(QUERY_KEYS.TRANSACTIONS);
  },

  activeUser() {
    void queryClient.invalidateQueries(QUERY_KEYS.USER('activeUser'));
  },

  user(userUuid: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.USER(userUuid));
  },

  users() {
    void queryClient.invalidateQueries(QUERY_KEYS.USERS);
  },

  userGroupList(sub: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.USER_GROUP_LIST(sub));
  },

  /**
   * Invalidate meta filters for a list of things in a catalog
   */
  thingMetaFilters(catalogUuid: string) {
    void queryClient.invalidateQueries(
      QUERY_KEYS.THING_META_FILTERS(catalogUuid),
    );
  },

  thingMetaFiltersAll() {
    void queryClient.invalidateQueries(QUERY_KEYS.THING_META_FILTERS_ALL);
  },

  shoppingCart() {
    void queryClient.invalidateQueries(QUERY_KEYS.SHOPPING_CART);
  },

  groups() {
    void queryClient.invalidateQueries(QUERY_KEYS.GROUPS);
  },

  group(groupUuid: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.GROUP(groupUuid));
    void queryClient.invalidateQueries(QUERY_KEYS.GROUPS_LIST);
  },

  groupRoles(groupOidcId: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.GROUP_ROLES(groupOidcId));
  },

  transactions() {
    void queryClient.invalidateQueries(QUERY_KEYS.TRANSACTIONS);
  },

  filters(groupId: string) {
    void queryClient.invalidateQueries(QUERY_KEYS.FILTERS(groupId));
  },

  thingTypes() {
    void queryClient.invalidateQueries(QUERY_KEYS.THING_TYPES);
  },

  /**
   * Meta-invalidator for when a thing is created or moved
   */
  thingMoveCreate(catalogUuid: string, oldCatalogUuid?: string) {
    this.catalogs();
    if (oldCatalogUuid) {
      this.thingMetaFilters(oldCatalogUuid);
    }
    this.thingMetaFilters(catalogUuid);
    this.things();
  },
});
