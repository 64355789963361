import { useState, useEffect } from 'react';

import { Tooltip } from '@mui/material';
import { FormikProps } from 'formik';

import useToasts from '@/common/hooks/useToasts';
import { provideFallbackImage } from '@/common/utilities/images';
import { urlFromFile } from '@/common/utility';
import componentsStyles from '@/components/Library/Components.module.css';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustImageIcon from '@/components/Library/Icons/DustImageIcon';
import { combineClass } from '@/components/Library/utility';

import styles from './ThingFileModal.module.css';

type FormikValues = object & {
  primaryImage?: File | null;
};

type Props<T extends FormikValues> = {
  formik: FormikProps<T>;
  primaryFromType?: ThingMediaFile | null;
  disabled?: boolean;
  disabledMessage?: string;
};

export default function ThingPrimaryImage<T extends FormikValues>({
  formik,
  primaryFromType = null,
  disabled = false,
  disabledMessage = '',
}: Props<T>) {
  const { addToast } = useToasts();

  // Get an image url from the file
  const [primarySrc, setPrimarySrc] = useState('');
  useEffect(() => {
    if (primaryFromType) {
      setPrimarySrc(primaryFromType.publicUri);
    } else {
      const getSrc = async () =>
        setPrimarySrc(
          formik.values.primaryImage
            ? await urlFromFile(formik.values.primaryImage)
            : '',
        );
      void getSrc();
    }
  }, [formik.values.primaryImage, primaryFromType]);

  const handleReject = (errors: string[]) => {
    errors.forEach((error) => addToast(error, 'error'));
  };

  return (
    <div className="flex-row">
      {primarySrc && (
        <img
          alt="Thing Primary"
          className={styles.primaryImage}
          src={primarySrc}
          onError={provideFallbackImage}
        />
      )}
      {primaryFromType ? (
        <Tooltip title="The Primary Image is set by the Thing Type and cannot be edited.">
          <div
            className={combineClass(
              componentsStyles.dropzone,
              componentsStyles.dropzone__single__loaded,
            )}
            style={{ flex: 1, margin: 0, minHeight: '4.5rem' }}
          >
            <div className={componentsStyles.dropzone_controls}>
              <div className={componentsStyles.dropzone_label}>
                <DustImageIcon />
                <p>Primary Image</p>
              </div>
            </div>
          </div>
        </Tooltip>
      ) : (
        <DustFileUploadField
          formik={formik}
          name="primaryImage"
          fileType="image"
          label={(disabled && disabledMessage) || 'Primary Image'}
          style={{ flex: 1, margin: 0, minHeight: '4.5rem' }}
          onReject={handleReject}
          disabled={disabled}
        />
      )}
    </div>
  );
}
