import ErrorBase from '@/components/Pages/ErrorPages/ErrorBase';

export default function Unauthorized() {
  return (
    <ErrorBase
      message="You do not have permissions to access this page."
      title="Permission denied."
    />
  );
}
