import { useMemo, useState } from 'react';

import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useUsersData from '@/common/entities/users/useUsersData';
import { ROUTES } from '@/common/routes';
import { alphaSort } from '@/common/utilities/sort';
import DustLoader from '@/components/Library/DustLoader';
import DustSearchInput from '@/components/Library/DustSearchInput';
import UsersTable from '@/components/Pages/Admin/Users/UsersTable';

import styles from './Users.module.css';

export default function Users() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const { isLoading, isSuccess: isSuccessUsers, users } = useUsersData();

  const onRowClick = (row: any) => {
    navigate(ROUTES.ADMIN_USERS_EDIT(row.id));
  };

  const createNewUser = () => {
    navigate(ROUTES.ADMIN_USERS_ADD);
  };

  const userList = useMemo(() => {
    if (!isSuccessUsers) return [];
    const searchRegex = new RegExp(search, 'i');
    return users
      .filter(
        (row) =>
          searchRegex.test(row.firstName.toString()) ||
          searchRegex.test(row.lastName.toString()) ||
          searchRegex.test(row.email.toString()),
      )
      .sort((a, b) => alphaSort(a, b, 'firstName'));
  }, [search, users, isSuccessUsers]);

  return isLoading ? (
    <DustLoader flex size="xlarge" />
  ) : (
    <>
      <div className={styles.controlsRow}>
        <DustSearchInput aria-label="Search for users" setValue={setSearch} />
        <Button
          onClick={() => createNewUser()}
          startIcon={<Add />}
          sx={{ margin: '0', marginRight: '1rem' }}
          variant="contained"
        >
          Invite
        </Button>
      </div>
      <div className="flex-1 border">
        <UsersTable onRowClick={onRowClick} users={userList} />
      </div>
    </>
  );
}
