import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import styles from './AntiTamperRescanWarningModal.module.css';

type AntiTamperRescanWarningModalProps = {
  open: boolean;
  onCancel: () => void;
  onRescan: () => void;
};

export default function AntiTamperRescanWarningModal({
  onCancel,
  onRescan,
  open,
}: AntiTamperRescanWarningModalProps) {
  return (
    <Dialog open={open} aria-labelledby="anti-tamper-warning-dialog">
      <DialogTitle component="h3" className={styles.modalTitle}>
        Discard current scan and rescan?
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="p" className={styles.modalContentText}>
          By taking a new scan, you will lose any comments or notations you’ve
          made on the current scan. Select “Discard and Rescan” to continue or
          “Cancel” to go back to existing scan.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.modalAction}>
        <Button
          onClick={onCancel}
          variant="contained"
          className={`${styles.returnButton} ${styles.modalButton}`}
        >
          Cancel
        </Button>
        <Button
          onClick={onRescan}
          color="primary"
          variant="contained"
          className={styles.modalButton}
        >
          Discard and Rescan
        </Button>
      </DialogActions>
    </Dialog>
  );
}
