import { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import DustMapWrapper from './DustMapWrapper';

function InteractiveMap({ center, zoom }) {
  const ref = useRef();
  const mapOptions = {
    draggable: false,
    scrollwheel: false,
    panControl: false,
    keyboardShortcuts: false,
    maxZoom: zoom,
    minZoom: zoom,
    zoom,
    center,
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
  };

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, mapOptions);

    // NOTE: disabling lint, assuming documented behavior
    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      position: {
        lat: center.lat,
        lng: center.lng,
      },
      draggable: false,
      map,
    });
  });

  return (
    <div
      ref={ref}
      style={{
        height: '50vh',
        width: '100%',
        maxHeight: '300px',
      }}
    />
  );
}

InteractiveMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
};

function MapError({ message }) {
  return <div style={{ fontSize: 'var(--font-size-text-sm)' }}>{message}</div>;
}

MapError.propTypes = {
  message: PropTypes.string,
};

function DustMap({ latitude, longitude, zoom }) {
  if (
    !latitude ||
    !longitude ||
    Number.isNaN(latitude) ||
    Number.isNaN(longitude)
  ) {
    return <MapError message="No location data available" />;
  }

  return (
    <section
      style={{
        height: '50vh',
        width: '100%',
        maxHeight: '300px',
      }}
    >
      <DustMapWrapper>
        <InteractiveMap
          center={{ lat: latitude, lng: longitude }}
          zoom={zoom}
        />
      </DustMapWrapper>
    </section>
  );
}

DustMap.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
};

export default DustMap;
