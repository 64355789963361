import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

export default function useUserData(
  userId: string,
  { enabled }: { enabled?: boolean } = {},
) {
  const { usersApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    [...QUERY_KEYS.USER(userId)],
    () => usersApi.getUser(userId),
    { enabled },
  );

  return {
    user: data && data.error === false && data.data ? data.data : null,
    isLoading,
    isSuccess,
  };
}
