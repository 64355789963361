import { useAuth } from 'react-oidc-context';

import ErrorBase from './ErrorBase';
import LoginErrorButtons from './LoginErrorButtons';

/** Used to display an auth related error any time it happens. */
export default function AuthErrorPage() {
  const auth = useAuth();

  return (
    <div className="app-container">
      <ErrorBase
        title="Uh-oh."
        hideLinks
        message={
          'Looks like we’re unable to log you in at this time. Please try again. If the issue persists, please contact the DUST customer success team using the button\xA0below.'
        }
      >
        <LoginErrorButtons authProps={auth} />
      </ErrorBase>
    </div>
  );
}
