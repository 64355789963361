import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 10 * 60 * 1000;

type HookProps = {
  enabled?: boolean;
  sort?: string;
};

export default function useFacilitiesData({ enabled = true, sort }: HookProps) {
  const { facilitiesApi, QUERY_KEYS } = useRequest();

  const queryParams = { sort };

  const { isLoading, isSuccess, data } = useQuery(
    [...QUERY_KEYS.FACILITIES, queryParams],
    () => facilitiesApi.list(queryParams),
    { enabled, staleTime: STALE_TIME },
  );

  return {
    facilities: data?.error || !data?.data ? [] : data.data,
    isSuccess,
    isLoading,
  };
}
