import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

import styles from './Components.module.css';
import { combineClass } from './utility';

/**
 *
 * @param   {object}                                   props
 * @param   {string}                                   [props.className]
 * @param   {'icon'|'small'|'medium'|'large'|'xlarge'} props.size
 * @param   {boolean}                                  [props.flex]
 * @param   {object}                                   [props.sx]
 * @returns {JSX.Element}
 */
function DustLoader({ className = '', size, sx = undefined, flex = false }) {
  const sizes = {
    small: '1rem',
    icon: '1.25rem',
    medium: '2rem',
    'large:': '3rem',
    xlarge: '4rem',
  };
  return (
    <div
      className={combineClass(
        styles.loader__container,
        className,
        flex && 'flex-1',
      )}
    >
      <CircularProgress size={sizes[size]} sx={sx} />
    </div>
  );
}

DustLoader.propTypes = {
  className: PropTypes.string,
  flex: PropTypes.bool,
  size: PropTypes.oneOf(['icon', 'small', 'medium', 'large', 'xlarge']),
  sx: PropTypes.object,
};

export default DustLoader;
