import { AuthContextProps } from 'react-oidc-context';

import ErrorBase from '../ErrorPages/ErrorBase';
import LoginErrorButtons from '../ErrorPages/LoginErrorButtons';

export default function UserNotInOrgsPage({
  authProps,
}: {
  authProps: AuthContextProps;
}) {
  return (
    <div className="app-container">
      <ErrorBase
        title="Uh-oh."
        hideLinks
        message="Looks like your user account isn’t connected to an organization. Please contact your account administrator to solve this issue."
      >
        <LoginErrorButtons authProps={authProps} />
      </ErrorBase>
    </div>
  );
}
