import { GridColDef, GridEventListener } from '@mui/x-data-grid';

import { formatDateForDisplay } from '@/common/dates';
import { timeSort } from '@/common/utilities/sort';
import DustDataGrid from '@/components/Library/DustDataGrid';

type Props = {
  groups: Group[];
  onSelect: (e: Group[]) => void;
  getNextPage: () => void;
  totalItems?: number;
  isFetching: boolean;
  onRowClick: GridEventListener<'rowClick'>;
  onCellClick: GridEventListener<'cellClick'>;
};

const columns: GridColDef<Group>[] = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
  {
    valueFormatter: ({ value }) => formatDateForDisplay(value),
    sortComparator: (a, b) => timeSort(a, b),
    field: 'createdAt',
    headerName: 'Created at',
    flex: 1,
    sortingOrder: ['desc', 'asc'],
  },
];

export default function UserGroupsTable({
  groups = [],
  onSelect,
  getNextPage,
  totalItems,
  isFetching,
  onRowClick,
  onCellClick,
}: Props) {
  return (
    <DustDataGrid
      altTheme
      columns={columns}
      loading={isFetching}
      initialState={{
        sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
      }}
      onCellClick={onCellClick}
      onRowClick={onRowClick}
      getRowId={(row) => row.id /* OIDC */}
      onRowsScrollEnd={getNextPage}
      onSelectionModelChange={(items) =>
        onSelect(groups.filter((group) => items.includes(group.id)))
      }
      getRowClassName={({ row }) => row.name.split(' ').join('-')}
      rows={groups}
      rowCount={totalItems}
      checkboxSelection
      disableSelectionOnClick
    />
  );
}
