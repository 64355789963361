import { useState, useEffect, useMemo } from 'react';

import { useInfiniteQuery } from 'react-query';
import { atom } from 'recoil';

import {
  pageCountGetNextPageParam,
  useFetchAll,
  useGetNextPage,
} from '@/common/utilities/table';
import { TYPES, getType, keysOf } from '@/common/utility';
import { GetThings, ValueFilters } from '@/services/requests/apis/ThingsApi';
import useRequest from '@/services/requests/useRequest';
import { SortOptions } from '@/services/useSort';

const STALE_TIME = 5 * 60 * 1000;

export const SORT_OPTIONS = Object.freeze({
  nameaz: {
    label: 'Thing Title (A-Z)',
    params: { fixedParams: { sort: ['title'] } },
    column: 'title',
    direction: 'asc',
  },
  nameza: {
    label: 'Thing Title (Z-A)',
    params: { fixedParams: { sort: ['-title'] } },
    column: 'title',
    direction: 'desc',
  },
  createdAtNewest: {
    label: 'Newest First',
    params: { fixedParams: { sort: ['-createdAt'] } },
    column: 'createdAt',
    direction: 'desc',
  },
  createdAtOldest: {
    label: 'Oldest First',
    params: { fixedParams: { sort: ['createdAt'] } },
    column: 'createdAt',
    direction: 'asc',
  },
} as const);

// TODO: use upcoming TypeScript `satisfies` keyword for this type check
((q: SortOptions) => q)(SORT_OPTIONS);

export const thingSelectorSortAtom = atom<keyof typeof SORT_OPTIONS & string>({
  key: 'thingSelectorSort',
  default: 'createdAtNewest',
});

/**
 * Checks if a filter matches its default
 * Uses a pseudo-deep equals that required order to be the same
 */
function isFilterDefault(filter: any, defaultValue: any) {
  return getType(filter) === TYPES.OBJECT || getType(filter) === TYPES.ARRAY
    ? JSON.stringify(filter) === JSON.stringify(defaultValue)
    : filter === defaultValue;
}

/**
 * Returns an object with a boolean value for each filter key that
 * determines if it equals its default value
 */
function areFiltersDefault(
  filters: ThingFilters,
  defaultFilters: ThingFilters,
) {
  const isDefault = {} as {
    [x in keyof Omit<ThingFilters, 'search'>]: boolean;
  } & { search?: boolean };

  keysOf(filters).forEach((filter) => {
    isDefault[filter] = isFilterDefault(
      filters[filter],
      defaultFilters[filter],
    );
  });

  // Remove search so that a chip and 'clearFilters button' do not depend on it
  delete isDefault.search;
  return isDefault;
}

// ---------------------------------------------------------------------------

// Tri-state view options for dust state
export const DUST_OPTIONS = ['All', 'DUSTed', 'Not DUSTed'];
export const BOUND_STATE = {
  [DUST_OPTIONS[0]]: undefined,
  [DUST_OPTIONS[1]]: true,
  [DUST_OPTIONS[2]]: false,
};

// Tri-state view options for checkout state
export const CHECKOUT_OPTIONS = ['All', 'Checked Out', 'Checked In'];
export const CHECKED_OUT_STATE = {
  [CHECKOUT_OPTIONS[0]]: undefined,
  [CHECKOUT_OPTIONS[1]]: true,
  [CHECKOUT_OPTIONS[2]]: false,
};

export type ThingFilters = {
  catalogUuids: string[];
  status: string;
  showCheckedOut: string;
  search: string;
  // metadataFilters: ValueFilters; // Deprecated
  typedMetadataFilters: ValueFilters;
};

/** Create a default filter set */
const defaultFilters = (catalogUuid?: string): ThingFilters => ({
  catalogUuids: catalogUuid ? [catalogUuid] : [],
  status: DUST_OPTIONS[0],
  showCheckedOut: CHECKOUT_OPTIONS[0],
  search: '',
  // metadataFilters: {}, // Deprecated
  typedMetadataFilters: {},
});

export const THING_FILTER_NAMES = {
  catalogUuids: () => 'Catalogs',
  status: (status: any) => status,
  showCheckedOut: (status: any) => status,
  typedMetadataFilters: () => 'Field Values',
};

export type FixedParams = Pick<
  GetThings,
  'canBeParentOf' | 'canBeChildOf' | 'showCheckedOut' | 'fields' | 'sort'
> & {
  thingUuids?: string[];
  rootUuids?: string[];
} & Partial<Omit<ThingFilters, 'showCheckedOut'>>;

/** Data fetch hook for multiple things */
export default function useThingsData({
  catalogUuid = undefined,
  fixedParams = {},
  perPage = 50,
  enabled = true,
  fetchAll = false,
}: {
  /** Optional catalog uuid to search a single catalog */
  catalogUuid?: string;
  fixedParams?: FixedParams;
  perPage?: number;
  enabled?: boolean;
  fetchAll?: boolean;
}) {
  const { thingsApi, QUERY_KEYS } = useRequest();

  const [filters, setFilters] = useState(defaultFilters(catalogUuid));

  // Must change the catalogUuid ID filter anytime the prop is updated
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      catalogUuids: catalogUuid ? [catalogUuid] : [],
    }));
  }, [catalogUuid]);

  // ---------------------------------------------------------------------------
  // HANDLE FILTER SETTING

  /** Set a single filter */
  const setFilter = <K extends keyof ThingFilters>(
    key: K,
    val: ThingFilters[K],
  ) => {
    setFilters((prev) => ({ ...prev, [key]: val }));
  };

  /** Reset a single filter */
  const resetFilter = (key: keyof ThingFilters) => {
    setFilter(key, defaultFilters()[key]);
  };

  /** Reset all filters */
  const resetFilters = () => {
    setFilters((prev) => ({
      ...defaultFilters(catalogUuid),
      search: prev.search,
    }));
  };

  const defaultedFilters = useMemo(
    () => areFiltersDefault(filters, defaultFilters(catalogUuid)),
    [filters, catalogUuid],
  );

  const showResetFilters = Object.values(defaultedFilters).some((v) => !v);

  // ---------------------------------------------------------------------------
  // QUERY HANDLING

  const queryParams = {
    catalogUuids: filters.catalogUuids,
    isBound: BOUND_STATE[filters.status],
    showCheckedOut: CHECKED_OUT_STATE[filters.showCheckedOut],
    metadataText: filters.search,
    typedMetadataFilters: filters.typedMetadataFilters,
    perPage,
    ...fixedParams,
  };

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [...QUERY_KEYS.THINGS_LIST, queryParams],
    ({ pageParam = 1 }) =>
      thingsApi.search({ ...queryParams, page: pageParam }),
    {
      getNextPageParam: pageCountGetNextPageParam,
      enabled,
      staleTime: STALE_TIME,
    },
  );

  /** Combine all the pages of things into a single object */
  const things =
    data?.pages.reduce<Thing[]>(
      (combined, current) => combined.concat(current.error ? [] : current.data),
      [],
    ) ?? [];

  const isError = data?.pages?.some((page) => page.error);

  const pageZero = data?.pages?.[0];
  const totalItems = pageZero && !pageZero.error ? pageZero.total : undefined;

  const getNextPage = useGetNextPage(
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  );

  useFetchAll(fetchAll, getNextPage, isFetchingNextPage, hasNextPage);

  return {
    things,
    totalItems,
    isLoading,
    isFetching: isLoading || isFetchingNextPage,
    isSuccess: isSuccess && !isError,
    isError,
    getNextPage,
    showResetFilters,
    defaultedFilters,
    filters,
    setFilter,
    resetFilter,
    resetFilters,
    hasNextPage,
  };
}
