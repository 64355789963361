import { useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import { THING_TYPE_FIELD_TYPES } from '@/common/entities/thingTypes/constants';
import { addThingTypeSchema } from '@/common/entities/thingTypes/schemas';
import {
  mapTitleStringToSchema,
  thingTypeToFormSchema,
} from '@/common/entities/thingTypes/utility';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import useToasts from '@/common/hooks/useToasts';
import { ROUTES } from '@/common/routes';
import { has, removeFileExtension, urlFromFile } from '@/common/utility';
import { FILE_EXTENSIONS_FROM_MIME } from '@/components/Library/constants';
import DustFieldLabel from '@/components/Library/DustFieldLabel';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustInfoIconTooltip from '@/components/Library/DustInfoIconTooltip';
import DustModal from '@/components/Library/DustModal';
import DustSchemaTextField from '@/components/Library/DustSchemaTextField';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import { clone } from '@/components/Library/utility';
import useRequest from '@/services/requests/useRequest';

import styles from './AddEditThingTypes.module.css';
import ThingTypeFields from './ThingTypeFields';
import ThingTypeFilePlaceholder from './ThingTypeFilePlaceholder';
import ThingTypeStaticFiles from './ThingTypeStaticFiles';
/**
 *
 * @param   {object}      props
 * @param   {ThingType}   [props.thingType]
 * @param   {object}      [props.mediaFiles]
 * @param   {boolean}     [props.edit]
 * @returns {JSX.Element}
 */
function AddEditThingType({ thingType = null, mediaFiles = {}, edit = false }) {
  const { addToast } = useToasts();
  const { thingMediaApi, thingTypeApi } = useRequest();
  const navigate = useNavigate();
  const [showUpdateWarning, setShowUpdateWarning] = useState(false);
  const [acceptWarning, setAcceptWarning] = useState(false);
  const [formIsComplete, setFormIsComplete] = useState(false);

  const submitForm = async (values) => {
    const form = clone(values);
    const submissionFiles = { ...values.files };

    // Format title value into schema
    form.titleField.value = form.title
      ? mapTitleStringToSchema(form.title, form.fieldTypes)
      : null;

    // If primary image has been explicitly set to null then remove any existing value
    if (submissionFiles[form.primaryImageFieldTypeUuid] === null) {
      form.primaryImageField.value = null;
    }

    // Add title and primary image fields
    form.fieldTypes = [
      form.titleField,
      form.primaryImageField,
      ...form.fieldTypes,
    ];

    // Add extensions to the filenames based on MIME type. Do not change restricted fields
    form.fieldTypes = form.fieldTypes.map((field) =>
      field.value?.type !== 'STATIC_MEDIA' ||
      Object.values(RESTRICTED_THING_FIELDS).includes(field.name)
        ? field
        : {
            ...field,
            name: `${removeFileExtension(field.name)}.${
              FILE_EXTENSIONS_FROM_MIME[
                submissionFiles[field.uuid]?.type ?? mediaFiles[field.uuid].type
              ]
            }`,
          },
    );

    // Associate each file with its data in array format for API submission
    const fileArray = Object.entries(submissionFiles)
      .filter(([_, file]) => !!file)
      .map(([fieldUuid, file]) => ({
        fieldUuid,
        file,
        filename: form.fieldTypes.find((f) => f.uuid === fieldUuid)?.name,
        fieldIdx: form.fieldTypes.findIndex((f) => f.uuid === fieldUuid),
      }));

    // Post the media
    if (fileArray.length > 0) {
      const res = await thingMediaApi.createMedia({
        files: fileArray.map((f) => f.file),
        filenames: fileArray.map((f) => f.filename),
      });
      if (res.error) return;

      // Associate the media file UUIDs to the appropriate fieldType entry
      res.data.forEach((file, idx) => {
        const fileRef = fileArray[idx];
        form.fieldTypes[fileRef.fieldIdx].value = {
          type: 'STATIC_MEDIA',
          value: file.uuid,
        };
      });
    }

    // Submit data
    const createRes = edit
      ? await thingTypeApi.edit({
          uuid: thingType.uuid,
          name: form.name,
          fieldTypes: form.fieldTypes,
          primaryImageFieldTypeUuid: form.primaryImageFieldTypeUuid,
          titleFieldTypeUuid: form.titleFieldTypeUuid,
        })
      : await thingTypeApi.create({
          name: form.name,
          fieldTypes: form.fieldTypes,
          primaryImageFieldTypeUuid: form.primaryImageFieldTypeUuid,
          titleFieldTypeUuid: form.titleFieldTypeUuid,
        });

    if (!createRes.error) setFormIsComplete(true);
  };

  // When we set the form to complete we disabled the nav block and navigate
  useEffect(() => {
    if (formIsComplete) {
      navigate(ROUTES.ADMIN_THING_TYPES);
    }
  }, [formIsComplete, navigate]);

  const formik = useFormik({
    // In edit mode we don't resubmit the files.
    // File referencing should look in files first then mediaFiles for a matching uuid
    initialValues: thingTypeToFormSchema(thingType, edit ? {} : mediaFiles),
    validationSchema: addThingTypeSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (thingType?.thingsCount > 0 && !acceptWarning) {
        setShowUpdateWarning(true);
      } else await submitForm(values);
    },
  });

  const { handleSubmit } = formik;

  // Submit the form when we accept the editing things warning
  useEffect(() => {
    if (acceptWarning) handleSubmit();
  }, [acceptWarning, handleSubmit]);

  useNavigationPrompt(formik.dirty && !formIsComplete);

  const [showRequiredWarning, setShowRequiredWarning] = useState(false);

  // Dry run edit with initial data to see if it is possible to make a field required
  const checkRequiredIsValid = async (fieldUuid) => {
    const fieldUuids = thingType?.fieldTypes.map((f) => f.uuid);

    // Do not check if no things use the type
    if (!thingType || thingType?.thingsCount === 0) return true;

    // If the field is new and there are things it cannot be populated
    if (!fieldUuids.includes(fieldUuid)) {
      setShowRequiredWarning(true);
      return false;
    }

    const testPayload = thingTypeToFormSchema(thingType);
    const fieldIdx = testPayload.fieldTypes.findIndex(
      (f) => f.uuid === fieldUuid,
    );
    testPayload.fieldTypes[fieldIdx].required = true;

    const res = await thingTypeApi.edit({
      uuid: thingType.uuid,
      name: testPayload.name,
      fieldTypes: [
        testPayload.titleField,
        testPayload.primaryImageField,
        ...testPayload.fieldTypes,
      ],
      primaryImageFieldTypeUuid: testPayload.primaryImageFieldTypeUuid,
      titleFieldTypeUuid: testPayload.titleFieldTypeUuid,
      dryRun: true,
    });

    if (res.error) setShowRequiredWarning(true);
    return !res.error;
  };

  // ---------------------------------------------------------------------------
  // COMPONENT PROPERTIES

  const thingTitle = formik.values.title;
  const setThingTitle = (value) => formik.setFieldValue('title', value);

  const primaryImage = has(
    formik.values.files,
    formik.values.primaryImageFieldTypeUuid,
  )
    ? formik.values.files[formik.values.primaryImageFieldTypeUuid]
    : mediaFiles[formik.values.primaryImageFieldTypeUuid];

  const setFile = (file, uuid) => {
    formik.setFieldValue('files', {
      ...formik.values.files,
      [uuid]: file,
    });
  };

  const removeFile = (uuid) => {
    const { [uuid]: removedFile, ...rest } = formik.values.files;
    formik.setFieldValue('files', rest);
  };

  // Get an image url from the file
  const [primarySrc, setPrimarySrc] = useState(null);
  useEffect(() => {
    const getSrc = async () =>
      setPrimarySrc(primaryImage ? await urlFromFile(primaryImage) : null);
    getSrc();
  }, [primaryImage]);

  // Any string data fields can be used as a field in the title
  const options = [
    ...new Set(
      formik.values.fieldTypes
        .filter((field) => field.schema.type === THING_TYPE_FIELD_TYPES.STRING)
        .map((field) => field.name)
        .filter((field) => field !== ''),
    ),
  ];

  const isTitleError = formik.touched.name && !!formik.errors.name;
  const isFieldInTitle = thingTitle.includes('<') && thingTitle.includes('>');

  const handleReject = (errors) => {
    errors.forEach((error) => addToast(error, 'error'));
  };
  return (
    <>
      <form className="action-body" id="add-thing-type">
        {/* Type Title Field */}
        <div className="flex-row items-start mb-1 gap-05">
          <TextField
            error={isTitleError}
            helperText={isTitleError ? formik.errors.name : ''}
            label="Type Title*"
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            size="small"
            sx={{ width: '20rem' }}
            value={formik.values.name}
          />
          <DustInfoIconTooltip title={TitleToolTip} />
        </div>
        <div className="mb-2 divider-0" />
        {/* Thing Title Field */}
        <div>
          <div
            className="flex-row items-center gap-05"
            style={{ maxWidth: '35rem' }}
          >
            <DustSchemaTextField
              className="flex-1 min-w-0"
              label="Thing Title"
              menuId="select-typed-field"
              options={options}
              setValue={setThingTitle}
              value={thingTitle}
            />
            <DustInfoIconTooltip title={ThingTitleTooltip} />
          </div>
          {isFieldInTitle && (
            <span className={styles.schemaHelperText}>
              Note: if field does not contain data, its field name will be
              displayed instead.
            </span>
          )}
          {thingTitle && (
            <p className="mt-1 mb-1 text-sm">Title preview: {thingTitle}</p>
          )}
        </div>
        {/* Main Form Content */}
        <div className={styles.bodyContent}>
          {/* Primary Image and Fields */}
          <div className="flex-1">
            <DustFieldLabel className="mt-1 mb-05" label="Primary Image" />
            <div className="flex-row">
              {primarySrc && (
                <img
                  alt="Thing Primary"
                  className={styles.primaryImage}
                  src={primarySrc}
                />
              )}
              <DustFileUploadField
                file={primaryImage}
                fileType="image"
                label="Image"
                onDelete={() =>
                  setFile(null, formik.values.primaryImageFieldTypeUuid)
                }
                setFile={(file) =>
                  setFile(file, formik.values.primaryImageFieldTypeUuid)
                }
                style={{ flex: 1, margin: 0, minHeight: '4.5rem' }}
                onReject={handleReject}
              />
            </div>
            <DustFieldLabel className="mt-3" label="Data Fields" />
            <ThingTypeFields
              checkRequiredIsValid={checkRequiredIsValid}
              formik={formik}
            />
          </div>
          {/* File Fields */}
          <div className="flex-1">
            <DustFieldLabel
              className="mt-1 mb-05"
              label="Static Files and Images"
            />
            <ThingTypeStaticFiles
              files={formik.values.files}
              formik={formik}
              initialFiles={mediaFiles}
              removeFile={removeFile}
            />
            <DustFieldLabel
              className="mt-3"
              label="Placeholder Files and Images"
            />
            <ThingTypeFilePlaceholder formik={formik} />
          </div>
        </div>
      </form>
      {showRequiredWarning && (
        <DustModal
          footerProps={{
            onSubmit: () => setShowRequiredWarning(false),
            submitLabel: 'Continue',
          }}
          onClose={() => setShowRequiredWarning(false)}
          open={showRequiredWarning}
          title="Unable to mark this field as required."
        >
          <p>
            This field is not populated in existing Things of this type. Marking
            the field as required would produce data field errors. Ensure all
            existing instances include data in this field before marking as
            required.
          </p>
        </DustModal>
      )}
      {thingType?.thingsCount > 0 && (
        <DustModal
          footerProps={{
            onCancel: () => setShowUpdateWarning(false),
            onSubmit: () => setAcceptWarning(true),
            submitLabel: 'Proceed',
            cancelLabel: 'Cancel',
            loading: formik.isSubmitting,
            disabled: formik.isSubmitting,
          }}
          onClose={() => setShowUpdateWarning(false)}
          open={showUpdateWarning}
          title="Update all existing Things of this Type?"
        >
          <p>
            Updating this Thing Type will also update {thingType?.thingsCount}{' '}
            Thing{thingType?.thingsCount > 1 ? 's' : ''} it is currently applied
            to. Click Update to proceed.
          </p>
        </DustModal>
      )}
      <DustStepperFooter
        loading={formik.isSubmitting}
        onCancel={() => navigate(-1)}
        submitLabel={edit ? 'Update Thing Type' : 'Create Thing Type'}
        onSubmit={handleSubmit}
      />
    </>
  );
}

const ThingTitleTooltip = (
  <>
    <strong>Thing Title (optional)</strong>
    <div>
      Create a title that will serve as the name for an individual Thing using
      this template. Enter text and/or included data fields to make up the
      title. Template tags can be selected from the dropdown or typed by
      wrapping a field name with angled brackets (ex.
      &ldquo;&lt;FIELD&gt;&rdquo;).
      <br />
      You may also leave this field blank and add titles at the point of
      creation.
    </div>
  </>
);

const TitleToolTip = (
  <>
    <strong>Type Title (Required)</strong>
    <div>
      Create a title for this template that represents the type of Thing (or
      product) it will be applied to.
    </div>
  </>
);

AddEditThingType.propTypes = {
  thingType: PropTypes.object,
  mediaFiles: PropTypes.object,
  edit: PropTypes.bool,
};

export default AddEditThingType;
