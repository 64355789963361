import { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import useGroupData from '@/common/entities/groups/useGroupData';
import DustEditableText from '@/components/Library/DustEditableText';
import DustLoader from '@/components/Library/DustLoader';
import DustTabContainer, {
  DustTabContainerTab,
} from '@/components/Library/DustTabContainer';
import GroupEditRoles from '@/components/Pages/Admin/UserGroups/GroupEditRoles';
import GroupFiltersTab from '@/components/Pages/Admin/UserGroups/GroupFiltersTab';
import GroupUserList from '@/components/Pages/Admin/UserGroups/GroupUserList';
import useRequest from '@/services/requests/useRequest';

export default function GroupDetails() {
  const { groupsApi } = useRequest();

  const { groupId } = useParams();
  if (!groupId) throw new Error('Missing path param');

  const { isLoading, group } = useGroupData({ groupUuid: groupId });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateGroupField = async (
    value: string,
    field: 'name' | 'description',
    onSuccess: () => void,
  ) => {
    if (!group) return;

    setIsSubmitting(true);

    const res = await groupsApi.updateGroup(group.id, {
      name: field === 'name' ? value : group.name,
      description: field === 'description' ? value : group.description,
    });

    if (!res.error) onSuccess();
    setIsSubmitting(false);
  };

  const tabs: DustTabContainerTab[] = useMemo(
    () =>
      group
        ? [
            {
              label: 'Users',
              content: <GroupUserList group={group} />,
            },
            {
              label: 'Filters',
              content: group.uuid ? (
                <GroupFiltersTab groupUuid={group.uuid} />
              ) : (
                <p>Group is not in DICE, group filters are not available</p>
              ),
            },
            {
              label: 'Roles',
              content: <GroupEditRoles group={group} />,
            },
          ]
        : [],
    [group],
  );

  return (
    <>
      <DustEditableText
        className="h1"
        key="title"
        loading={isSubmitting || isLoading}
        onSave={(value: string, onSuccess: () => void) =>
          updateGroupField(value, 'name', onSuccess)
        }
        tag="h1"
        textFieldProps={{
          variant: 'standard',
          sx: { width: '20rem' },
          inputProps: {
            maxLength: 255,
          },
        }}
        value={group?.name ?? ''}
      />
      <DustEditableText
        key="description"
        loading={isSubmitting || isLoading}
        onSave={(value: string, onSuccess: () => void) =>
          updateGroupField(value, 'description', onSuccess)
        }
        tag="p"
        isInvalid={() => null}
        placeholder="Group Description"
        textFieldProps={{
          variant: 'standard',
          sx: { width: '40rem' },
        }}
        value={group?.description ?? ''}
      />
      {isLoading ? (
        <DustLoader size="xlarge" />
      ) : (
        <DustTabContainer tabs={tabs} tabStyles={{ padding: '0' }} />
      )}
    </>
  );
}
