import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Delete } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { GridEventListener } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import useGroupsData from '@/common/entities/groups/useGroupsData';
import { ROUTES } from '@/common/routes';
import { useFetchMoreToFillHeightRef } from '@/common/utilities/table';
import DustSearchInput from '@/components/Library/DustSearchInput';
import AddUserGroup from '@/components/Pages/Admin/UserGroups/AddUserGroup';
import DeleteUserGroup from '@/components/Pages/Admin/UserGroups/DeleteUserGroup';
import UserGroupsTable from '@/components/Pages/Admin/UserGroups/UserGroupsTable';

import styles from './Groups.module.css';

export default function UserGroups() {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selected, setSelected] = useState<Group[]>([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isPollingDiceGroup, setIsPollingDiceGroup] = useState(false);

  const {
    groups,
    totalItems,
    getNextPage,
    isFetching: isFetchingGroups,
    refetch,
  } = useGroupsData();

  const cancelGroupPollRef = useRef<(() => void) | null>();
  const handleNewGroupPoll = useCallback(
    (pollPromise: Promise<boolean>, cancel: () => void) => {
      setIsPollingDiceGroup(true);
      cancelGroupPollRef.current = cancel;
      pollPromise.then(
        (groupFetched) => {
          if (groupFetched) {
            // Just using invalidate.groups() here doesn't work -
            // DICE may update while an existing refetch is ongoing
            void refetch({ cancelRefetch: true });
          }
          setIsPollingDiceGroup(false);
        },
        () => setIsPollingDiceGroup(false),
      );
    },
    [refetch],
  );
  // Cancel the poll on unmount
  useEffect(() => () => cancelGroupPollRef.current?.(), []);

  const onRowClick = ({ row }: { row: Group }) => {
    if (row.uuid) {
      navigate(ROUTES.ADMIN_GROUP_DETAILS(row.uuid));
    }
  };

  const onCellClick: GridEventListener<'cellClick'> = (params, event) => {
    if (params.field === 'adminAccess') {
      event.stopPropagation();
    }
  };

  const tableContainerRef = useFetchMoreToFillHeightRef(
    getNextPage,
    groups.length,
  );

  const filteredGroups = useMemo(() => {
    const searchRegex = new RegExp(search, 'i');
    return groups?.filter(
      (row) => searchRegex.test(row.name) || searchRegex.test(row.description),
    );
  }, [search, groups]);

  return (
    <>
      <div className={styles.controlsRow}>
        <DustSearchInput
          aria-label="Search for user groups"
          setValue={setSearch}
        />

        <Button
          onClick={() => setShowModal(true)}
          startIcon={<Add />}
          sx={{ margin: '0', marginRight: '1rem' }}
          variant="contained"
        >
          Create Group
        </Button>
        <Button
          color="error"
          disabled={selected.length === 0}
          onClick={() => setShowDeleteModal(true)}
          startIcon={<Delete />}
          sx={{ margin: '0', marginRight: '1rem' }}
          variant="contained"
        >
          {selected.length > 1 ? 'Delete User Groups' : 'Delete User Group'}
        </Button>
      </div>
      <div className="flex-1 border" ref={tableContainerRef}>
        <UserGroupsTable
          getNextPage={getNextPage}
          groups={filteredGroups}
          isFetching={isFetchingGroups || isPollingDiceGroup}
          onCellClick={onCellClick}
          onRowClick={onRowClick}
          onSelect={(values) => setSelected(values)}
          totalItems={totalItems}
        />
      </div>
      {showModal && (
        <AddUserGroup
          onStartNewGroupPoll={handleNewGroupPoll}
          onClose={() => setShowModal(false)}
          open={showModal}
        />
      )}
      <DeleteUserGroup
        groups={selected}
        onClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
      />
    </>
  );
}
