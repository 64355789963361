import { useState } from 'react';

import { OidcUser } from '@/common/entities/users/typedefs';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

import styles from './Groups.module.css';

type Props = {
  group: Group;
  onClose: () => void;
  open: boolean;
  usersToRemove: OidcUser[];
};

export default function GroupDeleteUsersModal({
  group,
  onClose,
  open,
  usersToRemove,
}: Props) {
  const { groupsApi } = useRequest();
  const [submitting, setSubmitting] = useState(false);

  const plural = usersToRemove.length > 1 ? 'Users' : 'User';

  const handleDelete = async () => {
    setSubmitting(true);

    const res = await groupsApi.removeUsersFromGroup(group.id, usersToRemove);
    setSubmitting(false);

    if (!res.error) onClose();
  };

  return (
    <DustModal
      footerProps={{
        onCancel: onClose,
        loading: submitting,
        onSubmit: handleDelete,
        submitLabel: `Remove ${usersToRemove.length} ${plural}`,
        cancelLabel: 'Cancel',
      }}
      onClose={onClose}
      open={open}
      title="Are you sure you want to remove the following users?"
    >
      <>
        <h2 className="h6" style={{ marginBottom: '1rem' }}>
          The following users will be removed from {group.name}:
        </h2>
        <div>
          <ul className={styles.groupList}>
            {usersToRemove.map((user) => (
              <li key={user.sub}>
                {`${user.preferred_username} - ${user.email}`}
              </li>
            ))}
          </ul>
        </div>
      </>
    </DustModal>
  );
}
