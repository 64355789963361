import { useState } from 'react';

import { useInterval } from 'usehooks-ts';

import useThingData from '@/common/entities/things/useThingData';
import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import ScanModal, {
  SCAN_MODES,
} from '@/components/Composed/ScanModal/ScanModal';
import DustLoader from '@/components/Library/DustLoader';
import DustModal from '@/components/Library/DustModal';
import { SESSION_TIMEOUT_WARN } from '@/services/user-oidc';

const STEPS = {
  PROMPT: 'prompt',
  SCAN: 'scan',
};

export default function VerifiedSessionDialog() {
  const [step, setStep] = useState(STEPS.PROMPT);

  const [open, setOpen] = useState(false);
  const [dismissed, setDismissed] = useState<string[]>([]);

  const [countdown, setCountdown] = useState(SESSION_TIMEOUT_WARN);

  const { verifiedThings, expireVerifiedThing } = useVerifiedSession();

  // Need to query the thing in preparation for a re-scan
  const [queryThingUuid, setQueryThingUuid] = useState('');

  const { isLoading, thing } = useThingData({
    thingUuid: queryThingUuid,
    enabled: !!queryThingUuid,
  });

  useInterval(() => {
    Object.keys(verifiedThings).forEach((thingUuid) => {
      const expiry = verifiedThings[thingUuid];
      const secondsRemaining = Math.floor((expiry - Date.now()) / 1000);

      // If you are on the thing page we prompt for session expiry
      if (
        window.location.pathname.includes(thingUuid) &&
        !dismissed.includes(thingUuid)
      ) {
        if (secondsRemaining < SESSION_TIMEOUT_WARN) {
          // Query the thing in preparation for a scan
          if (!queryThingUuid) {
            setQueryThingUuid(thingUuid);
          }

          setCountdown(secondsRemaining);
          setOpen(true);
        }
      }

      // Clear the dialog and expire the thing
      // If the user is in the scan flow we wait until the scan flow is closed to reset
      if (secondsRemaining < 0 && step !== STEPS.SCAN) {
        setDismissed((prev) => prev.filter((id) => id !== thingUuid));
        expireVerifiedThing(thingUuid);
        setQueryThingUuid('');
        setOpen(false);
      }
    });
  }, 1000);

  const handleDismiss = () => {
    Object.keys(verifiedThings).forEach((thingUuid) => {
      if (window.location.pathname.includes(thingUuid)) {
        setDismissed((prev) => [...prev, thingUuid]);
        setQueryThingUuid('');
        setOpen(false);
      }
    });
  };

  const StepComponents = {
    [STEPS.PROMPT]: (
      <DustModal
        footerProps={{
          onCancel: handleDismiss,
          cancelLabel: 'Dismiss',
          loading: isLoading,
          submitLabel: 'Re-scan Thing',
          onSubmit: () => setStep(STEPS.SCAN),
        }}
        maxWidth="25rem"
        open={open}
        title="DUST Verified Session Expiring"
      >
        <span>
          Your DUST verified session will end in {countdown < 0 ? 0 : countdown}{' '}
          seconds. Please re-scan to continue in a verified session.
        </span>
      </DustModal>
    ),
    [STEPS.SCAN]: (
      <ScanModal
        mode={SCAN_MODES.VERIFY}
        onClose={() => setStep(STEPS.PROMPT)}
        onComplete={() => {
          setStep(STEPS.PROMPT);
          setOpen(false);
        }}
        open={open}
        setOpen={setOpen}
        thing={thing}
        verifyMessage={
          !thing || isLoading ? (
            <DustLoader size="xlarge" sx={{ margin: '5rem' }} />
          ) : undefined
        }
      />
    ),
  };

  return StepComponents[step];
}
