import { useMemo, useState } from 'react';

import { Troubleshoot } from '@mui/icons-material';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import OutputIcon from '@mui/icons-material/Output';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useNavigate, useParams } from 'react-router-dom';

import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import useCheckout from '@/common/hooks/useCheckout';
import { ROUTES } from '@/common/routes';
import DustMenuButton from '@/components/Library/DustMenuButton';
import DustModal from '@/components/Library/DustModal';
import ThingChangeCatalog from '@/components/Pages/Things/Thing/ThingChangeCatalog';
import useRequest from '@/services/requests/useRequest';

type Props = {
  thing: Thing;
  catalog: Catalog;
  disabled?: boolean;
  onAntiTamper: () => void;
};

const MAX_MOVABLE_CHILDREN = 100;

export default function ThingActionButton({
  thing,
  catalog,
  disabled = false,
  onAntiTamper,
}: Props) {
  const { thingId } = useParams();
  const navigate = useNavigate();

  const [showConfirmRemoveParent, setShowConfirmRemoveParent] = useState(false);
  const [isRemovingParent, setIsRemovingParent] = useState(false);
  const [showMoveCatalog, setShowMoveCatalog] = useState(false);

  const { shoppingCart, checkout } = useCheckout();
  const { org } = useOrgData();
  const { thingsApi } = useRequest();
  const {
    relationships: { parent, children },
    title,
    hasParent,
    isDusted,
  } = thing;

  const tooManyChildrenForMove = useMemo(
    () => (children ?? []).length > MAX_MOVABLE_CHILDREN,
    [children],
  );

  const handleRemoveParent = async () => {
    if (!parent) return;
    setIsRemovingParent(true);
    await thingsApi.updateThingChildren({
      thingUuid: parent.uuid,
      removeUuids: [thing.uuid],
    });
    setIsRemovingParent(false);

    setShowConfirmRemoveParent(false);
  };

  // Only allow catalog change if the extraction config matches the org config
  const extractConfigMatches = useMemo(
    () =>
      org?.defaultFingerprintExtractionConfigUuid ===
      catalog.currentExtractionConfig?.uuid,
    [catalog, org],
  );

  const thingMenuOptions = useMemo(() => {
    const actions: {
      label: JSX.Element;
      onClick?: () => void;
      disabled?: boolean;
      tooltip?: string;
    }[] = [
      {
        label: (
          <div className="flex-row gap-05 items-center">
            {hasParent ? <LinkOffIcon /> : <LinkIcon />}
            {hasParent ? 'Remove Parent' : 'Add Parent'}
          </div>
        ),
        onClick: () => {
          if (thing.hasParent) {
            setShowConfirmRemoveParent(true);
          } else {
            navigate(ROUTES.THING_EDIT_PARENT(thing.uuid));
          }
        },
      },
      {
        label: (
          <div className="flex-row gap-05 items-center">
            <LinkIcon />
            Add/Remove Children
          </div>
        ),
        onClick: () => {
          if (thingId) navigate(ROUTES.THING_EDIT_CHILDREN(thingId));
        },
      },
    ];

    if (org?.isFeatureEnabled?.(FEATURE_FLAGS.MESH)) {
      actions.unshift({
        label: (
          <div className="flex-row gap-05 items-center d">
            <OutputIcon />
            Check Out Thing
          </div>
        ),
        onClick: () => {
          void checkout.addThings({ things: [thing] });
        },
        disabled: shoppingCart.isThingAdded(thing),
        tooltip:
          (shoppingCart.isThingAdded(thing) &&
            'Thing is already checked out') ||
          '',
      });
    }

    if (
      org?.isFeatureEnabled?.(FEATURE_FLAGS.MOVE_THING) &&
      extractConfigMatches
    ) {
      actions.push({
        label: (
          <div className="flex-row gap-05 items-center">
            <DriveFileMoveOutlinedIcon />
            Change Catalog
          </div>
        ),
        onClick: () => {
          setShowMoveCatalog(true);
        },
        disabled: tooManyChildrenForMove,
        tooltip:
          (tooManyChildrenForMove &&
            'Can not move a Thing with more than 100 children') ||
          '',
      });
    }

    if (org?.isFeatureEnabled?.(FEATURE_FLAGS.ANTITAMPER)) {
      actions.push({
        label: (
          <div className="flex-row gap-05 items-center">
            <Troubleshoot />
            Tamper Analysis
          </div>
        ),
        onClick: onAntiTamper,
        disabled: !isDusted,
        tooltip: isDusted
          ? 'Tamper Analysis'
          : 'Tamper Analysis only available for Dusted Things',
      });
    }

    return actions;
  }, [
    hasParent,
    org,
    extractConfigMatches,
    thing,
    navigate,
    thingId,
    shoppingCart,
    checkout,
    tooManyChildrenForMove,
    onAntiTamper,
    isDusted,
  ]);

  return (
    <>
      <DustMenuButton
        disabled={disabled}
        label="Actions"
        options={thingMenuOptions}
      />
      {showMoveCatalog && (
        <ThingChangeCatalog
          catalog={catalog}
          open={showMoveCatalog}
          thing={thing}
          onClose={() => setShowMoveCatalog(false)}
        />
      )}
      <DustModal
        footerProps={{
          loading: isRemovingParent,
          onCancel: () => setShowConfirmRemoveParent(false),
          onSubmit: () => handleRemoveParent(),
          submitLabel: 'Confirm',
          submitColor: 'error',
        }}
        onClose={() => setShowConfirmRemoveParent(false)}
        open={showConfirmRemoveParent}
        title="Remove Parent"
      >
        <>
          <div className="flex-row gap-05 items-end">
            <ReportProblemIcon className="warning" />
            Are you sure?
          </div>
          <p className="mt-1">
            You are about to remove <strong>{parent?.title}</strong> as the
            parent of <strong>{title}</strong>.
          </p>
          <p className="mt-1">
            <strong>{parent?.title}</strong> and all its children will be
            removed from the relationship tree.
          </p>
          <p className="mt-1">
            Note: <strong>{title}</strong> and the remainder of its children
            will not be affected by this change.
          </p>
        </>
      </DustModal>
    </>
  );
}
