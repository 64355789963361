import { thingTypeModel } from '@/common/entities/thingTypes/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import { DustSingleResponse } from '@/services/requests/types';
import {
  formatAPIReturn,
  formatAPIError,
  formatPagedApiReturn,
} from '@/services/utility';

import { MESSAGES } from '../constants';

export type SearchThingTypeParams = {
  nameLike?: string;
  sort?: readonly {
    desc: boolean;
    field: string;
  }[];
  page: number;
  perPage?: number;
};

/** Factory function for producing the thing type API */
export default class ThingTypeApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Search a list of thingTypes */
  async search(params: SearchThingTypeParams) {
    return this.apiService
      .request({
        url: '/api/thing-types/search',
        method: 'POST',
        data: removeNil({ perPage: 50, ...params }),
      })
      .then((res) => formatPagedApiReturn(res, params.page, thingTypeModel))
      .catch((err) => formatAPIError(err));
  }

  /** Get a single thing type */
  async get({ uuid }: { uuid: string }) {
    const args = new URLSearchParams();
    args.append('idType', 'thingType_uuid');

    return this.apiService
      .request({
        url: `/api/thing-types/${uuid}?${args.toString()}`,
        method: 'GET',
      })
      .then((res: DustSingleResponse) => formatAPIReturn(res, thingTypeModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Create a new thing type */
  async create(params: {
    name: string;
    fieldTypes: object[];
    titleFieldTypeUuid: string;
    primaryImageFieldTypeUuid: string;
  }) {
    return this.apiService
      .request({
        url: 'api/thing-types',
        method: 'POST',
        data: removeNil(params),
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.thingTypes();
        this.addToast(MESSAGES.THING_TYPE.CREATE.SUCCESS, 'success');
        return formatAPIReturn(res);
      })
      .catch((err) => {
        const response = formatAPIError<{ message: string }>(err);
        this.addToast(
          response?.data?.message ?? MESSAGES.THING_TYPE.CREATE.FAIL,
          'error',
        );
        return response;
      });
  }

  /** Edit a thing type */
  async edit(params: {
    uuid: string;
    name: string;
    fieldTypes: object[];
    titleFieldTypeUuid: string;
    primaryImageFieldTypeUuid: string;
    dryRun?: boolean;
  }) {
    const { uuid, dryRun = false, ...dataParams } = params;
    return this.apiService
      .request({
        url: `api/thing-types/${uuid}${dryRun ? '?dryRun=true' : ''}`,
        method: 'PUT',
        data: removeNil(dataParams),
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.thingTypes();
        this.invalidate.thingMetaFiltersAll();
        if (!dryRun) {
          this.addToast(MESSAGES.THING_TYPE.EDIT.SUCCESS, 'success');
        }
        return formatAPIReturn(res, thingTypeModel);
      })
      .catch((err) => {
        const response = formatAPIError<{ message: string }>(err);
        if (!dryRun) {
          this.addToast(
            response?.data?.message ?? MESSAGES.THING_TYPE.EDIT.FAIL,
            'error',
          );
        }

        return response;
      });
  }

  /** Remove a thing type */
  async remove({ uuid }: { uuid: string }) {
    const args = new URLSearchParams();
    args.append('idType', 'thingType_uuid');

    return this.apiService
      .request({
        url: `api/thing-types/${uuid}?${args.toString()}`,
        method: 'DELETE',
      })
      .then((res) => {
        this.addToast(MESSAGES.THING_TYPE.DELETE.SUCCESS, 'success');
        this.invalidate.thingTypes();
        return formatAPIReturn(res);
      })
      .catch((err) => {
        this.addToast(MESSAGES.THING_TYPE.DELETE.FAIL, 'error');
        return formatAPIError(err);
      });
  }
}
