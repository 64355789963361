import { Status as MAP_STATUS, Wrapper } from '@googlemaps/react-wrapper';

import DustLoader from '@/components/Library/DustLoader';

function MapError({ message }: { message: string }) {
  return <div style={{ fontSize: 'var(--font-size-text-sm)' }}>{message}</div>;
}

const renderNonSuccess = (status: ValueOf<MAP_STATUS>) => {
  switch (status) {
    case MAP_STATUS.LOADING:
      return <DustLoader size="medium" />;
    case MAP_STATUS.FAILURE:
      return <MapError message="Failed to load interactive map" />;
    default:
      throw new Error(`Unexpected non-success status: "${status}"`);
  }
};

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

export default function DustMapWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <Wrapper apiKey={apiKey} render={renderNonSuccess}>
      {children}
    </Wrapper>
  );
}
