import { useState, useMemo } from 'react';

import { MenuItem, Tooltip } from '@mui/material';

import { booleanSort, alphaSort } from '@/common/utilities/sort';

import DustCheckbox from './DustCheckbox';
import DustSelect, { DustSelectProps } from './DustSelect';

type Props = {
  label: string;
  entries: { value: string; name: string }[];
  values: string[];
  onChange: (values: string[]) => void;
  onClear?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
};

/** Multi-select list that shows a list of checkboxes */
export default function DustCheckboxSelect({
  label,
  entries,
  values,
  onClear,
  onChange,
  onOpen,
  onClose,
  ...rest
}: Props &
  Omit<
    DustSelectProps<string[]>,
    'onChange' | 'values' | 'entries' | 'label'
  >) {
  // If handlers are used then the open state must be controlled
  const useControlledState = !!onOpen || !!onClose;

  // Provide optional props if we provide open and close handlers
  const [open, setOpen] = useState(useControlledState ? false : undefined);

  // Used for title display. Must map values to names
  const entryNames = useMemo(
    () => Object.fromEntries(entries.map(({ value, name }) => [value, name])),
    [entries],
  );

  // Add the checked property to the entries and then sort so
  // that checked values are all listed at the top of the selector
  const sortedEntries = useMemo(
    () =>
      entries
        .map((entry) => ({ ...entry, checked: values.includes(entry.value) }))
        .sort((a, b) => alphaSort(a, b, 'name'))
        .sort((a, b) => booleanSort(a, b, 'checked')),
    [entries, values],
  );

  // Optional open and close handlers for controlled state
  const handleOpen = useControlledState
    ? () => {
        onOpen?.();
        setOpen(true);
      }
    : undefined;

  const handleClose = useControlledState
    ? () => {
        onClose?.();
        setOpen(false);
      }
    : undefined;

  const handleChange = (value: string, checked: boolean) => {
    const newValues = checked
      ? [...values, value]
      : values.filter((v) => v !== value);

    onChange(newValues);
  };

  return (
    <DustSelect
      label={label}
      MenuProps={{
        sx: {
          maxWidth: '100px',
        },
      }}
      multiple
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      renderValue={(selected) =>
        selected.map((s) => entryNames[s]).join(', ') ?? ''
      }
      value={values}
      {...rest}
    >
      {!!onClear && (
        <MenuItem onClick={onClear}>
          <em>Clear Selections</em>
        </MenuItem>
      )}
      {sortedEntries?.map((option) => (
        <DustCheckbox
          checked={option.checked}
          key={option.value}
          label={
            <Tooltip enterDelay={900} placement="left" title={option.name}>
              <span>{option.name}</span>
            </Tooltip>
          }
          onChange={(checked) => handleChange(option.value, checked)}
          noWrap
        />
      ))}
    </DustSelect>
  );
}
