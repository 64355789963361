import { MouseEventHandler, useCallback } from 'react';

import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { combineClass } from '@/components/Library/utility';

import styles from './LeftNav.module.css';

type Props = {
  name: string;
  icon: React.ReactNode;
  href?: string;
  buttonStyle?: boolean;
  isFull?: boolean;
  hide?: boolean;
} & React.LinkHTMLAttributes<HTMLAnchorElement>;

/** Renders an <a> element that use react-router navigate on click */
export default function NavLink({
  name,
  icon,
  href,
  buttonStyle = false,
  isFull = false,
  hide = false,
  onClick,
  ...props
}: Props) {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (evt) => {
      onClick?.(evt);
      // For alt clicks use default behaviour. Otherwise use SPA navigation
      if (!evt.ctrlKey && !evt.metaKey && href) {
        evt.preventDefault();
        navigate(href);
      }
    },
    [href, navigate, onClick],
  );

  return (
    <Tooltip
      arrow
      enterDelay={isFull ? 10000 : 100}
      placement="right"
      title={name}
    >
      <a
        href={href}
        onClick={handleClick}
        className={combineClass(
          'text-button',
          styles.navMenuItem,
          buttonStyle && styles.navMenuButton,
        )}
        data-testid={`left-nav-${name?.toLowerCase()}`}
        style={{ visibility: hide ? 'hidden' : 'visible' }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {icon}
        {isFull && <span>{name}</span>}
      </a>
    </Tooltip>
  );
}
