import {
  thingMetaFilterModel,
  thingModel,
} from '@/common/entities/things/models';
import { ThingSubmission } from '@/common/entities/things/typedefs';
import { transactionModel } from '@/common/entities/transactions/models';
import { AddToastCallback } from '@/common/hooks/useToasts';
import { removeNil } from '@/common/utility';
import ApiService from '@/services/requests/ApiService';
import {
  DustArrayResponse,
  DustSingleResponse,
} from '@/services/requests/types';
import {
  defaultErrorHandler,
  formatAPIReturn,
  formatAPIError,
  ApiReturnError,
  formatPagedApiReturn,
} from '@/services/utility';

import { HTTP_RESPONSE_CODES, MESSAGES } from '../constants';

export type ValueFilters = Record<string, string[]>;

/** Thing search api params, based on `pnpm gen-api-types GetThings */
export type GetThings = {
  /** Filter Things that are eligible to be a child of specified Thing (UUID) */
  canBeChildOf?: string;
  /** Filter Things that are eligible to be the parent of specified Thing (UUID) */
  canBeParentOf?: string;
  /** Filter by Catalog, comma separated list of UUIDs */
  catalogUuids?: string[];
  /** Filter by DUST, comma separated list of UUIDs */
  dustUuids?: string[];
  /** Specify additional fields to include in the response */
  fields?: string[];
  /** Filter by whether the Thing is currently bound to a DUST or not */
  isBound?: boolean;
  /** Filter by metadata values */
  metadataFilters?: ValueFilters;
  /** Filter by metadata name or value */
  metadataText?: string;
  /** The page to return */
  page?: number;
  /** Number of items to return per page */
  perPage?: number;
  /** Array of fields, prefix "-" for desc, e.g.: `sort: ["-title"] */
  sort?: readonly string[];
  /** Show Things that are currently checked out in results */
  showCheckedOut?: boolean;
  /** Filter by Thing, comma separated list of UUIDs */
  thingUuids?: string[];
  /** Filter by typed metadata values */
  typedMetadataFilters?: ValueFilters;
};

export type FileReturn = {
  uuid: string;
};

// Both originSearch and destinationConflicts are key/value of thingUuid and thingName
export type MoveConflictError = {
  originSearch: {
    [thingUuid: string]: string;
  };
  destinationConflicts: {
    [thingUuid: string]: string;
  };
};

/** Factory function for producing the things API */
export default class ThingsApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Get a single thing */
  async getThing({ uuid, fields }: { uuid: string; fields: string[] }) {
    const args = new URLSearchParams();
    if (fields) {
      args.append('fields', fields.toString());
    }

    return this.apiService
      .request({
        url: `/api/things/${uuid}?${args.toString()}`,
        method: 'GET',
      })
      .then((res) => formatAPIReturn(res, thingModel))
      .catch((err) => {
        console.error(err);
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Search for a list of things */
  async search(params: GetThings) {
    return this.apiService
      .request({
        url: '/api/things/search',
        method: 'POST',
        data: removeNil({
          perPage: 50 /* Large enough to accommodate at least a screen's worth of data */,
          fields: ['mediaLinks', 'metadata', 'schema'],
          ...params,
        }),
      })
      .then((res) => formatPagedApiReturn(res, params.page ?? 1, thingModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Create new things */
  async create({
    catalogUuid,
    things,
  }: {
    catalogUuid: string;
    things: ThingSubmission[];
  }) {
    return this.apiService
      .request({
        url: '/api/things',
        method: 'POST',
        data: {
          catalogUuid,
          things,
        },
      })
      .then((res: DustArrayResponse) => {
        this.invalidate.thingMoveCreate(catalogUuid);
        return formatAPIReturn(res, thingModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.THING_CREATE.CREATE_ERROR, 'error');
        }),
      );
  }

  /** Create multiple things from a CSV file */
  async bulkCreate({
    file,
    catalogUuid,
  }: {
    file: File | Blob;
    catalogUuid: string;
  }) {
    const formData = new FormData();
    formData.append('csv', file, 'csv');
    formData.append('catalogUuid', catalogUuid);

    return this.apiService
      .request({
        url: '/api/things/bulk',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      .then((res: DustArrayResponse) => {
        this.invalidate.thingMoveCreate(catalogUuid);
        return formatAPIReturn(res, thingModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.THING_CREATE.BULK_ERROR, 'error');
        }),
      );
  }

  /** Update thing meta */
  async updateMetadata({
    metadata,
    typedMetadata,
    thingUuid,
    catalogUuid,
  }: {
    metadata: object;
    typedMetadata: object;
    thingUuid: string;
    catalogUuid: string;
  }) {
    // API does not accept thing format for typed metadata. Needs key/value pairs for string type only
    const typedFieldsToUpdate = typedMetadata
      ? Object.fromEntries(
          Object.entries(typedMetadata)
            .filter(([_key, val]) => val.type === 'string')
            .map(([key, val]) => [key, val.value]),
        )
      : undefined;

    return this.apiService
      .request({
        url: `/api/things/${thingUuid}/metadata`,
        method: 'PUT',
        data: {
          metadata,
          typedMetadata: typedFieldsToUpdate,
        },
      })
      .then((res) => {
        this.invalidate.things();
        this.invalidate.thingMetaFilters(catalogUuid);
        this.invalidate.shoppingCart();
        return formatAPIReturn(res, thingModel);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.COMMON.GENERIC, 'error');
        }),
      );
  }

  /** Get a list of transactions for thing */
  async getTransactions({ uuid }: { uuid: string }) {
    return this.apiService
      .request({
        url: encodeURI(`/api/transactions?thingUuids=${uuid}`),
        method: 'GET',
      })
      .then((res: DustArrayResponse) => formatAPIReturn(res, transactionModel))
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Attach files to things */
  async attachTypedFiles({
    thingUuids,
    files,
    fieldTypeUuid,
    suppressToast = false,
  }: {
    thingUuids: string[];
    files: File[];
    fieldTypeUuid?: string;
    suppressToast?: boolean;
  }) {
    const formData = new FormData();
    const body = removeNil({
      filters: { thingUuids },
      fieldTypeUuid,
    });

    formData.append(
      'json',
      new Blob([JSON.stringify(body)], { type: 'application/json' }),
    );
    files.forEach((file) => formData.append('files', file, file.name));

    return this.apiService
      .request({
        method: 'POST',
        url: '/api/things/files',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      .then((res: DustArrayResponse) => formatAPIReturn<FileReturn, any[]>(res))
      .catch(
        defaultErrorHandler(this.addToast, () => {
          if (!suppressToast) this.addToast(MESSAGES.FILE_FAILURE, 'error');
        }),
      )
      .finally(() => {
        this.invalidate.things();
        this.invalidate.shoppingCart();
      });
  }

  /** Update a file on a thing */
  async updateThingFile(params: {
    thingUuid: string;
    fileUuid: string;
    filename?: string;
    isPrimary?: boolean;
  }) {
    const { thingUuid, fileUuid, ...dataParams } = params;
    const args = new URLSearchParams();

    return this.apiService
      .request({
        method: 'PUT',
        url: `/api/things/${thingUuid}/files/${fileUuid}?${args.toString()}`,
        data: removeNil(dataParams),
      })
      .then((res) => formatAPIReturn(res))
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.FILE_UPDATE_FAILURE, 'error');
        }),
      )
      .finally(() => {
        this.invalidate.thing(thingUuid);
        this.invalidate.shoppingCart();
      });
  }

  /** Remove a file from a thing */
  async removeThingFile({
    thingUuid,
    fileUuid,
    suppressToast = false,
  }: {
    thingUuid: string;
    fileUuid: string;
    suppressToast?: boolean;
  }) {
    return this.apiService
      .request({
        method: 'DELETE',
        url: `/api/things/${thingUuid}/files/${fileUuid}`,
      })
      .then((res) => formatAPIReturn(res))
      .catch(
        defaultErrorHandler(this.addToast, () => {
          if (!suppressToast)
            this.addToast(MESSAGES.FILE_DELETE_FAILURE, 'error');
        }),
      )
      .finally(() => this.invalidate.thing(thingUuid));
  }

  /** Get a list of meta filters */
  async getMetadataFilters({ catalogUuid = '' }) {
    return this.apiService
      .request({
        method: 'GET',
        url: `/api/things/metadata-filters/${catalogUuid}`,
      })
      .then((res: DustSingleResponse) => {
        this.invalidate.thingMetaFilters(catalogUuid);
        return formatAPIReturn(res, thingMetaFilterModel);
      })
      .catch((err) => {
        this.addToast(MESSAGES.COMMON.FETCH_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Update children on a thing */
  async updateThingChildren({
    thingUuid,
    addUuids = [],
    removeUuids = [],
  }: {
    thingUuid: string;
    addUuids?: string[];
    removeUuids?: string[];
  }) {
    if (!(addUuids?.length >= 1) && !(removeUuids?.length >= 1)) {
      // guard against misuse
      throw new Error('Provide children uuids to either add or remove');
    }
    return this.apiService
      .request({
        method: 'PATCH',
        url: `/api/things/${thingUuid}/children`,
        data: {
          addUuids,
          removeUuids,
        },
      })
      .then((res) => {
        this.invalidate.thing(thingUuid);
        this.invalidate.shoppingCart();
        this.invalidate.things();
        return formatAPIReturn(res, thingModel);
      })
      .catch((err) => {
        this.addToast(MESSAGES.RELATIONSHIPS.UPDATE_FAILED, 'error');
        return formatAPIError(err);
      });
  }

  /** Export things to a csv */
  async exportThings(params: /* TODO: use: typedMetadataFilters, showCheckedOut */
  {
    catalogUuids?: string[];
    thingUuids?: string[];
    exportFields?: string[];
    exportAllMetadata?: boolean;
    metadataFilters?: object;
    typedMetadataFilters?: object;
    isBound?: boolean;
    showCheckedOut?: boolean;
    metadataText?: string;
  }) {
    return this.apiService
      .request({
        method: 'POST',
        url: '/api/things/export',
        data: removeNil({
          ...params,
          // TODO: use these 2 fields instead of not sending them
          typedMetadataFilters: undefined,
          showCheckedOut: undefined,
        }),
      })
      .then((res) => {
        const file = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });

        // Mock standard payload format
        return formatAPIReturn<{ file: File }, unknown>({
          ...res,
          data: {
            data: { file },
            meta: null,
          },
        });
      })
      .catch((err) => {
        const errResult = formatAPIError<{ message: string }>(err);
        if (errResult.data?.message) {
          this.addToast(errResult.data.message, 'error');
        }
        return errResult;
      });
  }

  async moveThing({
    thingUuid,
    catalogUuid,
    oldCatalogUuid,
    onError,
  }: {
    thingUuid: string;
    catalogUuid: string;
    oldCatalogUuid: string;
    onError: (
      error: ApiReturnError<{ message: string; conflict?: MoveConflictError }>,
    ) => void;
  }) {
    return this.apiService
      .request({
        method: 'POST',
        url: `/api/things/${thingUuid}/move`,
        data: {
          catalogUuid,
        },
      })
      .then((res) => {
        this.addToast(MESSAGES.THING_MOVE.SUCCESS, 'success');
        return formatAPIReturn(res, thingModel);
      })
      .catch((err) => {
        let error = formatAPIError<{ message: string }>(err);
        if (
          error.status === HTTP_RESPONSE_CODES.CONFLICT &&
          error.data?.message
        ) {
          try {
            error = {
              ...error,
              data: {
                ...error.data,
                conflict: JSON.parse(
                  error.data.message,
                )[0] as MoveConflictError,
              },
            } as ApiReturnError<{
              message: string;
              conflict: MoveConflictError;
            }>;
          } catch (e) {
            // Fallback happens below
          }
        }
        if (onError) {
          onError(error);
        } else if (err?.response?.status === 409) {
          this.addToast(MESSAGES.THING_MOVE.DUST_CONFLICT, 'error');
        } else {
          const message = error?.data?.message || MESSAGES.THING_MOVE.FAILURE;
          this.addToast(message, 'error');
        }
        return error;
      })
      .finally(() => {
        this.invalidate.thingMoveCreate(catalogUuid, oldCatalogUuid);
      });
  }

  /** Remove a tag from a thing */
  async unbindXTag({
    thingUuid,
    xtagUuid,
  }: {
    thingUuid: string;
    xtagUuid: string;
  }) {
    return this.apiService
      .request({
        method: 'POST',
        url: `/api/things/${thingUuid}/unbind/${xtagUuid}`,
      })
      .then((res) => formatAPIReturn(res))
      .catch(defaultErrorHandler(this.addToast))
      .finally(() => this.invalidate.thing(thingUuid));
  }
}
