import baseModel from '../baseModel';

export const thingTypeModel = (data: ThingType): ThingType => ({
  ...baseModel(data),
  titleField:
    data.fieldTypes.find((f) => f.uuid === data.titleFieldTypeUuid) ?? null,
  primaryImageField:
    data.fieldTypes.find((f) => f.uuid === data.primaryImageFieldTypeUuid) ??
    null,
  mediaUuids:
    (data.fieldTypes
      .filter((field) => field.value?.type === 'STATIC_MEDIA')
      .map((field) => field.value.value as string | unknown)
      .filter((val) => !!val) as string[]) ?? [],
  fieldTypes: data.fieldTypes.map((f) => ({
    ...f,
    isStatic: f.value?.type === 'STATIC_MEDIA',
  })),
});
