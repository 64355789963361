import { useState } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';

import { combineClass } from '@/components/Library/utility';

import styles from './EditTable.module.css';

function EditTableHeaderCell({
  field,
  setHeaderCell,
  // TODO use: onFocusCell,
  onSort,
  sortedField,
  style,
  hideCell = false,
}) {
  const [title, setTitle] = useState(String(field ?? ''));
  const [sortDir, setSortDir] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const handleBlur = () => {
    setIsFocused(false);

    // Set header will return false if validation fails
    // If validation fails reset the value
    const setValue = setHeaderCell(field, title.trim());
    if (!setValue) {
      setTitle(field);
    }
  };

  const handleSort = () => {
    onSort(field, sortDir);
    setSortDir(!sortDir);
  };

  const sortButtonClass = combineClass(
    styles.headerSortButton,
    sortedField === field && styles.headerSortButtonSelected,
  );

  return (
    <div
      style={style}
      className={combineClass(styles.headerCell, hideCell && styles.hideCell)}
    >
      <input
        onBlur={handleBlur}
        onChange={(evt) => setTitle(evt.target.value)}
        onFocus={() => setIsFocused(true)}
        style={{ paddingRight: onSort ? '0' : undefined }}
        type="text"
        value={title}
      />
      {!!onSort && !isFocused && (
        <div className={sortButtonClass}>
          <IconButton
            onClick={handleSort}
            sx={{ color: 'var(--text-contrast)' }}
          >
            {sortDir ? (
              <ArrowDownwardIcon fontSize="small" />
            ) : (
              <ArrowUpwardIcon fontSize="small" />
            )}
          </IconButton>
        </div>
      )}
    </div>
  );
}

EditTableHeaderCell.propTypes = {
  field: PropTypes.string,
  sortedField: PropTypes.string,
  setHeaderCell: PropTypes.func,
  // TODO use: onFocusCell: PropTypes.func,
  onSort: PropTypes.func,
  hideCell: PropTypes.bool,
};

export default EditTableHeaderCell;
