import { MouseEventHandler } from 'react';

import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type Props = {
  label: string;
  href?: string;
  onClick?: (e: any) => void;
};

/** Uses a react-router navigate on click */
export default function NavEntryMenuItem({ label, href = '', onClick }: Props) {
  const navigate = useNavigate();

  const handleClick: MouseEventHandler<Element> = (evt) => {
    // For alt clicks use default behaviour. Otherwise use SPA navigation
    if (!evt.ctrlKey && !evt.metaKey) {
      evt.preventDefault();
      navigate(href);
    }
  };
  return (
    <MenuItem
      component={onClick ? 'button' : 'a'}
      href={href}
      onClick={onClick ?? handleClick}
    >
      {label}
    </MenuItem>
  );
}
