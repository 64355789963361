import { Component } from 'react';

import ErrorBase from '@/components/Pages/ErrorPages/ErrorBase';

type Props = {
  children: React.ReactNode;
  path: string;
};

type State = {
  hasError: boolean;
  previousPath: string;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, previousPath: props.path };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.path !== state.previousPath) {
      return { hasError: false, previousPath: props.path };
    }
    return null;
  }

  componentDidCatch(error: any, errorInfo: any) {
    // we want to display the error in the console for troubleshooting
    console.error('errorInfo:', errorInfo);
    console.error(error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <ErrorBase
          title="Uh-oh"
          message="Something went wrong. Please try again or contact support."
        />
      );
    }

    return children;
  }
}

export default ErrorBoundary;
