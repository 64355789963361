import { MouseEvent, useState, SyntheticEvent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import useThingsData from '@/common/entities/things/useThingsData';
import useCheckout from '@/common/hooks/useCheckout';
import { ROUTES } from '@/common/routes';
import DustAccordion from '@/components/Library/DustAccordion';
import DustEventBlocker from '@/components/Library/DustEventBlocker';
import DustLinkButton from '@/components/Library/DustLinkButton';
import DustLoader from '@/components/Library/DustLoader';
import DustLoadingButton from '@/components/Library/DustLoadingButton';
import DustThingThumbnail from '@/components/Library/DustThingThumbnail';
import { combineClass } from '@/components/Library/utility';

import styles from './CatalogItems.module.css';

type Props = {
  thing: Thing;
  readOnly: boolean;
  shoppingCart: ShoppingCart;
};

function CatalogThing({ thing, readOnly = false, shoppingCart }: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [isRemoving, setIsRemoving] = useState(false);
  const { checkout } = useCheckout();
  const [isChildrenOpen, setIsChildrenOpen] = useState(false);

  const removeThing = async (evt: MouseEvent) => {
    evt.stopPropagation();
    setIsRemoving(true);
    await checkout.removeThings({
      things: [thing],
      onSuccess: () => setOpen(false),
    });
    setIsRemoving(false);
  };

  const handleNavigate = (evt: MouseEvent) => {
    evt.stopPropagation();
    navigate(ROUTES.THING(thing.uuid), { replace: false });
  };

  const handleToggleChildren = (e: SyntheticEvent, expanded: boolean) => {
    setIsChildrenOpen(expanded);
  };

  const REMOVE_LINKS = {
    NORMAL: (
      <DustLoadingButton isIcon loading={isRemoving} onClick={removeThing}>
        <CloseIcon fontSize="small" />
      </DustLoadingButton>
    ),
    READ_ONLY: null,
    CHECKED_OUT: (
      <DustLinkButton
        loading={isRemoving}
        onClick={removeThing}
        sx={{ color: 'var(--darker-red)' }}
      >
        Checked out.
      </DustLinkButton>
    ),
  };

  const removeLinkIndex =
    (readOnly && 'READ_ONLY') ||
    (thing.isCheckedOut && 'CHECKED_OUT') ||
    'NORMAL';

  const childrenUuids = thing.relationships.children?.map((t) => t.uuid) ?? [];

  const { things: children, isLoading: isChildrenLoading } = useThingsData({
    fixedParams: {
      thingUuids: childrenUuids,
      fields: ['mediaLinks', 'children'],
      sort: ['title'],
    },
    fetchAll: true,
    perPage: 100,
    enabled: childrenUuids.length > 0 && isChildrenOpen,
  });

  const accordionItems = [
    {
      name: thing.uuid,
      summary: (
        <div className={styles.thingChildrenHeader}>
          {childrenUuids.length === 1
            ? '1 Child'
            : `${childrenUuids.length} children`}
        </div>
      ),
      content: isChildrenLoading ? (
        <DustLoader size="icon" />
      ) : (
        children.map((t) => (
          <CatalogThing
            thing={t}
            readOnly
            shoppingCart={shoppingCart}
            key={t.uuid}
          />
        ))
      ),
    },
  ];

  // TODO: DICE3-740
  return open ? (
    <DustEventBlocker className={styles.catalogThingWrapper}>
      <div
        className={combineClass(
          styles.catalogThing,
          thing.isCheckedOut && styles.checkedOutThing,
        )}
      >
        <span className="flex-row items-center gap-05">
          <DustThingThumbnail thing={thing} className={styles.thumbnail} />
          <Tooltip enterDelay={700} title={thing.title}>
            <DustLinkButton
              onClick={handleNavigate}
              sx={{
                maxWidth: 265,
              }}
            >
              {thing.title}
            </DustLinkButton>
          </Tooltip>
        </span>
        {REMOVE_LINKS[removeLinkIndex]}
      </div>
      {childrenUuids.length > 0 && (
        <DustAccordion
          items={accordionItems}
          accordionProps={{
            TransitionProps: { unmountOnExit: true },
            onChange: handleToggleChildren,
          }}
          itemSx={{
            padding: 0,
            borderLeft: '4px solid #1C83F9',
            marginLeft: '4px',
            '& .MuiAccordionDetails-root': {
              borderColor: 'var(--dark-blue)',
              '& .MuiAccordionDetails-root': {
                borderColor: '#212F3F',
                '& .MuiAccordionDetails-root': {
                  borderColor: 'var(--medium-blue)',
                  '& .MuiAccordionDetails-root': {
                    borderColor: '#9F38EB',
                  },
                },
              },
            },
          }}
          summaryContentSx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 0,
            margin: 0,
            lineHeight: 0,
            height: '5px',
          }}
          summarySx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            borderBottom: '1px solid var(--light-grey)',
            padding: '0 .5rem',
            top: 0,
            backgroundColor: 'var(--white)',
            minHeight: '30px',
            '&:last-child': {
              borderBottom: 'none',
            },
          }}
        />
      )}
    </DustEventBlocker>
  ) : null;
}

export default CatalogThing;
