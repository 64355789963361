import { useEffect, useState, useCallback } from 'react';

type HookProps = {
  callback?: ResizeObserverCallback;
};

/** Watch the resize of an html element. Returns a ref that is attached to the element to observe */
export default function useResize(props: HookProps = {}) {
  const callback = props?.callback;

  const [observer, setObserver] = useState<ResizeObserver | null>(null);
  const [element, setElem] = useState<Element | null>(null);
  const [width, setWidth] = useState<number>(NaN);
  const [height, setHeight] = useState<number>(NaN);

  // Create the observer whenever a new callback is provided
  useEffect(() => {
    const setSize: ResizeObserverCallback = (entries) => {
      const content = entries[0].contentRect;
      setWidth(content.width);
      setHeight(content.height);
    };
    const callbackObserver = new ResizeObserver(callback ?? setSize);
    setObserver(callbackObserver);

    return () => callbackObserver.disconnect();
  }, [callback]);

  const resizeRef = useCallback((elem: Element | null) => {
    setElem(elem);
  }, []);

  useEffect(() => {
    // Observe after the element or observer changes
    if (observer && element) {
      observer.observe(element);
    }

    // Unobserve before the element or observer changes
    return () => {
      if (element) {
        observer?.unobserve(element);
      }
    };
  }, [element, observer]);

  return {
    resizeRef,
    width,
    height,
  };
}
