import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Chip } from '@mui/material';

import styles from './CheckIn.module.css';

type ResolutionStatusProps = {
  status: 'error' | 'success';
  label: string;
};

export default function ResolutionStatus({
  status,
  label,
}: ResolutionStatusProps) {
  return (
    <span className={styles.resolutionStatus}>
      <Chip
        variant="outlined"
        color={status}
        icon={status === 'success' ? <CheckCircleOutlined /> : <InfoOutlined />}
        label={label}
        sx={{ border: 'none' }}
      />
    </span>
  );
}
