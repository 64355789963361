import DustModal from '@/components/Library/DustModal';
import DustProgressLoader from '@/components/Library/DustProgressLoader';

type Props = { open: boolean };

export default function ProgressModal({ open }: Props) {
  return (
    <DustModal open={open}>
      <div className="flex-col items-center gap-1">
        <DustProgressLoader height={50} width={50} alt="loading indicator" />

        {/* <DustProgressLoader className="mb-2" height={50} width={50} /> */}
        <h2 className="h2">Please Wait</h2>
        <p className="text-center mb-1">
          This process may take a few minutes. To avoid interruptions please do
          not navigate away from this screen.
        </p>
      </div>
    </DustModal>
  );
}
