import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

type HookProps = { thingTypeUuid: string; enabled: boolean };

export default function useThingTypeData({
  thingTypeUuid,
  enabled = true,
}: HookProps) {
  const { thingTypeApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.THING_TYPE(thingTypeUuid),
    () => thingTypeApi.get({ uuid: thingTypeUuid }),
    { enabled },
  );

  return {
    thingType: data && !data.error ? data.data : undefined,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
