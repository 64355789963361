import React from 'react';

import {
  InputLabel,
  FormControl,
  Select,
  FormHelperText,
  SelectProps,
} from '@mui/material';

export type DustSelectProps<T> = {
  label: string;
  error?: boolean;
  helperText?: string;
} & Omit<SelectProps<T>, 'label'>;

export default function DustSelect<T>({
  label,
  error = false,
  helperText = '',
  ...props
}: DustSelectProps<T>) {
  const labelStyle = {
    top: '-7.5px',
    '&[data-shrink="true"]': {
      top: 0,
    },
  };

  const labelId = React.useId();

  return (
    <FormControl sx={{ width: '100%', margin: 0 }} variant="outlined">
      <InputLabel error={error} sx={labelStyle} id={labelId}>
        {label}
      </InputLabel>
      <Select
        {...props}
        labelId={labelId}
        error={error}
        label={label}
        MenuProps={{
          ...props.MenuProps,
          sx: {
            fontSize: '0.8rem',
            ...props.MenuProps?.sx,
          },
        }}
        SelectDisplayProps={{
          ...props.SelectDisplayProps,
          style: {
            minHeight: '1.5rem',
            padding: '0.5rem 2rem 0.5rem 1rem',
            fontSize: '0.8rem',
            ...props.SelectDisplayProps?.style,
          },
        }}
      />
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}
