import { combineClass } from '@/components/Library/utility';

import styles from './Thing.module.css';

type Props = {
  title: string;
  children: React.ReactNode;
};

export default function ThingSummaryCard({ title, children }: Props) {
  return (
    <div className={styles.summaryCard}>
      <h4 className={combineClass('h4', styles.summaryTitle)}>{title}</h4>
      {children}
    </div>
  );
}
