import { ReactNode } from 'react';

import {
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  Tooltip,
  TooltipProps,
} from '@mui/material';

type Props = {
  loading: boolean;
  isIcon?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'> | null;
  children?: ReactNode;
} & ButtonProps;

export default function DustLoadingButton({
  loading,
  isIcon = false,
  tooltipProps = null,
  children,
  ...props
}: Props) {
  const loader = (
    <div
      style={{
        display: 'flex',
        padding: '2px',
        marginRight: props.size === 'small' || isIcon ? '0' : '5px',
      }}
    >
      <CircularProgress
        color="inherit"
        size={props.size === 'small' ? '15px' : '20px'}
      />
    </div>
  );

  const buttonJsx = isIcon ? (
    <IconButton {...props}>{loading ? loader : children}</IconButton>
  ) : (
    <Button variant="contained" {...props} endIcon={loading ? loader : null}>
      {children}
    </Button>
  );

  return tooltipProps ? (
    <Tooltip arrow enterDelay={150} {...tooltipProps} color="error">
      {buttonJsx}
    </Tooltip>
  ) : (
    buttonJsx
  );
}
