import {
  format as formatFn,
  formatDistanceToNow,
  isBefore,
  sub,
  min,
} from 'date-fns';

// ---------------------------------------------------------------------------
// DATE FORMATTING UTILITIES
// TODO: Needs unit testing coverage

type DateFormat =
  | 'mm/dd/yyyy'
  | 'mm/dd/yy'
  | 'dd/mm/yyyy'
  | 'mmmm yyyy'
  | 'yyyy-mm'
  | 'll'
  | 'mm/dd/yy hh:mm'
  | 'iso';

/**
 *
 * @param dateParam to return formatted string for
 * @param format    format specification
 */
export function formatDate(
  dateParam: Date | string,
  format: DateFormat = 'mm/dd/yyyy',
): string {
  const date = new Date(dateParam);

  if (date.toString().toLocaleLowerCase() === 'invalid date') {
    console.error('Invalid Date Provided. ');
    return '';
  }

  switch (format) {
    case 'mm/dd/yyyy': {
      return new Intl.DateTimeFormat('en-US').format(date);
    }
    case 'mm/dd/yy': {
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      }).format(date);
    }
    case 'dd/mm/yyyy': {
      return new Intl.DateTimeFormat('en-DE').format(date);
    }
    case 'mmmm yyyy': {
      return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        year: 'numeric',
      }).format(date);
    }
    case 'yyyy-mm': {
      const month = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
      }).format(date);
      const year = date.getFullYear();
      return `${year}-${month}`;
    }
    case 'll': {
      return new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(date);
    }
    case 'mm/dd/yy hh:mm': {
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        month: '2-digit',
        year: '2-digit',
      }).format(date);
    }
    default: {
      return date.toISOString();
    }
  }
}

/**
 * Return standard US time format
 *
 * @param dateParam   date to get time for
 * @param showSeconds options to add seconds or not
 */
export function formatTime(
  dateParam: Date | string,
  showSeconds = false,
): string {
  const date = new Date(dateParam);
  return showSeconds
    ? date.toLocaleTimeString('en-us')
    : date.toLocaleTimeString('en-us', {
        hour: 'numeric',
        minute: '2-digit',
      });
}

/**
 * Check if date is valid
 *
 * @param dateParam check if date is valid
 */
export function isValidDate(dateParam: Date | string): boolean {
  const date = new Date(dateParam);
  return !Number.isNaN(date.getTime());
}

// ---------------------------------------------------------------------------
// DATE MANIPULATION UTILITIES

/**
 *
 * @param dateParam to remove months from
 * @param numMonths number of months to subtract
 */
export function subtractMonths(
  dateParam: Date | string,
  numMonths: number,
): Date {
  const date = new Date(dateParam);
  date.setMonth(date.getMonth() - numMonths);
  return date;
}

/**
 * Check if the first date is after the second
 *
 * @param date1 date to check if its after
 * @param date2 date to compare against
 */
export function dateIsAfter(
  date1: Date | string,
  date2: Date | string,
): boolean {
  return new Date(date1).getTime() > new Date(date2).getTime();
}

/**
 * Check if the first date is before the second
 *
 * @param date1 date to check if its after
 * @param date2 date to compare against
 */
export function dateIsBefore(
  date1: Date | string,
  date2: Date | string,
): boolean {
  return new Date(date1).getTime() < new Date(date2).getTime();
}

/**
 * Convert a database date from UTC to local time
 */
export function localDate(date: Date | string): Date {
  const d = new Date(date);
  return new Date(
    Date.UTC(
      d.getFullYear(),
      d.getMonth(),
      d.getDate(),
      d.getHours(),
      d.getMinutes(),
      d.getSeconds(),
    ),
  );
}

type FormatDateOptions = {
  showTime?: boolean;
  distanceFromNow?: boolean;
  allowRelativeFutureTime?: boolean;
};

export function formatDateForDisplay(
  dateParam: Date | string,
  options: FormatDateOptions = {},
) {
  if (!dateParam) return null;

  const {
    showTime = true,
    distanceFromNow = true,
    allowRelativeFutureTime = false,
  } = options;
  const date = new Date(
    typeof dateParam === 'string' ? dateParam.replace(' ', 'T') : dateParam,
  );
  return isBefore(date, sub(Date.now(), { days: 1 })) || !distanceFromNow
    ? formatFn(date, showTime ? 'Pp' : 'P')
    : formatDistanceToNow(
        allowRelativeFutureTime ? date : min([date, Date.now()]),
        { addSuffix: true },
      );
}
