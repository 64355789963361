export const FILE_TYPE_OPTIONS = {
  IMAGE: 'image',
  FILE: 'file',
  CSV: 'csv',
  IMPORT: 'import',
  ALL: 'all',
} as const;

export const ACCEPTED_MIME_TYPES: MapFromValues<
  typeof FILE_TYPE_OPTIONS,
  string
> = {
  [FILE_TYPE_OPTIONS.IMAGE]:
    'image/png, image/jpg, image/jpeg, image/gif, image/webp, image/svg, image/svg+xml, image/bmp',
  [FILE_TYPE_OPTIONS.FILE]: 'application/pdf, text/csv, text/plain',
  [FILE_TYPE_OPTIONS.CSV]: 'text/csv',
  [FILE_TYPE_OPTIONS.IMPORT]: 'application/zip',
  [FILE_TYPE_OPTIONS.ALL]:
    'application/pdf, text/csv, text/plain, image/png, image/jpg, image/jpeg, image/gif, image/webp,   image/svg, image/svg+xml, image/bmp',
} as const;

export const FILE_TYPE_ICONS: MapFromValues<typeof FILE_TYPE_OPTIONS, string> =
  {
    [FILE_TYPE_OPTIONS.IMAGE]: 'image',
    [FILE_TYPE_OPTIONS.FILE]: 'file',
    [FILE_TYPE_OPTIONS.CSV]: 'file',
    [FILE_TYPE_OPTIONS.IMPORT]: 'zip',
    [FILE_TYPE_OPTIONS.ALL]: 'file',
  } as const;

export const FILE_EXTENSIONS_FROM_MIME: { [x: string]: string | undefined } = {
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'image/gif': 'gif',
  'image/webp': 'webp',
  'image/svg': 'svg',
  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'image/bmp': 'bmp',
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'text/csv': 'csv',
  'text/plain': 'txt',
} as const;

export const CONTACT_SUPPORT_EMAIL = 'success@dustidentity.com';
