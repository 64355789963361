import * as yup from 'yup';

export const addUserSchema = yup.object({
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  email: yup
    .string()
    .email('Enter a valid email address.')
    .required('Email is required.'),
  addUserGroups: yup
    .array()
    .min(1, 'You must select a user group for this user.'),
});

export const editProfileSchema = yup.object({
  firstName: yup.string().required('First name is required.'),
  lastName: yup.string().required('Last name is required.'),
  password: yup
    .string()
    .min(15, 'Password is too short, should be 15 chars minimum.'),
  verifyPassword: yup
    .string()
    .test(
      'passwords-match',
      'Passwords must match',
      (value, { parent }) => value === parent.password,
    ),
});
