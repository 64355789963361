import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const STALE_TIME = 10 * 60 * 1000;

export default function useOrgsData({ enabled }: { enabled?: boolean } = {}) {
  const { orgApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess, isError } = useQuery(
    QUERY_KEYS.ORGS,
    () => orgApi.getOrgs(),
    { enabled, staleTime: STALE_TIME },
  );

  return {
    data: !data?.error ? data?.data : undefined,
    isLoading,
    isSuccess,
    isError: !!data?.error || isError,
  };
}
