import { useEffect, useState } from 'react';

import { Button, ButtonGroup, Switch } from '@mui/material';

import useFiltersData from '@/common/entities/filters/useFiltersData';
import { alphaSort } from '@/common/utilities/sort';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustLoader from '@/components/Library/DustLoader';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import styles from '@/components/Pages/Admin/UserGroups/Groups.module.css';
import useRequest from '@/services/requests/useRequest';

function GroupFiltersTab({ groupUuid }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [viewSelectedOnly, setViewSelectedOnly] = useState(false);

  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [search, setSearch] = useState('');
  const { groupsApi } = useRequest();

  const handleChange = (event) => {
    setIsEnabled(event.target.checked);
  };

  const { isSuccess, isLoading, filters } = useFiltersData({
    groupUuid,
  });

  const catalogs = filters?.catalogs ?? [];
  const initialSelected = filters?.bounds?.uuids ?? [];
  const searchRegex = new RegExp(search, 'i');
  const filteredCatalogs = catalogs
    .filter((catalog) => searchRegex.test(catalog.name.toString()))
    .sort((a, b) => alphaSort(a, b, 'name'));

  const catalogCount = !isEnabled ? catalogs.length : selectedCatalogs.length;

  useEffect(() => {
    // Initialize selected from API
    if (isSuccess) {
      setSelectedCatalogs(filters.bounds?.uuids ?? []);
      setIsEnabled(Array.isArray(filters.bounds.uuids));
    }
  }, [isSuccess, filters]);

  const handleCatalogSelect = (checked, catalogUuid) => {
    setSelectedCatalogs((prev) =>
      checked
        ? [...prev, catalogUuid]
        : prev.filter((selectedId) => selectedId !== catalogUuid),
    );
  };

  const selectAllCatalogs = () => {
    const newCatalogs = filteredCatalogs
      .map((catalog) => catalog.uuid)
      .filter((catalog) => !selectedCatalogs.includes(catalog));
    setSelectedCatalogs(selectedCatalogs.concat(newCatalogs));
  };

  const deselectAllCatalogs = () => {
    const filteredArray = filteredCatalogs.map((catalog) => catalog.uuid);
    const newCatalogs = selectedCatalogs.filter(
      (catalog) => !filteredArray.includes(catalog),
    );
    setSelectedCatalogs(newCatalogs);
  };

  const resetCatalogFilters = () => setSelectedCatalogs(initialSelected);

  const submitCatalogFilters = async () => {
    setIsSubmitting(true);
    const filtersToSend = isEnabled ? selectedCatalogs : null;

    await groupsApi.updateGroupFilters(groupUuid, { uuids: filtersToSend });
    setIsSubmitting(false);
  };

  return isSuccess ? (
    <div className={styles.tabBox}>
      <div className={styles.filterSwitch}>
        <Switch
          checked={isEnabled}
          inputProps={{ 'aria-label': 'controlled' }}
          onChange={handleChange}
        />
        <div>
          <p>
            Catalog Filter{' '}
            <span className={styles.textBold}>
              {isEnabled ? 'Enabled' : 'Disabled'}
            </span>
          </p>
          <p>
            {!isEnabled
              ? 'Can access all catalogs. Can automatically access newly created catalogs.'
              : `This group has access to ${catalogCount} catalogs. Cannot automatically access newly created catalogs.`}
          </p>
        </div>
      </div>

      {isEnabled ? (
        <>
          <div className={styles.filterControlsRow}>
            <DustSearchInput
              autoComplete="groupPolicyList"
              setValue={setSearch}
              aria-label="Search for a particular policy"
            />
            <div className="flex-1" />
            <DustCheckbox
              checked={viewSelectedOnly}
              label="Only View Selected"
              onChange={(checked) => setViewSelectedOnly(checked)}
            />
            <ButtonGroup aria-label="outlined button group" variant="contained">
              <Button onClick={selectAllCatalogs}>SELECT ALL</Button>
              <Button onClick={deselectAllCatalogs}>DESELECT ALL</Button>
            </ButtonGroup>
          </div>
          <div className={styles.catalogCheckboxes}>
            {filteredCatalogs.length > 0 ? (
              filteredCatalogs.map((catalog) =>
                !selectedCatalogs?.includes(catalog.uuid) &&
                viewSelectedOnly ? (
                  ''
                ) : (
                  <DustCheckbox
                    checked={selectedCatalogs?.includes(catalog.uuid)}
                    key={catalog.uuid}
                    label={catalog.name}
                    onChange={(checked) =>
                      handleCatalogSelect(checked, catalog.uuid)
                    }
                  />
                ),
              )
            ) : (
              <h2>Sorry, we didn’t find any results.</h2>
            )}
          </div>
        </>
      ) : (
        <div className="flex-1" />
      )}
      <DustStepperFooter
        cancelLabel="Discard Changes"
        disabled={isLoading || isSubmitting}
        loading={isSubmitting}
        onCancel={resetCatalogFilters}
        onSubmit={submitCatalogFilters}
        showPrevious={false}
        submitLabel="Save"
      />
    </div>
  ) : (
    <div className="flex-row items-center justify-center min-h-full">
      <DustLoader size="xlarge" />
    </div>
  );
}

export default GroupFiltersTab;
