import { useCallback } from 'react';

import { useParams, useNavigate } from 'react-router-dom';

import useThingData from '@/common/entities/things/useThingData';
import { ROUTES } from '@/common/routes';
import EditChildrenTable from '@/components/Composed/EditChildRelationships/EditChildrenTable';
import DustLoader from '@/components/Library/DustLoader';

export default function ThingEditChildren() {
  const navigate = useNavigate();
  const { thingId: thingUuid } = useParams();
  if (!thingUuid) throw new Error('Expected thing id in route');
  const { thing, isLoading, isError } = useThingData({
    thingUuid,
  });

  if (isError) throw new Error('Failed to load Thing');

  const handleNavToThingPage = useCallback(() => {
    navigate(ROUTES.THING(thingUuid));
  }, [navigate, thingUuid]);

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (isLoading || !thing /* for types */) {
    return <DustLoader size="large" />;
  }

  return (
    <EditChildrenTable
      thingUuid={thingUuid}
      catalogUuid={thing.catalogUuid}
      onNavigateToThingPage={handleNavToThingPage}
      onNavigateBack={handleNavigateBack}
    />
  );
}
