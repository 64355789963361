import { forwardRef, useRef, useImperativeHandle, Ref } from 'react';

import { useNavigate } from 'react-router-dom';

import { knownAuthSearchParamKeys } from './services/user-oidc';

export type SigninNavReplaceRef = {
  removeSigninParams(): void;
};

// Disabling lint - this type is recommended by react-typescript-cheatsheet when using useImperativeHandle with no props
// eslint-disable-next-line @typescript-eslint/ban-types
type NoProps = {};

// ESLint: we want a function name attached to the contents of forwardRef
/* eslint-disable prefer-arrow-callback */

/**
 * This component's purpose is to avoid a conflict between BrowserRouter and
 * window.history.replace(...), and allows us to replace the OIDC signin query
 * params in a way that doesn't get ignored or overwritten by React Router.
 *
 * Without this, a symptom seen during dev testing was that
 * window.history.replace(...) would be called, then shortly afterward the url
 * would revert to include the removed state/session_state/code search params.
 * If a delay of 7 seconds was added in the onSigninCallback, the url would end
 * up in a correct state.
 *
 * Using React Router's navigate to do the history redirect avoids the conflict
 * and properly performs the intent of onSigninCallback using React Router.
 */
export default forwardRef(function SigninNavReplaceInner(
  _props: NoProps,
  ref: Ref<SigninNavReplaceRef>,
) {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  navigateRef.current = navigate;
  useImperativeHandle(ref, () => ({
    removeSigninParams() {
      const url = new URL(window.location.href);
      const newParams = new URLSearchParams(url.searchParams);
      knownAuthSearchParamKeys.forEach((key) => newParams.delete(key));
      const newParamStr = newParams.toString();
      const newPath = url.pathname + newParamStr ? `?${newParamStr}` : '';

      navigateRef.current(newPath, { replace: true });
    },
  }));

  return null;
});
