import { Code, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

import {
  Resolution,
  ResolutionObject,
  RESOLUTIONS,
} from '@/common/entities/exportBatches/typedefs';
import DustThingThumbnail from '@/components/Library/DustThingThumbnail';
import { combineClass } from '@/components/Library/utility';

import styles from './CheckIn.module.css';
import ResolutionStatus from './ResolutionStatus';
import { getConflictMessage } from './utility';

type Props = {
  existingCatalogNameLookup: { [k: string]: string };
  existingThing?: Thing;
  importThing: Thing;
  importCatalogName: string;
  resolution: ResolutionObject;
  onResolution: (resolution: Resolution) => void;
  isImportedThingSelected: boolean;
  isServerThingSelected: boolean;
  nothingSelected: boolean;
};

export default function ResolutionRoot({
  existingCatalogNameLookup,
  existingThing,
  importThing,
  importCatalogName,
  resolution,
  onResolution,
  isImportedThingSelected,
  isServerThingSelected,
  nothingSelected,
}: Props) {
  const otherResolution = isImportedThingSelected
    ? RESOLUTIONS.USE_SERVER_THING
    : RESOLUTIONS.USE_IMPORTED_THING;

  const toggleResolution = (buttonResolution: Resolution) =>
    onResolution(
      buttonResolution === resolution.resolution
        ? RESOLUTIONS.NO_RESOLUTION
        : buttonResolution,
    );

  const Icon =
    (isImportedThingSelected && ChevronRight) ||
    (isServerThingSelected && ChevronLeft) ||
    Code;

  return (
    <>
      <div
        className={combineClass(
          styles.thingCell,
          styles.rootThing,
          isServerThingSelected && styles.selectedCell,
          isImportedThingSelected && styles.unselectedCell,
          nothingSelected && styles.noSelectionCell,
        )}
      >
        {existingThing && (
          <>
            <DustThingThumbnail
              thing={existingThing}
              className={styles.thingImage}
            />
            <div className="flex-col flex-1">
              <h4 className={styles.thingTitle}>{existingThing.title}</h4>
              <div className={styles.catalogName}>
                Catalog: {existingCatalogNameLookup[existingThing.catalogUuid]}
              </div>
            </div>
          </>
        )}
        <ResolutionStatus
          status="error"
          label={getConflictMessage(resolution.conflict)}
        />
        <Button
          className={styles.button}
          onClick={() => toggleResolution(RESOLUTIONS.USE_SERVER_THING)}
          variant="contained"
        >
          {isServerThingSelected ? 'Unselect' : 'Use This'}
        </Button>
      </div>
      <div className={combineClass(styles.middleSwitch, styles.rootThing)}>
        <IconButton onClick={() => onResolution(otherResolution)}>
          <Icon fontSize="large" />
        </IconButton>
      </div>
      <div
        className={combineClass(
          styles.thingCell,
          styles.rootThing,
          isImportedThingSelected && styles.selectedCell,
          isServerThingSelected && styles.unselectedCell,
          nothingSelected && styles.noSelectionCell,
        )}
      >
        <div className="flex-col flex-1 pl-1">
          <h4 className={styles.thingTitle}>{importThing.title}</h4>
          <div className={styles.catalogName}>
            Destination Catalog: {importCatalogName}
          </div>
        </div>
        <Button
          onClick={() => toggleResolution(RESOLUTIONS.USE_IMPORTED_THING)}
          variant="contained"
          className={styles.button}
        >
          {isImportedThingSelected ? 'Unselect' : 'Use This'}
        </Button>
      </div>
    </>
  );
}
