import { useState, useRef } from 'react';

import { ArrowDropDown } from '@mui/icons-material';
import { Button, ButtonGroup, Menu, MenuItem, Divider } from '@mui/material';

import DustLoader from '@/components/Library/DustLoader';

export type Action = {
  name: string;
  action?: () => void | Promise<boolean> | boolean;
  disabled?: boolean;
  key?: string;
  isDivider?: boolean;
  isLoading?: boolean;
};

type Props = {
  actionList: Action[];
};

export default function DustActionButton({ actionList = [] }: Props) {
  const [menuEl, setMenuEl] = useState(null);
  const anchor = useRef(null);

  const [loadingIdx, setLoadingIdx] = useState(-1);

  const handleClick = async (action: Action['action'], idx: number) => {
    setLoadingIdx(idx);
    const success = await action?.();
    setLoadingIdx(-1);

    if (success) {
      setMenuEl(null);
    }
  };

  return (
    <ButtonGroup>
      <Button
        onClick={() => setMenuEl(anchor.current)}
        ref={anchor}
        variant="contained"
      >
        Action
      </Button>
      <Button
        onClick={() => setMenuEl(anchor.current)}
        size="small"
        sx={{ borderLeft: '.1rem solid var(--color-text-contrast)' }}
        variant="contained"
      >
        <ArrowDropDown />
      </Button>
      <Menu
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => setMenuEl(null)}
        open={!!menuEl}
      >
        {actionList.map(({ action, name, key, disabled, isDivider }, idx) =>
          isDivider ? (
            <Divider key={key ?? name} textAlign="left">
              {name}
            </Divider>
          ) : (
            <MenuItem
              disabled={disabled}
              key={key ?? name}
              onClick={() => handleClick(action, idx)}
              sx={{ minWidth: '8.125rem' }}
            >
              {name}
              {idx === loadingIdx && (
                <DustLoader size="small" sx={{ marginLeft: '.5rem' }} />
              )}
            </MenuItem>
          ),
        )}
      </Menu>
    </ButtonGroup>
  );
}
