import { useState, useRef, useEffect } from 'react';

import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ButtonGroup,
} from '@mui/material';
import PropTypes from 'prop-types';

import DustCheckbox from '@/components/Library/DustCheckbox';
import DustModal from '@/components/Library/DustModal';

import styles from './ThingDetailsMulti.module.css';

function EditTableButton({
  headers,
  onRemoveHeader,
  onAddHeader,
  restrictedFields,
}) {
  const [menuEl, setMenuEl] = useState(null);
  const [subMenuEl, setSubMenuEl] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!menuEl) {
      setSubMenuEl(null);
    }
  }, [menuEl]);

  const anchor = useRef(null);
  const subAnchor = useRef(null);

  const [removeList, setRemoveList] = useState([]);
  const removeChange = (checked, entry) => {
    if (checked) {
      setRemoveList([...removeList, entry]);
    } else {
      setRemoveList(removeList.filter((e) => e !== entry));
    }
  };
  const displayHeaders = headers.filter(
    (h) =>
      !restrictedFields.map((f) => f.toLowerCase()).includes(h.toLowerCase()),
  );

  const handleRemove = () => {
    onRemoveHeader(removeList);
    setShowDeleteModal(false);
    setSubMenuEl(null);
    setMenuEl(null);
  };

  const handleRemoveCancel = () => {
    setRemoveList([]);
    setMenuEl(null);
  };
  return (
    <>
      <ButtonGroup disableElevation>
        <Button
          size="small"
          ref={anchor}
          variant="contained"
          onClick={() => setMenuEl(anchor.current)}
          sx={{ whiteSpace: 'nowrap', height: '2.5rem' }}
        >
          Edit Table
        </Button>
        <Button
          onClick={() => setMenuEl(anchor.current)}
          size="small"
          sx={{
            borderLeft: '1px solid var(--contrast-text-color)',
            padding: '.25rem',
            height: '2.5rem',
          }}
          variant="contained"
        >
          <ExpandMoreIcon />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={menuEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        MenuListProps={
          {
            //   'aria-labelledby': ariaLabelledBy,
          }
        }
        onClose={() => setMenuEl(undefined)}
        open={!!menuEl}
      >
        <MenuItem onClick={onAddHeader}>Add Column</MenuItem>
        {displayHeaders.length > 0 && (
          <MenuItem
            onClick={(evt) => {
              evt.stopPropagation();
              setSubMenuEl(subAnchor.current);
            }}
            ref={subAnchor}
          >
            Remove Column
            <ListItemIcon>
              <ArrowForwardIosOutlinedIcon
                fontSize="small"
                sx={{ marginLeft: 'auto' }}
              />
            </ListItemIcon>
          </MenuItem>
        )}
        <Menu
          anchorEl={subMenuEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setSubMenuEl(undefined)}
          open={!!subMenuEl}
          sx={{
            '& .MuiList-root': {
              padding: '.5rem 0 0 0',
            },
          }}
        >
          {displayHeaders.map((entry) => (
            <DustCheckbox
              checked={removeList.includes(entry)}
              key={entry}
              label={entry}
              onChange={(checked) => removeChange(checked, entry)}
            />
          ))}
          <div className={styles.colEditFooter}>
            <Button
              onClick={handleRemoveCancel}
              size="small"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={removeList.length < 1}
              onClick={() => setShowDeleteModal(true)}
              size="small"
              variant="contained"
            >
              Delete
            </Button>
            <DustModal
              footerProps={{
                submitLabel: 'Delete',
                onCancel: () => setShowDeleteModal(false),
                onSubmit: handleRemove,
              }}
              onClose={() => setShowDeleteModal(false)}
              open={showDeleteModal}
              title="Remove Columns"
            >
              <p>
                Are you sure you want to delete the selected columns? You
                can&apos;t undo this action.
              </p>
            </DustModal>
          </div>
        </Menu>
      </Menu>
    </>
  );
}

EditTableButton.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  onRemoveHeader: PropTypes.func,
  onAddHeader: PropTypes.func,
  restrictedFields: PropTypes.arrayOf(PropTypes.string),
};

export default EditTableButton;
