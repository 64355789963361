import useToasts from '@/common/hooks/useToasts';

import Toast from './Toast';
import styles from './Toast.module.css';

export const TOAST_LEVELS = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

function ToastBar() {
  const { toasts, removeToast } = useToasts();

  return (
    <div className={styles.toastBar}>
      {toasts.map((t) => (
        <Toast key={t.id} onDelete={() => removeToast(t.id)} toast={t} />
      ))}
    </div>
  );
}

export default ToastBar;
