import { useMemo } from 'react';

import useCatalogsData from '@/common/entities/catalogs/useCatalogsData';
import DustAccordion from '@/components/Library/DustAccordion';

import CatalogSummary from './CatalogItems/CatalogSummary';
import CatalogThings from './CatalogItems/CatalogThings';

type CheckoutItemListProps = {
  isReview: boolean;
  things: ShoppingCartThings;
  shoppingCart: ShoppingCart;
};

export default function CheckoutItemList({
  isReview = false,
  things,
  shoppingCart,
}: CheckoutItemListProps) {
  const { catalogs, isLoading } = useCatalogsData({
    fetchAll: true,
    perPage: 100,
  });

  const filteredCatalogs = useMemo(
    () => catalogs.filter((c) => things.catalogUuids.includes(c.uuid)),
    [catalogs, things.catalogUuids],
  );

  const accordionItems = filteredCatalogs.map((catalog) => ({
    name: catalog.uuid,
    summary: (
      <CatalogSummary catalog={catalog} readOnly={isReview} things={things} />
    ),
    content: (
      <CatalogThings
        catalog={catalog}
        readOnly={isReview}
        things={things}
        shoppingCart={shoppingCart}
      />
    ),
  }));
  return (
    <div className="flex-1 min-h-0 scroll-y">
      {!isReview && (
        <p className="text-xs" style={{ margin: '0 1rem 1rem' }}>
          The following Things will be assigned a Checked Out status and
          included in the export file. Things will be read-only on this device
          once complete
        </p>
      )}
      <DustAccordion
        accordionProps={{
          TransitionProps: { unmountOnExit: true },
          defaultExpanded: filteredCatalogs.length === 1,
        }}
        items={accordionItems}
        itemSx={{
          padding: 0,
        }}
        summaryContentSx={{
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        summarySx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',

          borderBottom: '1px solid var(--light-grey)',
          padding: '0 .5rem',
          position: 'sticky',
          top: 0,
          backgroundColor: 'var(--white)',
          zIndex: 1, // necessary to overlap things list
        }}
        loading={isLoading}
      />
    </div>
  );
}
