import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

type Options = {
  batchUuid: string | undefined;
  enabled?: boolean;
};

/** Data provider hook for Export Batch */
export default function useExportBatchThingsData({
  batchUuid,
  enabled = true,
}: Options) {
  const { exportBatchesApi, QUERY_KEYS } = useRequest();

  const queryParams = {
    uuid: batchUuid ?? '',
  };

  const { isLoading, isSuccess, data } = useQuery(
    [...QUERY_KEYS.EXPORT_BATCHES, queryParams],
    () => exportBatchesApi.get(queryParams),
    { enabled: enabled && !!batchUuid },
  );

  if (data?.error) {
    return {
      things: [],
      catalogs: [],
      isLoading: false,
      isSuccess: false,
    };
  }

  const importValidation = data?.data?.importValidation;

  return {
    things: importValidation?.things ?? [],
    catalogs: importValidation?.catalogs ?? [],
    statuses: importValidation?.validationStatuses ?? [],
    isLoading,
    isSuccess,
  };
}
