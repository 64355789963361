import { MouseEventHandler } from 'react';

import styles from './Thing.module.css';

type Props = {
  disabled: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export default function ThingPlaceholderImage({ disabled, onClick }: Props) {
  return (
    <div className="relative">
      <svg
        fill="none"
        height="120"
        viewBox="0 0 120 120"
        width="120"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M95 15H25C19.5 15 15 19.5 15 25V95C15 100.5 19.5 105 25 105H95C100.5 105 105 100.5 105 95V25C105 19.5 100.5 15 95 15ZM95 25V95H25V25H95ZM55.7 78.65L70.7 59.3L90 85H30L45 65.7L55.7 78.65Z"
          fill="#666666"
          fillRule="evenodd"
        />
      </svg>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {!disabled && (
        <button
          type="button"
          className={styles.addPrimaryImageButton}
          onClick={onClick}
          title="Add a primary image"
        >
          <svg
            fill="none"
            height="40"
            viewBox="0 0 40 40"
            width="40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect fill="var(--primary-blue)" height="40" rx="20" width="40" />
            <path
              clipRule="evenodd"
              d="M10 20C10 14.48 14.48 10 20 10C25.52 10 30 14.48 30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20ZM21 21H25V19H21V15H19V19H15V21H19V25H21V21Z"
              fill="white"
              fillRule="evenodd"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
