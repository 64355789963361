import { useRef, useCallback } from 'react';

// Returns a memoized function that will only call the passed function when it hasn't been called for the wait period
export default function useDebouncedCallback<T extends any[]>(
  func: (...args: T) => void,
  wait: number,
) {
  // Use a ref to store the timeout between renders
  // and prevent changes to it from causing re-renders
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  return useCallback(
    (...args: T) => {
      const later = () => {
        if (timeout.current) clearTimeout(timeout.current);
        func(...args);
      };

      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(later, wait);
    },
    [func, wait],
  );
}
