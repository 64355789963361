import { useCallback, useEffect, useRef, useState } from 'react';

import { Formik, FormikHelpers } from 'formik';

import { shoppingCartThingsModel } from '@/common/entities/shoppingCarts/models';
import { checkoutSchema } from '@/common/entities/shoppingCarts/schemas';
import useThingsData from '@/common/entities/things/useThingsData';
import useCheckout, {
  CHECKOUT_STEPS,
  EXPORT_STEPS,
} from '@/common/hooks/useCheckout';
import AppSidebar from '@/components/AppSidebar';

import CheckoutItemList from './CheckoutItemList';
import ConfirmBody from './ConfirmBody';
import DownloadProgressModal from './DownloadProgressModal';
import RelationshipsCheckoutWarning from './RelationshipsCheckoutWarning';
import RelationshipsRemoveWarning from './RelationshipsRemoveWarning';
import ShoppingCartActions from './ShoppingCartActions';
import ShoppingCartHeader from './ShoppingCartHeader';

const CART_TITLES = {
  [CHECKOUT_STEPS.CART]: 'Checkout Summary',
  [CHECKOUT_STEPS.CONFIRM]: 'Checkout Review',
};

type ShoppingCartStepProps = {
  step: ValueOf<typeof CHECKOUT_STEPS>;
  shoppingCart: ShoppingCart;
  things: ShoppingCartThings;
};

type ShoppingCartFormValues = {
  name: string;
  description: string;
};

const emptyShoppingCart = shoppingCartThingsModel([]);

function ShoppingCartStep({
  step,
  shoppingCart,
  things,
}: ShoppingCartStepProps) {
  switch (step) {
    case CHECKOUT_STEPS.CART:
      return (
        <>
          <ShoppingCartHeader shoppingCart={shoppingCart} things={things} />
          <CheckoutItemList
            isReview={false}
            things={things}
            shoppingCart={shoppingCart}
          />
          <ShoppingCartActions things={things} />
        </>
      );
    case CHECKOUT_STEPS.CONFIRM:
      return <ConfirmBody things={things} />;
    default:
      return (
        <h1>
          Could not load the required data. Please refresh and try again.{' '}
        </h1>
      );
  }
}

export default function ShoppingCart() {
  const {
    cartPanel,
    shoppingCart,
    isLoading: shoppingCartIsLoading,
    isError,
    checkout,
    exportModal,
    relationshipWarningOptions,
    relationshipRemoveWarningOptions,
  } = useCheckout();

  const [things, setThings] = useState(emptyShoppingCart);
  const { setOpen: setCartPanelOpen, setStep: setCartPanelStep } = cartPanel;

  const handleClose = useCallback(() => {
    setCartPanelStep(CHECKOUT_STEPS.CART);
    setCartPanelOpen(false);
  }, [setCartPanelStep, setCartPanelOpen]);

  const handleSubmit = async (
    values: ShoppingCartFormValues,
    formik: FormikHelpers<ShoppingCartFormValues>,
  ) => {
    await exportModal.initializeExportModal({
      newExportBatchPlaceholder: {
        name: values.name,
        description: values.description,
        thingCount: shoppingCart.numberOfRootThings,
      },
      step: EXPORT_STEPS.DOWNLOAD_PROGRESS,
    });
    formik.resetForm();
    cartPanel.setOpen(false);
    await checkout.completeCheckout(values);
  };

  const {
    things: thingsArray,
    isSuccess: thingsIsSuccess,
    isLoading: thingsIsLoading,
    isFetching: thingsIsFetching,
    hasNextPage: thingsHasNextPage,
  } = useThingsData({
    fixedParams: {
      thingUuids: shoppingCart.thingUuids,
      fields: ['mediaLinks', 'children'],
      sort: ['title'],
    },
    fetchAll: true,
    perPage: 100,
    enabled: shoppingCart.thingUuids.length > 0,
  });
  const thingsRef = useRef(thingsArray);
  thingsRef.current = thingsArray;

  /** Conditions to handle:
   * 1. Adding a new thing
   * 2. Removing a thing
   * 3. Removing a catalog
   * 4. changing a thing relationships
   * 5. Renaming a thing
   */
  useEffect(() => {
    if (
      !thingsIsLoading &&
      !thingsIsFetching &&
      !thingsHasNextPage &&
      !shoppingCartIsLoading
    ) {
      setThings(shoppingCartThingsModel(thingsRef.current));
    }
  }, [
    thingsIsSuccess,
    thingsIsLoading,
    thingsIsFetching,
    shoppingCartIsLoading,
    thingsHasNextPage,
  ]);

  return (
    <>
      {relationshipWarningOptions && <RelationshipsCheckoutWarning />}
      {relationshipRemoveWarningOptions && <RelationshipsRemoveWarning />}
      {exportModal.isExportModalOpen && <DownloadProgressModal />}
      <Formik
        initialValues={{ name: '', description: '' }}
        onSubmit={handleSubmit}
        validationSchema={checkoutSchema}
        enableReinitialize
        validateOnBlur
      >
        <AppSidebar
          onClose={handleClose}
          open={cartPanel.open}
          setOpen={cartPanel.setOpen}
          title={CART_TITLES[cartPanel.step]}
          forceUnique={false}
        >
          {!isError && cartPanel.open && (
            <ShoppingCartStep
              step={cartPanel.step}
              shoppingCart={shoppingCart}
              things={things}
            />
          )}
          {isError && (
            <h2>
              Could not load the checkout cart data. Please refresh and try
              again.
            </h2>
          )}
        </AppSidebar>
      </Formik>
    </>
  );
}
