import {
  EXTRACT_OUTCOMES,
  MATCH_OUTCOMES,
  TRANSACTION_TYPES,
  TRANSACTION_OUTCOMES,
  VALIDATE_INTENT,
} from '@/common/entities/transactions/constants';
import { has } from '@/common/utility';
import { CHIP_STATUS } from '@/components/Library/DustStatusChip';

const isExtractFailure = (transaction: Transaction) =>
  (transaction.transactionType === TRANSACTION_TYPES.EXTRACT.value &&
    transaction.extractOutcome !== EXTRACT_OUTCOMES.SUCCESS.value) ||
  ([TRANSACTION_TYPES.IDENTIFY.value, TRANSACTION_TYPES.VERIFY.value].includes(
    transaction.transactionType,
  ) &&
    transaction.extractOutcome !== EXTRACT_OUTCOMES.SUCCESS.value);

const isDuplicate = (transaction: Transaction) =>
  transaction.transactionType === TRANSACTION_TYPES.VALIDATE.value &&
  transaction.matchOutcome === MATCH_OUTCOMES.MATCH_SUCCESS.value;

const isNoMatch = (transaction: Transaction) =>
  [TRANSACTION_TYPES.IDENTIFY.value, TRANSACTION_TYPES.VERIFY.value].includes(
    transaction.transactionType,
  ) && transaction.matchOutcome === MATCH_OUTCOMES.MATCH_NOT_FOUND.value;

function determineOutcomeStatus(transaction: Transaction) {
  if (isExtractFailure(transaction) || isDuplicate(transaction)) {
    return CHIP_STATUS.WARNING;
  }
  return isNoMatch(transaction) ? CHIP_STATUS.ERROR : CHIP_STATUS.SUCCESS;
}

function transactionLabel(transaction: Transaction) {
  if (transaction.transactionType === TRANSACTION_TYPES.THING_FILE_ADD.value) {
    const numFiles = Object.keys(
      (transaction.transactionData?.actionData?.file_data as object) ?? [],
    ).length;
    return `${TRANSACTION_TYPES.THING_FILE_ADD.label} (${numFiles})`;
  }
  if (transaction.transactionType === TRANSACTION_TYPES.VALIDATE.value) {
    if (
      transaction.transactionData.actionData?.intent ===
      VALIDATE_INTENT.MOVE.value
    ) {
      return 'Duplicate Move Attempted';
    }
    return 'Duplicate Enrollment Attempted';
  }

  if (
    transaction.transactionType === TRANSACTION_TYPES.VERIFY.value &&
    transaction.matchOutcome !== 'MATCH_SUCCESS'
  ) {
    return 'Verify - Failed';
  }

  if (
    transaction.transactionType === TRANSACTION_TYPES.ANTI_TAMPER_ANALYSIS.value
  ) {
    const userOutcome = transaction.transactionData.actionData
      .user_outcome as string;
    if (userOutcome === 'SUSPECTED_TAMPER' || userOutcome === 'FLAG_TAMPERED') {
      return `${TRANSACTION_TYPES.ANTI_TAMPER_ANALYSIS.label} - 
        ${
          TRANSACTION_TYPES.ANTI_TAMPER_ANALYSIS.userDeclarations?.[
            userOutcome
          ] ?? ''
        }`;
    }
  }
  if (has(TRANSACTION_TYPES, transaction.transactionType)) {
    return TRANSACTION_TYPES[transaction.transactionType].label;
  }
  throw new Error(
    `Unexpected transaction.transactionType: ${transaction.transactionType}`,
  );
}

/**
 * @param transaction audit record
 */
export function transactionModel(transaction: any): Transaction {
  const outcome = transaction.outcome as string;
  if (!has(TRANSACTION_OUTCOMES, outcome)) {
    throw new Error(`transaction.outcome of "${outcome}" was not expected`);
  }
  return {
    ...transaction,
    createdBy: transaction?.user?.username || '',
    isVerified: !!transaction.verifiedDustUuid,
    label: transactionLabel(transaction),
    outcomeStatus: determineOutcomeStatus(transaction),
    outcomeLabel: TRANSACTION_OUTCOMES[outcome]?.label,
  };
}
