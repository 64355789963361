import { useState } from 'react';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TextField, IconButton } from '@mui/material';

export default function DustPasswordField({ ...props }) {
  const [isMasked, setIsMasked] = useState(true);

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <IconButton onClick={() => setIsMasked(!isMasked)}>
            {isMasked ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </IconButton>
        ),
      }}
      label="Password"
      size="small"
      name="password"
      type={isMasked ? 'password' : 'text'}
      {...props}
      sx={{
        ...props.sx,
        '& .MuiOutlinedInput-root': {
          paddingRight: '.5rem',
        },
      }}
    />
  );
}
