import { useState } from 'react';

import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { OidcUser } from '@/common/entities/users/typedefs';
import useUsersInGroupData from '@/common/entities/users/useUsersInGroupsData';
import { alphaSort } from '@/common/utilities/sort';
import DustActionButton from '@/components/Library/DustActionButton';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustSearchInput from '@/components/Library/DustSearchInput';

import GroupAddUserModal from './GroupAddUserModal';
import GroupDeleteUsersModal from './GroupDeleteUserModal';
import styles from './Groups.module.css';

// Concatenate first and last name so someone can search for a full name.
// Not i18n-friendly, so will need a rewrite at some point
const getUserFullName = (user: OidcUser) =>
  `${user.given_name.toString()} ${user.family_name.toString()}`;

const columns: GridColDef<OidcUser>[] = [
  {
    valueGetter: ({ row: user }) =>
      `${getUserFullName(user)}${user.enabled ? '' : ' (inactive)'}`,
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'preferred_username',
    headerName: 'Username',
    flex: 1,
  },
];

type Props = { group: Group };

export default function GroupUserList({ group }: Props) {
  const [selected, setSelected] = useState<OidcUser[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const { isLoading, users } = useUsersInGroupData({
    groupId: group.id,
  });

  const [searchValue, setSearchValue] = useState('');
  const searchRegex = new RegExp(searchValue, 'i');
  const userList = users
    .filter((user) =>
      [getUserFullName(user), user.email.toString()].some(
        (field) => !searchValue || searchRegex.test(field),
      ),
    )
    .sort((a, b) => alphaSort(a, b, 'given_name'));

  const actionList = [
    {
      name: selected.length > 1 ? 'Remove Users' : 'Remove User',
      action: () => {
        setShowDeleteModal(true);
        return true;
      },
      disabled: selected.length < 1,
    },
  ];

  return (
    userList && (
      <div className="flex-col h-full">
        <div className={styles.controlsRow}>
          <DustSearchInput
            autoComplete="groupUserList"
            setValue={setSearchValue}
            aria-label="Search for a particular user"
          />
          <Button
            onClick={() => setShowAddModal(true)}
            startIcon={<Add />}
            sx={{ margin: '0', marginRight: '1rem' }}
            variant="contained"
          >
            Add User
          </Button>
          <DustActionButton actionList={actionList} />
        </div>
        <DustDataGrid
          altTheme
          columns={columns}
          getRowId={(row) => row.sub}
          loading={isLoading}
          selectionModel={selected.map((u) => u.sub)}
          onSelectionModelChange={(rowIds) =>
            setSelected(users.filter((u) => rowIds.includes(u.sub)))
          }
          rows={userList}
          checkboxSelection
          getRowClassName={({ row }) => (row.enabled ? '' : styles.inactiveRow)}
        />
        {group && (
          <>
            <GroupDeleteUsersModal
              usersToRemove={selected}
              group={group}
              onClose={() => setShowDeleteModal(false)}
              open={showDeleteModal}
            />
            <GroupAddUserModal
              existingUsers={users}
              group={group}
              onClose={() => setShowAddModal(false)}
              open={showAddModal}
            />
          </>
        )}
      </div>
    )
  );
}
