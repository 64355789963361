import { useCallback, useMemo, useState } from 'react';

import { FooterAction, SuccessCallbackPayload } from '@dustid/dice-sdk';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';

import { useResize } from '@/common/hooks';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import Scanner, { SCAN_MODES } from '@/components/Composed/Scanner/Scanner';
import DustSelect from '@/components/Library/DustSelect';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import DustDustedIcon from '@/components/Library/Icons/DustDustedIcon';
import { invalidators } from '@/services/requests/invalidators';
import { autoNextAtom } from '@/state/atoms/scanner';

import AutoNextSwitch from './AutoNextSwitch';
import BoundPlaceholder from './BoundPlaceholder';
import StepScanSummaryTable from './StepScanSummaryTable';
import styles from './ThingAdd.module.css';

type Props = {
  catalogUuid?: string;
  things: Thing[];
  onFinish: () => void;
};

const ROW_LAYOUT_MIN_WIDTH = 640;

export default function StepScan({ catalogUuid, things, onFinish }: Props) {
  const firstUndustedIdx = things.findIndex((t) => !t.isDusted);
  const [thingIdx, setThingIdx] = useState(
    firstUndustedIdx === -1 ? 0 : firstUndustedIdx,
  );
  // Used to determine whether to show the progress bar in the 'bound' placeholder.
  const [lastDustedThingIdx, setLastDustedThingIdx] = useState<number | null>(
    null,
  );

  const [boundIds, setBoundIds] = useState<string[]>(
    things.filter((t) => t.isDusted).map((t) => t.uuid),
  );
  const [isAutoNext, setIsAutoNext] = useRecoilState(autoNextAtom);
  const { resizeRef, width: contentWidth } = useResize();
  const isLayoutVertical = contentWidth < ROW_LAYOUT_MIN_WIDTH;

  /**
   * Scan step can be run with things from react query OR static things where isDusted will not be reactive
   * We will track the total count of things with dust by saving the ids of any things that have been dusted
   * and taking a unique set.
   */
  const dustedIds = useMemo(
    () =>
      new Set([
        ...things.filter((t) => t.isDusted).map((t) => t.uuid),
        ...boundIds,
      ]),
    [boundIds, things],
  );

  const handleFooterSubmit = () => {
    onFinish();
  };

  const handleSelect = (evt: SelectChangeEvent<string>) => {
    setLastDustedThingIdx(null);
    setThingIdx(things.findIndex((t) => t.uuid === evt.target.value));
  };

  const queryClient = useQueryClient();
  const invalidate = useMemo(() => invalidators(queryClient), [queryClient]);

  const isBound = useCallback(
    (thing: Thing) => dustedIds.has(thing?.uuid),
    [dustedIds],
  );

  const nextUndustedIdx = useMemo(
    () => things.findIndex((thing, idx) => idx > thingIdx && !isBound(thing)),
    [thingIdx, things, isBound],
  );

  const firstUnboundIdx = useMemo(
    () => things.findIndex((thing) => !isBound(thing)),
    [things, isBound],
  );

  const selectNext = useCallback(() => {
    setThingIdx((val) => {
      // Corner case to watch for: firstUnboundIdx = 0
      if (nextUndustedIdx !== -1) return nextUndustedIdx;
      if (firstUnboundIdx !== -1) return firstUnboundIdx;
      return val;
    });
  }, [nextUndustedIdx, firstUnboundIdx]);

  const handleBind = useCallback(
    (evt: SuccessCallbackPayload) => {
      if (evt.action === 'BIND' && evt.thingUuid) {
        setBoundIds((ids) => [...ids, evt.thingUuid as string]);

        // Must invalidate thing list so that dusted status shows properly
        invalidate.things();
        // Move to the next unbound thing. List rollover must be handled
        setLastDustedThingIdx(thingIdx);
        if (isAutoNext) {
          setTimeout(selectNext, 2000);
        }
      }
    },
    [invalidate, isAutoNext, selectNext, thingIdx],
  );

  const allThingsBound = useMemo(
    () => things.length === dustedIds.size,
    [things, dustedIds],
  );

  const submitLabel = useMemo(() => {
    /**
     * CASES:
     * 1) Everything is dusted
     * 2) Only a single unbound thing
     * 3) Some things are bound some are not
     * 4) All things unbound
     */

    if (!things) return 'Skip';
    if (allThingsBound) return 'Finish';
    if (things.length === 1 && dustedIds.size === 0) return 'Skip Bind';
    if (dustedIds.size > 0) return 'Skip Remaining';
    return 'Skip All';
  }, [dustedIds, things, allThingsBound]);

  useNavigationPrompt(!allThingsBound, {
    message: 'You have unbound Things that have been added to your catalog.',
    title: 'Leave before binding?',
  });

  const handleAutoNext = useCallback(
    () => setIsAutoNext((v) => !v),
    [setIsAutoNext],
  );

  return things.length === 0 ? null : (
    <div
      className="action-container overflow-hidden"
      style={{ '--input-max-width': '16rem' } as React.CSSProperties}
      ref={resizeRef}
    >
      <div
        className="action-body scroll-y"
        style={{ scrollbarGutter: 'stable' }}
      >
        <div className={styles.scanTitleRow}>
          <div className={styles.scanCountWrapper}>
            <div className={styles.scanCount}>
              <DustDustedIcon />
              <span>
                <b>{thingIdx + 1}</b>
                {` of ${things.length} Things`}
              </span>
            </div>
          </div>

          <h2 className="h5" style={{ padding: '0.5rem 0' }}>
            Optional:{' '}
            <span className="text">
              Bind Things to Dust by scanning individual tags
            </span>
          </h2>
        </div>
        <div
          className={`${
            isLayoutVertical ? 'flex-col' : 'flex-row'
          } flex-1 gap-1`}
        >
          <div className="flex-col">
            <div className="flex-row items-center mb-1">
              <DustDustedIcon
                fontSize="large"
                sx={{
                  color: 'var(--color-icon-disabled)',
                  marginRight: '1rem',
                }}
              />
              <DustSelect
                disabled={things.length === 1}
                label="Thing to Bind"
                onChange={handleSelect}
                value={things[thingIdx]?.uuid}
              >
                {things?.map((thing) => (
                  <MenuItem key={thing?.uuid} value={thing?.uuid}>
                    <div
                      className={`flex-row justify-space w-full ${styles.selectThingRow}`}
                      style={{ paddingTop: 3 }}
                    >
                      <span>{thing?.title}</span>
                      <span>
                        {isBound(thing) ? (
                          <DustDustedIcon
                            color="primary"
                            sx={{ height: '1.2rem' }}
                          />
                        ) : null}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </DustSelect>
            </div>
            <StepScanSummaryTable thing={things[thingIdx]} />
          </div>
          <div
            style={{
              overflow: 'hidden',
              flexBasis: isLayoutVertical
                ? `max(${contentWidth}px, 400px)`
                : '100%',
              minWidth: '300px',
            }}
          >
            <Scanner
              className={styles.scanner}
              catalogUuid={catalogUuid ?? things[thingIdx].catalogUuid}
              mode={SCAN_MODES.BIND}
              onSuccess={handleBind}
              placeHolder={
                isBound(things[thingIdx]) ? (
                  <BoundPlaceholder
                    onNext={selectNext}
                    disabled={allThingsBound || firstUnboundIdx === -1}
                    nextLabel={`Scan Next (${dustedIds.size} of ${things.length} bound)`}
                    autoNextEnabled={
                      isAutoNext && lastDustedThingIdx === thingIdx
                    }
                    onToggleAutoNext={
                      things.length > 1 ? handleAutoNext : undefined
                    }
                  />
                ) : undefined
              }
              showPrevious={false}
              thingUuid={things[thingIdx]?.uuid}
              actions={
                things.length > 1
                  ? [
                      {
                        /** Applied to the MUI button component used in the footer */
                        buttonProps: {
                          component: AutoNextSwitch,
                          checked: isAutoNext,
                          onClick: handleAutoNext,
                          disableRipple: true,
                        },
                        action: () => {},
                        label: '',
                      } as FooterAction,
                    ]
                  : []
              }
            />
          </div>
        </div>
      </div>
      {allThingsBound && (
        <DustStepperFooter
          onSubmit={handleFooterSubmit}
          submitLabel={submitLabel}
        />
      )}
      {!allThingsBound && (
        <DustStepperFooter
          onCancel={handleFooterSubmit}
          cancelLabel={submitLabel}
        />
      )}
    </div>
  );
}
