import { Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';

import useThingData from '@/common/entities/things/useThingData';
import { ROUTES } from '@/common/routes';
import DustThingChip from '@/components/Library/DustThingChip';
import DustThingThumbnail from '@/components/Library/DustThingThumbnail';

import styles from './Scanner.module.css';

type Props = {
  thing: Pick<Thing, 'uuid' | 'catalogUuid'>;
};

export default function ThingRenderer({ thing: thingData }: Props) {
  const { thing } = useThingData({
    thingUuid: thingData.uuid,
  });

  return (
    <MuiLink to={ROUTES.THING(thingData.uuid)} component={Link} replace>
      <DustThingChip
        thing={thingData}
        icon={
          thing ? (
            <DustThingThumbnail
              thing={thing}
              className={styles.thingThumbnail}
            />
          ) : null
        }
      />
    </MuiLink>
  );
}
