import React from 'react';

import { Button, Tooltip } from '@mui/material';

import AntiTamperNotationForm from './AntiTamperNotationForm';
import styles from './AntiTamperNotationPointer.module.css';

type AntiTamperNotationPointerProps = {
  item: AntiTamperNotation;
  handleCancel: () => void;
  handleSave: (comment: string) => void;
  handleOpen: () => void;
  handleClose: () => void;
  index: number;
  isReadOnly: boolean;
};

const FORM_WIDTH = 335;
const FORM_HEIGHT = 221;

export default function AntiTamperNotationPointer({
  item,
  handleCancel,
  handleSave,
  handleOpen,
  handleClose,
  index,
  isReadOnly,
}: AntiTamperNotationPointerProps) {
  return (
    <>
      <Tooltip title={item.comment}>
        <Button
          sx={{
            top: `calc(${item.pctY}% - 11px)`, // -50% of button width
            left: `calc(${item.pctX}% - 11px)`, // -50% of button height
            borderRadius: '50%',
            border: '1px solid var(--white)',
            width: 22,
            height: 22,
            backgroundColor: 'var(--warning-yellow)',
            position: 'absolute',
            display: 'grid',
            placeContent: 'center',
            color: 'var(--black)',
            cursor: 'pointer',
            zIndex: 9,
            fontWeight: 700,
            minWidth: 22,
            '&:hover': {
              backgroundColor: 'var(--warning-yellow)',
            },
          }}
          onClick={handleOpen}
        >
          {index + 1}
        </Button>
      </Tooltip>
      <div
        className={styles.formAnchor}
        style={{
          top: `min(100% - ${FORM_HEIGHT}px - 20px, ${item.pctY}%)`,
          left: `min(100% - ${FORM_WIDTH}px - 20px, ${item.pctX}%)`,
        }}
      >
        {item.active && (
          <AntiTamperNotationForm
            disabled={item.saved}
            handleSave={handleSave}
            handleCancel={handleCancel}
            handleClose={handleClose}
            item={item}
            isReadOnly={isReadOnly}
          />
        )}
      </div>
    </>
  );
}
