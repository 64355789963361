import { useState, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from '@/common/routes';
import ThingFileModal from '@/components/Composed/ThingFileModal/ThingFileModal';
import ThingSelector from '@/components/Composed/ThingSelector/ThingSelector';

import StepScan from '../ThingAdd/StepScan';

const ACTIONS = {
  SELECT: 'select',
  BIND: 'bind',
};

export default function ThingListing() {
  /** catalogId could be undefined from account */
  const { catalogId = undefined } = useParams();

  const [step, setStep] = useState(ACTIONS.SELECT);
  const [selected, setSelected] = useState<Thing[]>([]);
  const selectedWithoutDust = selected.filter((thing) => !thing.isDusted);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const navigate = useNavigate();

  const actionList = useMemo(
    () => [
      {
        name: 'Bind to DUST',
        action: () => {
          setStep(ACTIONS.BIND);
        },
        disabled: selectedWithoutDust.length < 1,
      },
      {
        name: 'Upload Files',
        action: () => {
          setShowUploadModal(true);
        },
        disabled: selected.length < 1,
      },
    ],
    [selected.length, selectedWithoutDust.length],
  );

  const steps = {
    [ACTIONS.SELECT]: (
      <ThingSelector
        showActionButton
        additionalButtonActions={actionList}
        catalogUuid={catalogId}
        onRowClick={(thing) => navigate(ROUTES.THING(thing.uuid))}
        onSelect={(values) => setSelected(values)}
        selected={selected}
      />
    ),
    [ACTIONS.BIND]: (
      <StepScan
        catalogUuid={catalogId}
        onFinish={() => setStep(ACTIONS.SELECT)}
        things={selected}
      />
    ),
  };

  return (
    <>
      {steps[step]}
      <ThingFileModal
        open={showUploadModal}
        setOpen={setShowUploadModal}
        things={selected}
        // Selected things need to be reset, because they are now stale.
        // In the future, it might make sense to keep things selected and
        // refresh the selected things when the cache expires
        onFileUploadSuccess={() => setSelected([])}
      />
    </>
  );
}
