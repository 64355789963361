import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import { defaultErrorHandler, formatAPIReturn } from '@/services/utility';

import { MESSAGES } from '../constants';

/** Factory function for producing the Dust API */
export default class DustApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
    private invalidate: Invalidators,
  ) {}

  /** Retrieve active user details */
  async deactivateTag({
    dustUuid,
    reason,
  }: {
    dustUuid: string;
    reason: string;
  }) {
    return this.apiService
      .request({
        url: `/api/dusts/deactivate/${dustUuid}`,
        method: 'PUT',
        data: {
          reason,
        },
      })
      .then((res) => {
        this.invalidate.things();
        this.addToast(MESSAGES.DUST_DEACTIVATE_SUCCESS, 'success');
        return formatAPIReturn(res);
      })
      .catch(
        defaultErrorHandler(this.addToast, () => {
          this.addToast(MESSAGES.DUST_DEACTIVATE_FAILED, 'error');
        }),
      );
  }
}
