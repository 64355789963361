import { useCallback, useMemo, useState, useEffect } from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { SxProps, Tooltip } from '@mui/material';
import {
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColDef,
  GridGroupingColDefOverride,
  GridEventListener,
} from '@mui/x-data-grid-premium';

import useThingTypesData from '@/common/entities/thingTypes/useThingTypesData';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import {
  makeMixedThingTypeLookupColDef,
  relationshipsTableTheme,
} from '@/common/utilities/relationships/constants';
import { arrayDiff, keyBy } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import Icon from '@/components/Library/Icons/Icon';
import {
  createdAtThingColDef,
  dustedThingColDef,
  titleThingColDef,
} from '@/components/Library/sharedThingColDefs';
import useRequest from '@/services/requests/useRequest';

import ConfirmEditChildrenDialog from './ConfirmEditChildrenDialog';
import useChildSelectionData from './useChildSelectionData';

type ThingChildGridColDef = GridColDef<Thing & { isCurrentChild?: boolean }>;

const MAX_CURRENT_CHILDREN_TO_EXPAND = 10;
const MAX_NEW_CHILDREN = 100;

type Props = {
  thingUuid: string;
  catalogUuid: string;
  onNavigateToThingPage: () => void;
  onNavigateBack: () => void;
};

export default function EditChildrenTable({
  thingUuid,
  catalogUuid,
  onNavigateToThingPage,
  onNavigateBack,
}: Props) {
  const {
    isLoading,
    thing,
    childrenThings = [],
    filters,
    setFilter,
    getNextPage,
    totalItems,
  } = useChildSelectionData(thingUuid, catalogUuid);

  const { thingTypes = [] } = useThingTypesData({ fetchAll: true });

  // ---- Selection data concerns ----

  const [selectedChildren, setSelectedChildren] = useState<string[]>([]);

  const initialSelection = useMemo(
    () => (thing?.relationships?.children ?? []).map((c) => c.uuid),
    [thing],
  );

  const { childUuidsToRemove, childUuidsToAdd } = useMemo(
    () => ({
      childUuidsToRemove: arrayDiff(initialSelection, selectedChildren),
      childUuidsToAdd: arrayDiff(selectedChildren, initialSelection),
    }),
    [initialSelection, selectedChildren],
  );

  // When complete we disable nav block and navigate
  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    if (isComplete) {
      onNavigateToThingPage();
    }
  }, [isComplete, onNavigateToThingPage]);

  useNavigationPrompt(
    !isComplete &&
      (childUuidsToAdd.length > 0 || childUuidsToRemove.length > 0),
  );

  useEffect(() => {
    // Only initialize selection if there are no previous items
    setSelectedChildren((s) => (s.length === 0 ? initialSelection : s));
  }, [initialSelection]);

  // ---- Dialog concern ----
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { thingsApi } = useRequest();

  const handleConfirmUpdateChildren = useCallback(async () => {
    setIsConfirmDialogOpen(false);
    setIsSubmitting(true);
    await thingsApi.updateThingChildren({
      thingUuid,
      addUuids: childUuidsToAdd,
      removeUuids: childUuidsToRemove,
    });
    setIsSubmitting(false);
    setIsComplete(true);
  }, [childUuidsToRemove, childUuidsToAdd, thingUuid, thingsApi]);

  const newChildrenCount = useMemo(
    () =>
      selectedChildren.filter((uuid) =>
        childrenThings.some(
          (child) => child.uuid === uuid && !child.isCurrentChild,
        ),
      ).length,
    [selectedChildren, childrenThings],
  );

  // ---- Grid concerns ----
  const apiRef = useGridApiRef();

  const columns = useMemo((): ThingChildGridColDef[] => {
    const thingTypesMap = keyBy(thingTypes, 'uuid');
    return [
      // We want checkbox selection, but for styling reasons, we want to
      // completely hide the column and render the checkbox ourselves
      { field: GRID_CHECKBOX_SELECTION_FIELD, hide: true },
      {
        // Required to show title value from groupingColDef leafField
        field: 'title',
        renderCell: ({ row, id, formattedValue }) => (
          <>
            <Icon className="mr-1">
              {apiRef.current?.isRowSelected(id) ? (
                <CheckBoxIcon />
              ) : (
                <Tooltip
                  title={
                    !row.isCurrentChild && newChildrenCount === MAX_NEW_CHILDREN
                      ? `Limit Reached: Only ${MAX_NEW_CHILDREN} children can be added at a time.`
                      : ''
                  }
                >
                  <CheckBoxOutlineBlankIcon
                    color={
                      !row.isCurrentChild &&
                      newChildrenCount === MAX_NEW_CHILDREN
                        ? 'disabled'
                        : 'inherit'
                    }
                  />
                </Tooltip>
              )}
            </Icon>{' '}
            {formattedValue}
          </>
        ),
        hide: true, // Use groupingColDef to show title
        groupingValueGetter: ({ row }) => row.isCurrentChild,
      },
      dustedThingColDef,
      makeMixedThingTypeLookupColDef(thingTypesMap),
      createdAtThingColDef,
    ];
  }, [apiRef, thingTypes, newChildrenCount]);

  const groupingColDef = useMemo(
    (): GridGroupingColDefOverride => ({
      ...titleThingColDef,
      leafField: 'title',
      colSpan: (params) =>
        params.rowNode?.isAutoGenerated === true ? columns.length + 1 : 1,
      hideDescendantCount: true,
      // LINT: this is used as a render prop, and furthermore is not a component, should be safe.
      // eslint-disable-next-line react/no-unstable-nested-components
      valueFormatter: ({ value }) =>
        value ? (
          <span>
            {'Children of '}
            <strong>{thing?.title}</strong>
          </span>
        ) : (
          <span>
            {'Eligible Children for '}
            <strong>{thing?.title}</strong>
          </span>
        ),
    }),
    [thing?.title, columns.length],
  );

  const handleRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      const rowNode = apiRef.current.getRowNode(params.id);
      if (rowNode && rowNode.isAutoGenerated) {
        apiRef.current.setRowChildrenExpansion(
          params.id,
          !rowNode.childrenExpanded,
        );
      }
    },
    [apiRef],
  );

  return (
    <>
      <div className="flex-row mb-1">
        <DustSearchInput
          defaultValue={filters.search}
          setValue={(value) => setFilter('search', value)}
          aria-label="Search for children to add or remove"
        />
      </div>

      <div className="flex-1 border">
        <DustDataGrid
          altTheme
          apiRef={apiRef}
          loading={isLoading}
          sx={
            {
              ...relationshipsTableTheme,
              // Restore default MUI behavior over altTheme override of allowing selection to highlight
              '& .MuiDataGrid-cell': undefined,
            } as SxProps
          }
          columns={columns}
          rows={childrenThings}
          rowCount={totalItems}
          getRowId={(r) => r.uuid}
          onRowClick={handleRowClick}
          rowGroupingModel={[
            'title' /* Works with 'title' col def and `groupingColDef.leafField` */,
          ]}
          groupingColDef={groupingColDef}
          isGroupExpandedByDefault={(node) =>
            // ("node.groupingKey" means "is current child")
            node.groupingKey === true
              ? !!node.children &&
                node.children.length <= MAX_CURRENT_CHILDREN_TO_EXPAND
              : true
          }
          onRowsScrollEnd={async () => {
            await getNextPage();
          }}
          checkboxSelection
          keepNonExistentRowsSelected // Handles filtering hypotheticals
          selectionModel={selectedChildren}
          onSelectionModelChange={(newModel) => {
            const numberOfNewChildren = newModel.filter((uuid) =>
              childrenThings.some(
                (child) => child.uuid === uuid && !child.isCurrentChild,
              ),
            ).length;
            if (numberOfNewChildren <= MAX_NEW_CHILDREN) {
              setSelectedChildren(newModel as string[]);
            }
          }}
        />
      </div>
      <DustStepperFooter
        disabled={
          childUuidsToRemove.length === 0 && childUuidsToAdd.length === 0
        }
        loading={isSubmitting}
        onCancel={onNavigateBack}
        submitLabel="Apply"
        onSubmit={() => setIsConfirmDialogOpen(true)}
      />
      {(isConfirmDialogOpen || isSubmitting) && (
        <ConfirmEditChildrenDialog
          open={isConfirmDialogOpen}
          onConfirm={handleConfirmUpdateChildren}
          onCancel={() => {
            setIsConfirmDialogOpen(false);
          }}
          thingTitle={thing?.title ?? '(no title)'}
          observeChildrenThingTitles={childrenThings}
          childUuidsToAdd={childUuidsToAdd}
          childUuidsToRemove={childUuidsToRemove}
        />
      )}
    </>
  );
}
