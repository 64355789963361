import { useCallback, useEffect } from 'react';

import { MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { atom, useRecoilState } from 'recoil';
import * as yup from 'yup';

import useToasts from '@/common/hooks/useToasts';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustFileUploadMulti from '@/components/Library/DustFileUploadMulti';
import DustModal from '@/components/Library/DustModal';
import DustSelect from '@/components/Library/DustSelect';
import useRequest from '@/services/requests/useRequest';

type Props = {
  open: boolean;
  setOpen: (e: boolean) => void;
};

const typeOptions = [
  { name: 'Bug', value: 'BUG' },
  { name: 'Feature Request', value: 'FEATURE' },
];

const defaultForm = () => ({
  feedbackType: 'BUG',
  summary: '',
  description: '',
  files: {},
  noSensitiveInfo: false,
});

const feedbackFormDataAtom = atom({
  default: defaultForm(),
  key: 'feedback-form-data',
});

const validationSchema = yup.object({
  feedbackType: yup.string().required('Feedback type must be specified.'),
  summary: yup
    .string()
    .label('Title')
    .max(255, 'Title must be less than 256 characters')
    .required('Feedback title is required.'),
  description: yup.string().required('Description is required.'),
  noSensitiveInfo: yup
    .boolean()
    .isTrue('You must ensure no sensitive information is attached.'),
});

export default function FeedbackModal({ open, setOpen }: Props) {
  const { addToast } = useToasts();

  const { feedbackApi } = useRequest();

  const [savedFormData, setSavedFormData] =
    useRecoilState(feedbackFormDataAtom);

  /** Reset form and close */
  const cancelModal = useCallback(() => {
    setSavedFormData(defaultForm());
    setOpen(false);
  }, [setOpen, setSavedFormData]);

  const formik = useFormik({
    initialValues: savedFormData,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      const res = await feedbackApi.submitFeedback({
        ...values,
        pageUrl: window.location.href,
      });

      if (!res.error) cancelModal(); // Reset and close
    },
  });

  /** Save state and close */
  const closeModal = useCallback(() => {
    setSavedFormData(formik.values);
    setOpen(false);
  }, [setOpen, setSavedFormData, formik.values]);

  // Insure formik initial state is current with any saved values
  const { resetForm } = formik;
  useEffect(() => {
    resetForm({ values: savedFormData });
  }, [resetForm, savedFormData]);

  /** Show file errors */
  const handleReject = useCallback(
    (errors: string[]) => {
      errors.forEach((error) => addToast(error, 'error'));
    },
    [addToast],
  );

  return (
    <DustModal
      title="Feedback"
      open={open}
      onClose={closeModal}
      maxWidth="50rem"
      footerProps={{
        onCancel: cancelModal,
        loading: formik.isSubmitting,
        onSubmit: formik.handleSubmit,
      }}
    >
      <div className="flex-col gap-1">
        <p>
          Select the type of feedback. Bugs are to report unexpected or faulty
          behavior. Feature requests are to request changes or additions that
          would improve usability or user experience.
        </p>
        <DustSelect
          className="mb-1"
          label="FeedbackType"
          name="feedbackType"
          onChange={formik.handleChange}
          value={formik.values.feedbackType}
        >
          {typeOptions.map((t) => (
            <MenuItem key={t.value} value={t.value}>
              {t.name}
            </MenuItem>
          ))}
        </DustSelect>
        <TextField
          error={formik.touched.summary && !!formik.errors.summary}
          helperText={formik.touched.summary && formik.errors.summary}
          label="Title"
          placeholder="Title"
          name="summary"
          sx={{ marginBottom: '1rem' }}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.summary}
        />
        <p>
          {formik.values.feedbackType === 'BUG'
            ? 'Explain the expected behavior and what actually happened. Include any steps that were taken that led to the bug.'
            : 'Explain why the feature is needed and how you think it should work.'}
        </p>
        <TextField
          error={formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          label="Description"
          placeholder="Description"
          name="description"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          rows={3}
          size="small"
          value={formik.values.description}
          multiline
        />
        <DustFileUploadMulti
          fileType={FILE_TYPE_OPTIONS.ALL}
          formik={formik}
          onReject={(res: string[]) => handleReject(res)}
          label="Feedback files"
          name="files"
        />
        <DustCheckbox
          label="I confirm this feedback contains no sensitive or export controlled information."
          checked={formik.values.noSensitiveInfo}
          error={
            formik.touched.noSensitiveInfo ? formik.errors.noSensitiveInfo : ''
          }
          onChange={(checked) =>
            formik.setFieldValue('noSensitiveInfo', checked)
          }
          name="noSensitiveInfo"
        />
      </div>
    </DustModal>
  );
}
