import { Output } from '@mui/icons-material';
import { Button } from '@mui/material';

import useCheckout from '@/common/hooks/useCheckout';

export default function CheckOutButton() {
  const { cartPanel, shoppingCart } = useCheckout();

  const isButtonShowing =
    shoppingCart && (cartPanel.open || shoppingCart.numberOfRootThings);

  return isButtonShowing ? (
    <Button
      endIcon={<Output />}
      onClick={() => cartPanel.toggleOpen()}
      sx={{
        color: 'var(--white)',
        borderColor: 'var(--white)',
        marginRight: '1rem',
        whiteSpace: 'pre',
        flexShrink: 0,
      }}
      variant="outlined"
    >
      Check Out ({shoppingCart.numberOfRootThings})
    </Button>
  ) : null;
}
