import { useMemo } from 'react';

import useThingsData, {
  THING_FILTER_NAMES,
} from '@/common/entities/things/useThingsData';
import { keysOf } from '@/common/utility';
import DustFilterChip from '@/components/Library/DustFilterChip';
import DustLinkButton from '@/components/Library/DustLinkButton';

import styles from './ThingSelector.module.css';

type UseThingData = ReturnType<typeof useThingsData>;

type Props = Pick<
  UseThingData,
  | 'filters'
  | 'defaultedFilters'
  | 'resetFilter'
  | 'resetFilters'
  | 'showResetFilters'
>;

export default function ThingSelectorFilterChips({
  filters,
  defaultedFilters,
  resetFilter,
  resetFilters,
  showResetFilters,
}: Props) {
  const chipsInfo = useMemo(
    () =>
      keysOf(defaultedFilters)
        .filter((e) => e !== 'search')
        .map((key) => {
          // Handle type conversion for above filter
          const filterKey = key as unknown as keyof Omit<
            typeof defaultedFilters,
            'search'
          >;

          return {
            key: filterKey,
            label: THING_FILTER_NAMES[filterKey](filters[filterKey]),
            isDefault: defaultedFilters[filterKey],
          };
        }),
    [defaultedFilters, filters],
  );

  return (
    <div className={styles.chips}>
      {chipsInfo.map(
        ({ key, label, isDefault }) =>
          !isDefault && (
            <DustFilterChip
              key={key}
              label={label}
              onDelete={() => resetFilter(key)}
            />
          ),
      )}
      {showResetFilters && (
        <DustLinkButton onClick={resetFilters} sx={{ margin: '0 .5rem' }}>
          Clear filters
        </DustLinkButton>
      )}
    </div>
  );
}
