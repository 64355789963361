import { useCallback, useRef, useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem } from '@mui/material';

import { FEATURE_FLAGS } from '@/common/entities/features/constants';
import useOrgData from '@/common/entities/orgs/useOrgData';
import useCheckout from '@/common/hooks/useCheckout';
import useToasts from '@/common/hooks/useToasts';
import DustLoader from '@/components/Library/DustLoader';
import { MESSAGES } from '@/services/requests/constants';
import useRequest from '@/services/requests/useRequest';

import CatalogSettings from './CatalogSettings';

type Props = {
  catalog: Catalog;
};

export default function CatalogMenu({ catalog }: Props) {
  const button = useRef(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showUpdateCatalogModal, setShowUpdateCatalogModal] = useState(false);
  const { checkout, isCommitting } = useCheckout();
  const { org } = useOrgData();
  const { addToast } = useToasts();

  const { catalogsApi } = useRequest();
  const [isLoading, setIsLoading] = useState(false);

  const handleHide = useCallback(
    async (e: React.SyntheticEvent) => {
      e.stopPropagation();
      setIsLoading(true);
      const action = catalog.hidden
        ? catalogsApi.unhide.bind(catalogsApi)
        : catalogsApi.hide.bind(catalogsApi);
      await action(catalog.uuid);
      setIsLoading(false);
    },
    [catalogsApi, catalog],
  );

  // Prevent event bubbling
  const handleMenu = useCallback((evt: React.SyntheticEvent) => {
    evt.stopPropagation();
    setMenuAnchor(button.current);
  }, []);

  const handleCatalogSettingsClose = useCallback(() => {
    setMenuAnchor(null);
    setShowUpdateCatalogModal(false);
  }, []);

  const handleCheckOut = useCallback(() => {
    setMenuAnchor(null);
    void checkout.addThings({
      catalogUuids: [catalog.uuid],
      suppressToast: true,
      onSuccess: () => {
        addToast(
          MESSAGES.CART_ADD_CATALOG_NAME_SUCCESS(catalog.name),
          'success',
        );
      },
    });
  }, [addToast, catalog, checkout]);

  return (
    <>
      <IconButton id={catalog.uuid} onClick={handleMenu} ref={button}>
        {isCommitting ? (
          <DustLoader size="icon" />
        ) : (
          <MoreHorizIcon fontSize="medium" />
        )}
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={catalog.uuid}
        onClose={() => setMenuAnchor(null)}
        open={!!menuAnchor}
        sx={{ fontSize: 'var(--font-size-text-sm)' }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setShowUpdateCatalogModal(true)}>
          Settings
        </MenuItem>
        <MenuItem disabled={isLoading} onClick={handleHide}>
          {catalog.hidden ? 'Show' : 'Hide'}
        </MenuItem>
        {org &&
          org.isFeatureEnabled(FEATURE_FLAGS.MESH) &&
          catalog.size !== 0 && (
            <MenuItem disabled={isCommitting} onClick={handleCheckOut}>
              Check Out
            </MenuItem>
          )}
      </Menu>
      <CatalogSettings
        catalog={catalog}
        onClose={handleCatalogSettingsClose}
        open={showUpdateCatalogModal}
      />
    </>
  );
}
