import React from 'react';

import { useInterval, useTimeout } from 'usehooks-ts';

import AntiTamperApi from '@/services/requests/apis/AntiTamperApi';
import useRequest from '@/services/requests/useRequest';

const ANTI_TAMPER_LOADING_STATE = {
  LOADING_SCAN: {
    key: 'LOADING_SCAN',
    label: 'Loading Previous Result...',
  },
  PROCESSING_SCAN: {
    key: 'PROCESSING_SCAN',
    label: 'Processing Scan..',
  },
  ANALYZING_DUST_MARKERS: {
    key: 'ANALYZING_DUST_MARKERS',
    label: 'Analyzing DUST markers...',
  },
  GENERATING_RESULT: {
    key: 'GENERATING_RESULT',
    label: 'Generating Results...',
  },
  COMPLETING_RESULTS: {
    key: 'COMPLETING_RESULTS',
    label: 'Completing Results...',
  },
  PROCESSING_ERROR: {
    key: 'PROCESSING_ERROR',
    label: 'Processing error.',
  },
  PERMISSIONS_ERROR: {
    key: 'PERMISSIONS_ERROR',
    label: 'You do not have permission to perform that action',
  },
};

type StartAntiTamperAnalysisProps = {
  thingUuid: string;
  scan?: string;
};

type ConfirmAntiTamperAnalysisProps = {
  outcome: string;
  comment: string;
  notations: AntiTamperNotation[];
};

export type AnalysisLoadingState = ValueOf<typeof ANTI_TAMPER_LOADING_STATE>;

type UseAntiTamper = {
  analysisLoadingState: AnalysisLoadingState;
  setAnalysisLoadingState: (s: AnalysisLoadingState) => void;
  progress: number;
  handleCancel: () => void;
  setIsLoadingModalOpen: (s: boolean) => void;
  isLoadingModalOpen: boolean;
  // TODO: see if verified session can use existing code.
  isVerifiedSession: boolean;
  setIsVerifiedSession: (s: boolean) => void;
  antiTamperAnalysisScan?: AntiTamperAnalysisScan;
  confirmAntiTamperAnalysis: (
    props: ConfirmAntiTamperAnalysisProps,
  ) => Promise<void>;
  startAntiTamperAnalysis: (
    props: StartAntiTamperAnalysisProps,
  ) => Promise<void>;
  setAntiTamperAnalysisScan: (s: AntiTamperAnalysisScan) => void;
  setProgress: (n: number) => void;
  loadAntiTamperAnalysis: (
    uuid: string,
  ) => Promise<AntiTamperAnalysisScan | null>;
  resetAnalysis: () => void;
};

export default function useAntiTamper(): UseAntiTamper {
  const [antiTamperAnalysisScan, setAntiTamperAnalysisScan] =
    React.useState<AntiTamperAnalysisScan>();

  const [analysisLoadingState, setAnalysisLoadingState] =
    React.useState<AnalysisLoadingState>(
      ANTI_TAMPER_LOADING_STATE.PROCESSING_SCAN,
    );
  const [progress, setProgress] = React.useState(0);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = React.useState(false);
  const [isVerifiedSession, setIsVerifiedSession] = React.useState(false);
  // Not sure why this was required but eslint was uphappy.
  const { antiTamperApi }: { antiTamperApi: AntiTamperApi } = useRequest();

  useInterval(
    () => {
      setProgress((oldProgress) => {
        let newProgress = oldProgress;
        if (newProgress === 100) {
          return 100;
        }
        switch (newProgress) {
          case 20: {
            setAnalysisLoadingState(
              ANTI_TAMPER_LOADING_STATE.ANALYZING_DUST_MARKERS,
            );
            break;
          }
          case 60: {
            setAnalysisLoadingState(
              ANTI_TAMPER_LOADING_STATE.GENERATING_RESULT,
            );
            break;
          }
          case 90: {
            setAnalysisLoadingState(
              ANTI_TAMPER_LOADING_STATE.COMPLETING_RESULTS,
            );
            break;
          }
          default:
            break;
        }
        newProgress += 5;
        if (newProgress > 99) {
          newProgress = 99;
        }
        return newProgress;
      });
    },
    isLoadingModalOpen ? 300 : null,
  );

  useTimeout(
    () => {
      setIsVerifiedSession(true);
      setIsLoadingModalOpen(false);
      setProgress(0);
      setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.PROCESSING_SCAN);
    },
    progress === 100 ? 500 : null,
  );

  const handleCancel = () => {
    setIsLoadingModalOpen(false);
    setProgress(0);
    setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.PROCESSING_SCAN);
  };

  const startAntiTamperAnalysis = async ({
    thingUuid,
    scan,
  }: StartAntiTamperAnalysisProps) => {
    setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.PROCESSING_SCAN);
    setIsLoadingModalOpen(true);
    const result = await antiTamperApi.startAntiTamperAnalysis({
      thingUuid,
      scan,
    });
    if (result.error || !result.data) {
      // TODO: Permissions error isn't handled anywhere
      setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.PROCESSING_ERROR);
      setIsLoadingModalOpen(false);
      setProgress(0);
      return;
    }
    setProgress(100);
    setAntiTamperAnalysisScan(result.data);
  };

  const confirmAntiTamperAnalysis = async ({
    outcome,
    comment,
    notations,
  }: ConfirmAntiTamperAnalysisProps) => {
    await antiTamperApi.confirmAntiTamperAnalysis({
      antiTamperAnalysisUuid: antiTamperAnalysisScan?.antiTamperAnalysisUuid,
      outcome,
      comment,
      notations: notations.map((notation, index) => ({
        // TODO: UID shouldn't really be necessary anymore.
        uid: index,
        ...notation,
      })),
    });
  };

  const loadAntiTamperAnalysis = async (uuid: string) => {
    setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.LOADING_SCAN);
    setIsLoadingModalOpen(true);
    const result = await antiTamperApi.loadAntiTamperAnalysis({ uuid });
    if (result.error || !result.data) {
      // TODO: Permissions error isn't handled anywhere
      setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.PROCESSING_ERROR);
      setIsLoadingModalOpen(false);
      setProgress(0);
      return null;
    }
    setProgress(100);
    setAntiTamperAnalysisScan(result.data);
    return result.data ?? null;
  };

  const resetAnalysis = () => {
    setAntiTamperAnalysisScan(undefined);
    setAnalysisLoadingState(ANTI_TAMPER_LOADING_STATE.PROCESSING_SCAN);
    setIsVerifiedSession(false);
    setIsLoadingModalOpen(false);
    setProgress(0);
  };

  return {
    analysisLoadingState,
    setAnalysisLoadingState,
    progress,
    handleCancel,
    setIsLoadingModalOpen,
    isLoadingModalOpen,
    isVerifiedSession,
    setIsVerifiedSession,
    antiTamperAnalysisScan,
    setAntiTamperAnalysisScan,
    startAntiTamperAnalysis,
    confirmAntiTamperAnalysis,
    loadAntiTamperAnalysis,
    setProgress,
    resetAnalysis,
  };
}
