import styles from './AntiTamperImageLayer.module.css';

type AntiTamperImageLayerProps = {
  isLayerActive: boolean;
  imageSrc: string;
};

export default function AntiTamperImageLayer({
  isLayerActive,
  imageSrc,
}: AntiTamperImageLayerProps) {
  if (!isLayerActive) return null;
  return (
    <div
      style={{ backgroundImage: `url(${imageSrc})` }}
      className={styles.root}
    />
  );
}
