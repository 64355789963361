import { useCallback, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  ResolutionObject,
  RESOLUTIONS,
} from '@/common/entities/exportBatches/typedefs';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import { ROUTES } from '@/common/routes';
import DustHeaderStepper from '@/components/Library/DustHeaderStepper';
import { CheckInResolutionParam } from '@/services/requests/apis/ExportBatchesApi';
import useRequest from '@/services/requests/useRequest';

import styles from './CheckIn.module.css';
import CheckInProgressModal from './CheckInProgressModal';
import ConflictsStep from './ConflictsStep';
import FinishStep from './FinishStep';
import ImportStep from './ImportStep';
import ReviewStep from './ReviewStep';
import { RESOLUTION_MAP } from './utility';

const STEPS = {
  IMPORT: 'import',
  REVIEW: 'review',
  CONFLICTS: 'conflicts',
  FINISH: 'finish',
};
type Step = (typeof STEPS)[keyof typeof STEPS];

function CheckIn() {
  const { exportBatchesApi } = useRequest();
  const navigate = useNavigate();
  const [step, setStep] = useState<Step>(STEPS.IMPORT);
  const [exportBatch, setExportBatch] = useState<ExportBatch | null>(null);
  const [resolutions, setResolutions] = useState<ResolutionObject[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [checkInSuccess, setCheckInSuccess] = useState<boolean>(false);
  const [checkInError, setCheckInError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  useNavigationPrompt(
    step !== STEPS.IMPORT && !(checkInSuccess || checkInError),
  );

  const headerSteps = useMemo(
    () =>
      exportBatch?.hasConflicts
        ? {
            [STEPS.IMPORT]: { label: 'Import' },
            [STEPS.REVIEW]: { label: 'Review' },
            [STEPS.CONFLICTS]: { label: 'Manage Conflicts' },
            [STEPS.FINISH]: { label: 'Check In' },
          }
        : {
            [STEPS.IMPORT]: { label: 'Import' },
            [STEPS.FINISH]: { label: 'Review & Check In' },
          },
    [exportBatch],
  );

  const handleImportSuccess = useCallback(
    async (batch: ExportBatch) => {
      setIsLoading(true);
      const data = await exportBatchesApi.validateBatch({ uuid: batch.uuid });
      if (data.error) {
        setIsLoading(false);
        return;
      }
      setExportBatch(data.data);
      setIsLoading(false);
      setResolutions(
        data.data.importValidation.validationStatuses.reduce(
          (prev: ResolutionObject[], thingStatus) =>
            thingStatus.importProblems.length
              ? [
                  ...prev,
                  ...thingStatus.importProblems.map((conflict) => ({
                    thingUuid: thingStatus.thingUuid,
                    conflict,
                    resolution: RESOLUTIONS.NO_RESOLUTION,
                  })),
                ]
              : prev,
          [],
        ),
      );
      setStep(data.data.hasConflicts ? STEPS.REVIEW : STEPS.FINISH);
    },
    [exportBatchesApi],
  );

  const handleImportError = () => setStep(STEPS.IMPORT);

  const handleReviewCancel = useCallback(() => {
    setExportBatch(null);
    setStep(STEPS.IMPORT);
  }, []);

  const handleReviewSuccess = useCallback(() => {
    setStep(STEPS.CONFLICTS);
  }, []);

  const handleConflictsPrevious = useCallback(() => {
    // TODO: DICE3-923 - See if this is the appropriate workflow
    setStep(STEPS.REVIEW);
  }, []);

  const handleFinishPrevious = useCallback(() => {
    if (exportBatch?.hasConflicts) {
      setStep(STEPS.CONFLICTS);
    } else {
      setExportBatch(null);
      setStep(STEPS.IMPORT);
    }
  }, [exportBatch]);

  const handleFinish = useCallback(async () => {
    if (!exportBatch) {
      return;
    }
    setIsModalOpen(true);

    const thingResolutions = Object.values(
      resolutions.reduce(
        (prev: { [k: string]: CheckInResolutionParam }, current) => {
          const existing = prev[current.thingUuid];
          const newResolution =
            RESOLUTION_MAP[current.conflict][current.resolution];
          return {
            ...prev,
            [current.thingUuid]: existing
              ? {
                  ...existing,
                  resolutions: [...existing.resolutions, newResolution.value],
                }
              : {
                  thingUuid: current.thingUuid,
                  resolutions: [newResolution.value],
                },
          };
        },
        {},
      ),
    );

    const result = await exportBatchesApi.checkIn({
      uuid: exportBatch.uuid,
      thingResolutions,
    });
    if (!result || result.error) {
      setCheckInError(true);
    } else {
      setCheckInSuccess(true);
    }
  }, [exportBatch, exportBatchesApi, resolutions]);

  const handleCheckInComplete = () => {
    setIsModalOpen(false);
    setExportBatch(null);
    setStep(STEPS.IMPORT);
    navigate(ROUTES.CATALOGS, { replace: true });
  };

  const stepElement = useMemo(() => {
    switch (step) {
      case STEPS.IMPORT:
        return (
          <ImportStep
            onSuccess={handleImportSuccess}
            onError={handleImportError}
          />
        );
      case STEPS.REVIEW:
        return (
          <ReviewStep
            onSuccess={handleReviewSuccess}
            exportBatch={exportBatch}
            isLoading={isLoading}
            onCancel={handleReviewCancel}
          />
        );
      case STEPS.CONFLICTS:
        return (
          <ConflictsStep
            exportBatch={exportBatch as ExportBatch}
            onSuccess={() => setStep(STEPS.FINISH)}
            resolutions={resolutions}
            onResolutionsUpdate={setResolutions}
            onPrevious={handleConflictsPrevious}
          />
        );
      case STEPS.FINISH:
        return (
          <FinishStep
            exportBatch={exportBatch}
            resolutions={resolutions}
            onSuccess={handleFinish}
            isLoading={isLoading}
            onPrevious={handleFinishPrevious}
          />
        );
      default:
        throw new Error('Invalid Check In Step');
    }
  }, [
    step,
    handleImportSuccess,
    handleReviewSuccess,
    exportBatch,
    isLoading,
    handleReviewCancel,
    resolutions,
    handleConflictsPrevious,
    handleFinish,
    handleFinishPrevious,
  ]);

  return (
    <>
      <div className={styles.checkInContainer}>
        <DustHeaderStepper
          activeStep={step}
          steps={headerSteps}
          className={styles.stepper}
        />
        {stepElement}
      </div>
      {isModalOpen && exportBatch && (
        <CheckInProgressModal
          checkInSuccess={checkInSuccess}
          exportBatch={exportBatch}
          isError={checkInError}
          onComplete={handleCheckInComplete}
        />
      )}
    </>
  );
}

export default CheckIn;
