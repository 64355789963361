import { RefObject, ReactNode, useEffect } from 'react';

import { Alert, AlertProps, Slide } from '@mui/material';

import styles from './Components.module.css';

type Props = {
  children: ReactNode;
  show: boolean;
  containerRef: RefObject<HTMLDivElement>;
  timeout?: number;
  onTimeout?: () => void;
  /** Allows the MUI alert message to expand full-width */
  flexGrowMessage?: boolean;
} & AlertProps;

export default function DustAlert({
  children,
  show,
  containerRef,
  timeout,
  onTimeout,
  flexGrowMessage,
  ...props
}: Props) {
  useEffect(() => {
    const timer =
      timeout && show
        ? setTimeout(() => {
            onTimeout?.();
          }, timeout)
        : null;

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timeout, show, onTimeout]);

  return (
    <Slide
      container={containerRef.current}
      direction="up"
      in={show}
      mountOnEnter
      unmountOnExit
    >
      <Alert
        sx={{ borderRadius: 0 }}
        variant="filled"
        classes={{
          message: flexGrowMessage ? styles.flex1 : undefined,
        }}
        {...props}
      >
        {children}
      </Alert>
    </Slide>
  );
}
