import useExportBatchesData from '@/common/entities/exportBatches/useExportBatchesData';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustTableControlsRow from '@/components/Library/DustTableControlsRow';
import ExportBatchesTable from '@/components/Pages/Admin/Checkout/ExportBatchesTable';

export default function CheckoutHistory() {
  const { exportBatches, isLoading, setFilter } = useExportBatchesData();

  return (
    <>
      You can only recover or download one export at a time.
      <DustTableControlsRow>
        <DustSearchInput
          aria-label="Search the checkout history"
          setValue={(value: string) => setFilter('search', value)}
        />
      </DustTableControlsRow>
      <div className="flex-1 border">
        <ExportBatchesTable
          exportBatches={exportBatches}
          isLoading={isLoading}
        />
      </div>
    </>
  );
}
