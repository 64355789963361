import { useMemo, useRef, useEffect, useState } from 'react';

import { arrayMove } from '@dnd-kit/sortable';
import Add from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { FieldArray, FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

// TODO use or remove: import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import { THING_TYPE_FIELD_TYPES } from '@/common/entities/thingTypes/constants';
import {
  escapeRegExp,
  getFieldQuery,
  getRenderList,
} from '@/common/entities/thingTypes/utility';
import DustDraggableList from '@/components/Library/DustDraggableList';

import ThingTypeField from './ThingTypeField';

function ThingTypeFields({
  formik,
  fieldKey = 'fieldTypes',
  checkRequiredIsValid,
  disableAll = false,
}) {
  const fieldValues = formik.values[fieldKey]
    .map((field, idx) => [field, idx])
    .filter(([field]) => field.schema.type === THING_TYPE_FIELD_TYPES.STRING);

  const defaultEntry = () => ({
    name: '',
    uuid: uuidv4(),
    schema: { type: THING_TYPE_FIELD_TYPES.STRING },
    required: false,
  });

  // Must override set behaviour on draggable list in order to handle the filtering of the original list
  const onDragEnd = (evt) => {
    const items = formik.values[fieldKey];
    const { active, over } = evt;

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.uuid === active.id);
      const newIndex = items.findIndex((item) => item.uuid === over.id);

      formik.setValues({
        ...formik.values,
        [fieldKey]: arrayMove(items, oldIndex, newIndex),
      });
    }
  };

  const fieldQuery = useMemo(
    () => getFieldQuery(fieldValues.map((fv) => fv[0].name)),
    [fieldValues],
  );
  const renderStringList = getRenderList(formik.values.title, fieldQuery);

  const handleChange = (e, { prevValue }) => {
    formik.handleChange(e);
    const title = renderStringList
      .map((val) =>
        val.replace(
          new RegExp(`^<${escapeRegExp(prevValue)}>$`, 'gi'),
          `<${e.currentTarget.value}>`,
        ),
      )
      .join('');
    if (title !== formik.values.title) {
      formik.setFieldValue('title', title);
    }
  };

  const handleBlur = (e, { prevValue }) => {
    const { value, name } = e.currentTarget;
    if (!value) return;

    formik.setFieldValue(name, value.trim());
    formik.handleBlur(e);
    const title = renderStringList
      .map((val) =>
        val.replace(
          new RegExp(`^<${escapeRegExp(prevValue)}>$`, 'gi'),
          `<${value.trim()}>`,
        ),
      )
      .join('');
    if (title !== formik.values.title) formik.setFieldValue('title', title);
  };

  const entryCont = useRef(null);
  const [focusIdx, setFocusIdx] = useState(null);
  const addEntry = (arrayHelpers) => {
    arrayHelpers.push(defaultEntry());
    setFocusIdx(fieldValues.length);
  };

  const removeEntry = (idx, arrayHelpers) => {
    arrayHelpers.remove(idx);
    const newIdx = focusIdx > 0 ? focusIdx - 1 : 0;

    // If original index is zero then we reset the focusIdx
    setFocusIdx(focusIdx === 0 ? null : newIdx);
  };

  useEffect(() => {
    if (focusIdx !== null)
      entryCont.current?.children?.[focusIdx]?.querySelector('input')?.focus();
  }, [focusIdx]);

  return (
    <FormikProvider value={formik}>
      <FieldArray
        name={fieldKey}
        render={(arrayHelpers) => (
          <div
            ref={entryCont}
            className="flex-col gap-1"
            style={{ paddingTop: '1rem' }}
          >
            <DustDraggableList
              items={fieldValues.map(([entry, idx]) => ({
                ...entry,
                idx,
                arrayHelpers,
                formik,
                fieldKey,
                checkRequiredIsValid,
                onChange: handleChange,
                onRemove: (idx) => removeEntry(idx, arrayHelpers),
                onEnter: () => addEntry(arrayHelpers),
                onBlur: handleBlur,
              }))}
              ListItemComponent={ThingTypeField}
              onDragEnd={onDragEnd}
            />
            <Button
              disabled={disableAll}
              fullWidth={false}
              onClick={() => addEntry(arrayHelpers)}
              startIcon={<Add />}
              sx={{ marginTop: '.5rem', maxWidth: '5rem' }}
            >
              Add
            </Button>
          </div>
        )}
        validateOnChange={false}
      />
    </FormikProvider>
  );
}

ThingTypeFields.propTypes = {
  formik: PropTypes.object,
  fieldKey: PropTypes.string,
  disableAll: PropTypes.bool,
};

export default ThingTypeFields;
