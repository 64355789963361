import React from 'react';

import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ROUTES } from '@/common/routes';
import CheckIn from '@/components/Pages/Admin/CheckIn/CheckIn';
import CheckoutHistory from '@/components/Pages/Admin/Checkout/CheckoutHistory';
import { ExportBatchThings } from '@/components/Pages/Admin/Checkout/ExportBatchThings';
import Facilities from '@/components/Pages/Admin/Facilities';
import FacilitiesForm from '@/components/Pages/Admin/FacilitiesForm';
import Org from '@/components/Pages/Admin/Org';
import ProtectedAdminRoute from '@/components/Pages/Admin/ProtectedAdminRoute';
import AddThingType from '@/components/Pages/Admin/ThingTypes/AddEditThingTypes/AddThingType';
import EditThingType from '@/components/Pages/Admin/ThingTypes/AddEditThingTypes/EditThingType';
import ThingTypes from '@/components/Pages/Admin/ThingTypes/ThingTypes';
import UserForm from '@/components/Pages/Admin/UserForm';
import GroupDetails from '@/components/Pages/Admin/UserGroups/GroupDetails';
import UserGroups from '@/components/Pages/Admin/UserGroups/UserGroups';
import Users from '@/components/Pages/Admin/Users/Users';
import Scan from '@/components/Pages/Catalogs/Account/Scan';
import Catalogs from '@/components/Pages/Catalogs/Catalogs';
import Page404 from '@/components/Pages/ErrorPages/Page404';
import Unauthorized from '@/components/Pages/ErrorPages/Unauthorized';
import Login from '@/components/Pages/Login/Login';
import SelectOrg from '@/components/Pages/Login/SelectOrg';
import ProtectedRoute from '@/components/Pages/ProtectedRoute';
import ThingEditChildren from '@/components/Pages/Things/Relationships/ThingEditChildren/ThingEditChildren';
import Thing from '@/components/Pages/Things/Thing/ThingPage';
import ThingAdd from '@/components/Pages/Things/ThingAdd/ThingAdd';
import ThingListing from '@/components/Pages/Things/ThingListing/ThingListing';
import ThingRedirect from '@/components/Pages/Things/ThingRedirect/ThingRedirect';
import TransactionsDetails from '@/components/Pages/Transactions/TransactionDetails';
import TransactionsTable from '@/components/Pages/Transactions/TransactionsTable';

import App from './App';
import AppBody from './AppBody';
import AddParent from './components/Pages/Things/Relationships/AddParent';
import './assets/css/normalize.css'; // MUST happen before all other CSS imports
import './assets/css/index.css';
import './assets/css/colorStates.css';
import './assets/css/typography.css';

// Enable MUI Premium
LicenseInfo.setLicenseKey(
  '7a0a953d907a1e88c87074f2c6518c49Tz02NjA4NSxFPTE3MTUyNzI3ODU4OTQsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y',
);

const container = document.getElementById('root');

if (!container) {
  throw new Error('Could not find app container');
}

const root = createRoot(container);

document.title = import.meta.env.VITE_NON_PROD_ENV_NAME
  ? `DICE (${import.meta.env.VITE_NON_PROD_ENV_NAME}) - Dust Identity`
  : 'DICE - Dust Identity';

const appJsx = (
  <App>
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <AppBody />
          </ProtectedRoute>
        }
        path="/"
      >
        <Route
          element={<Navigate to={ROUTES.CATALOGS} replace />}
          path={ROUTES.ROOT}
        />
        <Route
          element={<Navigate to="./things" replace />}
          path={ROUTES.CATALOG_ROOT}
        />
        <Route element={<Catalogs />} path={ROUTES.CATALOGS} />
        <Route element={<Scan />} path={ROUTES.SCAN} />
        <Route element={<TransactionsTable />} path={ROUTES.TRANSACTIONS} />
        <Route
          element={<TransactionsDetails />}
          path={ROUTES.TRANSACTION(':transactionId')}
        />
        <Route element={<ThingListing />} path={ROUTES.THINGS_ACCOUNT} />
        <Route element={<ThingAdd />} path={ROUTES.THINGS_ADD} />
        <Route element={<ThingListing />} path={ROUTES.THINGS(':catalogId')} />
        <Route element={<Thing />} path={ROUTES.THING(':thingId')} />
        <Route
          element={<ThingRedirect />}
          path={ROUTES.THING_REDIRECT(':catalogId', ':thingId')}
        />
        <Route
          element={<AddParent />}
          path={ROUTES.THING_EDIT_PARENT(':thingId')}
        />
        <Route
          element={<ThingEditChildren />}
          path={ROUTES.THING_EDIT_CHILDREN(':thingId')}
        />
        {/*  */}
        <Route element={<Unauthorized />} path={ROUTES.UNAUTHORIZED} />
      </Route>
      <Route
        element={
          <ProtectedAdminRoute>
            <AppBody />
          </ProtectedAdminRoute>
        }
        path={ROUTES.ADMIN_GATE}
      >
        <Route
          element={<UserForm />}
          path={ROUTES.ADMIN_USERS_EDIT(':userId')}
        />
        <Route element={<Users />} path={ROUTES.ADMIN_USERS} />
        <Route element={<Org />} path={ROUTES.ADMIN_ACCOUNTS} />
        <Route element={<CheckIn />} path={ROUTES.ADMIN_CHECKIN} />
        <Route
          element={<CheckoutHistory />}
          path={ROUTES.ADMIN_CHECKOUT_HISTORY}
        />
        <Route
          element={<ExportBatchThings />}
          path={ROUTES.ADMIN_CHECKOUT_BATCH(':batchId')}
        />
        <Route element={<UserGroups />} path={ROUTES.ADMIN_GROUPS} />
        <Route
          element={<GroupDetails />}
          path={ROUTES.ADMIN_GROUP_DETAILS(':groupId')}
        />
        <Route element={<Facilities />} path={ROUTES.ADMIN_FACILITIES} />
        <Route
          element={<FacilitiesForm />}
          path={ROUTES.ADMIN_FACILITIES_EDIT(':facilityId')}
        />
        <Route element={<ThingTypes />} path={ROUTES.ADMIN_THING_TYPES} />
        <Route
          element={<AddThingType />}
          path={ROUTES.ADMIN_CREATE_THING_TYPE}
        />
        <Route
          element={<EditThingType />}
          path={ROUTES.ADMIN_EDIT_THING_TYPE(':typeId')}
        />
      </Route>
      <Route element={<Login />} path={ROUTES.LOGIN} />
      <Route element={<SelectOrg />} path={ROUTES.SELECT_ORG} />
      <Route element={<Page404 />} path="*" />
    </Routes>
  </App>
);

root.render(<React.StrictMode>{appJsx}</React.StrictMode>);
