import styles from './AntiTamperImagesContainer.module.css';

type AntiTamperImagesContainerProps = {
  scannedImageIsActive: boolean;
  scannedImage: string; // TODO: A lot to fix here
  isLoading: boolean;
  combinedHeatMapLayer: JSX.Element;
  matchingMarkers: JSX.Element;
  modifiedMarkers: JSX.Element;
  missingMarkers: JSX.Element;
  notationLayer: JSX.Element;
  matchingMarkersHeatMapLayer: JSX.Element;
  missingMarkersHeatMapLayer: JSX.Element;
  combinedMarkersHeatMapLayer: JSX.Element;
};

export default function AntiTamperImagesContainer({
  scannedImageIsActive,
  scannedImage,
  isLoading,
  combinedHeatMapLayer,
  matchingMarkers,
  modifiedMarkers,
  missingMarkers,
  notationLayer,
  matchingMarkersHeatMapLayer,
  missingMarkersHeatMapLayer,
  combinedMarkersHeatMapLayer,
}: AntiTamperImagesContainerProps) {
  return (
    <div
      id="at-image-container"
      className={`${styles.root} ${
        isLoading ? styles.loadingScreen : styles.loadedScreen
      }`}
      style={
        scannedImageIsActive
          ? { backgroundImage: `url(${scannedImage})` }
          : { backgroundImage: 'none' }
      }
    >
      {combinedHeatMapLayer}
      {matchingMarkersHeatMapLayer}
      {missingMarkersHeatMapLayer}
      {notationLayer}
      {combinedMarkersHeatMapLayer}
      {matchingMarkers}
      {modifiedMarkers}
      {missingMarkers}
    </div>
  );
}
