import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

type Options = {
  exportBatchUuid: string;
  enabled?: boolean;
  refetchInterval: number | false | (() => number | false);
};

/** Get one export batch by uuid */
export default function useExportBatchData({
  exportBatchUuid,
  enabled = true,
  refetchInterval = false,
}: Options) {
  const { exportBatchesApi, QUERY_KEYS } = useRequest();
  const { data, isError, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.EXPORT_BATCH(exportBatchUuid),
    () => exportBatchesApi.get({ uuid: exportBatchUuid }),
    { enabled, refetchInterval },
  );

  return {
    exportBatch: data && !data.error ? data.data : undefined,
    isError: data?.error || isError,
    isLoading,
    isSuccess,
  };
}
