import { fileFromBytes } from '@/common/utilities/files';
import { removeFileExtension } from '@/common/utility';

import baseModel from '../baseModel';
import { THING_TYPE_FIELD_TYPES } from '../thingTypes/constants';

export function thingMediaFileModel(data: ThingMediaFile): ThingMediaFile {
  return {
    ...baseModel(data),
    file: fileFromBytes(data),
  };
}

/**
 * Additional object properties must be stripped from the fieldType server response
 * to format it for re-submission when editing/duplicating
 */
export function fieldTypeForEditing(field: any): any {
  const reduced: any = {
    name:
      field.schema.type === THING_TYPE_FIELD_TYPES.STRING
        ? field.name
        : removeFileExtension(field.name),
    uuid: field.uuid,
    schema: field.schema,
  };
  if ('required' in field) reduced.required = field.required;
  if ('value' in field) reduced.value = field.value;

  return reduced;
}
