/** Convert a server file response to a file */
export function fileFromBytes(data: {
  bytes: string;
  filename: string;
  fileType: string;
  updatedAt?: Date;
}) {
  // Generate a file from the byte string
  const byteString = window.atob(data.bytes);
  const byteArray = new Uint8Array(byteString.length).map((_x, i) =>
    byteString.charCodeAt(i),
  );

  return new File([byteArray], data.filename, {
    type: data.fileType,
    lastModified: data.updatedAt
      ? new Date(data.updatedAt).getTime()
      : undefined,
  });
}
