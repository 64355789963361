import { nanoid } from 'nanoid';
import { atom } from 'recoil';

export type ToastSeverity = 'info' | 'success' | 'warning' | 'error';

export type ToastMessage = string | JSX.Element;

export function toastModel(
  message: ToastMessage,
  severity: ToastSeverity,
  persist: boolean,
  timeout: number,
) {
  return {
    message,
    severity,
    persist,
    timeout: Date.now() + timeout,
    id: nanoid(),
  };
}

export const toastListAtom = atom<ReturnType<typeof toastModel>[]>({
  key: 'toast-list',
  default: [],
});
