import { ChangeEvent, ReactNode, useCallback, useMemo } from 'react';

import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material';

type Props = {
  label: ReactNode;
  onChange: (val: boolean) => void;
  noWrap?: boolean;
  checked?: boolean;
  error?: string;
} & Omit<CheckboxProps, 'onChange'>;

export default function DustCheckbox({
  label,
  onChange,
  noWrap = false,
  checked,
  style,
  error,
  ...props
}: Props) {
  const controlStyle = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: '1rem',
    ...style,
  };
  const handleClick = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => onChange(evt.target.checked),
    [onChange],
  );

  const color = useMemo(
    () => (error ? 'var(--error-red)' : 'var(--primary-blue)'),
    [error],
  );

  return (
    <FormGroup>
      <FormControlLabel
        componentsProps={{
          typography: {
            noWrap,
          },
        }}
        control={
          <Checkbox
            checked={checked}
            onChange={handleClick}
            {...props}
            sx={{
              minWidth: '2.625rem',
              ...props.sx,
              color,
            }}
          />
        }
        label={label}
        sx={controlStyle}
      />
      {error && (
        <FormHelperText sx={{ marginLeft: '.75rem', color }}>
          {error}
        </FormHelperText>
      )}
    </FormGroup>
  );
}
