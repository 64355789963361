import styles from './Icons.module.css';

type Props = {
  children: React.ReactNode;
  className: string;
  width?: string;
  height?: string;
};

export default function Icon({ className, width, height, children }: Props) {
  return (
    <div className={`${className} ${styles.icon}`} style={{ width, height }}>
      {children}
    </div>
  );
}
