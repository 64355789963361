import { useCallback, useState } from 'react';

import {
  DataGridPremium,
  DataGridPremiumProps,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';

import { remToPx } from '@/common/utility';

import { NoRowsOverlay } from './DustDataGridNoRowOverlay';
import { renderTooltipCell } from './DustTableCellTooltip';

type Props<R extends GridValidRowModel> = {
  altTheme?: boolean;
  initialPageSize?: number;
  rowsPerPageOption?: number[];
  /** Note: footerContent must be memoized to prevent render loops */
  footerContent?: React.ReactNode;
} & DataGridPremiumProps<R>;

export const GRID_ROW_HIGHLIGHT = 'gridRowHighlight';

export default function DustDataGrid<R extends GridValidRowModel = any>({
  altTheme = false,
  initialPageSize,
  rowsPerPageOptions,
  columns = [],
  rows,
  rowCount,
  sx,
  footerContent,
  ...props
}: Props<R>) {
  const [pageSize, setPageSize] = useState(
    initialPageSize ?? rowsPerPageOptions?.[0] ?? 5,
  );

  const selectedTheme = altTheme
    ? {
        '& .MuiDataGrid-main': {
          border: '0',
        },
        '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-pinnedColumnHeaders': {
          backgroundColor: 'var(--dark-blue)',
          color: '#fff',
          fill: '#fff',
          '& .MuiSvgIcon-root': {
            fill: '#fff',
          },
          borderRadius: 0,
        },
        '& .MuiDataGrid-cell':
          // padding: '0 20px',
          props.checkboxSelection || props.onRowClick
            ? { cursor: 'pointer' }
            : { cursor: 'default' },
        [`& .${GRID_ROW_HIGHLIGHT}`]: {
          backgroundColor: 'var(--light-blue)',
        },
        '& .MuiDataGrid-columnHeader': {
          // padding: '0 4px',
        },
        '& .MuiTablePagination-root': {
          padding: '0 20px',
          flex: 1,
        },
        '& .MuiDataGrid-cellContent': {
          whiteSpace: 'pre',
        },
      }
    : null;

  const footerRowsLength = rows?.length ?? 0;

  /** Inline component for DataGridPremium */
  const Footer = useCallback(() => {
    if (footerContent !== undefined) {
      return footerContent as JSX.Element;
    }
    const rowCountText = rowCount ? ` of ${rowCount}` : '';
    return (
      <div
        style={{
          padding: '.5rem 1rem',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
        }}
      >
        {`${footerRowsLength}${rowCountText} Rows`}
      </div>
    );
  }, [footerRowsLength, rowCount, footerContent]);

  const colsWithDefaults = columns.map((col) => ({
    renderCell: renderTooltipCell,
    ...col,
  }));
  return (
    <DataGridPremium
      components={{ Footer, NoRowsOverlay }}
      // SEE: https://mui.com/x/react-data-grid/editing/
      experimentalFeatures={{ newEditingApi: true }}
      headerHeight={remToPx(2.5)}
      hideFooterSelectedRowCount
      onPageSizeChange={(val) => setPageSize(val)}
      rowHeight={48}
      rowsPerPageOptions={rowsPerPageOptions ?? [5, 10, 20, 50, 100]}
      sortingOrder={['asc', 'desc']}
      disableColumnMenu
      {...props}
      columns={colsWithDefaults}
      pageSize={pageSize}
      rows={rows}
      sx={{
        fontSize: 14,
        border: 'none',
        // backgroundColor: 'var(--white)',
        borderRadius: '0',
        '& .MuiDataGrid-main': {
          border: '1px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiCircularProgress-root': {
          zIndex: 1,
        },
        '& .MuiDataGrid-footerContainer': {
          maxHeight: '48px',
          minHeight: '48px',
          display: 'flex',
          justifyContent: 'flex-start',
          borderTop: '0',
        },
        '& .MuiTablePagination-root': {
          flex: 1,
        },
        '& .MuiTablePagination-root:last-child': {
          padding: '0 0.5rem 0 1rem',
        },
        '& .MuiTablePagination-toolbar .MuiTablePagination-actions': {
          marginLeft: '5px',
        },
        '& .MuiTablePagination-toolbar .MuiTablePagination-spacer': {
          display: 'none',
        },
        '& .MuiTablePagination-toolbar .MuiTablePagination-displayedRows': {
          textAlign: 'right',
          fontSize: '12px',
          flex: 1,
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: 'var(--light-grey)',
          borderRadius: 0,
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          fontWeight: '500',
        },
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-virtualScrollerContent': {
          fontSize: '14px',
        },
        '& .MuiTablePagination-selectLabel': {
          fontSize: '10px',
        },
        '& .MuiTablePagination-select': {
          fontSize: '10px',
          paddingLeft: 0,
        },
        '& .MuiTablePagination-toolbar': {
          paddingLeft: 0,
        },
        '& .MuiDataGrid-virtualScroller': {
          // cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row':
          props.checkboxSelection || props.onRowClick
            ? { cursor: 'pointer' }
            : { cursor: 'default' },
        ...selectedTheme,
        ...sx,
      }}
    />
  );
}
