import { Button } from '@mui/material';

import {
  Resolution,
  RESOLUTIONS,
} from '@/common/entities/exportBatches/typedefs';
import { combineClass } from '@/components/Library/utility';

import styles from './CheckIn.module.css';

type Props = {
  onSelectAll: (resolution: Resolution) => void;
  showOr?: boolean;
};

function ResolutionBookend({ onSelectAll, showOr = true }: Props) {
  return (
    <>
      <div
        className={combineClass(styles.existingHeader, styles.bookendHeader)}
      >
        <div className="flex-row justify-space items-center">
          Existing on this Device
          <Button
            onClick={() => onSelectAll(RESOLUTIONS.USE_SERVER_THING)}
            sx={{ color: 'var(--white)', whiteSpace: 'nowrap' }}
          >
            Select All
          </Button>
        </div>
      </div>
      <div className={styles.orDivider}>{showOr ? 'OR' : ''}</div>
      <div className={combineClass(styles.newHeader, styles.bookendHeader)}>
        <div className="flex-row justify-space items-center">
          NEW from Upload
          <Button
            onClick={() => onSelectAll(RESOLUTIONS.USE_IMPORTED_THING)}
            sx={{ color: 'var(--white)', whiteSpace: 'nowrap' }}
          >
            Select All
          </Button>
        </div>
      </div>
    </>
  );
}

export default ResolutionBookend;
