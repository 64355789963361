import { SvgIcon, SvgIconProps } from '@mui/material';

function DustCircleMatchingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 15 15">
        <circle cx="7.5" cy="7.5" r="6.5" stroke="#00FFFF" strokeWidth="2" />
      </svg>
    </SvgIcon>
  );
}

export default DustCircleMatchingIcon;
