import { v4 as uuidv4 } from 'uuid';

import { fieldTypeForEditing } from '@/common/entities/thingMedia/models';
import { RESTRICTED_THING_FIELDS } from '@/common/entities/things/constants';
import {
  includesWordMatch,
  isWordMatch,
  normalizeString,
} from '@/common/utility';
import { clone } from '@/components/Library/utility';

import { THING_TYPE_FIELD_TYPES } from './constants';

/**
 * Escape a string to use in a regex expression
 *
 * @param {string} string
 */
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Get a Regex test to search for a list of template strings
 * Each field options is a string and the template will be the string
 * in angled braces
 *
 * @param   {string[]} options
 * @returns {RegExp}
 */
export function getFieldQuery(options) {
  return new RegExp(
    `<(${options.map((o) => escapeRegExp(o)).join('|') || '__NULL__'})>`,
    'gi',
  );
}

/**
 * Split a string that includes "<template_field>" type fields
 * into an array containing a mix of templates with normal text between
 * ex: ['<firstName>', '-', '<lastName>']
 *
 * @param   {string}   value
 * @param   {RegExp}   FIELD_QUERY
 * @returns {string[]}
 */
export function getRenderList(value, FIELD_QUERY) {
  const fields = []; // Fields that have been replaced
  const parsed = value.replace(FIELD_QUERY, (val) => {
    fields.push(val);
    return '__FIELD__';
  });
  const segments = parsed.split('__FIELD__'); // Text between fields
  return fields.reduce(
    (list, currentField, idx) => [...list, currentField, segments[idx + 1]],
    [segments[0]],
  );
}

/**
 *
 * @param   {string}   selected selected value to insert
 * @param   {string[]} listMut  render list for schema string (Mutated)
 * @param   {number}   idx      index of insertion location in list
 * @param   {number}   pos      cursor position for insertion
 * @returns {string}            completed template string
 */
export function executeFieldReplace(selected, listMut, idx, pos) {
  /* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["listMut"] }] */
  const templateOption = `<${selected}>`;
  // Get a list of all partial options to search for
  const partials = Array.from(templateOption)
    .map((_, i) => templateOption.slice(0, i))
    .reverse()
    .slice(0, -1);
  const partialsReg = new RegExp(
    `(${partials.map((o) => escapeRegExp(o)).join('|')})`,
    'gi',
  );

  // Split input around the cursor
  const beforeCursor = listMut[idx].slice(0, pos);
  const afterCursor = listMut[idx].slice(pos);

  // Before the cursor there may be multiple matches. Only get the last one
  const matches = [...beforeCursor.matchAll(partialsReg)];
  const lastMatch = matches[matches.length - 1];
  const matchText = lastMatch?.[0];

  // Check that there is a match and the cursor is at the match location
  // If so remove the partial and append template string
  const beforeCursorResult =
    lastMatch && lastMatch.index === pos - matchText.length
      ? beforeCursor.slice(0, -1 * matchText.length) + templateOption
      : beforeCursor + templateOption;

  listMut[idx] = beforeCursorResult + afterCursor;

  return listMut.join('');
}

const TYPES = {
  LITERAL: 'LITERAL',
  REF: 'REF',
};

/**
 * Map the title string to the API schema
 *
 * @param   {string}   title
 * @param   {object[]} fieldTypes
 * @returns {object}
 */
export function mapTitleStringToSchema(title, fieldTypes) {
  const stringFields = fieldTypes.filter(
    (field) => field.schema.type === THING_TYPE_FIELD_TYPES.STRING,
  );
  const fieldNames = stringFields.map((field) => normalizeString(field.name));

  const templateOptions = fieldNames.map((name) => `<${name}>`);
  const list = getRenderList(title, getFieldQuery(fieldNames));

  return {
    type: 'COMPUTED_VALUE',
    value: list
      .map((entry) =>
        includesWordMatch(templateOptions, entry)
          ? {
              type: TYPES.REF,
              value:
                stringFields[
                  templateOptions.findIndex((t) => isWordMatch(t, entry))
                ].uuid,
            }
          : { type: TYPES.LITERAL, value: entry },
      )
      .filter((segment) => !!segment.value),
  };
}

/**
 * Parse a title string from the thingType
 *
 * @param   {ThingType} thingType
 * @returns {string}
 */
export function titleFieldToString(thingType) {
  if (!thingType) return '';

  const titleField = thingType?.fieldTypes.find(
    (field) => field.name === RESTRICTED_THING_FIELDS.DICE_TITLE,
  );
  const { fieldTypes } = thingType;

  const fieldOptions = Object.fromEntries(
    fieldTypes
      .filter((field) => field.schema.type === THING_TYPE_FIELD_TYPES.STRING)
      .map((field) => [field.uuid, field.name]),
  );

  // Handle null title
  return titleField?.value?.value
    ? titleField?.value?.value.reduce(
        (title, segment) =>
          segment.type === TYPES.REF
            ? `${title}<${fieldOptions[segment.value]}>`
            : title + segment.value,
        '',
      )
    : '';
}

/**
 *
 * @param   {ThingType|null}    thingType
 * @param   {TypedEntry[]} typedFields
 * @returns {string}
 */
export function titleDisplayStringFromFieldValues(thingType, typedFields) {
  const titleFieldSchema = thingType?.fieldTypes.find(
    (f) => f.uuid === thingType.titleFieldTypeUuid,
  )?.value;

  if (!titleFieldSchema) return '';

  const valueMap = typedFields.reduce(
    (hashMap, current) => ({
      ...hashMap,
      [current.uuid]: {
        value: current.value === '' ? null : current.value,
        name: current.name,
      },
    }),
    {},
  );

  return titleFieldSchema.value.reduce(
    (displayTitle, segment) =>
      segment.type === TYPES.LITERAL
        ? displayTitle + segment.value
        : `${displayTitle}${
            valueMap[segment.value]?.value ?? valueMap[segment.value]?.name
          }`,
    '',
  );
}

/**
 * Convert an API thingType to the create/edit form schema
 *
 * @param   {ThingType} thingType
 * @param   {object}    initialFiles
 * @returns {object}
 */
export function thingTypeToFormSchema(thingType = null, initialFiles = {}) {
  const primaryImageFieldTypeUuid =
    thingType?.primaryImageFieldTypeUuid ?? uuidv4();

  const titleFieldTypeUuid = thingType?.titleFieldTypeUuid ?? uuidv4();

  const primaryImage = thingType?.fieldTypes?.find(
    (field) => field.name === RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE,
  );

  const primaryImageField =
    thingType && primaryImage
      ? fieldTypeForEditing(primaryImage)
      : {
          name: RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE,
          uuid: primaryImageFieldTypeUuid,
          schema: { type: THING_TYPE_FIELD_TYPES.IMAGE },
          value: null,
        };

  return {
    name: thingType?.name ?? '',
    fieldTypes:
      thingType?.fieldTypes
        .filter(
          (field) =>
            !includesWordMatch(
              Object.values(RESTRICTED_THING_FIELDS),
              field.name,
            ),
        )
        .map((field) => fieldTypeForEditing(field)) ?? [],
    titleFieldTypeUuid,
    primaryImageFieldTypeUuid,
    // Placeholder fields that will be remapped on submission
    title: titleFieldToString(thingType),
    titleField: {
      name: RESTRICTED_THING_FIELDS.DICE_TITLE,
      value: null,
      uuid: titleFieldTypeUuid,
      schema: { type: THING_TYPE_FIELD_TYPES.STRING },
      required: true,
    },
    primaryImageField,
    files: initialFiles,
  };
}

/**
 *
 * @param   {ThingType}            thingType
 * @param   {ThingMediaFile[]}     thingMedia
 * @returns {Record<string, File>}
 */
export function mapFieldFiles(thingType, thingMedia) {
  if (!thingType || !thingMedia) return {};

  /** @type {Record<string, File>} */
  const files = {};
  thingMedia.forEach((file) => {
    // Find the field with a value equal to the file id
    const field = thingType.fieldTypes.find(
      (fieldType) => fieldType?.value?.value === file.uuid,
    );

    // Save the file to a new object associated with the field uuid (not the file uuid)
    files[field.uuid] = file.file;
  });
  return files;
}

/**
 * Clone a thing type form schema and assign new field UUIDs
 *
 * @param   {ThingType}           thingType
 * @param   {object}              thingMedia
 * @returns {{
 *  clonedMedia: object,
 *  clonedType: object
 * }}
 */
export function copyThingType(thingType, thingMedia = {}) {
  if (!thingType) return {};
  const clonedMedia = {};
  const clonedType = clone(thingType);

  // Get the index of the title field
  const titleFieldIdx = clonedType.fieldTypes.findIndex(
    (f) => f.name === RESTRICTED_THING_FIELDS.DICE_TITLE,
  );

  // Save a copy of the title schema if available
  const thingTitleValue =
    titleFieldIdx >= 0
      ? clonedType.fieldTypes[titleFieldIdx].value?.value
      : null;

  // Assign new fieldType uuids to all fields
  // Re-associate files with new field uuids
  // Re-associated field UUIDs in the title schema
  clonedType.fieldTypes = clonedType.fieldTypes.map((f) => ({
    ...f,
    uuid: uuidv4(),
    oldUuid: f.uuid,
  }));

  clonedType.fieldTypes.forEach((f) => {
    if (f.oldUuid in thingMedia) {
      f.value.value = null; // Clear old media reference
      clonedMedia[f.uuid] = thingMedia[f.oldUuid];
    }

    if (f.name === RESTRICTED_THING_FIELDS.DICE_TITLE)
      clonedType.titleFieldTypeUuid = f.uuid;

    if (f.name === RESTRICTED_THING_FIELDS.DICE_PRIMARY_IMAGE)
      clonedType.primaryImageFieldTypeUuid = f.uuid;

    // Remap title schema uuids if needed
    if (thingTitleValue) {
      thingTitleValue.forEach((segment) => {
        if (segment.value === f.oldUuid) segment.value = f.uuid;
      });
    }
  });

  if (clonedType.fieldTypes[titleFieldIdx]?.value?.value) {
    clonedType.fieldTypes[titleFieldIdx].value.value = thingTitleValue;
  }

  return {
    clonedType: {
      ...clonedType,
      thingsCount: 0,
      uuid: uuidv4(),
      name: `Copy of ${clonedType.name}`,
    },
    clonedMedia,
  };
}
