import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

type XTagDeactivationModalProps = {
  open: boolean;
  onCancel: () => void;
  onError: () => void;
  onSuccess: () => void;
  thing: Thing;
  xTagUuid: string;
};

export default function XTagDeactivationModal({
  open,
  onCancel,
  onError,
  onSuccess,
  thing,
  xTagUuid,
}: XTagDeactivationModalProps) {
  const { thingsApi } = useRequest();

  const handleSuccess = () => {
    void onSuccess();
  };

  const handleError = () => {
    void onError();
  };

  const handleCancel = () => {
    void onCancel();
  };

  const handleSubmit = async () => {
    const result = await thingsApi.unbindXTag({
      thingUuid: thing.uuid,
      xtagUuid: xTagUuid,
    });

    if (!result?.error) {
      handleSuccess();
    } else {
      handleError();
    }
  };

  return (
    <DustModal
      footerProps={{
        submitLabel: 'Deactivate',
        onCancel: handleCancel,
        onSubmit: handleSubmit,
      }}
      onClose={handleCancel}
      open={open}
      title="Tag Deactivation"
    >
      <div>Are you sure you want to deactivate this Tag?</div>
    </DustModal>
  );
}
