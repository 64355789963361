import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import styles from './AntiTamperWarningModal.module.css';

type AntiTamperWarningModalProps = {
  modalstate: boolean;
  handleModalReturn: () => void;
  handleModalDiscard: () => void;
};

export default function AntiTamperWarningModal({
  modalstate,
  handleModalDiscard,
  handleModalReturn,
}: AntiTamperWarningModalProps) {
  return (
    <Dialog open={modalstate} aria-labelledby="anti-tamper-warning-dialog">
      <DialogTitle component="h3" className={styles.modalTitle}>
        Leave without completing analysis?
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="p" className={styles.modalContentText}>
          Do you want to discard analysis of this tag? Select “Leave without
          Saving” to exit or “Return” to go back to analysis.
        </DialogContentText>
      </DialogContent>
      <DialogActions className={styles.modalAction}>
        <Button
          onClick={handleModalReturn}
          variant="contained"
          className={`${styles.returnButton} ${styles.modalButtons}`}
        >
          return
        </Button>
        <Button
          onClick={handleModalDiscard}
          color="primary"
          variant="contained"
          className={styles.modalButtons}
        >
          Leave without saving
        </Button>
      </DialogActions>
    </Dialog>
  );
}
