import { useMemo, useState } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';

import useThingData from '@/common/entities/things/useThingData';
import useThingsData, {
  SORT_OPTIONS,
  thingSelectorSortAtom,
} from '@/common/entities/things/useThingsData';
import useThingTypesData from '@/common/entities/thingTypes/useThingTypesData';
import { ROUTES } from '@/common/routes';
import {
  makeMixedThingTypeLookupColDef,
  relationshipsTableTheme,
} from '@/common/utilities/relationships/constants';
import { useFetchMoreToFillHeightRef } from '@/common/utilities/table';
import { keyBy } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustLoader from '@/components/Library/DustLoader';
import DustModal from '@/components/Library/DustModal';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustStepperFooter from '@/components/Library/DustStepperFooter';
import {
  createdAtThingColDef,
  dustedThingColDef,
  idThingColDef,
  titleThingColDef,
} from '@/components/Library/sharedThingColDefs';
import useRequest from '@/services/requests/useRequest';
import useSort from '@/services/useSort';

export default function AddParent() {
  const { thingId } = useParams();
  if (!thingId) throw new Error('Missing url Id');

  const { tableSortProps, applyColumnSortProps, sortRequestParams } = useSort(
    thingSelectorSortAtom,
    SORT_OPTIONS,
  );

  const { thingTypes = [] } = useThingTypesData({ fetchAll: true });

  const {
    thing,
    isSuccess,
    isLoading: isThingLoading,
  } = useThingData({
    thingUuid: thingId,
  });

  const { things, getNextPage, isFetching, totalItems, setFilter } =
    useThingsData({
      fixedParams: { canBeParentOf: thingId, ...sortRequestParams.fixedParams },
      enabled: isSuccess,
    });

  const { thingsApi } = useRequest();
  const navigate = useNavigate();

  const [parentModalOpen, setParentModalOpen] = useState(false);
  const [selectedThing, setSelectedThing] = useState<Thing | null>(null);

  const handleSelection = (selection: string) => {
    const matchedThing = things.find((theThing) => theThing.uuid === selection);
    if (matchedThing) {
      setSelectedThing(matchedThing);
      setParentModalOpen(true);
    }
  };

  const handleSetParent = async () => {
    if (!selectedThing) return;

    await thingsApi.updateThingChildren({
      thingUuid: selectedThing.uuid,
      addUuids: [thingId],
    });
    navigate(ROUTES.THING(thingId));
  };

  const columns = useMemo(() => {
    const thingTypesMap = keyBy(thingTypes, 'uuid');

    const cols: GridColDef<Thing>[] = [
      idThingColDef,
      titleThingColDef,
      dustedThingColDef,
      makeMixedThingTypeLookupColDef(thingTypesMap),
      createdAtThingColDef,
    ];
    return applyColumnSortProps(cols);
  }, [thingTypes, applyColumnSortProps]);

  const tableContainerRef = useFetchMoreToFillHeightRef(
    getNextPage,
    things.length,
  );

  if (isThingLoading) {
    return <DustLoader size="large" />;
  }

  return (
    <>
      <DustSearchInput setValue={(value) => setFilter('search', value)} />
      <div className="flex-1 border mt-1" ref={tableContainerRef}>
        <DustDataGrid
          rowCount={totalItems}
          loading={isFetching}
          altTheme
          columns={columns}
          onSelectionModelChange={(selected) =>
            handleSelection(selected[0] as string)
          }
          rows={things}
          getRowId={(t) => t.uuid}
          onRowsScrollEnd={() => {
            void getNextPage();
          }}
          sx={relationshipsTableTheme}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableSortProps}
        />
      </div>
      <DustStepperFooter
        submitColor="error"
        submitLabel="Cancel"
        onSubmit={() => navigate(-1)}
      />
      <DustModal
        open={parentModalOpen}
        onClose={() => setParentModalOpen(false)}
        title="Are you sure?"
        footerProps={{
          submitColor: 'info',
          submitLabel: 'Confirm',
          loading: false,
          onSubmit: handleSetParent,
          onCancel: () => setParentModalOpen(false),
        }}
      >
        <div>
          {thing && selectedThing && (
            <p>
              You are about to add <strong>{selectedThing.title}</strong> as the
              parent of <strong>{thing.title}</strong>.{' '}
              <strong>{thing?.title}</strong> and all of its children will be
              moved into the <strong>{selectedThing.title}</strong> relationship
              tree.
            </p>
          )}
        </div>
      </DustModal>
    </>
  );
}
