import { Box, Button, Typography } from '@mui/material';

import { formatDateForDisplay } from '@/common/dates';
import DustDustedIcon from '@/components/Library/Icons/DustDustedIcon';

import styles from './AntiTamperNewSession.module.css';

type AntiTamperNewSessionProps = {
  handleNewScanButton: () => void;
  handleVerifiedScanButton: () => void;
  isVerifiedSession: boolean;
  pageVerifiedTime: number;
};

export default function AntiTamperNewSession({
  handleNewScanButton,
  handleVerifiedScanButton,
  isVerifiedSession,
  pageVerifiedTime,
}: AntiTamperNewSessionProps) {
  const logoIcon = (
    <DustDustedIcon color="primary" sx={{ width: 75, height: 90 }} />
  );

  const lastVerifiedTime =
    isVerifiedSession && formatDateForDisplay(new Date(pageVerifiedTime));
  return (
    <Box className={styles.root}>
      <Box className={styles.container}>
        {logoIcon}
        <Typography component="p" variant="body1" className={styles.text}>
          Analyze tag for suspected tamper.
        </Typography>
        {isVerifiedSession && (
          <>
            <Button
              variant="outlined"
              color="primary"
              className={styles.useVerifiedScanButton}
              onClick={() => handleVerifiedScanButton()}
            >
              Use Last Verified Scan ({lastVerifiedTime})
            </Button>
            <Button
              className={styles.takeNewScanButton}
              onClick={handleNewScanButton}
            >
              Take New Scan
            </Button>
          </>
        )}
        {!isVerifiedSession && (
          <Button
            variant="outlined"
            color="primary"
            className={styles.scanButton}
            onClick={handleNewScanButton}
          >
            Scan
          </Button>
        )}
      </Box>
    </Box>
  );
}
