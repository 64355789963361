import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';

function DustFilterChip({
  sx = null,
  ...props
}: React.ComponentProps<typeof Chip>) {
  return (
    <Chip
      deleteIcon={
        <CloseIcon sx={{ color: 'white !important', width: '1rem' }} />
      }
      {...props}
      /* Normal color override wasn't working on the close icon */
      sx={{
        height: '1.5rem',
        backgroundColor: 'var(--medium-grey)',
        color: 'var(--white)',
        marginRight: '0.5rem',
        ...sx,
      }}
    />
  );
}

export default DustFilterChip;
