import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';

import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import useThingMediaData from '@/common/entities/thingMedia/useThingMediaData';
import { ACCEPTED_IMAGE_TYPE_LIST } from '@/common/entities/things/constants';
import { getAddThingInitialValues } from '@/common/entities/things/models';
import { addThingSchema } from '@/common/entities/things/schemas';
import { thingFromForm } from '@/common/entities/things/utility';
import useNavigationPrompt from '@/common/hooks/useNavigationPrompt';
import useToasts from '@/common/hooks/useToasts';
import CatalogListRecoilSelector from '@/components/Composed/CatalogListSelector/CatalogListRecoilSelector';
import ThingPrimaryImage from '@/components/Composed/ThingFileModal/ThingPrimaryImage';
import ThingTypeSelector from '@/components/Composed/ThingTypeSelector/ThingTypeSelector';
import { FILE_TYPE_OPTIONS } from '@/components/Library/constants';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustFieldLabel from '@/components/Library/DustFieldLabel';
import DustFileUploadField from '@/components/Library/DustFileUploadField';
import DustFileUploadMulti from '@/components/Library/DustFileUploadMulti';
import DustLoader from '@/components/Library/DustLoader';
import { combineClass } from '@/components/Library/utility';
import ThingDataFields from '@/components/Pages/Things/ThingAdd/ThingDetailsSingle/ThingDataFields';
import { selectedCatalogAtom } from '@/state/atoms/catalog';

import styles from './ThingDetailsSingle.module.css';
import ThingDetailTitle from './ThingDetailTitle';
import ThingStaticFile from './ThingStaticFile';

type Props = {
  onSubmit: (e: AddThingSubmission) => void | Promise<void>;
  formId: string;
  setSubmitLabel: Dispatch<SetStateAction<string>>;
  disableAll?: boolean;
};

// ---------------------------------------------------------------------------
// PRIMARY COMPONENT
export default function ThingDetailsSingle({
  onSubmit,
  formId,
  setSubmitLabel,
  disableAll = false,
}: Props) {
  const { addToast } = useToasts();

  const catalog = useRecoilValue(selectedCatalogAtom);

  const [thingType, setThingType] = useState<ThingType | null>(null);
  const isTyped = !!thingType;

  const [typedSchema, setTypedSchema] = useState(
    getAddThingInitialValues(null, catalog?.uuid),
  );

  const [primaryFromType, setPrimaryFromType] = useState<ThingMediaFile | null>(
    null,
  );

  const [submittedForm, setSubmittedForm] = useState(false);

  const formik = useFormik({
    initialValues: typedSchema,
    validationSchema: addThingSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      setSubmittedForm(true);
      // Format values into thing and return
      await onSubmit(thingFromForm(values)); // Pass things to parent and advance
    },
  });

  // Note - navigation happens in the parant component
  useNavigationPrompt(formik.dirty && !submittedForm);

  const toggleThingType = async (val: any) => {
    if (!val) setThingType(null);
    await formik.setFieldValue('useThingType', val);
  };

  // Must load static files for thingType to display
  const { isLoading: isMediaLoading, thingMedia } = useThingMediaData({
    uuids: thingType?.mediaUuids ?? [],
    enabled: !!thingType && thingType?.mediaUuids.length > 0,
  });

  // Update the form whenever the catalog changes
  const { setFieldValue } = formik;
  useEffect(() => {
    setFieldValue('catalogUuid', catalog?.uuid ?? '').catch((err) =>
      console.error(err),
    );
  }, [catalog, setFieldValue]);

  // ---------------------------------------------------------------------------
  // Use a dynamic schema to handle thing types

  // Form field schema config
  const formikRef = useRef(formik);
  formikRef.current = formik;
  const catalogUuidRef = useRef(catalog?.uuid);
  catalogUuidRef.current = catalog?.uuid;
  useEffect(() => {
    setPrimaryFromType(null);

    const newInitialValues = thingType
      ? getAddThingInitialValues(thingType, catalogUuidRef.current)
      : getAddThingInitialValues(null, catalogUuidRef.current);

    setTypedSchema(newInitialValues);
    formikRef.current.resetForm({ values: newInitialValues });
  }, [thingType]);

  // Set dynamic submit button text in parent
  useEffect(() => {
    setSubmitLabel('Create');
  }, [setSubmitLabel]);

  // Separate static files and Images
  // TODO: API does not sort static images and files. All have type = 'file' so we
  // have to manually check the media fileType. If the API is improved this step
  // can be eliminated
  const staticFiles = useMemo(() => {
    const mediaMap = Object.fromEntries(
      thingMedia.map((tMedia) => [tMedia.uuid, tMedia]),
    );
    const primaryImage = mediaMap[typedSchema.primaryImageField?.value] ?? null;
    if (primaryImage && !primaryFromType) {
      setPrimaryFromType(primaryImage);
    }

    return {
      files: typedSchema.typedStaticFiles
        .filter(
          (file) =>
            mediaMap[file.value.value] &&
            !ACCEPTED_IMAGE_TYPE_LIST.includes(
              mediaMap[file.value.value]?.fileType,
            ),
        )
        .map((f) => ({ ...f, media: mediaMap[f.value.value] })),
      images: typedSchema.typedStaticFiles
        .filter(
          (file) =>
            mediaMap[file.value.value] &&
            ACCEPTED_IMAGE_TYPE_LIST.includes(
              mediaMap[file.value.value]?.fileType,
            ),
        )
        .map((f) => ({ ...f, media: mediaMap[f.value.value] })),
    };
  }, [typedSchema, thingMedia, primaryFromType, setPrimaryFromType]);

  const handleReject = (errors: string[]) => {
    errors.forEach((error) => addToast(error, 'error'));
  };

  return (
    <form
      className={combineClass(styles.form, !isTyped && 'flex-col gap-1')}
      id={formId}
      onSubmit={formik.handleSubmit}
    >
      <div style={{ maxWidth: 'min(30rem, 100%)', width: '30rem' }}>
        <DustFieldLabel tag="h2" className="h4 mb-1" label="Catalog" />
        <CatalogListRecoilSelector
          error={
            formik.errors.catalogUuid
              ? 'Catalog is a required field'
              : undefined
          }
        />
        <DustFieldLabel tag="h2" className="h4 mt-2" label="Thing Type" />
        <DustCheckbox
          style={{ marginBottom: '.5rem' }}
          checked={formik.values.useThingType}
          disabled={disableAll}
          label="Apply Thing Type"
          name="useThingType"
          onChange={toggleThingType}
        />
        {formik.values.useThingType && (
          <ThingTypeSelector
            disabled={disableAll}
            setValue={setThingType}
            value={thingType?.name ?? ''}
            fullWidth
          />
        )}
        <DustFieldLabel tag="h2" className="h4 mt-2" label="Thing Title" />
        <ThingDetailTitle
          formik={formik}
          disableAll={disableAll}
          thingType={thingType}
        />
        <ThingPrimaryImage formik={formik} primaryFromType={primaryFromType} />

        {thingType && (
          <>
            {typedSchema.typedMetadata.length > 0 && (
              <DustFieldLabel
                tag="h3"
                className="mt-2"
                label={`${thingType.name} Data Fields`}
              />
            )}
            <ThingDataFields disableAll={disableAll} isTyped formik={formik} />
            {(typedSchema.typedFiles.length > 0 ||
              typedSchema.typedStaticFiles.length > 0) && (
              <DustFieldLabel
                tag="h3"
                className="mt-2"
                label={`${thingType.name} Files and Images`}
              />
            )}
            {typedSchema.typedFiles.length > 0 && (
              <h4 className={combineClass(styles.fileOriginLabel, 'mt-1')}>
                File placeholders from type
              </h4>
            )}
            {typedSchema.typedFiles.map((file, idx) =>
              file.multiple ? (
                <DustFileUploadMulti
                  fileType={file.type}
                  formik={formik}
                  key={file.name}
                  label={`${file.name}${file.required ? '*' : ''}`}
                  name={`typedFiles.${idx}.value`}
                  onReject={handleReject}
                />
              ) : (
                <DustFileUploadField
                  fileType={file.type}
                  formik={formik}
                  key={file.name}
                  label={`${file.name}${file.required ? '*' : ''}`}
                  name={`typedFiles.${idx}.value`}
                  onReject={handleReject}
                />
              ),
            )}
            {isMediaLoading ? (
              <DustLoader size="large" />
            ) : (
              (staticFiles.images.length > 0 ||
                staticFiles.files.length > 0) && (
                <div className="flex-row gap-1 mt-1">
                  <div className="flex-1 min-w-0">
                    <h4 className={styles.fileOriginLabel}>
                      Images added by type
                    </h4>
                    {staticFiles.images.map((file) => (
                      <ThingStaticFile
                        key={file.uuid}
                        file={file}
                        media={file.media}
                      />
                    ))}
                  </div>
                  <div className="flex-1 min-w-0">
                    <h4 className={styles.fileOriginLabel}>
                      Files added by type
                    </h4>
                    {staticFiles.files.map((file) => (
                      <ThingStaticFile
                        key={file.uuid}
                        file={file}
                        media={file.media}
                      />
                    ))}
                  </div>
                </div>
              )
            )}
          </>
        )}
      </div>
      <div style={{ maxWidth: 'min(30rem, 100%)', width: '30rem' }}>
        <DustFieldLabel
          tag="h2"
          className="h4"
          label={isTyped ? 'Additional Information' : 'Thing Details'}
        />
        {isTyped && (
          <p className="mt-1">
            Use these fields to add additional information not managed by the
            Thing Type
          </p>
        )}
        <DustFieldLabel
          tag="h3"
          className="mt-2"
          label={isTyped ? 'Additional Data Fields' : 'Data Fields'}
        />
        <ThingDataFields
          isTyped={false}
          disableAll={disableAll}
          formik={formik}
        />
        {/* General file upload options */}
        <DustFieldLabel
          tag="h3"
          className="mt-2"
          label={isTyped ? 'Additional Files and Images' : 'Files and Images'}
        />
        <DustFileUploadMulti
          fileType={FILE_TYPE_OPTIONS.ALL}
          formik={formik}
          label={thingType ? 'Other Files and Images' : 'Files and Images'}
          name="files.value"
          onReject={handleReject}
        />
      </div>
    </form>
  );
}
