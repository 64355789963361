import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

import { shoppingCartModel } from './models';

const STALE_TIME = 5 * 60 * 1000;

export default function useShoppingCartData({ enabled = true }) {
  const { shoppingCartsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.SHOPPING_CART,
    () =>
      shoppingCartsApi.getShoppingCart({
        fields: ['root', 'children', 'parent'],
      }),
    { enabled, staleTime: STALE_TIME },
  );

  // Provide default cart object to avoid lots of optional chaining
  // (NOTE: old behavior, should actually be undefined
  const emptyCart = shoppingCartModel(
    {
      shoppingCartUuid: null,
      thingUuids: [],
    } as any /* Old behavior */,
  );

  return {
    shoppingCart: data && !data.error ? data.data : emptyCart,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
