import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { Tooltip } from '@mui/material';
import {
  GridColDef,
  GridEventListener,
  GridSelectionModel,
} from '@mui/x-data-grid';

import { formatDateForDisplay } from '@/common/dates';
import useCatalogsData from '@/common/entities/catalogs/useCatalogsData';
import useFacilitiesData from '@/common/entities/facilities/useFacilitiesData';
import { TRANSACTION_TYPES } from '@/common/entities/transactions/constants';
import useTransactionsData, {
  FILTER_LABELS,
  filterToTrackingProp,
  TRANSACTION_SORT_OPTIONS,
  TransactionFilters,
  transactionsSortAtom,
  TRANSACTION_SORT_TRACKING_EVENT,
} from '@/common/entities/transactions/useTransactionsData';
import useNavigateWithParams from '@/common/hooks/useNavigateWithParams';
import { ROUTES } from '@/common/routes';
import { useFetchMoreToFillHeightRef } from '@/common/utilities/table';
import { downloadBlob } from '@/common/utility';
import ProgressModal from '@/components/Composed/ProgressModal/ProgressModal';
import TransactionsFilters from '@/components/Composed/TransactionFilters/TransactionsFilters';
// TODO DICE3-662 this functionality will be reenabled shortly
import DustActionButton from '@/components/Library/DustActionButton';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustFilterChip from '@/components/Library/DustFilterChip';
import DustLinkButton from '@/components/Library/DustLinkButton';
import DustStatusChip from '@/components/Library/DustStatusChip';
import VerifiedShield from '@/components/Library/Svg/VerifiedShield';
import { Mixpanel } from '@/mPanel';
import useRequest from '@/services/requests/useRequest';
import useSort from '@/services/useSort';

import styles from './Transactions.module.css';

export default function TransactionsTable() {
  const navigate = useNavigateWithParams();
  const { transactionsApi } = useRequest();

  const {
    applyColumnSortProps,
    tableSortProps,
    sortRequestParams,
    selectedSort,
  } = useSort(transactionsSortAtom, TRANSACTION_SORT_OPTIONS, {
    trackingEvent: TRANSACTION_SORT_TRACKING_EVENT,
  });

  const handleThingClick = useCallback(
    (evt: MouseEvent<HTMLButtonElement>, thingUuid: string) => {
      evt.stopPropagation();
      navigate(ROUTES.THING(thingUuid));
    },
    [navigate],
  );

  // Use name hash maps for table performance
  const { isLoading: catalogsLoading, catalogs } = useCatalogsData({
    fetchAll: true,
  });
  const catalogLookup = catalogs.reduce<{ [x: string]: string }>(
    (lookup, catalog) => ({ ...lookup, [catalog.uuid]: catalog.name }),
    {},
  );

  const { isLoading: facilitiesLoading, facilities } = useFacilitiesData({});
  const facilityLookup = facilities.reduce<{ [x: string]: string }>(
    (lookup, facility) => ({ ...lookup, [facility.uuid]: facility.name }),
    {},
  );

  const {
    transactions,
    totalItems,
    getNextPage,
    isLoading: transactionsLoading,
    isFetching,
    appliedFilters,
    filters,
    setFilter,
    resetFilter,
    resetFilters,
    queryParams,
  } = useTransactionsData(sortRequestParams);

  const isLoading = transactionsLoading || catalogsLoading || facilitiesLoading;

  function onRowClick(transaction: Transaction) {
    navigate(ROUTES.TRANSACTION(transaction.uuid));
  }

  const handleRowClick: GridEventListener<'rowClick'> = (row) => {
    const transaction = transactions?.find((t) => t.uuid === row.id);
    if (transaction) {
      Mixpanel.track('Navigate to Transaction Details', {
        'Transaction ID': transaction.uuid,
        From: 'Transactions Table',
        'Transaction Type': transaction.transactionType,
        Verified: transaction.isVerified,
        Outcome: transaction.outcomeLabel,
        'Date Created': transaction.createdAt,
      });
      onRowClick(transaction);
    }
  };

  const [selected, setSelected] = useState<string[]>([]);

  const handleRowSelection = (items: GridSelectionModel) =>
    setSelected(items as string[]);

  const [showExportBlock, setShowExportBlock] = useState(false);

  const baseTrackingData = useMemo(
    () => ({
      ...Object.fromEntries(
        Object.entries(filters).map(([key, value]) =>
          filterToTrackingProp(key as keyof TransactionFilters, value),
        ),
      ),
      'Sort By':
        TRANSACTION_SORT_OPTIONS[selectedSort]?.label ?? 'Unknown sort column',
    }),
    [filters, selectedSort],
  );

  const exportTrackingData = (
    startTime: number,
    success: boolean,
    type: 'All transactions' | 'Selected transactions',
  ) => ({
    ...baseTrackingData,
    Success: success,
    'Export Time (s)': Math.floor((Date.now() - startTime) / 1000),
    'Export Type': type,
  });

  const handleExport = async () => {
    const startTime = Date.now();
    setShowExportBlock(true);

    const res = await transactionsApi.export({
      ...queryParams,
    });

    setShowExportBlock(false);

    if (!res.error) {
      downloadBlob(res.data.file, 'transactions_export.csv');
    }

    Mixpanel.track(
      'Export Transactions',
      exportTrackingData(startTime, !res.error, 'All transactions'),
    );
    return !res.error;
  };

  const handleExportSelected = async () => {
    const startTime = Date.now();
    setShowExportBlock(true);

    const res = await transactionsApi.export({
      transactionUuids: selected,
    });

    setShowExportBlock(false);

    if (!res.error && res.data.file) {
      downloadBlob(res.data.file, 'transactions_export.csv');
      setSelected([]);
    }

    Mixpanel.track('Export Transactions', {
      ...exportTrackingData(startTime, !res.error, 'Selected transactions'),
      Count: selected.length,
    });

    return !res.error;
  };

  const handleFiltersOpened = useCallback(() => {
    Mixpanel.track('View Transaction Filters', baseTrackingData);
  }, [baseTrackingData]);

  const handleSetFilter = useCallback(
    (
      filterName: keyof TransactionFilters,
      filterValue: Date | string[] | null,
    ) => {
      const trackValues = {
        ...baseTrackingData,
        ...Object.fromEntries([filterToTrackingProp(filterName, filterValue)]),
      };
      Mixpanel.track('Filter Transactions', trackValues);
      setFilter(filterName, filterValue);
    },
    [setFilter, baseTrackingData],
  );

  // ---------------------------------------------------------------------------
  // TABLE DATA

  const columns = useMemo(() => {
    const commonLayout = { flex: 1, minWidth: 150 };

    const baseColumns: GridColDef<Transaction>[] = [
      {
        field: 'uuid',
        headerName: 'UUID',
        hide: true,
      },
      {
        field: 'thingTitle',
        headerName: 'Thing Title',
        renderCell: ({ row: { thingTitle, thingUuid } }) => (
          <Tooltip title={thingTitle ?? ''}>
            <DustLinkButton onClick={(evt) => handleThingClick(evt, thingUuid)}>
              {thingTitle}
            </DustLinkButton>
          </Tooltip>
        ),
        ...commonLayout,
      },
      {
        field: 'transactionType',
        valueGetter: ({ row }) => TRANSACTION_TYPES[row.transactionType]?.label,
        headerName: 'Transaction Type',
        ...commonLayout,
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        ...commonLayout,
      },
      {
        field: 'isVerified',
        renderCell: ({ value }) =>
          value ? <VerifiedShield className={styles.dustedIcon} /> : '',
        headerName: 'Verified',
        ...commonLayout,
      },
      {
        field: 'outcome',
        renderCell: ({ row }) => (
          <DustStatusChip
            label={row.outcomeLabel}
            status={row.outcomeStatus}
            sx={{ minWidth: '6.25rem' }}
          />
        ),
        headerName: 'Outcome',
        ...commonLayout,
      },
      {
        field: 'catalogUuid',
        valueGetter: ({ row }) => catalogLookup[row.catalogUuid],
        headerName: 'Catalog',
        ...commonLayout,
      },
      {
        valueGetter: ({ row }) => facilityLookup[row.facilityUuid],
        field: 'facilityUuid',
        headerName: 'Facility',
        ...commonLayout,
      },
      {
        field: 'date',
        valueGetter: ({ row }) => row.createdAt,
        valueFormatter: ({ value }) => formatDateForDisplay(value),
        headerName: 'Created at',
        sortingOrder: ['desc', 'asc'],
        ...commonLayout,
      },
    ];
    return applyColumnSortProps(baseColumns);
  }, [applyColumnSortProps, catalogLookup, facilityLookup, handleThingClick]);

  const containerRef = useFetchMoreToFillHeightRef(
    getNextPage,
    transactions.length,
  );

  return (
    <>
      {/* HEADER */}
      <div className="flex-row">
        <DustActionButton
          actionList={[
            {
              name: 'Export Selected Transactions',
              action: handleExportSelected,
              disabled: selected.length < 1,
            },
            {
              name: 'Export All Transactions',
              action: handleExport,
            },
          ]}
        />
        <div className="flex-1" />
        <TransactionsFilters
          filters={filters}
          resetFilter={resetFilter}
          resetFilters={resetFilters}
          setFilter={handleSetFilter}
          showResetFilters={appliedFilters.length > 0}
          onOpen={handleFiltersOpened}
        />
      </div>
      {/* CONTENT */}
      <div className={styles.chips}>
        {appliedFilters.map((filter) => (
          <DustFilterChip
            key={filter}
            label={FILTER_LABELS[filter]}
            onDelete={() => resetFilter(filter)}
          />
        ))}
        {appliedFilters.length > 0 && (
          <DustLinkButton onClick={resetFilters} sx={{ margin: '0 .5rem' }}>
            Clear filters
          </DustLinkButton>
        )}
      </div>
      <ProgressModal open={showExportBlock} />
      <div className="flex-row flex-1 border" ref={containerRef}>
        <DustDataGrid
          columns={columns}
          loading={isLoading || isFetching}
          onRowClick={handleRowClick}
          onRowsScrollEnd={getNextPage}
          selectionModel={selected}
          onSelectionModelChange={handleRowSelection}
          rows={transactions}
          getRowId={(r) => r.uuid}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
              {
                display: 'none',
              },
          }}
          rowCount={totalItems}
          altTheme
          checkboxSelection
          hideFooterPagination
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...tableSortProps}
        />
      </div>
    </>
  );
}
