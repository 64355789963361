import { useQuery } from 'react-query';

import useRequest from '@/services/requests/useRequest';

const emptyObj = Object.freeze({});
const STALE_TIME = 5 * 60 * 1000;

type HookProps = {
  catalogUuid?: string;
  enabled?: boolean;
};

export default function useThingMetaFilterData({
  catalogUuid = '',
  enabled = true,
}: HookProps) {
  const { thingsApi, QUERY_KEYS } = useRequest();

  const { data, isLoading, isSuccess } = useQuery(
    QUERY_KEYS.THING_META_FILTERS(catalogUuid),
    () => thingsApi.getMetadataFilters({ catalogUuid }),
    { enabled, staleTime: STALE_TIME },
  );

  return {
    metaFilters: data && !data.error ? data.data.filters : emptyObj,
    typedFilters: data && !data.error ? data.data.typedFilters : emptyObj,
    isLoading,
    isSuccess: isSuccess && !data?.error,
    isError: !!data?.error,
  };
}
