import { MouseEvent, useEffect, useState } from 'react';

import { Tooltip, Typography } from '@mui/material';

import styles from './Components.module.css';

type Props = {
  text: string;
  placeholder?: string | React.ReactNode;
  tooltip?: string;
  stopPropagation?: boolean;
};

export default function DustCopyableText({
  text,
  placeholder,
  tooltip = 'Copy',
  stopPropagation = true,
}: Props) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [copied]);

  const handleCopy = async (
    e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>,
  ) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    await navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return (
    <Tooltip
      title={copied ? 'Copied!' : tooltip}
      placement="bottom"
      classes={{ tooltip: copied ? styles.success : undefined }}
    >
      <Typography
        onClick={(e) => {
          handleCopy(e).catch((err) => console.error(err));
        }}
        sx={{ cursor: 'pointer', padding: '0.2em' }}
      >
        {placeholder || text}
      </Typography>
    </Tooltip>
  );
}
