import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorderTwoTone';

type Props = {
  favorite: boolean;
};

export default function DustFavoriteIcon({ favorite, ...props }: Props) {
  return favorite ? (
    <StarIcon sx={{ color: 'var(--warning-yellow)' }} {...props} />
  ) : (
    <StarBorderIcon {...props} />
  );
}
