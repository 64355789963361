import { ModeEditOutlineOutlined } from '@mui/icons-material';

import styles from './ThingTabs.module.css';

type Props = {
  fieldKey: string;
  value: Thing['metadata'][string] | Thing['typedMetadata'][string];
  onSelect: (fieldKey: string, isTyped: boolean) => void;
  disabled?: boolean;
};

export default function ThingFieldEntry({
  fieldKey,
  value,
  onSelect,
  disabled = false,
}: Props) {
  const isTyped = typeof value === 'object';
  return (
    <button
      disabled={disabled}
      type="button"
      className={styles.fieldEntry}
      onClick={() => onSelect(fieldKey, isTyped)}
    >
      <h3 className="field-label">{isTyped ? value?.name : fieldKey}</h3>
      <p>{isTyped ? value?.value : value}</p>
      {!disabled && (
        <div className={styles.fieldEditIcon}>
          <ModeEditOutlineOutlined sx={{ height: '18px', width: '18px' }} />
        </div>
      )}
    </button>
  );
}
