import { Tooltip } from '@mui/material';

import styles from './Components.module.css';
import { combineClass } from './utility';

type Props = {
  label: string;
  /** html tag */
  tag?: string;
  className?: string;
};

export default function DustFieldLabel({
  label,
  tag = 'div',
  className = '',
}: Props) {
  const hmap: { [x: string]: JSX.Element } = {
    h1: <h1 className={combineClass(styles.fieldLabel, className)}>{label}</h1>,
    h2: <h2 className={combineClass(styles.fieldLabel, className)}>{label}</h2>,
    h3: <h3 className={combineClass(styles.fieldLabel, className)}>{label}</h3>,
    h4: <h4 className={combineClass(styles.fieldLabel, className)}>{label}</h4>,
    h5: <h5 className={combineClass(styles.fieldLabel, className)}>{label}</h5>,
    h6: <h6 className={combineClass(styles.fieldLabel, className)}>{label}</h6>,
    div: (
      <div className={combineClass(styles.fieldLabel, className)}>{label}</div>
    ),
  };
  return (
    <Tooltip title={label} enterDelay={1000} placement="top-start">
      {hmap[tag] ?? hmap.div}
    </Tooltip>
  );
}
