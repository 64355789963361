import { AddToastCallback } from '@/common/hooks/useToasts';
import ApiService from '@/services/requests/ApiService';
import { MESSAGES } from '@/services/requests/constants';
import { DustSingleResponse } from '@/services/requests/types';
import { formatAPIError, formatAPIReturn } from '@/services/utility';

/** Factory function for producing the media API */
export default class FeedbackApi {
  constructor(
    private apiService: ApiService,
    private addToast: AddToastCallback,
  ) {}

  /** Create by Upload file */
  async submitFeedback({
    summary,
    description,
    feedbackType,
    pageUrl,
    files,
    noSensitiveInfo,
  }: {
    summary: string;
    description: string;
    feedbackType: string;
    pageUrl: string;
    files: { [x: string]: File };
    noSensitiveInfo: boolean;
  }) {
    const formData = new FormData();
    formData.append('summary', summary);
    formData.append('pageUrl', pageUrl);
    formData.append('description', description);
    formData.append('feedbackType', feedbackType);
    formData.append('noSensitiveInfo', noSensitiveInfo.toString());

    Object.keys(files).forEach((key) => {
      formData.append(key, files[key]);
    });

    return this.apiService
      .request({
        url: '/api/feedback',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      })
      .then((res: DustSingleResponse) => {
        this.addToast(MESSAGES.FEEDBACK.SUCCESS, 'success');
        return formatAPIReturn<{ uuid: string }, unknown>(res);
      })
      .catch((err) => {
        this.addToast(MESSAGES.FEEDBACK.FAIL, 'error');
        return formatAPIError(err);
      });
  }
}
