import { useMemo, useCallback } from 'react';

import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { GridColumns } from '@mui/x-data-grid';

import {
  DEFAULT_COLUMNS,
  RESTRICTED_COLUMNS,
  RESTRICTED_THING_FIELDS,
} from '@/common/entities/things/constants';
import { includesWordMatch, has, includes } from '@/common/utility';
import DustCheckbox from '@/components/Library/DustCheckbox';
import DustDraggableList from '@/components/Library/DustDraggableList';

import styles from './ThingSelector.module.css';
import TableFilter from '../TableFilter/TableFilter';

type CheckboxProps = {
  onChange: (e: boolean) => void;
  name: string;
  checked: boolean;
};
function CheckboxEntry({ onChange, name, checked }: CheckboxProps) {
  return (
    <DustCheckbox
      checked={checked}
      label={name}
      onChange={onChange}
      style={{ minWidth: '10rem', marginLeft: '-.5rem' }}
    />
  );
}

type Props = {
  columns: GridColumns<Thing>;
  typedFieldUuids: string[];
  selected?: string[];
  setSelected: (e: string[]) => void;
};

export default function ThingSelectorColumnFilter({
  columns,
  typedFieldUuids,
  selected = [],
  setSelected,
}: Props) {
  const selectedMap = useMemo(
    () => selected.reduce((acc, col) => ({ ...acc, [col]: true }), {}),
    [selected],
  );

  const toggleItem = useCallback(
    (checked: boolean, entry: string, currentSelected: string[]) =>
      checked
        ? setSelected([...currentSelected, entry])
        : setSelected(currentSelected.filter((e) => e !== entry)),
    [setSelected],
  );

  // Get a list of columns that can be filtered out
  // Ignore restricted columns and restricted thing type fields
  // Restricted columns are always shows
  // Restricted thing fields must be matched via name (not column id)
  const getFilterColumns = useCallback(
    (matchList: readonly string[]) =>
      columns
        .map((col) => ({
          id: col.field,
          field: col.field,
          name: col.headerName ?? 'MISSING_HEADER',
          checked: has(selectedMap, col.field),
          onChange: (checked: boolean) =>
            toggleItem(checked, col.field, selected),
        }))
        .filter(
          (col) =>
            !RESTRICTED_COLUMNS.includes(col.field) &&
            !includes(Object.values(RESTRICTED_THING_FIELDS), col.name) &&
            includesWordMatch(matchList, col.field),
        ),
    [selected, columns, selectedMap, toggleItem],
  );

  const selectedTypedFilterColumns = useMemo(
    () =>
      getFilterColumns(
        typedFieldUuids.filter((uuid) => has(selectedMap, uuid)),
      ).sort((a, b) => {
        const aIndex = selected.indexOf(a.id);
        const bIndex = selected.indexOf(b.id);

        return aIndex > bIndex ? 1 : -1;
      }),
    [getFilterColumns, selected, selectedMap, typedFieldUuids],
  );

  const unselectedTypedFilterColumns = useMemo(
    () =>
      getFilterColumns(
        typedFieldUuids.filter((uuid) => !has(selectedMap, uuid)),
      ).sort((a, b) => {
        const aIndex = selected.indexOf(a.id);
        const bIndex = selected.indexOf(b.id);

        if (aIndex < 0 && bIndex >= 0) {
          return 1;
        }
        if (aIndex >= 0 && bIndex < 0) {
          return -1;
        }
        return a.name.localeCompare(b.name);
      }),
    [selected, getFilterColumns, selectedMap, typedFieldUuids],
  );

  const defaultFilterColumns = useMemo(
    () => getFilterColumns(DEFAULT_COLUMNS),
    [getFilterColumns],
  );

  const handleDragEnd = useCallback(
    (evt: DragEndEvent) => {
      const { active, over } = evt;
      if (over?.id && active.id !== over.id) {
        const oldIndex = selected.findIndex((item) => item === active.id);
        const newIndex = selected.findIndex((item) => item === over.id);
        setSelected(arrayMove(selected, oldIndex, newIndex));
      }
    },
    [selected, setSelected],
  );

  const unselectedColData = useMemo(
    () =>
      unselectedTypedFilterColumns.map((e) => ({
        name: e.name,
        checked: e.checked,
        key: e.field,
        onChange: (checked: boolean) => toggleItem(checked, e.field, selected),
      })),
    [unselectedTypedFilterColumns, selected, toggleItem],
  );

  return (
    <TableFilter iconType="column" label="Columns to Display">
      {/* Basic fields */}
      {defaultFilterColumns.map((e) => (
        <span className={styles.pushRight} key={e.field}>
          <CheckboxEntry
            name={e.name}
            checked={e.checked}
            onChange={e.onChange}
          />
        </span>
      ))}
      {/* Typed fields */}
      {(selectedTypedFilterColumns.length > 0 ||
        unselectedTypedFilterColumns.length > 0) && (
        <div className="divider-1 h6">Fields</div>
      )}
      <span className={styles.pullLeft}>
        <DustDraggableList
          items={selectedTypedFilterColumns}
          ListItemComponent={CheckboxEntry}
          onDragEnd={handleDragEnd}
        />
      </span>

      {unselectedColData.map((e) => (
        <span className={styles.pushRight} key={e.key}>
          <CheckboxEntry
            name={e.name}
            checked={e.checked}
            onChange={e.onChange}
          />
        </span>
      ))}
    </TableFilter>
  );
}
