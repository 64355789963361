import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

import DustImageIcon from './Icons/DustImageIcon';

function DustThingChip({ showIcon = true, thing, ...props }) {
  return (
    <Chip
      icon={showIcon ? <DustImageIcon /> : undefined}
      label={thing?.title}
      sx={{ backgroundColor: 'var(--bg-primary)' }}
      {...props}
    />
  );
}

DustThingChip.propTypes = {
  thing: PropTypes.object,
  showIcon: PropTypes.bool,
};

export default DustThingChip;
