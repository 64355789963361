import { useMemo, useState } from 'react';

import { Button } from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import useExportBatchThingsData from '@/common/entities/exportBatches/useExportBatchThingsData';
import useThingsData from '@/common/entities/things/useThingsData';
import { getTreeDataPath } from '@/common/entities/things/utility';
import useToasts from '@/common/hooks/useToasts';
import { booleanSort } from '@/common/utilities/sort';
import { includes } from '@/common/utility';
import DustDataGrid from '@/components/Library/DustDataGrid';
import DustModal from '@/components/Library/DustModal';
import DustSearchInput from '@/components/Library/DustSearchInput';
import DustThingThumbnail from '@/components/Library/DustThingThumbnail';
import {
  createdAtThingColDef,
  idThingColDef,
  dustedThingColDef,
} from '@/components/Library/sharedThingColDefs';
import useRequest from '@/services/requests/useRequest';

import styles from './Checkout.module.css';

export function ExportBatchThings() {
  const { batchId } = useParams();
  const { exportBatchesApi } = useRequest();
  const { addToast } = useToasts();
  const [filter, setFilter] = useState('');

  // Get the list of minified things in the batch
  const {
    things: batchThings,
    catalogs,
    isLoading: isLoadingBatchThings,
  } = useExportBatchThingsData({
    batchUuid: batchId,
  });

  // Get the complete thing details for all checked out things
  const { things: checkedOutThings, isFetching: isFetchingCheckedOut } =
    useThingsData({
      fixedParams: {
        thingUuids: batchThings.map((t) => t.uuid),
        showCheckedOut: true,
        fields: ['mediaLinks', 'children', 'parent', 'root'],
      },
      perPage: 100,
      enabled: !isLoadingBatchThings,
      fetchAll: true,
    });

  // Get the complete thing details for things that have been recovered (isCheckedOut === false)
  const { things: recoveredThings, isFetching: isFetchingRecovered } =
    useThingsData({
      fixedParams: {
        thingUuids: batchThings.map((t) => t.uuid),
        showCheckedOut: false,
        fields: ['mediaLinks', 'children', 'parent', 'root'],
      },
      perPage: 100,
      enabled: !isLoadingBatchThings,
      fetchAll: true,
    });

  // Needed to managed race condition. See filter below
  const recoveredUuids = useMemo(
    () => recoveredThings.map((t) => t.uuid),
    [recoveredThings],
  );

  const allThings = useMemo(
    () =>
      batchThings.length > 0
        ? [
            ...recoveredThings.map((t) => ({
              ...t,
              recovered: true,
              catalogName: (catalogs ?? []).find(
                (c) => c.uuid === t.catalogUuid,
              )?.name,
            })),
            ...checkedOutThings
              // Prevent duplicate row keys in datagrid due to race condition between thing queries
              .filter((t) => !includes(recoveredUuids, t.uuid))
              .map((t) => ({
                ...t,
                recovered: false,
                catalogName: (catalogs ?? []).find(
                  (c) => c.uuid === t.catalogUuid,
                )?.name,
              })),
          ]
        : [],
    [checkedOutThings, recoveredThings, batchThings, recoveredUuids, catalogs],
  );

  // Display all trees with a thing that matches the search value
  const displayedRootUuids = useMemo(
    () =>
      allThings.filter((t) => t.title.includes(filter)).map((t) => t.rootUuid),
    [allThings, filter],
  );
  const displayedThings = useMemo(
    () => allThings.filter((t) => displayedRootUuids.includes(t.rootUuid)),
    [displayedRootUuids, allThings],
  );

  type ExtendedThing = (typeof allThings)[number] & Thing;
  // -------------------------------------------------------------------------------
  const columns = useMemo<GridColDef<ExtendedThing>[]>(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderCell: (props) =>
          props.row.hasParent
            ? ''
            : GRID_CHECKBOX_SELECTION_COL_DEF.renderCell?.(props),
      },
      idThingColDef as unknown as GridColDef<ExtendedThing>,
      {
        field: 'thumbnail',
        minWidth: 64,
        maxWidth: 64,
        headerName: '',
        renderCell: ({ row }) =>
          'primaryImage' in row && (
            <DustThingThumbnail thing={row} styleForTable />
          ),
      },
      {
        field: 'catalog',
        valueGetter: ({ row }) => row.catalogName,
        headerName: 'Catalog',
        flex: 1,
        minWidth: 80,
      },
      dustedThingColDef as unknown as GridColDef<ExtendedThing>,
      createdAtThingColDef as unknown as GridColDef<ExtendedThing>,
      {
        field: 'recovered',
        valueGetter: ({ row }) => (row.recovered ? 'Recovered' : ''),
        headerName: 'Recovery Status',
        minWidth: 150,
        sortComparator: (a: any, b: any) => booleanSort(a, b, 'recovered'),
      },
    ],
    [],
  );

  // -------------------------------------------------------------------------------
  // Event Handling

  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const [isRecovering, setIsRecovering] = useState(false);

  const numSelected = useMemo(
    () => allThings.filter((thing) => selected.includes(thing.rootUuid)).length,
    [selected, allThings],
  );

  const handleRecover = async () => {
    if (!batchId || selected.length < 1) {
      return;
    }

    setIsRecovering(true);

    const recoveryCalls = selected.map((thingUuid) =>
      exportBatchesApi.recoverThing({
        thingUuid,
      }),
    );
    const res = await Promise.all(recoveryCalls);

    if (res.some((query) => query.error)) {
      addToast('Could not recover some Things. Please try again.', 'error');
    } else {
      addToast(`Successfully recovered ${numSelected} Things.`, 'success');
      setModalOpen(false);
    }

    setSelected([]);
    setIsRecovering(false);
  };

  return (
    <>
      <div className="flex-row justify-space mb-1">
        <DustSearchInput setValue={setFilter} />
        <Button
          variant="contained"
          disabled={selected.length < 1}
          onClick={() => setModalOpen(true)}
        >
          {selected.length < 1 ? 'Recover' : `Recover ${numSelected} Things`}
        </Button>
      </div>
      <DustDataGrid
        altTheme
        loading={
          isLoadingBatchThings || isFetchingCheckedOut || isFetchingRecovered
        }
        columns={columns}
        getRowId={(r) => r.uuid}
        rows={displayedThings}
        isRowSelectable={(params: GridRowParams) =>
          !params.row.recovered && !params.row.hasParent
        }
        onSelectionModelChange={(items) => setSelected(items as string[])}
        selectionModel={selected}
        checkboxSelection
        treeData
        getTreeDataPath={(thing: Thing) => getTreeDataPath(thing, allThings)}
        groupingColDef={{
          headerName: 'Title',
          valueGetter: ({ row }) => row.title,
          hideDescendantCount: true,
        }}
        getRowClassName={({ row }) =>
          filter && row.title.includes(filter) ? styles.searchMatch : ''
        }
      />
      <DustModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        footerProps={{
          onCancel: () => setModalOpen(false),
          onSubmit: () => {
            void handleRecover();
          },
          loading: isRecovering,
        }}
        title="Recover Things"
      >
        <p>
          If you perform a recovery the Things on this machine will revert to
          Checked In status. Data conflicts may occur if you try to check a
          Thing in from another machine later.
        </p>
      </DustModal>
    </>
  );
}
