import { useState, useEffect, useRef, KeyboardEvent } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { TextField, IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

type Props = {
  initialValue: Date | null;
  onClear?: () => void;
} & Omit<DateTimePickerProps<Date, Date>, 'onChange' | 'renderInput' | 'value'>;

export default function DustDateTimePicker({
  initialValue = null,
  onClear,
  ...props
}: Props) {
  const [date, setDate] = useState(initialValue);

  useEffect(() => {
    setDate(initialValue);
  }, [initialValue]);

  const isDateValid = () => !Number.isNaN(date?.getTime?.());

  const handleBlur = () => {
    if (isDateValid()) {
      props?.onAccept?.(date);
    }
  };

  const handleKeyDown = (evt: KeyboardEvent<HTMLDivElement>) => {
    if (isDateValid() && evt.key === 'Enter') {
      props?.onAccept?.(date);
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClear = () => {
    setDate(null); // Clear value
    if (inputRef.current) {
      inputRef.current.value = ''; // Reset input
    }
    props?.onAccept?.(null); // Emit null value to parent
    onClear?.(); // Run option onClear function
  };

  return (
    <div className="flex-row gap-05">
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          ampm={false}
          onChange={(val) => setDate(val)}
          renderInput={(params) => (
            <TextField
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              ref={inputRef}
              size="small"
              sx={{ flex: 1 }}
              {...params}
            />
          )}
          value={date}
          {...props}
        />
      </LocalizationProvider>
      <IconButton onClick={handleClear}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}
