import { atom } from 'recoil';
import * as yup from 'yup';

import { userOrgLocalStorageEffect } from '../utility';

export const selectedFacilityAtom = atom<string | null>({
  key: 'selectedFacility',
  default: null,
  effects: [
    userOrgLocalStorageEffect('facility', yup.string().uuid().nullable()),
  ],
});

export const ignoreFacilityAtom = atom<boolean>({
  key: 'ignoreFacility',
  default: false,
  effects: [
    userOrgLocalStorageEffect('ignoreFacility', yup.boolean().nullable()),
  ],
});
