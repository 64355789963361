import { Tooltip } from '@mui/material';

import { ACCEPTED_IMAGE_TYPE_LIST } from '@/common/entities/things/constants';
import { provideFallbackImage } from '@/common/utilities/images';
import DustFileIcon from '@/components/Library/Icons/DustFileIcon';

import styles from './ThingDetailsSingle.module.css';

function ThingStaticFile({ file, media }) {
  const isImage = ACCEPTED_IMAGE_TYPE_LIST.includes(media?.fileType);

  return (
    <div className={styles.staticFileRow}>
      {isImage ? (
        <img
          className={styles.staticImage}
          onError={provideFallbackImage}
          src={media.publicUri}
          alt={`${file.name} preview`}
        />
      ) : (
        <DustFileIcon
          color="secondary"
          sx={{ margin: '.5rem 0', marginRight: '.5rem' }}
        />
      )}
      <Tooltip title={file.name} placement="bottom-start" enterDelay={700}>
        <span className="min-w-0 flex-1 text-overflow">{file.name}</span>
      </Tooltip>
    </div>
  );
}

export default ThingStaticFile;
