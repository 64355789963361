import { useMemo } from 'react';

import { useAuth } from 'react-oidc-context';

import useRequest from '@/services/requests/useRequest';
import { extractUserOrgs, getIsRootFromUser } from '@/services/utility';

/**
 * Hook for checking admin privileges on a user based on a set of group UUIDs
 */
export default function useAdminPrivileges() {
  const { orgId } = useRequest();
  const auth = useAuth();

  return useMemo(() => {
    const userOrgs = extractUserOrgs(auth);
    const userOrgRoles =
      userOrgs && orgId && orgId in userOrgs ? userOrgs[orgId].roles : [];
    const isRoot = getIsRootFromUser(auth.user);

    return {
      isLoadingAdminPrivileges: userOrgs === null,
      isRoot,
      canAccessAdminPanel:
        isRoot || ['admin', 'owner'].some((r) => userOrgRoles.includes(r)),
    };
  }, [auth, orgId]);
}
