import { MenuItem } from '@mui/material';
import PropTypes from 'prop-types';

import useThingTypesData from '@/common/entities/thingTypes/useThingTypesData';
import DustLoader from '@/components/Library/DustLoader';
import DustSelect from '@/components/Library/DustSelect';

function ThingTypeSelector({ value, setValue, ...props }) {
  const { isFetching, isSuccess, thingTypes } = useThingTypesData({
    fetchAll: true,
    sort: [{ desc: false, field: 'name' }],
  });

  const handleChange = (evt) => {
    setValue(thingTypes.find((t) => t.name === evt.target.value));
  };

  return (
    <DustSelect
      label="Thing Type"
      onChange={handleChange}
      size="small"
      value={value}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isSuccess && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
      )}
      {isSuccess &&
        thingTypes?.map((type) => (
          <MenuItem key={type?.name} value={type?.name}>
            {type?.name}
          </MenuItem>
        ))}
      {isFetching && <DustLoader size="medium" />}
    </DustSelect>
  );
}

ThingTypeSelector.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default ThingTypeSelector;
