import React from 'react';

import { QueryClient } from 'react-query';

import { AddToastCallback } from '@/common/hooks/useToasts';
import FeedbackApi from '@/services/requests/apis/FeedbackApi';

import AntiTamperApi from './apis/AntiTamperApi';
import CatalogsApi from './apis/CatalogsApi';
import DustApi from './apis/DustApi';
import ExportBatchesApi from './apis/ExportBatchesApi';
import FacilitiesApi from './apis/FacilitiesApi';
import FeaturesApi from './apis/FeaturesApi';
import GroupsApi from './apis/GroupsApi';
import MediaApi from './apis/MediaApi';
import OrgApi from './apis/OrgApi';
import OrgRolesApi from './apis/OrgRolesApi';
import ShoppingCartsApi from './apis/ShoppingCartsApi';
import ThingMediaApi from './apis/ThingMediaApi';
import ThingsApi from './apis/ThingsApi';
import ThingTypeApi from './apis/ThingTypeApi';
import TransactionsApi from './apis/TransactionsApi';
import UsersApi from './apis/UsersApi';
import ApiService from './ApiService';
import { QUERY_KEYS } from './constants';
import { invalidators } from './invalidators';

export function makeRequestContext(
  apiService: ApiService,
  queryClient: QueryClient,
  addToast: AddToastCallback,
  currentUserId: string | null,
  selectOrgId: (orgId: string) => void,
) {
  const invalidate = invalidators(queryClient);
  return {
    invalidate,
    QUERY_KEYS,
    orgId: apiService.orgId,
    selectOrgId,
    orgApi: new OrgApi(apiService, addToast, invalidate),
    catalogsApi: new CatalogsApi(apiService, addToast, invalidate),
    exportBatchesApi: new ExportBatchesApi(apiService, addToast, invalidate),
    facilitiesApi: new FacilitiesApi(apiService, addToast, invalidate),
    featuresApi: new FeaturesApi(apiService, addToast, invalidate),
    feedbackApi: new FeedbackApi(apiService, addToast),
    mediaApi: new MediaApi(apiService, addToast),
    orgRolesApi: new OrgRolesApi(apiService),
    shoppingCartsApi: new ShoppingCartsApi(apiService, addToast, invalidate),
    thingsApi: new ThingsApi(apiService, addToast, invalidate),
    thingTypeApi: new ThingTypeApi(apiService, addToast, invalidate),
    thingMediaApi: new ThingMediaApi(apiService, addToast),
    transactionsApi: new TransactionsApi(apiService, addToast, invalidate),
    groupsApi: new GroupsApi(apiService, addToast, invalidate),
    usersApi: new UsersApi(apiService, addToast, invalidate, currentUserId),
    dustApi: new DustApi(apiService, addToast, invalidate),
    antiTamperApi: new AntiTamperApi(apiService, addToast, invalidate),
  };
}

export type RequestContextType = ReturnType<typeof makeRequestContext>;

export const RequestContext = React.createContext(
  // We will always have a valid value because it's rendered at the App root.
  null as unknown as RequestContextType,
);
