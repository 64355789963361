import React from 'react';

import {
  CheckCircleOutline,
  Close,
  ErrorOutline,
  WarningAmberOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Typography,
  Dialog,
  IconButton,
  Grid,
} from '@mui/material';

import ArrowImage from '@/assets/images/multiColorArrow.png';

import styles from './AntiTamperAnalysisConfidenceLevel.module.css';

type AntiTamperAnalysisConfidenceLevelProps = {
  riskScore: number;
};

const CONFIDENCE_LEVEL = {
  SUSPECT_TAMPER: {
    key: 'SUSPECT_TAMPER',
    label: 'Suspect Tamper',
    text: 'The system suspects tampering on this tag.',
    Icon: <WarningAmberOutlined />,
    style: styles.suspectTamper,
    buttonStyle: {
      border: '1px solid var(--white) !important',
    },
  },
  INCONCLUSIVE: {
    key: 'INCONCLUSIVE',
    label: 'Inconclusive',
    text: 'The system cannot make a tampering conclusion on this tag.',
    Icon: <ErrorOutline />,
    style: styles.inconclusive,
    buttonStyle: {
      border: '1px solid var(--white) !important',
    },
  },
  PASS: {
    key: 'PASS',
    label: 'Pass',
    text: 'The system does NOT suspect tampering on this tag.',
    Icon: <CheckCircleOutline />,
    style: styles.pass,
    buttonStyle: {
      border: '1px solid var(--white) !important',
    },
  },
  MISMATCH: {
    key: 'MISMATCH',
    label: 'Mismatch',
    text: 'The system could not match the incoming scan with the existing tag for this item.',
    Icon: <CheckCircleOutline />,
    style: styles.mismatch,
    buttonStyle: {
      border: '1px solid var(--black) !important',
    },
  },
};

type ConfidenceLevel = ValueOf<typeof CONFIDENCE_LEVEL>;

export default function AntiTamperAnalysisConfidenceLevel({
  riskScore,
}: AntiTamperAnalysisConfidenceLevelProps) {
  const [confidenceLevel, setConfidenceLevel] = React.useState<ConfidenceLevel>(
    CONFIDENCE_LEVEL.SUSPECT_TAMPER,
  );
  const [riskScoreModalState, setRiskScoreModalState] = React.useState(false);

  React.useLayoutEffect(() => {
    if (riskScore < 0.0) return setConfidenceLevel(CONFIDENCE_LEVEL.MISMATCH);
    if (riskScore < 0.4) return setConfidenceLevel(CONFIDENCE_LEVEL.PASS);
    if (riskScore < 0.6)
      return setConfidenceLevel(CONFIDENCE_LEVEL.INCONCLUSIVE);
    setConfidenceLevel(CONFIDENCE_LEVEL.SUSPECT_TAMPER);
  }, [riskScore]);

  const handleOpen = () => {
    setRiskScoreModalState(true);
  };

  const handleClose = () => {
    setRiskScoreModalState(false);
  };

  return (
    <Box className={`${styles.root} ${confidenceLevel.style}`}>
      <Button
        sx={{
          backgroundColor: 'inherit',
          fontSize: '0.8rem',
          color: 'inherit !important',
          textTransform: 'none',
          borderRadius: 15,
          height: 30,
          ...confidenceLevel.buttonStyle,
        }}
        startIcon={confidenceLevel.Icon}
        variant="outlined"
        disabled
      >
        {confidenceLevel.label}
      </Button>

      <Typography
        sx={{
          fontSize: '0.7rem',
          fontWeight: 'bold',
          paddingTop: '1rem',
          width: 260,
        }}
      >
        {confidenceLevel.text}
        {confidenceLevel.key !== CONFIDENCE_LEVEL.MISMATCH.key && (
          <span className={styles.riskScore}>
            <Button
              onClick={handleOpen}
              sx={{
                color: 'var(--white)',
                textTransform: 'none',
                padding: 0,
                margin: 0,
                textDecoration: 'underline',
                height: '1em',
                fontSize: '0.7rem',
              }}
            >
              Risk score
            </Button>
            = {(riskScore * 100.0).toFixed(0)}%
          </span>
        )}
      </Typography>
      <Dialog
        open={riskScoreModalState}
        aria-labelledby="anti-tamper-score-dialog"
        onClose={handleClose}
      >
        <Box className={styles.modalCantainer}>
          <IconButton
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
            }}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
          <Typography variant="h5">Risk Score</Typography>
          <Grid container direction="row" className={styles.modalContent}>
            <Grid item md={2} className={styles.scoreArrow}>
              <img src={ArrowImage} alt="Score Arrow" />
            </Grid>
            <Grid
              item
              container
              md={10}
              className={styles.scoreLabels}
              direction="column"
            >
              <Grid item>
                <Typography sx={{ fontSize: 'var(--font-size-text-xs)' }}>
                  <p>
                    <strong>100%</strong>
                  </p>
                  <p>
                    Algorithm suspects tampering with high degree of confidence.
                  </p>
                </Typography>
              </Grid>
              <Grid item>
                <Typography sx={{ fontSize: 'var(--font-size-text-xs)' }}>
                  <p>
                    <strong>0%</strong>
                  </p>
                  <p>Algorithm does not detect any tampering.</p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
}
