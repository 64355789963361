import { TextField } from '@mui/material';
import { useFormik } from 'formik';

import { deactivateThingSchema } from '@/common/entities/things/schemas';
import useVerifiedSession from '@/common/hooks/useVerifiedSession';
import DustModal from '@/components/Library/DustModal';
import useRequest from '@/services/requests/useRequest';

type DeactivationModalProps = {
  open: boolean;
  onCancel: () => void;
  onError: () => void;
  onSuccess: () => void;
  thing: Thing;
};

export default function DeactivationModal({
  open,
  onCancel,
  onError,
  onSuccess,
  thing,
}: DeactivationModalProps) {
  const { dustApi } = useRequest();

  const { expireVerifiedThing } = useVerifiedSession();

  const handleSuccess = () => {
    expireVerifiedThing(thing.uuid);
    void onSuccess();
  };

  const handleError = () => {
    void onError();
  };

  const handleCancel = () => {
    void onCancel();
  };

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema: deactivateThingSchema,
    onSubmit: async (values) => {
      const result = await dustApi.deactivateTag({
        dustUuid: thing.dustUuids[0],
        reason: values.reason,
      });

      if (!result?.error) {
        formik.resetForm({ values: { reason: '' } });
        handleSuccess();
      } else {
        handleError();
      }
    },
  });

  return (
    <DustModal
      footerProps={{
        submitLabel: 'Deactivate',
        onCancel: handleCancel,
        submitFormId: 'deactivate-dust-form',
        loading: formik.isSubmitting,
      }}
      onClose={handleCancel}
      open={open}
      title="Deactivate DUST Tag"
    >
      <form id="deactivate-dust-form" onSubmit={formik.handleSubmit}>
        <h2 className="mb-1">What is the reason for tag deactivation?</h2>
        <TextField
          error={!!formik.errors.reason}
          fullWidth
          helperText={formik.errors.reason}
          margin="normal"
          minRows={2}
          multiline
          name="reason"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          placeholder="Deactivation reason..."
          size="small"
          value={formik.values.reason}
        />
      </form>
    </DustModal>
  );
}
