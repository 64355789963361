import { CSSProperties, forwardRef, useImperativeHandle } from 'react';

import FolderZipIconOutlined from '@mui/icons-material/FolderZipOutlined';
import { Button, SxProps } from '@mui/material';
import { FormikProps } from 'formik';
import { useDropzone } from 'react-dropzone';

import { readableFileSize } from '@/common/utility';

import styles from './Components.module.css';
import { ACCEPTED_MIME_TYPES, FILE_TYPE_ICONS } from './constants';
import DustFileChip from './DustFileChip';
import DustFileIcon from './Icons/DustFileIcon';
import DustImageIcon from './Icons/DustImageIcon';
import {
  combineClass,
  extensionsFromFileType,
  mimeTypesFromFileType,
  setNested,
  getNested,
} from './utility';

type Props<T extends object> = {
  fileType: keyof typeof ACCEPTED_MIME_TYPES;
  className?: string;
  style?: CSSProperties;
  label: string;
  maxSize?: number;
  disabled?: boolean;
  dataTestId?: string;

  file?: unknown;
  setFile?: unknown;
  onDelete?: unknown;
  onReject: (e: string[]) => void;

  formik?: unknown;
  name?: unknown;
} & (PropsWithoutFormik | PropsWithFormik<T>);

type PropsWithFormik<T extends object> = {
  name: string;
  formik: FormikProps<T>;
};

type PropsWithoutFormik = {
  name?: undefined;
  // // Marker needed to allow type refinement
  formik?: undefined;

  file?: File | null;
  setFile: (file: File) => void;
  onDelete: (e: string) => void;
};

export type DialogHandleRef = {
  open: () => void;
};

// From: https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/forward_and_create_ref/#generic-forwardrefs
// used to support generic refs
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-shadow
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export default forwardRef(
  <T extends object>(
    {
      className,
      style,
      label,
      name,
      file,
      setFile,
      onDelete,
      fileType,
      maxSize = 20971520, // 20 MB
      // UNUSED: error = false,
      onReject,
      formik,
      disabled,
      dataTestId,
    }: Props<T>,
    ref: React.Ref<DialogHandleRef>,
  ) => {
    const fileObj =
      (formik && name ? getNested(formik?.values, name) : null) ?? file;
    const mimeTypes = mimeTypesFromFileType(fileType);

    const extensions = extensionsFromFileType(fileType);

    // If Formik is detected handle file state through the Formik interface
    const onDrop = (acceptedFiles: File[]) => {
      if (formik) {
        const values = setNested(acceptedFiles[0], formik.values, name, true);
        formik.setValues(values, true);
      } else {
        setFile(acceptedFiles[0]);
      }
    };

    const handleDelete = (evt: string) => {
      if (formik) {
        const values = setNested(null, formik.values, name, true);
        formik.setValues(values, false);
      } else {
        onDelete(evt);
      }
    };

    const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
      useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        multiple: false,
        // Disable FSAccessAPI
        // Bug on Chromium (especially chromium linux) where picker won't open with mimeTypes specified
        // https://github.com/react-dropzone/react-dropzone/issues/1190
        useFsAccessApi: false,
        accept: mimeTypes,
        maxSize,
        disabled,
        onDropRejected: (items) => {
          if (items.length > 1) {
            onReject(['Only a single file may be selected. ']);
            return;
          }

          // Create a unique set of errors (don't display too many files for each files)
          // Format error messages as needed
          const errors: string[] = [];
          items.forEach((item) => {
            item?.errors?.forEach((err) => {
              if (err.code === 'file-too-large') {
                const size = readableFileSize(item.file.size);
                const max = readableFileSize(maxSize);
                errors.push(
                  `${item.file.name}: Max file size is ${max}. File size: ${size}`,
                );
              } else if (err.code === 'file-invalid-type') {
                errors.push(
                  `${
                    item.file.name
                  }: Unsupported file type. Supported file types: ${extensions.join(
                    ', ',
                  )}`,
                );
              } else if (err.message) {
                const msg = err.message.replace(/,(?=[^\s])/g, ', '); // Add trailing space to commas
                errors.push(`${item.file.name}: ${msg}`);
              }
            });
          });

          const uniqueErrors = [...new Set(errors)];
          onReject(uniqueErrors);
        },
      });

    // ---------------------------------------------------------------------------
    // Class Handing
    const fileLoaded = () => !!fileObj;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const isError: boolean =
      (formik && getNested(formik.errors, name.split('.value')?.[0])) ||
      isDragReject;

    const contClass = combineClass(
      styles.dropzone,
      className,
      (fileLoaded() || disabled) && styles.dropzone__single__loaded,
      isError && styles.dropzone__reject,
      isDragAccept && styles.dropzone__accept,
    );

    const buttonstyles: SxProps = isError
      ? {
          textTransform: 'none',
          color: 'var(--error-red)',
          transition: '0s',
        }
      : { textTransform: 'none' };

    const fileIcon = (FILE_TYPE_ICONS[fileType] === 'image' && (
      <DustImageIcon />
    )) ||
      (FILE_TYPE_ICONS[fileType] === 'zip' && <FolderZipIconOutlined />) || (
        <DustFileIcon />
      );

    useImperativeHandle(ref, () => ({
      open,
    }));

    return (
      // No need for keyboard listener due to button availability
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={contClass}
        style={style}
        {...getRootProps()}
        onClick={disabled || fileLoaded() ? undefined : open}
      >
        <input
          name={formik ? name : undefined}
          {...getInputProps()}
          data-test-id={dataTestId ?? null}
        />

        <div className={styles.dropzone_controls}>
          <div className={styles.dropzone_label}>
            {fileIcon}
            <p>{label}</p>
          </div>
          {fileLoaded() ? (
            <DustFileChip filename={fileObj?.name} onDelete={handleDelete} />
          ) : (
            <Button disabled={disabled} sx={buttonstyles}>
              Browse
            </Button>
          )}
        </div>
      </div>
    );
  },
);
